import { lazy } from 'react';

const List = lazy(() => import('pages/crud'));
const essayTheme = lazy(() => import('pages/essayTheme/essayTheme'));
const View = lazy(() => import('pages/essayTheme/view'));
const Details = lazy(() => import('pages/essayTheme/details'));

const essayThemeRoutes = [
    {
        path: '/app/temas-de-redacao',
        name: 'Temas de Redação',
        component: List,
        exact: true,
        title: 'Temas de Redação'
    },
    {
        path: '/app/temas-de-redacao/novo',
        name: 'Novo',
        component: essayTheme,
        exact: true,
        title: 'Novo tema'
    },
    {
        path: '/app/temas-de-redacao/editar/:id',
        name: 'Editar',
        component: essayTheme,
        exact: true,
        title: 'Editar tema'
    },
    {
        path: '/app/temas-de-redacao/ver/:id',
        name: 'Ver',
        component: View,
        exact: true,
        title: 'Detalhes do tema'
    },
    {
        path: '/app/temas-de-redacao/detalhes-redacao/:id/ver/:id',
        name: 'Exibir Redação',
        component: Details,
        exact: true,
        title: 'Detalhes da redação'
    }
];

export default essayThemeRoutes;
