import React, { memo } from 'react';
import styled from 'styled-components';
import Spinner from 'components/spinner';
import theme from 'config/theme';

const Container = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    flex: 1;
    padding: 50px 0;
`;

const MainLoader = memo(() => {
    return (
        <Container>
            <Spinner color={theme.colors.secondary} size={40} />
        </Container>
    );
});

export default MainLoader;
