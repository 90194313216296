import React from 'react';

// Components
import Avatar from 'components/avatar';
import Icon from 'components/icons/icon';

// Assets
import { WelcomeCardContainer, WelcomeCardButton, WelcomeCardText } from './styles';

interface IProps {
    name: string;
    isNavOpen: boolean;
    onOpenUserNav: () => void;
    imageProfile: string;
}

const WelcomeCard = ({ name, isNavOpen, onOpenUserNav, imageProfile }: IProps) => (
    <WelcomeCardContainer>
        <WelcomeCardButton isNavOpen={isNavOpen} onClick={onOpenUserNav}>
            <Avatar name={name} size="small" avatarPath={imageProfile} />
            <WelcomeCardText>
                Olá, <strong>{name}</strong>
            </WelcomeCardText>
            <Icon idIcon="ico-arrow-down" width="10" height="6" viewBox="0 0 10 6" />
        </WelcomeCardButton>
    </WelcomeCardContainer>
);

export default WelcomeCard;
