import { createActions, createReducer } from 'reduxsauce';

export const { Creators, Types } = createActions({
    searchRequest: ['payload'],
    searchSuccess: ['payload'],
    searchFailure: ['payload'],

    searchRemoveItem: ['payload'],
    searchRemoveItemFailure: [],

    clearSearch: []
});

export interface ISearchState {
    isLoading: boolean;
}

const INITIAL_STATE: ISearchState = {
    isLoading: false
};

const searchRequest = (state = INITIAL_STATE, action: any) => ({ ...state, isLoading: true });
const searchSuccess = (state = INITIAL_STATE, action: any) => {
    const { payload } = action;
    const { searchName } = payload;

    const updateState = state;
    updateState[searchName] = payload.data;
    updateState[`${searchName}_pagination`] = payload[`${searchName}_pagination`];

    return {
        ...updateState,
        isLoading: false
    };
};
const searchFailure = (state = INITIAL_STATE) => {
    return {
        isLoading: false
    };
};

// Remove Item
const searchRemoveItem = (state = INITIAL_STATE, action: any) => {
    try {
        const tmpState = { ...state };

        delete tmpState[action.payload];

        return {
            ...tmpState,
            isLoading: false
        };
    } catch (error) {
        return {
            ...state,
            isLoading: false
        };
    }
};

const clearSearch = (state = INITIAL_STATE) => INITIAL_STATE;

const searchRemoveItemFailure = (state = INITIAL_STATE) => ({ ...state });

export default createReducer(INITIAL_STATE, {
    [Types.SEARCH_REQUEST]: searchRequest,
    [Types.SEARCH_SUCCESS]: searchSuccess,
    [Types.SEARCH_FAILURE]: searchFailure,

    //

    [Types.SEARCH_REMOVE_ITEM]: searchRemoveItem,
    [Types.SEARCH_REMOVE_ITEM_FAILURE]: searchRemoveItemFailure,

    [Types.CLEAR_SEARCH]: clearSearch
});
