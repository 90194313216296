import React, { ButtonHTMLAttributes } from 'react';

// Components
import Icon from 'components/icons/icon';

// Assets
import { ButtonContainer, Variant } from './styles';

// export interface IPropsButton extends ButtonHTMLAttributes<HTMLButtonElement> {
export interface IPropsButton extends ButtonHTMLAttributes<HTMLButtonElement> {
    active?: boolean;
    block?: boolean;
    variant?: Variant;
    rounded?: boolean;
    size?: 'small' | 'large';
    withIcon?: boolean;
    isLoading?: boolean;
    gutterTop?: boolean;
    gutterBottom?: boolean;
    as?: any;
    to?: string;
    href?: any;
    target?: any;
    download?: boolean;
}

const Button = (props: IPropsButton) => <ButtonContainer {...props}>{props.isLoading ? <Icon idIcon="ico-spinner" width="20" height="20" viewBox="0 0 50 50" /> : props.children}</ButtonContainer>;

Button.defaultProps = {
    withIcon: false,
    rounded: false,
    type: 'button'
};

export default Button;
