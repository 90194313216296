import { put, call, takeLatest } from 'redux-saga/effects';
import { Creators as qrCodeActions } from 'store/ducks/qrcode';
import { Creators as alertActions } from 'store/ducks/alert';
import { apiRequest } from '../apiRequest';
import { IRequestAction } from 'utils/interfaces/IRequestAction';
import history from 'services/history';

import { Types as QRCodeTypes } from 'store/ducks/qrcode';

function* createQRCodes(action: IRequestAction<any>) {
    try {
        const { endpoint, method, body } = action.payload;

        const { data } = yield apiRequest(method, endpoint, body);

        if (!data) {
            throw new Error();
        }

        yield put(qrCodeActions.createQRCodesSuccess(data));
        yield put(alertActions.showAlert('QR Codes gerados.', 'success'));

        if (data.length === 1) {
            yield call(history.push, { pathname: `/app/qrcodes` });
        }
    } catch (error) {
        yield put(qrCodeActions.createQRCodesFailure());
        yield put(alertActions.showAlert(error?.response?.data?.detail || 'Não foi possível gerar os QR codes.', 'danger'));
    }
}

function* getQRCode(action: IRequestAction<any>) {
    try {
        const { endpoint, method } = action.payload;

        const { data } = yield apiRequest(method, endpoint);

        yield put(qrCodeActions.getQRCodeSuccess(data));
    } catch (error) {
        yield put(qrCodeActions.getQRCodeFailure());
        yield put(alertActions.showAlert('Não foi possível encontrar o QR code.', 'danger'));
    }
}

function* deleteQRCodeContent(action: { type: string; payload: { qrCodeId: number; LOId: number } }) {
    try {
        const { qrCodeId, LOId } = action.payload;

        if (!LOId || !qrCodeId) {
            throw new Error();
        }

        yield apiRequest('DELETE', `/admin/qrcode/${qrCodeId}/content/${LOId}`);

        yield put(qrCodeActions.deleteQRCodeContentSuccess(LOId));

        yield put(alertActions.showAlert('Conteúdo desassociado.', 'success'));
    } catch (error) {
        yield put(qrCodeActions.deleteQRCodeContentFailure());
        yield put(alertActions.showAlert('Não foi possível desassociar o conteúdo.', 'danger'));
    }
}

export default [
    takeLatest(QRCodeTypes.CREATE_QR_CODES_REQUEST, createQRCodes),
    takeLatest(QRCodeTypes.GET_QR_CODE_REQUEST, getQRCode),
    takeLatest(QRCodeTypes.DELETE_QR_CODE_CONTENT_REQUEST, deleteQRCodeContent)
];
