import { createActions, createReducer } from 'reduxsauce';
import { Action } from 'redux';

export interface ICampaignActions {
    getCampaignRequest(payload: { id: number }): Action<void>;
    getCampaignSuccess(response: any): Action<void>;
    getCampaignFailure(): Action<void>;

    createOrEditCampaignRequest(payload: any): Action<void>;
    createOrEditCampaignSuccess(response: any): Action<void>;
    createOrEditCampaignFailure(): Action<void>;

    clearCampaign(): Action<void>;
}

export const { Creators, Types } = createActions({
    getCampaignRequest: ['payload'],
    getCampaignSuccess: ['payload'],
    getCampaignFailure: [],

    createOrEditCampaignRequest: ['payload'],
    createOrEditCampaignSuccess: ['payload'],
    createOrEditCampaignFailure: [],

    clearCampaign: []
});

export interface ICampaignState {
    isLoading: boolean;
    id: number;
    name: string;
    slug: string;
    is_active: boolean;
}

export type CampaignTypes =
    | 'GET_CAMPAIGN_REQUEST'
    | 'GET_CAMPAIGN_SUCCESS'
    | 'GET_CAMPAIGN_FAILURE'
    | 'CREATE_OR_EDIT_CAMPAIGN_REQUEST'
    | 'CREATE_OR_EDIT_CAMPAIGN_SUCCESS'
    | 'CREATE_OR_EDIT_CAMPAIGN_FAILURE';

const INITIAL_STATE: ICampaignState = {
    isLoading: false,
    id: 0,
    name: '',
    slug: '',
    is_active: true
};

const getCampaignRequest = (state = INITIAL_STATE, action: any) => ({ ...state, isLoading: true });
const getCampaignSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoading: false,
    ...action.payload
});
const getCampaignFailure = (state = INITIAL_STATE) => ({ ...state, isLoading: false });

const createOrEditCampaignRequest = (state = INITIAL_STATE, action: any) => ({ ...state, isLoading: true });
const createOrEditCampaignSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoading: false,
    ...action.payload
});
const createOrEditCampaignFailure = (state = INITIAL_STATE) => ({ ...state, isLoading: false });

const clearCampaign = () => INITIAL_STATE;

const HANDLERS = {
    [Types.GET_CAMPAIGN_REQUEST]: getCampaignRequest,
    [Types.GET_CAMPAIGN_SUCCESS]: getCampaignSuccess,
    [Types.GET_CAMPAIGN_FAILURE]: getCampaignFailure,

    [Types.CREATE_OR_EDIT_CAMPAIGN_REQUEST]: createOrEditCampaignRequest,
    [Types.CREATE_OR_EDIT_CAMPAIGN_SUCCESS]: createOrEditCampaignSuccess,
    [Types.CREATE_OR_EDIT_CAMPAIGN_FAILURE]: createOrEditCampaignFailure,

    [Types.CLEAR_CAMPAIGN]: clearCampaign
};

// TYPES
export default createReducer(INITIAL_STATE, HANDLERS);
