export function queryStringToObject(str?: string) {
    const obj = {};

    if (!str) {
        return obj;
    }

    const pairs = str.split('&');

    let i;

    for (i in pairs) {
        const split = pairs[i].split('=');
        obj[decodeURIComponent(split[0])] = decodeURIComponent(split[1]);
    }

    return obj;
}
