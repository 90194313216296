import styled from 'styled-components';
import { CardContainer } from 'components/card/styles';
import { SmallTitle } from 'assets/global';
import { ButtonContainer } from 'components/button/styles';

export const AdvancedSearchContainer = styled.div`
    width: 350px;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;

    ${CardContainer} {
        height: 100%;
        overflow-y: auto;
        box-shadow: unset;
        padding: 20px;
        border-radius: unset;
    }

    .advancedsearch-enter {
        opacity: 0;
        transform: translateX(100%);
    }
    .advancedsearch-enter-active {
        opacity: 1;
        transform: translateX(0);
        transition: opacity 300ms, transform 300ms;
    }
    .advancedsearch-exit {
        opacity: 1;
    }
    .advancedsearch-exit-active {
        opacity: 0;
        transform: translateX(100%);
        transition: opacity 300ms, transform 300ms;
    }
`;

export const AdvancedSearchHeader = styled.header`
    display: flex;
    align-items: center;

    margin-bottom: 20px;

    ${ButtonContainer} {
        padding: 10px;
    }

    ${SmallTitle} {
        flex: 1;
    }
`;
