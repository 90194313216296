import announcements from './announcements';
import brands from './brand';
import company from './company';
import contests from './contest';
import course from './course';
import exam from './exam';
import examPrevious from './examPrevious';
import lessonPlan from './lessonPlan';
import schoolPeriod from './schoolPeriod';
import subject from './subject';
import question from './question';
import essayTheme from './essayTheme';
import teachers from './teachers';
import schoolGrade from './schoolgrade';
import schoolunit from './schoolunit';
import schoolClass from './schoolClass';
import student from './student';
import projectCategory from './projectCategory';
import menus from './menus';
import profiles from './profiles';
import pages from './pages';
import users from './users';
import products from './products';
import subjectFront from './subjectFront';
import topic from './topic';
import order from './order';
import event from './event';
import lessonPlanTag from './lessonPlanTag';
import knowledgeArea from './knowledgeArea';
import files from './files';
import subjectGroup from './subjectGroup';
import stores from './stores';
import correctionEngine from './correctionengine';
import sellers from './sellers';
import productsPhysical from './productsPhysical';
import invoices from './invoices';
import campaigns from './campaigns';

export default {
    announcements,
    brands,
    company,
    contests,
    course,
    exam,
    examPrevious,
    lessonPlan,
    schoolPeriod,
    subject,
    question,
    essayTheme,
    teachers,
    schoolGrade,
    schoolunit,
    schoolClass,
    student,
    projectCategory,
    menus,
    profiles,
    pages,
    users,
    products,
    subjectFront,
    topic,
    order,
    event,
    lessonPlanTag,
    knowledgeArea,
    files,
    subjectGroup,
    stores,
    correctionEngine,
    sellers,
    productsPhysical,
    invoices,
    campaigns
};
