import React, { useEffect } from 'react';

// Dependencies
import { object, string } from 'yup';
import { Form } from '@rocketseat/unform';

// Components
import Button from 'components/button';
import Input from 'components/forms/input';

// Helpers
import dictionary from 'config/dictionary';
import { IAuthActions } from 'store/types/IAuth';

// Assets
import { FormGroup } from 'components/forms/styles';

interface IProps {
    isLoading: boolean;
    authActions: IAuthActions;
}

const schema = object().shape({
    email: string().email(dictionary.validations.email).required(dictionary.validations.required),
    password: string().required()
});

const AuthForm = ({ authActions, isLoading }: IProps) => {
    useEffect(() => {
        authActions.clearLoading();
    }, [authActions]);

    const handleSubmit = (data: any) => authActions.loginRequest(data);

    return (
        <Form schema={schema} onSubmit={handleSubmit}>
            <FormGroup>
                <Input autoFocus={true} name="email" placeholder="E-mail" />
            </FormGroup>
            <FormGroup>
                <Input name="password" type="password" placeholder="Senha" />
            </FormGroup>
            <FormGroup>
                <Button disabled={isLoading} block={true} variant="primary" type="submit">
                    {isLoading ? 'ENTRANDO...' : 'ENTRAR'}
                </Button>
            </FormGroup>
        </Form>
    );
};

export default React.memo(AuthForm, (prevProps, nextProps) => (prevProps.isLoading === nextProps.isLoading ? true : false));
