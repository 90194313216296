import { put } from 'redux-saga/effects';
import { Creators as schoolGradeActions } from 'store/ducks/schoolGrade';
import { Creators as modalActions } from 'store/ducks/modal';
import { Creators as alertActions } from 'store/ducks/alert';
import { apiRequest } from '../apiRequest';
import { IRequestAction } from 'utils/interfaces/IRequestAction';
import { graphQLRequest } from '../graphQLRequest';
import addCompanyOrBrandVariables from 'utils/formatGraphQL/addCompanyOrBrandVariables';
import _set from 'lodash/set';
import _merge from 'lodash/merge';

export function* getAllSchoolGrades(action: any) {
    try {
        const { brand, brandSlug } = action.payload;

        const params: any = {
            where: {
                deleted: {
                    _is_null: true
                }
            }
        };

        if (brand) {
            _set(params, 'where.company.brands', {
                id: {
                    _eq: brand
                },
                deleted: {
                    _is_null: true
                }
            });
        } else if (brandSlug) {
            _set(params, 'where.company.brands', {
                id: {
                    slug: brand
                },
                deleted: {
                    _is_null: true
                }
            });
        } else {
            _set(params, 'where', _merge(addCompanyOrBrandVariables(false, 'company.brands', 'company', 'company.brands.units'), params.where));
        }

        const { data } = yield graphQLRequest(
            `query ($where: prodigio_school_grades_bool_exp) {
            items: prodigio_school_grades(where: $where) {
              id
              name
              order
            }
          }`,
            params
        );

        if (!data?.items) {
            throw new Error();
        }

        yield put(schoolGradeActions.getAllSchoolGradesSuccess(data.items));
    } catch (error) {
        yield put(schoolGradeActions.getAllSchoolGradesFailure());
        yield put(alertActions.showAlert('Não foi possível recuperar as séries.', 'danger'));
    }
}

export function* getSchoolGrade(action: IRequestAction<any>) {
    try {
        const { endpoint, method } = action.payload;

        const { data } = yield apiRequest(method, endpoint);

        if (!data) {
            throw new Error();
        }

        yield put(schoolGradeActions.getSchoolGradeSuccess(data));
    } catch (error) {
        console.log('error', error);
        yield put(schoolGradeActions.getSchoolGradeFailure());
        yield put(alertActions.showAlert('Não foi possível encontrar a série.', 'danger'));
    }
}

export function* getSchoolGradeProjects(action: IRequestAction<any>) {
    try {
        const { endpoint, method, body, headers } = action.payload;

        const { data } = yield apiRequest(method, endpoint, body, headers);

        if (!data) {
            throw new Error();
        }

        yield put(schoolGradeActions.getAllSchoolGradeProjectsSuccess(data));
    } catch (error) {
        console.log('error', error);

        yield put(schoolGradeActions.getAllSchoolGradeProjectsFailure());
        yield put(alertActions.showAlert('Não foi possível obter os projetos da série.', 'danger'));
    }
}

export function* sendProjectToSchoolGrade(action: IRequestAction<any>) {
    try {
        const { endpoint, method, body, headers } = action.payload;

        yield apiRequest(method, endpoint, body, headers);

        yield put(schoolGradeActions.sendProjectToSchoolGradeSuccess());

        yield put(modalActions.closeModal());

        const { data } = yield apiRequest('GET', `/admin/project/schoolgrade/${body.idGrade}`);

        if (!data) {
            return;
        }

        yield put(schoolGradeActions.getAllSchoolGradeProjectsSuccess(data));
    } catch (error) {
        console.log('error', error);

        yield put(schoolGradeActions.sendProjectToSchoolGradeFailure());
        yield put(alertActions.showAlert('Não foi possível enviar o projeto.', 'danger'));
    }
}
