import theme from 'config/theme';
import { Row } from 'react-bootstrap';
import styled from 'styled-components/macro';
import { NavListItem } from 'components/NavList/styles';
import { ActionsNavContainer } from './components/actions/styles';
import { StudentDataItem, StudentDataContainer, StudentContentWrapper } from 'pages/student/styles';

export const OrderContainer = styled.section`
    ${StudentContentWrapper} {
        a {
            color: ${theme.colors.primary};
            text-decoration: underline;
        }
    }

    ${StudentDataItem} {
        flex: unset;
        width: 100%;

        &:nth-last-child(-n + 3) {
            margin-bottom: 20px;
        }
    }

    ${StudentDataContainer} {
        &:not(:last-child) {
            border-bottom: 1px solid #f0f0f2;
        }
    }

    ${NavListItem} {
        ${StudentDataItem} {
            display: flex;
            margin: 0;

            strong {
                margin: 0 10px 0 0;
            }
            span {
                text-transform: lowercase;
            }
        }
    }
`;

export const OrderProductThumb = styled.figure`
    background: #f0f0f2;
    border-radius: 8px;
    overflow: hidden;

    img {
        max-width: 100%;
        display: block;
        height: auto;
    }
`;

export const OrderProductDetails = styled(Row)`
    &:not(:last-child) {
        margin-bottom: 25px;
        padding-bottom: 25px;
        border-bottom: 1px solid #f0f0f2;
    }
`;

export const OrderAmount = styled.footer`
    color: #404042;
    font-family: carosbold;
    text-transform: uppercase;

    > div {
        align-items: center;
        display: flex;
        justify-content: space-between;

        &:last-child {
            margin-top: 10px;
        }
    }

    strong {
        font-size: 28px;
    }
`;

export const OrderPhysicalProducts = styled.ul`
    border-top: 1px solid #f0f0f2;
    margin-top: 10px;
    padding-top: 10px;
`;

export const OrderPhysicalProductsItem = styled.li`
    display: flex;
    flex-direction: column;

    &:not(:last-child) {
        margin-bottom: 10px;
    }
`;

export const OrderActionsWrapper = styled.div`
    position: relative;

    ${ActionsNavContainer} {
        position: absolute;
        right: -10px;
        top: -10px;
    }
`;

export const OrderProductConfiguration = styled.div`
    padding: 10px;
`;
