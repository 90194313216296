import { store } from 'store';
import { IReduxStore } from 'utils/interfaces/IReduxStore';

const graphQLWebSocket = () => {
    const token = (store.getState() as IReduxStore)?.auth?.token;

    if (!token || !process.env.REACT_APP_GRAPHQL_SOCKET_URL) {
        throw new Error();
    }

    const socket = new WebSocket(process.env.REACT_APP_GRAPHQL_SOCKET_URL, 'graphql-ws');

    socket.addEventListener('open', () => {
        socket.send(
            JSON.stringify({
                type: 'connection_init',
                payload: {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            })
        );
    });

    return socket;
};

export default graphQLWebSocket;
