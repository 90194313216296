import styled from 'styled-components';
import { Link } from 'react-router-dom';
import theme from 'config/theme';

export const BreadcrumbContainer = styled.nav`
    align-items: center;
    display: flex;
`;

export const BreadcrumbWrapper = styled.ul`
    align-items: center;
    display: flex;
`;

export const BreadcrumbItem = styled.li<{ active?: boolean }>`
    align-items: center;
    color: ${({ active }) => (active ? theme.colors.primary : theme.colors.baseDark)};
    display: flex;

    &:not(:last-child) {
        margin-right: ${theme.spacing.small.all};

        &:after {
            color: ${theme.colors.base};
            content: '/';
            margin-left: ${theme.spacing.small.all};
            opacity: 0.3;
        }
    }
`;

export const BreadcrumbButton = styled(Link)`
    color: ${theme.colors.base};

    .ico-home {
        fill: ${theme.colors.base};
    }

    &:hover,
    &.active {
        color: ${theme.colors.primary};

        .ico-home {
            fill: ${theme.colors.primary};
        }
    }
`;
