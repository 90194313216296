import { IAvaliableColumns } from 'utils/interfaces/IRoute';

/**
 *  Dear maintainer:
 *
 *  Once you are done trying to 'optimize' this routine,
 *  and have realized what a terrible mistake that was,
 *  please increment the following counter as a warning
 *  to the next guy:
 *
 *  total_hours_wasted_here = 5
 *
 *
 * @param columns
 * @param tableName
 */
export default function generateGetAllQuery(columns: IAvaliableColumns[], tableName?: string, alternativeQuery?: string, isLearningObject?: boolean, schemaName = 'prodigio') {
    if (!!alternativeQuery) {
        return alternativeQuery || '';
    }

    if (!tableName) {
        return alternativeQuery ?? '';
    }

    const formatfields = columns
        .filter(({ name }) => name !== 'id')
        .map(({ name, subItem }) => {
            const format = `${name}${!!subItem ? `.${subItem}` : ''}`.split('.');

            return `${format.join(' {\n')}\n${Array.from(new Array(format.length >= 1 ? format.length - 1 : 0))
                .map(() => `}`)
                .join('\n')}`;
        })
        .join('\n');

    const formatTableName = `${schemaName}_${tableName}`;

    return `query ($orderBy: [${formatTableName}_order_by!], $where: ${formatTableName}_bool_exp, $limit: Int, $offset: Int) {
          items: ${formatTableName}(where: $where, order_by: $orderBy, limit: $limit, offset: $offset) {
            id
            ${formatfields}
            ${
                !!isLearningObject
                    ? `\nlearning_object {
                company {
                  id
                  brands {
                    id
                  }
                }
              }`
                    : ''
            }
          }
          quantity: ${formatTableName}_aggregate(where: $where) {
            aggregate {
              count
            }
          }
        }`;
}
