import { put, call } from 'redux-saga/effects';
import { Creators as notificationActions } from 'store/ducks/notification';
import { Creators as alertActions } from 'store/ducks/alert';
import history from 'services/history';
import { apiRequest } from '../apiRequest';

export function* getNotification(action: any) {
    try {
        const { data } = yield apiRequest('GET', `/admin/notification/${action.payload.id}`);

        yield put(notificationActions.getNotificationSuccess(data));
    } catch (error) {
        yield put(notificationActions.getNotificationFailure());
        yield put(alertActions.showAlert('Não foi possível recuperar o comunicado.', 'danger'));
    }
}

export function* sendNotification(action: any) {
    try {
        const { data } = yield apiRequest('PATCH', `/admin/notification/${action.payload.id}`, action.payload);

        yield put(notificationActions.sendNotificationSuccess(data));
        // @ts-ignore
        yield call(history.push, `/app/comunicados`);
    } catch (error) {
        yield put(notificationActions.sendNotificationFailure());
        yield put(alertActions.showAlert('Não foi possível enviar o comunicado.', 'danger'));
    }
}
