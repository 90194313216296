export const campaignByPk = `
query CampaignByPk($id: Int!) {
    item: prodigio_campaigns_by_pk(id: $id) {
      name
      date_start
      date_finish
      is_active
      discount_percentual
      discount_value
      coupons {
        code
        qtd
      }
      campaigns_had_products {
        id_product
      },
      campaigns_products {
        id_product
      }
      campaigns_payment_methods {
        id_payment_method
        payment_method {
          name
        }
      }
    }
  }
`;
