import React, { memo } from 'react';

// Components
import Product from './product';

// Helpers
import formatPrice from 'utils/formatPrice';
import { IStoreOrder } from 'utils/interfaces/IStore';

// Assets
import { OrderAmount } from 'pages/store/styles';
import { StudentDataGroup } from 'pages/student/styles';

interface IOrderProductsProps {
    order: IStoreOrder;
}

const OrderProducts = ({ order }: IOrderProductsProps) => {
    return (
        <StudentDataGroup>
            {order.details.map((detail) => (
                <Product {...detail} key={detail.id} />
            ))}
            <OrderAmount>
                <div>
                    <span>Valor original</span>
                    <span>{formatPrice(order.original_amount)}</span>
                </div>
                {order.original_amount - order.amount > 0 && (
                    <div>
                        <span>Desconto</span>
                        <span>{formatPrice(order.original_amount - order.amount)}</span>
                    </div>
                )}
                <div>
                    <span>Total</span>
                    <strong>{formatPrice(order.amount)}</strong>
                </div>
            </OrderAmount>
        </StudentDataGroup>
    );
};

export default memo(OrderProducts);
