export const storeLocations = `query getStoreLocations($orderBy: [prodigio_store_locations_order_by!], $where: prodigio_store_locations_bool_exp, $limit: Int, $offset: Int) {
    items: prodigio_store_locations(where: $where, order_by: $orderBy, limit: $limit, offset: $offset) {
      name
      id
      address {
        city
        complement
        id
        neighborhood
        number
        state
        street
        updated
        zip_code
      }
    }
    quantity: prodigio_store_locations_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }`;
