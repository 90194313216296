import InputTypes from 'utils/enums/inputTypes';
import { IField } from 'utils/interfaces/IField';

const exams: IField[] = [
    {
        presentation: 'Nome',
        label: 'Nome do simulado',
        name: 'name',
        type: InputTypes.text,
        columns: 3,
        required: true
    },
    {
        presentation: 'Ano',
        label: 'Ano',
        name: 'year',
        type: InputTypes.number,
        columns: 1,
        required: true
    },
    {
        presentation: 'Selecione um concurso',
        label: 'Concurso',
        name: 'contest',
        type: InputTypes.simpleSelect,
        columns: 3,
        required: true
    }
];

export default exams;
