import { createActions, createReducer } from 'reduxsauce';
import {
    AnswerQuestionsActionsType,
    IAnswerQuestionsState,
    IAnswerQuestionsActionsCreators,
    ITopicStatus,
    ITopicResponse,
    IResponsePayload,
    IPostsResponse,
    IPostsResponseVariables,
    IPostResponse,
    ICreatePostReplyResponseVariables,
    IDeletePostReplyResponseVariables,
    IEditPostReplyResponseVariables,
    IGetUpdatedPostsResponse,
    IPost,
    IPostsFormattedItemsToCheckUpdates,
    IUpdatedPostsResponseVariables,
    ICreateAnswerQuestionsPostReplyPayload,
    IEditAnswerQuestionsPostReplyPayload,
    IDeleteAnswerQuestionsPostAnswerAttachmentResponseVariables,
    IAddAnswerQuestionsPostAnswerAttachmentResponseVariables,
    IAddAnswerQuestionsPostAnswerAttachmentPayload
} from './types';

export const { Creators, Types } = createActions<Record<AnswerQuestionsActionsType, AnswerQuestionsActionsType>, IAnswerQuestionsActionsCreators>({
    getAnswerQuestionsTopicRequest: ['payload'],
    getAnswerQuestionsTopicSuccess: ['payload'],
    getAnswerQuestionsTopicFailure: ['payload'],

    getAnswerQuestionsPostsRequest: ['payload'],
    getAnswerQuestionsPostsSuccess: ['payload'],
    getAnswerQuestionsPostsFailure: ['payload'],

    getAnswerQuestionsHasNewPostsRequest: ['payload'],
    getAnswerQuestionsHasNewPostsSuccess: ['payload'],
    getAnswerQuestionsHasNewPostsFailure: ['payload'],

    getAnswerQuestionsHasUpdatedPostsRequest: ['payload'],
    getAnswerQuestionsHasUpdatedPostsSuccess: ['payload'],
    getAnswerQuestionsHasUpdatedPostsFailure: ['payload'],

    getAnswerQuestionsUpdatedPostsRequest: ['payload'],
    getAnswerQuestionsUpdatedPostsSuccess: ['payload'],
    getAnswerQuestionsUpdatedPostsFailure: ['payload'],

    createAnswerQuestionsPostReplyRequest: ['payload'],
    createAnswerQuestionsPostReplySuccess: ['payload'],
    createAnswerQuestionsPostReplyFailure: ['payload'],

    editAnswerQuestionsPostReplyRequest: ['payload'],
    editAnswerQuestionsPostReplySuccess: ['payload'],
    editAnswerQuestionsPostReplyFailure: ['payload'],

    deleteAnswerQuestionsPostReplyRequest: ['payload'],
    deleteAnswerQuestionsPostReplySuccess: ['payload'],
    deleteAnswerQuestionsPostReplyFailure: ['payload'],

    deleteAnswerQuestionsPostRequest: ['payload'],
    deleteAnswerQuestionsPostSuccess: ['payload'],
    deleteAnswerQuestionsPostFailure: ['payload'],

    addAnswerQuestionsPostAnswerAttachmentRequest: ['payload'],
    addAnswerQuestionsPostAnswerAttachmentSuccess: ['payload'],
    addAnswerQuestionsPostAnswerAttachmentFailure: ['payload'],

    deleteAnswerQuestionsPostAnswerAttachmentRequest: ['payload'],
    deleteAnswerQuestionsPostAnswerAttachmentSuccess: ['payload'],
    deleteAnswerQuestionsPostAnswerAttachmentFailure: ['payload'],

    clearAnswerQuestions: []
});

const INITIAL_STATE: IAnswerQuestionsState = {
    topic: {
        isLoading: true,
        data: {
            ...({} as ITopicResponse),
            status: ITopicStatus.Inactive
        }
    },
    posts: {
        items: [],
        filters: {
            quantity: 10,
            withoutAnswers: false
        },
        isLoading: true,
        hasMore: true,
        formattedItemsToCheckUpdates: [],
        deletedItemsCount: 0
    },
    newPostsQuantity: 0,
    postsToUpdate: []
};

interface IAction<T> {
    payload: T;
    type: string;
}

const getAnswerQuestionsTopicRequest = (state = INITIAL_STATE) => ({
    ...state,
    topic: {
        ...state.topic,
        isLoading: true
    }
});
const getAnswerQuestionsTopicSuccess = (state = INITIAL_STATE, { payload }: IAction<IResponsePayload<ITopicResponse, {}>>) => ({
    ...state,
    topic: {
        ...state.topic,
        isLoading: false,
        data: payload.response
    }
});
const getAnswerQuestionsTopicFailure = (state = INITIAL_STATE) => ({
    ...state,
    topic: {
        ...state.topic,
        isLoading: false
    }
});

const getAnswerQuestionsPostsRequest = (state = INITIAL_STATE) => ({
    ...state,
    posts: {
        ...state.posts,
        isLoading: true
    }
});
const getAnswerQuestionsPostsSuccess = (state = INITIAL_STATE, { payload }: IAction<IResponsePayload<IPostsResponse, IPostsResponseVariables>>) => {
    const { data, pagination } = payload.response;
    const { withoutAnswers, isRestarting, serverDate } = payload.variables;

    const withoutAnswersHasChanged = withoutAnswers !== state.posts.filters.withoutAnswers;
    const mustClearPosts = withoutAnswersHasChanged || !!isRestarting;

    const formattedItemsToCheckUpdates = data.map((post) => ({
        IdPost: post.id,
        LastestUpdate: new Date(serverDate).toISOString()
    }));

    return {
        ...state,
        posts: {
            ...state.posts,
            items: mustClearPosts ? data : [...state.posts.items, ...data],
            filters: {
                ...state.posts.filters,
                withoutAnswers,
                bookmark: pagination.bookmark
            },
            isLoading: false,
            hasMore: pagination.hasMoreItems,
            formattedItemsToCheckUpdates: mustClearPosts ? formattedItemsToCheckUpdates : [...state.posts.formattedItemsToCheckUpdates, ...formattedItemsToCheckUpdates],
            ...(mustClearPosts && { deletedItemsCount: 0 })
        },
        ...(!!isRestarting && { newPostsQuantity: 0, postsToUpdate: [] })
    };
};
const getAnswerQuestionsPostsFailure = (state = INITIAL_STATE) => ({
    ...state,
    posts: {
        ...state.posts,
        isLoading: false
    }
});

const getAnswerQuestionsHasNewPostsRequest = (state = INITIAL_STATE) => ({ ...state });
const getAnswerQuestionsHasNewPostsSuccess = (state = INITIAL_STATE, { payload }: IAction<IResponsePayload<IPostsResponse, {}>>) => {
    // let newPosts = payload.response.data;

    // if (state.posts.items.length < 10 && state.posts.deletedItemsCount > 0) {
    //     newPosts = newPosts.slice(0, 10 - state.posts.deletedItemsCount);
    // }

    // newPosts = newPosts.filter((newPostItem) => {
    //     return !state.posts.items.find((postItem) => postItem.id === newPostItem.id);
    // });

    let newPostsQuantity = 0;

    payload.response.data.forEach((newPostItem, index) => {
        const isNew = !state.posts.items.find((postItem) => postItem.id === newPostItem.id);

        if (isNew && newPostsQuantity === index) {
            newPostsQuantity = newPostsQuantity + 1;
        }
    });

    return {
        ...state,
        newPostsQuantity
        // newPostsQuantity: newPosts.length
    };
};
const getAnswerQuestionsHasNewPostsFailure = (state = INITIAL_STATE) => ({ ...state });

const getAnswerQuestionsHasUpdatedPostsRequest = (state = INITIAL_STATE) => ({ ...state });
const getAnswerQuestionsHasUpdatedPostsSuccess = (state = INITIAL_STATE, { payload }: IAction<IResponsePayload<IGetUpdatedPostsResponse[], {}>>) => {
    let hasDeletedItems: boolean = false;

    const filteredItems = state.posts.items.reduce((accumulator, postItem) => {
        const isActiveItem = !!payload.response.find((postToUpdateItem) => postToUpdateItem.idPost === postItem.id);

        if (isActiveItem) {
            return [...accumulator, postItem];
        }

        hasDeletedItems = true;

        return accumulator;
    }, [] as IPost[]);

    const filteredFormattedItemsToCheckUpdates = state.posts.formattedItemsToCheckUpdates.reduce((accumulator, formattedItemsToCheckUpdatesItem) => {
        const isActiveItem = !!payload.response.find((postToUpdateItem) => postToUpdateItem.idPost === formattedItemsToCheckUpdatesItem.IdPost);

        if (isActiveItem) {
            return [...accumulator, formattedItemsToCheckUpdatesItem];
        }

        return accumulator;
    }, [] as IPostsFormattedItemsToCheckUpdates[]);

    const postsToUpdate = payload.response.reduce((accumulator, post) => {
        if (post.hasChanges) {
            return [...accumulator, post.idPost];
        }

        return accumulator;
    }, [] as string[]);

    return {
        ...state,
        ...((!!hasDeletedItems as any) && {
            posts: {
                ...state.posts,
                filters: {
                    ...state.posts.filters,
                    bookmark: filteredItems[filteredItems.length - 1]?.id ?? undefined
                },
                items: filteredItems,
                formattedItemsToCheckUpdates: filteredFormattedItemsToCheckUpdates
            }
        }),
        postsToUpdate
    };
};
const getAnswerQuestionsHasUpdatedPostsFailure = (state = INITIAL_STATE) => ({ ...state });

const getAnswerQuestionsUpdatedPostsRequest = (state = INITIAL_STATE) => ({ ...state });
const getAnswerQuestionsUpdatedPostsSuccess = (state = INITIAL_STATE, { payload }: IAction<IResponsePayload<IPostResponse[], IUpdatedPostsResponseVariables>>) => {
    const { serverDate } = payload.variables;

    const items = state.posts.items.reduce((accumulator, postItem) => {
        const updatedPost = payload.response.find((updatedPostItem) => updatedPostItem.id === postItem.id);

        if (!!updatedPost) {
            return [...accumulator, updatedPost];
        }

        return [...accumulator, postItem];
    }, [] as IPost[]);

    const formattedItemsToCheckUpdates = state.posts.formattedItemsToCheckUpdates.reduce((accumulator, formattedItemsToCheckUpdatesItem) => {
        const updatedPost = payload.response.find((updatedPostItem) => updatedPostItem.id === formattedItemsToCheckUpdatesItem.IdPost);

        if (!!updatedPost) {
            return [
                ...accumulator,
                {
                    IdPost: updatedPost.id,
                    LastestUpdate: new Date(serverDate).toISOString()
                }
            ];
        }

        return [...accumulator, formattedItemsToCheckUpdatesItem];
    }, [] as IPostsFormattedItemsToCheckUpdates[]);

    return {
        ...state,
        posts: {
            ...state.posts,
            items,
            formattedItemsToCheckUpdates
        },
        postsToUpdate: []
    };
};
const getAnswerQuestionsUpdatedPostsFailure = (state = INITIAL_STATE) => ({ ...state });

const createAnswerQuestionsPostReplyRequest = (state = INITIAL_STATE, { payload }: IAction<ICreateAnswerQuestionsPostReplyPayload>) => ({ ...state, postIdToCreateOrEditAnswers: payload.postId });
const createAnswerQuestionsPostReplySuccess = (state = INITIAL_STATE, { payload }: IAction<IResponsePayload<IPostResponse, ICreatePostReplyResponseVariables>>) => {
    const { postId } = payload.variables;

    const formattedPosts = state.posts.items.map((post) => {
        if (post.id === postId) {
            return {
                ...post,
                posts: [...post.posts, payload.response]
            };
        }

        return post;
    });

    return {
        ...state,
        posts: {
            ...state.posts,
            items: formattedPosts
        },
        postIdToCreateOrEditAnswers: undefined
    };
};
const createAnswerQuestionsPostReplyFailure = (state = INITIAL_STATE) => ({ ...state, postIdToCreateOrEditAnswers: undefined });

const editAnswerQuestionsPostReplyRequest = (state = INITIAL_STATE, { payload }: IAction<IEditAnswerQuestionsPostReplyPayload>) => ({ ...state, postIdToCreateOrEditAnswers: payload.postId });
const editAnswerQuestionsPostReplySuccess = (state = INITIAL_STATE, { payload }: IAction<IResponsePayload<{}, IEditPostReplyResponseVariables>>) => {
    const { replyId, postId, content } = payload.variables;

    const formattedPosts = state.posts.items.map((post) => {
        if (post.id === postId) {
            const formattedReplies = post.posts.map((reply) => {
                if (reply.id === replyId) {
                    return {
                        ...reply,
                        content
                    };
                }

                return reply;
            });

            return {
                ...post,
                posts: formattedReplies
            };
        }

        return post;
    });

    return {
        ...state,
        posts: {
            ...state.posts,
            items: formattedPosts
        },
        postIdToCreateOrEditAnswers: undefined
    };
};
const editAnswerQuestionsPostReplyFailure = (state = INITIAL_STATE) => ({ ...state, postIdToCreateOrEditAnswers: undefined });

const deleteAnswerQuestionsPostReplyRequest = (state = INITIAL_STATE) => ({ ...state });
const deleteAnswerQuestionsPostReplySuccess = (state = INITIAL_STATE, { payload }: IAction<IResponsePayload<{}, IDeletePostReplyResponseVariables>>) => {
    const { replyId, postId } = payload.variables;

    const formattedPosts = state.posts.items.map((post) => {
        if (post.id === postId) {
            const filteredReplies = post.posts.filter((reply) => reply.id !== replyId);

            return {
                ...post,
                posts: filteredReplies
            };
        }

        return post;
    });

    return {
        ...state,
        posts: {
            ...state.posts,
            items: formattedPosts
        }
    };
};
const deleteAnswerQuestionsPostReplyFailure = (state = INITIAL_STATE) => ({ ...state });

const deleteAnswerQuestionsPostRequest = (state = INITIAL_STATE) => ({ ...state });
const deleteAnswerQuestionsPostSuccess = (state = INITIAL_STATE, { payload }: IAction<IResponsePayload<{}, IDeletePostReplyResponseVariables>>) => {
    const { postId } = payload.variables;

    let bookmark: string | undefined = state.posts.filters.bookmark;

    const lastPostId = state.posts.items[state.posts.items.length - 1].id;

    if (postId === lastPostId && state.posts.items.length === 1) {
        bookmark = undefined;
    }

    if (postId === lastPostId && state.posts.items.length > 1) {
        const penultimatePostId = state.posts.items[state.posts.items.length - 2].id;

        bookmark = penultimatePostId;
    }

    const filteredPosts = state.posts.items.filter((item) => item.id !== postId);
    const filteredFormattedItemsToCheckUpdates = state.posts.formattedItemsToCheckUpdates.filter((item) => item.IdPost !== postId);
    const filteredPostsToUpdate = state.postsToUpdate.filter((item) => item !== postId);

    return {
        ...state,
        posts: {
            ...state.posts,
            items: filteredPosts,
            formattedItemsToCheckUpdates: filteredFormattedItemsToCheckUpdates,
            filters: {
                ...state.posts.filters,
                bookmark
            },
            deletedItemsCount: state.posts.deletedItemsCount + 1
        },
        postsToUpdate: filteredPostsToUpdate
    };
};
const deleteAnswerQuestionsPostFailure = (state = INITIAL_STATE) => ({ ...state });

const addAnswerQuestionsPostAnswerAttachmentRequest = (state = INITIAL_STATE, { payload }: IAction<IAddAnswerQuestionsPostAnswerAttachmentPayload>) => ({
    ...state,
    postToAddAttachment: { postId: payload.postId, answerId: payload.answerId }
});

const addAnswerQuestionsPostAnswerAttachmentSuccess = (state = INITIAL_STATE, { payload }: IAction<IResponsePayload<IPostResponse, IAddAnswerQuestionsPostAnswerAttachmentResponseVariables>>) => {
    const { postId, answerId } = payload.variables;

    const formattedPosts = state.posts.items.map((post) => {
        if (post.id === postId) {
            const filteredAnswers = post.posts.map((answer) => {
                if (answer.id === answerId) {
                    return payload.response;
                }

                return answer;
            });

            return {
                ...post,
                posts: filteredAnswers
            };
        }

        return post;
    });

    return {
        ...state,
        posts: {
            ...state.posts,
            items: formattedPosts
        },
        postToAddAttachment: undefined
    };
};
const addAnswerQuestionsPostAnswerAttachmentFailure = (state = INITIAL_STATE) => ({ ...state, postToAddAttachment: undefined });

const deleteAnswerQuestionsPostAnswerAttachmentRequest = (state = INITIAL_STATE) => ({ ...state });
const deleteAnswerQuestionsPostAnswerAttachmentSuccess = (state = INITIAL_STATE, { payload }: IAction<IResponsePayload<{}, IDeleteAnswerQuestionsPostAnswerAttachmentResponseVariables>>) => {
    const { postId, answerId } = payload.variables;

    const formattedPosts = state.posts.items.map((post) => {
        if (post.id === postId) {
            const filteredAnswers = post.posts.map((answer) => {
                if (answer.id === answerId && !!answer.attachment) {
                    delete answer.attachment;
                }

                return answer;
            });

            return {
                ...post,
                posts: filteredAnswers
            };
        }

        return post;
    });

    return {
        ...state,
        posts: {
            ...state.posts,
            items: formattedPosts
        }
    };
};
const deleteAnswerQuestionsPostAnswerAttachmentFailure = (state = INITIAL_STATE) => ({ ...state });

const clearAnswerQuestions = (state = INITIAL_STATE) => ({ ...state, ...INITIAL_STATE });

export default createReducer(INITIAL_STATE, {
    [Types.GET_ANSWER_QUESTIONS_TOPIC_REQUEST]: getAnswerQuestionsTopicRequest,
    [Types.GET_ANSWER_QUESTIONS_TOPIC_SUCCESS]: getAnswerQuestionsTopicSuccess,
    [Types.GET_ANSWER_QUESTIONS_TOPIC_FAILURE]: getAnswerQuestionsTopicFailure,

    [Types.GET_ANSWER_QUESTIONS_POSTS_REQUEST]: getAnswerQuestionsPostsRequest,
    [Types.GET_ANSWER_QUESTIONS_POSTS_SUCCESS]: getAnswerQuestionsPostsSuccess,
    [Types.GET_ANSWER_QUESTIONS_POSTS_FAILURE]: getAnswerQuestionsPostsFailure,

    [Types.GET_ANSWER_QUESTIONS_HAS_NEW_POSTS_REQUEST]: getAnswerQuestionsHasNewPostsRequest,
    [Types.GET_ANSWER_QUESTIONS_HAS_NEW_POSTS_SUCCESS]: getAnswerQuestionsHasNewPostsSuccess,
    [Types.GET_ANSWER_QUESTIONS_HAS_NEW_POSTS_FAILURE]: getAnswerQuestionsHasNewPostsFailure,

    [Types.GET_ANSWER_QUESTIONS_HAS_UPDATED_POSTS_REQUEST]: getAnswerQuestionsHasUpdatedPostsRequest,
    [Types.GET_ANSWER_QUESTIONS_HAS_UPDATED_POSTS_SUCCESS]: getAnswerQuestionsHasUpdatedPostsSuccess,
    [Types.GET_ANSWER_QUESTIONS_HAS_UPDATED_POSTS_FAILURE]: getAnswerQuestionsHasUpdatedPostsFailure,

    [Types.GET_ANSWER_QUESTIONS_UPDATED_POSTS_REQUEST]: getAnswerQuestionsUpdatedPostsRequest,
    [Types.GET_ANSWER_QUESTIONS_UPDATED_POSTS_SUCCESS]: getAnswerQuestionsUpdatedPostsSuccess,
    [Types.GET_ANSWER_QUESTIONS_UPDATED_POSTS_FAILURE]: getAnswerQuestionsUpdatedPostsFailure,

    [Types.CREATE_ANSWER_QUESTIONS_POST_REPLY_REQUEST]: createAnswerQuestionsPostReplyRequest,
    [Types.CREATE_ANSWER_QUESTIONS_POST_REPLY_SUCCESS]: createAnswerQuestionsPostReplySuccess,
    [Types.CREATE_ANSWER_QUESTIONS_POST_REPLY_FAILURE]: createAnswerQuestionsPostReplyFailure,

    [Types.EDIT_ANSWER_QUESTIONS_POST_REPLY_REQUEST]: editAnswerQuestionsPostReplyRequest,
    [Types.EDIT_ANSWER_QUESTIONS_POST_REPLY_SUCCESS]: editAnswerQuestionsPostReplySuccess,
    [Types.EDIT_ANSWER_QUESTIONS_POST_REPLY_FAILURE]: editAnswerQuestionsPostReplyFailure,

    [Types.DELETE_ANSWER_QUESTIONS_POST_REPLY_REQUEST]: deleteAnswerQuestionsPostReplyRequest,
    [Types.DELETE_ANSWER_QUESTIONS_POST_REPLY_SUCCESS]: deleteAnswerQuestionsPostReplySuccess,
    [Types.DELETE_ANSWER_QUESTIONS_POST_REPLY_FAILURE]: deleteAnswerQuestionsPostReplyFailure,

    [Types.DELETE_ANSWER_QUESTIONS_POST_REQUEST]: deleteAnswerQuestionsPostRequest,
    [Types.DELETE_ANSWER_QUESTIONS_POST_SUCCESS]: deleteAnswerQuestionsPostSuccess,
    [Types.DELETE_ANSWER_QUESTIONS_POST_FAILURE]: deleteAnswerQuestionsPostFailure,

    [Types.ADD_ANSWER_QUESTIONS_POST_ANSWER_ATTACHMENT_REQUEST]: addAnswerQuestionsPostAnswerAttachmentRequest,
    [Types.ADD_ANSWER_QUESTIONS_POST_ANSWER_ATTACHMENT_SUCCESS]: addAnswerQuestionsPostAnswerAttachmentSuccess,
    [Types.ADD_ANSWER_QUESTIONS_POST_ANSWER_ATTACHMENT_FAILURE]: addAnswerQuestionsPostAnswerAttachmentFailure,

    [Types.DELETE_ANSWER_QUESTIONS_POST_ANSWER_ATTACHMENT_REQUEST]: deleteAnswerQuestionsPostAnswerAttachmentRequest,
    [Types.DELETE_ANSWER_QUESTIONS_POST_ANSWER_ATTACHMENT_SUCCESS]: deleteAnswerQuestionsPostAnswerAttachmentSuccess,
    [Types.DELETE_ANSWER_QUESTIONS_POST_ANSWER_ATTACHMENT_FAILURE]: deleteAnswerQuestionsPostAnswerAttachmentFailure,

    [Types.CLEAR_ANSWER_QUESTIONS]: clearAnswerQuestions
});
