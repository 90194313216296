enum VideoPlatform {
    Youtube,
    Vimeo,
    VZaar,
    LiveStream,
    Embed,
    Prodigio,
    VimeoLive
}
export default VideoPlatform;
