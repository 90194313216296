import styled, { css } from 'styled-components';
import { FadeIn, FadeInUp } from 'assets/animations';
import theme from 'config/theme';
import { Title, Subtitle, SmallTitle } from 'assets/global';
import { ButtonContainer } from 'components/button/styles';
import hexToRgba from 'utils/hexToRgba';
import { BadgeContainer } from 'components/badge/styles';

export const QuestionsAlternativesLetter = styled.strong`
    align-items: center;
    background: ${theme.colors.borderColor};
    border-radius: 50%;
    color: ${theme.colors.base};
    display: flex;
    font-family: ${theme.typography.family.primary.bold.regular};
    justify-content: center;
    height: 35px;
    margin-right: 15px;
    text-transform: uppercase;
    width: 35px;
`;

export const QuestionAlternativeWrap = styled.div<{ isCorrect?: boolean }>`
    align-items: center;
    animation: 0.5s ${FadeInUp};
    display: flex;
    padding: 15px;
    position: relative;

    ${BadgeContainer} {
        bottom: 15px;
        position: absolute;
        right: 15px;
    }

    ${({ isCorrect }) => {
        if (isCorrect) {
            return css`
                box-shadow: 0px 0px 10px 0px #e2e2e2;

                ${QuestionsAlternativesLetter} {
                    background: ${hexToRgba(theme.colors.system.success, 0.25)};
                    color: ${theme.colors.system.success};
                }
            `;
        }

        return;
    }}

    &:not(:last-child) {
        border-bottom: 1px solid rgb(240, 240, 242);
    }

    &:hover {
        background: rgba(129, 140, 157, 0.05);
    }

    ${ButtonContainer} {
        padding: 10px;
    }
`;

export const QuestionAlternativePreview = styled.div`
    flex: 1;
`;

export const QuestionAlternativeWarning = styled.div<{ variant: 'success' | 'danger' | 'warning' }>`
    background: ${(props) => props.variant && theme.colors.system[props.variant]};
    padding: 15px;
    color: #fff;
    margin-top: 10px;
    align-items: center;
    display: flex;

    svg {
        margin-right: 15px;
        fill: #fff;
    }
`;

export const QuestionPreviewContent = styled.div`
    animation: ${FadeIn} 0.5s ease;
    background: ${theme.colors.white};
    overflow-y: auto;
    padding: 30px;
    width: 100%;
    flex: 1;

    ${QuestionAlternativeWrap} {
        animation: none;
    }

    ${Title} {
        font-size: ${theme.typography.sizes.normalXX};
        line-height: 1.2;
    }

    > ${ButtonContainer} {
        display: flex;
        margin-bottom: 15px;

        > svg {
            margin-right: 10px;
            transform: rotate(90deg);
        }
    }
`;

export const QuestionPreviewContainer = styled.div<{ isOpen: boolean }>`
    animation: ${FadeIn} 0.2s;
    background: rgba(0, 0, 0, 0.4);
    left: 0;
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 12;

    ${({ isOpen }) => {
        if (isOpen) {
            return css`
                display: block;
            `;
        }

        return css`
            display: none;
        `;
    }}
`;

export const QuestionPreviewHeader = styled.header`
    margin-bottom: 20px;

    div {
        ${BadgeContainer} {
            :first-child {
                margin-left: 0;
            }
        }
    }
`;

export const QuestionPreviewBody = styled.div``;

export const QuestionAlternativeMeta = styled.div`
    font-size: 14px;

    span {
        font-family: ${theme.typography.family.primary.light.italic};
    }

    strong {
        color: ${theme.colors.system.info};
        font-family: ${theme.typography.family.primary.bold.italic};
    }

    &:not(:last-child) {
        margin-top: ${theme.spacing.small.all};
    }
`;

export const QuestionAnswer = styled.div`
    ${Title},
    ${Subtitle} {
        display: block;
        margin-bottom: ${theme.spacing.medium.all};
    }

    &:not(:last-child) {
        border-bottom: 1px solid ${theme.colors.borderColor};
        margin-bottom: ${theme.spacing.large.all};
        padding-bottom: ${theme.spacing.large.all};
    }
`;

export const QuestionAnswerPoints = styled.div`
    align-items: center;
    display: flex;

    ${Title} {
        margin: 0 20px 0 0;
    }
`;

export const QuestionAnswerBox = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 18px;
    justify-content: center;
    min-height: 60px;
    padding: 15px 30px;
    position: relative;

    p {
        font-family: ${theme.typography.family.primary.normal.italic};
        position: relative;
        z-index: 1;

        &:not(:last-child) {
            margin-bottom: ${theme.spacing.medium.all};
        }
    }

    &:before,
    &:after {
        color: #eaeaea;
        font-size: 110px;
        height: 40px;
        line-height: 1;
        position: absolute;
        z-index: 0;
    }

    &:before {
        content: open-quote;
        left: 0;
        top: 0;
    }

    &:after {
        bottom: 5px;
        content: close-quote;
        right: 8px;
    }
`;

export const QuestionTeacherAnswerWrapper = styled.div`
    display: flex;
    justify-content: space-between;

    > div {
        flex: 1;

        &:first-child {
            padding-right: ${theme.spacing.small.all};
        }

        &:last-child {
            display: flex;
            flex-direction: column;
            padding-left: ${theme.spacing.small.all};
        }
    }
`;

export const QuestionTeacherAnswerItem = styled.div`
    align-items: center;
    background: transparent;
    border-radius: 3px;
    display: flex;
    flex: 1;
    justify-content: space-between;
    padding: ${theme.spacing.small.all};
    transition: background 0.2s ease;

    > div {
        padding: 10px 0 0 40px;
        flex: 1;
    }

    ${SmallTitle} {
        span {
            color: ${theme.colors.base};
            display: block;
            font-family: ${theme.typography.family.primary.light.italic};
            font-size: 12px;
        }
    }

    &:hover {
        background: #f5f5f5;
    }
`;

export const QuestionEnunciation = styled.div`
    p {
        line-height: 1.5;

        &:not(:last-child) {
            margin-bottom: ${theme.spacing.medium.all};
        }
    }
`;

export const QuestionResult = styled.div`
    align-items: center;
    border-bottom: 1px solid ${theme.colors.borderColor};
    display: flex;
    justify-content: space-between;
    margin-bottom: ${theme.spacing.large.all};
    padding-bottom: ${theme.spacing.large.all};

    ${Subtitle} {
        margin-right: ${theme.spacing.small.all};
    }

    .ico-check {
        fill: ${theme.colors.system.success};
    }

    .ico-close {
        fill: ${theme.colors.system.danger};
    }

    > div {
        align-items: center;
        display: flex;
    }
`;
