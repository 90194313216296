import { createActions, createReducer } from 'reduxsauce';
import { IExerciseList } from 'utils/interfaces/IExerciseList';
import { IPagination } from 'utils/interfaces/IPagination';
import { IPerson } from 'utils/interfaces/IPerson';

export const { Creators, Types } = createActions({
    getExerciseListRequest: ['payload'],
    getExerciseListSuccess: ['payload'],
    getExerciseListFailure: ['payload'],

    searchExerciseListsRequest: ['payload'],
    searchExerciseListsSuccess: ['payload'],
    searchExerciseListsFailure: ['payload'],

    createOrEditExerciseListRequest: ['payload'],
    createOrEditExerciseListSuccess: ['payload'],
    createOrEditExerciseListFailure: ['payload'],

    deleteExerciseListRequest: ['payload'],
    deleteExerciseListFailure: [],

    createExerciseListItemRequest: ['payload'],
    createExerciseListItemFailure: [],

    sendExerciseListToStudentsRequest: ['payload'],
    sendExerciseListToStudentsFailure: [],

    deleteExerciseListItemRequest: ['payload'],
    deleteExerciseListItemFailure: [],

    getExerciseListStudentsRequest: ['payload'],
    getExerciseListStudentsSuccess: ['payload'],
    getExerciseListStudentsFailure: [],

    cancelExerciseListItemRequest: ['payload'],
    cancelExerciseListItemSuccess: ['payload'],
    cancelExerciseListItemFailure: [],

    deleteExerciseListStudentRequest: ['payload'],
    deleteExerciseListStudentSuccess: ['payload'],
    deleteExerciseListStudentFailure: ['payload'],

    clearExerciseList: []
});

export type IExerciseListState = IExerciseList & {
    isLoading: boolean;
    students: IPagination<{
        id: number;
        person: IPerson;
    }>;
};

const INITIAL_STATE: IExerciseListState = {
    id: 0,
    code: '',
    title: '',
    slug: '',
    durationDefault: '',
    description: '',
    realizationsTotal: 0,
    realizationsPerStudent: 0,
    realizationsAverageHitRate: 0,
    questionsTotal: 0,
    items: [],
    points: 0,
    isLoading: false,
    type: 1,
    exerciseListStudent: [],
    students: {
        count: 0,
        items: [],
        page: 0,
        totalItems: 0,
        totalPages: 0
    },
    startDate: null,
    endDate: null,
    isOpen: false,
    realizations: [],
    learningObject: {
        id: 0,
        depthLevel: 0
    }
};

// Exercise list request
const exerciseListRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true });

// Exercise list failure
const exerciseListFailure = (state = INITIAL_STATE) => ({ ...state, isLoading: false });

// Ger exercise list success
const getExerciseListSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    ...action.payload,
    isLoading: false
});

const searchExerciseListsRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true });

const searchExerciseListsFailure = (state = INITIAL_STATE) => ({ ...state, isLoading: false });

const searchExerciseListsSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    items: action.payload.items,
    isLoading: false
});

// Create or edit exercise list success
const createOrEditExerciseListSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    ...action.payload,
    isLoading: false
});

// Get exercise list students
const getExerciseListStudentsSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    students: action.payload,
    isLoading: false
});

// Clear current exercise list
const clearExerciseList = (state = INITIAL_STATE) => ({ ...state, ...INITIAL_STATE });

const cancelExerciseListItemSuccess = (state = INITIAL_STATE, action: any) => {
    const { id, isCanceled } = action.payload;

    const updateItems = state.items.map((item) => (item.id === id ? { ...item, isCanceled } : item));

    return {
        ...state,
        items: updateItems,
        isLoading: false
    };
};

const deleteStudent = (state = INITIAL_STATE, action: any) => {
    console.log('action', action);

    const { studentId } = action.payload;

    if (!studentId) {
        return { ...state, isLoading: false };
    }

    const updateStudents = state.students.items.filter((student) => student.id !== studentId);

    return {
        ...state,
        isLoading: false,
        students: {
            ...state.students,
            items: updateStudents
        }
    };
};

export default createReducer(INITIAL_STATE, {
    [Types.GET_EXERCISE_LIST_REQUEST]: exerciseListRequest,
    [Types.GET_EXERCISE_LIST_SUCCESS]: getExerciseListSuccess,
    [Types.GET_EXERCISE_LIST_FAILURE]: exerciseListFailure,

    [Types.SEARCH_EXERCISE_LISTS_REQUEST]: searchExerciseListsRequest,
    [Types.SEARCH_EXERCISE_LISTS_SUCCESS]: searchExerciseListsSuccess,
    [Types.SEARCH_EXERCISE_LISTS_FAILURE]: searchExerciseListsFailure,

    [Types.CREATE_OR_EDIT_EXERCISE_LIST_REQUEST]: exerciseListRequest,
    [Types.CREATE_OR_EDIT_EXERCISE_LIST_SUCCESS]: createOrEditExerciseListSuccess,
    [Types.CREATE_OR_EDIT_EXERCISE_LIST_FAILURE]: exerciseListFailure,

    [Types.DELETE_EXERCISE_LIST_REQUEST]: exerciseListRequest,
    [Types.DELETE_EXERCISE_LIST_FAILURE]: exerciseListFailure,

    [Types.CREATE_EXERCISE_LIST_ITEM_REQUEST]: exerciseListRequest,
    [Types.CREATE_EXERCISE_LIST_ITEM_FAILURE]: exerciseListFailure,

    [Types.DELETE_EXERCISE_LIST_ITEM_REQUEST]: exerciseListRequest,
    [Types.DELETE_EXERCISE_LIST_ITEM_FAILURE]: exerciseListFailure,

    [Types.SEND_EXERCISE_LIST_TO_STUDENTS_REQUEST]: exerciseListRequest,
    [Types.SEND_EXERCISE_LIST_TO_STUDENTS_FAILURE]: exerciseListFailure,

    [Types.GET_EXERCISE_LIST_STUDENTS_REQUEST]: exerciseListRequest,
    [Types.GET_EXERCISE_LIST_STUDENTS_SUCCESS]: getExerciseListStudentsSuccess,
    [Types.GET_EXERCISE_LIST_STUDENTS_FAILURE]: exerciseListFailure,

    [Types.CANCEL_EXERCISE_LIST_ITEM_REQUEST]: exerciseListRequest,
    [Types.CANCEL_EXERCISE_LIST_ITEM_SUCCESS]: cancelExerciseListItemSuccess,
    [Types.CANCEL_EXERCISE_LIST_ITEM_FAILURE]: exerciseListFailure,

    [Types.DELETE_EXERCISE_LIST_STUDENT_REQUEST]: exerciseListRequest,
    [Types.DELETE_EXERCISE_LIST_STUDENT_SUCCESS]: deleteStudent,
    [Types.DELETE_EXERCISE_LIST_STUDENT_FAILURE]: exerciseListFailure,

    [Types.CLEAR_EXERCISE_LIST]: clearExerciseList
});
