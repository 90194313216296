import { createActions, createReducer } from 'reduxsauce';

export const { Creators, Types } = createActions({
    createOrEditLessonPlanRequest: ['payload'],
    createOrEditLessonPlanSuccess: ['payload'],
    createOrEditLessonPlanFailure: [],

    getLessonPlanRequest: ['payload'],
    getLessonPlanSuccess: ['payload'],
    getLessonPlanFailure: [],

    clearLessonPlan: []
});

export interface ILessonPlanState {
    isLoading: boolean;
    id: number;
    name: string;
    course?: {
        id: number;
        name: string;
        slug: string;
    };
}

const INITIAL_STATE: ILessonPlanState = {
    isLoading: false,
    id: 0,
    name: ''
};

// CREATE OR EDIT LESSON PLAN
const createOrEditLessonPlanRequest = (state = INITIAL_STATE, action: any) => ({ ...state, isLoading: true });
const createOrEditLessonPlanSuccess = (state = INITIAL_STATE, action: any) => ({ ...state, isLoading: false, ...action.payload });
const createOrEditLessonPlanFailure = (state = INITIAL_STATE) => ({ ...state, isLoading: false });

// GET LESSON PLAN
const getLessonPlanRequest = (state = INITIAL_STATE, action: any) => ({ ...state, isLoading: true });
const getLessonPlanSuccess = (state = INITIAL_STATE, action: any) => ({ ...state, isLoading: false, ...action.payload });
const getLessonPlanFailure = (state = INITIAL_STATE) => ({ ...state, ...INITIAL_STATE, isLoading: false });

// Clear
const clearLessonPlan = (state = INITIAL_STATE) => INITIAL_STATE;

export default createReducer(INITIAL_STATE, {
    // Lesson Plan
    [Types.CREATE_OR_EDIT_LESSON_PLAN_REQUEST]: createOrEditLessonPlanRequest,
    [Types.CREATE_OR_EDIT_LESSON_PLAN_SUCCESS]: createOrEditLessonPlanSuccess,
    [Types.CREATE_OR_EDIT_LESSON_PLAN_FAILURE]: createOrEditLessonPlanFailure,

    [Types.GET_LESSON_PLAN_REQUEST]: getLessonPlanRequest,
    [Types.GET_LESSON_PLAN_SUCCESS]: getLessonPlanSuccess,
    [Types.GET_LESSON_PLAN_FAILURE]: getLessonPlanFailure,

    [Types.CLEAR_LESSON_PLAN]: clearLessonPlan
});
