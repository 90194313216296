import moment from 'moment';

/**
 * @description Helper para conversão de data
 *
 * @param {string} format Formato de saída de data desejado
 * @param {number} date Data à ser convertida
 * @returns {string} Retorna uma string com a data formatada
 */
const formatDate = (format: string, date?: string | Date) => (moment(date).isValid() ? moment.utc(date).format(format) : '-');

export default formatDate;
