import { lazy } from 'react';

const List = lazy(() => import('pages/crud'));
const Brand = lazy(() => import('pages/brand'));

const brandRoutes = [
    {
        path: '/app/marcas',
        name: 'Marcas',
        component: List,
        exact: true,
        title: 'Marcas'
    },
    {
        path: '/app/marcas/novo',
        name: 'Novo',
        component: Brand,
        exact: true,
        title: 'Nova marca'
    },
    {
        path: '/app/marcas/editar/:id',
        name: 'Editar',
        component: Brand,
        exact: true,
        title: 'Editar marca'
    }
];

export default brandRoutes;
