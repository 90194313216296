import theme from 'config/theme';
import { IPropsButton } from '.';
import hexToRgba from 'utils/hexToRgba';
import { rotate } from 'assets/animations';
import styled, { css } from 'styled-components/macro';
import { TooltipContainer } from 'components/tooltip/styles';
import lightenDarkenColor from 'utils/lightAndDarkColor';
import { ButtonHTMLAttributes } from 'react';

export type Variant =
    | 'primary'
    | 'secondary'
    | 'success'
    | 'danger'
    | 'warning'
    | 'info'
    | 'link'
    | 'outline-primary'
    | 'outline-secondary'
    | 'outline-success'
    | 'outline-danger'
    | 'outline-warning'
    | 'outline-info'
    | 'outline-base';

export const defineBgColor = (type: Variant) => {
    if (type === 'primary') {
        return theme.colors.primary;
    } else if (type === 'secondary') {
        return theme.colors.secondary;
    } else if (type === 'success') {
        return theme.colors.system.success;
    } else if (type === 'danger') {
        return theme.colors.system.danger;
    } else if (type === 'warning') {
        return theme.colors.system.warning;
    } else if (type === 'info') {
        return theme.colors.system.info;
    } else {
        return 'transparent';
    }
};

export const defineHoverBgColor = (type: Variant) => {
    if (type.includes('outline')) {
        const variant = type.split('-');

        if (variant[1] === 'success' || variant[1] === 'danger' || variant[1] === 'warning' || variant[1] === 'info') {
            return hexToRgba(theme.colors.system[variant[1]], 0.2);
        }

        return hexToRgba(theme.colors[variant[1]], 0.2);
    }

    if (type === 'success' || type === 'danger' || type === 'warning' || type === 'info') {
        return lightenDarkenColor(theme.colors.system[type], -10);
    }

    return lightenDarkenColor(theme.colors[type], -10);
};

export const defineTextColor = (type: Variant) => {
    if (type === 'outline-info') {
        return theme.colors.system.info;
    } else if (type === 'outline-warning') {
        return theme.colors.system.warning;
    } else if (type === 'outline-danger') {
        return theme.colors.system.danger;
    } else if (type === 'outline-success') {
        return theme.colors.system.success;
    } else if (type === 'outline-secondary') {
        return theme.colors.secondary;
    } else if (type === 'outline-primary') {
        return theme.colors.primary;
    } else if (type === 'outline-base') {
        return theme.colors.base;
    } else {
        return theme.colors.white;
    }
};

export const ButtonStyles = css`
    align-items: center;
    display: flex;
    font-family: ${theme.typography.family.primary.bold.regular};
    justify-content: center;
    line-height: 1;
    text-align: center;
    transition: all 0.2s ease;
    user-select: none;
    vertical-align: middle;
    white-space: nowrap;
    background-color: ${({ variant }) => defineBgColor(variant!)};
    border-radius: ${({ rounded }) => (rounded ? '50%' : '0.25rem')};
    color: ${({ variant }) => defineTextColor(variant!)};
    cursor: ${({ disabled }) => (disabled ? 'no-drop' : 'pointer')} !important;
    display: ${({ withIcon }) => (withIcon ? 'flex' : 'inline-block')};
    margin-top: ${({ gutterTop }) => gutterTop && theme.spacing.large.all};
    margin-bottom: ${({ gutterBottom }) => gutterBottom && theme.spacing.large.all};
    position: relative;

    svg {
        fill: ${({ variant }) => defineTextColor(variant!)};
        stroke: ${({ variant }) => defineTextColor(variant!)};

        ${({ isLoading }) => {
            if (isLoading) {
                return css`
                    animation: ${rotate} 1s linear infinite;
                    margin: 0 auto;
                `;
            }

            return;
        }}
    }

    span {
        margin-right: 5px;
    }

    &:active,
    &:focus {
        box-shadow: ${({ variant }) => {
            const hex = defineBgColor(variant!);

            return `0px 0px 0px 4px ${hexToRgba(hex, 0.5)}`;
        }};
    }

    &:disabled {
        background: #dddddd;
        color: rgba(29, 28, 29, 0.75);

        svg {
            fill: ${theme.colors.baseDark};
            stroke: ${theme.colors.baseDark};
        }
    }

    ${({ block }: IPropsButton) => {
        if (block) {
            return {
                display: 'block',
                width: '100%'
            };
        }

        return;
    }};

    ${({ size }) => {
        if (size === 'small') {
            return {
                fontSize: theme.typography.sizes.small,
                padding: '10px'
            };
        } else if (size === 'large') {
            return {
                fontSize: theme.typography.sizes.large,
                padding: '20px'
            };
        }

        return {
            fontSize: theme.typography.sizes.normal,
            padding: '15px'
        };
    }};

    &:hover {
        background: ${({ disabled, variant }) => (disabled ? '#dddddd' : defineHoverBgColor(variant!))};

        ${TooltipContainer} {
            opacity: 1;
            visibility: visible;
        }
    }
`;

export const ButtonContainer = styled.button<IPropsButton & ButtonHTMLAttributes<HTMLButtonElement>>`
    ${ButtonStyles}
`;
