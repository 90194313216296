import { lazy } from 'react';

const List = lazy(() => import('pages/crud'));
const Teachers = lazy(() => import('pages/teachers'));
const TeacherView = lazy(() => import('pages/teachers/view'));

const brandRoutes = [
    {
        path: '/app/professores',
        name: 'Professores',
        component: List,
        exact: true,
        title: 'Professores'
    },
    {
        path: '/app/professores/novo',
        name: 'Novo',
        component: Teachers,
        exact: true,
        title: 'Novo Professor'
    },
    {
        path: '/app/professores/editar/:id',
        name: 'Editar',
        component: Teachers,
        exact: true,
        title: 'Editar Professor'
    },
    {
        path: '/app/professores/ver/:id',
        name: 'Visualizar',
        component: TeacherView,
        exact: true,
        title: 'Visualizar Professor'
    }
];

export default brandRoutes;
