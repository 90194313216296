import { lazy } from 'react';

const List = lazy(() => import('pages/crud'));

const qrcodesHistoryRoutes = [
    {
        path: '/app/qrcodes-historico',
        name: 'Histórico de QR Codes',
        component: List,
        exact: true,
        title: 'Histórico de QR Codes'
    }
];

export default qrcodesHistoryRoutes;
