import { createReducer, createActions } from 'reduxsauce';
import { ExamFilesTypes, IExamFilesActionsCreators, IExamFilesState } from './types';
import { IReduxAction } from 'store/types/IRedux';
import { IImportFile } from 'utils/interfaces/ImportFile';
import { ImportFileStatus } from 'utils/enums/ImportFileStatus';
import { v4 as uuidv4 } from 'uuid';
import { ISelect } from 'utils/interfaces/IReactSelect';

export const { Creators, Types } = createActions<Record<ExamFilesTypes, ExamFilesTypes>, IExamFilesActionsCreators>({
    addFiles: ['payload'],
    removeFile: ['payload'],

    uploadExamFileRequest: ['payload'],
    uploadExamFileSuccess: ['payload'],
    uploadExamFileFailure: ['payload'],

    cancelExamFileUploadRequest: ['payload'],
    cancelExamFileUploadSuccess: ['payload'],

    startExamFileProcessingSocketRequest: ['payload'],
    startExamFileProcessingSocketSuccess: ['payload'],
    startExamFileProcessingSocketFailure: ['payload'],

    setFileSocketInstance: ['payload'],

    setExamFileProgress: ['payload'],

    setExamFileCancelToken: ['payload'],

    closeFileSocketConnection: ['payload'],

    uploadAllExamFiles: [],

    clearExamFiles: [],

    setFileBrand: ['payload']
});

const INITIAL_STATE: IExamFilesState = {
    files: [],
    brand: undefined
};

const clear = () => INITIAL_STATE;

const addFiles = (state = INITIAL_STATE, action: IReduxAction<File[]>) => {
    const newFiles: IImportFile[] = action.payload.map((file) => ({ file, uuid: uuidv4(), status: ImportFileStatus.Pending, progress: 0 }));

    return {
        ...state,
        files: [...state.files, ...newFiles]
    };
};

const removeFile = (state = INITIAL_STATE, action: IReduxAction<{ uuid: string }>) => {
    const updateFiles: IImportFile[] = state.files.filter(({ uuid }) => uuid !== action.payload.uuid);

    return {
        ...state,
        files: updateFiles
    };
};

const uploadFileRequest = (state = INITIAL_STATE, action: IReduxAction<{ uuid: string }>) => {
    const updateFiles: IImportFile[] = state.files.map((file) => {
        if (file.uuid === action.payload.uuid) {
            return { ...file, status: ImportFileStatus.Starting, cancelToken: undefined, progress: 0, errorMsg: undefined };
        }

        return file;
    });

    return {
        ...state,
        files: updateFiles
    };
};

const uploadFileSuccess = (state = INITIAL_STATE, action: IReduxAction<{ uuid: string }>) => {
    const updateFiles: IImportFile[] = state.files.map((file) => {
        if (file.uuid === action.payload.uuid) {
            return { ...file, status: ImportFileStatus.Processing, progress: 0, cancelToken: undefined };
        }

        return file;
    });

    return {
        ...state,
        files: updateFiles
    };
};

const uploadFileFailure = (state = INITIAL_STATE, action: IReduxAction<{ uuid: string; status?: ImportFileStatus }>) => {
    const updateFiles: IImportFile[] = state.files.map((file) => {
        if (file.uuid === action.payload.uuid) {
            return { ...file, status: action.payload.status ?? ImportFileStatus.UploadError, progress: 0, backgroundId: undefined, cancelToken: undefined };
        }

        return file;
    });

    return {
        ...state,
        files: updateFiles
    };
};

const setFileProgress = (state = INITIAL_STATE, action: IReduxAction<{ uuid: string; progress: number; status: ImportFileStatus }>) => {
    const updateFiles: IImportFile[] = state.files.map((file) => {
        if (file.uuid === action.payload.uuid) {
            return { ...file, progress: ~~action.payload.progress, status: action.payload.status ?? ImportFileStatus.Uploading };
        }

        return file;
    });

    return {
        ...state,
        files: updateFiles
    };
};

const setCancelToken = (state = INITIAL_STATE, action: IReduxAction<{ uuid: string; cancelToken: any }>) => {
    const updateFiles: IImportFile[] = state.files.map((file) => {
        if (file.uuid === action.payload.uuid) {
            return { ...file, cancelToken: action.payload.cancelToken };
        }

        return file;
    });

    return {
        ...state,
        files: updateFiles
    };
};

const cancelUpload = (state = INITIAL_STATE, action: IReduxAction<{ uuid: string }>) => {
    const updateFiles: IImportFile[] = state.files.map((file) => {
        if (file.uuid === action.payload.uuid) {
            return { ...file, status: ImportFileStatus.UploadCanceled, progress: 0, cancelToken: undefined };
        }

        return file;
    });

    return {
        ...state,
        files: updateFiles
    };
};

const chore = (state = INITIAL_STATE, _: any) => state;

const startSocket = (state = INITIAL_STATE, action: IReduxAction<{ uuid: string; backgroundId: number }>) => {
    const updateFiles: IImportFile[] = state.files.map((file) => {
        if (file.uuid === action.payload.uuid) {
            return { ...file, status: ImportFileStatus.Processing, progress: 0, cancelToken: undefined };
        }

        return file;
    });

    return {
        ...state,
        files: updateFiles
    };
};

const finishSocket = (state = INITIAL_STATE, action: IReduxAction<{ uuid: string }>) => {
    const updateFiles: IImportFile[] = state.files.map((file) => {
        if (file.uuid === action.payload.uuid) {
            return { ...file, status: ImportFileStatus.Complete, progress: 0, cancelToken: undefined };
        }

        return file;
    });

    return {
        ...state,
        files: updateFiles
    };
};

const errorSocket = (state = INITIAL_STATE, action: IReduxAction<{ uuid: string; msg?: string }>) => {
    const updateFiles: IImportFile[] = state.files.map((file) => {
        if (file.uuid === action.payload.uuid) {
            return { ...file, status: ImportFileStatus.SocketError, progress: 0, cancelToken: undefined, errorMsg: action.payload.msg };
        }

        return file;
    });

    return {
        ...state,
        files: updateFiles
    };
};

const setSocketInstance = (state = INITIAL_STATE, action: IReduxAction<{ uuid: string; socketInstance: any }>) => {
    const updateFiles: IImportFile[] = state.files.map((file) => {
        if (file.uuid === action.payload.uuid) {
            return { ...file, socketInstance: action.payload.socketInstance };
        }

        return file;
    });

    return {
        ...state,
        files: updateFiles
    };
};

const setBrand = (state = INITIAL_STATE, action: IReduxAction<ISelect>) => {
    return {
        ...state,
        brand: action.payload
    };
};

const HANDLERS = {
    [Types.ADD_FILES]: addFiles,
    [Types.REMOVE_FILE]: removeFile,

    [Types.UPLOAD_EXAM_FILE_REQUEST]: uploadFileRequest,
    [Types.UPLOAD_EXAM_FILE_SUCCESS]: uploadFileSuccess,
    [Types.UPLOAD_EXAM_FILE_FAILURE]: uploadFileFailure,

    [Types.SET_EXAM_FILE_PROGRESS]: setFileProgress,
    [Types.SET_EXAM_FILE_CANCEL_TOKEN]: setCancelToken,

    [Types.CANCEL_EXAM_FILE_UPLOAD_REQUEST]: chore,
    [Types.CANCEL_EXAM_FILE_UPLOAD_SUCCESS]: cancelUpload,

    [Types.START_EXAM_FILE_PROCESSING_SOCKET_REQUEST]: startSocket,
    [Types.START_EXAM_FILE_PROCESSING_SOCKET_SUCCESS]: finishSocket,
    [Types.START_EXAM_FILE_PROCESSING_SOCKET_FAILURE]: errorSocket,

    [Types.SET_FILE_SOCKET_INSTANCE]: setSocketInstance,

    [Types.CLOSE_FILE_SOCKET_CONNECTION]: chore,

    [Types.UPLOAD_ALL_EXAM_FILES]: chore,

    [Types.CLEAR_EXAM_FILES]: clear,

    [Types.SET_FILE_BRAND]: setBrand
};

export default createReducer(INITIAL_STATE, HANDLERS);
