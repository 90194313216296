export const editSellerAddress = `
mutation (
    $data: prodigio_address_set_input, 
    $id: Int
) {
    item:update_prodigio_address(
        where: {
            id: {
                _eq: $id
            }
        }, 
        _set: $data
    ) {
      affected_rows
      returning {
        id
      }
    }
}
`;
