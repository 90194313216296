import { IField } from 'utils/interfaces/IField';
import InputTypes from 'utils/enums/inputTypes';

const pages: IField[] = [
    {
        presentation: 'Nome',
        label: 'Nome da Página',
        name: 'name',
        type: InputTypes.text,
        columns: 3
    },
    {
        presentation: 'Link',
        label: 'Link',
        name: 'path',
        type: InputTypes.text,
        columns: 3
    }
];

export default pages;
