import React, { memo } from 'react';
import NavList from 'components/NavList';
import { CardContainer } from 'components/card/styles';
import { StoreNavOptions } from 'pages/store/details';

interface IProps {
    onClick(option: StoreNavOptions): void;
    activeEntity: StoreNavOptions;
}

const StoreNav = memo(({ onClick, activeEntity }: IProps) => {
    const navOptions = [
        {
            id: StoreNavOptions.Orders,
            title: 'Pedidos',
            onClick: () => onClick(StoreNavOptions.Orders),
            active: activeEntity === StoreNavOptions.Orders
        },
        {
            id: StoreNavOptions.Unities,
            title: 'Unidades',
            onClick: () => onClick(StoreNavOptions.Unities),
            active: activeEntity === StoreNavOptions.Unities
        },
        {
            id: StoreNavOptions.Categories,
            title: 'Categorias',
            onClick: () => onClick(StoreNavOptions.Categories),
            active: activeEntity === StoreNavOptions.Categories
        },
        {
            id: StoreNavOptions.Products,
            title: 'Produtos',
            onClick: () => onClick(StoreNavOptions.Products),
            active: activeEntity === StoreNavOptions.Products
        },
        {
            id: StoreNavOptions.Campaigns,
            title: 'Campanhas',
            onClick: () => onClick(StoreNavOptions.Campaigns),
            active: activeEntity === StoreNavOptions.Campaigns
        }
    ];

    return (
        <CardContainer withSpacing={true} style={{ position: 'sticky', top: '30px' }}>
            <NavList items={navOptions} title="NAVEGUE POR AQUI" />
        </CardContainer>
    );
});

export default StoreNav;
