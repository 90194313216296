export default function generateDeleteQuery(tableName?: string, alternativeQuery?: string, schemaName = 'prodigio') {
    if (!tableName || !!alternativeQuery) {
        return alternativeQuery ?? '';
    }

    return `mutation ($id: Int!, $date: timestamptz) {
        item: update_${schemaName}_${tableName}(where: {id: {_eq: $id}}, _set: {deleted: $date}) {
          affected_rows
          returning {
            id
          }
        }
      }`;
}
