import { lazy } from 'react';

const List = lazy(() => import('pages/crud'));
const ProductPhysical = lazy(() => import('pages/productPhysical'));

const productsPhysicalRoutes = [
    {
        path: '/app/produtos-fisicos',
        name: 'Produtos físicos',
        component: List,
        exact: true,
        title: 'Produtos físicos'
    },
    {
        path: '/app/produtos-fisicos/novo',
        name: 'Novo produto físico',
        component: ProductPhysical,
        exact: true,
        title: 'Novo produto físico'
    },
    {
        path: '/app/produtos-fisicos/editar/:id',
        name: 'Editar produto físico',
        component: ProductPhysical,
        exact: true,
        title: 'Editar produto físico'
    }
];

export default productsPhysicalRoutes;
