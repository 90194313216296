import React from 'react';
import Icon from 'components/icons/icon';
import { TableV2CaretsContainer } from './styles';

export enum TableCaretType {
    Ascending = 'asc_nulls_last',
    Descending = 'desc_nulls_last'
}

interface IProps {
    type?: TableCaretType;
    onClick(): void;
}

const TableV2Carets = ({ type, onClick }: IProps) => {
    return (
        <TableV2CaretsContainer caretType={type} onClick={onClick}>
            <Icon idIcon="ico-caret-down" width="10" height="10" viewBox="0 0 292.362 292.362" />
            <Icon idIcon="ico-caret-down" width="10" height="10" viewBox="0 0 292.362 292.362" />
        </TableV2CaretsContainer>
    );
};

export default TableV2Carets;
