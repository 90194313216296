import { lazy } from 'react';

const List = lazy(() => import('pages/crud'));
const Seller = lazy(() => import('pages/seller'));
const SellerView = lazy(() => import('pages/seller/sellerDetails'));

const sellersRoutes = [
    {
        path: '/app/comissionados',
        name: 'Comissionados',
        component: List,
        exact: true,
        title: 'Comissionados'
    },
    {
        path: '/app/comissionados/novo',
        name: 'Novo comissionado',
        component: Seller,
        exact: true,
        title: 'Novo comissionado'
    },
    {
        path: '/app/comissionados/editar/:id',
        name: 'Editar',
        component: Seller,
        exact: true,
        title: 'Editar comissionado'
    },
    {
        path: '/app/comissionados/ver/:id',
        name: 'Visualizar',
        component: SellerView,
        exact: true,
        title: 'Visualizar Comissionado'
    }
];

export default sellersRoutes;
