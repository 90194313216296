import styled, { css } from 'styled-components';
import theme from 'config/theme';

interface IProps {
    withSpacing?: boolean;
    gutterBottom?: boolean;
    column?: boolean;
}

export const CardStyles = css<IProps>`
    background: ${theme.colors.white};
    box-shadow: 0px 0px 10px 0px #e2e2e2;
    border-radius: 5px;
    /* min-width: 400px; */
    padding: ${({ withSpacing }) => withSpacing && theme.spacing.large.all};
    margin-bottom: ${({ gutterBottom }) => gutterBottom && theme.spacing.large.all};

    ${({ column }) =>
        !!column &&
        `
        display: block;
        flex-direction: column;
    `};
`;

export const CardContainer = styled.div<IProps>`
    ${CardStyles};
`;
