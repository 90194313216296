import InputTypes from 'utils/enums/inputTypes';
import { IField } from 'utils/interfaces/IField';

const projectCategory: IField[] = [
    {
        presentation: 'Titulo',
        label: 'Titulo do projeto',
        name: 'title',
        type: InputTypes.text,
        columns: 3
    }
];

export default projectCategory;
