import React, { useRef, useEffect, useState } from 'react';

// Dependencies
import { parseISO } from 'date-fns';
import { useField } from '@rocketseat/unform';
import ReactDatePicker, { registerLocale, ReactDatePickerProps } from 'react-datepicker';

// Helpers
import pt from 'date-fns/locale/pt-BR';

// Assets
import { FormElementWrapper } from './styles';
import { FormErrorMessage } from 'assets/global';
import 'react-datepicker/dist/react-datepicker.css';

registerLocale('pt-BR', pt);

interface IProps {
    name: string;
}

function DatePicker({ name, onChange, ...rest }: IProps & ReactDatePickerProps) {
    const ref = useRef(null);
    const { fieldName, registerField, defaultValue, error } = useField(name);
    const [selected, setSelected] = useState(defaultValue || rest.value);

    useEffect(() => {
        registerField({
            name: fieldName,
            // @ts-ignore
            ref: ref.current,
            path: 'props.selected',
            clearValue: (pickerRef: any) => pickerRef.clear()
        });

        if (defaultValue) {
            setSelected(parseISO(defaultValue));
        }
        // eslint-disable-next-line
    }, [ref.current, fieldName, defaultValue]);

    return (
        <FormElementWrapper hasError={!!error}>
            <ReactDatePicker
                autoComplete="off"
                value={selected}
                name={fieldName}
                dateFormat="P"
                selected={selected}
                onChange={(date: any, event) => {
                    const newDate = date ?? undefined;
                    setSelected(newDate);
                    onChange(newDate, event);
                }}
                locale="pt-BR"
                ref={ref}
                {...rest}
            />
            {error && <FormErrorMessage>{error}</FormErrorMessage>}
        </FormElementWrapper>
    );
}

export default DatePicker;
