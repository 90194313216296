import { put } from 'redux-saga/effects';

// Redux
import { Creators as courseActions } from 'store/ducks/course';
import { Creators as modalActions } from 'store/ducks/modal';
import { Creators as lessonPlanEventsActions } from 'store/ducks/lessonPlanEvents';
import { Creators as questionsActions } from 'store/ducks/questions';
import { Creators as filesActions } from 'store/ducks/files';

export function* createLOMaterialCourse(action: any, data: any) {
    try {
        const createBookletContentItemPayload = [
            {
                module: action.idModule,
                learningObject: data.learningObject.id,
                order: action.newOrder,
                data
            }
        ];

        yield put(courseActions.createBookletContentItemRequest(createBookletContentItemPayload));
        yield put(modalActions.closeModal());
    } catch (error) {
        console.log('error');
    }
}

export function* createLOMaterialLessonPlanEvents(action: any, data: any) {
    try {
        yield put(
            lessonPlanEventsActions.createOrEditLessonPlanEventsContentRequest({
                event: action.event,
                content: data
            })
        );
    } catch (error) {
        console.log('error');
    }
}

export function* createLOMaterialQuestionResolution(action: any, data: any) {
    try {
        yield put(questionsActions.createQuestionsResolutionRequest(data));
    } catch (error) {
        console.log('error');
    }
}

export function* editMaterialQuestionResolution(data: any) {
    try {
        console.log('editMaterialQuestionResolution');
        yield put(questionsActions.editQuestionMaterialSuccess(data));

        yield put(modalActions.closeModal());
    } catch (error) {
        console.log('error');
    }
}

export function* createLOMaterialFiles(data: any) {
    try {
        yield put(filesActions.getFileSuccess({ material: data }));
        yield put(modalActions.closeModal());
    } catch (error) {
        console.log('error');
    }
}
