import React, { memo } from 'react';

// Dependencies
import { withRouter, RouteComponentProps } from 'react-router';

// Components
import Button from 'components/button';
import Icon from 'components/icons/icon';
import Tooltip from 'components/tooltip';
import Breadcrumb, { IBreadcrumbItem } from 'components/breadcrumb';

// Helpers
import history from 'services/history';
import truncateText from 'utils/truncateText';

// Assets
import { Title, Container } from 'assets/global';
import { PageHeaderContainer, PageHeaderLeft, ButtonWrapper, PageHeaderTitle } from './styles';

interface IProps {
    title: string;
    hasNewButton?: boolean;
    hasEditButton?: boolean;
    breadcrumbItems?: IBreadcrumbItem[];
    onClickEdit?(): void;
}

const PageHeader = ({ title, hasEditButton, hasNewButton = false, location, breadcrumbItems, onClickEdit }: IProps & RouteComponentProps<any>) => {
    let editUrl = '';

    if (hasEditButton) {
        const url = location.pathname;
        editUrl = url.replace('ver', 'editar');
    }

    return (
        <PageHeaderContainer id="page-header">
            <Container>
                <PageHeaderLeft>
                    <PageHeaderTitle>
                        <Title>
                            {truncateText(title, 40)} {title.length > 40 && ' ...'}
                        </Title>
                        {title.length > 40 && <Tooltip content={title} />}
                    </PageHeaderTitle>

                    {hasNewButton && (
                        <ButtonWrapper>
                            <Button rounded={true} variant="success" size="small" onClick={() => history.push(`${location.pathname}/novo`)}>
                                <Icon idIcon="ico-plus" width="15" height="15" viewBox="0 0 31.444 31.444" />
                                <Tooltip content="Adicionar" />
                            </Button>
                        </ButtonWrapper>
                    )}
                    {hasEditButton && (
                        <ButtonWrapper>
                            <Button rounded={true} variant="warning" size="small" onClick={() => (onClickEdit ? onClickEdit() : history.push(editUrl))}>
                                <Icon idIcon="ico-pencil" width="15" height="15" viewBox="0 0 512 512" />
                                <Tooltip content="Editar" />
                            </Button>
                        </ButtonWrapper>
                    )}
                </PageHeaderLeft>
                <Breadcrumb title={title} items={breadcrumbItems} />
            </Container>
        </PageHeaderContainer>
    );
};

PageHeader.defaultProps = {
    hasNewButton: false
};

export default memo(withRouter(PageHeader));
