import { lazy } from 'react';

const List = lazy(() => import('pages/crud'));
const Project = lazy(() => import('pages/projects'));
const ProjectView = lazy(() => import('pages/projects/view'));

const projectRoutes = [
    {
        path: '/app/projetos',
        name: 'Projetos',
        component: List,
        exact: true,
        title: 'Projetos'
    },
    {
        path: '/app/projetos/novo',
        name: 'Novo',
        component: Project,
        exact: true,
        title: 'Novo projeto'
    },
    {
        path: '/app/projetos/editar/:id',
        name: 'Editar',
        component: Project,
        exact: true,
        title: 'Editar projeto'
    },
    {
        path: '/app/projetos/ver/:id',
        name: 'Ver',
        component: ProjectView,
        exact: true,
        title: 'Ver projeto'
    }
];

export default projectRoutes;
