import React from 'react';

// Dependencies
import { Styles } from 'react-select/lib/styles';
import { SelectComponentsProps } from 'react-select/lib/Select';

// Helpers
import theme from 'config/theme';
import hexToRgba from 'utils/hexToRgba';

// Assets
import { AsyncSelect as AsyncSelectInput } from './styles';

interface IAsyncSelectProps extends SelectComponentsProps {
    loadOptions(text: string): void;
}

const selectStyles = (): Partial<Styles> => {
    return {
        control: (styles: any, state) => {
            return {
                ...styles,
                ':hover': {
                    borderColor: theme.colors.secondary
                },
                backgroundColor: '#FFF',
                borderColor: state.isFocused ? theme.colors.secondary : '#d2d2d2',
                boxShadow: 'none'
            };
        },
        option: (styles: any, state) => {
            return {
                ...styles,
                ':hover': {
                    backgroundColor: hexToRgba(theme.colors.secondary, 0.1)
                },
                backgroundColor: state.isSelected ? hexToRgba(theme.colors.secondary, 0.1) : '#FFF',
                color: '#071f38',
                opacity: state?.isDisabled ? 0.45 : 1,
                cursor: state?.isDisabled ? 'not-allowed' : 'auto'
            };
        }
    };
};

function AsyncSelect({ multiple = false, cacheOptions = false, ...rest }: IAsyncSelectProps) {
    return (
        <AsyncSelectInput
            classNamePrefix="select"
            isMulti={multiple}
            defaultOptions
            styles={selectStyles()}
            cacheOptions={cacheOptions}
            loadingMessage={() => 'Carregando...'}
            noOptionsMessage={({ inputValue }: any) => (!!inputValue ? 'Nenhum registro encontrado' : 'Digite algo para buscar...')}
            {...rest}
        />
    );
}

export default AsyncSelect;
