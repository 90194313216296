import dictionary from 'config/dictionary';
import { ISelect } from './interfaces/IReactSelect';

const convertEnumToOptions = (enumValue: any, dictionaryKey?: string): ISelect<any>[] => {
    const tempA: ISelect[] = [];

    Object.keys(enumValue)
        .filter((key) => isNaN(+key))
        .forEach((key) => {
            tempA.push({
                label: !!dictionaryKey ? dictionary[dictionaryKey][key] ?? dictionary.general[key] ?? key : key,
                value: enumValue[key]
            });
        });

    return tempA;
};

export default convertEnumToOptions;
