import React, { useState } from 'react';

// Assets
import { AvatarContainer, UserInitial } from './styles';
import Tooltip from 'components/tooltip';

export type AvatarSize = 'small' | 'smallX' | 'medium' | 'large';

interface IProps {
    avatarPath?: string;
    name: string;
    size: AvatarSize;
    tooltip?: boolean;
}

const Avatar = ({ avatarPath, name, size, tooltip = false }: IProps) => {
    const [forceDefaultImage, setForceDefaultImage] = useState(false);

    return (
        <AvatarContainer
            size={size}
            className="bg-cover"
            style={{
                ...(!!avatarPath &&
                    !forceDefaultImage && {
                        backgroundImage: `url(${avatarPath})`
                    })
            }}
        >
            <img src={avatarPath} alt="" style={{ display: 'none' }} onError={() => setForceDefaultImage(true)} />
            {(!avatarPath || forceDefaultImage) && <UserInitial>{name.charAt(0)}</UserInitial>}
            {tooltip && <Tooltip content={name} />}
        </AvatarContainer>
    );
};

export default React.memo(Avatar);
