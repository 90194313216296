import { lazy } from 'react';

const List = lazy(() => import('pages/crud'));
const CreateOrEdit = lazy(() => import('pages/crud/createOrEdit'));

const schoolPeriodRoutes = [
    {
        path: '/app/anos-letivos',
        name: 'Anos letivos',
        component: List,
        exact: true,
        title: 'Anos letivos'
    },
    {
        path: '/app/anos-letivos/novo',
        name: 'Novo ano letivo',
        component: CreateOrEdit,
        exact: true,
        title: 'Novo ano letivo'
    },
    {
        path: '/app/anos-letivos/editar/:id',
        name: 'Editar',
        component: CreateOrEdit,
        exact: true,
        title: 'Editar ano letivo'
    }
];

export default schoolPeriodRoutes;
