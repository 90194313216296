import { createActions, createReducer } from 'reduxsauce';
import { IStoreState, IStoreEntity } from 'utils/interfaces/IStore';

export const { Creators, Types } = createActions({
    getStoreRequest: ['payload'],
    getStoreSuccess: ['payload'],
    getStoreFailure: [],

    createOrEditStoreRequest: ['payload'],
    createOrEditStoreSuccess: ['payload'],
    createOrEditStoreFailure: [],

    getAllStoreEntityRequest: ['payload'],
    getAllStoreEntitySuccess: ['payload'],
    getAllStoreEntityFailure: [],

    clearItems: [],

    createOrEditStoreEntityRequest: ['payload'],
    createOrEditStoreEntitySuccess: ['payload'],
    createOrEditStoreEntityFailure: [],

    deleteStoreEntityRequest: ['payload'],
    deleteStoreEntitySuccess: ['payload'],
    deleteStoreEntityFailure: [],

    getStoreOrderRequest: ['payload'],
    getStoreOrderSuccess: ['payload'],
    getStoreOrderFailure: [],

    orderRefundRequest: ['payload'],
    orderRefundSuccess: ['payload'],
    orderRefundFailure: [],

    clearStore: []
});

const ENTITY_INITIAL_DATA: IStoreEntity = {
    items: [],
    pagination: {
        count: 0,
        page: 0,
        totalItems: 0,
        totalPages: 0
    }
};

const ORDER_INITIAL_DATA = {
    addresses: [],
    amount: 0,
    boleto_barcode: '',
    boleto_url: '',
    code: '',
    created: '',
    customer: {
        country: '',
        document_number: '',
        email: '',
        id: 0,
        name: '',
        last_name: '',
        phone_number: '',
        type: 0
    },
    details: [],
    due_date: '',
    id: 0,
    original_amount: 0,
    pay_date: '',
    installments: 1,
    payment_form: {
        name: ''
    },
    status: {
        name: '' as any
    },
    subscriptions: [],
    movimentations: []
};

const INITIAL_STATE: IStoreState = {
    isLoading: false,
    isOrderRefunded: false,
    id: 0,
    name: '',
    url: '',
    slug: '',
    isActive: false,
    categories: [],
    address: [],
    products: [],
    isLoadingEntity: false,
    isLoadingOrder: false,
    entity: ENTITY_INITIAL_DATA,
    hasShowcase: false,
    hasWithdrawal: false,
    withDelivery: false,
    order: ORDER_INITIAL_DATA
};

// GENERIC ACTIONS
const storeRequest = (state = INITIAL_STATE, _: any) => ({ ...state, isLoading: true });
const storeSuccess = (state = INITIAL_STATE, action: any) => ({ ...state, isLoading: false, ...action.payload });
const storeFailure = (state = INITIAL_STATE) => ({ ...state, isLoading: false, isOrderRefunded: false });
const clearStore = () => INITIAL_STATE;

// ENTITY

const startEntityLoading = (state = INITIAL_STATE, _: any) => ({ ...state, isLoadingEntity: true });

const stopEntityLoading = (state = INITIAL_STATE, _: any) => ({
    ...state,
    isLoadingEntity: false
});

const getAllEntityRequest = (state = INITIAL_STATE) => ({
    ...state,
    isLoadingEntity: true,
    entity: ENTITY_INITIAL_DATA
});

const getAllEntityFailure = (state = INITIAL_STATE, _: any) => ({
    ...state,
    isLoadingEntity: false,
    isOrderRefunded: false,
    entity: ENTITY_INITIAL_DATA
});

const getAllEntitySuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoadingEntity: false,
    entity: {
        ...action.payload
    }
});

const clearItems = (state = INITIAL_STATE) => ({
    ...state,
    isLoadingEntity: false,
    entity: {
        ...state.entity,
        items: []
    }
});

const createOrEditEntity = (state = INITIAL_STATE, action: any) => {
    const haveThisEntity = state.entity.items.some((item) => item.id === action.payload?.id);
    const updateEntities = haveThisEntity ? state.entity.items.map((item) => (item.id === action.payload?.id ? action.payload : item)) : [action.payload, ...state.entity.items];

    return {
        ...state,
        isLoadingEntity: false,
        entity: {
            ...state.entity,
            items: updateEntities
        }
    };
};

const deleteEntity = (state = INITIAL_STATE, action: any) => {
    const updateEntities = state.entity.items.filter((item) => item.id !== action.payload?.id);

    return {
        ...state,
        isLoadingEntity: false,
        entity: {
            ...state.entity,
            items: updateEntities
        }
    };
};

const orderRequest = (state = INITIAL_STATE) => ({ ...state, isLoadingOrder: true, isOrderRefunded: false });

const orderFailure = (state = INITIAL_STATE, _: any) => ({ ...state, isLoadingOrder: false, isOrderRefunded: false });

const getOrderSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoadingOrder: false,
    isOrderRefunded: false,
    order: action.payload
});

const orderRefundSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isOrderRefunded: true,
    isLoadingOrder: false
});

export default createReducer(INITIAL_STATE, {
    [Types.GET_STORE_REQUEST]: storeRequest,
    [Types.GET_STORE_SUCCESS]: storeSuccess,
    [Types.GET_STORE_FAILURE]: storeFailure,

    [Types.CREATE_OR_EDIT_STORE_REQUEST]: storeRequest,
    [Types.CREATE_OR_EDIT_STORE_SUCCESS]: storeSuccess,
    [Types.CREATE_OR_EDIT_STORE_FAILURE]: storeFailure,

    [Types.GET_ALL_STORE_ENTITY_REQUEST]: getAllEntityRequest,
    [Types.GET_ALL_STORE_ENTITY_SUCCESS]: getAllEntitySuccess,
    [Types.GET_ALL_STORE_ENTITY_FAILURE]: getAllEntityFailure,

    [Types.CREATE_OR_EDIT_STORE_ENTITY_REQUEST]: startEntityLoading,
    [Types.CREATE_OR_EDIT_STORE_ENTITY_SUCCESS]: createOrEditEntity,
    [Types.CREATE_OR_EDIT_STORE_ENTITY_FAILURE]: stopEntityLoading,

    [Types.DELETE_STORE_ENTITY_REQUEST]: startEntityLoading,
    [Types.DELETE_STORE_ENTITY_SUCCESS]: deleteEntity,
    [Types.DELETE_STORE_ENTITY_FAILURE]: stopEntityLoading,

    [Types.GET_STORE_ORDER_REQUEST]: orderRequest,
    [Types.GET_STORE_ORDER_SUCCESS]: getOrderSuccess,
    [Types.GET_STORE_ORDER_FAILURE]: orderFailure,

    [Types.ORDER_REFUND_REQUEST]: orderRequest,
    [Types.ORDER_REFUND_SUCCESS]: orderRefundSuccess,
    [Types.ORDER_REFUND_FAILURE]: orderFailure,

    [Types.CLEAR_STORE]: clearStore,
    [Types.CLEAR_ITEMS]: clearItems
});
