import React from 'react';

// Assets
import { LoaderContainer, LoaderSize, LoaderBall, LoaderWrapper } from './styles';

interface IProps {
    size?: LoaderSize;
    loading?: boolean;
}

const Loader = ({ loading = false, size = 'small' }: IProps) => (
    <div className={`-loading ${loading && '-active'}`}>
        <div className="-loading-inner">
            <LoaderWrapper>
                <LoaderContainer size={size}>
                    {Array.from(Array(3)).map((_, index) => (
                        <LoaderBall key={index} />
                    ))}
                </LoaderContainer>
            </LoaderWrapper>
        </div>
    </div>
);

export default Loader;
