import React from 'react';

import PageHeader from 'components/pageHeader';
import ListData from 'components/listData';

// Helpers
import { Container, Content } from 'assets/global';
import { CardContainer } from 'components/card/styles';
import { IConfigCrud } from 'utils/interfaces/ICrud';
import { IModalActions } from 'utils/interfaces/IActions';
import Button from 'components/button';
import Icon from 'components/icons/icon';
import { useModal } from 'hooks/useModal';

interface IProps {
    config: IConfigCrud;
    title: string;
    modalActions: IModalActions;
}

const ListScreen = ({
    config: {
        hasNewButton,
        availableColumns = [],
        graphqlListQuery,
        path,
        apiEndpoint,
        hasCustomAction,
        relations = [],
        hasViewPage = false,
        isEditable = true,
        canToggleAnswerQuestionStatus = false,
        canToggleVideoStatus = false,
        isRemovable = true,
        graphqlDeleteQuery,
        advancedSearch,
        brandRelationName,
        companyRelationName,
        graphQLTableName,
        filters,
        unitRelationName,
        isLearningObject,
        mainColumn,
        actions,
        useGraphQlForDelete,
        messageDelete,
        useQueryParams,
        personalizedToggle
    },
    title
}: IProps) => {
    const modal = useModal();

    return (
        <Content>
            <PageHeader hasNewButton={!!hasNewButton} title={title} />
            <Container>
                <CardContainer withSpacing>
                    <ListData
                        columns={availableColumns}
                        getAllQuery={graphqlListQuery}
                        basePath={path}
                        apiEndpoint={apiEndpoint}
                        xrelations={relations}
                        canDelete={isRemovable}
                        canEdit={isEditable}
                        canToggleAnswerQuestionStatus={canToggleAnswerQuestionStatus}
                        canToggleVideoStatus={canToggleVideoStatus}
                        canSeeDetails={hasViewPage}
                        deleteQuery={graphqlDeleteQuery}
                        advancedSearch={advancedSearch}
                        defaultFiltes={filters}
                        brandRelationName={brandRelationName}
                        companyRelationName={companyRelationName}
                        unitRelationName={unitRelationName}
                        graphQLTableName={graphQLTableName}
                        isLearningObject={!!isLearningObject}
                        mainColumn={mainColumn}
                        actions={actions}
                        useGraphQlForDelete={useGraphQlForDelete}
                        messageDelete={messageDelete}
                        useQueryParams={useQueryParams}
                        personalizedToggle={personalizedToggle}
                    />

                    {hasCustomAction && (
                        <Button rounded={true} style={{ position: 'fixed', right: '20px', bottom: '20px', zIndex: 10 }} variant="primary" onClick={() => modal.open('importStudents')}>
                            <Icon idIcon="ico-upload" width="25" height="25" viewBox="0 0 512 512" fill="#FFFFFF" />
                        </Button>
                    )}
                </CardContainer>
            </Container>
        </Content>
    );
};

export default ListScreen;
