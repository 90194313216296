import React, { useEffect, memo, useState, useCallback } from 'react';

// Dependencies
import { useParams } from 'react-router';

// Components
import Order from './order';

// Redux
import { useDispatch, useSelector } from 'react-redux';

// Helpers
import { graphQLClient } from 'services/graphqlClient';
import { IReduxStore } from 'utils/interfaces/IReduxStore';
import { deleteOrderAddress } from 'utils/queries/store/orderByPk';
import { IStoreLocationAddress, OrderAddressType } from 'utils/interfaces/IStore';

const OrderScreen = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const [isFetched, setIsFetched] = useState(false);

    const { order, isLoading, isOrderRefunded } = useSelector(({ store }: IReduxStore) => ({
        order: store.order,
        isLoading: store.isLoadingOrder,
        isOrderRefunded: store.isOrderRefunded
    }));

    useEffect(() => {
        if (!isFetched) {
            dispatch({ type: 'GET_STORE_ORDER_REQUEST', payload: { id } });
            setIsFetched(true);
        }
    }, [dispatch, id, isFetched, order.id]);

    useEffect(() => {
        if (isOrderRefunded && isFetched) {
            setIsFetched(false);
        }
    }, [isFetched, isOrderRefunded]);

    const handleAddressSuccess = useCallback(() => {
        // Close modal
        dispatch({ type: 'CLOSE_MODAL' });
        // Show alert
        dispatch({ type: 'SHOW_ALERT', message: 'Sucesso', alertType: 'success' });
        // Get order again
        setIsFetched(false);
    }, [dispatch]);

    const handleAddress = useCallback(
        (type: OrderAddressType, address?: IStoreLocationAddress) => {
            dispatch({
                type: 'OPEN_MODAL',
                element: 'orderAddress',
                action: {
                    type,
                    address,
                    orderId: order.id,
                    onSuccess: handleAddressSuccess
                }
            });
        },
        [dispatch, handleAddressSuccess, order.id]
    );

    const handleConfirmDeleteAddress = useCallback(
        async (id: number) => {
            try {
                // DELETE ORDER ADDRESS
                const response = await graphQLClient(deleteOrderAddress, { id, date: new Date().toISOString() });

                if (response.errors) {
                    throw new Error('Ocorreu um erro ao apagar o endereço.');
                }

                // Show alert
                dispatch({ type: 'SHOW_ALERT', message: 'Sucesso', alertType: 'success' });
                // Get order again
                setIsFetched(false);
            } catch (error) {
                dispatch({ type: 'SHOW_ALERT', message: error.message, alertType: 'danger' });
            }
        },
        [dispatch]
    );

    const handleDeleteAddress = useCallback(
        (id: number) => {
            dispatch({ type: 'OPEN_MODAL', element: 'confirmActionModal', action: { confirmAction: () => handleConfirmDeleteAddress(id) } });
        },
        [dispatch, handleConfirmDeleteAddress]
    );

    // Refund card transaction
    const handleConfirmRefundAction = useCallback(() => dispatch({ type: 'ORDER_REFUND_REQUEST', payload: { id: order.id } }), [dispatch, order.id]);

    const handleRefund = useCallback(() => {
        if (order.payment_form.name === 'Boleto') {
            // return dispatch({ type: 'OPEN_MODAL', element: 'refund' });
            return;
        }

        return dispatch({ type: 'OPEN_MODAL', element: 'confirmActionModal', action: { confirmAction: () => handleConfirmRefundAction() } });
    }, [dispatch, handleConfirmRefundAction, order.payment_form.name]);

    return <Order order={order} isLoading={isLoading} onAddAddress={handleAddress} onEditAddress={handleAddress} onDeleteAddress={handleDeleteAddress} onRefundOrder={handleRefund} />;
};

export default memo(OrderScreen);
