import React from 'react';

// Assets
import { LogoContainer } from './styles';
import logoProdigio from 'assets/img/logo-prodigio.png';
import logoProdigioMain from 'assets/img/logo-prodigio-azul.png';

interface IProps {
    type: 'white' | 'main';
}

const Logo = ({ type }: IProps) => (
    <LogoContainer>
        <img alt="Prodígio Educação" src={type === 'main' ? logoProdigioMain : logoProdigio} />
    </LogoContainer>
);

export default Logo;
