import { lazy } from 'react';

const Campaign = lazy(() => import('pages/campaign'));

const campaignRoutes = [
    {
        path: '/app/campanhas/novo',
        name: 'Nova Campanha',
        component: Campaign,
        exact: true,
        title: 'Nova Campanha'
    },
    {
        path: '/app/campanhas/editar/:id',
        name: 'Editar Campanha',
        component: Campaign,
        exact: true,
        title: 'Editar Campanha'
    }
];

export default campaignRoutes;
