import React from 'react';

// Redux
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { Creators as ModalActions } from 'store/ducks/modal';
import { Creators as AlertActions } from 'store/ducks/alert';
import { IModalActions } from 'utils/interfaces/IActions';
import { IAlertActions } from 'store/types/IAlert';

interface IModalErrorBoundaryProps {
    modalActions: IModalActions;
    alertActions: IAlertActions;
    children: React.ReactNode;
}

interface IModalErrorBoundaryState {
    hasError: boolean;
}

class ModalErrorBoundary extends React.Component<IModalErrorBoundaryProps> {
    public state: IModalErrorBoundaryState = {
        hasError: false
    };

    public componentDidCatch = (error: Error, errorInfo: React.ErrorInfo) => {
        this.setState({ hasError: true });
        const { alertActions, modalActions } = this.props;

        alertActions.showAlert('Ops! Ocorreu um error.', 'danger');
        modalActions.closeModal();
    };

    public render() {
        const { children } = this.props;
        const { hasError } = this.state;

        if (hasError) {
            return null;
        }

        return children;
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    modalActions: bindActionCreators(ModalActions, dispatch) as any,
    alertActions: bindActionCreators(AlertActions, dispatch) as any
});

export default connect(null, mapDispatchToProps)(ModalErrorBoundary);
