import { createActions, createReducer } from 'reduxsauce';
import { ISelect } from 'utils/interfaces/IReactSelect';
import { QuestionType } from 'utils/enums/question';
import { IExerciseList } from 'utils/interfaces/IExerciseList';
import { IQrCode } from 'utils/interfaces/IQrCode';

export const { Creators, Types } = createActions({
    getQuestionsRequest: ['payload'],
    getQuestionsSuccess: ['payload'],
    getQuestionsFailure: ['payload'],

    createOrEditQuestionsRequest: ['payload'],
    createOrEditQuestionsSuccess: ['payload'],
    createOrEditQuestionsFailure: [],

    createOrEditQuestionsAlternativeRequest: ['payload'],
    createOrEditQuestionsAlternativeSuccess: ['payload'],
    createOrEditQuestionsAlternativeFailure: [],

    deleteQuestionsAlternativeRequest: ['payload'],
    deleteQuestionsAlternativeSuccess: ['payload'],
    deleteQuestionsAlternativeFailure: [],

    createQuestionsResolutionRequest: ['payload'],
    createQuestionsResolutionSuccess: ['payload'],
    createQuestionsResolutionFailure: [],

    deleteQuestionsResolutionRequest: ['payload'],
    deleteQuestionsResolutionSuccess: ['payload'],
    deleteQuestionsResolutionFailure: [],

    associateQuestionQrCodeRequest: ['payload'],
    associateQuestionQrCodeSuccess: ['payload'],
    associateQuestionQrCodeFailure: [],

    editQuestionMaterialSuccess: ['payload'],

    bulkCancelQuestionRequest: ['payload'],
    bulkCancelQuestionSuccess: [],
    bulkCancelQuestionFailure: [],

    updateQuestionTagsRequest: ['payload'],
    updateQuestionTagsSuccess: [],
    updateQuestionTagsFailure: [],

    clearQuestion: []
});

export interface IQuestionAlternativeState {
    id: number;
    enunciation: string;
    isCorrection: number;
    order: number;
    answersTotal: number;
    averageHitRate: number;
}

export interface IMetaData {
    year?: number;
    level: ISelect | null;
    subject: ISelect | null;
    contest: ISelect | null;
    topics: ISelect | null;
    subTopic: ISelect | null;
    subjectFront: ISelect | null;
    version?: string;
    contestPhaseYear: {
        contestPhase: {
            id: number;
            name: string;
        };
    };
}

export interface IQuestionsState {
    isLoading: boolean;
    id: number;
    code: string;
    edition: any;
    revisionComment: string;
    enunciation: string;
    questionType: number;
    realizationsTotal: number;
    realizationsPerStudent: number;
    alternatives: IQuestionAlternativeState[];
    resolutions: any[];
    learningObject?: any;
    attachment: string | null;
    exerciseListItems?: Array<{
        id: number;
        order: number;
        points: number;
        exerciseList: IExerciseList;
    }>;
    metadata?: IMetaData;
    qrcode?: IQrCode;
    origin: number;
    brands: any[];
    availableContests: any[];
}

const INITIAL_STATE: IQuestionsState = {
    isLoading: false,
    alternatives: [],
    code: '',
    edition: null,
    enunciation: '',
    id: 0,
    questionType: QuestionType.Objective,
    realizationsPerStudent: 0,
    realizationsTotal: 0,
    revisionComment: '',
    resolutions: [],
    attachment: null,
    origin: 0,
    metadata: {
        topics: null,
        subjectFront: null,
        subject: null,
        subTopic: null,
        level: null,
        contest: null,
        contestPhaseYear: {
            contestPhase: {
                id: 0,
                name: ''
            }
        }
    },
    brands: [],
    availableContests: []
};

// GET BY ID
const getQuestionsRequest = (state = INITIAL_STATE, action: any) => ({ ...state, isLoading: true });
const getQuestionsSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoading: false,
    ...action.payload
});
const getQuestionsFailure = (state = INITIAL_STATE) => ({ ...state, ...INITIAL_STATE });

// CREATE OR EDIT
const createOrEditQuestionsRequest = (state = INITIAL_STATE, action: any) => ({ ...state, isLoading: true });
const createOrEditQuestionsSuccess = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        isLoading: false,
        ...action.payload
    };
};

const createOrEditQuestionsFailure = (state = INITIAL_STATE) => ({ ...state, isLoading: false });

// CREATE OR EDIT ALTERNATIVE
const createOrEditQuestionsAlternativeRequest = (state = INITIAL_STATE, action: any) => ({ ...state, isLoading: true });
const createOrEditQuestionsAlternativeSuccess = (state = INITIAL_STATE, action: any) => {
    const { payload } = action;

    const haveThisAlternative = state.alternatives.find((item) => item.id === payload.id);

    const updateAlternatives = !!haveThisAlternative
        ? state.alternatives.map((alternative) => (alternative.id === payload.id ? payload : alternative))
        : [...state.alternatives, { ...payload, order: state.alternatives.length + 1 }];

    return {
        ...state,
        isLoading: false,
        alternatives: updateAlternatives
    };
};
const createOrEditQuestionsAlternativeFailure = (state = INITIAL_STATE) => ({ ...state, isLoading: false });

// DELETE ALTERNATIVE
const deleteQuestionsAlternativeRequest = (state = INITIAL_STATE, action: any) => ({ ...state, isLoading: true });
const deleteQuestionsAlternativeSuccess = (state = INITIAL_STATE, action: any) => {
    const { payload } = action;

    const updateAlternatives = state.alternatives.filter((item) => item.id !== payload.id);

    return {
        ...state,
        isLoading: false,
        alternatives: updateAlternatives
    };
};
const deleteQuestionsAlternativeFailure = (state = INITIAL_STATE) => ({ ...state, isLoading: false });

// CREATE RESOLUTION
const createQuestionsResolutionRequest = (state = INITIAL_STATE, action: any) => ({ ...state, isLoading: true });
const createQuestionsResolutionSuccess = (state = INITIAL_STATE, action: any) => {
    const { payload } = action;

    return {
        ...state,
        isLoading: false,
        resolutions: payload
    };
};
const createQuestionsResolutionFailure = (state = INITIAL_STATE) => ({ ...state, isLoading: false });

// DELETE RESOLUTION
const deleteQuestionsResolutionRequest = (state = INITIAL_STATE, action: any) => ({ ...state, isLoading: true });
const deleteQuestionsResolutionSuccess = (state = INITIAL_STATE, action: any) => {
    const { payload } = action;

    return {
        ...state,
        isLoading: false,
        resolutions: payload.resolutions
    };
};
const deleteQuestionsResolutionFailure = (state = INITIAL_STATE) => ({ ...state, isLoading: false });

// ASSOCIATE QR CODE
const associateQuestionQrCodeRequest = (state = INITIAL_STATE, action: any) => ({ ...state, isLoading: true });
const associateQuestionQrCodeSuccess = (state = INITIAL_STATE, action: any) => {
    const { payload } = action;

    return {
        ...state,
        isLoading: false,
        qrcode: payload
    };
};
const associateQuestionQrCodeFailure = (state = INITIAL_STATE) => ({ ...state, isLoading: false });

const editMaterial = (state = INITIAL_STATE, action: any) => {
    const { payload } = action;

    const updateResolution = (state.resolutions || []).map((resolution) => ((resolution.material?.id || resolution.id) === payload.id ? payload : resolution));

    return { ...state, isLoading: false, resolutions: updateResolution };
};

const clear = () => INITIAL_STATE;

const startLoading = (state = INITIAL_STATE, _?: any) => {
    return { ...state, isLoading: true };
};

const stopLoading = (state = INITIAL_STATE, _?: any) => {
    return { ...state, isLoading: false };
};

// TYPES
export default createReducer(INITIAL_STATE, {
    // GET BY ID
    [Types.GET_QUESTIONS_REQUEST]: getQuestionsRequest,
    [Types.GET_QUESTIONS_SUCCESS]: getQuestionsSuccess,
    [Types.GET_QUESTIONS_FAILURE]: getQuestionsFailure,

    // CREATE OR EDIT
    [Types.CREATE_OR_EDIT_QUESTIONS_REQUEST]: createOrEditQuestionsRequest,
    [Types.CREATE_OR_EDIT_QUESTIONS_SUCCESS]: createOrEditQuestionsSuccess,
    [Types.CREATE_OR_EDIT_QUESTIONS_FAILURE]: createOrEditQuestionsFailure,

    // CREATE OR EDIT ALTERNATIVES
    [Types.CREATE_OR_EDIT_QUESTIONS_ALTERNATIVE_REQUEST]: createOrEditQuestionsAlternativeRequest,
    [Types.CREATE_OR_EDIT_QUESTIONS_ALTERNATIVE_SUCCESS]: createOrEditQuestionsAlternativeSuccess,
    [Types.CREATE_OR_EDIT_QUESTIONS_ALTERNATIVE_FAILURE]: createOrEditQuestionsAlternativeFailure,

    // DELETE ALTERNATIVES
    [Types.DELETE_QUESTIONS_ALTERNATIVE_REQUEST]: deleteQuestionsAlternativeRequest,
    [Types.DELETE_QUESTIONS_ALTERNATIVE_SUCCESS]: deleteQuestionsAlternativeSuccess,
    [Types.DELETE_QUESTIONS_ALTERNATIVE_FAILURE]: deleteQuestionsAlternativeFailure,

    // CREATE RESOLUTION
    [Types.CREATE_QUESTIONS_RESOLUTION_REQUEST]: createQuestionsResolutionRequest,
    [Types.CREATE_QUESTIONS_RESOLUTION_SUCCESS]: createQuestionsResolutionSuccess,
    [Types.CREATE_QUESTIONS_RESOLUTION_FAILURE]: createQuestionsResolutionFailure,

    // DELETE RESOLUTION
    [Types.DELETE_QUESTIONS_RESOLUTION_REQUEST]: deleteQuestionsResolutionRequest,
    [Types.DELETE_QUESTIONS_RESOLUTION_SUCCESS]: deleteQuestionsResolutionSuccess,
    [Types.DELETE_QUESTIONS_RESOLUTION_FAILURE]: deleteQuestionsResolutionFailure,

    // ASSOCIATE QR CODE
    [Types.ASSOCIATE_QUESTION_QR_CODE_REQUEST]: associateQuestionQrCodeRequest,
    [Types.ASSOCIATE_QUESTION_QR_CODE_SUCCESS]: associateQuestionQrCodeSuccess,
    [Types.ASSOCIATE_QUESTION_QR_CODE_FAILURE]: associateQuestionQrCodeFailure,

    // EDIT MATERIAL RESOLUTION
    [Types.EDIT_QUESTION_MATERIAL_SUCCESS]: editMaterial,

    // BULK CANCEL
    [Types.BULK_CANCEL_QUESTION_REQUEST]: getQuestionsRequest,
    [Types.BULK_CANCEL_QUESTION_SUCCESS]: getQuestionsSuccess,
    [Types.BULK_CANCEL_QUESTION_FAILURE]: getQuestionsSuccess,

    //

    [Types.UPDATE_QUESTION_TAGS_REQUEST]: startLoading,
    [Types.UPDATE_QUESTION_TAGS_SUCCESS]: stopLoading,
    [Types.UPDATE_QUESTION_TAGS_FAILURE]: stopLoading,

    [Types.CLEAR_QUESTION]: clear
});
