import React from 'react';

// Components
import Button from 'components/button';

// Assets
import { ButtonGroupContainer } from './styles';

export type ButtonGroupOptions = Array<{
    label: string;
    action: () => void;
}>;

interface IProps {
    options: ButtonGroupOptions;
}

const ButtonGroup = ({ options }: IProps) => {
    return (
        <ButtonGroupContainer>
            {options.map((option, index) => (
                <Button type="button" variant="outline-primary" size="small" key={index} onClick={option.action}>
                    {option.label}
                </Button>
            ))}
        </ButtonGroupContainer>
    );
};

export default ButtonGroup;
