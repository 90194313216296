import { createActions, createReducer } from 'reduxsauce';
import { AuthActionsType, IAuthActionsCreators, IAuthState } from 'store/types/IAuth';

export const { Creators, Types } = createActions<Record<AuthActionsType, AuthActionsType>, IAuthActionsCreators>({
    loginRequest: ['payload', 'action'],
    loginSuccess: ['payload'],
    loginFailure: [],

    getProfileRequest: [],
    getProfileSuccess: ['payload'],
    getProfileFailure: [],

    getNavbarMenuRequest: ['payload'],
    getNavbarMenuSuccess: ['payload'],
    getNavbarMenuFailure: [],

    setMenuPreview: ['payload'],
    removeMenuPreview: [],

    setProfile: ['payload'],

    logout: [],

    clearLoading: []
});

const INITIAL_STATE: IAuthState = {
    isLoading: false,
    token: '',
    refreshToken: '',
    credentials: {
        id: 0,
        email: '',
        name: '',
        slug: ''
    },
    menu: {
        id: 1,
        name: '',
        menus: []
    },
    profiles: [],
    person: {
        id: 0,
        name: ''
    }
};

const loginRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true });
const loginSuccess = (state = INITIAL_STATE, action: any) => ({ ...state, ...action.payload, isLoading: false });
const loginFailure = (state = INITIAL_STATE) => ({ ...state, isLoading: false });

const logout = (state = INITIAL_STATE) => ({ ...state, ...INITIAL_STATE });

// GET MENU
const getNavbarMenuRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true });

const getNavbarMenuSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoading: false,
    menu: action.payload
});

const getNavbarMenuFailure = (state = INITIAL_STATE) => ({ ...state, isLoading: false });

// MENU PREVIEW
const setMenuPreview = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoading: false,
    menuPreview: action.payload
});

const removeMenuPreview = (state = INITIAL_STATE) => ({
    ...state,
    isLoading: false,
    menuPreview: null
});

// PROFILES
const getProfileRequest = (state = INITIAL_STATE, action: any) => ({ ...state, isLoading: true });

const getProfileSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoading: false,
    profiles: action.payload
});

const getProfileFailure = (state = INITIAL_STATE) => ({ ...state, isLoading: false });

export default createReducer(INITIAL_STATE, {
    [Types.LOGIN_REQUEST]: loginRequest,
    [Types.LOGIN_SUCCESS]: loginSuccess,
    [Types.LOGIN_FAILURE]: loginFailure,

    [Types.GET_NAVBAR_MENU_REQUEST]: getNavbarMenuRequest,
    [Types.GET_NAVBAR_MENU_SUCCESS]: getNavbarMenuSuccess,
    [Types.GET_NAVBAR_MENU_FAILURE]: getNavbarMenuFailure,

    [Types.SET_MENU_PREVIEW]: setMenuPreview,
    [Types.REMOVE_MENU_PREVIEW]: removeMenuPreview,

    [Types.GET_PROFILE_REQUEST]: getProfileRequest,
    [Types.GET_PROFILE_SUCCESS]: getProfileSuccess,
    [Types.GET_PROFILE_FAILURE]: getProfileFailure,

    [Types.LOGOUT]: logout,

    [Types.CLEAR_LOADING]: getProfileFailure
});
