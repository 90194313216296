import React from 'react';

// Assets
import { CardContainer } from './styles';

interface IProps {
    id?: string;
    cardFooter?: JSX.Element;
    cardHeader?: JSX.Element;
    cardBody: JSX.Element;
    withSpacing?: boolean;
    style?: object;
    column?: boolean;
}

const Card = ({ cardBody, cardFooter, cardHeader, withSpacing = true, column = false, ...rest }: IProps) => (
    <CardContainer {...rest} withSpacing={withSpacing} column={column}>
        {cardHeader && cardHeader}
        {cardBody}
        {cardFooter && cardFooter}
    </CardContainer>
);

export default Card;
