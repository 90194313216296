import { Creators as learningObjectActions } from 'store/ducks/learningObject';
import { Creators as modalActions } from 'store/ducks/modal';
import { Creators as alertActions } from 'store/ducks/alert';
import { put, call } from 'redux-saga/effects';
import { uploadFile } from '../upload';
import { IGenericUploadPayload } from 'utils/interfaces/IGenericUploadPayload';
import { graphQLRequest } from '../graphQLRequest';

import { deleteMedia as deleteMediaQuery } from 'utils/queries/media/deleteMedia';

export function* genericUploadFile(action: { type: string; payload: IGenericUploadPayload }) {
    try {
        const { file, next, onChangeProgress } = action.payload;

        if (!file) {
            throw new Error();
        }

        const payloadUpload = {
            method: 'POST',
            endpoint: `/upload`,
            body: {
                file
            }
        };

        const fileURL = yield call(uploadFile, payloadUpload, (percentage) => {
            if (onChangeProgress) {
                onChangeProgress(percentage);
            }
        });

        if (!fileURL) {
            throw new Error();
        }

        yield put(learningObjectActions.uploadFileSuccess());

        yield put(modalActions.closeModal());

        if (next) {
            next(fileURL);
        }
    } catch (error) {
        console.log(error);
        yield put(learningObjectActions.uploadFileFailure());
    }
}

export function* deleteMedia(action: { type: string; payload: { mediaId: number; onDelete?: () => void } }) {
    try {
        const { mediaId, onDelete } = action.payload;

        if (!mediaId) {
            throw new Error();
        }

        const { data } = yield graphQLRequest(deleteMediaQuery, {
            id: mediaId,
            date: new Date().toISOString()
        });

        if (!data?.item?.id) {
            throw new Error();
        }

        if (onDelete) {
            onDelete();
        }

        yield put(learningObjectActions.deleteMediaSuccess());

        yield put(alertActions.showAlert('Imagem excluída com sucesso.', 'success'));
    } catch (error) {
        console.log(error);

        yield put(alertActions.showAlert('Ocorreu um erro ao excluir a imagem.', 'danger'));
        yield put(learningObjectActions.deleteMediaFailure());
    }
}
