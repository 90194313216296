import _get from 'lodash/get';

export const generateXWhere = (data: object, useQueryParams: boolean = false) => {
    return Object.keys(data)
        .map((key) => {
            const item = _get(data, key);

            if (item?.value === undefined || item?.value === null) {
                return null;
            }

            const propertyValue = () => {
                if (item?.type === 'number') {
                    return Number(item.value);
                }

                if (item?.type === 'enum') {
                    return isNaN(item?.value) ? item?.value : Number(item?.value);
                }

                if (item?.type === 'date' && useQueryParams) {
                    return (item.value as Date).toISOString();
                }

                if (useQueryParams) {
                    return `${item.value}`;
                }

                return `%${item.value}%`;
            };

            const propertyKey = () => {
                if (useQueryParams) {
                    return key.toLocaleLowerCase();
                }

                return key;
            };

            return `${propertyKey()}=${propertyValue()}`;
        })
        .filter(Boolean)
        .join(useQueryParams ? '&' : ';');
};
