import { lazy } from 'react';

const List = lazy(() => import('pages/crud'));
const CreateOrEdit = lazy(() => import('pages/crud/createOrEdit'));

const subjectRoutes = [
    {
        path: '/app/disciplinas',
        name: 'Disciplinas',
        component: List,
        exact: true,
        title: 'Disciplinas'
    },
    {
        path: '/app/disciplinas/novo',
        name: 'Nova disciplina',
        component: CreateOrEdit,
        exact: true,
        title: 'Nova disciplina'
    },
    {
        path: '/app/disciplinas/editar/:id',
        name: 'Editar',
        component: CreateOrEdit,
        exact: true,
        title: 'Editar disciplina'
    }
];

export default subjectRoutes;
