import { IField } from 'utils/interfaces/IField';
import InputTypes from 'utils/enums/inputTypes';

const company: IField[] = [
    {
        presentation: 'Nome',
        label: 'Nome da empresa',
        name: 'name',
        type: InputTypes.text,
        columns: 3
    }
];

export default company;
