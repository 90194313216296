import { TableCaretType } from 'components/tablev2/components/carets';

export const generateXOrder = (data: object) => {
    if (!data) {
        return;
    }

    return Object.keys(data)
        .map((key) => {
            const value = data[key];

            if (!value) {
                return null;
            }

            return value === TableCaretType.Ascending ? key : `-${key}`;
        })
        .filter(Boolean)
        .join(';');
};
