export const getAllCampaignsQuery = `query getAllCampaigns($orderBy: [prodigio_vw_campaigns_list_order_by!], $where: prodigio_vw_campaigns_list_bool_exp, $limit: Int, $offset: Int) {
  items: prodigio_vw_campaigns_list(where: $where, order_by: $orderBy, limit: $limit, offset: $offset) {
    id
    name
    is_active
    date_start
    date_finish
      code
      qtd
      usage
  }
  quantity: prodigio_vw_campaigns_list_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
`;
