import InputTypes from 'utils/enums/inputTypes';
import { IField } from 'utils/interfaces/IField';

const lessonPlanTag: IField[] = [
    {
        presentation: 'Nome',
        label: 'Nome da tag',
        name: 'name',
        type: InputTypes.text,
        columns: 3
    },
    {
        presentation: 'Cor',
        label: 'Cor da tag',
        name: 'color',
        type: InputTypes.color,
        columns: 3
    }
];

export default lessonPlanTag;
