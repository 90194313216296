import React, { useRef, useEffect, useState } from 'react';

// Dependencies
import { useField } from '@rocketseat/unform';
import { Styles } from 'react-select/lib/styles';
import { SelectComponentsProps } from 'react-select/lib/Select';

// Helpers
import theme from 'config/theme';
import hexToRgba from 'utils/hexToRgba';
import { IOption } from 'utils/interfaces/IOption';

// Assets
import { FormErrorMessage } from 'assets/global';
import { SelectElement, SelectAsync } from './styles';
import { IPayloadRequest } from 'utils/interfaces/IRequestAction';
import api from 'services/api';

import _debounce from 'lodash/debounce';
import { AxiosRequestConfig } from 'axios';

const selectStyles = (): Partial<Styles> => {
    return {
        control: (styles: any, state) => {
            return {
                ...styles,
                backgroundColor: '#FFF',
                borderColor: state.isFocused ? theme.colors.secondary : '#d2d2d2',
                boxShadow: 'none',
                width: '100%',

                ':hover': {
                    borderColor: theme.colors.secondary
                }
            };
        },
        option: (styles: any, state) => {
            return {
                ...styles,
                backgroundColor: state.isSelected ? hexToRgba(theme.colors.secondary, 0.1) : '#FFF',
                color: '#071f38',

                ':hover': {
                    backgroundColor: hexToRgba(theme.colors.secondary, 0.1)
                }
            };
        }
    };
};

interface ISelectProps extends SelectComponentsProps {
    async?: boolean;
    configRequest?: IPayloadRequest<any> & { searchFor: string };
    isLoading?: boolean;
    handleChange?: (data: any) => void;
    handleMenuScrollToBottom?: () => void;
}

//

function Select({ name, options, placeholder, multiple, async = false, isLoading, configRequest, handleChange, handleMenuScrollToBottom, ...rest }: ISelectProps) {
    const ref = useRef(null);
    const { fieldName, registerField, defaultValue, error } = useField(name);
    const [value, setValue] = useState(defaultValue);

    const isAsync = async && !!configRequest;

    function parseSelectValue(selectValue: any) {
        try {
            if (!multiple) {
                return selectValue?.props?.value?.value || {};
            }

            return selectValue?.props?.value || [];
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        registerField({
            name: fieldName,
            // @ts-ignore
            ref: ref.current,
            path: 'state.value.value',
            parseValue: parseSelectValue,
            clearValue: (selectRef: any) => selectRef.select.clearValue()
        });

        if (defaultValue) {
            setValue(defaultValue);

            // @ts-ignore
            ref.current.state.value = defaultValue;
        }
        // eslint-disable-next-line
    }, [ref.current, fieldName, defaultValue]);

    function getDefaultValue() {
        if (!defaultValue) {
            return null;
        }

        if (!multiple) {
            return options.find((option: IOption) => option.value === defaultValue.value);
        }

        return options?.filter((option: IOption) => defaultValue.includes(option?.value));
    }

    // TODO: FAZER DEBOUNCE FUNCIONAR!!
    const loadOptions = async (inputValue: any, callback: any) => {
        try {
            if (!isAsync || !inputValue) {
                return callback([]);
            }

            const payload: AxiosRequestConfig = {
                method: configRequest!.method,
                url: configRequest!.endpoint,
                headers: {
                    ...configRequest!.headers,
                    'X-Where': `${configRequest!.searchFor}=%${inputValue.toLowerCase()}%`
                },
                data: {
                    ...configRequest!.body
                }
            };

            const { data } = await api(payload);

            const response = (Array.isArray(data) ? data : data.items).map((item: any) => ({ value: item.id, label: item.title || item.name }));

            return response;
        } catch (error) {
            console.log(error);

            return callback([]);
        }
    };

    const debounceLoadOptions = _debounce(loadOptions, 500);

    if (isAsync) {
        return (
            <>
                <SelectAsync
                    // cacheOptions
                    loadOptions={debounceLoadOptions}
                    defaultOptions
                    // debounce
                    name={fieldName}
                    aria-label={fieldName}
                    // options={options}
                    // isMulti={multiple}
                    // defaultValue={getDefaultValue()}
                    ref={ref}
                    // getOptionValue={(option: IOption) => option.value}
                    // getOptionLabel={(option: IOption) => option.label}
                    classNamePrefix="select"
                    placeholder={placeholder}
                    noOptionsMessage={() => 'Nenhuma opção encontrada'}
                    styles={selectStyles()}
                    error={error}
                    className="selected_styled"
                    {...rest}
                />
                {error && <FormErrorMessage>{error}</FormErrorMessage>}
            </>
        );
    }

    return (
        <>
            <SelectElement
                isLoading={isLoading}
                name={fieldName}
                aria-label={fieldName}
                options={options}
                isMulti={multiple}
                defaultValue={getDefaultValue()}
                ref={ref}
                getOptionValue={(option: IOption) => option.value}
                getOptionLabel={(option: IOption) => option.label}
                classNamePrefix="select"
                placeholder={placeholder}
                value={value}
                noOptionsMessage={() => 'Nenhuma opção encontrada'}
                styles={selectStyles()}
                error={error}
                className="selected_styled"
                onMenuScrollToBottom={() => {
                    if (!!handleMenuScrollToBottom) {
                        handleMenuScrollToBottom();
                    }
                }}
                {...rest}
                onChange={(item: any) => {
                    if (rest?.onChange) {
                        rest.onChange(item);
                    }

                    setValue(item);
                }}
                onInputChange={(event: any) => {
                    if (!!handleChange) {
                        handleChange(event);
                    }
                }}
            />
            {error && <FormErrorMessage>{error}</FormErrorMessage>}
        </>
    );
}

export default Select;
