import React, { createContext, useCallback, useReducer, useContext, useRef } from 'react';
import _setWith from 'lodash/setWith';
import _clone from 'lodash/clone';

const DEBUG_MODE = true;

export const ProdigyFormContext = createContext({});

export const ProdigyFormProvider = ({ children }: any) => {
    const previousValues = useRef();
    const reducer = (state: any, action: any) => {
        const actions = {
            setValue: () => {
                const newState = _setWith(_clone(state), `values.${action.payload.field}`, action.payload.value, _clone);
                previousValues.current = state.values;
                return newState;
            },
            setInitialValues: () => {
                previousValues.current = state.values;
                return { ...state, values: action.payload };
            },
            setErrors: () => {
                return { ...state, errors: action.payload };
            },
            setValidationSchema: () => {
                return { ...state, validationSchema: action.payload };
            },
            doValidation: () => {
                const formSchema = state.validationSchema;

                try {
                    formSchema.validateSync(state.values, { abortEarly: false });

                    if (action.successCallback) {
                        action.successCallback(state.values);
                    }

                    return {
                        ...state,
                        errors: {}
                    };
                } catch (err) {
                    const errors = err.inner
                        .map((v: any) => ({ field: v.path, message: v.message }))
                        .reduce(
                            (accumulator: any, currentValue: any) => ({
                                ...accumulator,
                                [currentValue.field]: currentValue.message
                            }),
                            {}
                        );

                    if (action.errorCallback) {
                        action.errorCallback(errors);
                    }

                    return {
                        ...state,
                        errors
                    };
                }
            }
        };

        if (DEBUG_MODE) {
            if (action.payload) {
                // console.log(action.payload);
            }
        }

        return actions[action.type]();
    };
    const memoizedReducer = useCallback(reducer, []);
    const defaultValue = {
        values: {},
        errors: {},
        initialValues: {},
        validationSchema: {}
    };
    const [state, dispatch] = useReducer(memoizedReducer, defaultValue);

    return <ProdigyFormContext.Provider value={{ state, previousValues: previousValues.current, dispatch }}>{children}</ProdigyFormContext.Provider>;
};

export const ProdigyFormConsumer = ProdigyFormContext.Consumer;

export const useProdigyForm: any = () => {
    const prodigyForm = useContext(ProdigyFormContext);

    return prodigyForm;
};
