import { all, takeLatest, race, take, put, takeEvery } from 'redux-saga/effects';

// ActionTypes
import { Types as BrandTypes } from 'store/ducks/brand';
import { Types as CourseTypes } from 'store/ducks/course';
import { Types as CompanyTypes } from 'store/ducks/company';
import { Types as LessonPlanTypes } from 'store/ducks/lessonPlan';
import { Types as ModalTypes } from 'store/ducks/modal';
import { Types as LessonPlanModulesTypes } from 'store/ducks/lessonPlanModules';
import { Types as LessonPlanEventsTypes } from 'store/ducks/lessonPlanEvents';
import { Types as SearchTypes } from 'store/ducks/search';
import { Types as TeacherTypes } from 'store/ducks/teacher';
import { Types as LearningObjectTypes } from 'store/ducks/learningObject';
import { Types as ExerciseListTypes } from 'store/ducks/exerciseList';
import { Types as DashboardTypes } from 'store/ducks/dashboard';
import { Types as AlertTypes } from 'store/ducks/alert';
import { Types as VideosTypes } from 'store/ducks/videos';
import { Types as SchoolunitTypes } from 'store/ducks/schoolunit';
import { Types as SchoolClassTypes } from 'store/ducks/schoolClass';
import { Types as SchoolGradeTypes } from 'store/ducks/schoolGrade';
import { Types as SchoolPeriodTypes } from 'store/ducks/schoolPeriod';
import { Types as TimezoneTypes } from 'store/ducks/timezone';
import { Types as NotificationTypes } from 'store/ducks/notification';
import { Types as ProfilesTypes } from 'store/ducks/profiles';
import { Types as MenuTypes } from 'store/ducks/menu';
import { Types as UserTypes } from 'store/ducks/user';
import { Types as OrderTypes } from 'store/ducks/order';
import { Types as FilesTypes } from 'store/ducks/files';
import { Types as SubjectGroupsTypes } from 'store/ducks/subjectGroups';
import { Types as SellerTypes } from 'store/ducks/seller';

// Saga methods
import auth from './auth';
import crud from './crud';
import answerCard from './answerCard';
import exam from './exam';
import store from './store';
import productPhysical from './productPhysical';
import product from './product';
import qrcode from './qrcode';
import project from './project';
import complaint from './complaint';
import question from './question';
import lessonPlanEducational from './lesson-plan-educational';
import campaign from './campaign';
import examFiles from './examFiles';
import student from './student';
import contest from './contest';
import bunnyVideo from './bunnyVideo';
import answerQuestions from './answerQuestions';

import { getBrandById, createOrEditUserBrand, createOrEditBrand, getAllBrands } from './brand';
import {
    getCourseById,
    createOrEditCourse,
    createModule,
    deleteModule,
    createBookletContentItem,
    getModulesBySubject,
    deleteBookletContentItem,
    createModuleAndTemplate,
    associateQrCodeToModule,
    associateQrCodeToBookletContentItem,
    editBookletModule,
    getBookletModuleTemplates,
    saveBookletModuleTemplate,
    reorderCourseItems,
    reorderCourseContentItems,
    editBookletContentItem,
    disassociateQrCodeToBookletContentItem
} from './course';
import { getCompanies, setCurrentBrand, getCompanyById, createOrEditCompany, createOrEditCompanyUsers, setCurrentUnit, setCurrentCompany } from './company';
import { createOrEditLessonPlan, getLessonPlan } from './lessonPlan';
import { searchRequest } from './search';
import { teacherRequest, getTeacherById, createOrEditTeacher, createOrEditTeacherAddress, deleteTeacherAddress } from './teacher';
import { getLessonPlanModules, createOrEditLessonPlanModule, deleteLessonPlanModules } from './lessonPlanModules';
import { openModalRequest } from './modal';
import {
    getLessonPlanEvents,
    createOrEditLessonPlanEvents,
    deleteLessonPlanEvents,
    getHandShakeLessonPlanEvents,
    deleteLessonPlanEventsContent,
    createOrEditLessonPlanEventsContent,
    getLessonPlanEventById,
    createLessonPlanEventVideo
} from './lessonPlanEvents';
import { createLOVideo, createLOExerciseList, createLOMaterial, deleteLO, createLOSearch } from './learningObject';
import { showAlert } from './alert';
import { timezoneRequest } from './timezone';
import {
    getExerciseList,
    createOrEditExerciseList,
    deleteExerciseList,
    createExerciseListItem,
    deleteExerciseListItem,
    searchExerciseLists,
    sendExerciseListToStudents,
    getExerciseListStudents,
    cancelExerciselistItem,
    deleteExerciseListStudent
} from './exerciseList';

import { getDashboardContent } from './dashboard';
import { getVideoById, createOrEditVideo, searchVideos, associateVideoQRCode, getTopicRequest, activateOrDeactivateTopic } from './videos';
import { getSchoolUnitById, createOrEditSchoolUnit, getAllSchoolUnits } from './schoolunit';
import {
    getSchoolClassById,
    getSchoolClasses,
    createOrEditSchoolClass,
    createOrEditTeacherSchoolClass,
    createOrEditStudentSchoolClass,
    deleteTeacherSchoolClass,
    deleteStudentSchoolClass,
    getTeachersBySubject
} from './schoolClass';

import { getSellerById, createOrEditSeller } from './seller';

// Actions
import { Creators as modalActions } from 'store/ducks/modal';

// Helpers
import HttpStatus from 'utils/enums/httpStatus';
import { ICrudAction } from 'utils/interfaces/ICrud';
import { getAllSchoolGrades, getSchoolGrade, getSchoolGradeProjects, sendProjectToSchoolGrade } from './schoolGrade';
import { getAllSchoolPeriods } from './schoolPeriod';
import { getNotification, sendNotification } from './notification';
import { getProfileById, createOrEditProfile } from './profiles';
import { getMenuById, createOrEditMenu } from './menu';
import { getUserById, createOrEditUser, changeUserPassword } from './user';
import { getOrderById, refoundOrder } from './order';
import { getFileById, createOrEditFile, updateMaterialFile } from './files';
import {
    getAllSubjectGroups,
    createOrEditSubjectGroup,
    deleteSubjectGroup,
    requestSubjectTeachers,
    addSubjectTeacher,
    addSubjectGroupSubject,
    deleteSubjectGroupSubject,
    deleteSubjectGroupTeacher,
    reorderSubjectGroups
} from './subjectGroups';
import { genericUploadFile, deleteMedia } from './learningObject/genericUpload';

function monitorableAction(action: any) {
    return action.type.includes('REQUEST');
}

function identifyAction(action: any) {
    return action.type.split('_').slice(0, -1).join('_');
}
function getSuccessType(action: any) {
    return `${identifyAction(action)}_SUCCESS`;
}
function getFailType(action: any) {
    return `${identifyAction(action)}_FAILURE`;
}

function* monitor(monitoredAction: ICrudAction) {
    const { fail } = yield race({
        success: take(getSuccessType(monitoredAction)),
        fail: take(getFailType(monitoredAction))
    });

    if (fail && fail.payload && fail.payload.code === HttpStatus.UNAUTHORIZED) {
        yield put(modalActions.openModal('newLogin', monitoredAction));
    }
}

export default function* rootSaga() {
    yield all([
        // Auth Sagas
        ...auth,
        // Answercard sagas
        ...answerCard,
        // PRODUCT PHYSICAL
        ...productPhysical,
        // Project
        ...project,

        // Crud Sagas
        ...crud,

        // Question
        ...question,

        // Lesson plan educational
        ...lessonPlanEducational,
        ...examFiles,

        // Companies sagas
        takeLatest(CompanyTypes.GET_COMPANIES_REQUEST, getCompanies),
        takeLatest(CompanyTypes.GET_COMPANY_BY_ID_REQUEST, getCompanyById),
        takeLatest(CompanyTypes.CREATE_OR_EDIT_COMPANY_REQUEST, createOrEditCompany),
        takeLatest(CompanyTypes.CREATE_OR_EDIT_COMPANY_USER_REQUEST, createOrEditCompanyUsers),

        // Brand
        takeLatest(BrandTypes.GET_ALL_BRANDS_REQUEST, getAllBrands),
        takeLatest(BrandTypes.GET_BRAND_REQUEST, getBrandById),
        takeLatest(BrandTypes.CREATE_OR_EDIT_BRAND_REQUEST, createOrEditBrand),
        takeLatest(BrandTypes.CREATE_OR_EDIT_USERS_BRAND_REQUEST, createOrEditUserBrand),

        // Current Brand and Unit sagas
        takeLatest(CompanyTypes.SET_CURRENT_COMPANY_REQUEST, setCurrentCompany),
        takeLatest(CompanyTypes.SET_CURRENT_BRAND_REQUEST, setCurrentBrand),
        takeLatest(CompanyTypes.SET_CURRENT_UNIT_REQUEST, setCurrentUnit),

        // Course sagas
        takeLatest(CourseTypes.GET_COURSE_BY_ID_REQUEST, getCourseById),
        takeLatest(CourseTypes.CREATE_OR_EDIT_COURSE_REQUEST, createOrEditCourse),
        takeLatest(CourseTypes.CREATE_MODULE_REQUEST, createModule),
        takeLatest(CourseTypes.CREATE_BOOKLET_CONTENT_ITEM_REQUEST, createBookletContentItem),
        takeLatest(CourseTypes.DELETE_MODULE_REQUEST, deleteModule),
        takeLatest(CourseTypes.GET_MODULES_BY_SUBJECT_REQUEST, getModulesBySubject),
        takeLatest(CourseTypes.DELETE_BOOKLET_CONTENT_ITEM_REQUEST, deleteBookletContentItem),
        takeLatest(CourseTypes.CREATE_MODULE_AND_TEMPLATE_REQUEST, createModuleAndTemplate),
        takeLatest(CourseTypes.ASSOCIATE_QR_CODE_TO_MODULE_REQUEST, associateQrCodeToModule),
        takeLatest(CourseTypes.ASSOCIATE_QR_CODE_TO_BOOKLET_CONTENT_ITEM_REQUEST, associateQrCodeToBookletContentItem),
        takeLatest(CourseTypes.EDIT_COURSE_MODULE_REQUEST, editBookletModule),
        takeLatest(CourseTypes.SAVE_BOOKLET_MODULE_TEMPLATE_REQUEST, saveBookletModuleTemplate),
        takeLatest(CourseTypes.GET_BOOKLET_MODULE_TEMPLATES_REQUEST, getBookletModuleTemplates),
        takeLatest(CourseTypes.REORDER_COURSE_ITEMS_REQUEST, reorderCourseItems),
        takeLatest(CourseTypes.REORDER_COURSE_CONTENT_ITEMS_REQUEST, reorderCourseContentItems),
        takeLatest(CourseTypes.EDIT_COURSE_CONTENT_ITEM_REQUEST, editBookletContentItem),
        takeLatest(CourseTypes.DISASSOCIATE_QR_CODE_TO_BOOKLET_CONTENT_ITEM_REQUEST, disassociateQrCodeToBookletContentItem),

        // Lesson Plan sagas
        takeLatest(LessonPlanTypes.CREATE_OR_EDIT_LESSON_PLAN_REQUEST, createOrEditLessonPlan),
        takeLatest(LessonPlanTypes.GET_LESSON_PLAN_REQUEST, getLessonPlan),

        // Lesson Plan Modules Sagas
        takeLatest(LessonPlanModulesTypes.GET_LESSON_PLAN_MODULES_REQUEST, getLessonPlanModules),
        takeLatest(LessonPlanModulesTypes.CREATE_OR_EDIT_LESSON_PLAN_MODULES_REQUEST, createOrEditLessonPlanModule),
        takeLatest(LessonPlanModulesTypes.DELETE_LESSON_PLAN_MODULES_REQUEST, deleteLessonPlanModules),

        // Lesson Plan Events Sagas
        takeLatest(LessonPlanEventsTypes.GET_LESSON_PLAN_EVENTS_REQUEST, getLessonPlanEvents),
        takeLatest(LessonPlanEventsTypes.CREATE_OR_EDIT_LESSON_PLAN_EVENTS_REQUEST, createOrEditLessonPlanEvents),
        takeLatest(LessonPlanEventsTypes.DELETE_LESSON_PLAN_EVENTS_REQUEST, deleteLessonPlanEvents),
        takeLatest(LessonPlanEventsTypes.GET_HAND_SHAKE_LESSON_PLAN_EVENTS_REQUEST, getHandShakeLessonPlanEvents),
        takeLatest(LessonPlanEventsTypes.DELETE_LESSON_PLAN_EVENTS_CONTENT_REQUEST, deleteLessonPlanEventsContent),
        takeLatest(LessonPlanEventsTypes.CREATE_OR_EDIT_LESSON_PLAN_EVENTS_CONTENT_REQUEST, createOrEditLessonPlanEventsContent),
        takeLatest(LessonPlanEventsTypes.GET_LESSON_PLAN_EVENT_BY_ID_REQUEST, getLessonPlanEventById),
        takeLatest(LessonPlanEventsTypes.CREATE_LESSON_PLAN_EVENT_VIDEO_REQUEST, createLessonPlanEventVideo),

        // Modal sagas
        takeLatest(ModalTypes.OPEN_MODAL_REQUEST, openModalRequest),

        // Search sagas
        takeEvery(SearchTypes.SEARCH_REQUEST, searchRequest),

        // Teacher sagas
        takeLatest(TeacherTypes.TEACHER_REQUEST, teacherRequest),
        takeLatest(TeacherTypes.GET_TEACHER_BY_ID_REQUEST, getTeacherById),
        takeLatest(TeacherTypes.CREATE_OR_EDIT_TEACHER_REQUEST, createOrEditTeacher),
        takeLatest(TeacherTypes.CREATE_OR_EDIT_TEACHER_ADDRESS_REQUEST, createOrEditTeacherAddress),
        takeLatest(TeacherTypes.DELETE_TEACHER_ADDRESS_REQUEST, deleteTeacherAddress),

        // School Unit Sagas
        takeLatest(SchoolunitTypes.GET_ALL_SCHOOL_UNITS_REQUEST, getAllSchoolUnits),
        takeLatest(SchoolunitTypes.GET_SCHOOL_UNIT_BY_ID_REQUEST, getSchoolUnitById),
        takeLatest(SchoolunitTypes.CREATE_OR_EDIT_SCHOOL_UNIT_REQUEST, createOrEditSchoolUnit),

        // School Class Sagas
        takeLatest(SchoolClassTypes.GET_SCHOOL_CLASS_BY_ID_REQUEST, getSchoolClassById),
        takeLatest(SchoolClassTypes.GET_SCHOOL_CLASSES_REQUEST, getSchoolClasses),
        takeLatest(SchoolClassTypes.CREATE_OR_EDIT_SCHOOL_CLASS_REQUEST, createOrEditSchoolClass),
        takeLatest(SchoolClassTypes.CREATE_OR_EDIT_TEACHER_SCHOOL_CLASS_REQUEST, createOrEditTeacherSchoolClass),
        takeLatest(SchoolClassTypes.CREATE_OR_EDIT_STUDENT_SCHOOL_CLASS_REQUEST, createOrEditStudentSchoolClass),
        takeLatest(SchoolClassTypes.DELETE_TEACHER_SCHOOL_CLASS_REQUEST, deleteTeacherSchoolClass),
        takeLatest(SchoolClassTypes.DELETE_STUDENT_SCHOOL_CLASS_REQUEST, deleteStudentSchoolClass),
        takeLatest(SchoolClassTypes.GET_TEACHER_SCHOOL_CLASS_REQUEST, getTeachersBySubject),

        // School Grade
        takeLatest(SchoolGradeTypes.GET_ALL_SCHOOL_GRADES_REQUEST, getAllSchoolGrades),
        takeLatest(SchoolGradeTypes.GET_SCHOOL_GRADE_REQUEST, getSchoolGrade),
        takeLatest(SchoolGradeTypes.GET_ALL_SCHOOL_GRADE_PROJECTS_REQUEST, getSchoolGradeProjects),
        takeLatest(SchoolGradeTypes.SEND_PROJECT_TO_SCHOOL_GRADE_REQUEST, sendProjectToSchoolGrade),

        // School Period
        takeLatest(SchoolPeriodTypes.GET_ALL_SCHOOL_PERIODS_REQUEST, getAllSchoolPeriods),

        // Videos Sagas
        takeLatest(VideosTypes.SEARCH_VIDEOS_REQUEST, searchVideos),
        takeLatest(VideosTypes.GET_VIDEO_BY_ID_REQUEST, getVideoById),
        takeLatest(VideosTypes.CREATE_OR_EDIT_VIDEO_REQUEST, createOrEditVideo),
        takeLatest(VideosTypes.ASSOCIATE_VIDEO_QR_CODE_REQUEST, associateVideoQRCode),
        takeLatest(VideosTypes.GET_TOPIC_REQUEST, getTopicRequest),
        takeLatest(VideosTypes.ACTIVATE_OR_DEACTIVATE_TOPIC, activateOrDeactivateTopic),

        // Learning Object sagas
        takeLatest(LearningObjectTypes.CREATE_LO_VIDEO_REQUEST, createLOVideo),
        takeLatest(LearningObjectTypes.CREATE_LO_EXERCISE_LIST_REQUEST, createLOExerciseList),
        takeLatest(LearningObjectTypes.CREATE_LO_MATERIAL_REQUEST, createLOMaterial),
        takeLatest(LearningObjectTypes.DELETE_LO_REQUEST, deleteLO),
        takeLatest(LearningObjectTypes.CREATE_LO_SEARCH_REQUEST, createLOSearch),
        takeLatest(LearningObjectTypes.UPLOAD_FILE_REQUEST, genericUploadFile),
        takeLatest(LearningObjectTypes.DELETE_MEDIA_REQUEST, deleteMedia),

        // Exams sagas
        ...exam,

        // Exercise list sagas
        takeLatest(ExerciseListTypes.GET_EXERCISE_LIST_REQUEST, getExerciseList),
        takeLatest(ExerciseListTypes.SEARCH_EXERCISE_LISTS_REQUEST, searchExerciseLists),
        takeLatest(ExerciseListTypes.CREATE_OR_EDIT_EXERCISE_LIST_REQUEST, createOrEditExerciseList),
        takeLatest(ExerciseListTypes.DELETE_EXERCISE_LIST_REQUEST, deleteExerciseList),
        takeLatest(ExerciseListTypes.CREATE_EXERCISE_LIST_ITEM_REQUEST, createExerciseListItem),
        takeLatest(ExerciseListTypes.DELETE_EXERCISE_LIST_ITEM_REQUEST, deleteExerciseListItem),
        takeLatest(ExerciseListTypes.SEND_EXERCISE_LIST_TO_STUDENTS_REQUEST, sendExerciseListToStudents),
        takeLatest(ExerciseListTypes.GET_EXERCISE_LIST_STUDENTS_REQUEST, getExerciseListStudents),
        takeLatest(ExerciseListTypes.CANCEL_EXERCISE_LIST_ITEM_REQUEST, cancelExerciselistItem),
        takeLatest(ExerciseListTypes.DELETE_EXERCISE_LIST_STUDENT_REQUEST, deleteExerciseListStudent),

        // Dashboard sagas
        takeLatest(DashboardTypes.GET_DASHBOARD_CONTENT_REQUEST, getDashboardContent),

        // Alert
        takeLatest(AlertTypes.SHOW_ALERT, showAlert),

        // Timezone
        takeLatest(TimezoneTypes.TIMEZONE_REQUEST, timezoneRequest),

        // QRCodes
        ...qrcode,

        // Notification
        takeLatest(NotificationTypes.GET_NOTIFICATION_REQUEST, getNotification),
        takeLatest(NotificationTypes.SEND_NOTIFICATION_REQUEST, sendNotification),

        // Student
        ...student,

        // Profiles
        takeLatest(ProfilesTypes.GET_PROFILE_BY_ID_REQUEST, getProfileById),
        takeLatest(ProfilesTypes.CREATE_OR_EDIT_PROFILE_REQUEST, createOrEditProfile),

        // MENU
        takeLatest(MenuTypes.GET_MENU_REQUEST, getMenuById),
        takeLatest(MenuTypes.CREATE_OR_EDIT_MENU_REQUEST, createOrEditMenu),

        // User
        takeLatest(UserTypes.GET_USER_REQUEST, getUserById),
        takeLatest(UserTypes.CREATE_OR_EDIT_USER_REQUEST, createOrEditUser),
        takeLatest(UserTypes.CHANGE_USER_PASSWORD_REQUEST, changeUserPassword),

        // Product
        ...product,

        // Store
        ...store,

        // Seller
        takeLatest(SellerTypes.GET_SELLER_REQUEST, getSellerById),
        takeLatest(SellerTypes.CREATE_OR_EDIT_SELLER_REQUEST, createOrEditSeller),

        // Campaign
        ...campaign,

        // Order
        takeLatest(OrderTypes.GET_ORDER_REQUEST, getOrderById),
        takeLatest(OrderTypes.REFOUND_ORDER_REQUEST, refoundOrder),

        // Files
        takeLatest(FilesTypes.GET_FILE_REQUEST, getFileById),
        takeLatest(FilesTypes.CREATE_OR_EDIT_FILE_REQUEST, createOrEditFile),
        takeLatest(FilesTypes.EDIT_MATERIAL_FILE, updateMaterialFile),

        // Subject Groups
        takeLatest(SubjectGroupsTypes.GET_SUBJECT_GROUPS_REQUEST, getAllSubjectGroups),
        takeLatest(SubjectGroupsTypes.CREATE_OR_EDIT_SUBJECT_GROUP_REQUEST, createOrEditSubjectGroup),
        takeLatest(SubjectGroupsTypes.DELETE_SUBJECT_GROUP_REQUEST, deleteSubjectGroup),
        takeLatest(SubjectGroupsTypes.GET_SUBJECT_GROUPS_TEACHERS_REQUEST, requestSubjectTeachers),
        takeLatest(SubjectGroupsTypes.ADD_SUBJECT_GROUPS_TEACHER_REQUEST, addSubjectTeacher),
        takeLatest(SubjectGroupsTypes.ADD_SUBJECT_GROUP_SUBJECT_REQUEST, addSubjectGroupSubject),
        takeLatest(SubjectGroupsTypes.DELETE_SUBJECT_GROUP_SUBJECT_REQUEST, deleteSubjectGroupSubject),
        takeLatest(SubjectGroupsTypes.DELETE_SUBJECT_GROUP_TEACHER_REQUEST, deleteSubjectGroupTeacher),
        takeLatest(SubjectGroupsTypes.REORDER_SUBJECT_GROUPS_REQUEST, reorderSubjectGroups),

        // Complaints
        ...complaint,

        ...contest,

        ...bunnyVideo,

        ...answerQuestions,

        // All requests
        takeEvery(monitorableAction, monitor)
    ]);
}
