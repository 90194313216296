import InputTypes from 'utils/enums/inputTypes';
import { IField } from 'utils/interfaces/IField';

const exams: IField[] = [
    {
        presentation: 'Nome',
        label: 'Nome da prova anterior',
        name: 'name',
        type: InputTypes.text,
        columns: 4,
        required: true
    },
    {
        presentation: 'Ano',
        label: 'Ano',
        name: 'year',
        type: InputTypes.number,
        columns: 4,
        required: true
    },
    {
        presentation: 'Selecione um concurso',
        label: 'Concurso',
        name: 'contest',
        type: InputTypes.simpleSelect,
        columns: 4,
        required: true
    },
    {
        presentation: 'Descrição',
        label: 'Descrição',
        name: 'description',
        type: InputTypes.textArea,
        columns: 12,
        required: false
    }
];

export default exams;
