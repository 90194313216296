import styled from 'styled-components';
import theme from 'config/theme';
import { AvatarSize } from '.';
import { TooltipContainer } from 'components/tooltip/styles';

const setSize = (size: AvatarSize) => {
    if (size === 'small') {
        return '28px';
    } else if (size === 'smallX') {
        return '40px';
    } else if (size === 'medium') {
        return '80px';
    }

    return '160px';
};

export const UserInitial = styled.span`
    color: ${theme.colors.secondary};
    font-family: ${theme.typography.family.primary.bold.regular};
    line-height: 1;
`;

export const AvatarContainer = styled.figure<{ size: 'small' | 'smallX' | 'medium' | 'large' }>`
    align-items: center;
    background-color: ${theme.colors.white};
    background-position: center;
    background-size: cover !important;
    border: 2px solid #fff;
    border-radius: 50%;
    display: flex;
    height: ${({ size }) => setSize(size)};
    justify-content: center;
    text-transform: uppercase;
    width: ${({ size }) => setSize(size)};
    margin-right: 4px;

    ${UserInitial} {
        font-size: ${({ size }) => size === 'large' && '48px'};
    }

    :hover {
        position: relative;

        ${TooltipContainer} {
            visibility: visible;
            opacity: 1;
        }
    }
`;
