import React, { memo, useEffect, useMemo, useState, useRef } from 'react';

import * as S from './styles';

import useDebounce from 'hooks/useDebounce';
import { ITableV2Props } from 'utils/interfaces/ITable';
import Pagination from 'components/pagination';

import Header from './components/header';
import Body from './components/body';
import TableError from './error';

const Tablev2 = ({
    columns = [],
    actions = [],
    data,
    pagination,
    isLoading,
    handleChangePagination = () => null,
    mainColumn,
    handleChangeSearch = () => null,
    isEmpty = false,
    ordenation,
    handleChangeOrdenation = () => null,
    error = false,
    sortBySingleField = false
}: ITableV2Props) => {
    const [search, setSearch] = useState<object>();

    const hasActions = useMemo(() => !!actions?.length, [actions]);
    const debounceSearch = useDebounce(search, 600);

    const tmpTableId = useRef(Math.random());

    useEffect(() => {
        if (!columns) {
            return;
        }

        tmpTableId.current = Math.random();
    }, [columns]);

    useEffect(() => {
        if (!debounceSearch) {
            return;
        }

        handleChangeSearch(debounceSearch[tmpTableId.current]);
    }, [debounceSearch, handleChangeSearch]);

    return (
        <S.TableContainer>
            <Header
                columns={columns}
                onChangeSearch={(data) => setSearch({ [tmpTableId.current]: data })}
                searchData={!!search ? search[tmpTableId.current] : undefined}
                hasActions={hasActions}
                hasOrdenations={!!handleChangeOrdenation}
                orderData={ordenation}
                onChangeOrdenation={handleChangeOrdenation}
                tableId={tmpTableId.current}
                sortBySingleField={sortBySingleField}
            />

            {error ? (
                <TableError />
            ) : (
                <>
                    <Body columns={columns} hasActions={hasActions} isLoading={isLoading} mainColumn={mainColumn} data={data} actions={actions} isEmpty={isEmpty} tableId={tmpTableId.current} />

                    {(pagination?.totalPages ?? 0) > 1 && <Pagination current={pagination?.page ?? 1} total={pagination?.totalPages ?? 1} onPageChange={handleChangePagination} />}
                </>
            )}
        </S.TableContainer>
    );
};

export default memo(Tablev2);
