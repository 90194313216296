import { lazy } from 'react';

const List = lazy(() => import('pages/crud'));
const UploadVideo = lazy(() => import('pages/bunnyVideo/uploadBunny'));

const bunnyVideo = [
    {
        path: '/app/videos/fila-de-uploads',
        name: 'Fila de upload',
        component: List,
        exact: true,
        title: 'Fila de uploads'
    },
    {
        path: '/app/videos/fila-de-uploads/novo',
        name: 'Upload',
        component: UploadVideo,
        exact: true,
        title: 'Subir vídeo'
    }
];

export default bunnyVideo;
