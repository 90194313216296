import { OrderStatus } from './enums/product';

export const renderBadgeVariantByStatus = (value: any, enumValue: any) => {
    const variants = {
        Free: 'success',
        [OrderStatus.Paid]: 'success',
        Used: 'warning',
        Cancelled: 'danger',
        Discarted: 'danger',
        Pending: 'primary',
        Aproved: 'success',
        Finished: 'success',
        Discarded: 'danger',
        Processing: 'secondary',
        Available: 'success',
        Archiving: 'secondary',
        Archived: 'danger',
        Restoring: 'secondary'
    };

    const key = isNaN(+value) ? value : enumValue[value];

    return variants[key] || 'primary';
};
