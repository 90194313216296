import { lazy } from 'react';

const List = lazy(() => import('pages/crud'));
const QrCodes = lazy(() => import('pages/qrcodes'));
const Create = lazy(() => import('pages/qrcodes/create'));

const qrcodesRoutes = [
    {
        path: '/app/qrcodes',
        name: 'QR Codes',
        component: List,
        exact: true,
        title: 'QR Codes'
    },
    {
        path: '/app/qrcodes/novo',
        name: 'Novos QR Codes',
        component: Create,
        exact: true,
        title: 'Novos QR Codes'
    },
    {
        path: '/app/qrcodes/ver/:id',
        name: 'Ver',
        component: QrCodes,
        exact: true,
        title: 'Ver qr code'
    }
];

export default qrcodesRoutes;
