export enum ProductCredits {
    Assinatura = 1,
    Curso = 3,
    Redacao = 4,
    Simulado = 5,
    Projeto = 6
}

export enum ProductPeriodicity {
    Assinatura = 1,
    Mensal,
    Semanal
}

export enum ProductPaymentForms {
    'Cartão' = 1,
    Boleto
}

export enum ProductTimeOut {
    'Semana(s)' = 1,
    'Mês(es)',
    'Ano(s)'
}

export enum ProductTimeOutType {
    weeks = 1,
    months,
    years
}

export enum ProductPaymentFormsSlug {
    creditCard = 1,
    bankSlip
}

export enum ProductPaymentFormsName {
    'Cartão de crédito' = 1,
    'Boleto bancário'
}

export enum OrderStatus {
    AwaitingPayment = 'waiting_payment',
    AuthorizedPayment = 'authorized_payment',
    Paid = 'paid',
    UnauthorizedPayment = 'unauthorized_payment',
    RefundedPayment = 'refunded_payment',
    Processing = 'processing',
    RefusedPayment = 'refused_payment'
}

export enum OrderStatusDictionary {
    'awaiting_payment' = 'Aguardando pagamento',
    'waiting_payment' = 'Aguardando pagamento',
    'authorized_payment' = 'Pagamento autorizado',
    paid = 'Pago',
    'unauthorized_payment' = 'Pagamento não autorizado',
    'refunded_payment' = 'Pagamento estornado',
    processing = 'Processando',
    'refused_payment' = 'Pagamento recusado'
}

export enum PaymentFormDictionary {
    Boleto = 'Boleto bancário',
    'Cartão' = 'Cartão de crédito'
}
