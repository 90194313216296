import { lazy } from 'react';

const List = lazy(() => import('pages/crud'));
const CreateOrEdit = lazy(() => import('pages/crud/createOrEdit'));

const lessonPlanTagRoutes = [
    {
        path: '/app/plano-de-estudos/tags',
        name: 'Tags',
        component: List,
        exact: true,
        title: 'Tags do Plano de Estudos'
    },
    {
        path: '/app/plano-de-estudos/tags/novo',
        name: 'Novo tópico',
        component: CreateOrEdit,
        exact: true,
        title: 'Nova tag'
    },
    {
        path: '/app/plano-de-estudos/tags/editar/:id',
        name: 'Editar',
        component: CreateOrEdit,
        exact: true,
        title: 'Editar'
    }
];

export default lessonPlanTagRoutes;
