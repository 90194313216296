import styled from 'styled-components';

export const ToggleButton = styled.div`
    align-items: center;
    display: flex;
`;

export const ToggleLabel = styled.label`
    align-items: center;
    color: #7c8195;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    line-height: 16px;

    > span {
        margin-left: 10px;
    }
`;
