import styled, { css } from 'styled-components/macro';
import theme from 'config/theme';
import { ButtonContainer } from 'components/button/styles';
import { Subtitle, SmallTitle } from 'assets/global';
import { ResizableBox } from 'react-resizable';
import { FormGroup } from 'components/forms/styles';

export const ModalContainer = styled.div<{ isVisible: boolean }>`
    align-items: center;
    background: rgba(0, 0, 0, 0.35);
    display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
    height: 100%;
    justify-content: center;
    left: 0;
    position: fixed;
    top: 0;
    transition: all 0.3s;
    width: 100%;
    z-index: 999;
`;

export const ModalWrapper = styled.div`
    background: ${theme.colors.white};
    border-radius: 5px;
    max-height: 100%;
    max-width: 100%;
    min-width: 400px;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;

    > ${ButtonContainer} {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 10;
    }
`;

export const ModalWrapperResizable = styled(ResizableBox)`
    background: ${theme.colors.white};
    border-radius: 5px;
    max-height: 100%;
    max-width: 100%;
    min-width: 400px;
    overflow-y: auto;
    position: relative;

    > ${ButtonContainer} {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 10;
    }
`;

export const ModalHeader = styled.header`
    border-bottom: 1px solid ${theme.colors.borderColor};
    padding: ${theme.spacing.medium.all};
    width: 100%;

    ${SmallTitle} {
        display: block;
        overflow: hidden;
        text-align: center;
        text-overflow: ellipsis;
        text-transform: uppercase;
        white-space: nowrap;
    }
`;

export const ModalContent = styled.div<{ withSpacing?: boolean }>`
    padding: ${({ withSpacing }) => (withSpacing ? theme.spacing.large.all : 0)};

    ${Subtitle} {
        display: block;
        padding-bottom: ${theme.spacing.medium.all};
        text-align: center;
        text-transform: uppercase;
    }

    > ${FormGroup} {
        &:last-child {
            margin-bottom: 0;
        }
    }
`;

export const ModalFooter = styled.footer<{ horizontalSpaced?: boolean }>`
    border-top: 1px solid ${theme.colors.borderColor};
    padding: ${theme.spacing.large.all};

    ${ButtonContainer} {
        text-transform: uppercase;
    }

    ${({ horizontalSpaced }) => {
        if (!horizontalSpaced) {
            return;
        }

        return css`
            align-items: center;
            display: flex;
            justify-content: space-between;

            ${ButtonContainer} {
                &:first-child {
                    margin-right: 15px !important;
                }
            }
        `;
    }}
`;

export const ModalTitle = styled.strong``;

export const ModalConfirmActionContainer = styled.div`
    min-width: 500px;
    text-align: center;

    ${SmallTitle} {
        text-transform: uppercase;
    }

    .ico-exclamation {
        margin: 0 auto 10px;
        fill: ${theme.colors.system.danger};
    }
`;

export const ModalConfirmActionText = styled.p`
    font-size: 20px;

    strong {
        color: ${theme.colors.system.danger};
        display: block;
    }
`;

export const ModalConfirmActionFooter = styled.footer`
    border-top: 1px solid ${theme.colors.borderColor};
    display: flex;

    ${ButtonContainer} {
        border-radius: 0;
        flex: 1;
        text-transform: uppercase;
    }
`;

ModalContent.defaultProps = {
    withSpacing: true
};

export const PreviewUrlContainer = styled.div`
    height: 600px;
    width: 800px;

    ${ModalContent} {
        height: calc(100% - 64px);
    }
`;

export const PreviewUrlEmbed = styled.iframe`
    height: 100%;
    width: 100%;
`;

export const ModalContainerRef = styled.div`
    max-height: calc(100% - 80px);
    max-width: calc(100% - 80px);
    display: flex;
`;
