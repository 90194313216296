import { createActions, createReducer } from 'reduxsauce';
import { IAlertState } from 'store/types/IAlert';

export const { Types, Creators } = createActions({
    showAlert: ['message', 'alertType'],
    showAlertSuccess: ['payload'],
    hideAlert: ['payload']
});

const INITIAL_STATE: IAlertState = {
    items: []
};

const showAlert = (state = INITIAL_STATE, action: any) => {
    return state;
};

const showAlertSuccess = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        items: [...state.items, action.payload]
    };
};

const hideAlert = (state = INITIAL_STATE, action: any) => {
    const { payload } = action;

    const updateItems = state.items.filter((item) => item.id !== payload.id);

    return { ...state, items: updateItems };
};

export default createReducer(INITIAL_STATE, {
    [Types.SHOW_ALERT_SUCCESS]: showAlertSuccess,
    [Types.SHOW_ALERT]: showAlert,
    [Types.HIDE_ALERT]: hideAlert
});
