import history from 'services/history';
import { apiRequest } from '../apiRequest';
import { BrandType } from 'utils/enums/brandType';
import { graphQLRequest } from '../graphQLRequest';
import { select, put, call, takeLatest } from 'redux-saga/effects';
import { IReduxStore } from 'utils/interfaces/IReduxStore';
import { Creators as alertActions } from 'store/ducks/alert';
import { Creators as modalActions } from 'store/ducks/modal';
import { Creators as studentActions, Types as StudentTypes } from 'store/ducks/student';
import { IRequestAction } from 'utils/interfaces/IRequestAction';
import { getPersonAddresses } from 'utils/queries/person';
import { IReduxAction } from 'store/types/IRedux';
import { showAlertError } from '../utils/showAlertError';

const getStudent = (id: number) => apiRequest('GET', `/admin/student/${id}`);

export function* getRequest(action: IReduxAction<{ id: number }>) {
    try {
        const { id } = action.payload;

        const { data } = yield graphQLRequest(
            `query getStudentById($id: Int!) {
                item: prodigio_students_by_pk(id: $id) {
                  id
                  isMigrated: is_migrated
                  person {
                    id
                    name
                    last_name
                    img_profile
                    birthDate: birth_date
                    gender
                    cpf
                    phone
                    isTermsAccepted: is_terms_accepted
                    isMigrated: is_migrated
                    user {
                      email
                      username
                      id
                    }
                    addresses(where: {deleted: {_is_null: true}}) {
                      id
                      label
                      address {
                        id
                        street
                        number
                        complement
                        neighborhood
                        zipCode: zip_code
                        city
                        state
                      }
                    }
                  }
                }
              }
              `,
            { id }
        );

        if (!data?.item) {
            throw new Error();
        }

        yield put(studentActions.getSuccess(data?.item));
    } catch (error) {
        console.log({ error });

        yield call(history.push, { pathname: `/app/alunos` });
        yield put(studentActions.getFailure());
        yield showAlertError(error);
    }
}

export function* createRequest(action: any) {
    try {
        const { preRegistration, ...rest } = action.payload;

        const { data } = yield apiRequest('POST', '/admin/student', rest);

        const authStore = (globalState: IReduxStore): IReduxStore => globalState;
        const state = yield select(authStore);

        // Check if the brand has school type
        if (state.organizations.currentBrand && state.organizations.currentBrand.type === BrandType.School) {
            // Check if the preRegistration payload is valid
            if (preRegistration) {
                // Request for pre register
                yield apiRequest('POST', '/admin/preregistration', {
                    student: data.id,
                    schoolGrade: preRegistration.schoolGrade,
                    schoolPeriod: preRegistration.schoolPeriod,
                    unit: preRegistration.schoolUnit
                });
            }
        }

        yield put(studentActions.createSuccess(data));

        yield call(history.push, { pathname: `/app/alunos/ver/${data.id}` });
        yield put(alertActions.showAlert('Aluno foi criado com sucesso!', 'success'));
    } catch (error) {
        console.log('error: ', error);
        yield put(studentActions.createFailure());
        yield put(alertActions.showAlert(error?.response?.data?.detail?.message || 'Ocorreu um erro. Tente novamente mais tarde.', 'danger'));
    }
}

export function* createOrEditAddress(action: any) {
    try {
        const { type, graphql, rest } = action.payload;

        if (type === 'create') {
            const { data } = yield graphQLRequest(graphql.query, { data: graphql.data });

            if (!data) {
                throw new Error();
            }
        } else {
            const { data } = yield apiRequest(rest.method, rest.endpoint, rest.body, rest.headers);

            if (!data.person.addresses) {
                throw new Error();
            }
        }

        const { data: responseAddresses } = yield graphQLRequest(getPersonAddresses, { id: graphql.data.id_person });

        yield put(studentActions.createOrEditAddressSuccess(responseAddresses.addresses));
        yield put(modalActions.closeModal());

        yield put(alertActions.showAlert(`Endereço ${type === 'create' ? 'criado' : 'alterado'} com sucesso`, 'success'));
    } catch (error) {
        console.log(error);
        yield put(studentActions.createOrEditAddressFailure());
        yield put(alertActions.showAlert('Ocorreu um erro. Tente novamente mais tarde.', 'danger'));
    }
}

export function* deleteAddress(action: any) {
    try {
        const { payload } = action;

        yield apiRequest(payload.method, payload.endpoint, payload.body, payload.headers);

        yield put(studentActions.deleteAddressSuccess(payload.body));
        // yield put(modalActions.closeModal());
    } catch (error) {
        console.log(error);
        yield put(studentActions.deleteAddressFailure());
        yield put(alertActions.showAlert('Ocorreu um erro. Tente novamente mais tarde.', 'danger'));
    }
}

export function* editRequest(action: any) {
    try {
        yield apiRequest('PATCH', `/admin/student/${action.payload.id}`, action.payload.data);
        yield put(studentActions.editSuccess());

        yield call(history.push, { pathname: `/app/alunos` });
        yield put(alertActions.showAlert('Aluno foi editado com sucesso!', 'success'));
    } catch (error) {
        console.log('error: ', error);
        yield put(studentActions.editFailure());
        yield put(alertActions.showAlert(error?.response?.data?.detail?.message || 'Ocorreu um erro. Tente novamente mais tarde.', 'danger'));
    }
}

export function* getSubjectsRequest(action: any) {
    try {
        const { data } = yield apiRequest('GET', `/admin/student/${action.payload.studentId}/course/${action.payload.courseId}/subject`, null, {
            'X-Relations': undefined
        });

        yield put(studentActions.getSubjectsSuccess(data));
    } catch (error) {
        console.log('error: ', error);
        yield put(studentActions.getSubjectsFailure());
        yield put(alertActions.showAlert(error?.response?.data?.detail?.message || 'Ocorreu um erro. Tente novamente mais tarde.', 'danger'));
    }
}

export function* deleteRequest(action: any) {
    try {
        yield apiRequest('DELETE', `/admin/student/${action.payload}`);

        yield put(studentActions.deleteStudentSuccess());
        yield put(alertActions.showAlert('Aluno foi excluído com sucesso.', 'success'));

        yield call(history.push, { pathname: `/app/alunos` });
    } catch (error) {
        console.log('error: ', error);
        yield put(studentActions.deleteStudentFailure());
        yield put(alertActions.showAlert(error?.response?.data?.detail?.message || 'Ocorreu um erro. Tente novamente mais tarde.', 'danger'));
    }
}

export function* enrollStudentRequest(action: any) {
    try {
        yield apiRequest('POST', `/admin/subscription`, action.payload);

        yield put(studentActions.enrollStudentSuccess());

        const { data } = yield getStudent(action.payload[0].student.id);

        yield put(studentActions.getSuccess(data));

        yield put(alertActions.showAlert('O aluno foi enturmado com sucesso.', 'success'));

        yield put(modalActions.closeModal());
    } catch (error) {
        console.log('error: ', error);
        yield put(studentActions.enrollStudentFailure());
        yield put(alertActions.showAlert(error?.response?.data?.detail?.message || 'Ocorreu um erro. Tente novamente mais tarde.', 'danger'));
    }
}

export function* getStudentOrders(action: IRequestAction<any>) {
    try {
        const { payload } = action;

        const { data } = yield apiRequest(payload.method, payload.endpoint, payload.body, payload.headers);
        yield put(studentActions.getStudentOrdersSuccess(data));
    } catch (error) {
        console.log(error);
        yield put(studentActions.getStudentOrdersFailure());
    }
}

export function* createOrEditSubscription(action: IRequestAction<any>) {
    try {
        const { payload } = action;

        const { data } = yield apiRequest(payload.method, payload.endpoint, { ...payload.body, product: payload.body.product.id }, payload.headers);

        yield put(studentActions.createOrEditSubscriptionSuccess({ ...data[0], product: payload.body.product }));

        yield put(modalActions.closeModal());
    } catch (error) {
        console.log(error);
        yield put(studentActions.createOrEditSubscriptionFailure());
    }
}
export function* cancelSubscription(action: IRequestAction<any>) {
    try {
        const { method, endpoint, body } = action.payload;
        yield apiRequest(method, endpoint, body.data);
        yield put(studentActions.cancelSubscriptionSuccess(body.subscriptionId));
        yield put(modalActions.closeModal());
    } catch (error) {
        console.log(error);
        yield put(alertActions.showAlert('Ocorreu um erro ao cancelar assinatura.', 'danger'));
        yield put(studentActions.cancelSubscriptionFailure());
    }
}

export default [
    takeLatest(StudentTypes.GET_REQUEST, getRequest),
    takeLatest(StudentTypes.CREATE_REQUEST, createRequest),
    takeLatest(StudentTypes.EDIT_REQUEST, editRequest),
    takeLatest(StudentTypes.DELETE_STUDENT_REQUEST, deleteRequest),
    takeLatest(StudentTypes.ENROLL_STUDENT_REQUEST, enrollStudentRequest),
    takeLatest(StudentTypes.CREATE_OR_EDIT_ADDRESS_REQUEST, createOrEditAddress),
    takeLatest(StudentTypes.DELETE_ADDRESS_REQUEST, deleteAddress),
    takeLatest(StudentTypes.GET_SUBJECTS_REQUEST, getSubjectsRequest),
    takeLatest(StudentTypes.GET_STUDENT_ORDERS_REQUEST, getStudentOrders),
    takeLatest(StudentTypes.CREATE_OR_EDIT_SUBSCRIPTION_REQUEST, createOrEditSubscription),
    takeLatest(StudentTypes.CANCEL_SUBSCRIPTION_REQUEST, cancelSubscription)
];
