import { put } from 'redux-saga/effects';
import { Creators as alertActions } from 'store/ducks/alert';
import { Creators as searchActions } from 'store/ducks/search';
import { apiRequest } from '../apiRequest';

export function* searchRequest(action: any) {
    try {
        const { request, searchName } = action.payload;

        const { data } = yield apiRequest(request.method, request.endpoint, request.body, request.header);

        if (!data) {
            throw new Error();
        }

        const havePagination = !!data.items && !!Array.isArray(data.items);

        const formatSearch =
            data.items &&
            data.items.map((item: any) => ({
                id: item.id,
                name: item.name || item.title
            }));

        yield put(
            searchActions.searchSuccess({
                searchName,
                data: request.noFormat ? data.items || data : formatSearch,
                [`${searchName}_pagination`]: havePagination
                    ? {
                          count: data.count,
                          page: data.page,
                          totalItems: data.totalItems,
                          totalPages: data.totalPages
                      }
                    : undefined
            })
        );
    } catch (error) {
        console.log('error: ', error);
        yield put(searchActions.searchFailure({ code: error?.response?.status || 502 }));
        yield put(alertActions.showAlert('Ocorreu um erro. Tente novamente mais tarde.', 'danger'));
    }
}
