export enum ImportFileStatus {
    Starting = 'starting',
    Pending = 'pending',
    Uploading = 'uploading',
    UploadCanceled = 'uploadCanceled',
    UploadError = 'uploadError',
    Processing = 'processing',
    Complete = 'complete',
    SocketError = 'socketError'
}
