export function removePortalChild(portalId: string) {
    if (!portalId) {
        return;
    }

    const portalElement = document.getElementById(portalId);

    if (!portalElement || !portalElement.firstChild) {
        return;
    }

    portalElement.removeChild(portalElement.firstChild);
}
