import { lazy } from 'react';

const List = lazy(() => import('pages/crud'));
const CreateOrEdit = lazy(() => import('pages/crud/createOrEdit'));

const topicRoutes = [
    {
        path: '/app/questoes/topicos',
        name: 'Tópicos',
        component: List,
        exact: true,
        title: 'Tópicos'
    },
    {
        path: '/app/questoes/topicos/novo',
        name: 'Novo tópico',
        component: CreateOrEdit,
        exact: true,
        title: 'Novo tópico'
    },
    {
        path: '/app/questoes/topicos/editar/:id',
        name: 'Editar',
        component: CreateOrEdit,
        exact: true,
        title: 'Editar'
    }
];

export default topicRoutes;
