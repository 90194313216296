import { combineReducers } from 'redux';

import alert from './alert';
import answerCard from './answerCard';
import auth from './auth';
import brand from './brand';
import course from './course';
import crud from './crud';
import dashboard from './dashboard';
import exam from './exam';
import exerciseList from './exerciseList';
import organizations from './company';
import lessonPlan from './lessonPlan';
import search from './search';
import teacher from './teacher';
import lessonPlanModules from './lessonPlanModules';
import lessonPlanEvents from './lessonPlanEvents';
import questions from './questions';
import videos from './videos';
import schoolunit from './schoolunit';
import schoolClass from './schoolClass';
import schoolGrade from './schoolGrade';
import schoolPeriod from './schoolPeriod';
import timezone from './timezone';
import student from './student';
import picker from './picker';
import project from './project';
import notification from './notification';
import qrCode from './qrcode';
import profiles from './profiles';
import menu from './menu';
import user from './user';
import product from './product';
import store from './store';
import modal from './modal';
import order from './order';
import files from './files';
import subjectGroups from './subjectGroups';
import learningObject from './learningObject';
import seller from './seller';
import productPhysical from './productPhysical';
import complaint from './complaint';
import lessonPlanEducational from './lesson-plan-educational';
import campaign from './campaign';
import examFiles from './examFiles';
import contest from './contest';
import bunnyVideo from './bunnyVideo';
import answerQuestions from './answerQuestions';

export default combineReducers({
    answerCard,
    alert,
    auth,
    course,
    crud,
    dashboard,
    exam,
    exerciseList,
    lessonPlan,
    lessonPlanModules,
    lessonPlanEvents,
    lessonPlanEducational,
    modal,
    organizations,
    project,
    search,
    teacher,
    questions,
    brand,
    videos,
    schoolunit,
    schoolClass,
    schoolGrade,
    schoolPeriod,
    timezone,
    student,
    picker,
    notification,
    qrCode,
    profiles,
    menu,
    user,
    product,
    store,
    order,
    files,
    subjectGroups,
    learningObject,
    seller,
    productPhysical,
    complaint,
    campaign,
    examFiles,
    contest,
    bunnyVideo,
    answerQuestions
});
