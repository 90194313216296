import { IField } from 'utils/interfaces/IField';
import InputTypes from 'utils/enums/inputTypes';

const essayTheme: IField[] = [
    {
        presentation: 'Título',
        label: 'Título',
        name: 'title',
        type: InputTypes.text,
        columns: 3
    },
    {
        presentation: 'Selecione um concurso',
        label: 'Concurso',
        name: 'contest',
        type: InputTypes.simpleSelect,
        columns: 3
    },
    {
        presentation: 'Selecione uma complexidade',
        label: 'Complexidade',
        name: 'complexity',
        type: InputTypes.simpleSelect,
        columns: 3,
        data: [
            {
                value: 1,
                label: 'Fácil'
            },
            {
                value: 2,
                label: 'Médio'
            },
            {
                value: 3,
                label: 'Difícil'
            }
        ]
    },
    {
        presentation: 'Selecione um gênero textual',
        label: 'Gênero textual',
        name: 'genre',
        type: InputTypes.simpleSelect,
        columns: 3
    },
    {
        presentation: 'Selecione uma categoria',
        label: 'Categoria',
        name: 'category',
        type: InputTypes.simpleSelect,
        columns: 3
    },
    {
        presentation: 'Material de apoio',
        label: 'Material de apoio',
        name: 'material',
        type: InputTypes.upload,
        columns: 3
    }
];

export default essayTheme;
