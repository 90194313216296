import styled from 'styled-components';
import theme from 'config/theme';
import { Subtitle } from 'assets/global';

const Header = styled.header`
    align-items: center;
    display: flex;
    margin-bottom: 30px;

    > ${Subtitle} {
        text-transform: uppercase;
        margin-right: 10px;
    }
`;

const Content = styled.div`
    display: flex;
    padding: 20px 30px;
`;

export const StoreCard = {
    Header,
    Content
};

export const StoreDataItem = styled.div`
    flex: 1;

    strong {
        color: ${theme.colors.baseDark};
        display: block;
        font-family: ${theme.typography.family.primary.bold.regular};
        margin-bottom: ${theme.spacing.smallX.all};
        text-transform: uppercase;
    }

    span {
        flex: 1;
        font-family: ${theme.typography.family.primary.light.italic};
        font-size: 14px;

        img {
            width: 100%;
            height: auto;
            border-radius: 8px;
        }
    }
`;
