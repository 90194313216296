import styled from 'styled-components/macro';
import { EmptyContainer } from 'components/empty/styles';
import Col from 'react-bootstrap/esm/Col';
import { BadgeContainer } from 'components/badge/styles';

export const TableBodyContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    min-height: 80px;

    .-loading {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(255, 255, 255, 0.5);
        z-index: 10;
    }

    ${EmptyContainer} {
        padding: 30px;
    }
`;

export const TableBodyRow = styled.div`
    width: 100%;
    display: flex;
    align-items: center;

    :not(:last-child) {
        border-bottom: 1px solid #eeeeee;
    }

    > .row {
        align-items: center;
        border-right: 1px solid #eeeeee;
    }
`;

export const TableBodyRowCell = styled(Col)`
    display: flex;
    align-items: center;
    height: 72px;

    :not(:last-child) {
        border-right: 1px solid #eeeeee;
    }

    > * {
        max-height: 100%;
    }

    img {
        display: none;
    }

    ${BadgeContainer} {
        white-space: unset;
    }
`;
