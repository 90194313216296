const theme = {
    project: {
        chave: 'proenem'
    },
    colors: {
        base: '#818c9d',
        baseDark: '#404042',
        borderColor: '#f0f0f2',
        black: '#000000',
        primary: '#005495',
        secondary: '#0094d9',
        link: '#0094d9',
        white: '#FFFFFF',
        components: {
            tooltipBg: '#2b363f',
            inputBorder: '#ced3db'
        },
        system: {
            danger: '#f95c3c',
            info: '#6dc0e0',
            success: '#02ccba',
            warning: '#ecc91b'
        }
    },
    typography: {
        family: {
            primary: {
                thin: {
                    italic: 'carosthin_italic',
                    regular: 'carosthin'
                },
                light: {
                    italic: 'caroslight_italic',
                    regular: 'caroslight'
                },
                normal: {
                    italic: 'carositalic',
                    regular: 'carosregular'
                },
                bold: {
                    italic: 'carosbold_italic',
                    regular: 'carosbold'
                },
                black: {
                    italic: 'carosheavy_italic',
                    regular: 'carosheavy'
                }
            }
        },
        sizes: {
            small: '13px',
            normal: '15px',
            normalX: '16px',
            normalXX: '18px',
            large: '25px',
            largeX: '35px'
        }
    },
    spacing: {
        smallX: {
            vertical: '5px 0',
            horizontal: '0 5px',
            all: '5px'
        },
        small: {
            vertical: '10px 0',
            horizontal: '0 10px',
            all: '10px'
        },
        medium: {
            vertical: '20px 0',
            horizontal: '0 20px',
            all: '20px'
        },
        large: {
            vertical: '30px 0',
            horizontal: '0 30px',
            all: '30px'
        },
        largeX: {
            vertical: '60px 0',
            horizontal: '0 60px',
            all: '60px'
        }
    }
};

export default theme;
