import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { Creators as alertActions } from 'store/ducks/alert';
import { Creators as companyActions } from 'store/ducks/company';
import { Creators as crudActions, Types as CrudTypes } from 'store/ducks/crud';
import { ICrudAction, IGraphqlCrudAction } from 'utils/interfaces/ICrud';
import { graphQLClient } from 'services/graphqlClient';

import { apiRequest } from '../apiRequest';
import { IReduxStore } from 'utils/interfaces/IReduxStore';
import history from 'services/history';
import formatGraphQLOrder from 'utils/formatGraphQL/formatGraphQLOrder';
import learningObjectMiddleware from 'utils/formatGraphQL/learningObjectMiddleware';

import { AxiosInstances } from 'utils/enums/axiosInstance';
import api from 'services/api';
import apiMongo from 'services/api-mongo';

const globalState = (state: IReduxStore): IReduxStore => state;

// Middleware for get all registers of entity
function* getAll(action: ICrudAction) {
    try {
        const { axiosInstance = AxiosInstances.Normal, endpoint, params, queryParams } = action.payload;

        const apiInstance = axiosInstance === AxiosInstances.Mongo ? apiMongo.get : api.get;

        const { data } = yield call(apiInstance, endpoint, {
            headers: {
                ...params
            },
            ...(queryParams && { params: queryParams })
        });

        yield put(crudActions.getAllSuccess(data));
    } catch (error) {
        // Pass status code of request to saga monitor
        yield put(crudActions.getAllFailure({ code: error?.response?.status }));
        yield put(alertActions.showAlert(error?.response?.data?.detail?.message || 'Ocorreu um erro. Tente novamente mais tarde.', 'danger'));
    }
}

// Middleware for get all registers of entit (using graphql)
function* getAllGraphql(action: IGraphqlCrudAction) {
    try {
        const { query, page = 1, search = {}, order } = action.payload;

        if (!query) {
            throw new Error();
        }

        if (process.env.NODE_ENV === 'development') {
            console.group('CRUD SAGA');
            console.log(query);
            console.log({ search });
            console.log({ order });
            console.groupEnd();
        }

        const itemsPerPage = 10;

        const params = {
            limit: itemsPerPage,
            offset: (page >= 1 ? page - 1 : 0) * itemsPerPage,
            where: { ...search },
            orderBy: formatGraphQLOrder(order)
        };

        const { data } = yield graphQLClient(query, params);

        const { items, quantity } = data;
        const { count } = quantity.aggregate;

        const response = {
            items: learningObjectMiddleware(items),
            page: (params.limit + params.offset) / params.limit,
            totalItems: count,
            count: items.length,
            totalPages: Math.ceil(count / params.limit),
            payload: action.payload
        };

        yield put(crudActions.getAllSuccess(response));
    } catch (error) {
        console.log('error', error);
        yield put(crudActions.getAllFailure({ code: error?.response?.status }));
        yield put(alertActions.showAlert(error?.response?.data?.detail?.message || 'Ocorreu um erro. Tente novamente mais tarde.', 'danger'));
    }
}

// Middleware for create or edit register
function* createOrEdit(action: ICrudAction) {
    try {
        const { data: payload, endpoint, method, uploads, messageSuccess } = action.payload;
        let updatedPayload = {};

        if (uploads) {
            const fieldsToExclude = uploads.map((upload) => upload.fieldName);
            const uploadPromises = uploads
                .filter((upload) => !!payload[upload.fieldName])
                .map(function* (upload) {
                    const formData = new FormData();

                    formData.set('file', payload[upload.fieldName]);

                    const response = yield apiRequest('POST', upload.apiEndpoint, formData);

                    return {
                        [upload.targetFieldName]: response.data.fileUrl
                    };
                });

            if (uploadPromises.length > 0) {
                const uploadResponse = yield all(uploadPromises);

                const filteredPayload = Object.keys(payload)
                    .filter((field) => !fieldsToExclude.includes(field))
                    .reduce(
                        (accumulator: any, currentValue: any) => ({
                            ...accumulator,
                            [currentValue]: payload[currentValue]
                        }),
                        {}
                    );

                updatedPayload = {
                    ...filteredPayload,
                    ...uploadResponse.reduce((accumulator: any, currentValue: any) => {
                        return {
                            ...accumulator,
                            ...currentValue
                        };
                    }, {})
                };
            }
        }

        const { data } = yield apiRequest(method, endpoint, Object.keys(updatedPayload).length > 0 ? updatedPayload : payload);

        const messageAlert = messageSuccess ? messageSuccess : 'Registro cadastrado com sucesso';

        yield put(crudActions.createOrEditSuccess(data));
        yield put(alertActions.showAlert(messageAlert, 'success'));

        if (!!action?.payload?.redirectPath) {
            yield call(history.push, { pathname: !!action?.payload?.redirectToDetails ? `${action.payload.redirectPath}/ver/${data.id}` : action.payload.redirectPath });
        }

        if (endpoint === '/brand' || endpoint === '/company') {
            yield put(companyActions.getCompaniesRequest());
        }
    } catch (error) {
        const messageError = error?.response?.data?.detail?.message || error?.response?.data?.detail;
        yield put(crudActions.createOrEditFailure());
        yield put(alertActions.showAlert(messageError || 'Ocorreu um erro. Tente novamente mais tarde.', 'danger'));
    }
}

// Middleware for get one
function* getOne(action: ICrudAction) {
    try {
        const { endpoint, method, params, relations } = action.payload;

        const { data } = yield apiRequest(method, endpoint, null, params);

        if (relations) {
            const responseFormatted = Object.keys(data).reduce((total, current) => {
                if (typeof data[current] === 'object' && data[current] !== null) {
                    return {
                        ...total,
                        [current]: {
                            value: data[current].id,
                            label: data[current].name
                        }
                    };
                }

                return {
                    ...total,
                    [current]: data[current]
                };
            }, {});

            return yield put(crudActions.getOneSuccess(responseFormatted));
        }

        yield put(crudActions.getOneSuccess(data));
    } catch (error) {
        yield put(crudActions.getOneFailure());
        yield put(alertActions.showAlert(error?.response?.data?.detail?.message || 'Ocorreu um erro. Tente novamente mais tarde.', 'danger'));
    }
}

// Get relations of entity
function* getWithRelations(action: ICrudAction) {
    try {
        const { method, relations } = action.payload;

        const requests = relations!.map((relation) => apiRequest(method, relation.apiEndpoint!, null, relation.headers || null));

        // Execute all requests
        const relationsResponse = yield all(requests);

        // Format relations for select options
        const formatResponses = relationsResponse.map((response: any, index: number) => ({
            [relations![index].relationName!]: (Array.isArray(response.data) ? response.data : response.data.items).map((item: any) => ({
                value: item.id,
                label: !!item.person ? item.person.name : item.name
            }))
        }));

        const responses = formatResponses.reduce((total: any, current: any) => ({
            ...total,
            ...current
        }));

        return yield put(crudActions.getWithRelationsSuccess(responses));
    } catch (error) {
        console.log('error', error);
        yield put(crudActions.getWithRelationsFailure({ code: error?.response?.status }));
        yield put(alertActions.showAlert(error?.response?.data?.detail?.message || 'Ocorreu um erro. Tente novamente mais tarde.', 'danger'));
    }
}

// Middleware for delete a register of entity
function* remove(action: ICrudAction) {
    try {
        const { data, endpoint, axiosInstance = AxiosInstances.Normal, messageDelete } = action.payload;

        const apiInstance = axiosInstance === AxiosInstances.Mongo ? apiMongo.delete : api.delete;

        yield call(apiInstance, `${endpoint}/${data.id}`);

        const store = yield select(globalState);
        const newItems = store.crud.data.items.filter((item: any) => item?.id !== action.payload.data.id);

        const messageAlert = messageDelete ? messageDelete : 'Registro excluído com sucesso';
        yield put(crudActions.deleteSuccess(newItems));
        yield put(alertActions.showAlert(messageAlert, 'success'));

        if (endpoint === '/brand' || endpoint === '/company') {
            yield put(companyActions.getCompaniesRequest());
        }
    } catch (error) {
        yield put(crudActions.deleteFailure());
        yield put(alertActions.showAlert(error?.response?.data?.detail?.message || 'Ocorreu um erro ao tentar apagar o registro', 'danger'));
    }
}

function* graphqlRemove(action: IGraphqlCrudAction) {
    try {
        const { query, params, messageDelete } = action.payload;

        yield graphQLClient(query, params);

        const store = yield select(globalState);

        const newItems = store.crud.data.items.filter((item: any) => item.id !== action.payload.params.id);

        yield put(crudActions.deleteSuccess(newItems));

        const messageAlert = messageDelete ? messageDelete : 'Registro excluído com sucesso';
        yield put(alertActions.showAlert(messageAlert, 'success'));
    } catch (error) {
        yield put(crudActions.deleteFailure());
        yield put(alertActions.showAlert(error?.response?.data?.detail?.message || 'Ocorreu um erro ao tentar apagar o registro', 'danger'));
    }
}

function* setActiveStatus(action: ICrudAction) {
    try {
        const store = yield select(globalState);

        const { data, endpoint, method } = action.payload;

        const newItems = store.crud.data.items.map((item: any) => {
            if (item.id === data.id) {
                return {
                    ...item,
                    status: data.status
                };
            }
            return item;
        });

        yield apiRequest(method, endpoint);

        yield put(crudActions.toggleActiveSuccess(newItems));
        yield put(alertActions.showAlert('Registro alterado com sucesso', 'success'));
    } catch (error) {
        yield put(crudActions.toggleActiveFailure());
        yield put(alertActions.showAlert(error?.response?.data?.detail?.message || 'Ocorreu um erro ao tentar atualizar o registro', 'danger'));
    }
}

function* changeVideoStatus(action: ICrudAction) {
    try {
        const { data, endpoint, method } = action.payload;

        yield apiRequest(method, endpoint, { status: data.status });

        yield put(crudActions.changeVideoStatusSuccess(data));
        yield put(alertActions.showAlert('Ação realizada com sucesso', 'success'));
    } catch (error) {
        yield put(crudActions.changeVideoStatusFailure());
        yield put(alertActions.showAlert(error?.response?.data?.detail?.message || 'Ocorreu um erro ao tentar realizar açãp', 'danger'));
    }
}

function* refreshGetAll(action: IGraphqlCrudAction) {
    try {
        const savedPayload = yield select(({ crud }: IReduxStore) => crud?.payload);

        const payload = { ...(savedPayload ?? action?.payload), page: 1 };

        const isGraphql = !!payload?.query;

        const reduxAction = isGraphql ? crudActions.getAllGraphqlRequest : crudActions.getAllRequest;

        // getAllGraphqlRequest
        yield put(reduxAction(payload));
    } catch (error) {
        console.log(error);
    }
}

export default [
    takeLatest(CrudTypes.GET_ALL_REQUEST, getAll),
    takeLatest(CrudTypes.GET_ALL_GRAPHQL_REQUEST, getAllGraphql),
    takeLatest(CrudTypes.GET_WITH_RELATIONS_REQUEST, getWithRelations),
    takeLatest(CrudTypes.GET_ONE_REQUEST, getOne),
    takeLatest(CrudTypes.DELETE_REQUEST, remove),
    takeLatest(CrudTypes.GRAPHQL_DELETE_REQUEST, graphqlRemove),
    takeLatest(CrudTypes.CREATE_OR_EDIT_REQUEST, createOrEdit),
    takeLatest(CrudTypes.REFRESH_GET_ALL, refreshGetAll),
    takeLatest(CrudTypes.TOGGLE_ACTIVE_REQUEST, setActiveStatus),
    takeLatest(CrudTypes.CHANGE_VIDEO_STATUS_REQUEST, changeVideoStatus)
];
