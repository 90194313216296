import { lazy } from 'react';

const List = lazy(() => import('pages/crud'));
const Company = lazy(() => import('pages/company'));

const companyRoutes = [
    {
        path: '/app/empresas',
        name: 'Empresas',
        component: List,
        exact: true,
        title: 'Empresas'
    },
    {
        path: '/app/empresas/novo',
        name: 'Novo',
        component: Company,
        exact: true,
        title: 'Nova empresa'
    },
    {
        path: '/app/empresas/editar/:id',
        name: 'Editar',
        component: Company,
        exact: true,
        title: 'Editar empresa'
    }
];

export default companyRoutes;
