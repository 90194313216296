import { lazy } from 'react';

const List = lazy(() => import('pages/crud'));
const Event = lazy(() => import('pages/event'));

const eventRoutes = [
    {
        path: '/app/plano-de-estudos/eventos',
        name: 'Eventos',
        component: List,
        exact: true,
        title: 'Eventos'
    },
    {
        path: '/app/plano-de-estudos/eventos/ver/:id',
        name: 'Visualizar',
        component: Event,
        exact: true,
        title: 'Visualizar Evento'
    }
];

export default eventRoutes;
