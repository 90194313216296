import React, { useState } from 'react';

// Dependencies
import { Link } from 'react-router-dom';

// Components
import Logo from '../logo';
import Nav from '../navBar/nav';
import UserNav from '../userNav';
import WelcomeCard from './welcomeCard';
import Icon from 'components/icons/icon';

// Helpers
import { IModalActions } from 'utils/interfaces/IActions';

// Assets
import { Container } from '../../assets/global';
import { HeaderContainer, HeaderTop, HeaderBottom, HeaderActions, BrandSelectorContainer, BrandSelectorText, BrandSelectWrapper, BrandSelected } from './styles';

// Temp
// import menuMock from '../../helpers/menuMock';

interface IProps {
    currentBrandName: string | undefined;
    currentCompanyName: string | undefined;
    currentUnitName: string | undefined;
    modalActions: IModalActions;
    name: string;
    imageProfile: string;
}

const Header = ({ currentBrandName, currentCompanyName, currentUnitName, modalActions, name, imageProfile }: IProps) => {
    const [isUserNavOpen, setIsUserNavOpen] = useState(false);

    const RenderNavigationName = () => {
        if (currentUnitName) {
            return (
                <>
                    {currentBrandName} &gt; <strong>{currentUnitName}</strong>
                </>
            );
        }

        if (currentBrandName) {
            return <strong>{currentBrandName}</strong>;
        }

        if (currentCompanyName) {
            return <strong>{currentCompanyName}</strong>;
        }

        return <strong>Administrador</strong>;
    };

    return (
        <HeaderContainer id="header">
            <UserNav isNavOpen={isUserNavOpen} />
            <HeaderTop>
                <Container>
                    <HeaderActions>
                        <Link to="/">
                            <Logo type="white" />
                        </Link>
                        <BrandSelectorContainer>
                            <BrandSelectorText>Você está navegando como</BrandSelectorText>
                            <BrandSelectWrapper>
                                <BrandSelected>
                                    <RenderNavigationName />
                                </BrandSelected>
                                <button type="button" onClick={() => modalActions.openModal('brandSelector')}>
                                    <Icon idIcon="ico-exchange" width="13" height="13" viewBox="0 0 330 330" />
                                </button>
                            </BrandSelectWrapper>
                        </BrandSelectorContainer>
                    </HeaderActions>
                    <WelcomeCard name={name} isNavOpen={isUserNavOpen} onOpenUserNav={() => setIsUserNavOpen(!isUserNavOpen)} imageProfile={imageProfile} />
                </Container>
            </HeaderTop>
            <HeaderBottom>
                <Container>
                    <Nav />
                </Container>
            </HeaderBottom>
        </HeaderContainer>
    );
};

export default Header;
