import React, { useCallback } from 'react';

import * as S from './styles';
import { TableItemType } from 'utils/enums/tableItemType';
import Row from 'react-bootstrap/esm/Row';
import Loader from 'components/loader';
import { ITableAction } from 'utils/interfaces/ITable';
import { TableActions } from 'components/tablev2/styles';
import TableItem from '../item';
import _get from 'lodash/get';
import { IAvaliableColumns } from 'utils/interfaces/IRoute';
import Empty from 'components/empty';

import Actions from '../actions';

interface IProps {
    isLoading: boolean;
    mainColumn?: string;
    data: any[];
    actions?: ITableAction[];
    hasActions: boolean;
    columns: IAvaliableColumns[];
    isEmpty: boolean;
    tableId: number;
}

const TableV2Body = ({ isLoading, mainColumn, data = [], actions = [], hasActions = false, columns = [], isEmpty, tableId }: IProps) => {
    const formatActions = useCallback(
        (id?: any, item?: any) => {
            const formatActions: any[] =
                actions
                    ?.filter((actionItem) => (actionItem?.isVisible !== undefined ? actionItem?.isVisible(item) : true))
                    ?.reduce((acc, action) => {
                        let status = '';

                        if (action.name === 'video.status') {
                            if (item.status === 1) status = 'Arquivar';
                            if (item.status === 2) status = 'Cancelar arquivamento';
                            if (item.status === 3) status = 'Restaurar';

                            if (status === '') return acc;
                        }

                        return [
                            ...acc,
                            {
                                ...action,
                                ...(action.name === 'video.status' && status !== '' && { name: status }),
                                ...(!!action?.path && { path: `${action?.path(id, item)}` }),
                                ...(!!action?.action && { action: () => action.action!(id, item) })
                            }
                        ];
                    }, [] as any[]) ?? [];

            return !!item?.isReadOnly ? formatActions.filter(({ isPublic }) => !!isPublic) : formatActions;
        },
        [actions]
    );

    return (
        <S.TableBodyContainer>
            {isLoading && <Loader />}

            {data.map((item, index) => {
                const id = !!mainColumn ? item[mainColumn] : item?.id;

                return (
                    <S.TableBodyRow key={id ?? `table_${tableId}_${index}`}>
                        <Row>
                            {columns.map((column, ix) => {
                                const columnValue = _get(item, column.name);

                                return (
                                    <S.TableBodyRowCell md={column?.grid} key={`column_${index}_${ix}`}>
                                        <TableItem
                                            type={column.type as TableItemType}
                                            value={columnValue}
                                            enumValue={column?.enum}
                                            name={column.name}
                                            customComponent={(value) => column?.customComponent && column?.customComponent(value, item)}
                                            subItem={column?.subItem}
                                            path={column?.path}
                                        />
                                    </S.TableBodyRowCell>
                                );
                            })}
                        </Row>

                        {hasActions && (
                            <TableActions>
                                <Actions actions={formatActions(id, item)} />
                            </TableActions>
                        )}
                    </S.TableBodyRow>
                );
            })}

            {isEmpty && <Empty text="Nenhum registro foi encontrado." />}
        </S.TableBodyContainer>
    );
};

export default TableV2Body;
