import { createActions, createReducer } from 'reduxsauce';
import { AnswerCardActionsType, IAnswerCardState, IAnswerCardActionsCreators } from 'store/types/IAnswerCard';
import { ExerciseListType } from 'utils/enums/question';

export const { Creators, Types } = createActions<Record<AnswerCardActionsType, AnswerCardActionsType>, IAnswerCardActionsCreators>({
    getAnswerCardRequest: ['payload'],
    getAnswerCardSuccess: ['payload'],
    getAnswerCardFailure: ['payload'],

    getAnswerCardLightRequest: ['payload'],
    getAnswerCardLightSuccess: ['payload'],
    getAnswerCardLightFailure: [],

    getAnswerCardQuestionRequest: ['payload'],
    getAnswerCardQuestionSuccess: ['payload'],
    getAnswerCardQuestionFailure: [],

    setRevision: ['payload'],

    reviseAnswerCardRequest: ['payload'],
    reviseAnswerCardSuccess: ['payload'],
    reviseAnswerCardFailure: ['payload'],

    clearAnswerCard: []
});

const INITIAL_STATE: IAnswerCardState = {
    id: 0,
    answers: [],
    answersCorrect: 0,
    endDate: new Date(),
    exerciseList: {
        id: 0,
        title: '',
        items: [],
        type: ExerciseListType.Normal,
        points: 0,
        knowledge_areas: []
    },
    points: 0,
    questionsTotal: 0,
    realizationMode: 0,
    startDate: new Date(),
    status: 0,
    student: {
        id: 0,
        isMigrated: false,
        person: {
            slug: '',
            name: '',
            id: 0,
            cpf: '',
            isMigrated: false,
            isTermsAccepted: false,
            addresses: []
        },
        created: '',
        preRegistrations: [],
        subscriptions: []
    },
    isLoading: false,
    revision: [],
    error: false,
    isLoadingQuestions: false
};

const answerCardRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true, error: false });

const answerCardFailure = (state = INITIAL_STATE) => ({ ...state, isLoading: false, error: true });

const answerCardSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    ...action.payload,
    isLoading: false,
    error: false
});

const setRevision = (state = INITIAL_STATE, action: any) => {
    const updateQuestions = state?.exerciseList?.items?.map((item) =>
        item.question?.id === action?.payload?.idQuestion
            ? {
                  ...item,
                  answer: {
                      ...item.answer,
                      ...action?.payload?.newValue
                  }
              }
            : item
    );

    return {
        ...state,
        exerciseList: {
            ...state.exerciseList,
            items: updateQuestions
        }
    };
};

const reviseAnswerCardRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true, error: false });

const clearAnswerCard = (state = INITIAL_STATE) => ({ ...state, ...INITIAL_STATE });

const getAnswerCardQuestionRequest = (state = INITIAL_STATE, action: any) => ({ ...state, isLoadingQuestions: true, selectedQuestionId: action?.payload?.idQuestion });
const getAnswerCardQuestionSuccess = (state = INITIAL_STATE, action: any) => {
    if (!action) {
        return {
            ...state,
            isLoadingQuestions: false
        };
    }

    const updateQuestions = state?.exerciseList?.items?.map((item) => ((item?.question?.id || item?.id_question) === action.payload?.question?.id ? { ...item, ...action.payload } : item));

    return {
        ...state,
        isLoadingQuestions: false,
        exerciseList: {
            ...state.exerciseList,
            items: updateQuestions
        }
    };
};
const getAnswerCardQuestionFailure = (state = INITIAL_STATE) => ({ ...state, isLoadingQuestions: false });

const stopLoading = (state = INITIAL_STATE, _: any) => ({ ...state, isLoading: false });

export default createReducer(INITIAL_STATE, {
    [Types.GET_ANSWER_CARD_REQUEST]: answerCardRequest,
    [Types.GET_ANSWER_CARD_SUCCESS]: answerCardSuccess,
    [Types.GET_ANSWER_CARD_FAILURE]: answerCardFailure,

    [Types.SET_REVISION]: setRevision,

    [Types.REVISE_ANSWER_CARD_REQUEST]: reviseAnswerCardRequest,
    [Types.REVISE_ANSWER_CARD_SUCCESS]: stopLoading,
    [Types.REVISE_ANSWER_CARD_FAILURE]: stopLoading,

    [Types.CLEAR_ANSWER_CARD]: clearAnswerCard,

    [Types.GET_ANSWER_CARD_QUESTION_REQUEST]: getAnswerCardQuestionRequest,
    [Types.GET_ANSWER_CARD_QUESTION_SUCCESS]: getAnswerCardQuestionSuccess,
    [Types.GET_ANSWER_CARD_QUESTION_FAILURE]: getAnswerCardQuestionFailure,

    [Types.GET_ANSWER_CARD_LIGHT_REQUEST]: answerCardRequest,
    [Types.GET_ANSWER_CARD_LIGHT_SUCCESS]: answerCardSuccess,
    [Types.GET_ANSWER_CARD_LIGHT_FAILURE]: answerCardFailure
});
