export const getAllInvoicesQuery = `query ($orderBy: [prodigio_invoices_order_by!], $where: prodigio_invoices_bool_exp, $limit: Int, $offset: Int) {
    items: prodigio_invoices(where: $where, order_by: $orderBy, limit: $limit, offset: $offset) {
      id
      id_invoice_status
      number
      link_pdf
      link_xml
      order {
        id
        customer {
          email
        }
        store {
          id
        }
      }
      key
    }
    quantity: prodigio_invoices_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
  `;
