import { IField } from 'utils/interfaces/IField';
import InputTypes from 'utils/enums/inputTypes';

const company: IField[] = [
    {
        presentation: 'Nome',
        label: 'Nome da marca',
        name: 'name',
        type: InputTypes.text,
        columns: 3
    },
    {
        presentation: 'Selecione uma empresa',
        label: 'Empresa',
        name: 'company',
        type: InputTypes.simpleSelect,
        columns: 3
    }
];

export default company;
