import { createActions, createReducer } from 'reduxsauce';
import { IVideo, IMaterial } from 'utils/interfaces/ILearningObject';

export const { Creators, Types } = createActions({
    createQRCodesRequest: ['payload'],
    createQRCodesSuccess: ['payload'],
    createQRCodesFailure: [],

    deleteQRCodeContentRequest: ['payload'],
    deleteQRCodeContentSuccess: ['payload'],
    deleteQRCodeContentFailure: [],

    getQRCodeRequest: ['payload'],
    getQRCodeSuccess: ['payload'],
    getQRCodeFailure: [],

    clearQRCode: []
});

export interface IQRCodeContent {
    id: number;
    url: string;
    learningObject: {
        id: number;
        video?: IVideo;
        material?: IMaterial;
        question?: any;
        theme?: any;
        exerciseList?: any;
        playlist?: any;
    };
}

export interface IQRCodeState {
    isLoading: boolean;
    id: number;
    created: string;
    code: string;
    status: string;
    codeImagePath: string | null;
    codeImageUrl: string | null;
    content: IQRCodeContent[];
    items?: IQRCodeState[];
}

const INITIAL_STATE: IQRCodeState = {
    isLoading: false,
    code: '',
    codeImagePath: null,
    codeImageUrl: null,
    created: '',
    id: 0,
    status: 'Free',
    content: [],
    items: []
};

const createQRCodesRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true });
const createQRCodesSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoading: false,
    items: action.payload
});
const createQRCodesFailure = (state = INITIAL_STATE) => ({ ...state, isLoading: false });

const getQRCodeRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true });
const getQRCodeSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoading: false,
    ...action.payload
});
const getQRCodeFailure = (state = INITIAL_STATE) => ({ ...state, ...INITIAL_STATE });

const clearQRCode = () => INITIAL_STATE;

const deleteContentSuccess = (state = INITIAL_STATE, action: any) => {
    const updateContents = state.content.filter((content) => content.learningObject.id !== action.payload);

    return {
        ...state,
        isLoading: false,
        content: updateContents,
        ...(!updateContents.length && { status: 'Free' })
    };
};

export default createReducer(INITIAL_STATE, {
    [Types.CREATE_QR_CODES_REQUEST]: createQRCodesRequest,
    [Types.CREATE_QR_CODES_SUCCESS]: createQRCodesSuccess,
    [Types.CREATE_QR_CODES_FAILURE]: createQRCodesFailure,

    [Types.GET_QR_CODE_REQUEST]: getQRCodeRequest,
    [Types.GET_QR_CODE_SUCCESS]: getQRCodeSuccess,
    [Types.GET_QR_CODE_FAILURE]: getQRCodeFailure,

    [Types.DELETE_QR_CODE_CONTENT_REQUEST]: createQRCodesRequest,
    [Types.DELETE_QR_CODE_CONTENT_SUCCESS]: deleteContentSuccess,
    [Types.DELETE_QR_CODE_CONTENT_FAILURE]: createQRCodesFailure,

    [Types.CLEAR_QR_CODE]: clearQRCode
});
