import { call, put, select } from 'redux-saga/effects';
import { Creators as alertActions } from 'store/ducks/alert';
import { Creators as authActions } from 'store/ducks/auth';
import { Creators as companyActions } from 'store/ducks/company';
import { Creators as modalActions } from 'store/ducks/modal';
import { apiRequest } from '../apiRequest';
import { IReduxStore } from 'utils/interfaces/IReduxStore';
import { ICompanyAction, ICompany } from 'utils/interfaces/ICompany';
import { IBrand } from 'utils/interfaces/IBrand';
import history from 'services/history';
import api from 'services/api';
import apiMongo from 'services/api-mongo';

const globalState = (state: IReduxStore): IReduxStore => state;

export function* getCompanyById(action: any) {
    try {
        const { payload } = action;

        const { data } = yield apiRequest(payload.method, payload.endpoint, payload.body, payload.headers);

        yield put(companyActions.getCompanyByIdSuccess(data));
    } catch (error) {
        console.log(error);
        yield put(companyActions.getCompanyByIdFailure());
        yield put(alertActions.showAlert('Ocorreu um erro. Tente novamente mais tarde.', 'danger'));
    }
}

export function* createOrEditCompany(action: any) {
    try {
        const { payload } = action;

        const formatBody = {
            ...payload.body,
            managingUsers: payload.body.managingUsers.map((item: any) => {
                if (item.id < 1) {
                    delete item.id;
                }

                return item;
            })
        };

        const { data } = yield apiRequest(payload.method, payload.endpoint, formatBody, payload.headers);

        yield put(companyActions.getCompanyByIdSuccess(data));

        yield put(alertActions.showAlert(`Empresa ${payload.method === 'POST' ? 'Criada' : 'Atualizada'}!`, 'success'));

        if (payload.method === 'POST') {
            yield call(history.push, { pathname: `/app/empresas/editar/${data.id}` });
        }
    } catch (error) {
        console.log(error);
        yield put(companyActions.getCompanyByIdFailure());
        yield put(alertActions.showAlert('Ocorreu um erro. Tente novamente mais tarde.', 'danger'));
    }
}

export function* createOrEditCompanyUsers(action: any) {
    try {
        const { payload } = action;

        yield put(companyActions.createOrEditCompanyUserSuccess(payload));
        yield put(modalActions.closeModal());
    } catch (error) {
        yield put(companyActions.createOrEditCompanyUserFailure());
        yield put(alertActions.showAlert('Ocorreu um erro. Tente novamente mais tarde.', 'danger'));
    }
}

// Middleware for get credentials
export function* getCompanies() {
    try {
        const { data } = yield apiRequest('GET', '/person/me');
        const { companies } = data;

        yield put(companyActions.getCompaniesSuccess(companies));

        // Joga o person no state
        yield put(authActions.loginSuccess({ person: { id: data.id, name: data.name } } as any));

        // ISSO TRAVA O ACESSO DO USUARIO QUE NAO TEM COMPANIES
        if (!data.companies) {
            yield put(authActions.logout());
            yield put(companyActions.clearCompanies());

            // yield call(history.push, { pathname: '/' });
            throw new Error('Sem autorização.');
        }
    } catch (error) {
        console.log(error);
        yield put(alertActions.showAlert('Ocorreu um erro. Talvez o usuário não tenha Permissão.', 'danger'));
        yield put(authActions.logout());

        // yield put(companyActions.getCompaniesFailure({ code: (error?.response?.status) || 401 }));
    }
}

// Middleware for set current Company
export function* setCurrentCompany(action: any) {
    try {
        yield put(companyActions.setCurrentCompanySuccess(action.payload));
        yield put(modalActions.closeModal());

        const payload = {
            method: 'GET',
            endpoint: '/person/menu'
        };

        delete api.defaults.headers.common['X-Brand'];
        delete api.defaults.headers.common['X-Unit'];

        delete apiMongo.defaults.headers.common['X-Brand'];
        delete apiMongo.defaults.headers.common['X-Unit'];

        yield put(authActions.getNavbarMenuRequest(payload));

        api.defaults.headers.common['X-Company'] = action.payload.slug || '';
        apiMongo.defaults.headers.common['X-Company'] = action.payload.slug || '';
    } catch (error) {
        yield put(authActions.logout());
        yield put(companyActions.setCurrentBrandFailure());
        yield put(alertActions.showAlert('Ocorreu um erro ao tentar selecionar a Empresa', 'danger'));
    } finally {
        yield call(history.push, { pathname: '/' });
    }
}

// Middleware for set current brand
export function* setCurrentBrand(action: ICompanyAction) {
    try {
        const store = yield select(globalState);
        const { idBrand, idCompany } = action.payload;

        const company = store.organizations.companies.find((company: ICompany) => company.id === idCompany);
        const brand: IBrand = company.brands.find((brand: IBrand) => brand.id === idBrand);

        api.defaults.headers.common['X-Brand'] = brand.slug || '';
        apiMongo.defaults.headers.common['X-Brand'] = brand.slug || '';

        yield put(companyActions.setCurrentBrandSuccess(brand));

        const payload = {
            method: 'GET',
            endpoint: '/person/menu'
        };
        yield put(authActions.getNavbarMenuRequest(payload));

        delete api.defaults.headers.common['X-Company'];
        delete api.defaults.headers.common['X-Unit'];

        delete apiMongo.defaults.headers.common['X-Company'];
        delete apiMongo.defaults.headers.common['X-Unit'];

        yield put(modalActions.closeModal());
    } catch (error) {
        yield put(companyActions.setCurrentBrandFailure());
        yield put(alertActions.showAlert('Ocorreu um erro ao tentar selecionar a Marca', 'danger'));
    } finally {
        yield call(history.push, { pathname: '/' });
    }
}

export function* setCurrentUnit(action: any) {
    try {
        yield put(companyActions.setCurrentUnitSuccess(action.payload));

        api.defaults.headers.common['X-Brand'] = (action.payload.currentBrand && action.payload.currentBrand.slug) || '';
        api.defaults.headers.common['X-Unit'] = (action.payload.currentUnit && action.payload.currentUnit.slug) || '';

        apiMongo.defaults.headers.common['X-Brand'] = (action.payload.currentBrand && action.payload.currentBrand.slug) || '';
        apiMongo.defaults.headers.common['X-Unit'] = (action.payload.currentUnit && action.payload.currentUnit.slug) || '';

        const payload = {
            method: 'GET',
            endpoint: '/person/menu'
        };
        yield put(authActions.getNavbarMenuRequest(payload));

        delete api.defaults.headers.common['X-Company'];
        delete apiMongo.defaults.headers.common['X-Company'];

        yield put(modalActions.closeModal());
    } catch (error) {
        yield put(companyActions.setCurrentUnitFailure());
        yield put(authActions.logout());
        yield put(alertActions.showAlert('Ocorreu um erro ao tentar selecionar a Empresa', 'danger'));
    } finally {
        yield call(history.push, { pathname: '/' });
    }
}
