import { lazy } from 'react';

const List = lazy(() => import('pages/crud'));
const LessonPlan = lazy(() => import('pages/lessonPlan'));
const Event = lazy(() => import('pages/event'));

const lessonPlanRoutes = [
    {
        path: '/app/plano-de-estudos',
        name: 'Plano de Estudos',
        component: List,
        exact: true,
        title: 'Plano de Estudos'
    },
    {
        path: '/app/plano-de-estudos/novo',
        name: 'Novo',
        component: LessonPlan,
        exact: true,
        title: 'Novo plano de estudos'
    },
    {
        path: '/app/plano-de-estudos/editar/:id',
        name: 'Editar',
        component: LessonPlan,
        exact: true,
        title: 'Editar plano de estudos'
    },
    {
        path: '/app/plano-de-estudos/evento/:idEvent',
        name: 'Editar Evento',
        component: Event,
        exact: true,
        title: 'Editar Evento'
    }
];

export default lessonPlanRoutes;
