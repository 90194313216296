import React, { useCallback, useState } from 'react';
import { ErrorContainer } from './styles';
import Button from 'components/button';
import Icon from 'components/icons/icon';
import { SmallTitle } from 'assets/global';

interface IProps {
    onClick?(): void;
    title?: string;
}

const ErrorUI = ({ onClick, title = 'Desculpe, tivemos um problema.' }: IProps) => {
    const [clicked, setClick] = useState(false);

    const handleClick = useCallback(() => {
        if (onClick) {
            onClick();
        } else {
            window.location.reload();
        }

        setClick(true);
    }, [onClick]);

    return (
        <ErrorContainer>
            <Icon idIcon="ico-page-error" width="80" height="80" viewBox="0 0 100 100" />
            <SmallTitle>{title}</SmallTitle>
            <p>
                <a href="https://fast.com/" target="_blank" rel="noopener noreferrer">
                    Verifique
                </a>{' '}
                se você está conectado a internet.
            </p>
            <Button isLoading={clicked} type="button" onClick={handleClick} variant="info" size="small">
                Tente novamente
            </Button>
        </ErrorContainer>
    );
};

export default ErrorUI;
