export type ListContentArray = Array<{ id: number; name: string; order: number; points?: number; idQuestion: number }>;

const reorder = (list: ListContentArray, startIndex: number, endIndex: number) => {
    const result = Array.from(list);

    const [removed] = result.splice(startIndex, 1);

    result.splice(endIndex, 0, removed);

    const newListReordered = result.map((question, index) => ({
        ...question,
        order: index + 1
    }));

    return newListReordered;
};

// Para arrays genéricos (sem typagem)
export function reorderGeneric<T>(list: T[], startIndex: any, endIndex: any): T[] {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);

    result.splice(endIndex, 0, removed);

    return result;
}

export default reorder;
