import { call, select } from 'redux-saga/effects';
import { IReduxStore } from 'utils/interfaces/IReduxStore';
import api, { headers } from 'services/api';
import { AxiosRequestConfig } from 'axios';

export type restVerbs = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE' | '';

export function* apiRequest(method: restVerbs, endpoint: string, body?: any, customHeaders?: any, onChangeUploadProgress?: (percentage: number) => void) {
    const authStore = (state: IReduxStore): IReduxStore => state;
    const state = yield select(authStore);

    const configs: AxiosRequestConfig = {
        headers: {
            ...headers.headers,
            Authorization: `Bearer ${state.auth.token}`,
            ...customHeaders,
            ...(!!state.organizations.currentCompany && { 'X-Company': state.organizations.currentCompany.slug }),
            ...(state.organizations.currentBrand.id > 0 && { 'X-Brand': state.organizations.currentBrand.slug }),
            ...(!!state.organizations.currentUnit && { 'X-Unit': state.organizations.currentUnit.slug })
        },
        ...(onChangeUploadProgress && {
            onUploadProgress: (progressEvent: any) => {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);

                if (!!percentCompleted && percentCompleted % 5 === 0) {
                    onChangeUploadProgress(percentCompleted);
                }
            }
        })
    };

    if (method === 'GET') {
        return yield call(api.get, endpoint, configs);
    } else if (method === 'POST') {
        return yield call(api.post, endpoint, body, configs);
    } else if (method === 'PUT') {
        return yield call(api.put, endpoint, body, configs);
    } else if (method === 'PATCH') {
        return yield call(api.patch, endpoint, body, configs);
    } else if (method === 'DELETE') {
        return yield call(api.delete, endpoint, configs);
    }

    return;
}
