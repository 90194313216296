import InputTypes from 'utils/enums/inputTypes';
import { IField } from 'utils/interfaces/IField';

const subject: IField[] = [
    {
        presentation: 'Nome',
        label: 'Nome da Disciplina',
        name: 'name',
        type: InputTypes.text,
        columns: 3
    },
    {
        presentation: 'Cor',
        label: 'Cor da Disciplina',
        name: 'color',
        type: InputTypes.text,
        columns: 3
    }
];

export default subject;
