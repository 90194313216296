import history from 'services/history';
import dictionary from 'config/dictionary';
import { IReduxAction } from 'store/types/IRedux';
import { IOption } from 'utils/interfaces/IOption';
import { graphQLRequest } from '../graphQLRequest';
import { IReduxStore } from 'utils/interfaces/IReduxStore';
import { Creators as AlertActions } from 'store/ducks/alert';
import { Creators as ModalActions } from 'store/ducks/modal';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import * as queries from 'pages/lesson-plan-educational/services/queries';
import { lessonPlanEducational, lessonPlanModule, lessonPlanModuleItem } from 'pages/lesson-plan-educational/services/mutations';
import { Types as LessonPlanEducationalTypes, Creators as LessonPlanEducationalActions } from 'store/ducks/lesson-plan-educational';
import {
    ILessonPlanEducational,
    MethodType,
    ILessonPlanEducationalModule,
    ILessonPlanModulePayload,
    ILessonPlanModuleItem,
    ILessonPlanModuleItemPayload,
    ILessonPlanModuleItemResponse
} from 'store/types/ILessonPlanEducational';

import _orderBy from 'lodash/orderBy';
import { reorderGeneric } from 'utils/reorderArray';
import { apiRequest } from '../apiRequest';

const lessonPlanEducationalState = ({ lessonPlanEducational }: IReduxStore) => lessonPlanEducational.lessonPlan;

const LOTypeStrategy = {
    Video: 'Vídeo',
    ExerciseList: 'Lista de exercícios',
    Material: 'Material didático',
    Apostila: 'Apostila'
};

const formatContentPerType = (modules: any[]) => {
    return modules.map((module) => ({
        id: module.id,
        name: module.name,
        subject_front: module.subject_front,
        contentPerType: Object.keys(module)
            .filter((item) => item !== 'id' && item !== 'name' && item !== 'subject_front')
            .map((item) => ({
                type: item,
                items: Array.from(Array(module[item].aggregate.count), (_, index) => index),
                title: dictionary.course[LOTypeStrategy[item]],
                events: []
            }))
    }));
};

const formatedModules = (modules: any[], id_lesson_plan_module_educational: number) => {
    return modules.map((item, index) => ({
        id: item.id,
        id_lesson_plan_module_educational,
        id_booklet_module: item.booklet_module.id,
        order: index + 1
    }));
};

function* setupLessonPlan() {
    const { id } = yield select(lessonPlanEducationalState);

    yield put(LessonPlanEducationalActions.getLessonPlanRequest({ id: id.toString() }));
    yield put(LessonPlanEducationalActions.getLessonPlanModulesRequest({ id: id.toString() }));
}

function* getCourses(action: IReduxAction<{ brand: number }>) {
    try {
        const { data }: { data: { courses: IOption[] } } = yield graphQLRequest(queries.relations.getCourses, { id: action.payload.brand });

        if (!data) {
            throw new Error();
        }

        yield put(LessonPlanEducationalActions.getCoursesSuccess(data.courses));
    } catch (error) {
        yield put(LessonPlanEducationalActions.getCoursesFailure());
    }
}

function* getCurrentModulesOnLessonPlan(action: IReduxAction<{ id: number }>) {
    try {
        const { data }: { data: { prodigio_booklet_modules: any[] } } = yield graphQLRequest(queries.relations.getCurrentModulesOnLessonPlan, { id: action.payload.id });

        if (!data) {
            throw new Error();
        }

        const response = data.prodigio_booklet_modules.map((item) => item.id);

        yield put(LessonPlanEducationalActions.getCurrentModulesOnLessonPlanSuccess(response));
    } catch (error) {
        yield put(LessonPlanEducationalActions.getCurrentModulesOnLessonPlanFailure());
    }
}

function* getCourseSubjects(action: IReduxAction<{ id: number }>) {
    try {
        const { data }: { data: { subjects: IOption[] } } = yield graphQLRequest(queries.relations.getCourseSubjects, { id: action.payload.id });

        if (!data) {
            throw new Error();
        }

        yield put(LessonPlanEducationalActions.getCourseSubjectsSuccess(data.subjects));
    } catch (error) {
        yield put(LessonPlanEducationalActions.getCourseSubjectsFailure());
    }
}

function* getSubjectModules(action: IReduxAction<{ id: number; idCourse: number }>) {
    try {
        const { data }: { data: { modules: any[] } } = yield graphQLRequest(queries.relations.getSubjectModules, { id: action.payload.id, idCourse: action.payload.idCourse });

        if (!data) {
            throw new Error();
        }

        const response = formatContentPerType(data.modules);

        yield put(LessonPlanEducationalActions.getSubjectModulesSuccess(response));
    } catch (error) {
        yield put(LessonPlanEducationalActions.getSubjectModulesFailure());
    }
}

function* getModule(action: IReduxAction<{ id: number }>) {
    try {
        const { data }: { data: { module: ILessonPlanModuleItem } } = yield graphQLRequest(queries.lessonPlan.getLessonPlanModuleByPk, { id: action.payload.id });

        if (!data) {
            throw new Error();
        }

        const response: ILessonPlanModuleItemResponse = {
            id: data.module.id,
            order: data.module.order,
            lesson_plan_module_educational_items: data.module.lesson_plan_module_educational_items.map((module) => ({
                id: module.id,
                id_booklet_module: module.booklet_module.id,
                order: module.order,
                name: module.booklet_module.name,
                subjectFront: module.booklet_module.subject_front || { id: module.booklet_module.subject.id, title: module.booklet_module.subject.name }
            }))
        };

        yield put(LessonPlanEducationalActions.getModuleSuccess(response));
    } catch (error) {
        console.log('error', error);
        yield put(LessonPlanEducationalActions.getModuleFailure());
    }
}

function* getLessonPlan(action: IReduxAction<{ id: string }>) {
    try {
        const { data }: { data: { lessonPlan: ILessonPlanEducational } } = yield graphQLRequest(queries.lessonPlan.getLessonPlan, { id: action.payload.id });

        yield put(LessonPlanEducationalActions.getLessonPlanSuccess(data.lessonPlan));
    } catch (error) {
        yield put(LessonPlanEducationalActions.getLessonPlanFailure());
        yield put(AlertActions.showAlert(error?.response?.data?.detail?.message || 'Ocorreu um erro. Tente novamente mais tarde.', 'danger'));
    }
}

function* getLessonPlanModules(action: IReduxAction<{ id: string }>) {
    try {
        const { data }: { data: { modules: ILessonPlanEducationalModule[] } } = yield graphQLRequest(queries.lessonPlan.getLessonPlanModules, { id: action.payload.id });

        yield put(LessonPlanEducationalActions.getLessonPlanModulesSuccess(data.modules));
    } catch (error) {
        yield put(LessonPlanEducationalActions.getLessonPlanModulesFailure());
    }
}

function* changeLessonPlanEducational(id: number) {
    const { course } = yield select(lessonPlanEducationalState);

    yield apiRequest(
        'POST',
        `/admin/lessonplaneducational/${id}/change`,
        { lessonPlan: id },
        {
            'X-Course': course.slug
        }
    );
}

function* createOrUpdateLessonPlan(action: { type: string; payload: { method: MethodType; body: any } }) {
    try {
        const { body, method } = action.payload;
        const isCreate = method === 'CREATE';

        const mutation = isCreate ? lessonPlanEducational.create : lessonPlanEducational.update;

        const { data, errors } = yield graphQLRequest(mutation, body);

        if (errors) {
            throw new Error();
        }

        yield put(LessonPlanEducationalActions.createOrUpdateLessonPlanEducationalSuccess());

        yield call(changeLessonPlanEducational, data.lessonPlan.id);

        const alertMessage = isCreate ? 'Plano de estudos criado com sucesso' : 'Plano de estudos atualizado com sucesso';

        yield put(AlertActions.showAlert(alertMessage, 'success'));

        if (!isCreate) {
            yield call(setupLessonPlan);
            return;
        }

        yield call(history.push, { pathname: `/app/planos-de-estudos-semanal/editar/${data.lessonPlan.id}` });
    } catch (error) {
        console.log('error', JSON.stringify(error));

        yield put(AlertActions.showAlert(error?.response?.data?.detail?.message || 'Ocorreu um erro. Tente novamente mais tarde.', 'danger'));
        yield put(LessonPlanEducationalActions.createOrUpdateLessonPlanEducationalFailure());
    }
}

function* createOrUpdateModule(action: { type: string; payload: ILessonPlanModulePayload }) {
    try {
        const isCreate = !Boolean(action.payload.id);

        const mutation = isCreate ? lessonPlanModule.create : lessonPlanModule.update;

        const { errors } = yield graphQLRequest(mutation, action.payload);

        if (errors) {
            throw new Error();
        }

        yield put(LessonPlanEducationalActions.createOrUpdateModuleSuccess());
        yield put(ModalActions.closeModal());

        const alertMessage = isCreate ? 'Módulo criado com sucesso' : 'Módulo atualizado com sucesso';

        yield put(AlertActions.showAlert(alertMessage, 'success'));

        yield call(changeLessonPlanEducational, action.payload.data.id_lesson_plan_educational);
        yield call(setupLessonPlan);
    } catch (error) {
        console.log('error', JSON.stringify(error));

        yield put(AlertActions.showAlert(error?.response?.data?.detail?.message || 'Ocorreu um erro. Tente novamente mais tarde.', 'danger'));
        yield put(LessonPlanEducationalActions.createOrUpdateModuleFailure());
    }
}

function* reorderModules(action: { type: string; payload: any[] }) {
    try {
        const { errors } = yield graphQLRequest(lessonPlanModule.reorder, { data: action.payload });

        if (errors) {
            throw new Error();
        }

        yield put(LessonPlanEducationalActions.reorderModulesSuccess());
        yield put(AlertActions.showAlert('Módulos atualizados com sucesso', 'success'));

        const { id } = yield select(lessonPlanEducationalState);

        yield call(changeLessonPlanEducational, id);
        yield put(LessonPlanEducationalActions.getLessonPlanModulesRequest({ id: id.toString() }));
    } catch (error) {
        console.log('error', JSON.stringify(error));

        yield put(AlertActions.showAlert(error?.response?.data?.detail?.message || 'Ocorreu um erro. Tente novamente mais tarde.', 'danger'));
        yield put(LessonPlanEducationalActions.reorderModulesFailure());
    }
}

function* reorderModuleItems(action: { type: string; payload: any[] }) {
    try {
        const { errors } = yield graphQLRequest(lessonPlanModuleItem.reorder, { data: action.payload });
        const idLessonPlanModuleEducational = action.payload[0].id_lesson_plan_module_educational;

        if (errors) {
            throw new Error();
        }

        yield put(LessonPlanEducationalActions.reorderModuleItemsSuccess());
        yield put(AlertActions.showAlert('Conteúdos do módulo atualizados com sucesso', 'success'));

        yield put(LessonPlanEducationalActions.getModuleRequest({ id: idLessonPlanModuleEducational }));
    } catch (error) {
        console.log('error', JSON.stringify(error));

        yield put(AlertActions.showAlert(error?.response?.data?.detail?.message || 'Ocorreu um erro. Tente novamente mais tarde.', 'danger'));
        yield put(LessonPlanEducationalActions.reorderModuleItemsFailure());
    }
}

function* deleteModule(action: { type: string; payload: { id: number } }) {
    try {
        const { id } = yield select(lessonPlanEducationalState);
        const { errors } = yield graphQLRequest(lessonPlanModule.delete, { date: new Date(), id: action.payload.id });

        if (errors) {
            throw new Error();
        }

        yield put(LessonPlanEducationalActions.deleteModuleSuccess());

        yield put(ModalActions.closeModal());
        yield put(AlertActions.showAlert('Módulo apagado com sucesso', 'success'));

        yield call(changeLessonPlanEducational, id);
        yield call(setupLessonPlan);
    } catch (error) {
        yield put(LessonPlanEducationalActions.deleteModuleFailure());
        yield put(AlertActions.showAlert(error?.response?.data?.detail?.message || 'Ocorreu um erro. Tente novamente mais tarde.', 'danger'));
    }
}

function* addModuleItem(action: { type: string; payload: ILessonPlanModuleItemPayload }) {
    try {
        const { errors } = yield graphQLRequest(lessonPlanModuleItem.create, { data: action.payload });

        if (errors) {
            throw new Error();
        }

        const { id_lesson_plan_module_educational, order } = action.payload;
        const { data }: { data: { module: ILessonPlanModuleItem } } = yield graphQLRequest(queries.lessonPlan.getLessonPlanModuleByPk, { id: id_lesson_plan_module_educational });
        const reorderModules = reorderGeneric(data.module.lesson_plan_module_educational_items, 0, order - 1);
        const sortedModules = _orderBy(reorderModules, ['order', 'id'], ['asc', 'desc']);

        const mappedModules = formatedModules(sortedModules, id_lesson_plan_module_educational);

        const { errors: errorsReorder } = yield graphQLRequest(lessonPlanModuleItem.reorder, { data: mappedModules });

        if (errorsReorder) {
            throw new Error();
        }

        yield put(LessonPlanEducationalActions.addModuleItemSuccess());

        yield put(AlertActions.showAlert('Item adicionado com sucesso', 'success'));

        const { module, id } = yield select(lessonPlanEducationalState);

        yield call(changeLessonPlanEducational, id);
        yield put(LessonPlanEducationalActions.getCurrentModulesOnLessonPlanRequest({ id }));
        yield put(LessonPlanEducationalActions.getModuleRequest({ id: module.id }));
    } catch (error) {
        yield put(LessonPlanEducationalActions.addModuleItemFailure());
        yield put(AlertActions.showAlert(error?.response?.data?.detail?.message || 'Ocorreu um erro. Tente novamente mais tarde.', 'danger'));
    }
}

function* deleteModuleItem(action: { type: string; payload: { id: number } }) {
    try {
        const { errors } = yield graphQLRequest(lessonPlanModuleItem.delete, { date: new Date(), id: action.payload.id });

        if (errors) {
            throw new Error();
        }

        yield put(LessonPlanEducationalActions.deleteModuleItemSuccess());
        yield put(AlertActions.showAlert('Item removido com sucesso', 'success'));

        const { module, id } = yield select(lessonPlanEducationalState);

        const { data }: { data: { module: ILessonPlanModuleItem } } = yield graphQLRequest(queries.lessonPlan.getLessonPlanModuleByPk, { id: module.id });

        if (data?.module?.lesson_plan_module_educational_items?.length > 0) {
            const sortedModules = reorderGeneric(data.module.lesson_plan_module_educational_items, 0, 0);

            const mappedModules = formatedModules(sortedModules, module.id);

            const { errors: errorsReorder } = yield graphQLRequest(lessonPlanModuleItem.reorder, { data: mappedModules });

            if (errorsReorder) {
                throw new Error();
            }
        }

        yield call(changeLessonPlanEducational, id);
        yield put(LessonPlanEducationalActions.getCurrentModulesOnLessonPlanRequest({ id }));
        yield put(LessonPlanEducationalActions.getModuleRequest({ id: module.id }));
    } catch (error) {
        yield put(LessonPlanEducationalActions.deleteModuleItemFailure());
        yield put(AlertActions.showAlert(error?.response?.data?.detail?.message || 'Ocorreu um erro. Tente novamente mais tarde.', 'danger'));
    }
}

export default [
    takeLatest(LessonPlanEducationalTypes.GET_MODULE_REQUEST, getModule),
    takeLatest(LessonPlanEducationalTypes.GET_COURSES_REQUEST, getCourses),
    takeLatest(LessonPlanEducationalTypes.DELETE_MODULE_REQUEST, deleteModule),
    takeLatest(LessonPlanEducationalTypes.ADD_MODULE_ITEM_REQUEST, addModuleItem),
    takeLatest(LessonPlanEducationalTypes.GET_LESSON_PLAN_REQUEST, getLessonPlan),
    takeLatest(LessonPlanEducationalTypes.REORDER_MODULES_REQUEST, reorderModules),
    takeLatest(LessonPlanEducationalTypes.DELETE_MODULE_ITEM_REQUEST, deleteModuleItem),
    takeLatest(LessonPlanEducationalTypes.GET_COURSE_SUBJECTS_REQUEST, getCourseSubjects),
    takeLatest(LessonPlanEducationalTypes.GET_SUBJECT_MODULES_REQUEST, getSubjectModules),
    takeLatest(LessonPlanEducationalTypes.REORDER_MODULE_ITEMS_REQUEST, reorderModuleItems),
    takeLatest(LessonPlanEducationalTypes.GET_LESSON_PLAN_MODULES_REQUEST, getLessonPlanModules),
    takeLatest(LessonPlanEducationalTypes.CREATE_OR_UPDATE_MODULE_REQUEST, createOrUpdateModule),
    takeLatest(LessonPlanEducationalTypes.CREATE_OR_UPDATE_LESSON_PLAN_EDUCATIONAL_REQUEST, createOrUpdateLessonPlan),
    takeLatest(LessonPlanEducationalTypes.GET_CURRENT_MODULES_ON_LESSON_PLAN_REQUEST, getCurrentModulesOnLessonPlan)
];
