import styled from 'styled-components';
import { MediaQueries } from 'assets/settings';

export const LogoContainer = styled.div`
    ${MediaQueries.SMALL} {
        > img {
            max-width: 80%;
        }
    }
`;
