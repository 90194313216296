import React, { Suspense } from 'react';

// Dependencies
import { Switch, Route, withRouter, RouteComponentProps } from 'react-router';

// Components
import MainLoader from './loader';
import Header from 'components/header';
import Footer from 'components/footer';

// Helpers
import privateRoutes from 'routes/privateRoutes';
import { ICrudActions } from 'utils/interfaces/IActions';

// Assets
import { MainContainer, MainWrapper } from 'assets/global';
import ErrorBoundary from 'components/error/error';

interface IProps {
    crudActions: ICrudActions;
}

class Main extends React.Component<IProps & RouteComponentProps<any>> {
    public render() {
        return (
            <MainContainer>
                <Header />
                <MainWrapper id="main">
                    <ErrorBoundary>
                        <Suspense fallback={<MainLoader />}>
                            <Switch>
                                {privateRoutes.map((route, index) => {
                                    if (route.hasViews) {
                                        return route.views!.map((item, key) => (
                                            <Route exact={item.exact} path={item.path} key={key} render={(routeProps) => <item.component {...routeProps} title={item.title} config={route} />} />
                                        ));
                                    }

                                    return <Route exact={route.exact} path={route.path} component={route.component} key={index} />;
                                })}
                            </Switch>
                        </Suspense>
                    </ErrorBoundary>
                </MainWrapper>
                <Footer />
            </MainContainer>
        );
    }
}

export default withRouter(Main);
