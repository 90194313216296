import theme from 'config/theme';
import styled from 'styled-components/macro';
import { ButtonContainer } from 'components/button/styles';

export const ButtonGroupContainer = styled.nav`
    background: ${theme.colors.white};
    border: 1px solid ${theme.colors.borderColor};
    box-shadow: 0px 0px 10px 0px #e2e2e2;
    border-radius: 5px;
    white-space: nowrap;

    ${ButtonContainer} {
        border-radius: 0;

        &:first-child {
            border-radius: 5px 0 0 5px;
        }
        &:last-child {
            border-radius: 0 5px 5px 0;
        }

        &:not(:last-child) {
            border-right: 1px solid ${theme.colors.borderColor};
        }
    }
`;
