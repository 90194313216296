import { put, call } from 'redux-saga/effects';
import { Creators as alertActions } from 'store/ducks/alert';
import { Creators as userActions } from 'store/ducks/user';
import { Creators as modalActions } from 'store/ducks/modal';
import { apiRequest } from '../apiRequest';
import history from 'services/history';
import { IRequestAction } from 'utils/interfaces/IRequestAction';
import { uploadFile } from '../upload';

export function* getUserById(action: IRequestAction<any>) {
    try {
        const { payload } = action;

        const { data } = yield apiRequest(payload.method, payload.endpoint, payload.body, payload.headers);

        yield put(userActions.getUserSuccess(data));
    } catch (error) {
        console.log(error);
        yield put(userActions.getUserFailure());
        yield put(alertActions.showAlert('Ocorreu um erro. Tente novamente mais tarde.', 'danger'));
    }
}

export function* createOrEditUser(action: IRequestAction<any>) {
    try {
        const { payload } = action;

        if (!!payload.body.person.imageProfile) {
            const payloadUpload = {
                method: 'POST',
                endpoint: `/upload`,
                body: {
                    file: payload.body.person.imageProfile
                }
            };

            const response = yield call(uploadFile, payloadUpload);

            payload.body.person.imageProfile = response;
        }

        const { data } = yield apiRequest(payload.method, payload.endpoint, payload.body, payload.headers);

        yield put(userActions.createOrEditUserSuccess(data));

        yield put(alertActions.showAlert('Usuário Salvo!', 'success'));

        if (payload.method === 'POST') {
            yield call(history.push, { pathname: `/app/usuarios/editar/${data.id}` });
        }
    } catch (error) {
        console.log(error);
        yield put(userActions.createOrEditUserFailure());
        yield put(alertActions.showAlert('Ocorreu um erro. Tente novamente mais tarde.', 'danger'));
    }
}

export function* changeUserPassword(action: IRequestAction<any>) {
    try {
        const { payload } = action;

        yield apiRequest(payload.method, payload.endpoint, payload.body, payload.headers);

        yield put(userActions.changeUserPasswordSuccess());

        yield put(alertActions.showAlert('Senha alterada com sucesso!', 'success'));

        yield put(modalActions.closeModal());
    } catch (error) {
        console.log(error);
        yield put(userActions.changeUserPasswordFailure());
        yield put(alertActions.showAlert('Ocorreu um erro. Tente novamente mais tarde.', 'danger'));
    }
}
