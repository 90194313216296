import { put, call } from 'redux-saga/effects';
import { Creators as alertActions } from 'store/ducks/alert';
import { Creators as profilesActions } from 'store/ducks/profiles';
import { apiRequest } from '../apiRequest';
import history from 'services/history';

export function* getProfileById(action: any) {
    try {
        const { payload } = action;

        const { data } = yield apiRequest(payload.method, payload.endpoint, payload.body, payload.headers);

        yield put(profilesActions.getProfileByIdSuccess(data));
    } catch (error) {
        console.log(error);
        yield put(profilesActions.getProfileByIdFailure());
        yield put(alertActions.showAlert('Ocorreu um erro. Tente novamente mais tarde.', 'danger'));
    }
}

export function* createOrEditProfile(action: any) {
    try {
        const { payload } = action;

        const { data } = yield apiRequest(payload.method, payload.endpoint, payload.body, payload.headers);

        yield put(
            profilesActions.createOrEditProfileSuccess({
                ...data,
                ...payload.body
            })
        );

        if (payload.method === 'POST') {
            // @ts-ignore
            yield call(history.push, `/app/perfis/editar/${data.id}`);
        }

        yield put(alertActions.showAlert('Perfil Salvo!', 'success'));
    } catch (error) {
        console.log(error);
        yield put(profilesActions.createOrEditProfileFailure());
        yield put(alertActions.showAlert('Ocorreu um erro. Tente novamente mais tarde.', 'danger'));
    }
}
