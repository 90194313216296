import { keyframes } from 'styled-components';

export const bounceInLeft = keyframes`from,
60%,
75%,
90%,
to {
  -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

0% {
  opacity: 0;
  -webkit-transform: translate3d(-3000px, 0, 0);
  transform: translate3d(-3000px, 0, 0);
}

60% {
  opacity: 1;
  -webkit-transform: translate3d(25px, 0, 0);
  transform: translate3d(25px, 0, 0);
}

75% {
  -webkit-transform: translate3d(-10px, 0, 0);
  transform: translate3d(-10px, 0, 0);
}

90% {
  -webkit-transform: translate3d(5px, 0, 0);
  transform: translate3d(5px, 0, 0);
}

to {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}`;

export const bounceRight = keyframes`
    from,
    15%,
    20%,
    35%,
    50%,
    65%,
    80%,
    to {animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);}

    from {
        opacity: 0;
        transform: translate3d(3000px, 0, 0);
    }

    10% {
        opacity: 1;
        transform: translate3d(-25px, 0, 0);
    }

    20% {
        transform: translate3d(0, 0, 0);
    }

    90% {
        transform: translate3d(0, 0, 0);
    }

    to {
        transform: translate3d(3000px, 0, 0);
        display: none;
    }
`;

export const pulse = keyframes`
    0% {
        transform: scaleX(1)
    }
    50% {
        transform: scale3d(1.2, 1.2, 1.2)
    }
    to {
        transform: scaleX(1)
    }
`;

export const jelly = keyframes`
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(.6);
    }
    100% {
        transform: scale(1);
    }
`;

export const jelly2 = keyframes`
    0% {
        transform: scale(1.4);
    }
    45% {
        transform: scale(1);
    }
    100% {
        transform: scale(1.4);
    }
`;

export const jelly3 = keyframes`
    0% {
        transform: scale(1.8);
    }
    40% {
        transform: scale(1.5);
    }
    100% {
        transform: scale(1.8);
    }
`;

export const rotate = keyframes`
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }  
`;

export const FadeInUp = keyframes`
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
`;

export const FadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const FadeInDown = keyframes`
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
`;

export const SlideInLeft = keyframes`
    from {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
        visibility: visible
    }
    to {
        -webkit-transform: translateZ(0);
        transform: translateZ(0)
    }
`;

export const SlideInLeftSmall = keyframes`
    from {
        -webkit-transform: translate3d(-10%, 0, 0);
        transform: translate3d(-10%, 0, 0);
        visibility: visible
    }
    to {
        -webkit-transform: translateZ(0);
        transform: translateZ(0)
    }
`;

export const SlideInBottom = keyframes`
    from {
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
        visibility: visible
    }
    to {
        -webkit-transform: translateZ(0);
        transform: translateZ(0)
    }
`;

export const SlideInUp = keyframes`
    from {
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
        visibility: visible
    }
    to {
        -webkit-transform: translateZ(0);
        transform: translateZ(0)
    }
`;

export const Floating = keyframes`
    0% {
        transform: translateY(0%);	
    }
    50% {
        transform: translateY(5%);	
    }	
    100% {
        transform: translateY(0%);
    }
`;

export const loadingDots = keyframes`
    0%   {content: ''}
    25%  {content: '.'}
    50%  {content: '..'}
    100% {content: '...'}
`;

export const tada = keyframes`
from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  10%,
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }

  30%,
  50%,
  70%,
  90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }

  40%,
  60%,
  80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
`;

export const dash = keyframes`
    0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
`;
