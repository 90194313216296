import React, { memo, useMemo, useCallback } from 'react';

// Dependencies
import _filter from 'lodash/filter';
import _orderBy from 'lodash/orderBy';

// Components
import Card from 'components/card';
import Button from 'components/button';
import Icon from 'components/icons/icon';
import Loading from 'components/loading';
import Tooltip from 'components/tooltip';
import ActionsNav from './components/actions';
import PageHeader from 'components/pageHeader';
import OrderSummary from './components/order-summary';
import Actions from 'pages/projects/components/actions';
import OrderProducts from './components/order-products';

// Helpers
import formatDate from 'utils/formatDate';
import dictionary from 'config/dictionary';
import { formatCEP, formatCpf } from 'utils/formatText';
import generateOrderStatusColor from 'utils/generateOrderStatusColor';
import { IStoreOrder, OrderAddressType, IStoreLocationAddress } from 'utils/interfaces/IStore';

// Assets
import { Row, Col } from 'react-bootstrap';
import { Content, Container, Subtitle } from 'assets/global';
import { OrderContainer, OrderActionsWrapper } from './styles';
import { NavListContainer, NavListItem } from 'components/NavList/styles';
import { StudentDataGroup, StudentDataItem, StudentContentWrapper } from 'pages/student/styles';

interface IOrderProps {
    order: IStoreOrder;
    isLoading: boolean;
    onAddAddress: (type: OrderAddressType) => void;
    onEditAddress: (type: OrderAddressType, address: IStoreLocationAddress) => void;
    onDeleteAddress: (id: number) => void;
    onRefundOrder: () => void;
}

const customerType = {
    individual: 'Pessoa física',
    company: 'Empresarial'
};

const Order = ({ order, isLoading, onAddAddress, onEditAddress, onDeleteAddress, onRefundOrder }: IOrderProps) => {
    const getAddress = useCallback((type: OrderAddressType) => _filter(order.addresses, { address_type: type }), [order.addresses]);

    const ADDRESS = useMemo(
        () => (type: OrderAddressType) => {
            const address = getAddress(type);

            return address;
        },
        [getAddress]
    );

    const movimentations = useMemo(() => {
        if (!order.movimentations.length) {
            return;
        }

        return _orderBy(order.movimentations, 'created', 'desc');
    }, [order.movimentations]);

    const OrderActions = useMemo(() => {
        const actions = [];
        const whiteStatus = ['AuthorizedPayment', 'OrderCompleted'];

        if (!Boolean(ADDRESS(OrderAddressType.Shipping).length)) {
            actions.push({ label: 'Adicionar endereço de entrega', action: () => onAddAddress(OrderAddressType.Shipping) });
        }

        if (whiteStatus.includes(order.status.name)) {
            actions.push({ label: 'Estornar pedido', action: onRefundOrder });
        }

        return actions;
    }, [ADDRESS, onAddAddress, onRefundOrder, order.status.name]);

    const handleAddAddress = useCallback(() => onAddAddress(OrderAddressType.Financial), [onAddAddress]);

    return (
        <OrderContainer>
            {isLoading && <Loading fullScreen={true} />}
            <PageHeader title={order.code ? `Pedido #${order.code}` : 'Visualizar pedido'} />
            <Content>
                <Container>
                    <Row>
                        <Col md={4}>
                            <Card
                                cardBody={
                                    <StudentDataGroup>
                                        <Subtitle>
                                            <Icon idIcon="ico-student" width="21" height="21" viewBox="0 0 512.001 512.001" />
                                            Dados do cliente
                                        </Subtitle>
                                        {order.customer ? (
                                            <div>
                                                <StudentDataItem>
                                                    <strong>Nome</strong>
                                                    <span>
                                                        {order.customer.name} {order.customer.last_name}
                                                    </span>
                                                </StudentDataItem>
                                                <StudentDataItem>
                                                    <strong>E-mail</strong>
                                                    <span>{order.customer.email}</span>
                                                </StudentDataItem>
                                                <StudentDataItem>
                                                    <strong>CPF</strong>
                                                    <span>{formatCpf(order.customer.document_number)}</span>
                                                </StudentDataItem>
                                                {order.customer.phone_number && (
                                                    <StudentDataItem>
                                                        <strong>Telefone</strong>
                                                        <span>{order.customer.phone_number}</span>
                                                    </StudentDataItem>
                                                )}
                                                <StudentDataItem>
                                                    <strong>Tipo do cliente</strong>
                                                    <span>{customerType[order.customer.type]}</span>
                                                </StudentDataItem>
                                                <StudentDataItem>
                                                    <strong>
                                                        Endereço de cobrança
                                                        {!Boolean(ADDRESS(OrderAddressType.Financial).length) && (
                                                            <Button variant="outline-success" size="small" onClick={handleAddAddress}>
                                                                <Icon idIcon="ico-plus" width="10" height="10" viewBox="0 0 31.444 31.444" />
                                                                <Tooltip content="Adicionar" />
                                                            </Button>
                                                        )}
                                                    </strong>
                                                    {ADDRESS(OrderAddressType.Financial).length > 0 ? (
                                                        ADDRESS(OrderAddressType.Financial).map((item) => (
                                                            <div key={item.id}>
                                                                <span>
                                                                    {item.street}, {item.number} - {item.complement} - {item.city}, {item.state} • {formatCEP(item.zip_code)}
                                                                </span>
                                                                <Actions
                                                                    options={[
                                                                        {
                                                                            buttonVariant: 'outline-warning',
                                                                            icon: <Icon idIcon="ico-pencil" width="12" height="12" viewBox="0 0 512 512" />,
                                                                            action: () => onEditAddress(OrderAddressType.Financial, item)
                                                                        },
                                                                        {
                                                                            buttonVariant: 'outline-danger',
                                                                            icon: <Icon idIcon="ico-trash" width="12" height="13" viewBox="0 0 465 512" />,
                                                                            action: () => onDeleteAddress(item.id)
                                                                        }
                                                                    ]}
                                                                    tooltipDirection="left"
                                                                />
                                                            </div>
                                                        ))
                                                    ) : (
                                                        <span>Nenhum endereço cadastrado. </span>
                                                    )}
                                                </StudentDataItem>
                                            </div>
                                        ) : (
                                            <p>Nenhum dado encontrado</p>
                                        )}
                                    </StudentDataGroup>
                                }
                            />
                        </Col>
                        <Col md={8}>
                            <StudentContentWrapper>
                                <Card
                                    cardHeader={
                                        OrderActions.length > 0 ? (
                                            <OrderActionsWrapper>
                                                <ActionsNav options={OrderActions} />
                                            </OrderActionsWrapper>
                                        ) : undefined
                                    }
                                    cardBody={
                                        <>
                                            <OrderSummary addresses={ADDRESS(OrderAddressType.Shipping)} order={order} onEditAddress={onEditAddress} onDeleteAddress={onDeleteAddress} />
                                            <OrderProducts order={order} />
                                        </>
                                    }
                                />
                                <Card
                                    cardBody={
                                        <StudentDataGroup>
                                            <Subtitle>
                                                <Icon idIcon="ico-order-status" width="21" height="21" viewBox="0 0 511.999 511.999" />
                                                Atualizações do pedido
                                            </Subtitle>
                                            {movimentations ? (
                                                <NavListContainer as="ul">
                                                    {movimentations.map((move) => (
                                                        <NavListItem as="li" style={{ cursor: 'unset' }} key={move.id}>
                                                            <StudentDataItem>
                                                                <strong style={{ color: generateOrderStatusColor(move.order_status.name) }}>{dictionary.crud[move.order_status.name]}</strong>
                                                                <span>{formatDate('LLL', move.created)}</span>
                                                            </StudentDataItem>
                                                        </NavListItem>
                                                    ))}
                                                </NavListContainer>
                                            ) : (
                                                <p>Nenhuma movimentação encontrada</p>
                                            )}
                                        </StudentDataGroup>
                                    }
                                />
                            </StudentContentWrapper>
                        </Col>
                    </Row>
                </Container>
            </Content>
        </OrderContainer>
    );
};

export default memo(Order);
