import InputTypes from 'utils/enums/inputTypes';
import { IField } from 'utils/interfaces/IField';

const invoices: IField[] = [
    {
        presentation: 'Status',
        label: 'Status',
        name: 'status',
        type: InputTypes.text,
        columns: 3
    }
];

export default invoices;
