import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { Creators as alertActions } from 'store/ducks/alert';
import { Creators as modalActions } from 'store/ducks/modal';
import { Creators as answerCardActions, Types } from 'store/ducks/answerCard';

import { apiRequest } from '../apiRequest';
import { QuestionType } from 'utils/enums/question';
import history from 'services/history';
import { graphQLRequest } from '../graphQLRequest';

import _set from 'lodash/set';
import _omit from 'lodash/omit';
import { IReduxStore } from 'utils/interfaces/IReduxStore';
import { AnswerCardStatus } from 'utils/enums/answerCard';
import { getAnswerCardByIdQuery } from 'utils/queries/answerCard/getById';
import { getAnswerCardByIdWithKnowledgeAreasQuery } from 'utils/queries/answerCard/getByIdWithKnowledgeAreas';
import { formatAnswerCardResponse } from './format';
import { showAlertError } from '../utils/showAlertError';
import { getExamAnswerCardKnowledgeAreasQuery } from 'utils/queries/answerCard/getExamAnswerCardKnowledgeAreas';

const URL = process.env.REACT_APP_API_URL;

// Middleware for get answercard
function* getAnswerCard(action: { type: string; payload: { id: number; isExam?: boolean } }) {
    try {
        const { id, isExam = false } = action.payload;

        if (!id) {
            throw new Error();
        }

        const query = isExam ? getAnswerCardByIdWithKnowledgeAreasQuery : getAnswerCardByIdQuery;

        const { data } = yield graphQLRequest(query, { id });

        if (!data?.item?.exerciseList) {
            throw new Error();
        }

        const response = formatAnswerCardResponse(data.item);

        yield put(answerCardActions.getAnswerCardSuccess(response));

        const [firstQuestion] = response?.exerciseList?.items;

        if (firstQuestion?.question?.id && response.status > AnswerCardStatus.Started) {
            yield put(answerCardActions.getAnswerCardQuestionRequest({ idQuestion: firstQuestion.question.id }));
        }
    } catch (error) {
        console.log('error', error);

        yield put(answerCardActions.getAnswerCardFailure({ code: error?.response?.status }));
        yield put(alertActions.showAlert(error?.response?.data?.detail?.message || 'Ocorreu um erro. Tente novamente mais tarde.', 'danger'));
    }
}

// Middleware for revise answer card
function* reviseAnswerCard(action: any) {
    try {
        const { idAnswerCard, discursiveCorrections } = yield select(({ answerCard }: IReduxStore) => ({
            idAnswerCard: answerCard.id,
            discursiveCorrections: answerCard.exerciseList?.items
                ?.filter((item) => item.question?.question_type === QuestionType.Discursive)
                .map((item) => ({ idQuestion: item.question?.id, points: item.answer?.points || 0, comment: item.answer?.correction_comment || '' }))
        }));

        if (!idAnswerCard) {
            throw new Error();
        }

        const payload = {
            discursiveCorrections,
            id: idAnswerCard,
            status: AnswerCardStatus.Corrected
        };

        yield apiRequest('PATCH', `/admin/answercard/${idAnswerCard}`, payload);

        yield put(modalActions.closeModal());

        yield put(answerCardActions.reviseAnswerCardSuccess());

        return yield call(history.push, { pathname: `/app/listas-de-exercicios-extras/correcoes/ver/${idAnswerCard}` });
    } catch (error) {
        yield put(answerCardActions.reviseAnswerCardFailure());
        yield put(alertActions.showAlert(error?.response?.data?.detail || 'Ocorreu um erro. Tente novamente mais tarde.', 'danger'));
    }
}

function* getAnswerCardQuestionRequest(action: { type: string; payload: { idQuestion: number } }) {
    try {
        const { idQuestion } = action?.payload;
        const { idAnswerCard, hasQuestion, idExerciseList } = yield select((state: IReduxStore) => ({
            idAnswerCard: state.answerCard.id,
            hasQuestion: state?.answerCard?.exerciseList?.items?.some((item) => item?.question?.id === idQuestion && !!item?.question?.alternatives),
            idExerciseList: state?.answerCard?.exerciseList?.id
        }));

        if (hasQuestion) {
            yield delay(200);

            return yield put(answerCardActions.getAnswerCardQuestionSuccess());
        }

        if (!idQuestion || !idAnswerCard || !idExerciseList) {
            throw new Error();
        }

        const { data } = yield graphQLRequest(
            `query getAnswerCardQuestion($idAnswerCard: Int!, $idQuestion: Int!, $idExerciseList: Int!) {
                exerciseListItem: prodigio_exercise_list_items(where: {id_question: {_eq: $idQuestion}, id_exercise_list: {_eq: $idExerciseList}}) {
                  points
                  is_canceled
                  id
                  question {
                    id
                    enunciation
                    question_type
                  }
                }
                answers: prodigio_answer_card_questions(where: {deleted: {_is_null: true}, id_answer_card: {_eq: $idAnswerCard}, id_question: {_eq: $idQuestion}}, order_by: {id: asc}) {
                  id
                  points
                  hit
                  correction_comment
                  discursiveAnswer
                  objective_answer
                }
              }`,
            {
                idQuestion,
                idAnswerCard,
                idExerciseList
            }
        );

        const { answers, exerciseListItem } = data;

        const [questionItem] = exerciseListItem;
        const { question } = questionItem;

        if (!question) {
            throw new Error();
        }

        const [answer] = answers;

        const result = {
            answer,
            question: {
                ..._omit(questionItem, ['question']),
                ...question,
                alternatives: []
            }
        };

        if (question.question_type === QuestionType.Objective) {
            const { data: alternatives } = yield apiRequest('GET', `${URL}/admin/question/${idQuestion}/alternatives`);

            if (!alternatives) {
                throw new Error();
            }

            _set(result, 'question.alternatives', alternatives);
        }

        yield put(answerCardActions.getAnswerCardQuestionSuccess(result));
    } catch (error) {
        console.log('error', error);

        yield put(answerCardActions.getAnswerCardFailure({ code: error?.response?.status }));
        yield put(alertActions.showAlert(error?.response?.data?.detail?.message || 'Ocorreu um erro. Tente novamente mais tarde.', 'danger'));
    }
}

function* getAnswerCardLight(action: { type: string; payload: { id: number } }) {
    try {
        const { id } = action.payload;

        if (!id) {
            throw new Error();
        }

        const { data } = yield graphQLRequest(getExamAnswerCardKnowledgeAreasQuery, { id });

        if (!data?.item) {
            throw new Error();
        }

        const response = formatAnswerCardResponse(data.item);

        yield put(answerCardActions.getAnswerCardLightSuccess(response));
    } catch (error) {
        yield put(answerCardActions.getAnswerCardLightFailure());

        yield showAlertError(error);
    }
}

export default [
    takeLatest(Types.GET_ANSWER_CARD_REQUEST, getAnswerCard),
    takeLatest(Types.REVISE_ANSWER_CARD_REQUEST, reviseAnswerCard),
    takeLatest(Types.GET_ANSWER_CARD_QUESTION_REQUEST, getAnswerCardQuestionRequest),
    takeLatest(Types.GET_ANSWER_CARD_LIGHT_REQUEST, getAnswerCardLight)
];
