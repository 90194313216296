import moment from 'moment';

export const formatCpf = (text?: string) => {
    if (!text) {
        return '-';
    }

    text = text.replace(/(\d{3})(\d)/, '$1.$2');
    text = text.replace(/(\d{3})(\d)/, '$1.$2');
    text = text.replace(/(\d{3})(\d{1,2})$/, '$1-$2');

    return text;
};

export const formatPhone = (text?: string) => {
    if (!text) {
        return '-';
    }

    text = text.replace(/\D/g, '');
    text = text.replace(/^(\d{2})(\d)/g, '($1) $2');
    text = text.replace(/(\d)(\d{4})$/, '$1-$2');

    return text;
};

export const formatCEP = (text: string) => text.replace(/(\d{5})(\d)/, '$1-$2');

export const formatMoney = (text: string) => `R$ ${text.replace('.', ',')}`;

export const formatDate = (format: string, date?: string | Date) => moment.utc(date).format(format);
