import _uniqBy from 'lodash/uniqBy';
import { createActions, createReducer } from 'reduxsauce';
import { IProductState } from 'utils/interfaces/IProduct';

import { ProductTypes, IProductActionsCreators, IProductEntity } from './types';

export const { Creators, Types } = createActions<Record<ProductTypes, ProductTypes>, IProductActionsCreators>({
    getProductRequest: ['payload'],
    getProductSuccess: ['payload'],
    getProductFailure: [],

    createOrEditProductRequest: ['payload'],
    createOrEditProductSuccess: ['payload'],
    createOrEditProductFailure: [],

    getAllProductEntityRequest: ['payload'],
    getAllProductEntitySuccess: ['payload'],
    getAllProductEntityFailure: [],

    createOrEditProductEntityRequest: ['payload'],
    createOrEditProductEntitySuccess: ['payload'],
    createOrEditProductEntityFailure: [],

    deleteProductEntityRequest: ['payload'],
    deleteProductEntitySuccess: ['payload'],
    deleteProductEntityFailure: [],

    getCurrentProductsGroupRequest: ['payload'],
    getCurrentProductsGroupSuccess: ['payload'],
    getCurrentProductsGroupFailure: [],

    openCurrentProductsGroupModal: ['payload'],
    closeCurrentProductsGroupModal: [],

    openCurrentProductsGroupSearch: [],
    closeCurrentProductsGroupSearch: [],

    addCurrentProductsGroupProductRequest: ['payload'],
    addCurrentProductsGroupProductSuccess: ['payload'],
    addCurrentProductsGroupProductFailure: [],

    createOrEditProductsGroupRequest: ['payload'],
    createOrEditProductsGroupSuccess: ['payload'],
    createOrEditProductsGroupFailure: [],

    deleteCurrentProductsGroupProductRequest: ['payload'],
    deleteCurrentProductsGroupProductSuccess: ['payload'],
    deleteCurrentProductsGroupProductFailure: [],

    clearProduct: []
});

const ENTITY_INITIAL_DATA: IProductEntity = {
    items: [],
    pagination: {
        count: 0,
        page: 0,
        totalItems: 0,
        totalPages: 0
    }
};

const INITIAL_STATE: IProductState = {
    isLoading: false,
    id: 0,
    name: '',
    description: '',
    slug: '',
    is_active: false,
    isLoadingEntity: false,
    entity: ENTITY_INITIAL_DATA,
    store: {
        id: 0,
        name: ''
    },
    isLoadingCurrentProductsGroup: false,
    activeProductsGroupModal: false,
    activeProductsGroupModalSearch: false,
    title: '',
    has_configuration: false,
    has_delivery: false
};

// GENERIC ACTIONS
const productRequest = (state = INITIAL_STATE, _: any) => ({ ...state, isLoading: true });
const productSuccess = (state = INITIAL_STATE, action: any) => ({ ...state, isLoading: false, ...action.payload });
const productFailure = (state = INITIAL_STATE) => ({ ...state, isLoading: false });

const clearProduct = () => INITIAL_STATE;

// ENTITY

const getAllEntityRequest = (state = INITIAL_STATE, _: any) => ({
    ...state,
    isLoadingEntity: true,
    entity: ENTITY_INITIAL_DATA
});

const getAllEntitySuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoadingEntity: false,
    entity: {
        ...action.payload
    }
});

const getAllEntityFailure = (state = INITIAL_STATE, _: any) => ({
    ...state,
    isLoadingEntity: false,
    entity: ENTITY_INITIAL_DATA
});

const startEntityLoading = (state = INITIAL_STATE, _: any) => ({ ...state, isLoadingEntity: true });

const stopEntityLoading = (state = INITIAL_STATE, _: any) => ({
    ...state,
    isLoadingEntity: false
});

const createOrEditEntity = (state = INITIAL_STATE, action: any) => {
    const isArray = Array.isArray(action.payload);

    if (!isArray) {
        const haveThisEntity = state.entity.items.some((item) => (!!item?.price?.id && item?.price?.id === action.payload?.price?.id) || item?.id === action.payload?.id);

        const updateEntities = haveThisEntity
            ? state.entity.items.map((item) => ((!!item?.price?.id && item?.price?.id === action.payload?.price?.id) || item?.id === action.payload?.id ? action.payload : item))
            : [action.payload, ...state.entity.items];

        return {
            ...state,
            isLoadingEntity: false,
            entity: {
                ...state.entity,
                items: updateEntities
            }
        };
    }

    const updateEntities = _uniqBy([...action.payload, ...state?.entity?.items]?.filter(Boolean), 'id');

    console.log('updateEntities', updateEntities);
    console.log('action.payload', action.payload);

    return {
        ...state,
        isLoadingEntity: false,
        entity: {
            ...state.entity,
            items: updateEntities
        }
    };
};

const deleteEntity = (state = INITIAL_STATE, action: any) => {
    const updateEntities = state.entity.items.filter((item) => item.id !== action.payload?.id);

    return {
        ...state,
        isLoadingEntity: false,
        entity: {
            ...state.entity,
            items: updateEntities
        }
    };
};

// GROUP

const getCurrentProductsGroupRequest = (state = INITIAL_STATE, _: any) => ({
    ...state,
    isLoadingCurrentProductsGroup: true,
    currentProductsGroup: undefined
});

const getCurrentProductsGroupSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoadingCurrentProductsGroup: false,
    currentProductsGroup: action.payload
});

const getCurrentProductsGroupFailure = (state = INITIAL_STATE) => ({
    ...state,
    isLoadingCurrentProductsGroup: false,
    currentProductsGroup: undefined
});

const openCurrentProductsGroup = (state = INITIAL_STATE, _: any) => ({
    ...state,
    activeProductsGroupModal: true
});

const closeCurrentProductsGroup = (state = INITIAL_STATE, _: any) => ({
    ...state,
    activeProductsGroupModal: false,
    isLoadingCurrentProductsGroup: false,
    currentProductsGroup: undefined
});

const openCurrentProductsGroupSearch = (state = INITIAL_STATE) => ({
    ...state,
    activeProductsGroupModalSearch: true
});

const closeCurrentProductsGroupSearch = (state = INITIAL_STATE) => ({
    ...state,
    activeProductsGroupModalSearch: false
});

const addCurrentProductsGroupProductRequest = (state = INITIAL_STATE) => ({
    ...state,
    isLoadingCurrentProductsGroup: true
});

const addCurrentProductsGroupProductSuccess = (state = INITIAL_STATE, action: any) => {
    const updateProducts = [...state.currentProductsGroup?.physical_products, action.payload];

    return {
        ...state,
        isLoadingCurrentProductsGroup: false,
        currentProductsGroup: {
            ...state.currentProductsGroup,
            physical_products: updateProducts
        }
    };
};

const addCurrentProductsGroupProductFailure = (state = INITIAL_STATE) => ({
    ...state,
    isLoadingCurrentProductsGroup: false
});

// create or edit group

const createOrEditProductsGroupRequest = (state = INITIAL_STATE) => ({
    ...state,
    isLoadingCurrentProductsGroup: true
});

const createOrEditProductsGroupSuccess = (state = INITIAL_STATE, action: any) => {
    const haveThisGroup = state.entity.items.some((item) => item.id === action.payload.id);

    const updateGroups = haveThisGroup ? state.entity.items.map((item) => (item.id === action.payload.id ? action.payload : item)) : [action.payload, ...state.entity.items];

    return {
        ...state,
        isLoadingCurrentProductsGroup: false,
        currentProductsGroup: action.payload,
        entity: {
            ...state.entity,
            items: updateGroups
        }
    };
};

const createOrEditProductsGroupFailure = (state = INITIAL_STATE) => ({
    ...state,
    isLoadingCurrentProductsGroup: false
});

// delete

const deleteCurrentProductsGroupProductRequest = (state = INITIAL_STATE) => ({
    ...state,
    isLoadingCurrentProductsGroup: true
});

const deleteCurrentProductsGroupProductSuccess = (state = INITIAL_STATE, action: any) => {
    const updateProducts = state.currentProductsGroup?.physical_products?.filter((item) => item.physical_product.id !== action.payload.id);

    return {
        ...state,
        isLoadingCurrentProductsGroup: false,
        currentProductsGroup: {
            ...state.currentProductsGroup,
            physical_products: updateProducts
        }
    };
};

const deleteCurrentProductsGroupProductFailure = (state = INITIAL_STATE) => ({
    ...state,
    isLoadingCurrentProductsGroup: false
});

const HANDLERS = {
    [Types.GET_PRODUCT_REQUEST]: productRequest,
    [Types.GET_PRODUCT_SUCCESS]: productSuccess,
    [Types.GET_PRODUCT_FAILURE]: productFailure,

    [Types.CREATE_OR_EDIT_PRODUCT_REQUEST]: productRequest,
    [Types.CREATE_OR_EDIT_PRODUCT_SUCCESS]: productSuccess,
    [Types.CREATE_OR_EDIT_PRODUCT_FAILURE]: productFailure,

    [Types.GET_ALL_PRODUCT_ENTITY_REQUEST]: getAllEntityRequest,
    [Types.GET_ALL_PRODUCT_ENTITY_SUCCESS]: getAllEntitySuccess,
    [Types.GET_ALL_PRODUCT_ENTITY_FAILURE]: getAllEntityFailure,

    [Types.CREATE_OR_EDIT_PRODUCT_ENTITY_REQUEST]: startEntityLoading,
    [Types.CREATE_OR_EDIT_PRODUCT_ENTITY_SUCCESS]: createOrEditEntity,
    [Types.CREATE_OR_EDIT_PRODUCT_ENTITY_FAILURE]: stopEntityLoading,

    [Types.DELETE_PRODUCT_ENTITY_REQUEST]: startEntityLoading,
    [Types.DELETE_PRODUCT_ENTITY_SUCCESS]: deleteEntity,
    [Types.DELETE_PRODUCT_ENTITY_FAILURE]: stopEntityLoading,

    [Types.GET_CURRENT_PRODUCTS_GROUP_REQUEST]: getCurrentProductsGroupRequest,
    [Types.GET_CURRENT_PRODUCTS_GROUP_SUCCESS]: getCurrentProductsGroupSuccess,
    [Types.GET_CURRENT_PRODUCTS_GROUP_FAILURE]: getCurrentProductsGroupFailure,

    [Types.OPEN_CURRENT_PRODUCTS_GROUP_MODAL]: openCurrentProductsGroup,
    [Types.CLOSE_CURRENT_PRODUCTS_GROUP_MODAL]: closeCurrentProductsGroup,

    [Types.OPEN_CURRENT_PRODUCTS_GROUP_SEARCH]: openCurrentProductsGroupSearch,
    [Types.CLOSE_CURRENT_PRODUCTS_GROUP_SEARCH]: closeCurrentProductsGroupSearch,

    [Types.ADD_CURRENT_PRODUCTS_GROUP_PRODUCT_REQUEST]: addCurrentProductsGroupProductRequest,
    [Types.ADD_CURRENT_PRODUCTS_GROUP_PRODUCT_SUCCESS]: addCurrentProductsGroupProductSuccess,
    [Types.ADD_CURRENT_PRODUCTS_GROUP_PRODUCT_FAILURE]: addCurrentProductsGroupProductFailure,

    [Types.CREATE_OR_EDIT_PRODUCTS_GROUP_REQUEST]: createOrEditProductsGroupRequest,
    [Types.CREATE_OR_EDIT_PRODUCTS_GROUP_SUCCESS]: createOrEditProductsGroupSuccess,
    [Types.CREATE_OR_EDIT_PRODUCTS_GROUP_FAILURE]: createOrEditProductsGroupFailure,

    [Types.DELETE_CURRENT_PRODUCTS_GROUP_PRODUCT_REQUEST]: deleteCurrentProductsGroupProductRequest,
    [Types.DELETE_CURRENT_PRODUCTS_GROUP_PRODUCT_SUCCESS]: deleteCurrentProductsGroupProductSuccess,
    [Types.DELETE_CURRENT_PRODUCTS_GROUP_PRODUCT_FAILURE]: deleteCurrentProductsGroupProductFailure,

    [Types.CLEAR_PRODUCT]: clearProduct
};

export default createReducer(INITIAL_STATE, HANDLERS);
