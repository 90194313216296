import Auth from 'pages/auth';
import BrandSelector from 'pages/brandSelector';
import Main from 'pages/main';

const mainRoutes = [
    {
        title: 'Auth',
        component: Auth,
        path: '/',
        exact: true
    },
    {
        title: 'Brand Selector',
        component: BrandSelector,
        path: '/empresas',
        exact: true
    },
    {
        title: 'Main',
        component: Main,
        path: '/app'
    }
];

export default mainRoutes;
