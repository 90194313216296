import React, { useState } from 'react';

// Components
import Button from 'components/button';
import Icon from 'components/icons/icon';
import ButtonGroup, { ButtonGroupOptions } from 'components/buttonGroup';

// Assets
import { ActionsNavContainer } from './styles';

interface IActionsNavProps {
    options: ButtonGroupOptions;
}

const ActionsNav = ({ options }: IActionsNavProps) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <ActionsNavContainer>
            {isOpen && <ButtonGroup options={options} />}
            <Button size="small" variant={isOpen ? 'info' : 'outline-info'} rounded={true} onClick={() => setIsOpen(!isOpen)}>
                <Icon idIcon="ico-dots" width="20" height="20" viewBox="0 0 60 60" />
            </Button>
        </ActionsNavContainer>
    );
};

export default ActionsNav;
