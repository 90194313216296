import React from 'react';

// Helpers
import history from 'services/history';
import { IAuthActions } from 'store/types/IAuth';

// Assets
import { Container } from 'assets/global';
import { UserNavContainer, UserNavButton, UserNavItem, UserNavWrapper } from './styles';

interface IProps {
    authActions: IAuthActions;
    isNavOpen: boolean;
}

const UserNav = ({ authActions, isNavOpen }: IProps) => (
    <UserNavContainer isNavOpen={isNavOpen}>
        <Container>
            <UserNavWrapper>
                <ul>
                    <UserNavItem onClick={() => history.push('/app/editar-perfil')}>
                        <UserNavButton>Editar perfil</UserNavButton>
                    </UserNavItem>
                    <UserNavItem onClick={() => authActions.logout()}>
                        <UserNavButton>Sair</UserNavButton>
                    </UserNavItem>
                </ul>
            </UserNavWrapper>
        </Container>
    </UserNavContainer>
);

export default UserNav;
