export const getAllProductPhysicalConfigurationOptions = `query getAllProductPhysicalConfigurationOptions($orderBy: [prodigio_product_physical_configuration_options_order_by!], $where: prodigio_product_physical_configuration_options_bool_exp) {
    items: prodigio_product_physical_configuration_options(where: $where, order_by: $orderBy) {
      name
      id
      sku
      media {
          id
          url
      }
    }
  }
`;
