import { put } from 'redux-saga/effects';
import { Creators as alertActions } from 'store/ducks/alert';
import { Creators as modalActions } from 'store/ducks/modal';
import { IRequestAction } from 'utils/interfaces/IRequestAction';
import { apiRequest } from '../apiRequest';
// import { Creators as modalActions } from 'store/ducks/modal';
import { Creators as subjectGroupsActions } from 'store/ducks/subjectGroups';

export function* getAllSubjectGroups(action: IRequestAction<any>) {
    try {
        const { payload } = action;

        const { data } = yield apiRequest(payload.method, payload.endpoint, payload.body, payload.headers);

        if (!data || !data.items) {
            throw new Error();
        }

        yield put(subjectGroupsActions.getSubjectGroupsSuccess(data.items));
    } catch (error) {
        console.log('error', error);

        yield put(subjectGroupsActions.getSubjectGroupsFailure());

        yield put(alertActions.showAlert('Não foi possível obter os grupos de matérias.', 'danger'));
    }
}

export function* createOrEditSubjectGroup(action: IRequestAction<any>) {
    try {
        const { payload } = action;

        const { data } = yield apiRequest(payload.method, payload.endpoint, payload.body, payload.headers);

        console.log('data', data);

        if (!data) {
            throw new Error();
        }

        yield put(modalActions.closeModal());

        yield put(subjectGroupsActions.createOrEditSubjectGroupSuccess(data));

        yield put(alertActions.showAlert('Grupo de matérias criado.', 'success'));
    } catch (error) {
        console.log('error', error);

        yield put(subjectGroupsActions.createOrEditSubjectGroupFailure());

        yield put(alertActions.showAlert('Não foi possível criar o grupo de matérias.', 'danger'));
    }
}

export function* deleteSubjectGroup(action: IRequestAction<any>) {
    try {
        const { payload } = action;

        yield apiRequest(payload.method, payload.endpoint);

        yield put(subjectGroupsActions.deleteSubjectGroupSuccess(payload.body.id));

        yield put(alertActions.showAlert('Grupo de matérias apagado.', 'success'));

        yield put(modalActions.closeModal());
    } catch (error) {
        console.log('error', error);

        yield put(subjectGroupsActions.createOrEditSubjectGroupFailure());

        yield put(alertActions.showAlert('Não foi possível apagar o grupo de matérias.', 'danger'));
    }
}

export function* requestSubjectTeachers(action: IRequestAction<any>) {
    try {
        const { payload } = action;

        console.log('payload', payload);

        const { data } = yield apiRequest(payload.method, payload.endpoint);

        console.log('data', data);

        if (!data || !Array.isArray(data)) {
            throw new Error();
        }

        yield put(
            subjectGroupsActions.getSubjectGroupsTeachersSuccess({
                ...payload.body,
                items: data
            })
        );
    } catch (error) {
        console.log('error', error);

        yield put(subjectGroupsActions.createOrEditSubjectGroupFailure());

        yield put(alertActions.showAlert('Não foi possível apagar o grupo de matérias.', 'danger'));
    }
}

export function* addSubjectTeacher(action: IRequestAction<any>) {
    try {
        const { payload } = action;

        const { data } = yield apiRequest(payload.method, payload.endpoint, payload.body);

        if (!data) {
            throw new Error();
        }

        yield put(
            subjectGroupsActions.addSubjectGroupsTeacherSuccess({
                ...payload.body,
                ...data
            })
        );

        yield put(alertActions.showAlert('Professor adicionado.', 'success'));

        yield put(modalActions.closeModal());
    } catch (error) {
        console.log('error', error);

        yield put(subjectGroupsActions.addSubjectGroupsTeacherFailure());

        yield put(alertActions.showAlert('Não foi possível apagar o grupo de matérias.', 'danger'));
    }
}

export function* addSubjectGroupSubject(action: IRequestAction<any>) {
    try {
        const { payload } = action;

        const { data } = yield apiRequest(payload.method, payload.endpoint, payload.body);

        if (!data) {
            throw new Error();
        }

        yield put(
            subjectGroupsActions.addSubjectGroupSubjectSuccess({
                ...payload.body,
                ...data
            })
        );

        yield put(alertActions.showAlert('Matéria adicionada.', 'success'));

        yield put(modalActions.closeModal());
    } catch (error) {
        console.log('error', error);

        yield put(subjectGroupsActions.addSubjectGroupSubjectFailure());

        yield put(alertActions.showAlert('Não foi possível criar o grupo de matérias.', 'danger'));
    }
}

export function* deleteSubjectGroupSubject(action: IRequestAction<any>) {
    try {
        const { payload } = action;

        yield apiRequest(payload.method, payload.endpoint);

        yield put(subjectGroupsActions.deleteSubjectGroupSubjectSuccess(payload.body));

        yield put(alertActions.showAlert('Materia removida.', 'success'));
    } catch (error) {
        console.log('error', error);

        yield put(subjectGroupsActions.deleteSubjectGroupSubjectFailure());

        yield put(alertActions.showAlert('Não foi possível criar o grupo de matérias.', 'danger'));
    }
}

export function* deleteSubjectGroupTeacher(action: IRequestAction<any>) {
    try {
        // deleteSubjectGroupTeacher
        const { payload } = action;

        yield apiRequest(payload.method, payload.endpoint);

        yield put(subjectGroupsActions.deleteSubjectGroupTeacherSuccess(payload.body));

        yield put(alertActions.showAlert('Professor removido.', 'success'));
    } catch (error) {
        console.log('error', error);

        yield put(subjectGroupsActions.deleteSubjectGroupTeacherFailure());

        yield put(alertActions.showAlert('Não foi possível remover o professor da matéria.', 'danger'));
    }
}

export function* reorderSubjectGroups(action: IRequestAction<any>) {
    try {
        // deleteSubjectGroupTeacher
        const { payload } = action;
        const { items } = payload.body;

        if (!items) {
            throw new Error();
        }

        const formatItemsForRequest = items.map((item: any, index: number) => ({
            id: item.id,
            order: index + 1
        }));

        yield apiRequest(payload.method, payload.endpoint, formatItemsForRequest);

        const formatItems = items.map((item: any, index: number) => ({
            order: index + 1,
            ...item
        }));

        yield put(subjectGroupsActions.reorderSubjectGroupsSuccess({ ...payload.body, items: formatItems }));

        yield put(alertActions.showAlert('Grupos de matéria reordenados.', 'success'));
    } catch (error) {
        console.log('error', error);

        yield put(subjectGroupsActions.reorderSubjectGroupsFailure());

        yield put(alertActions.showAlert('Não foi possível reordenar os grupos de matéria.', 'danger'));
    }
}
