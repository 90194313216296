export const getAllApplymentWindowQuery = `query ($where: prodigio_students_bool_exp, $offset: Int) {
    items: prodigio_students(where: $where, order_by: {person: {name: asc}}, limit: 150, offset: $offset) {
      id
      person {
        name
        user {
          email
        }
      }
    }
    total: prodigio_students_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
  `;
