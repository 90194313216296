import React from 'react';

// Components
import Icon from 'components/icons/icon';

// Helpers
import theme from 'config/theme';

// Assets
import { Container } from 'assets/global';
import { FooterContainer, FooterText, FooterHelper, FooterCol } from './styles';
import { NavContainer, NavWrapper, NavItem } from 'components/navBar/styles';

import packageJson from '../../../package.json';

const Footer = () => (
    <FooterContainer id="footer">
        <Container>
            <FooterCol>
                <FooterText>Prodígio Educação &copy;</FooterText>
                <FooterHelper>
                    Feito com
                    <Icon idIcon="ico-heart" viewBox="0 0 426.668 426.668" width="12" height="12" />
                    em busca de uma educação <strong style={{ color: theme.colors.system.danger }}>melhor</strong>. v{packageJson.version}
                </FooterHelper>
            </FooterCol>
            <NavContainer>
                <NavWrapper>
                    <NavItem>
                        <a href="https://proenem.slack.com" rel="noopener noreferrer" title="Slack" target="_blank">
                            Slack
                        </a>
                    </NavItem>
                    <NavItem>
                        <a href="https://proenem-company.monday.com" rel="noopener noreferrer" title="Monday" target="_blank">
                            Monday
                        </a>
                    </NavItem>
                    <NavItem>
                        <a href="https://drive.google.com/drive/folders/1hgOdWhphChbKgP_KXCS7yYsQ-ETXcx1X" rel="noopener noreferrer" title="Tutoriais" target="_blank">
                            Tutoriais
                        </a>
                    </NavItem>
                </NavWrapper>
            </NavContainer>
        </Container>
    </FooterContainer>
);

export default Footer;
