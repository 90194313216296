import styled from 'styled-components';
import theme from 'config/theme';

export const UserNavContainer = styled.div<{ isNavOpen: boolean }>`
    background: ${theme.colors.secondary};
    padding: ${theme.spacing.small.vertical};
    margin-top: ${({ isNavOpen }) => (isNavOpen ? 0 : '-40px')};
    transition: margin 0.2s ease;
    width: 100%;
`;

export const UserNavWrapper = styled.nav`
    > ul {
        align-items: center;
        display: flex;
        justify-content: flex-end;
    }
`;

export const UserNavItem = styled.li`
    &:not(:last-child) {
        margin-right: ${theme.spacing.medium.all};
    }
`;

export const UserNavButton = styled.button`
    color: ${theme.colors.white};
    font-family: ${theme.typography.family.primary.light.regular};
    font-size: ${theme.typography.sizes.small};
    opacity: 1;
    text-transform: uppercase;
    transition: opacity 0.2s ease;

    &:hover {
        opacity: 0.7;
    }
`;
