import React from 'react';
import { LoadingWrap } from './styles';
import Spinner from 'components/spinner';
import theme from 'config/theme';

interface IProps {
    fullScreen?: boolean;
}

const Loading = ({ fullScreen }: IProps) => (
    <LoadingWrap fullScreen={fullScreen}>
        {!fullScreen && 'Carregando... '}
        <Spinner color={theme.colors.secondary} />
    </LoadingWrap>
);

export default Loading;
