import api from 'services/api';
import apiMongo from 'services/api-mongo';

export default (store: any) => (next: any) => (action: any) => {
    const result = next(action);
    const state = store.getState();

    if (action.type === 'persist/REHYDRATE') {
        const { token } = state.auth;

        api.defaults.headers.common.Authorization = `Bearer ${token}`;
        apiMongo.defaults.headers.common.Authorization = `Bearer ${token}`;

        if (state.organizations.currentBrand.id > 0) {
            api.defaults.headers.common['X-Brand'] = state.organizations.currentBrand.slug;
            apiMongo.defaults.headers.common['X-Brand'] = state.organizations.currentBrand.slug;
        }

        if (!!state.organizations.currentCompany) {
            api.defaults.headers.common['X-Company'] = state.organizations.currentCompany.slug;
            apiMongo.defaults.headers.common['X-Company'] = state.organizations.currentCompany.slug;
        }

        if (!!state.organizations.currentUnit) {
            api.defaults.headers.common['X-Unit'] = state.organizations.currentUnit.slug;
            apiMongo.defaults.headers.common['X-Unit'] = state.organizations.currentUnit.slug;
        }
    }

    return result;
};
