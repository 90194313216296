import styled from 'styled-components';
import theme from 'config/theme';
import { Subtitle } from 'assets/global';
import { ButtonContainer } from 'components/button/styles';
import { CheckboxContainer } from 'components/forms/styles';

export const TemplateContentItemOrder = styled.div`
    display: flex;
    align-items: center;
    width: 25px;

    .ico-move {
        display: none;
        fill: ${theme.colors.secondary};
    }

    p {
        color: ${theme.colors.secondary};
    }
`;

export const TemplateContainer = styled.div`
    overflow-y: auto;
    padding: ${theme.spacing.large.all};
    width: 100%;
    flex: 1;

    ${Subtitle} {
        color: ${theme.colors.primary};
        display: block;
        font-size: ${theme.typography.sizes.large};
        /* margin-bottom: ${theme.spacing.large.all}; */
    }
`;

export const TemplateWrapper = styled.div``;

export const TemplateItem = styled.div`
    ${ButtonContainer} {
        align-items: center;
        display: flex;
        justify-content: center;
        height: 25px;
        padding: 0;
        position: relative;
        width: 25px;

        svg {
            margin: 0;
        }
    }

    &:not(:last-child) {
        margin-bottom: ${theme.spacing.medium.all};
    }
`;

export const TemplateItemHeader = styled.header`
    align-items: center;
    border-bottom: 1px solid ${theme.colors.secondary};
    color: ${theme.colors.secondary};
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: ${theme.spacing.small.all};
    padding: 15px 10px;
    transition: background 0.2s ease;

    ${ButtonContainer} {
        position: relative;
    }

    > div {
        align-items: center;
        display: flex;

        .ico-live,
        .ico-play,
        .ico-exercises-list,
        .ico-monitoring,
        .ico-material,
        .ico-playlist {
            fill: ${theme.colors.base};
        }

        svg {
            margin-right: ${theme.spacing.small.all};
        }
    }
`;

export const TemplateItemTitle = styled.strong`
    font-size: ${theme.typography.sizes.normalXX};
`;

export const TemplateContent = styled.div`
    width: 100%;
`;

export const TemplateContentItem = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: ${theme.spacing.small.all};
    width: 100%;

    ${CheckboxContainer} {
        margin-right: 12px;
    }

    ${ButtonContainer} {
        padding: 0;
        margin: 0 5px;
    }

    &:not(:last-child) {
        border-bottom: 1px solid ${theme.colors.borderColor};
    }

    &:hover {
        background: #f9fafa;
    }

    .ico-qrcode {
        width: 18px;
        height: 18px;
    }

    :hover {
        ${TemplateContentItemOrder} {
            .ico-move {
                display: block;
            }

            p {
                display: none;
            }
        }
    }
`;

export const TemplatePreviewButton = styled.button`
    display: flex;
    flex-direction: column;
    gap: 4px;
    text-align: left;
    flex: 1;

    img {
        display: none;
    }

    > * {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
`;

export const TemplatePreviewButtonContentWrapper = styled.div`
    display: flex;
    align-items: center;
    padding: 5px;
    border-radius: 3px;
    background-color: #6dc0e0;
`;

export const TemplatePreviewButtonId = styled.span`
    font-size: 11px;
    font-weight: 300;
    color: #ffffff;
    text-transform: uppercase;
`;

export const TemplatePreviewButtonTitle = styled.p`
    white-space: break-spaces;
`;

export const TemplatePreviewWrapper = styled.div`
    text-align: left;

    img {
        display: none;
    }

    > * {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
`;

export const TemplateDownloadButton = styled.a`
    color: ${theme.colors.base};
    text-align: left;
    text-decoration: underline;
    flex: 1;
`;

export const TemplateHeader = styled.div`
    display: flex;
    margin-bottom: 25px;
    align-items: center;

    ${Subtitle} {
        flex: 1;
    }
`;

export const TemplateNav = styled.nav`
    align-items: center;
    display: flex;
    /* margin-bottom: ${theme.spacing.medium.all}; */
    justify-content: flex-end;

    ${ButtonContainer} {
        padding: 7px;
    }

    svg {
        width: 16px;
        height: 16px;
    }
`;
