import { put } from 'redux-saga/effects';

// Redux
import { Creators as questionsActions } from 'store/ducks/questions';

export function* createQuestionsResolution(action: any) {
    try {
        yield put(questionsActions.createQuestionsResolutionRequest(action));
    } catch (error) {
        console.log('error');
    }
}
