export const productPhysicalGroupByPk = `query getProductPhysicalGroup($id: Int!) {
    item: prodigio_products_physical_group_by_pk(id: $id) {
      id
      name
      has_configuration
      media {
        id
        url
      }
      physical_products {
        physical_product {
          id
          name
        }
      }
    }
  }
`;
