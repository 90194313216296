import { createActions, createReducer } from 'reduxsauce';

export const { Creators, Types } = createActions({
    getAllSchoolUnitsRequest: ['payload'],
    getAllSchoolUnitsSuccess: ['payload'],
    getAllSchoolUnitsFailure: [],

    getSchoolUnitByIdRequest: ['payload'],
    getSchoolUnitByIdSuccess: ['payload'],
    getSchoolUnitByIdFailure: [],

    createOrEditSchoolUnitRequest: ['payload'],
    createOrEditSchoolUnitSuccess: ['payload'],
    createOrEditSchoolUnitFailure: [],

    clearSchoolUnit: []
});

export interface ISchoolUnitState {
    isLoading: boolean;

    id: number;
    name: string;
    director: string;
    address: {
        name: string;
        number: any;
        complement: string;
        zip: any;
        city: string;
        state: string;
    };
    brand?: {
        id: number;
        name: string;
    };

    items: any[];
}

const INITIAL_STATE: ISchoolUnitState = {
    isLoading: false,

    id: 0,
    name: '',
    director: '',
    address: {
        name: '',
        number: 0,
        complement: '',
        zip: '',
        city: '',
        state: ''
    },
    items: []
};

const getSchoolUnitByIdRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true });
const getSchoolUnitByIdSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoading: false,
    ...action.payload
});
const getSchoolUnitByIdFailure = (state = INITIAL_STATE) => ({ ...state, ...INITIAL_STATE });

const getAllSchoolUnitsRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true });
const getAllSchoolUnitsSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoading: false,
    items: action.payload
});
const getAllSchoolUnitsFailure = (state = INITIAL_STATE) => ({ ...state, ...INITIAL_STATE });

const createOrEditSchoolUnitRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true });
const createOrEditSchoolUnitSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoading: false,
    ...action.payload
});
const createOrEditSchoolUnitFailure = (state = INITIAL_STATE) => ({ ...state, ...INITIAL_STATE });

const clearSchoolUnit = (state = INITIAL_STATE) => INITIAL_STATE;

export default createReducer(INITIAL_STATE, {
    [Types.GET_ALL_SCHOOL_UNITS_REQUEST]: getAllSchoolUnitsRequest,
    [Types.GET_ALL_SCHOOL_UNITS_SUCCESS]: getAllSchoolUnitsSuccess,
    [Types.GET_ALL_SCHOOL_UNITS_FAILURE]: getAllSchoolUnitsFailure,

    [Types.GET_SCHOOL_UNIT_BY_ID_REQUEST]: getSchoolUnitByIdRequest,
    [Types.GET_SCHOOL_UNIT_BY_ID_SUCCESS]: getSchoolUnitByIdSuccess,
    [Types.GET_SCHOOL_UNIT_BY_ID_FAILURE]: getSchoolUnitByIdFailure,

    [Types.CREATE_OR_EDIT_SCHOOL_UNIT_REQUEST]: createOrEditSchoolUnitRequest,
    [Types.CREATE_OR_EDIT_SCHOOL_UNIT_SUCCESS]: createOrEditSchoolUnitSuccess,
    [Types.CREATE_OR_EDIT_SCHOOL_UNIT_FAILURE]: createOrEditSchoolUnitFailure,

    [Types.CLEAR_SCHOOL_UNIT]: clearSchoolUnit
});
