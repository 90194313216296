import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { Creators as alertActions } from 'store/ducks/alert';
import { Creators as examActions } from 'store/ducks/exam';
import { Creators as crudActions } from 'store/ducks/crud';
import { ICrudAction } from 'utils/interfaces/ICrud';
import { IExamDayAction, IExamRequest, IKnowledgeArea } from 'utils/interfaces/IExam';
import { Creators as modalActions } from 'store/ducks/modal';
import { apiRequest } from '../apiRequest';
import history from 'services/history';
import moment from 'moment';
import { IPayloadRequest } from 'utils/interfaces/IRequestAction';
import { IStudent } from 'utils/interfaces/IStudent';
import { removePortalChild } from 'components/portal/removePortalChild';
import { IReduxStore } from 'utils/interfaces/IReduxStore';
import { ExamType } from 'utils/enums/ExamType';
import { Types as ExamsTypes } from 'store/ducks/exam';
import { showAlertError } from '../utils/showAlertError';
import { IApplymentWindowSearchForm, ICreateApplymentWindowPayload } from 'utils/interfaces/IApplymentWindow';
import { graphQLRequest } from '../graphQLRequest';
import _set from 'lodash/set';
import { getAllApplymentWindowQuery } from 'utils/queries/applymentWindow/getAll';
import { IReduxAction } from 'store/types/IRedux';

function* getExamId() {
    return yield select((state: IReduxStore) => state?.exam?.id);
}

function getExamDayRequest(idExamDay: number) {
    return apiRequest('GET', `/admin/examday/${idExamDay}`);
}

// Middleware for get one exam
function* getExam(action: ICrudAction) {
    try {
        // Get exam by id
        const { data } = yield apiRequest('GET', `/admin/exam/${action.payload}`);

        const requests = data.examDays.map((examDay: any) => getExamDayRequest(examDay.id));

        // Get all examdays of exam
        const responses = yield all(requests);

        const formatResponses = {
            ...data,
            examDays: responses.map((response: any) => response.data)
        };

        yield put(examActions.getExamSuccess(formatResponses));
    } catch (error) {
        yield put(examActions.getExamFailure());
        yield showAlertError(error);
    }
}

// Middleware for create or edit a exam
function* createOrEditExam(action: ICrudAction) {
    try {
        const { data: payload, endpoint, method } = action.payload;

        const { data } = yield apiRequest(method, endpoint, payload);
        const alertMessage = method === 'POST' ? 'Simulado cadastrado com sucesso' : 'Simulado atualizado com sucesso';

        yield put(examActions.createOrEditExamSuccess(data));
        yield put(alertActions.showAlert(alertMessage, 'success'));

        const isPreviousExam = window.location.href.includes('provas-anteriores');
        const customPath = isPreviousExam ? `/app/provas-anteriores/ver/${data.id}` : `/app/simulados/ver/${data.id}`;

        const redirectPath = action.payload.redirectPath || customPath;

        yield call(history.push, { pathname: redirectPath });

        yield put(modalActions.closeModal());
    } catch (error) {
        yield put(examActions.createOrEditExamFailure());
        yield showAlertError(error);
    }
}

// Middleware for get exam day
function* getExamDay(action: { type: string; payload: number }) {
    try {
        const { data } = yield getExamDayRequest(action.payload);

        yield put(examActions.getExamDaySuccess(data));
    } catch (error) {
        yield put(examActions.getExamDayFailure());
        yield showAlertError(error);
    }
}

// Middleware for create or edit a exam
function* createExamDay(action: IExamDayAction) {
    try {
        const exam = yield select((state: IReduxStore) => state.exam);

        const formatPayload = {
            ...action.payload,

            // previous exam
            ...(exam?.type === ExamType.PreviousExam && {
                isAnswerReleased: true,
                isPaid: false
            }),

            // simulated
            ...(exam?.type === ExamType.Simulated && {
                isAnswerReleased: false,
                isPaid: true
            })
        };

        yield apiRequest('POST', '/admin/examday', formatPayload);

        yield put(alertActions.showAlert('Prova criada com sucesso', 'success'));
        yield put(examActions.getExamRequest(action.payload.exam));

        yield put(modalActions.closeModal());
    } catch (error) {
        yield put(examActions.createOrEditExamFailure());
        yield showAlertError(error);
    }
}

// Middleware for update a exam day
function* updateExamDay(action: { type: string; payload: { idExam: number; idExamDay: number; data: any; isFromModal?: boolean } }) {
    try {
        const { data } = action.payload;

        const formatData = {
            ...data,
            ...(!!data.startDate && { startDate: moment(data.startDate).toISOString() }),
            ...(!!data.endDate && { endDate: moment(data.endDate).toISOString() })
        };

        yield apiRequest('PATCH', `/admin/examday/${action.payload.idExamDay}`, formatData);

        yield put(alertActions.showAlert('Prova atualizada com sucesso', 'success'));
        yield put(examActions.getExamDayRequest(action.payload.idExamDay));

        if (action.payload.isFromModal) {
            yield put(modalActions.closeModal());
        }
    } catch (error) {
        console.log('error', error);

        yield put(examActions.updateExamDayFailure());
        yield showAlertError(error);
    }
}

function* createOrEditKnowledgeArea<T>(action: { type: string; payload: IExamRequest<any> & { onSave?(knowledgeAreas: IKnowledgeArea[]): void } }) {
    try {
        const { endpoint, method, idExamDay, body, onSave } = action.payload;

        yield apiRequest(method, endpoint, body);

        const { data } = yield apiRequest('GET', `/admin/examday/${idExamDay}`);

        if (!data) {
            throw new Error();
        }

        yield put(examActions.getExamDaySuccess(data));

        const message = `Registro ${method === 'PATCH' ? 'atualizado' : 'criado'} com sucesso`;

        yield put(alertActions.showAlert(message, 'success'));

        if (onSave && data.knowledgeAreas) {
            onSave(data.knowledgeAreas);
        } else {
            yield put(modalActions.closeModal());
        }
    } catch (error) {
        yield showAlertError(error);

        yield put(examActions.createOrEditKnowledgeAreaFailure());
    }
}

// Create or edit resume
function* createOrEditExamEntity<T>(action: { type: string; payload: IExamRequest<any> }) {
    try {
        const { endpoint, method, idExamDay, body } = action.payload;

        yield apiRequest(method, endpoint, body);

        yield put(examActions.getExamDayRequest(idExamDay));

        yield put(modalActions.closeModal());

        // Close portal

        const message = `Registro ${method === 'PATCH' ? 'atualizado' : 'criado'} com sucesso`;

        yield put(alertActions.showAlert(message, 'success'));

        removePortalChild('createQuestionModal');
    } catch (error) {
        console.log(error);
        yield showAlertError(error);

        yield put(examActions.createOrEditKnowledgeAreaFailure());
    }
}

// Middleware for delete resume of project
function* deleteExamEntity<T>(action: { type: string; payload: IExamRequest<T> }) {
    try {
        const { endpoint, type, method, idExam, idExamDay } = action.payload;

        yield apiRequest(method, endpoint);

        if (type !== 'examday') {
            yield put(examActions.getExamDayRequest(idExamDay));
        } else {
            yield put(examActions.getExamRequest(idExam));
        }

        yield put(modalActions.closeModal());

        yield put(alertActions.showAlert('Registro apagado com sucesso', 'success'));
    } catch (error) {
        yield put(examActions.deleteExamEntityFailure());
        yield showAlertError(error);
    }
}

function* getExamTesters<T>(action: { type: string; payload: IPayloadRequest<T> }) {
    try {
        const { endpoint, method } = action.payload;

        const { data } = yield apiRequest(method, endpoint);

        if (!data || !Array.isArray(data)) {
            throw new Error();
        }

        yield put(examActions.getExamTestersSuccess(data));
    } catch (error) {
        yield put(examActions.getExamTestersFailure());
        yield showAlertError(error);
    }
}

function* updateExamTesters(action: { type: string; payload: IPayloadRequest<{ testers: IStudent[] }> }) {
    try {
        const { endpoint, method, body } = action.payload;

        const formatTesters = !!body ? body.testers.map((student) => ({ id: student.id })) : [];

        const { data } = yield apiRequest(method, endpoint, { testers: formatTesters });

        if (!data) {
            throw new Error();
        }

        yield put(examActions.updateTesterSuccess(!!body ? body.testers : []));

        yield put(alertActions.showAlert('Lista de testadores atualizada com sucesso.', 'success'));
        yield put(modalActions.closeModal());
    } catch (error) {
        yield put(examActions.updateTesterFailure());
        yield showAlertError(error);
    }
}

function* getExamCompanies<T>(action: { type: string; payload: IPayloadRequest<T> }) {
    try {
        const { endpoint, method } = action.payload;

        const { data } = yield apiRequest(method, endpoint);

        if (!data || !Array.isArray(data)) {
            throw new Error();
        }

        yield put(examActions.getExamCompaniesSuccess(data));
    } catch (error) {
        yield put(examActions.getExamCompaniesFailure());
        yield showAlertError(error);
    }
}

function* updateExamCompany(action: { type: string; payload: { id: number; name: string } }) {
    try {
        const item = action.payload;
        const examId = yield getExamId();

        if (!item?.id || !examId) {
            throw new Error();
        }

        yield apiRequest('POST', `/admin/exam/${examId}/acessiblecompany`, { id: item.id });

        yield put(examActions.updateCompanySuccess(item));

        yield put(alertActions.showAlert('Lista de empresas atualizada com sucesso.', 'success'));
        yield put(modalActions.closeModal());
    } catch (error) {
        yield put(examActions.updateCompanyFailure());
        yield showAlertError(error);
    }
}

function* getExamCourses<T>(action: { type: string; payload: IPayloadRequest<T> }) {
    try {
        const { endpoint, method } = action.payload;

        const { data } = yield apiRequest(method, endpoint);

        if (!data || !Array.isArray(data)) {
            throw new Error();
        }

        yield put(examActions.getExamCoursesSuccess(data));
    } catch (error) {
        yield put(examActions.getExamCoursesFailure());
        yield showAlertError(error);
    }
}

function* deleteExamCourse<T>(action: { type: string; payload: IPayloadRequest<{ id: number }> }) {
    try {
        const { endpoint, method, body } = action.payload;

        yield apiRequest(method, endpoint);

        yield put(examActions.deleteExamCourseSuccess(body));
    } catch (error) {
        yield put(examActions.deleteExamCourseFailure());
        yield showAlertError(error);
    }
}

function* createExamCourse<T>(action: { type: string; payload: IPayloadRequest<{ id: number }> }) {
    try {
        const { endpoint, method, body } = action.payload;

        const { data } = yield apiRequest(method, endpoint, body);

        if (!data) {
            throw new Error();
        }

        yield put(examActions.createExamCourseSuccess(data));

        yield put(crudActions.refreshGetAll());

        yield put(modalActions.closeModal());
    } catch (error) {
        yield put(examActions.createExamCourseFailure());
        yield showAlertError(error);
    }
}

function* createOrEditExamCorrectionEngine(action: { type: string; payload: { id: number; name: string } }) {
    try {
        const item = action.payload;
        const examId = yield getExamId();

        if (!item?.id || !examId) {
            throw new Error();
        }

        yield apiRequest('POST', `/admin/exam/${examId}/relatedCorrectionEngine`, {
            id: item.id
        });

        yield put(examActions.createOrEditExamCorrectionEngineSuccess(item));
        yield put(crudActions.refreshGetAll());
        yield put(modalActions.closeModal());
        yield put(alertActions.showAlert('Lista de motores de correção atualizada.', 'success'));
    } catch (error) {
        yield showAlertError(error);

        yield put(examActions.createOrEditExamCorrectionEngineFailure());
    }
}

function* deleteResolution(action: { type: string; payload: { id: number } }) {
    try {
        const id = action?.payload?.id;

        if (!id) {
            throw new Error();
        }

        yield apiRequest('DELETE', `/admin/exam_resolution/${id}`);

        // UPDATE GENERIC DUCK STATE
        const updateItems = yield select(({ crud }: IReduxStore) => crud?.data?.items?.filter((item: any) => item.id !== id) || []);
        yield put(crudActions.deleteSuccess(updateItems));

        yield put(examActions.deleteExamResolutionSuccess({ id }));
        yield put(modalActions.closeModal());
        yield put(alertActions.showAlert('Lista de motores de correção atualizada.', 'success'));
    } catch (error) {
        yield put(examActions.deleteExamResolutionFailure());
        yield showAlertError(error);
    }
}

function* deleteCompany(action: { type: string; payload: { id: number } }) {
    try {
        const { id } = action.payload;
        const examId = yield getExamId();

        if (!id || !examId) {
            throw new Error();
        }

        yield apiRequest('DELETE', `/admin/exam/${examId}/acessiblecompany/${id}`);

        yield put(examActions.deleteExamCompanySuccess({ id }));
        yield put(modalActions.closeModal());
        yield put(alertActions.showAlert('Empresa removida com sucesso.', 'success'));
    } catch (error) {
        yield put(examActions.deleteExamCompanyFailure());
        yield showAlertError(error);
    }
}

function* deleteCorrectionEngine(action: { type: string; payload: { id: number } }) {
    try {
        const { id } = action.payload;
        const examId = yield getExamId();

        if (!id || !examId) {
            throw new Error();
        }

        yield apiRequest('DELETE', `/admin/exam/${examId}/relatedCorrectionEngine/${id}`);

        yield put(examActions.deleteExamResolutionSuccess({ id }));
        yield put(crudActions.refreshGetAll());
        yield put(modalActions.closeModal());
        yield put(alertActions.showAlert('Motor de correção removido com sucesso.', 'success'));
    } catch (error) {
        yield put(examActions.deleteExamResolutionFailure());
        yield showAlertError(error);
    }
}

function* getApplymentWindowStudents(action: { type: string; payload: IApplymentWindowSearchForm }) {
    try {
        if (!Object.keys(action?.payload)?.length) {
            return;
        }

        const { brands, email, schoolClasses, units } = action.payload;

        const where: any = {
            deleted: {
                _is_null: true
            },
            person: {
                deleted: {
                    _is_null: true
                },
                user: {
                    deleted: {
                        _is_null: true
                    },
                    ...(!!email && {
                        email: {
                            _eq: email
                        }
                    })
                }
            }
        };

        if (brands?.length) {
            _set(where, 'brand', {
                deleted: {
                    _is_null: true
                },
                id: {
                    _in: brands
                }
            });
        }

        if (schoolClasses?.length) {
            _set(where, 'subscriptions', {
                deleted: {
                    _is_null: true
                },
                school_class: {
                    id: {
                        _in: schoolClasses
                    }
                }
            });
        }

        if (units?.length) {
            if (where?.subscriptions?.school_class) {
                _set(where, 'subscriptions', {
                    deleted: {
                        _is_null: true
                    },
                    school_class: {
                        deleted: {
                            _is_null: true
                        },
                        _and: [
                            {
                                unit: {
                                    deleted: {
                                        _is_null: true
                                    },
                                    id: {
                                        _in: units
                                    }
                                }
                            },
                            {
                                id: {
                                    _in: schoolClasses
                                }
                            }
                        ]
                    }
                });
            } else {
                _set(where, 'subscriptions', {
                    deleted: {
                        _is_null: true
                    },
                    school_class: {
                        deleted: {
                            _is_null: true
                        },
                        unit: {
                            deleted: {
                                _is_null: true
                            },
                            id: {
                                _in: units
                            }
                        }
                    }
                });
            }
        }

        const { data } = yield graphQLRequest(getAllApplymentWindowQuery, { where });

        if (!data?.items) {
            throw new Error();
        }

        yield put(examActions.getExamApplymentWindowStudentsSuccess({ students: data.items, total: data?.total?.aggregate?.count }));
    } catch (error) {
        yield put(examActions.getExamApplymentWindowStudentsFailure());

        yield showAlertError(error);
    }
}

function* createApplymentWindow(action: { type: string; payload: ICreateApplymentWindowPayload }) {
    try {
        const { students, ...applymentWindow } = action.payload;

        if (!applymentWindow?.id && !applymentWindow?.examDay?.id) {
            throw new Error();
        }

        const response = !!applymentWindow?.id ? applymentWindow : yield apiRequest('POST', '/admin/applymentwindow', applymentWindow);

        const data = response?.data || response;

        if (!data?.id) {
            throw new Error();
        }

        const formatGraphQLBody = students.map((id_student) => ({
            id_applyment_window: data.id,
            id_student
        }));

        const { data: graphQLData } = yield graphQLRequest(
            `mutation insertStudents($data: [prodigio_applyment_windows_students_insert_input!]!) {
            items: insert_prodigio_applyment_windows_students(objects: $data) {
              affected_rows
            }
          }
          `,
            {
                data: formatGraphQLBody
            }
        );

        if (!graphQLData?.items) {
            throw new Error();
        }

        yield put(examActions.createExamApplymentWindowSuccess());
        yield put(modalActions.closeModal());

        yield put(crudActions.refreshGetAll());

        yield put(alertActions.showAlert('Janela de aplicação criada com sucesso.', 'success'));
    } catch (error) {
        yield put(examActions.createExamApplymentWindowFailure());

        yield showAlertError(error);
    }
}

function* deleteApplymentWindow(action: { type: string; payload: { id: number } }) {
    try {
        const { id } = action.payload;

        if (!id) {
            throw new Error();
        }

        yield apiRequest('DELETE', `/admin/applymentwindow/${id}`);

        yield put(examActions.deleteExamApplymentWindowSuccess());
        yield put(modalActions.closeModal());
        yield put(crudActions.refreshGetAll());
        yield put(alertActions.showAlert('Janela de aplicação apagada com sucesso.', 'success'));
    } catch (error) {
        yield put(examActions.deleteExamApplymentWindowFailure());

        yield showAlertError(error);
    }
}

function* deleteApplymentWindowStudent(action: { type: string; payload: { idStudent: number; idApplymentWindow: number } }) {
    try {
        const { idApplymentWindow, idStudent } = action.payload;

        if (!idStudent || !idApplymentWindow) {
            throw new Error();
        }

        const { data } = yield graphQLRequest(
            `mutation ($idStudent: Int!, $idApplymentWindow: Int!) {
                item: delete_prodigio_applyment_windows_students(where: {id_student: {_eq: $idStudent}, id_applyment_window: {_eq: $idApplymentWindow}}) {
                  affected_rows
                }
              }`,
            {
                idStudent,
                idApplymentWindow
            }
        );

        if (!data?.item) {
            throw new Error();
        }

        yield put(examActions.deleteExamApplymentWindowStudentSuccess());
        yield put(modalActions.closeModal());
        yield put(crudActions.refreshGetAll());
        yield put(alertActions.showAlert('Aluno removido da janela de aplicação com sucesso.', 'success'));
    } catch (error) {
        yield put(examActions.deleteExamApplymentWindowStudentFailure());

        yield showAlertError(error);
    }
}

function* discardImport(action: IReduxAction<{ id: number; reason: string }>) {
    try {
        const { id, reason } = action.payload;

        if (!id) {
            throw new Error();
        }

        yield apiRequest('PATCH', `/admin/exam/import/${id}/discard`, {
            reason
        });

        yield put(examActions.discardExamImportSuccess());
        yield put(modalActions.closeModal());
    } catch (error) {
        yield showAlertError(error);
        yield put(examActions.discardExamImportFailure());
    }
}

export default [
    takeLatest(ExamsTypes.GET_EXAM_REQUEST, getExam),
    takeLatest(ExamsTypes.GET_EXAM_DAY_REQUEST, getExamDay),
    takeLatest(ExamsTypes.CREATE_OR_EDIT_EXAM_REQUEST, createOrEditExam),
    takeLatest(ExamsTypes.CREATE_EXAM_DAY_REQUEST, createExamDay),
    takeLatest(ExamsTypes.UPDATE_EXAM_DAY_REQUEST, updateExamDay),
    takeLatest(ExamsTypes.DELETE_EXAM_ENTITY_REQUEST, deleteExamEntity),
    takeLatest(ExamsTypes.UPDATE_OR_CREATE_KNOWLEDGE_AREA_REQUEST, createOrEditKnowledgeArea),
    takeLatest(ExamsTypes.UPDATE_OR_CREATE_QUESTIONS_REQUEST, createOrEditExamEntity),
    takeLatest(ExamsTypes.GET_EXAM_TESTERS_REQUEST, getExamTesters),
    takeLatest(ExamsTypes.UPDATE_TESTER_REQUEST, updateExamTesters),
    takeLatest(ExamsTypes.GET_EXAM_COMPANIES_REQUEST, getExamCompanies),
    takeLatest(ExamsTypes.UPDATE_COMPANY_REQUEST, updateExamCompany),
    takeLatest(ExamsTypes.GET_EXAM_COURSES_REQUEST, getExamCourses),
    takeLatest(ExamsTypes.DELETE_EXAM_COURSE_REQUEST, deleteExamCourse),
    takeLatest(ExamsTypes.CREATE_EXAM_COURSE_REQUEST, createExamCourse),
    takeLatest(ExamsTypes.CREATE_OR_EDIT_EXAM_CORRECTION_ENGINE_REQUEST, createOrEditExamCorrectionEngine),
    takeLatest(ExamsTypes.DELETE_EXAM_RESOLUTION_REQUEST, deleteResolution),
    takeLatest(ExamsTypes.DELETE_EXAM_COMPANY_REQUEST, deleteCompany),
    takeLatest(ExamsTypes.DELETE_EXAM_CORRECTION_ENGINE_REQUEST, deleteCorrectionEngine),
    takeLatest(ExamsTypes.GET_EXAM_APPLYMENT_WINDOW_STUDENTS_REQUEST, getApplymentWindowStudents),
    takeLatest(ExamsTypes.CREATE_EXAM_APPLYMENT_WINDOW_REQUEST, createApplymentWindow),
    takeLatest(ExamsTypes.DELETE_EXAM_APPLYMENT_WINDOW_REQUEST, deleteApplymentWindow),
    takeLatest(ExamsTypes.DELETE_EXAM_APPLYMENT_WINDOW_STUDENT_REQUEST, deleteApplymentWindowStudent),
    takeLatest(ExamsTypes.DISCARD_EXAM_IMPORT_REQUEST, discardImport)
];
