import { put } from 'redux-saga/effects';
import { Creators as alertActions } from 'store/ducks/alert';
import { Creators as modalActions } from 'store/ducks/modal';
import { Creators as lessonPlanModulesActions } from 'store/ducks/lessonPlanModules';

import { apiRequest } from '../apiRequest';

export function* getLessonPlanModules(action: any) {
    try {
        const { payload } = action;
        // const { data } = yield apiRequest('GET', '/lessonplanmodule', '', {
        //     'X-Where': `lessonPlan=${payload.id}`,
        //     'X-PerPage': '100'
        // });

        const { data } = yield apiRequest(payload.method, payload.endpoint, payload.body, payload.headers);

        yield put(lessonPlanModulesActions.getLessonPlanModulesSuccess(data.items));
    } catch (error) {
        yield put(lessonPlanModulesActions.getLessonPlanModulesFailure());
        yield put(alertActions.showAlert('Não foi possível obter a lista de Modulos.', 'danger'));
    }
}

export function* createOrEditLessonPlanModule(action: any) {
    try {
        const { payload } = action;

        const { data } = yield apiRequest(payload.method, payload.endpoint, payload.data);

        yield put(lessonPlanModulesActions.createOrEditLessonPlanModulesSuccess(data));

        yield put(modalActions.closeModal());
    } catch (error) {
        console.log('error', error);

        yield put(lessonPlanModulesActions.createOrEditLessonPlanModulesFailure());

        yield put(alertActions.showAlert(error?.response?.data?.detail?.message || 'Ocorreu um erro. Tente novamente mais tarde.', 'danger'));
    }
}

export function* deleteLessonPlanModules(action: any) {
    try {
        const { payload } = action;

        yield apiRequest('DELETE', `/lessonplanmodule/${payload.id}`);

        yield put(lessonPlanModulesActions.deleteLessonPlanModulesSuccess(payload.id));
        yield put(alertActions.showAlert('Modulo excluído.', 'success'));
    } catch (error) {
        yield put(lessonPlanModulesActions.deleteLessonPlanModulesFailure());
        yield put(alertActions.showAlert('Não foi possível obter a lista de Modulos.', 'danger'));
    }
}
