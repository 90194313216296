export default function filterSubscriberReponseData(response: any, connectionId: string | number) {
    try {
        const data = JSON.parse(response);

        if (data?.id !== connectionId || data?.type !== 'data') {
            return undefined;
        }

        return data?.payload?.data;
    } catch (error) {
        console.log(error);
        return undefined;
    }
}
