const lessonPlanEducational = {
    create: `mutation ($data: prodigio_lesson_plans_educational_insert_input!) {
        lessonPlan: insert_prodigio_lesson_plans_educational_one(object: $data) {
            id
        }
    }`,
    update: `mutation ($data: prodigio_lesson_plans_educational_set_input!, $id: Int!) {
        lessonPlan: update_prodigio_lesson_plans_educational_by_pk(pk_columns: { id: $id }, _set: $data) {
            id
        }
    }`
};

const lessonPlanModule = {
    create: `mutation ($data: prodigio_lesson_plan_modules_educational_insert_input!) {
        module: insert_prodigio_lesson_plan_modules_educational_one(object: $data) {
            id
        }
    }`,
    update: `mutation ($data: prodigio_lesson_plan_modules_educational_set_input!, $id: Int!) {
        module: update_prodigio_lesson_plan_modules_educational_by_pk(pk_columns: { id: $id }, _set: $data) {
            id
        }
    }`,
    delete: `mutation ($date: timestamptz, $id: Int!) {
        update_prodigio_lesson_plan_modules_educational_by_pk(pk_columns: { id: $id }, _set: { deleted: $date }) {
          id
        }
      }
    `,
    reorder: `mutation ($data: [prodigio_lesson_plan_modules_educational_insert_input!]!) {
        insert_prodigio_lesson_plan_modules_educational(objects: $data, on_conflict: { constraint: PK_c50f42add027b63ad1cfa5f9b04 , update_columns: [order] }) {
            affected_rows
        }
    }`
};

const lessonPlanModuleItem = {
    create: `mutation ($data: prodigio_lesson_plan_module_educational_items_insert_input!) {
        item: insert_prodigio_lesson_plan_module_educational_items_one(object: $data) {
            id
        }
    }`,
    delete: `mutation ($date: timestamptz, $id: Int!) {
        update_prodigio_lesson_plan_module_educational_items_by_pk(pk_columns: { id: $id }, _set: { deleted: $date }) {
          id
        }
      }
    `,
    reorder: `mutation ($data: [prodigio_lesson_plan_module_educational_items_insert_input!]!) {
        insert_prodigio_lesson_plan_module_educational_items(objects: $data, on_conflict: { constraint: PK_2d8fcd50475b3db5f02b736f095 , update_columns: [order] }) {
            affected_rows
        }
    }`
};

export { lessonPlanEducational, lessonPlanModule, lessonPlanModuleItem };
