import InputTypes from 'utils/enums/inputTypes';
import { IField } from 'utils/interfaces/IField';

const announcements: IField[] = [
    {
        presentation: 'Título',
        label: 'Título',
        name: 'title',
        type: InputTypes.text,
        columns: 12
    },
    {
        presentation: 'Conteúdo',
        label: 'Conteúdo',
        name: 'description',
        type: InputTypes.textArea,
        columns: 12
    },
    {
        presentation: 'Preview',
        label: 'Preview',
        name: 'preview',
        type: InputTypes.text,
        columns: 12
    },
    {
        presentation: 'Assinatura',
        label: 'Assinatura',
        name: 'signature',
        type: InputTypes.text,
        columns: 12
    }
];

export default announcements;
