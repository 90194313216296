import List from 'pages/crud';
import Store from 'pages/store';
import StoreView from 'pages/store/storeDetails';
import StoreOrder from 'pages/store/storeOrder';

const storesRoutes = [
    {
        path: '/app/lojas',
        name: 'Lojas',
        component: List,
        exact: true,
        title: 'Lojas'
    },
    {
        path: '/app/lojas/novo',
        name: 'Nova loja',
        component: Store,
        exact: true,
        title: 'Nova loja'
    },
    {
        path: '/app/lojas/editar/:id',
        name: 'Editar',
        component: Store,
        exact: true,
        title: 'Editar loja'
    },
    {
        path: '/app/lojas/ver/:id',
        name: 'Visualizar',
        component: StoreView,
        exact: true,
        title: 'Visualizar Loja'
    },
    {
        path: '/app/lojas/ver/:id/:activeEntity',
        name: 'Visualizar',
        component: StoreView,
        exact: true,
        title: 'Visualizar Loja'
    },
    {
        path: '/app/lojas/ver/:storeId/pedidos/:id',
        name: 'Visualizar',
        component: StoreOrder,
        exact: true,
        title: 'Visualizar pedido'
    }
];

export default storesRoutes;
