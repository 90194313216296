import { IField } from 'utils/interfaces/IField';
import InputTypes from 'utils/enums/inputTypes';

const subjectFront: IField[] = [
    {
        presentation: 'Título',
        label: 'Título',
        name: 'title',
        type: InputTypes.text,
        columns: 3
    },
    {
        presentation: 'Selecione uma disciplina',
        label: 'Disciplina',
        name: 'subject',
        type: InputTypes.simpleSelect,
        columns: 3
    },
    {
        presentation: 'Ordem',
        label: 'Ordem',
        name: 'order',
        type: InputTypes.number,
        columns: 3
    }
];

export default subjectFront;
