import React from 'react';

// Components
import Logo from 'components/logo';
import AuthForm from 'components/authForm';

// Helpers
import history from 'services/history';

// Assets
import { AuthContainer, AuthBar } from './styles';

interface IProps {
    hasPermission: boolean;
    token: string;
}

const Auth = ({ hasPermission, token }: IProps) => {
    if (token && hasPermission) {
        history.push('/app');
        return <div />;
    }

    return (
        <AuthContainer>
            <AuthBar>
                <Logo type="main" />
                <AuthForm />
            </AuthBar>
        </AuthContainer>
    );
};

export default Auth;
