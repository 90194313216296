import styled, { css } from 'styled-components';
import theme from 'config/theme';
import lightenDarkenColor from 'utils/lightAndDarkColor';

export const SwitchCircle = styled.span`
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background: ${lightenDarkenColor(theme.colors.borderColor, -25)};
    box-shadow: 2px 0px 4px #ccc;
    transition: all 0.25s;
    left: -5px;
    position: absolute;
    top: -2px;
`;

export const SwitchTrail = styled.button<{ active?: boolean }>`
    background: ${lightenDarkenColor(theme.colors.borderColor, -5)};
    border-radius: 10px;
    display: inline-block;
    height: 16px;
    position: relative;
    width: 30px;
    transition: all 0.25s;

    ${({ active }) => css`
        ${SwitchCircle} {
            left: ${active && '15px'};
            background: ${active && '#fff'};
        }

        background: ${active && theme.colors.system.success};
    `}
`;
