import React, { useEffect, useCallback } from 'react';

// Components
import Icon from 'components/icons/icon';

// Helpers
import { RouteComponentProps } from 'react-router';
import { IAlertActions, IAlertState } from 'store/types/IAlert';

// Assets
import { AlertWrapper, AlertText, AlertClose, AlertContainer } from './styles';

interface IProps extends RouteComponentProps<any> {
    alert: IAlertState;
    alertActions: IAlertActions;
}

const Alert = ({ alert, alertActions }: IProps) => {
    const hideAlerts = useCallback(() => {
        try {
            alert.items.map((alertItem) =>
                setTimeout(() => {
                    alertActions.hideAlert({ id: alertItem.id });
                }, 5000)
            );
        } catch (error) {
            console.log(error);
        }
    }, [alert.items, alertActions]);

    useEffect(() => hideAlerts(), [hideAlerts]);

    return (
        <>
            {alert.items.length > 0 && (
                <AlertContainer>
                    {alert.items.map((item) => (
                        <AlertWrapper key={item.id} type={item.alertType}>
                            {Array.isArray(item.message) && (
                                <AlertText>{item.message.map((message) => Object.keys(message.constraints).map((constraint) => message.constraints[constraint]))}</AlertText>
                            )}
                            {!Array.isArray(item.message) && <AlertText>{item.message}</AlertText>}
                            <AlertClose onClick={() => alertActions.hideAlert({ id: item.id })}>
                                <Icon idIcon="ico-close" width="15" height="15" viewBox="0 0 512 512" />
                            </AlertClose>
                        </AlertWrapper>
                    ))}
                </AlertContainer>
            )}
        </>
    );
};

export default Alert;
