import { Types as ComplaintTypes, Creators as complaintActions } from 'store/ducks/complaint';
import { takeLatest, put, call, select } from 'redux-saga/effects';
import { Creators as modalActions } from 'store/ducks/modal';
import { Creators as crudActions } from 'store/ducks/crud';
import { Creators as alertActions } from 'store/ducks/alert';
import apiMongo from 'services/api-mongo';
import { IGetComplaintPayload, IUpdateComplaintPayload } from 'store/ducks/complaint/types';
import { IReduxStore } from 'utils/interfaces/IReduxStore';
import { ComplaintStatus } from 'utils/enums/complaintStatus';
import { showAlertError } from '../utils/showAlertError';

function* getById(action: { type: string; payload: IGetComplaintPayload }) {
    try {
        const { id } = action.payload;

        if (!id) {
            throw new Error();
        }

        const { data } = yield call(apiMongo.get, `/v1/admin/complaint/${id}`);

        if (!data) {
            throw new Error();
        }

        yield put(complaintActions.getComplaintByIdSuccess(data));
    } catch (error) {
        yield put(complaintActions.getComplaintByIdFailure());
        yield put(modalActions.closeModal());

        yield showAlertError(error);
    }
}

function* updateComplaint(action: { type: string; payload: IUpdateComplaintPayload }) {
    try {
        const { id, status } = action.payload;

        if (!id) {
            throw new Error();
        }

        yield call(apiMongo.patch, `/v1/admin/complaint/${id}/ratify`, {
            status
        });

        const crudItem = yield select(({ crud }: IReduxStore) => crud?.data?.items?.find((item: any) => item.id === id));

        yield put(crudActions.createOrEditSuccess({ ...crudItem, ...action.payload }));

        yield put(complaintActions.updateComplaintSuccess());

        yield put(modalActions.closeModal());

        yield put(alertActions.showAlert(`Denúncia ${status === ComplaintStatus.Aproved ? 'Aprovada' : 'Descartada'}`, 'success'));
    } catch (error) {
        yield put(complaintActions.updateComplaintFailure());

        yield showAlertError(error);
    }
}

export default [takeLatest(ComplaintTypes.GET_COMPLAINT_BY_ID_REQUEST, getById), takeLatest(ComplaintTypes.UPDATE_COMPLAINT_REQUEST, updateComplaint)];
