import InputTypes from 'utils/enums/inputTypes';
import { IField } from 'utils/interfaces/IField';

const files: IField[] = [
    {
        presentation: 'Nome',
        label: 'Nome do arquivo',
        name: 'name',
        type: InputTypes.text,
        columns: 3
    }
];

export default files;
