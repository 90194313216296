import { QuestionDifficults } from 'utils/enums/question';
import theme from 'config/theme';
import convertEnumToOptions from './convertEnumToOptions';

export const questionDifficultOptions = convertEnumToOptions(QuestionDifficults);

export const difficultyColor = {
    1: theme.colors.system.success,
    2: theme.colors.system.warning,
    3: theme.colors.system.danger
};
