import React from 'react';

// Dependencies

// Helpers
import { IAlertActions } from 'store/types/IAlert';

// Assets
import ErrorUI from './ui';

interface IProps {
    alertActions?: IAlertActions;
    children: React.ReactNode;
    onError?: () => void;
}

interface IState {
    hasError: boolean;
}

class ErrorBoundary extends React.Component<IProps, IState> {
    public state: IState = {
        hasError: false
    };

    public componentDidCatch = (error: Error, errorInfo: React.ErrorInfo) => {
        const { onError } = this.props;

        // if (process.env.NODE_ENV === 'production') {
        //     // SOME ERROR API INTEGRATION
        // }

        if (onError) {
            return onError();
        }

        this.setState({ hasError: true });
    };

    public render() {
        const { alertActions, children } = this.props;
        const { hasError } = this.state;

        if (hasError) {
            alertActions && alertActions.showAlert('Ocorreu um erro inesperado', 'danger');

            return <ErrorUI />;
        }

        return children;
    }
}

export default ErrorBoundary;
