import { put, call } from 'redux-saga/effects';

import { Creators as alertActions } from 'store/ducks/alert';
import { Creators as courseActions } from 'store/ducks/course';
import { Creators as modalActions } from 'store/ducks/modal';
import { Creators as projectActions } from 'store/ducks/project';
import { Creators as lessonPlanEventsActions } from 'store/ducks/lessonPlanEvents';
import { Creators as learningObjectActions } from 'store/ducks/learningObject';

import { apiRequest } from '../apiRequest';

// Functions
import { createQuestionsResolution } from './createLOSearch';
import { createLOVideoCourse, createLOVideoLessonPlanEvents, createLOVideoLessonPlanEventsMainVideo, createLOVideoQuestionResolution } from './createLOVideo';
import { createLOExerciseListCourse, createLOExerciseListLessonPlanEvents } from './createLOExerciseList';
import { createLOMaterialCourse, createLOMaterialLessonPlanEvents, createLOMaterialQuestionResolution, createLOMaterialFiles, editMaterialQuestionResolution } from './createLOMaterial';
import { uploadFile } from '../upload';
import { MaterialType } from 'utils/enums/materialType';

export function* createLOVideo(action: any) {
    try {
        const { payload } = action;

        const formatPayload = {
            ...payload.data,
            teachers: payload.data.teachers.map((teacher: any) => ({
                id: teacher.value
            }))
        };

        const { data } = yield apiRequest('POST', '/admin/video', formatPayload, { 'X-Relations': 'learningObject' });

        if (payload.origin === 'course') {
            return yield call(createLOVideoCourse, payload, data);
        }

        if (payload.origin === 'lessonPlanEvents') {
            return yield call(createLOVideoLessonPlanEvents, payload, data);
        }

        if (payload.origin === 'lessonPlanEventsMainVideo') {
            return yield call(createLOVideoLessonPlanEventsMainVideo, payload);
        }

        if (payload.origin === 'questionResolution') {
            return yield call(createLOVideoQuestionResolution, payload, data);
        }

        if (payload.origin === 'promoVideo') {
            yield put(modalActions.closeModal());

            return yield put(projectActions.addPromoVideo(data));
        }
    } catch (error) {
        yield put(learningObjectActions.createLOVideoFailure({ code: error?.response?.status }));
        yield put(alertActions.showAlert('Ocorreu um erro ao criar o vídeo', 'danger'));
    }
}

export function* createLOExerciseList(action: any) {
    try {
        const { payload } = action;

        if (payload.origin === 'course') {
            return yield call(createLOExerciseListCourse, payload);
        }

        if (payload.origin === 'lessonPlanEvents') {
            return yield call(createLOExerciseListLessonPlanEvents, payload);
        }

        //
    } catch (error) {
        console.log(error);
        yield put(learningObjectActions.createLOExerciseListFailure());
        yield put(alertActions.showAlert('Ocorreu um erro ao Adicionar a Lista', 'danger'));
    }
}

export function* createLOMaterial(action: any) {
    try {
        const { payload } = action;

        const customPayload = {
            ...payload.data,
            type: payload.booklet ? MaterialType.Booklet : MaterialType.Complementar
        };

        const { data } = yield apiRequest(customPayload.id ? 'PATCH' : 'POST', `/material${!!customPayload.id ? `/${customPayload.id}` : ''}`, customPayload, { 'X-Relations': 'learningObject' });

        if (!!payload.data.url) {
            const payloadUpload = {
                method: 'POST',
                endpoint: `/learningobject/${data.learningObject.id}/upload`,
                body: {
                    file: payload.data.url
                }
            };

            const response = yield call(uploadFile, payloadUpload, (percentage) => {
                if (payload.data.onChangeUploadProgress) {
                    payload.data.onChangeUploadProgress(percentage);
                }
            });

            data.url = response;
        }

        yield put(learningObjectActions.createLOMaterialSuccess(data));

        if (payload.origin === 'files') {
            return yield call(createLOMaterialFiles, data);
        }

        // COURSE
        if (payload.origin === 'course') {
            return yield call(createLOMaterialCourse, payload, data);
        }

        if (payload.origin === 'lessonPlanEvents') {
            return yield call(createLOMaterialLessonPlanEvents, payload, data);
        }

        if (payload.origin === 'questionResolution') {
            return yield call(createLOMaterialQuestionResolution, payload, data);
        }

        if (payload.origin === 'editQuestionResolution') {
            return yield call(editMaterialQuestionResolution, data);
        }

        //
    } catch (error) {
        console.log(error);
        yield put(learningObjectActions.createLOMaterialFailure());
        yield put(alertActions.showAlert('Ocorreu um erro ao criar o Material', 'danger'));
    }
}

export function* deleteLO(action: any) {
    try {
        const { id, type, module } = action.payload;

        yield apiRequest('DELETE', `/bookletcontentitem/${id}`);

        yield put(courseActions.deleteBookletContentItemSuccess({ id, type, module }));
    } catch (error) {
        yield put(learningObjectActions.deleteLOFailure());
        yield put(alertActions.showAlert('Não foi possível apagar esse Item', 'danger'));
    }
}

export function* createLOSearch(action: any) {
    try {
        const { origin, data } = action.payload;

        if (origin === 'files') {
            return yield call(createLOMaterialFiles, data);
        }
        if (origin === 'questionResolution') {
            return yield call(createQuestionsResolution, data);
        }
        if (origin === 'lessonPlanEvents') {
            yield put(learningObjectActions.createLOVideoSuccess(data));

            yield put(
                lessonPlanEventsActions.createOrEditLessonPlanEventsContentRequest({
                    event: action.payload.event,
                    content: data
                })
            );
        }

        if (origin === 'course') {
            const createBookletContentItemPayload = data.map((item: any) => {
                return {
                    module: action.payload.module,
                    learningObject: item.learningObject.id,
                    order: action.payload.newOrder
                };
            });

            yield put(courseActions.createBookletContentItemRequest(createBookletContentItemPayload));
        }
    } catch (error) {
        console.log(error);
        yield put(learningObjectActions.createLOSearchFailure());
        yield put(alertActions.showAlert('Ocorreu um error. Tente novamente mais tarde.', 'danger'));
    }
}

export function* createLOTeacherOrStudent(action: any) {
    try {
        const { origin, data } = action.payload;

        if (origin === 'questionResolution') {
            return yield call(createQuestionsResolution, data);
        }

        //
    } catch (error) {
        console.log(error);
        yield put(learningObjectActions.createLOSearchFailure());
        yield put(alertActions.showAlert('Ocorreu um error. Tente novamente mais tarde.', 'danger'));
    }
}
