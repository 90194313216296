import { put, call, takeLatest } from 'redux-saga/effects';
import { Creators as alertActions } from 'store/ducks/alert';
import { Types as contestTypes, Creators as contestActions } from 'store/ducks/contest';

import { apiRequest } from '../apiRequest';

import history from 'services/history';

export function* getContestByIdRequest(action: any) {
    try {
        const { contestId } = action.payload;

        const { data } = yield apiRequest('GET', `/contest/${contestId}`);

        yield put(contestActions.getContestByIdSuccess(data));
    } catch (error) {
        yield put(contestActions.getContestByIdFailure());
        yield put(alertActions.showAlert(error?.response?.data?.detail?.message || 'Ocorreu um erro. Tente novamente mais tarde.', 'danger'));
    }
}

export function* createOrEditContest(action: any) {
    try {
        const { payload } = action;

        const { data } = yield apiRequest(payload.method, payload.endpoint, payload.body, payload.headers);

        yield put(contestActions.createOrEditContestSuccess(data));

        yield put(alertActions.showAlert(`Registro ${payload.method === 'POST' ? 'criado' : 'atualizado'} com sucesso`, 'success'));
        yield call(history.push, { pathname: '/app/concursos' });
    } catch (error) {
        yield put(contestActions.createOrEditContestFailure());
        yield put(alertActions.showAlert(error?.response?.data?.detail || 'Não foi possível criar concurso.', 'danger'));
    }
}

export default [takeLatest(contestTypes.GET_CONTEST_BY_ID_REQUEST, getContestByIdRequest), takeLatest(contestTypes.CREATE_OR_EDIT_CONTEST_REQUEST, createOrEditContest)];
