import React, { useState, useCallback, useEffect } from 'react';
import Portal from 'components/portal';
import Button from 'components/button';
import Icon from 'components/icons/icon';
import { ModalContainer } from 'components/modal/styles';
import CSSTransition from 'react-transition-group/CSSTransition';
import { CardContainer } from 'components/card/styles';
import { AdvancedSearchContainer, AdvancedSearchHeader } from './styles';
import { SmallTitle } from 'assets/global';
import { IAdvancedSearch } from 'utils/interfaces/IRoute';
import AdvancedSearchFieldItem from './item';

interface IProps {
    active: boolean;
    onClickButton(): void;
    searchFields: IAdvancedSearch[];
    onSubmit(data: any): void;
    state: any;
    isLearningObject?: boolean;
}

const AdvancedSearch = ({ active = false, onClickButton, searchFields = [], onSubmit, state, isLearningObject }: IProps) => {
    const [stateFilters, setStateFilters] = useState<any>(state);

    useEffect(() => {
        setStateFilters(state);
    }, [state]);

    const handleSubmit = useCallback(
        (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();

            onSubmit(stateFilters);
            onClickButton();
        },
        [onClickButton, onSubmit, stateFilters]
    );

    return (
        <Portal portalId="advanced-search">
            <>
                {!active && (
                    <Button rounded variant="primary" style={{ position: 'fixed', right: '20px', bottom: '20px' }} onClick={onClickButton}>
                        <Icon idIcon="ico-search" width="20" height="20" viewBox="0 0 451 451" fill="#FFFFFF" />
                    </Button>
                )}

                <ModalContainer isVisible={active} onClick={() => onClickButton()}>
                    <AdvancedSearchContainer onClick={(e) => e.stopPropagation()}>
                        <CSSTransition in={active} timeout={300} classNames="advancedsearch" unmountOnExit>
                            <CardContainer withSpacing>
                                <AdvancedSearchHeader>
                                    <SmallTitle>BUSCA AVANÇADA</SmallTitle>

                                    <Button variant="outline-secondary" type="button" onClick={onClickButton} size="small">
                                        <Icon idIcon="ico-close" width="15" height="15" viewBox="0 0 512 512" />
                                    </Button>
                                </AdvancedSearchHeader>

                                <form onSubmit={handleSubmit}>
                                    <div style={{ marginBottom: '20px' }}>
                                        {searchFields.map((field, index) => {
                                            return (
                                                <AdvancedSearchFieldItem
                                                    key={`field_${index}`}
                                                    field={field}
                                                    stateFilters={stateFilters}
                                                    setStateFilters={setStateFilters}
                                                    isLearningObject={!!isLearningObject}
                                                />
                                            );
                                        })}
                                    </div>

                                    <Button variant="primary" type="submit" block>
                                        BUSCAR
                                    </Button>
                                </form>
                            </CardContainer>
                        </CSSTransition>
                    </AdvancedSearchContainer>
                </ModalContainer>
            </>
        </Portal>
    );
};

export default AdvancedSearch;
