import { lazy } from 'react';

const List = lazy(() => import('pages/crud'));
const Files = lazy(() => import('pages/files'));

const filesRoutes = [
    {
        path: '/app/arquivos',
        name: 'Arquivos',
        component: List,
        exact: true,
        title: 'Arquivos'
    },
    {
        path: '/app/arquivos/novo',
        name: 'Novo Arquivo',
        component: Files,
        exact: true,
        title: 'Novo Arquivo'
    },
    {
        path: '/app/arquivos/editar/:id',
        name: 'Editar',
        component: Files,
        exact: true,
        title: 'Editar Arquivo'
    }
];

export default filesRoutes;
