import { createActions, createReducer } from 'reduxsauce';
import { ILearningObjectState } from 'utils/interfaces/ILearningObject';

export const { Creators, Types } = createActions({
    createLOVideoRequest: ['payload'],
    createLOVideoSuccess: ['payload'],
    createLOVideoFailure: ['payload'],

    createLOExerciseListRequest: ['payload'],
    createLOExerciseListSuccess: ['payload'],
    createLOExerciseListFailure: ['payload'],

    createLOMaterialRequest: ['payload'],
    createLOMaterialSuccess: ['payload'],
    createLOMaterialFailure: ['payload'],

    deleteLORequest: ['payload'],
    deleteLOFailure: ['payload'],

    createLOSearchRequest: ['payload'],
    createLOSearchSuccess: ['payload'],
    createLOSearchFailure: ['payload'],

    createLOTeacherOrStudentRequest: ['payload'],
    createLOTeacherOrStudentSuccess: ['payload'],
    createLOTeacherOrStudentFailure: ['payload'],

    uploadFileRequest: ['payload'],
    uploadFileSuccess: ['payload'],
    uploadFileFailure: ['payload'],

    deleteMediaRequest: ['payload'],
    deleteMediaSuccess: ['payload'],
    deleteMediaFailure: ['payload'],

    clearUploadFile: ['payload'],

    clearLO: []
});

const INITIAL_STATE: ILearningObjectState = {
    isLoading: false,
    isLoadingUpload: false,
    errorUpload: false,
    isLoadingDeleteMedia: false,
    errorDeleteMedia: false
};

/**
 *      VIDEO
 */

const createLOVideoRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true });

const createLOVideoSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    video: action.payload,
    isLoading: false
});

const createLOVideoFailure = (state = INITIAL_STATE) => ({ ...state, isLoading: false });

/**
 *      EXERCISELIST
 */

const createLOExerciseListRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true });

const createLOExerciseListSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    exerciseList: action.payload,
    isLoading: false
});

const createLOExerciseListFailure = (state = INITIAL_STATE) => ({ ...state, isLoading: false });

/**
 *      MATERIAL
 */

const createLOMaterialRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true });

const createLOMaterialSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    material: action.payload,
    isLoading: false
});

const createLOMaterialFailure = (state = INITIAL_STATE) => ({ ...state, isLoading: false });

/**
 *      DELETE LO
 */
const deleteLORequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true });
const deleteLOFailure = (state = INITIAL_STATE) => ({ ...state, isLoading: false });

/**
 *      CLEAR
 */

const createLOSearchRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true });
const createLOSearchSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    learningObject: action.payload,
    isLoading: false
});
const createLOSearchFailure = (state = INITIAL_STATE) => ({ ...state, isLoading: false });

/**
 *      CREATE STUDENT OR TEACHER
 */

const createLOTeacherOrStudentRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true });

const createLOTeacherOrStudentSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    teacherOrStudent: action.payload,
    isLoading: false
});

const createLOTeacherOrStudentFailure = (state = INITIAL_STATE) => ({ ...state, isLoading: false });

/**
 *      UPLOAD
 */

const uploadFile = (state = INITIAL_STATE) => ({ ...state, isLoadingUpload: true, errorUpload: false });
const uploadFileSuccess = (state = INITIAL_STATE, action?: any) => ({ ...state, isLoadingUpload: false, errorUpload: false, ...action?.payload });
const uploadFileFailure = (state = INITIAL_STATE) => ({ ...state, isLoadingUpload: false, errorUpload: true });

const deleteMediaRequest = (state = INITIAL_STATE) => ({ ...state, isLoadingDeleteMedia: true, errorDeleteMedia: false });
const deleteMediaSuccess = (state = INITIAL_STATE, action?: any) => ({ ...state, isLoadingDeleteMedia: false, errorDeleteMedia: false, ...action?.payload });
const deleteMediaFailure = (state = INITIAL_STATE) => ({ ...state, isLoadingDeleteMedia: false, errorDeleteMedia: true });

/**
 *      CLEAR
 */

const clearLO = () => INITIAL_STATE;

export default createReducer(INITIAL_STATE, {
    [Types.CREATE_LO_VIDEO_REQUEST]: createLOVideoRequest,
    [Types.CREATE_LO_VIDEO_SUCCESS]: createLOVideoSuccess,
    [Types.CREATE_LO_VIDEO_FAILURE]: createLOVideoFailure,

    [Types.CREATE_LO_EXERCISE_LIST_REQUEST]: createLOExerciseListRequest,
    [Types.CREATE_LO_EXERCISE_LIST_SUCCESS]: createLOExerciseListSuccess,
    [Types.CREATE_LO_EXERCISE_LIST_FAILURE]: createLOExerciseListFailure,

    [Types.CREATE_LO_MATERIAL_REQUEST]: createLOMaterialRequest,
    [Types.CREATE_LO_MATERIAL_SUCCESS]: createLOMaterialSuccess,
    [Types.CREATE_LO_MATERIAL_FAILURE]: createLOMaterialFailure,

    [Types.DELETE_LO_REQUEST]: deleteLORequest,
    [Types.DELETE_LO_FAILURE]: deleteLOFailure,

    [Types.CREATE_LO_SEARCH_REQUEST]: createLOSearchRequest,
    [Types.CREATE_LO_SEARCH_SUCCESS]: createLOSearchSuccess,
    [Types.CREATE_LO_SEARCH_FAILURE]: createLOSearchFailure,

    [Types.CREATE_LO_TEACHER_OR_STUDENT_REQUEST]: createLOTeacherOrStudentRequest,
    [Types.CREATE_LO_TEACHER_OR_STUDENT_SUCCESS]: createLOTeacherOrStudentSuccess,
    [Types.CREATE_LO_TEACHER_OR_STUDENT_FAILURE]: createLOTeacherOrStudentFailure,

    [Types.UPLOAD_FILE_REQUEST]: uploadFile,
    [Types.UPLOAD_FILE_SUCCESS]: uploadFileSuccess,
    [Types.UPLOAD_FILE_FAILURE]: uploadFileFailure,
    [Types.CLEAR_UPLOAD_FILE]: uploadFileSuccess,

    [Types.DELETE_MEDIA_REQUEST]: deleteMediaRequest,
    [Types.DELETE_MEDIA_SUCCESS]: deleteMediaSuccess,
    [Types.DELETE_MEDIA_FAILURE]: deleteMediaFailure,

    [Types.CLEAR_LO]: clearLO
});
