import { createActions, createReducer } from 'reduxsauce';
import { ProjectActionsType, IProjectActionsCreators, IProjectState } from 'store/types/IProject';
import { IReduxAction } from 'store/types/IRedux';

export const { Creators, Types } = createActions<Record<ProjectActionsType, ProjectActionsType>, IProjectActionsCreators>({
    getProjectCategoriesRequest: ['payload'],
    getProjectCategoriesSuccess: ['payload'],
    getProjectCategoriesFailure: ['payload'],

    getProjectRequest: ['payload'],
    getProjectSuccess: ['payload'],
    getProjectFailure: ['payload'],

    createProjectExhibitionPlacesRequest: ['payload'],
    createProjectExhibitionPlacesSuccess: [''],
    createProjectExhibitionPlacesFailure: [''],

    createOrEditProjectRequest: ['payload'],
    createOrEditProjectSuccess: ['payload'],
    createOrEditProjectFailure: ['payload'],

    reorderResourceRequest: ['payload'],
    reorderResourceSuccess: ['payload'],
    reorderResourceFailure: ['payload'],

    editResourceRequest: ['payload'],
    editResourceSuccess: ['payload'],

    deleteResourceRequest: ['payload'],

    addTag: ['payload'],
    deleteTag: ['payload'],

    addPromoVideo: ['payload'],
    deletePromoVideo: [],

    projectFailure: ['payload'],

    createOrEditResumeRequest: ['payload'],
    createOrEditResumeFailure: [],

    createOrEditModuleRequest: ['payload'],
    createOrEditModuleFailure: [],

    createOrEditSectionRequest: ['payload'],
    createOrEditSectionFailure: [],

    createOrEditContentRequest: ['payload'],
    createOrEditContentFailure: [],

    clearProject: []
});

const INITIAL_STATE: IProjectState = {
    relations: {
        categories: []
    },
    isLoading: false,
    project: {
        id: 0,
        isPublished: false,
        urlImage: '',
        video: null,
        modules: [],
        name: '',
        projectCategory: {
            id: 0,
            title: ''
        },
        resumes: [],
        tags: []
    },
    isLoadingExhibitionPlaces: false
};

const projectRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true });
const projectFailure = (state = INITIAL_STATE) => ({ ...state, isLoading: false });

const getProjectCategoriesRequest = (state = INITIAL_STATE) => ({ ...state });

const getProjectCategoriesSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    relations: {
        categories: action.payload
    }
});

const projectSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    project: action.payload,
    isLoading: false
});

const createOrEditProjectSuccess = (state = INITIAL_STATE, action: any) => ({ ...state, isLoading: false });

const addTag = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        project: {
            ...state.project,
            tags: [...state.project.tags, action.payload]
        }
    };
};

const deleteTag = (state = INITIAL_STATE, action: any) => {
    const tags = state.project.tags.map((tag) => ({
        ...tag,
        ...(tag.name === action.payload && { deleted: new Date().toISOString() })
    }));

    const hasMainTag = tags.some((tag) => tag.isMain);

    if (!hasMainTag && tags.length > 0) {
        tags[0].isMain = 1;
    }

    return {
        ...state,
        project: {
            ...state.project,
            tags
        }
    };
};

const addPromoVideo = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        project: {
            ...state.project,
            video: action.payload
        }
    };
};

const deletePromoVideo = (state = INITIAL_STATE) => {
    return {
        ...state,
        project: {
            ...state.project,
            video: null
        }
    };
};

const editResource = (state = INITIAL_STATE, action: any) => {
    const { idContent, material, idModule, idSection } = action.payload;

    const updateModules = state.project.modules.map((module) => {
        if (module.id === idModule) {
            const updateSections = module.sections.map((section) => {
                if (section.id === idSection) {
                    return {
                        ...section,
                        contents: section.contents!.map((content) => (content.id === idContent ? { ...content, material } : content))
                    };
                }

                return section;
            });

            return {
                ...module,
                sections: updateSections
            };
        }

        return module;
    });

    return {
        ...state,
        project: {
            ...state.project,
            modules: updateModules
        }
    };
};

const reorderResource = (state = INITIAL_STATE) => ({ ...state, isLoading: false });

const startExhibitionPlacesLoading = (state = INITIAL_STATE, _: IReduxAction<{ brandId: number; courses?: number[]; projectId: number }>) => ({
    ...state,
    isLoadingExhibitionPlaces: true
});

const stopExhibitionPlacesLoading = (state = INITIAL_STATE) => ({
    ...state,
    isLoadingExhibitionPlaces: false
});

// Clear project
const clearProject = (state = INITIAL_STATE) => ({ ...state, ...INITIAL_STATE });

export default createReducer<IProjectState>(INITIAL_STATE, {
    // getInitialData
    [Types.GET_PROJECT_CATEGORIES_REQUEST]: getProjectCategoriesRequest,
    [Types.GET_PROJECT_CATEGORIES_SUCCESS]: getProjectCategoriesSuccess,
    [Types.GET_PROJECT_CATEGORIES_FAILURE]: projectFailure,

    // getProject
    [Types.GET_PROJECT_REQUEST]: projectRequest,
    [Types.GET_PROJECT_SUCCESS]: projectSuccess,
    [Types.GET_PROJECT_FAILURE]: projectFailure,

    // createOrEditProject
    [Types.CREATE_OR_EDIT_PROJECT_REQUEST]: projectRequest,
    [Types.CREATE_OR_EDIT_PROJECT_SUCCESS]: createOrEditProjectSuccess,
    [Types.CREATE_OR_EDIT_PROJECT_FAILURE]: projectFailure,

    // editResource
    [Types.EDIT_RESOURCE_REQUEST]: projectRequest,
    [Types.EDIT_RESOURCE_SUCCESS]: editResource,
    [Types.DELETE_RESOURCE_REQUEST]: projectRequest,

    // reorderResource
    [Types.REORDER_RESOURCE_REQUEST]: projectRequest,
    [Types.REORDER_RESOURCE_SUCCESS]: reorderResource,
    [Types.REORDER_RESOURCE_FAILURE]: projectRequest,

    // manage tags
    [Types.ADD_TAG]: addTag,
    [Types.DELETE_TAG]: deleteTag,

    // manage promo video
    [Types.ADD_PROMO_VIDEO]: addPromoVideo,
    [Types.DELETE_PROMO_VIDEO]: deletePromoVideo,

    // Project failure
    [Types.PROJECT_FAILURE]: projectFailure,

    // Reset project
    [Types.CLEAR_PROJECT]: clearProject,

    // createOrEditResume
    [Types.CREATE_OR_EDIT_RESUME_REQUEST]: projectRequest,
    [Types.CREATE_OR_EDIT_RESUME_FAILURE]: projectFailure,

    // createOrEditModule
    [Types.CREATE_OR_EDIT_MODULE_REQUEST]: projectRequest,
    [Types.CREATE_OR_EDIT_MODULE_FAILURE]: projectFailure,

    // createOrEditModule
    [Types.CREATE_OR_EDIT_SECTION_REQUEST]: projectRequest,
    [Types.CREATE_OR_EDIT_SECTION_FAILURE]: projectFailure,

    // createOrEditModule
    [Types.CREATE_OR_EDIT_CONTENT_REQUEST]: projectRequest,
    [Types.CREATE_OR_EDIT_CONTENT_FAILURE]: projectFailure,

    [Types.CREATE_PROJECT_EXHIBITION_PLACES_REQUEST]: startExhibitionPlacesLoading,
    [Types.CREATE_PROJECT_EXHIBITION_PLACES_SUCCESS]: stopExhibitionPlacesLoading,
    [Types.CREATE_PROJECT_EXHIBITION_PLACES_FAILURE]: stopExhibitionPlacesLoading
});
