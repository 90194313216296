// import { TableColumnType } from "../utils/enums/TableColumnType";

import _get from 'lodash/get';
import _set from 'lodash/set';
import { TableItemType } from '../enums/tableItemType';
import { DateTime } from 'luxon';
import { isValidDate } from 'utils/isValidDate';

export default function convertToGraphQLSearch(data?: object) {
    const formatSearch = {};

    if (!data) {
        return;
    }

    Object.keys(data).forEach((key) => {
        const search = _get(data, key);

        if (search?.value === null || search?.value === undefined) {
            return;
        }

        switch (search.type) {
            case TableItemType.Number:
            case TableItemType.Boolean:
            case TableItemType.Email:
                return _set(formatSearch, key, {
                    _eq: search.value
                });

            case TableItemType.Enum:
                return _set(formatSearch, key, {
                    _eq: search.value
                });

            case TableItemType.Person:
                return _set(formatSearch, key, {
                    _or: [
                        {
                            name: {
                                _ilike: `%${search.value}%`
                            }
                        },
                        {
                            user: {
                                email: {
                                    _ilike: `%${search.value}%`
                                }
                            }
                        }
                    ]
                });

            case TableItemType.Calendar:
            case TableItemType.Date:
                if (!isValidDate(search?.value)) {
                    return;
                }

                // eslint-disable-next-line
                const date = DateTime.fromJSDate(search.value).set({ hour: 0, minute: 0, millisecond: 0 }).toJSDate();

                return _set(formatSearch, key, {
                    _gt: DateTime.fromJSDate(date).toFormat('yyyyLLdd'),
                    _lt: DateTime.fromJSDate(date).plus({ day: 1 }).toFormat('yyyyLLdd')
                });

            case TableItemType.Array:
            case TableItemType.Teacher:
            case TableItemType.ArrayText:
                return _set(formatSearch, !!search?.subItem ? `${key}.${search.subItem}` : key, {
                    _ilike: `%${search.value}%`
                });

            case TableItemType.ArrayTextTooltip:
                return _set(formatSearch, !!search?.subItem ? `${key}.${search.subItem}` : key, {
                    _ilike: `%${search.value}%`
                });

            case TableItemType.Clickable:
                return _set(formatSearch, `${key}.${search?.subItem ?? 'name'}`, {
                    _ilike: `%${search.value}%`
                });

            default:
                return _set(formatSearch, key, {
                    _ilike: `%${search.value}%`
                });
        }
    });

    return formatSearch;
}
