import { lazy } from 'react';

const List = lazy(() => import('pages/crud'));

const invoicesRoutes = [
    {
        path: '/app/notas-fiscais',
        name: 'Notas fiscais',
        component: List,
        exact: true,
        title: 'Notas fiscais'
    }
];

export default invoicesRoutes;
