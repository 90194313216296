import { lazy } from 'react';

const List = lazy(() => import('pages/crud'));
const View = lazy(() => import('pages/exam'));
const CreateOrEdit = lazy(() => import('pages/crud/createOrEdit'));

const examRoutes = [
    {
        path: '/app/simulados',
        name: 'Simulados',
        component: List,
        exact: true,
        title: 'Simulados'
    },
    {
        path: '/app/simulados/novo',
        name: 'Novo simulado',
        component: CreateOrEdit,
        exact: true,
        title: 'Novo simulado'
    },
    {
        path: '/app/simulados/editar/:id',
        name: 'Editar',
        component: CreateOrEdit,
        exact: true,
        title: 'Editar simulado'
    },
    {
        path: '/app/simulados/ver/:id',
        name: 'Ver',
        component: View,
        exact: false,
        title: 'Ver simulado'
    }
];

export default examRoutes;
