import { createActions, createReducer } from 'reduxsauce';

export const { Creators, Types } = createActions({
    getAllSchoolPeriodsRequest: ['payload'],
    getAllSchoolPeriodsSuccess: ['payload'],
    getAllSchoolPeriodsFailure: []
});

export interface ISchoolPeriodState {
    isLoading: boolean;
    items: any;
}

const INITIAL_STATE: ISchoolPeriodState = {
    isLoading: false,
    items: []
};

const getAllSchoolPeriodsRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true });
const getAllSchoolPeriodsSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoading: false,
    items: action.payload
});
const getAllSchoolPeriodsFailure = (state = INITIAL_STATE) => ({ ...state, ...INITIAL_STATE });

export default createReducer(INITIAL_STATE, {
    [Types.GET_ALL_SCHOOL_PERIODS_REQUEST]: getAllSchoolPeriodsRequest,
    [Types.GET_ALL_SCHOOL_PERIODS_SUCCESS]: getAllSchoolPeriodsSuccess,
    [Types.GET_ALL_SCHOOL_PERIODS_FAILURE]: getAllSchoolPeriodsFailure
});
