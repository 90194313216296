export enum TableAdvancedSearchType {
    Text = 'text',
    Select = 'select',
    Enum = 'enum',
    Date = 'date',
    Number = 'number',
    AsyncSelect = 'asyncSelect',
    Calendar = 'calendar',
    Email = 'email'
}
