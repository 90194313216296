export const getAnswerCardByIdQuery = `query getAnswerCard($id: Int!) {
    item: prodigio_answer_cards_by_pk(id: $id) {
      id
      realizationMode: realization_mode
      status
      questionsTotal: questions_total
      points: answer_card_questions_aggregate(where: {hit: {_eq: true}, deleted: {_is_null: true}}) {
        aggregate {
          sum {
            points
          }
        }
      }
      exerciseList: exercise_list {
        id
        title
        type
        points: items_aggregate(where: {deleted: {_is_null: true}}) {
          aggregate {
            sum {
              points
            }
          }
        }
        items(where: {deleted: {_is_null: true}}, order_by: {order: asc}) {
          id
          question {
            id
            question_type
          }
        }
      }
      student {
        id
        person {
          name
        }
      }
    }
  }`;
