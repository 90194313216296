import { IReduxStore } from 'utils/interfaces/IReduxStore';

import { store } from '../../store';

export default function learningObjectMiddleware(items: any[]) {
    const state = store.getState() as IReduxStore;
    const brandId = state?.organizations?.currentBrand?.id;
    // const unitId = state?.organizations?.currentUnit?.id ?? 0;
    const companyId = state?.organizations?.currentCompany?.id ?? 0;

    return items.map((item: any) => {
        if (brandId >= 1 && !!item?.learning_object?.company?.brands && !item?.learning_object?.company?.brands?.some(({ id }: any) => id === brandId)) {
            return {
                ...item,
                isReadOnly: true
            };
        }

        if (companyId >= 1 && !!item?.learning_object?.company && item?.learning_object?.company?.id !== companyId) {
            return {
                ...item,
                isReadOnly: true
            };
        }

        return item;
    });
}
