import { IField } from 'utils/interfaces/IField';
import InputTypes from 'utils/enums/inputTypes';

const schoolgrade: IField[] = [
    {
        presentation: 'Nome',
        label: 'Nome',
        name: 'name',
        type: InputTypes.text,
        columns: 3
    },

    {
        presentation: 'Ordem',
        label: 'Ordem',
        name: 'order',
        type: InputTypes.number,
        columns: 1
    }
];

export default schoolgrade;
