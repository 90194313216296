import { lazy } from 'react';

const List = lazy(() => import('pages/crud'));
const Menu = lazy(() => import('pages/menus'));

const menusRoutes = [
    {
        path: '/app/menus',
        name: 'Menus',
        component: List,
        exact: true,
        title: 'Menus'
    },
    {
        path: '/app/menus/novo',
        name: 'Novo Menu',
        component: Menu,
        exact: true,
        title: 'Novo Menu'
    },
    {
        path: '/app/menus/editar/:id',
        name: 'Editar',
        component: Menu,
        exact: true,
        title: 'Editar Menu'
    }
];

export default menusRoutes;
