import React from 'react';

// Components
import Auth from './auth';

// Redux
import { connect } from 'react-redux';

// Helpers
import { IReduxStore } from 'utils/interfaces/IReduxStore';

interface IProps {
    hasPermission: boolean;
    token: string;
}

const AuthContainer = (props: IProps) => <Auth {...props} />;

const mapStateToProps = ({ auth, organizations }: IReduxStore) => ({
    hasPermission: !!organizations.currentCompany || organizations.currentBrand.id > 0 || !!organizations.currentUnit || auth.profiles.some((item) => item.type === 1),
    token: auth.token
});

export default connect(mapStateToProps)(AuthContainer);
