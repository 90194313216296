import { store } from '../../store';
import { IReduxStore } from '../interfaces/IReduxStore';
import _set from 'lodash/set';
import { PROENEM_BRAND_ID, PRODIGIO_COMPANY_ID } from 'utils/prodigioFiltersIds';

export default function addCompanyOrBrandVariables(isLearningObject: boolean, brandRelationName?: string, companyRelationName?: string, unitRelationName?: string, vars?: object) {
    if (!brandRelationName && !companyRelationName) {
        return vars;
    }

    const state = store.getState() as IReduxStore;
    const brandId = state?.organizations?.currentBrand?.id;
    const unitId = state?.organizations?.currentUnit?.id ?? 0;
    const companyId = state?.organizations?.currentCompany?.id ?? 0;

    const tmpVars = { ...vars };

    const returnFilterObj = (value: number, relationName?: string, defaultValue?: number) => {
        if (relationName === 'id') {
            return {
                _eq: value
            };
        }

        return {
            ...(!!defaultValue
                ? {
                      _or: [
                          {
                              id: {
                                  _eq: value
                              }
                          },
                          {
                              id: {
                                  _eq: defaultValue // PROENEM or PRODIGIO ID
                              }
                          }
                      ]
                  }
                : {
                      id: {
                          _eq: value
                      }
                  }),

            deleted: {
                _is_null: true
            }
        };
    };

    switch (true) {
        case unitId >= 1 && !!unitRelationName:
            _set(tmpVars, `${unitRelationName}`, returnFilterObj(unitId, unitRelationName));
            break;
        case brandId >= 1 && !!brandRelationName:
            _set(tmpVars, `${brandRelationName}`, returnFilterObj(brandId, brandRelationName, isLearningObject ? PROENEM_BRAND_ID : undefined));
            break;
        case companyId >= 1 && !!companyRelationName:
            _set(tmpVars, `${companyRelationName}`, returnFilterObj(companyId, companyRelationName, isLearningObject ? PRODIGIO_COMPANY_ID : undefined));
            break;
        default:
            break;
    }

    return tmpVars;
}
