import { Types as ProductPhysicalTypes, Creators as examFilesActions } from 'store/ducks/examFiles';
import { takeLatest, put, select, all, takeEvery, delay } from 'redux-saga/effects';
import { IReduxStore } from 'utils/interfaces/IReduxStore';
import { IReduxAction } from 'store/types/IRedux';
import { IImportFile } from 'utils/interfaces/ImportFile';
import api from 'services/api';
import axios from 'axios';
import { store } from 'store';
import { ImportFileStatus } from 'utils/enums/ImportFileStatus';
import graphQLWebSocket from 'services/graphQLWebSocket';
import filterSubscriberReponseData from 'utils/filterSubscriberReponseData';
import _get from 'lodash/get';
import { BackgroundWorkStatus } from 'utils/enums/backgroundWorkStatus';
import { BackgroundWorkType } from 'utils/enums/backgroundWorkType';
import { ISelect } from 'utils/interfaces/IReactSelect';
import { showAlertError } from '../utils/showAlertError';
import { Creators as alertActions } from 'store/ducks/alert';

// alertActions

function* getExamId() {
    return yield select((state: IReduxStore) => state?.exam?.id);
}

function* getFileByUuid(uuid?: string) {
    if (!uuid) {
        throw new Error();
    }

    return yield select<(state: IReduxStore) => IImportFile | undefined>(({ examFiles }) => examFiles?.files?.find((file) => file.uuid === uuid));
}

function* getFileBrand() {
    return yield select<(state: IReduxStore) => ISelect | undefined>(({ examFiles }) => examFiles?.brand);
}

// TODO: NEW REDUX INSTANCES CAN BROKE THE CODE
function handleChangeProgress(data: { uuid: string; progress: number; status: ImportFileStatus }) {
    store.dispatch(examFilesActions.setExamFileProgress(data));
}

// TODO: NEW REDUX INSTANCES CAN BROKE THE CODE
function handleSetCancelToken(data: { cancelToken: any; uuid: string }) {
    store.dispatch(examFilesActions.setExamFileCancelToken(data));
}

// TODO: NEW REDUX INSTANCES CAN BROKE THE CODE
function closeSocketConnection(uuid: string) {
    const file = (store.getState() as IReduxStore)?.examFiles?.files.find((file) => file.uuid === uuid);

    if (!file?.socketInstance) {
        throw new Error();
    }

    file.socketInstance.close();
}

// TODO: NEW REDUX INSTANCES CAN BROKE THE CODE
function completeFileProcessing(uuid: string) {
    store.dispatch(examFilesActions.startExamFileProcessingSocketSuccess({ uuid }));
}

// TODO: NEW REDUX INSTANCES CAN BROKE THE CODE
function errorFileProcessing(uuid: string, msg?: string) {
    store.dispatch(examFilesActions.startExamFileProcessingSocketFailure({ uuid, msg }));
}

function* uploadFile(action: IReduxAction<{ uuid: string }>) {
    try {
        const { uuid } = action.payload;

        const currentFile: IImportFile | undefined = yield getFileByUuid(uuid);

        if (!currentFile) {
            throw new Error();
        }

        const brand = yield getFileBrand();

        const axiosInstanceBrand = axios.defaults.headers.common['X-Brand'];

        if (!brand?.value) {
            throw new Error('brand not found');
        }

        const fileUpload = new FormData();

        fileUpload.set('file', currentFile.file);

        const CancelToken = axios.CancelToken;

        const examId = yield getExamId();

        const headers = axiosInstanceBrand === brand?.value ? undefined : { 'x-brand': brand.value };

        const { data } = yield api.post(`/admin/exam/${examId}/import`, fileUpload, {
            ...(!!headers && { headers }),
            onUploadProgress: (progressEvent) => {
                const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total) || 0;

                if (progress % 2 === 0) {
                    handleChangeProgress({ progress, uuid, status: ImportFileStatus.Uploading });
                }
            },
            cancelToken: new CancelToken(function executor(cancelToken) {
                handleSetCancelToken({ cancelToken, uuid });
            })
        });

        yield put(examFilesActions.uploadExamFileSuccess({ uuid }));
        yield put(examFilesActions.startExamFileProcessingSocketRequest({ uuid, backgroundId: data.id }));
    } catch (error) {
        if (error?.message === 'request cancelled') {
            return yield put(examFilesActions.cancelExamFileUploadSuccess(action.payload));
        }

        if (error?.message === 'brand not found') {
            yield put(examFilesActions.uploadExamFileFailure({ ...action.payload, status: ImportFileStatus.Pending }));

            return yield put(alertActions.showAlert('Por favor, selecione uma marca.', 'danger'));
        }

        yield showAlertError(error);
        yield put(examFilesActions.uploadExamFileFailure(action.payload));
    }
}

function* cancelUpload(action: IReduxAction<{ uuid: string }>) {
    try {
        const { uuid } = action.payload;

        const currentFile: IImportFile | undefined = yield getFileByUuid(uuid);

        if (!currentFile?.cancelToken) {
            throw new Error();
        }

        currentFile.cancelToken('request cancelled');

        yield put(examFilesActions.cancelExamFileUploadSuccess({ uuid }));
    } catch (error) {
        console.log(error);
    }
}

function* uploadAllFiles() {
    try {
        const allFiles: IImportFile[] = yield select<(state: IReduxStore) => IImportFile[]>(({ examFiles }) => examFiles?.files?.filter(({ status }) => status === ImportFileStatus.Pending) || []);

        if (!allFiles?.length) {
            return;
        }

        yield all(allFiles.map(({ uuid }) => put(examFilesActions.uploadExamFileRequest({ uuid }))));
    } catch (error) {
        console.log(error);
    }
}

function* startProcessingSocket(action: IReduxAction<{ uuid: string; backgroundId: number }>) {
    try {
        const { uuid, backgroundId } = action.payload;

        const currentFile: IImportFile | undefined = yield getFileByUuid(uuid);

        if (!currentFile) {
            throw new Error();
        }

        const socketInstance = graphQLWebSocket();

        yield put(examFilesActions.setFileSocketInstance({ uuid, socketInstance }));

        const query = `subscription ($where: prodigio_background_works_bool_exp) {
            items: prodigio_background_works(where: $where, order_by: {created: desc}, limit: 1) {
              id
              status
              progress
              details
            }
          }`;

        const variables = {
            where: {
                deleted: {
                    _is_null: true
                },
                entity_id: {
                    _eq: backgroundId
                },
                type: {
                    _eq: BackgroundWorkType.ImportAnswerCardFile
                }
            }
        };

        socketInstance.addEventListener('open', () => {
            socketInstance.send(
                JSON.stringify({
                    type: 'start',
                    id: uuid,
                    payload: {
                        extensions: {},
                        operationName: null,
                        query,
                        variables
                    }
                })
            );
        });

        let stop = false;

        socketInstance.addEventListener('message', (event) => {
            if (stop) {
                socketInstance?.close();
            }

            const response = filterSubscriberReponseData(event?.data, uuid);

            if (response) {
                const { progress = 0, status, details: msg } = _get(response, 'items[0]', {});

                if (status === BackgroundWorkStatus.Done) {
                    stop = true;

                    return completeFileProcessing(uuid);
                }

                if (status === BackgroundWorkStatus.Failed) {
                    stop = true;

                    return errorFileProcessing(uuid, msg);
                }

                handleChangeProgress({ progress, uuid, status: ImportFileStatus.Processing });
            }
        });

        socketInstance.addEventListener('error', () => {
            socketInstance?.close();
        });
    } catch (error) {
        console.log(error);
    }
}

function* closeFileSocketConnection(action: IReduxAction<{ uuid: string }>) {
    try {
        const { uuid } = action.payload;

        if (!uuid) {
            throw new Error();
        }

        yield delay(100);

        closeSocketConnection(uuid);
    } catch (error) {
        console.log(error);
    }
}

export default [
    takeEvery(ProductPhysicalTypes.UPLOAD_EXAM_FILE_REQUEST, uploadFile),
    takeEvery(ProductPhysicalTypes.CANCEL_EXAM_FILE_UPLOAD_REQUEST, cancelUpload),
    takeLatest(ProductPhysicalTypes.UPLOAD_ALL_EXAM_FILES, uploadAllFiles),
    takeLatest(ProductPhysicalTypes.START_EXAM_FILE_PROCESSING_SOCKET_REQUEST, startProcessingSocket),
    takeLatest(ProductPhysicalTypes.CLOSE_FILE_SOCKET_CONNECTION, closeFileSocketConnection)
];
