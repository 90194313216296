import { createActions, createReducer } from 'reduxsauce';
import { ISubject } from 'utils/interfaces/ISubject';

export const { Creators, Types } = createActions({
    getSchoolClassesRequest: ['payload'],
    getSchoolClassesSuccess: ['payload'],
    getSchoolClassesFailure: [],

    getSchoolClassByIdRequest: ['payload'],
    getSchoolClassByIdSuccess: ['payload'],
    getSchoolClassByIdFailure: [],

    createOrEditSchoolClassRequest: ['payload'],
    createOrEditSchoolClassSuccess: ['payload'],
    createOrEditSchoolClassFailure: [],

    getTeacherSchoolClassRequest: ['payload'],
    getTeacherSchoolClassSuccess: ['payload'],
    getTeacherSchoolClassFailure: [],

    createOrEditTeacherSchoolClassRequest: ['payload'],
    createOrEditTeacherSchoolClassSuccess: ['payload'],
    createOrEditTeacherSchoolClassFailure: [],

    createOrEditStudentSchoolClassRequest: ['payload'],
    createOrEditStudentSchoolClassSuccess: ['payload'],
    createOrEditStudentSchoolClassFailure: [],

    deleteTeacherSchoolClassRequest: ['payload'],
    deleteTeacherSchoolClassSuccess: ['payload'],
    deleteTeacherSchoolClassFailure: [],

    deleteStudentSchoolClassRequest: ['payload'],
    deleteStudentSchoolClassSuccess: ['payload'],
    deleteStudentSchoolClassFailure: [],

    clearSchoolClass: []
});

export interface ISchoolClassStudent {
    id: number;
    person: {
        name: string;
    };
}

export interface ITeachersBySubject {
    id: number;
    teacher: {
        id: number;
        image: string;
        person: {
            id: number;
            name: string;
        };
    };
    subject: ISubject;
}

export interface ISchoolClassState {
    isLoading: boolean;
    items: any;
    id: number;
    shiftType: number;
    name: string;
    teachersBySubject: ITeachersBySubject[];
    grade?: any;
    period?: any;
    unit?: any;
    course?: {
        id: number;
        name: string;
    };
    subscriptions?: any[];
}

const INITIAL_STATE: ISchoolClassState = {
    isLoading: false,
    items: [],
    teachersBySubject: [],
    id: 0,
    name: '',
    shiftType: 1
};

const getSchoolClassByIdRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true });
const getSchoolClassByIdSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoading: false,
    ...action.payload
});
const getSchoolClassByIdFailure = (state = INITIAL_STATE) => ({ ...state, ...INITIAL_STATE });

const getSchoolClassesRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true });
const getSchoolClassesSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoading: false,
    items: action.payload
});
const getSchoolClassesFailure = (state = INITIAL_STATE) => ({ ...state, ...INITIAL_STATE });

const createOrEditSchoolClassRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true });
const createOrEditSchoolClassSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoading: false,
    ...action.payload
});
const createOrEditSchoolClassFailure = (state = INITIAL_STATE) => ({ ...state, ...INITIAL_STATE });

const createOrEditTeacherSchoolClassRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true });
const createOrEditTeacherSchoolClassSuccess = (state = INITIAL_STATE, action: any) => {
    const { payload } = action;

    return {
        ...state,
        isLoading: false,
        teachersBySubject: [payload, ...state.teachersBySubject]
    };
};
const createOrEditTeacherSchoolClassFailure = (state = INITIAL_STATE) => ({ ...state, ...INITIAL_STATE });

const deleteTeacherSchoolClassRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true });
const deleteTeacherSchoolClassSuccess = (state = INITIAL_STATE, action: any) => {
    const { id } = action.payload;

    const updateSubjects = state.teachersBySubject.filter((item) => item.id !== id);

    return {
        ...state,
        isLoading: false,
        teachersBySubject: updateSubjects
    };
};
const deleteTeacherSchoolClassFailure = (state = INITIAL_STATE) => ({ ...state, ...INITIAL_STATE });

const createOrEditStudentSchoolClassRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true });
const createOrEditStudentSchoolClassSuccess = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        isLoading: false,
        subscriptions: action.payload
    };
};
const createOrEditStudentSchoolClassFailure = (state = INITIAL_STATE) => ({ ...state, ...INITIAL_STATE });

const deleteStudentSchoolClassRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true });
const deleteStudentSchoolClassSuccess = (state = INITIAL_STATE, action: any) => {
    const { payload } = action;

    const updateStudents = state.subscriptions!.filter((student) => student.id !== payload.id);

    return {
        ...state,
        isLoading: false,
        subscriptions: updateStudents
    };
};
const deleteStudentSchoolClassFailure = (state = INITIAL_STATE) => ({ ...state, ...INITIAL_STATE });

const clearSchoolClass = (state = INITIAL_STATE) => INITIAL_STATE;

const getTeacherSchoolClassRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true });

const getTeacherSchoolClassSuccess = (state = INITIAL_STATE, action: any) => {
    const { payload } = action;

    return { ...state, isLoading: false, teachersBySubject: payload };
};

const getTeacherSchoolClassFailure = (state = INITIAL_STATE) => ({ ...state, isLoading: false });

export default createReducer(INITIAL_STATE, {
    [Types.GET_SCHOOL_CLASS_BY_ID_REQUEST]: getSchoolClassByIdRequest,
    [Types.GET_SCHOOL_CLASS_BY_ID_SUCCESS]: getSchoolClassByIdSuccess,
    [Types.GET_SCHOOL_CLASS_BY_ID_FAILURE]: getSchoolClassByIdFailure,

    [Types.GET_SCHOOL_CLASSES_REQUEST]: getSchoolClassesRequest,
    [Types.GET_SCHOOL_CLASSES_SUCCESS]: getSchoolClassesSuccess,
    [Types.GET_SCHOOL_CLASSES_FAILURE]: getSchoolClassesFailure,

    [Types.CREATE_OR_EDIT_SCHOOL_CLASS_REQUEST]: createOrEditSchoolClassRequest,
    [Types.CREATE_OR_EDIT_SCHOOL_CLASS_SUCCESS]: createOrEditSchoolClassSuccess,
    [Types.CREATE_OR_EDIT_SCHOOL_CLASS_FAILURE]: createOrEditSchoolClassFailure,

    [Types.CREATE_OR_EDIT_TEACHER_SCHOOL_CLASS_REQUEST]: createOrEditTeacherSchoolClassRequest,
    [Types.CREATE_OR_EDIT_TEACHER_SCHOOL_CLASS_SUCCESS]: createOrEditTeacherSchoolClassSuccess,
    [Types.CREATE_OR_EDIT_TEACHER_SCHOOL_CLASS_FAILURE]: createOrEditTeacherSchoolClassFailure,

    [Types.DELETE_TEACHER_SCHOOL_CLASS_REQUEST]: deleteTeacherSchoolClassRequest,
    [Types.DELETE_TEACHER_SCHOOL_CLASS_SUCCESS]: deleteTeacherSchoolClassSuccess,
    [Types.DELETE_TEACHER_SCHOOL_CLASS_FAILURE]: deleteTeacherSchoolClassFailure,

    [Types.CREATE_OR_EDIT_STUDENT_SCHOOL_CLASS_REQUEST]: createOrEditStudentSchoolClassRequest,
    [Types.CREATE_OR_EDIT_STUDENT_SCHOOL_CLASS_SUCCESS]: createOrEditStudentSchoolClassSuccess,
    [Types.CREATE_OR_EDIT_STUDENT_SCHOOL_CLASS_FAILURE]: createOrEditStudentSchoolClassFailure,

    [Types.DELETE_STUDENT_SCHOOL_CLASS_REQUEST]: deleteStudentSchoolClassRequest,
    [Types.DELETE_STUDENT_SCHOOL_CLASS_SUCCESS]: deleteStudentSchoolClassSuccess,
    [Types.DELETE_STUDENT_SCHOOL_CLASS_FAILURE]: deleteStudentSchoolClassFailure,

    [Types.GET_TEACHER_SCHOOL_CLASS_REQUEST]: getTeacherSchoolClassRequest,
    [Types.GET_TEACHER_SCHOOL_CLASS_SUCCESS]: getTeacherSchoolClassSuccess,
    [Types.GET_TEACHER_SCHOOL_CLASS_FAILURE]: getTeacherSchoolClassFailure,

    [Types.CLEAR_SCHOOL_CLASS]: clearSchoolClass
});
