import styled from 'styled-components';
import { LogoContainer } from 'components/logo/styles';
import theme from 'config/theme';
import hexToRgba from 'utils/hexToRgba';
import { CardContainer } from 'components/card/styles';
import { ModalHeader } from 'components/modal/styles';
import Button from 'components/button';

export const BrandSelectorContainer = styled.div`
    align-items: center;
    background: #f2f4f5;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    width: 100%;

    ${CardContainer} {
        max-height: calc(100vh - 50px);
        display: flex;
        flex-direction: column;
        /* overflow: auto; */
    }
`;

export const BrandSelectorHeader = styled(ModalHeader)`
    border-bottom: 1px solid ${hexToRgba(theme.colors.base, 0.2)};
    /* margin-bottom: ${theme.spacing.medium.all}; */
    padding-bottom: ${theme.spacing.large.all};
    text-align: center;

    ${LogoContainer} {
        text-align: center;

        > img {
            max-width: 70%;
        }
    }
`;

export const BrandSelectorLogout = styled(Button)`
    position: absolute;
    top: 15px;
    right: 15px;
    text-transform: uppercase;
    /* color: ${hexToRgba(theme.colors.base, 0.7)}; */

    display: flex;
    align-items: center;

   

    svg {
        margin-left: 9px;
    width: 15px;
    height: 13px;
    }
`;
