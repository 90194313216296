import { createActions, createReducer } from 'reduxsauce';
import { ICompany } from 'utils/interfaces/ICompany';
import { IBrand } from 'utils/interfaces/IBrand';

export const { Creators, Types } = createActions({
    getCompanyByIdRequest: ['payload'],
    getCompanyByIdSuccess: ['payload'],
    getCompanyByIdFailure: [],

    createOrEditCompanyRequest: ['payload'],
    createOrEditCompanySuccess: ['payload'],
    createOrEditCompanyFailure: [],

    createOrEditCompanyUserRequest: ['payload'],
    createOrEditCompanyUserSuccess: ['payload'],
    createOrEditCompanyUserFailure: [],

    getCompaniesRequest: [],
    getCompaniesSuccess: ['payload'],
    getCompaniesFailure: ['payload'],

    setCurrentCompanyRequest: ['payload'],
    setCurrentCompanySuccess: ['payload'],
    setCurrentCompanyFailure: [],

    setCurrentBrandRequest: ['payload'],
    setCurrentBrandSuccess: ['payload'],
    setCurrentBrandFailure: [],

    setCurrentUnitRequest: ['payload'],
    setCurrentUnitSuccess: ['payload'],
    setCurrentUnitFailure: [],

    clearCompanies: [],
    clearCompany: [],

    clearNavigationHeaders: []
});

export interface ICompanyState {
    isLoading: boolean;
    companies: ICompany[];
    currentCompany?: ICompany;
    currentBrand: IBrand;
    currentUnit?: any;
    id: number;
    name: string;
    type: number;
    cnpj: string;
    tradingName: string;
    address: {
        name: string;
        number: any;
        complement: string;
        zip: any;
        city: string;
        state: string;
    };
    managingUsers: any[];
}

const INITIAL_STATE: ICompanyState = {
    currentBrand: {
        id: -1,
        name: '',
        slug: ''
    },
    companies: [],
    isLoading: false,

    id: 0,
    name: '',
    type: 0,
    cnpj: '',
    tradingName: '',
    managingUsers: [],
    address: {
        name: '',
        number: 0,
        complement: '',
        zip: '',
        city: '',
        state: ''
    }
};

const getCompanyByIdRequest = (state = INITIAL_STATE, action: any) => ({ ...state, isLoading: true });
const getCompanyByIdSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoading: false,
    ...action.payload
});

const getCompanyByIdFailure = (state = INITIAL_STATE) => ({ ...state, isLoading: false });

//

const createOrEditCompanyRequest = (state = INITIAL_STATE, action: any) => ({ ...state, isLoading: true });
const createOrEditCompanySuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoading: false,
    ...action.payload
});

const createOrEditCompanyFailure = (state = INITIAL_STATE) => ({ ...state, isLoading: false });

//

const createOrEditCompanyUserRequest = (state = INITIAL_STATE, action: any) => ({ ...state, isLoading: true });
const createOrEditCompanyUserSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoading: false,
    managingUsers: action.payload
});

const createOrEditCompanyUserFailure = (state = INITIAL_STATE) => ({ ...state, isLoading: false });

// Company

const setCurrentCompanyRequest = (state = INITIAL_STATE, action: any) => ({ ...state, isLoading: true });
const setCurrentCompanySuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoading: false,
    currentCompany: action.payload,
    currentBrand: {
        id: -1,
        name: '',
        slug: ''
    },
    currentUnit: undefined
});

const setCurrentCompanyFailure = (state = INITIAL_STATE) => ({ ...state, isLoading: false });

// Unit

const setCurrentUnitRequest = (state = INITIAL_STATE, action: any) => ({ ...state, isLoading: true });
const setCurrentUnitSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoading: false,
    ...action.payload,
    currentCompany: undefined
});

const setCurrentUnitFailure = (state = INITIAL_STATE) => ({ ...state, isLoading: false });

const setCurrentBrandRequest = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoading: true
});

const setCurrentBrandSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    currentBrand: action.payload,
    isLoading: false,
    currentCompany: undefined,
    currentUnit: undefined
});

const setCurrentBrandFailure = (state = INITIAL_STATE) => ({ ...state, ...INITIAL_STATE });

const clearCompany = (state = INITIAL_STATE) => ({
    ...state,
    isLoading: false,
    id: 0,
    name: '',
    type: 0,
    cnpj: '',
    tradingName: '',
    managingUsers: [],
    address: {
        name: '',
        number: 0,
        complement: '',
        zip: '',
        city: '',
        state: ''
    }
});

const getCompaniesRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true });

const getCompaniesSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    companies: [...action.payload],
    isLoading: false
});

const getCompaniesFailure = (state = INITIAL_STATE) => ({ ...state, isLoading: false });

const clearCompanies = (state = INITIAL_STATE) => INITIAL_STATE;

const clearNavigationHeaders = (state = INITIAL_STATE) => ({
    ...state,
    currentBrand: {
        id: -1,
        name: '',
        slug: ''
    },
    currentCompany: undefined,
    currentUnit: undefined
});

export default createReducer(INITIAL_STATE, {
    [Types.GET_COMPANY_BY_ID_REQUEST]: getCompanyByIdRequest,
    [Types.GET_COMPANY_BY_ID_SUCCESS]: getCompanyByIdSuccess,
    [Types.GET_COMPANY_BY_ID_FAILURE]: getCompanyByIdFailure,

    [Types.CREATE_OR_EDIT_COMPANY_REQUEST]: createOrEditCompanyRequest,
    [Types.CREATE_OR_EDIT_COMPANY_SUCCESS]: createOrEditCompanySuccess,
    [Types.CREATE_OR_EDIT_COMPANY_FAILURE]: createOrEditCompanyFailure,

    [Types.CREATE_OR_EDIT_COMPANY_USER_REQUEST]: createOrEditCompanyUserRequest,
    [Types.CREATE_OR_EDIT_COMPANY_USER_SUCCESS]: createOrEditCompanyUserSuccess,
    [Types.CREATE_OR_EDIT_COMPANY_USER_FAILURE]: createOrEditCompanyUserFailure,

    [Types.GET_COMPANIES_REQUEST]: getCompaniesRequest,
    [Types.GET_COMPANIES_SUCCESS]: getCompaniesSuccess,
    [Types.GET_COMPANIES_FAILURE]: getCompaniesFailure,

    [Types.SET_CURRENT_COMPANY_REQUEST]: setCurrentCompanyRequest,
    [Types.SET_CURRENT_COMPANY_SUCCESS]: setCurrentCompanySuccess,
    [Types.SET_CURRENT_COMPANY_FAILURE]: setCurrentCompanyFailure,

    [Types.SET_CURRENT_BRAND_REQUEST]: setCurrentBrandRequest,
    [Types.SET_CURRENT_BRAND_SUCCESS]: setCurrentBrandSuccess,
    [Types.SET_CURRENT_BRAND_FAILURE]: setCurrentBrandFailure,

    [Types.SET_CURRENT_UNIT_REQUEST]: setCurrentUnitRequest,
    [Types.SET_CURRENT_UNIT_SUCCESS]: setCurrentUnitSuccess,
    [Types.SET_CURRENT_UNIT_FAILURE]: setCurrentUnitFailure,

    [Types.CLEAR_NAVIGATION_HEADERS]: clearNavigationHeaders,

    [Types.CLEAR_COMPANIES]: clearCompanies,
    [Types.CLEAR_COMPANY]: clearCompany
});
