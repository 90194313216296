import React, { useEffect, useState, useCallback } from 'react';
import Button from 'components/button';
import Icon from 'components/icons/icon';
import Tooltip from 'components/tooltip';
import { PaginationContainer } from './styles';

export interface IProps {
    current: number;
    total: number;
    onPageChange: (page: number) => void;
}

const Pagination = ({ current = 1, total = 1, onPageChange }: IProps) => {
    const [page, setPage] = useState(current);

    useEffect(() => {
        setPage(current);
    }, [current]);

    const goToNextPage = useCallback(() => {
        const nextPage = current + 1;

        if (nextPage > total) {
            return;
        }

        onPageChange(nextPage);
    }, [current, onPageChange, total]);

    const goToPreviousPage = useCallback(() => {
        const previousPage = current - 1;

        if (previousPage <= 0) {
            return;
        }

        onPageChange(previousPage);
    }, [current, onPageChange]);

    return (
        <PaginationContainer id="PaginationContainer">
            <Button variant="outline-secondary" size="small" onClick={goToPreviousPage} disabled={current <= 1}>
                <Icon idIcon="ico-arrow-left" width="16" height="10" viewBox="0 0 22 14" />
                <Tooltip content="Anterior" />
            </Button>
            <div>
                <span>
                    Página{' '}
                    <input
                        type="number"
                        value={page.toString()}
                        max={total}
                        min={1}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPage(Number(e.target.value))}
                        style={{ width: '100px' }}
                        onBlur={(e: React.ChangeEvent<HTMLInputElement>) => onPageChange(Number(e?.target?.value || 1))}
                    />{' '}
                    de <strong>{total}</strong>
                </span>
            </div>
            <Button variant="outline-secondary" size="small" onClick={goToNextPage} disabled={current >= total}>
                <Icon idIcon="ico-arrow-right" width="15" height="10" viewBox="0 0 22 14" />
                <Tooltip content="Próxima" />
            </Button>
        </PaginationContainer>
    );
};

export default Pagination;
