import { all, put } from 'redux-saga/effects';
import { apiRequest } from '../apiRequest';
import { Creators as alertActions } from 'store/ducks/alert';
import { Creators as dashboardActions } from 'store/ducks/dashboard';

export function* getDashboardContent(action: any) {
    try {
        const requests = [
            apiRequest('GET', '/schoolclass', null, { 'X-Relations': 'unit;period;grade;course;teachersBySubject;teachersBySubject.subject' }),
            apiRequest('GET', '/admin/exerciselist', null, { 'X-Relations': 'subject' }),
            apiRequest('GET', '/schoolperiod'),
            apiRequest('GET', '/answercard?pendingCorrection=true', null, {
                'X-Relations': 'exerciseList'
            })
        ];

        const responses = yield all(requests);
        const [classes, exerciseLists, schoolPeriods, answerCards] = responses;

        const payload = {
            answerCards: answerCards.data.items,
            classes: classes.data.items,
            exerciseLists: exerciseLists.data.items,
            schoolPeriods: schoolPeriods.data.items
        };

        yield put(dashboardActions.getDashboardContentSuccess(payload));
    } catch (error) {
        yield put(dashboardActions.getDashboardContentFailure({ code: error?.response?.status }));
        yield put(alertActions.showAlert('Ocorreu um erro ao carregar o conteúdo', 'danger'));
    }
}
