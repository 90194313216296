import List from 'pages/crud';

import { lazy } from 'react';

const CreateOrEdit = lazy(() => import('pages/crud/createOrEdit'));
const Announcement = lazy(() => import('pages/announcement'));

const announcementsRoutes = [
    {
        path: '/app/comunicados',
        name: 'Comunicados',
        component: List,
        exact: true,
        title: 'Comunicados'
    },
    {
        path: '/app/comunicados/novo',
        name: 'Novo comunicado',
        component: CreateOrEdit,
        exact: true,
        title: 'Novo comunicado'
    },
    {
        path: '/app/comunicados/editar/:id',
        name: 'Editar',
        component: CreateOrEdit,
        exact: true,
        title: 'Editar comunicado'
    },
    {
        path: '/app/comunicados/ver/:id',
        name: 'Ver',
        component: Announcement,
        exact: true,
        title: 'Ver comunicado'
    }
];

export default announcementsRoutes;
