import _set from 'lodash/set';
import _sortBy from 'lodash/sortBy';

interface IKnowledgeAreaResponse {
    id: number;
    order: number;
}

export interface IExerciseListItem {
    id: number;
    id_knowledge_area: number;
}

export function formatAnswerCardResponse(data: object) {
    const response: any = { ...data };

    const maxExerciseListPoints = response?.exerciseList?.points?.aggregate?.sum?.points || 0;
    const answerCardPoints = response?.points?.aggregate?.sum?.points || 0;

    _set(response, 'exerciseList.points', maxExerciseListPoints);
    _set(response, 'points', answerCardPoints);

    const knowledge_areas: IKnowledgeAreaResponse[] = _sortBy(response?.exerciseList?.exam_day?.knowledge_areas || [], ['order']) || [];

    if (!!knowledge_areas?.length) {
        _set(response, 'exerciseList.knowledge_areas', knowledge_areas);
        _set(response, 'exerciseList.exam_day', undefined);

        let questionsItems: any[] = [];

        knowledge_areas.forEach((knowledge_area) => {
            const items = (response?.exerciseList?.items as IExerciseListItem[])?.filter((item) => item.id_knowledge_area === knowledge_area.id);

            questionsItems = [...questionsItems, ...items];
        });

        _set(
            response,
            'exerciseList.items',
            questionsItems.map((item, index) => ({ ...item, number: index + 1 }))
        );
    }

    return response;
}
