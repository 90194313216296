import { lazy } from 'react';

const List = lazy(() => import('pages/crud'));
const Student = lazy(() => import('pages/student'));
const Details = lazy(() => import('pages/studentsv2'));

const courseRoutes = [
    {
        path: '/app/alunos',
        name: 'Alunos',
        component: List,
        exact: true,
        title: 'Alunos'
    },
    {
        path: '/app/alunos/novo',
        name: 'Novo aluno',
        component: Student,
        exact: true,
        title: 'Novo aluno'
    },
    {
        path: '/app/alunos/editar/:id',
        name: 'Editar',
        component: Student,
        exact: true,
        title: 'Editar aluno'
    },
    {
        path: '/app/alunos/ver/:id',
        name: 'Detalhes',
        component: Details,
        exact: false,
        title: 'Detalhes do aluno'
    }
];

export default courseRoutes;
