import { AxiosResponse } from 'axios';
import { all, put, takeLatest } from 'redux-saga/effects';
import { Creators as alertActions } from 'store/ducks/alert';
import { Creators as answerPostsActions, Types as answerPostsTypes } from 'store/ducks/answerQuestions';
import {
    IAddAnswerQuestionsPostAnswerAttachmentPayload,
    IAnswerQuestionsHasNewPostsPayload,
    IAnswerQuestionsHasUpdatedPostsPayload,
    IAnswerQuestionsPostsPayload,
    IAnswerQuestionsTopicPayload,
    IAnswerQuestionsUpdatedPostsPayload,
    ICreateAnswerQuestionsPostReplyPayload,
    IDeleteAnswerQuestionsPostAnswerAttachmentPayload,
    IDeleteAnswerQuestionsPostPayload,
    IDeleteAnswerQuestionsPostReplyPayload,
    IEditAnswerQuestionsPostReplyPayload
} from 'store/ducks/answerQuestions/types';
import { apiRequest } from '../apiRequest';

interface IAction<T> {
    payload: T;
    type: string;
}

function* getAnswerQuestionsTopicRequest({ payload }: IAction<IAnswerQuestionsTopicPayload>) {
    try {
        const { topicId } = payload;

        const { data } = yield apiRequest('GET', `/admin/forum/topic/${topicId}`);

        yield put(answerPostsActions.getAnswerQuestionsTopicSuccess({ response: data, variables: {} }));
    } catch (error) {
        yield put(answerPostsActions.getAnswerQuestionsTopicFailure(error?.response?.data));
        yield put(alertActions.showAlert(error?.response?.data?.detail?.message || 'Ocorreu um erro. Tente novamente mais tarde.', 'danger'));
    }
}

function* getAnswerQuestionsPostsRequest({ payload }: IAction<IAnswerQuestionsPostsPayload>) {
    try {
        const { topicId, quantity, withoutAnswers, bookmark, isRestarting } = payload;

        const fixedUrl = `/admin/forum/topic/${topicId}/posts?quantity=${quantity}&withoutanswer=${withoutAnswers}`;
        const bookmarkUrl = `&bookmark=${bookmark}`;
        const url = !!bookmark && !isRestarting ? fixedUrl + bookmarkUrl : fixedUrl;

        const { data } = yield apiRequest('GET', url);

        const { data: serverDate } = yield apiRequest('GET', '/util/unixtime');

        yield put(answerPostsActions.getAnswerQuestionsPostsSuccess({ response: data, variables: { withoutAnswers, serverDate, isRestarting } }));
    } catch (error) {
        yield put(answerPostsActions.getAnswerQuestionsPostsFailure(error?.response?.data));
        yield put(alertActions.showAlert(error?.response?.data?.detail?.message || 'Ocorreu um erro. Tente novamente mais tarde.', 'danger'));
    }
}

export function* getAnswerQuestionsHasNewPostsRequest({ payload }: IAction<IAnswerQuestionsHasNewPostsPayload>) {
    try {
        const { topicId, quantity, withoutAnswers } = payload;

        const url = `/admin/forum/topic/${topicId}/posts?quantity=${quantity}&withoutanswer=${withoutAnswers}`;

        const { data } = yield apiRequest('GET', url);

        yield put(answerPostsActions.getAnswerQuestionsHasNewPostsSuccess({ response: data, variables: {} }));
    } catch (error) {
        yield put(answerPostsActions.getAnswerQuestionsHasNewPostsFailure(error?.response?.data));
    }
}

export function* getAnswerQuestionsHasUpdatedPostsRequest({ payload }: IAction<IAnswerQuestionsHasUpdatedPostsPayload>) {
    try {
        const { topicId, posts } = payload;

        const { data } = yield apiRequest('POST', `/admin/forum/topic/${topicId}/posts/updates`, { Posts: posts });

        yield put(answerPostsActions.getAnswerQuestionsHasUpdatedPostsSuccess({ response: data, variables: {} }));
    } catch (error) {
        yield put(answerPostsActions.getAnswerQuestionsHasUpdatedPostsFailure(error?.response?.data));
    }
}

export function* getAnswerQuestionsUpdatedPostsRequest({ payload }: IAction<IAnswerQuestionsUpdatedPostsPayload>) {
    try {
        const { posts } = payload;

        const updatedPosts: Array<AxiosResponse> = yield all(posts.map((post) => apiRequest('GET', `/admin/forum/topic/post/${post}`)));

        const formattedUpdatedPosts = updatedPosts.map((post) => post.data);

        const { data: serverDate } = yield apiRequest('GET', '/util/unixtime');

        yield put(answerPostsActions.getAnswerQuestionsUpdatedPostsSuccess({ response: formattedUpdatedPosts, variables: { serverDate } }));
    } catch (error) {
        yield put(answerPostsActions.getAnswerQuestionsUpdatedPostsFailure(error?.response?.data));
    }
}

function* createAnswerQuestionsPostReplyRequest({ payload }: IAction<ICreateAnswerQuestionsPostReplyPayload>) {
    try {
        const { postId, content } = payload;

        const { data } = yield apiRequest('POST', '/admin/forum/topic/post', { idPost: postId, content });

        yield put(answerPostsActions.createAnswerQuestionsPostReplySuccess({ response: data, variables: { postId } }));
        yield put(alertActions.showAlert('Resposta criada com sucesso.', 'success'));
    } catch (error) {
        yield put(answerPostsActions.createAnswerQuestionsPostReplyFailure(error?.response?.data));
        yield put(alertActions.showAlert(error?.response?.data?.detail?.message || 'Ocorreu um erro. Tente novamente mais tarde.', 'danger'));
    }
}

function* editAnswerQuestionsPostReplyRequest({ payload }: IAction<IEditAnswerQuestionsPostReplyPayload>) {
    try {
        const { replyId, content } = payload;

        yield apiRequest('PATCH', `/admin/forum/topic/post/${replyId}`, { content });

        yield put(answerPostsActions.editAnswerQuestionsPostReplySuccess({ response: {}, variables: payload }));
        yield put(alertActions.showAlert('Resposta atualizada com sucesso.', 'success'));
    } catch (error) {
        yield put(answerPostsActions.editAnswerQuestionsPostReplyFailure(error?.response?.data));
        yield put(alertActions.showAlert(error?.response?.data?.detail?.message || 'Ocorreu um erro. Tente novamente mais tarde.', 'danger'));
    }
}

function* deleteAnswerQuestionsPostReplyRequest({ payload }: IAction<IDeleteAnswerQuestionsPostReplyPayload>) {
    try {
        const { replyId } = payload;

        yield apiRequest('DELETE', `/admin/forum/topic/post/${replyId}`);

        yield put(answerPostsActions.deleteAnswerQuestionsPostReplySuccess({ response: {}, variables: payload }));
        yield put(alertActions.showAlert('Resposta removida com sucesso.', 'success'));
    } catch (error) {
        yield put(answerPostsActions.deleteAnswerQuestionsPostReplyFailure(error?.response?.data));
        yield put(alertActions.showAlert(error?.response?.data?.detail?.message || 'Não foi possível deletar a resposta.', 'danger'));
    }
}

function* deleteAnswerQuestionsPostRequest({ payload }: IAction<IDeleteAnswerQuestionsPostPayload>) {
    try {
        const { postId } = payload;

        yield apiRequest('DELETE', `/admin/forum/topic/post/${postId}`);

        yield put(answerPostsActions.deleteAnswerQuestionsPostSuccess({ response: {}, variables: payload }));
        yield put(alertActions.showAlert('Pergunta removida com sucesso.', 'success'));
    } catch (error) {
        yield put(answerPostsActions.deleteAnswerQuestionsPostFailure(error?.response?.data));
        yield put(alertActions.showAlert(error?.response?.data?.detail?.message || 'Não foi possível deletar a resposta.', 'danger'));
    }
}

function* addAnswerQuestionsPostAnswerAttachment({ payload }: IAction<IAddAnswerQuestionsPostAnswerAttachmentPayload>) {
    try {
        const { postId, topicId, answerId, file, fileName } = payload;

        const formData = new FormData();

        formData.append('file', file, fileName);

        const { data } = yield apiRequest('POST', `/admin/forum/topic/${topicId}/posts/${answerId}/attachment`, formData);

        yield put(answerPostsActions.addAnswerQuestionsPostAnswerAttachmentSuccess({ response: data.data, variables: { postId, answerId } }));
        yield put(alertActions.showAlert('Anexo adicionado com sucesso.', 'success'));
    } catch (error) {
        yield put(answerPostsActions.addAnswerQuestionsPostAnswerAttachmentFailure(error?.response?.data));
        yield put(alertActions.showAlert(error?.response?.data?.detail?.message || 'Não foi possível salvar o anexo.', 'danger'));
    }
}

function* deleteAnswerQuestionsPostAnswerAttachment({ payload }: IAction<IDeleteAnswerQuestionsPostAnswerAttachmentPayload>) {
    try {
        const { postId, topicId, answerId } = payload;

        yield apiRequest('DELETE', `/admin/forum/topic/${topicId}/posts/${answerId}/attachment`);

        yield put(answerPostsActions.deleteAnswerQuestionsPostAnswerAttachmentSuccess({ response: {}, variables: { postId, answerId } }));
        yield put(alertActions.showAlert('Anexo removido com sucesso.', 'success'));
    } catch (error) {
        yield put(answerPostsActions.deleteAnswerQuestionsPostAnswerAttachmentFailure(error?.response?.data));
        yield put(alertActions.showAlert(error?.response?.data?.detail?.message || 'Não foi possível remover o anexo.', 'danger'));
    }
}

export default [
    takeLatest(answerPostsTypes.GET_ANSWER_QUESTIONS_TOPIC_REQUEST, getAnswerQuestionsTopicRequest),
    takeLatest(answerPostsTypes.GET_ANSWER_QUESTIONS_POSTS_REQUEST, getAnswerQuestionsPostsRequest),
    takeLatest(answerPostsTypes.GET_ANSWER_QUESTIONS_HAS_NEW_POSTS_REQUEST, getAnswerQuestionsHasNewPostsRequest),
    takeLatest(answerPostsTypes.GET_ANSWER_QUESTIONS_HAS_UPDATED_POSTS_REQUEST, getAnswerQuestionsHasUpdatedPostsRequest),
    takeLatest(answerPostsTypes.GET_ANSWER_QUESTIONS_UPDATED_POSTS_REQUEST, getAnswerQuestionsUpdatedPostsRequest),
    takeLatest(answerPostsTypes.CREATE_ANSWER_QUESTIONS_POST_REPLY_REQUEST, createAnswerQuestionsPostReplyRequest),
    takeLatest(answerPostsTypes.EDIT_ANSWER_QUESTIONS_POST_REPLY_REQUEST, editAnswerQuestionsPostReplyRequest),
    takeLatest(answerPostsTypes.DELETE_ANSWER_QUESTIONS_POST_REPLY_REQUEST, deleteAnswerQuestionsPostReplyRequest),
    takeLatest(answerPostsTypes.DELETE_ANSWER_QUESTIONS_POST_REQUEST, deleteAnswerQuestionsPostRequest),
    takeLatest(answerPostsTypes.ADD_ANSWER_QUESTIONS_POST_ANSWER_ATTACHMENT_REQUEST, addAnswerQuestionsPostAnswerAttachment),
    takeLatest(answerPostsTypes.DELETE_ANSWER_QUESTIONS_POST_ANSWER_ATTACHMENT_REQUEST, deleteAnswerQuestionsPostAnswerAttachment)
];
