/**
 * @description Helper para manipulação de cores
 *
 * @param {string} hex Hexadecimal que sofrerá alteração
 * @param {number} percentage Porcentagem usada para manipular o hexadecimal. Valores positivos claream a cor, enquanto valores negativos à escurecem. Ex: 20 (clareia o hexadecimal em 20%), -20 (escurece o hexadecimal em 20%)
 * @returns {string} Retorna uma string com o hexadecimal alterado
 */
const lightenDarkenColor = (col: string, percent: number) => {
    const color = col.split('#')[1];

    const num = parseInt(color, 16);
    const amt = Math.round(2.55 * percent);

    const R = (num >> 16) + amt;
    const B = ((num >> 8) & 0x00ff) + amt;
    const G = (num & 0x0000ff) + amt;

    return `#${(0x1000000 + (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 + (B < 255 ? (B < 1 ? 0 : B) : 255) * 0x100 + (G < 255 ? (G < 1 ? 0 : G) : 255)).toString(16).slice(1)}`;
};

export default lightenDarkenColor;
