import { apiRequest } from '../apiRequest';

export function* uploadFile(action: any, onChangeUploadProgress?: (percentage: number) => void) {
    try {
        const fileUpload = new FormData();

        fileUpload.set('file', action.body.file);

        const fileResponse = yield apiRequest(action.method, action.endpoint, fileUpload, action.headers, onChangeUploadProgress);

        const fileUrl = fileResponse.data.fileUrl;

        return fileUrl;
    } catch (error) {
        console.log(error);
    }
}
