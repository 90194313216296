import styled from 'styled-components';
import theme from 'config/theme';

export const EmptyContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    text-align: center;
`;

export const EmptyThumb = styled.figure`
    margin-bottom: ${theme.spacing.medium.all};
`;

export const EmptyText = styled.p`
    font-family: ${theme.typography.family.primary.normal.italic};
`;
