import { lazy } from 'react';

const List = lazy(() => import('pages/crud'));
const LessonPlanEducational = lazy(() => import('pages/lesson-plan-educational'));

const lessonPlanEducationalRoutes = [
    {
        path: '/app/planos-de-estudos-semanal',
        name: 'Plano de Estudos Semanal',
        component: List,
        exact: true,
        title: 'Plano de Estudos Semanal'
    },
    {
        path: '/app/planos-de-estudos-semanal/novo',
        name: 'Novo',
        component: LessonPlanEducational,
        exact: true,
        title: 'Novo plano de estudos semanal'
    },
    {
        path: '/app/planos-de-estudos-semanal/editar/:id',
        name: 'Editar',
        component: LessonPlanEducational,
        exact: true,
        title: 'Editar plano de estudos semanal'
    }
];

export default lessonPlanEducationalRoutes;
