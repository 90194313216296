export const createSellerCompany = `
mutation (
    $data: [prodigio_sellers_companies_insert_input!]!
) {
    item:insert_prodigio_sellers_companies(
        objects: $data
    ) {
        affected_rows
        returning {
            id: id_company
        }
    }
}
`;
