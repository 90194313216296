// Redux
import { put, call, takeLatest, select } from 'redux-saga/effects';
import { Creators as alertActions } from 'store/ducks/alert';
import { Creators as productActions, Types as ProductTypes } from 'store/ducks/product';
import { Creators as modalActions } from 'store/ducks/modal';

// Services
import history from 'services/history';

// Helpers
import { IGraphqlRequestAction, IPayloadGraphqlRequest } from 'utils/interfaces/IRequestAction';

// Queries
import { productByPk } from 'utils/queries/product/productByPk';
import { graphQLRequest } from '../graphQLRequest';
import { checkGraphQLConflict } from 'utils/checkGraphQLConflictQuery';
import { IPaginationCore } from 'utils/interfaces/IPagination';
import { productPhysicalGroupByPk } from 'utils/queries/product/productPhysicalGroupByPk';
import { IReduxStore } from 'utils/interfaces/IReduxStore';
import { IProductPhysicalGroupPhysicalProduct, IProductState } from 'utils/interfaces/IProduct';

import { addCurrentProductsGroupProduct as addCurrentProductsGroupProductQuery } from 'utils/queries/product/addCurrentProductsGroupProduct';

import { deleteCurrentProductsGroupProduct as deleteCurrentProductsGroupProductQuery } from 'utils/queries/product/deleteCurrentProductsGroupProduct';
import { setMainProductPriceQuery } from 'utils/queries/product/setMainProductPrice';

function* getProductById(action: { type: string; payload: { id: number } }) {
    try {
        if (!action.payload) {
            throw new Error();
        }

        const { data } = yield graphQLRequest(productByPk, action.payload);

        if (!data?.item) {
            throw new Error();
        }

        yield put(productActions.getProductSuccess(data.item));
    } catch (error) {
        console.log(error);
        yield put(productActions.getProductFailure());
        yield put(alertActions.showAlert('Ocorreu um erro. Tente novamente mais tarde.', 'danger'));
        yield call(history.push, { pathname: `/app/produtos` });
    }
}

function* createOrEditProduct(action: IGraphqlRequestAction<{ data: any }>) {
    try {
        const { query, params } = action.payload;

        const { data, errors } = yield graphQLRequest(query, params);

        if (!data?.item) {
            throw new Error();
        }

        if (checkGraphQLConflict(errors)) {
            throw new Error('Esse slug já está sendo usado.');
        }

        if (!!params?.data?.id_store) {
            yield call(history.push, { pathname: `/app/lojas/ver/${params?.data?.id_store}` });
        } else {
            yield call(history.push, { pathname: `/app/produtos/ver/${data.item.id}` });
        }

        yield put(alertActions.showAlert('Produto Salvo.', 'success'));

        yield put(productActions.createOrEditProductSuccess(data));
    } catch (error) {
        yield put(productActions.createOrEditProductFailure());
        yield put(alertActions.showAlert(error.message || 'Ocorreu um erro. Tente novamente mais tarde.', 'danger'));
    }
}

function* getAllEntity(action: IGraphqlRequestAction<any>) {
    try {
        const { query, params } = action.payload;

        const { data } = yield graphQLRequest(query, params);

        if (!data?.items) {
            throw new Error();
        }

        const items =
            data?.items?.map((item: any) => {
                if (!!item?.price) {
                    return item.price;
                }

                if (!!item?.category) {
                    return item.category;
                }

                return item;
            }) || [];

        const count = data.quantity?.aggregate?.count || 0;

        const { offset = 0, limit = 10 } = params;

        const formatPagination: IPaginationCore = {
            page: (limit + offset) / limit,
            totalItems: count,
            count: items.length,
            totalPages: Math.ceil(count / limit)
        };

        const response = {
            items,
            pagination: formatPagination
        };

        yield put(productActions.getAllProductEntitySuccess(response));
    } catch (error) {
        yield put(productActions.getAllProductEntityFailure());
        yield put(alertActions.showAlert(error.message || 'Ocorreu um erro. Tente novamente mais tarde.', 'danger'));
    }
}

function* setMainProductPrice(idProduct: number, idPrice: number) {
    if (!idProduct || !idPrice) {
        return;
    }

    yield graphQLRequest(setMainProductPriceQuery, {
        idProduct,
        idPrice
    });

    const product: IProductState = yield select((state: IReduxStore) => state.product);

    const prices: Array<{ active: boolean; id: number }> = product?.entity?.items || [];

    const updatePrices = prices.map((price) => (price.id !== idPrice ? { ...price, active: false } : price));

    const updateProduct = {
        ...product,
        entity: {
            ...product.entity,
            items: updatePrices
        }
    };

    yield put(productActions.getProductSuccess(updateProduct));
}

function* createOrEditEntity(action: { type: string; payload: IPayloadGraphqlRequest & { mainPrice?: boolean; id_product?: number } }) {
    try {
        const { query, params, mainPrice = false, id_product = 0 } = action.payload;

        const { data, errors } = yield graphQLRequest(query, params);

        const hasConflict = checkGraphQLConflict(errors);

        if (hasConflict && !!params?.data?.id_category) {
            throw new Error('Essa categoria já foi adicionada.');
        } else if (hasConflict) {
            throw new Error('Esse slug já está sendo usado.');
        }

        if (!data?.item) {
            throw new Error();
        }

        let formatResponse = data.item?.returning || data.item?.category || data.item?.price || data.item;

        if (!!data?.prices_by_payment_form?.returning?.length) {
            formatResponse = {
                ...data.item?.returning[0],
                prices_by_payment_form: data.prices_by_payment_form.returning
            };
        }

        yield put(productActions.createOrEditProductEntitySuccess(formatResponse));

        if (mainPrice && id_product) {
            yield setMainProductPrice(id_product, formatResponse.id);
        }

        yield put(alertActions.showAlert('Registro salvo com sucesso!', 'success'));
        yield put(modalActions.closeModal());
    } catch (error) {
        console.log('error', error);

        yield put(productActions.createOrEditProductEntityFailure());

        yield put(alertActions.showAlert(error.message || 'Ocorreu um erro. Tente novamente mais tarde.', 'danger'));
    }
}

function* deleteEntity(action: IGraphqlRequestAction<any>) {
    try {
        const { query, params } = action.payload;

        const { data } = yield graphQLRequest(query, params);

        if (!data?.item) {
            throw new Error();
        }

        yield put(modalActions.closeModal());

        yield put(productActions.deleteProductEntitySuccess({ id: params.id ?? params.idCategory }));

        yield put(alertActions.showAlert('Registro excluído com sucesso!', 'success'));
    } catch (error) {
        yield put(productActions.deleteProductEntityFailure());

        yield put(alertActions.showAlert(error.message || 'Ocorreu um erro. Tente novamente mais tarde.', 'danger'));
    }
}

function* getCurrentProductsGroupById(action: { type: string; payload: { id: number } }) {
    try {
        const { id } = action.payload;

        if (!id) {
            throw new Error();
        }

        const { data } = yield graphQLRequest(productPhysicalGroupByPk, { id });

        if (!data?.item) {
            throw new Error();
        }

        yield put(productActions.getCurrentProductsGroupSuccess(data.item));
    } catch (error) {
        yield put(productActions.getCurrentProductsGroupFailure());
        yield put(modalActions.closeModal());

        yield put(alertActions.showAlert(error.message || 'Ocorreu um erro. Tente novamente.', 'danger'));
    }
}

function* openCurrentProductsGroupModal(action: { type: string; payload?: { id: number } }) {
    if (!action?.payload?.id) {
        return;
    }

    yield put(productActions.getCurrentProductsGroupRequest({ id: action.payload.id }));
}

function* addCurrentProductsGroupProduct(action: { type: string; payload: { id: number; name: string } }) {
    try {
        const { id } = action.payload;

        if (!id) {
            throw new Error();
        }

        const { groupId = 0, products = [] } = yield select((state: IReduxStore) => ({
            groupId: state?.product?.currentProductsGroup?.id,
            products: state?.product?.currentProductsGroup?.physical_products
        }));

        // CHECK IF THIS PRODUCT HAVE ADDED
        if (!!products?.length && (products as IProductPhysicalGroupPhysicalProduct[]).some((item) => item?.physical_product?.id === id)) {
            throw new Error('Esse produto físico já foi adicionado.');
        }

        const payload = {
            data: {
                id_product_physical: id,
                id_product_physical_group: groupId
            }
        };

        const { data } = yield graphQLRequest(addCurrentProductsGroupProductQuery, payload);

        if (!data?.item) {
            throw new Error();
        }

        yield put(productActions.addCurrentProductsGroupProductSuccess(data.item));
        yield put(productActions.closeCurrentProductsGroupSearch());

        yield put(alertActions.showAlert('Produto físico adicionado com sucesso.', 'success'));
    } catch (error) {
        yield put(productActions.addCurrentProductsGroupProductFailure());

        yield put(alertActions.showAlert(error.message || 'Ocorreu um erro. Tente novamente.', 'danger'));
    }
}

function* createOrEditProductsGroup(action: IGraphqlRequestAction<any>) {
    try {
        const { query, params } = action.payload;

        const { data } = yield graphQLRequest(query, params);

        if (!data?.item) {
            throw new Error();
        }

        yield put(productActions.createOrEditProductsGroupSuccess({ ...data.item, ...(!params?.data?.id && { physical_products: [] }) }));

        if (!!params?.data?.id) {
            yield put(productActions.closeCurrentProductsGroupModal());
        }

        yield put(alertActions.showAlert('Registro salvo com sucesso!', 'success'));
    } catch (error) {
        console.log('error', error);

        yield put(productActions.createOrEditProductsGroupFailure());

        yield put(alertActions.showAlert(error.message || 'Ocorreu um erro. Tente novamente mais tarde.', 'danger'));
    }
}

function* deleteCurrentProductsGroupProduct(action: { type: string; payload: { id: number } }) {
    try {
        const { id } = action.payload;

        if (!id) {
            throw new Error();
        }

        const groupId = yield select((state: IReduxStore) => state?.product?.currentProductsGroup?.id);

        const { data } = yield graphQLRequest(deleteCurrentProductsGroupProductQuery, { id_product_physical_group: groupId, id_product_physical: id });

        if (!data?.item) {
            throw new Error();
        }

        yield put(productActions.deleteCurrentProductsGroupProductSuccess({ id }));

        yield put(alertActions.showAlert('Produto físico removido com sucesso.', 'success'));
    } catch (error) {
        yield put(productActions.deleteCurrentProductsGroupProductFailure());

        yield put(alertActions.showAlert(error.message || 'Ocorreu um erro. Tente novamente.', 'danger'));
    }
}

export default [
    takeLatest(ProductTypes.GET_PRODUCT_REQUEST, getProductById),
    takeLatest(ProductTypes.CREATE_OR_EDIT_PRODUCT_REQUEST, createOrEditProduct),
    takeLatest(ProductTypes.GET_ALL_PRODUCT_ENTITY_REQUEST, getAllEntity),
    takeLatest(ProductTypes.CREATE_OR_EDIT_PRODUCT_ENTITY_REQUEST, createOrEditEntity),
    takeLatest(ProductTypes.DELETE_PRODUCT_ENTITY_REQUEST, deleteEntity),
    takeLatest(ProductTypes.GET_CURRENT_PRODUCTS_GROUP_REQUEST, getCurrentProductsGroupById),
    takeLatest(ProductTypes.OPEN_CURRENT_PRODUCTS_GROUP_MODAL, openCurrentProductsGroupModal),
    takeLatest(ProductTypes.ADD_CURRENT_PRODUCTS_GROUP_PRODUCT_REQUEST, addCurrentProductsGroupProduct),
    takeLatest(ProductTypes.CREATE_OR_EDIT_PRODUCTS_GROUP_REQUEST, createOrEditProductsGroup),
    takeLatest(ProductTypes.DELETE_CURRENT_PRODUCTS_GROUP_PRODUCT_REQUEST, deleteCurrentProductsGroupProduct)
];
