import { IField } from 'utils/interfaces/IField';
import InputTypes from 'utils/enums/inputTypes';

const schoolPeriod: IField[] = [
    {
        presentation: 'Nome',
        label: 'Nome do ano letivo',
        name: 'name',
        type: InputTypes.text,
        columns: 3
    },
    {
        presentation: 'Início',
        label: 'Data inicial',
        name: 'startDate',
        type: InputTypes.datePicker,
        columns: 3
    },
    {
        presentation: 'Fim',
        label: 'Data final',
        name: 'endDate',
        type: InputTypes.datePicker,
        columns: 3
    }
];

export default schoolPeriod;
