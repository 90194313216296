import styled from 'styled-components';
import { TableCaretType } from '.';

export const TableV2CaretsContainer = styled.button.attrs({ type: 'button' })<{ caretType?: TableCaretType }>`
    display: flex;
    flex-direction: column;
    background: transparent;
    border: none;
    outline: none;

    > svg {
        :first-child {
            transform: rotate(180deg);
            opacity: ${({ caretType }) => (caretType === TableCaretType.Ascending ? 1 : 0.5)};
        }

        :last-child {
            opacity: ${({ caretType }) => (caretType === TableCaretType.Descending ? 1 : 0.5)};
        }
    }
`;
