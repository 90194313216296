enum InputTypes {
    datePicker = 'date',
    email = 'email',
    multipleSelect = 'multipleSelect',
    number = 'number',
    password = 'password',
    simpleSelect = 'simpleSelect',
    simpleSelectData = 'simpleSelectData',
    text = 'text',
    textArea = 'textarea',
    upload = 'file',
    color = 'colorPicker'
}

export default InputTypes;
