import InputTypes from 'utils/enums/inputTypes';
import { IField } from 'utils/interfaces/IField';

const knowledgeArea: IField[] = [
    {
        presentation: 'Nome',
        label: 'Nome da área',
        name: 'name',
        type: InputTypes.text,
        columns: 3
    },
    {
        presentation: 'Selecione uma disciplina',
        label: 'Disciplina',
        name: 'subject',
        type: InputTypes.simpleSelect,
        columns: 3
    }
];

export default knowledgeArea;
