export const productByPk = `
query ProductByPk($id: Int!) {
    item: prodigio_products_by_pk(id: $id) {
      id
      slug
      name
      description
      thumb
      banner_url
      available_for_app
      is_active
      title
      has_delivery
      has_configuration
      brand {
        id
        name
      }
      store {
        id
        name
      }
    }
  }
`;
