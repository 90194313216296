import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { createPortalElement } from "./createPortalElement";
import { removePortalElement } from "./removePortalElement";

interface IProps {
    children: React.ReactNode;
    portalId: string;
}

const Portal = ({ children, portalId }: IProps) => {
    const portalElement = document.getElementById(portalId);

    useEffect(() => {
        createPortalElement(portalId);

        return () => {
            removePortalElement(portalId);
        };
    }, [portalId]);

    if (!portalElement || !children) {
        return null;
    }

    return ReactDOM.createPortal(children, portalElement);
};

export default Portal;
