import { createActions, createReducer } from 'reduxsauce';
import { ISubject } from 'utils/interfaces/ISubject';

export const { Creators, Types } = createActions({
    teacherRequest: [],
    teacherSuccess: ['payload'],
    teacherFailure: [],

    getTeacherByIdRequest: ['payload'],
    getTeacherByIdSuccess: ['payload'],
    getTeacherByIdFailure: [],

    createOrEditTeacherRequest: ['payload'],
    createOrEditTeacherSuccess: ['payload'],
    createOrEditTeacherFailure: [],

    // address
    createOrEditTeacherAddressRequest: ['payload'],
    createOrEditTeacherAddressSuccess: ['payload'],
    createOrEditTeacherAddressFailure: [],

    deleteTeacherAddressRequest: ['payload'],
    deleteTeacherAddressSuccess: ['payload'],
    deleteTeacherAddressFailure: [],

    clearTeachers: []
});

export interface ITeacherState {
    isLoading: boolean;
    items: any[];
    id: number;
    subjects?: ISubject[];
    description: string;
    image: string;
    isMonitor: boolean;
    person: {
        id: number;
        name: string;
        gender: string;
        cpf: string;
        birthDate: string;
        phone: string;
        slug: string;
        observation: string;
        addresses?: any[];

        user: {
            id: number;
            email: string;
            username: string;
        };
    };

    subjectsByClass?: any[];
}

const INITIAL_STATE: ITeacherState = {
    isLoading: false,
    items: [],
    isMonitor: false,
    id: 0,
    description: '',
    image: '',
    person: {
        id: 0,
        name: '',
        gender: '',
        cpf: '',
        birthDate: '',
        phone: '',
        slug: '',
        observation: '',
        user: {
            id: 0,
            email: '',
            username: ''
        }
    }
};

const teacherRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true });
const teacherSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoading: false,
    items: action.payload
});

const teacherFailure = (state = INITIAL_STATE) => ({ ...state, ...INITIAL_STATE });

const getTeacherByIdRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true });
const getTeacherByIdSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoading: false,
    ...action.payload
});
const getTeacherByIdFailure = (state = INITIAL_STATE) => ({ ...state, ...INITIAL_STATE });

const createOrEditTeacherRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true });
const createOrEditTeacherSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoading: false,
    ...action.payload
});
const createOrEditTeacherFailure = (state = INITIAL_STATE) => ({ ...state, ...INITIAL_STATE });

// ADDRESS
const createOrEditTeacherAddressRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true });
const createOrEditTeacherAddressSuccess = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        isLoading: false,
        person: {
            ...state.person,
            addresses: action.payload
        }
    };
};
const createOrEditTeacherAddressFailure = (state = INITIAL_STATE) => ({ ...state, isLoading: false });

const deleteTeacherAddressRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true });
const deleteTeacherAddressSuccess = (state = INITIAL_STATE, action: any) => {
    const { payload } = action;

    const updateAddresses = !!state.person.addresses && state.person.addresses.filter((item) => item.id !== payload.id);

    return {
        ...state,
        isLoading: false,
        person: {
            ...state.person,
            addresses: updateAddresses
        }
    };
};
const deleteTeacherAddressFailure = (state = INITIAL_STATE) => ({ ...state, isLoading: false });

const clearTeachers = (state = INITIAL_STATE) => INITIAL_STATE;

export default createReducer(INITIAL_STATE, {
    [Types.TEACHER_REQUEST]: teacherRequest,
    [Types.TEACHER_SUCCESS]: teacherSuccess,
    [Types.TEACHER_FAILURE]: teacherFailure,

    [Types.GET_TEACHER_BY_ID_REQUEST]: getTeacherByIdRequest,
    [Types.GET_TEACHER_BY_ID_SUCCESS]: getTeacherByIdSuccess,
    [Types.GET_TEACHER_BY_ID_FAILURE]: getTeacherByIdFailure,

    [Types.CREATE_OR_EDIT_TEACHER_REQUEST]: createOrEditTeacherRequest,
    [Types.CREATE_OR_EDIT_TEACHER_SUCCESS]: createOrEditTeacherSuccess,
    [Types.CREATE_OR_EDIT_TEACHER_FAILURE]: createOrEditTeacherFailure,

    // Address
    [Types.CREATE_OR_EDIT_TEACHER_ADDRESS_REQUEST]: createOrEditTeacherAddressRequest,
    [Types.CREATE_OR_EDIT_TEACHER_ADDRESS_SUCCESS]: createOrEditTeacherAddressSuccess,
    [Types.CREATE_OR_EDIT_TEACHER_ADDRESS_FAILURE]: createOrEditTeacherAddressFailure,

    [Types.DELETE_TEACHER_ADDRESS_REQUEST]: deleteTeacherAddressRequest,
    [Types.DELETE_TEACHER_ADDRESS_SUCCESS]: deleteTeacherAddressSuccess,
    [Types.DELETE_TEACHER_ADDRESS_FAILURE]: deleteTeacherAddressFailure,

    [Types.CLEAR_TEACHERS]: clearTeachers
});
