import { FormGroup } from 'components/forms/styles';
import styled, { createGlobalStyle } from 'styled-components';
import { ModalWrapper } from '../styles';

export const GenerateExamRankingGlobalStyle = createGlobalStyle`
    ${ModalWrapper} {
        overflow-x: unset; 
     overflow-y: unset;
    }
`;

export const GenerateExamRankingContainer = styled.div`
    width: 500px;
    max-width: 100%;
    position: relative;

    ${FormGroup} {
        position: relative;
    }
`;
