import React, { useMemo } from 'react';

import * as S from './styles';
import { IAvaliableColumns } from 'utils/interfaces/IRoute';
import Icon from 'components/icons/icon';
import { TableItemType } from 'utils/enums/tableItemType';
import Select from 'components/forms/select';
import convertEnumToOptions from 'utils/convertEnumToOptions';
import DatePicker from 'components/forms/datePicker';
import { answerQuestionsStatus } from 'utils/enums/answerQuestionsStatus';

interface IProps {
    column: IAvaliableColumns;
    handleChangeSearchText(value: any): void;
    columnLabel?: string;
}

const TableV2SearchInput = ({ column, columnLabel, handleChangeSearchText }: IProps) => {
    const inputElement = useMemo(() => {
        switch (column.type) {
            case TableItemType.Number:
            case TableItemType.Text:
            case TableItemType.Array:
            case TableItemType.Teacher:
            case TableItemType.RichText:
            case TableItemType.Color:
            case TableItemType.Person:
            case TableItemType.ArrayText:
            case TableItemType.ArrayTextTooltip:
            case TableItemType.Clickable:
                return (
                    <S.TableV2SearchInputTextField
                        type={column.type === TableItemType.Number ? 'number' : 'search'}
                        placeholder={`Pesquisar ${columnLabel}...`}
                        onChange={(e) => handleChangeSearchText(e?.target?.value)}
                    />
                );

            case TableItemType.Email:
                return <S.TableV2SearchInputTextField type="email" placeholder={`Pesquisar ${columnLabel}...`} onChange={(e) => handleChangeSearchText(e?.target?.value)} />;

            case TableItemType.Boolean:
                return (
                    <Select
                        autoFocus={false}
                        placeholder={`Pesquisar...`}
                        options={[
                            {
                                value: true,
                                label: 'Sim'
                            },
                            {
                                value: false,
                                label: 'Não'
                            }
                        ]}
                        isClearable={true}
                        onChange={(option: any) => handleChangeSearchText(option?.value)}
                        classNamePrefix="select"
                        multiple={false}
                        name={column.name}
                    />
                );

            case TableItemType.Status:
                return (
                    <Select
                        autoFocus={false}
                        placeholder={`Pesquisar...`}
                        options={convertEnumToOptions(answerQuestionsStatus)}
                        isClearable={true}
                        onChange={(option: any) => handleChangeSearchText(option?.value)}
                        classNamePrefix="select"
                        multiple={false}
                        name={column.name}
                    />
                );

            case TableItemType.Enum:
                // eslint-disable-next-line
                const convertEnum = !!column?.enum ? convertEnumToOptions(column.enum, 'crud') : [];

                return (
                    <Select
                        autoFocus={false}
                        placeholder={`Pesquisar...`}
                        options={convertEnum}
                        isClearable={true}
                        onChange={(option: any) => handleChangeSearchText(option?.value)}
                        classNamePrefix="select"
                        multiple={false}
                        name={column.name}
                    />
                );

            case TableItemType.Calendar:
            case TableItemType.Date:
                return <DatePicker placeholderText={`Pesquisar ${columnLabel}...`} name="startDate" autoComplete="off" dateFormat="P" onChange={(date) => handleChangeSearchText(date)} />;

            default:
                return null;
        }
    }, [column, columnLabel, handleChangeSearchText]);

    if (!inputElement) {
        return null;
    }

    return (
        <S.TableV2SearchInputContainer>
            <Icon idIcon="ico-search" height="15" width="15" viewBox="0 0 451 451" />

            {inputElement}
        </S.TableV2SearchInputContainer>
    );
};

export default TableV2SearchInput;
