import React from 'react';

// Components
import Icon from 'components/icons/icon';

// Dependencies
import { Route, RouteComponentProps, withRouter } from 'react-router-dom';

// Assets
import { BreadcrumbWrapper, BreadcrumbItem, BreadcrumbButton, BreadcrumbContainer } from './styles';
import truncateText from 'utils/truncateText';

export interface IBreadcrumbItem {
    label: any;
    path?: string;
    active?: boolean;
}
interface IProps extends RouteComponentProps<any> {
    items?: IBreadcrumbItem[];
    title: string;
}

const routes = {
    '/app': 'Home',
    '/app/empresas': 'Empresas',
    '/app/empresas/novo': 'Nova empresa',
    '/app/empresas/editar/:id': 'Editar',

    '/app/plano-de-estudos': 'Plano de Estudos',
    '/app/plano-de-estudos/novo': 'Novo plano de estudos',
    '/app/plano-de-estudos/editar/:id': 'Editar plano de estudos',
    '/app/plano-de-estudos/ver/:id': 'Editar plano de estudos',

    '/app/disciplinas': 'Disciplinas',
    '/app/disciplinas/novo': 'Nova disciplina',
    '/app/disciplinas/editar/:id': 'Editar disciplina',

    '/app/anos-letivos': 'Anos letivos',
    '/app/anos-letivos/novo': 'Novo ano letivo',
    '/app/anos-letivos/editar/:id': 'Editar ano letivo',

    '/app/cursos': 'Cursos',
    '/app/cursos/novo': 'Novo curso',
    '/app/cursos/editar/:id': 'Editar curso',

    '/app/simulados': 'Simulados',
    '/app/simulados/novo': 'Novo simulado',
    '/app/simulados/editar/:id': 'Editar simulado',

    '/app/provas-anteriores': 'Provas anteriores',
    '/app/provas-anteriores/novo': 'Novo prova anterior',
    '/app/provas-anteriores/editar/:id': 'Editar prova anterior',

    '/app/questoes': 'Questões',
    '/app/questoes/novo': 'Nova questão',
    '/app/questoes/editar/:id': 'Editar questão',

    '/app/listas-de-exercicios': 'Listas de Exercícios',
    '/app/listas-de-exercicios/novo': 'Nova lista de exercícios',
    '/app/listas-de-exercicios/editar/:id': 'Editar lista de exercícios',

    '/app/listas-de-exercicios-extras': 'Listas de Exercícios extras',
    '/app/listas-de-exercicios-extras/novo': 'Nova lista de exercícios extra',
    '/app/listas-de-exercicios-extras/editar/:id': 'Editar lista de exercícios extra',

    '/app/listas-de-exercicios-extras/correcoes': 'Correções',
    '/app/listas-de-exercicios-extras/correcoes/:id': 'Ver correção',
    '/app/listas-de-exercicios-extras/correcoes/editar/:id': 'Corrigir',

    '/app/turmas': 'Turmas',
    '/app/turmas/novo': 'Nova turma',
    '/app/turmas/editar/:id': 'Editar turma',

    '/app/series': 'Séries',
    '/app/series/novo': 'Nova série',
    '/app/series/editar/:id': 'Editar série',

    '/app/unidades-de-escola': 'Unidades de escola',
    '/app/unidades-de-escola/novo': 'Nova unidade',
    '/app/unidades-de-escola/editar/:id': 'Editar unidade',

    '/app/professores': 'Professores',
    '/app/professores/novo': 'Novo professor',
    '/app/professores/editar/:id': 'Editar professor',

    '/app/videos': 'Vídeos',
    '/app/videos/novo': 'Novo vídeo',
    '/app/videos/editar/:id': 'Editar vídeo',
    '/app/videos/fila-de-uploads/': 'Fila de uploads',
    '/app/videos/fila-de-uploads/uploads': 'Uploads',

    '/app/alunos': 'Alunos',
    '/app/alunos/novo': 'Novo aluno',
    '/app/alunos/editar/:id': 'Editar aluno',

    '/app/projetos': 'Projetos',
    '/app/projetos/novo': 'Novo projeto',
    '/app/projetos/editar/:id': 'Editar projeto',

    '/app/projetos/categorias': 'Categorias',
    '/app/projetos/categorias/novo': 'Nova categoria',
    '/app/projetos/categorias/editar/:id': 'Editar categoria',

    '/app/menus': 'Menus',
    '/app/menus/novo': 'Novo menu',
    '/app/menus/editar/:id': 'Editar menu',

    '/app/perfis': 'Perfis',
    '/app/perfis/novo': 'Novo perfil',
    '/app/perfis/editar/:id': 'Editar perfil',

    '/app/usuarios': 'Usuários',
    '/app/usuarios/novo': 'Novo usuário',
    '/app/usuarios/editar/:id': 'Editar usuário',

    '/app/qrcodes': 'QR Codes',
    '/app/qrcodes/novo': 'Criar QR Codes',
    '/app/qrcodes/editar/:id': 'Editar QR Code',

    '/app/marcas': 'Marcas',
    '/app/marcas/novo': 'Criar marca',
    '/app/marcas/editar/:id': 'Editar marca',

    '/app/lojas': 'Lojas',
    '/app/lojas/novo': 'Nova loja',
    '/app/lojas/editar/:id': 'Editar loja',
    '/app/lojas/ver/:storeId/pedidos/:id': 'Pedido',

    '/app/produtos': 'Produtos',
    '/app/produtos/novo': 'Novo produto',
    '/app/produtos/editar/:id': 'Editar produto',

    '/app/frentes-de-disciplina': 'Frentes de disciplina',
    '/app/frentes-de-disciplina/novo': 'Nova frente',
    '/app/frentes-de-disciplina/editar/:id': 'Editar frente',

    '/app/pedidos': 'Pedidos',
    '/app/pedidos/ver/:id': 'Detalhes do pedido',

    '/app/plano-de-estudos/eventos': 'Eventos',
    '/app/plano-de-estudos/eventos/ver/:id': 'Detalhes do evento',

    '/app/plano-de-estudos/tags': 'Tags',
    '/app/plano-de-estudos/tags/editar/:id': 'Editar Tags',

    '/app/areas-de-conhecimento': 'Áreas de conhecimento',
    '/app/areas-de-conhecimento/novo': 'Nova área de conhecimento',
    '/app/areas-de-conhecimento/editar/:id': 'Editar área de conhecimento',

    '/app/arquivos': 'Arquivos',
    '/app/arquivos/novo': 'Novo arquivo',
    '/app/arquivos/editar/:id': 'Editar arquivo',

    '/app/arquivos/categorias': 'Categorias de Arquivo',
    '/app/arquivos/categorias/novo': 'Nova categoria de arquivo',
    '/app/arquivos/categorias/editar/:id': 'Editar categoria de arquivo',

    '/app/motores-de-correcao': 'Motores de correção',
    '/app/motores-de-correcao/novo': 'Novo motor de correção',
    '/app/motores-de-correcao/editar/:id': 'Editar motor de correção',

    '/app/produtos-fisicos': 'Produtos físicos',
    '/app/produtos-fisicos/novo': 'Novo produto físico',
    '/app/produtos-fisicos/editar/:id': 'Editar produto físico',

    '/app/comissionados': 'Comissionados',
    '/app/comissionados/novo': 'Novo comissionado',
    '/app/comissionados/editar/:id': 'Editar comissionado',
    '/app/comissionados/ver/:id': 'Ver comissionado',

    '/app/paginas': 'Paginas',
    '/app/paginas/novo': 'Nova página',
    '/app/paginas/editar/:id': 'Editar página',

    '/app/simulados/resolucoes': 'Resoluções',
    '/app/simulados/resolucoes/ver/:id': 'Resolução',

    '/app/planos-de-estudos-semanal': 'Planos de estudos semanal',
    '/app/planos-de-estudos-semanal/novo': 'Novo plano de estudos semanal',
    '/app/planos-de-estudos-semanal/editar/:id': 'Editar plano de estudos semanal'
};

const findRouteName = (url: string) => routes[url];

const getPaths = (pathname: any) => {
    const paths = ['/'];

    if (pathname === '/app') {
        return paths;
    }

    pathname.split('/').reduce((prev: any, curr: any) => {
        const currPath = `${prev}/${curr}`;

        paths.push(currPath);

        return currPath;
    });

    return paths;
};

const BreadcrumbsLink = ({ label, path = '', active }: IBreadcrumbItem) => <BreadcrumbItem active={active}>{!!path ? <BreadcrumbButton to={path}>{label}</BreadcrumbButton> : label}</BreadcrumbItem>;

const HomeLink = () => <BreadcrumbsLink path="/app" label={<Icon idIcon="ico-home" width="17" height="17" viewBox="0 0 486.988 486.988" />} />;

const BreadcrumbsItem = (props: RouteComponentProps<any> & { title: string; path: string }) => {
    const routeName = findRouteName(props.path);

    if (props.path === props.match.url) {
        return <BreadcrumbItem active={props.match.url === props.location.pathname}>{truncateText(props.title, 25, true) || routeName}</BreadcrumbItem>;
    }

    if (props.path === '/app') {
        return <HomeLink />;
    }

    if (routeName) {
        return <BreadcrumbsLink path={props.path} label={routeName} />;
    }

    return null;
};

const Breadcrumbs = (props: IProps) => {
    const paths = getPaths(props.location.pathname);

    const { items } = props;
    const withItems = !!items?.length;

    return (
        <BreadcrumbWrapper>
            {withItems ? (
                <>
                    <HomeLink />
                    {items?.map(({ label, path }, index) => (
                        <BreadcrumbsLink path={path} label={label} key={index} />
                    ))}
                </>
            ) : (
                paths.map((p, index) => <BreadcrumbsItem key={index} path={p} {...props} />)
            )}
        </BreadcrumbWrapper>
    );
};

const Breadcrumb = (props: IProps) => (
    <BreadcrumbContainer>
        <Route path="/:path" render={() => <Breadcrumbs {...props} />} />
    </BreadcrumbContainer>
);

export default withRouter(Breadcrumb);
