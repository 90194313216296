import { put, call } from 'redux-saga/effects';
import { Creators as brandActions } from 'store/ducks/brand';
import { Creators as alertActions } from 'store/ducks/alert';
import { Creators as modalActions } from 'store/ducks/modal';
import history from 'services/history';
import { apiRequest } from '../apiRequest';

export function* getAllBrands(action: any) {
    try {
        const { data } = yield apiRequest('GET', '/brand');

        yield put(brandActions.getAllBrandsSuccess(data.items));
    } catch (error) {
        yield put(brandActions.getAllBrandsFailure());
        yield put(alertActions.showAlert('Não foi possível recuperar as marcas.', 'danger'));
    }
}

export function* getBrandById(action: any) {
    try {
        const { payload } = action;

        const { data } = yield apiRequest(payload.method, payload.endpoint, payload.body, payload.headers);

        yield put(brandActions.getBrandSuccess(data));
    } catch (error) {
        yield put(brandActions.getBrandFailure());
        yield put(alertActions.showAlert('Não foi possível encontrar esse registro.', 'danger'));
    }
}

export function* createOrEditBrand(action: any) {
    try {
        const { payload } = action;

        const formatBody = {
            ...payload.body,
            managingUsers: payload.body.managingUsers.map((item: any) => {
                if (item.id < 1) {
                    delete item.id;
                }

                return item;
            })
        };

        const { data } = yield apiRequest(payload.method, payload.endpoint, formatBody, payload.headers);

        yield put(brandActions.createOrEditBrandSuccess(data));

        yield put(alertActions.showAlert(`Marca ${payload.method === 'POST' ? 'Criada' : 'Atualizada'}!`, 'success'));

        if (payload.method === 'POST') {
            yield call(history.push, { pathname: `/app/marcas/editar/${data.id}` });
        }
    } catch (error) {
        yield put(brandActions.createOrEditBrandFailure());
        yield put(alertActions.showAlert('Ocorreu um erro. Tente novamente mais tarde.', 'danger'));
    }
}

export function* createOrEditUserBrand(action: any) {
    try {
        const { payload } = action;

        yield put(brandActions.createOrEditUsersBrandSuccess(payload));
        yield put(modalActions.closeModal());
    } catch (error) {
        yield put(brandActions.createOrEditUsersBrandFailure());
        yield put(alertActions.showAlert('Ocorreu um erro. Tente novamente mais tarde.', 'danger'));
    }
}
