import { createReducer, createActions } from 'reduxsauce';
import { UploadBunnyTypes, IUploadBunnyActionsCreators, IUploadBunnyState } from './types';

export const { Creators, Types } = createActions<Record<UploadBunnyTypes, UploadBunnyTypes>, IUploadBunnyActionsCreators>({
    uploadBunnyRequest: ['payload'],
    uploadBunnySuccess: ['payload'],
    uploadBunnyFailure: ['payload'],
    uploadBunnyClear: [],
    uploadBunnyIsLoading: []
});

const INITIAL_STATE: IUploadBunnyState = {
    isLoading: false,
    data: [],
    isLeaveScreen: true
};

const uploadBunnyRequest = (state = INITIAL_STATE, action: any) => {
    const { data } = action.payload;

    return { ...state, isLoading: true, data: data, isLeaveScreen: false };
};

const uploadBunnySuccess = (state = INITIAL_STATE, action: any) => {
    const { data } = action.payload;

    return { ...state, data: data };
};

const uploadBunnyFailure = (state = INITIAL_STATE, action: any) => {
    const { data } = action.payload;

    return { ...state, data: data };
};

const uploadBunnyClear = () => {
    return { isLoading: false, data: [], isLeaveScreen: true };
};

const uploadBunnyIsLoading = (state = INITIAL_STATE) => {
    return { ...state, isLoading: false };
};

export default createReducer(INITIAL_STATE, {
    [Types.UPLOAD_BUNNY_REQUEST]: uploadBunnyRequest,
    [Types.UPLOAD_BUNNY_SUCCESS]: uploadBunnySuccess,
    [Types.UPLOAD_BUNNY_FAILURE]: uploadBunnyFailure,
    [Types.UPLOAD_BUNNY_CLEAR]: uploadBunnyClear,
    [Types.UPLOAD_BUNNY_IS_LOADING]: uploadBunnyIsLoading
});
