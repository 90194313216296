import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { Creators } from 'store/ducks/modal';
import { IModalResizable } from 'utils/interfaces/IModal';

export function useModal() {
    const dispatch = useDispatch();

    const open = useCallback((element: string, action?: any, resizableOptions?: IModalResizable, isCustom?: boolean) => dispatch(Creators.openModal(element, action, resizableOptions, !!isCustom)), [
        dispatch
    ]);

    const close = useCallback(() => dispatch(Creators.closeModal()), [dispatch]);

    return {
        open,
        close
    };
}
