import { createActions, createReducer } from 'reduxsauce';
import { IPagination } from 'utils/interfaces/IPagination';

export const { Creators, Types } = createActions({
    getAllSchoolGradesRequest: ['payload'],
    getAllSchoolGradesSuccess: ['payload'],
    getAllSchoolGradesFailure: [],

    getSchoolGradeRequest: ['payload'],
    getSchoolGradeSuccess: ['payload'],
    getSchoolGradeFailure: [],

    getAllSchoolGradeProjectsRequest: ['payload'],
    getAllSchoolGradeProjectsSuccess: ['payload'],
    getAllSchoolGradeProjectsFailure: [],

    sendProjectToSchoolGradeRequest: ['payload'],
    sendProjectToSchoolGradeSuccess: [],
    sendProjectToSchoolGradeFailure: []
});

export interface ISchoolGrade {
    id: number;
    name: string;
    order: number;
}

export interface ISchoolGradeState extends ISchoolGrade {
    isLoading: boolean;
    items: any[];
    projects: IPagination<any>;
}

const INITIAL_STATE: ISchoolGradeState = {
    isLoading: false,
    items: [],
    projects: {
        items: [],
        count: 0,
        page: 0,
        totalItems: 0,
        totalPages: 0
    },
    id: 0,
    name: '',
    order: 0
};

const schoolGradesRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true });

const getSchoolGradeSuccess = (state = INITIAL_STATE, action: any) => ({ ...state, ...action.payload, isLoading: false });
const getSchoolGradeFailure = (state = INITIAL_STATE) => ({ ...state, isLoading: false });

const getAllSchoolGradesSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoading: false,
    items: action.payload
});
const getAllSchoolGradesFailure = (state = INITIAL_STATE) => ({ ...state, ...INITIAL_STATE });

const getAllSchoolGradeProjects = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoading: false,
    projects: action.payload
});

const getAllSchoolGradeProjectsFailure = (state = INITIAL_STATE) => ({
    ...state,
    isLoading: false,
    projects: []
});

const sendProjectToSchoolGrade = (state = INITIAL_STATE, action: any) => {
    console.log('action', action);

    return { ...state, isLoading: false };
};

const sendProjectToSchoolGradeFailure = (state = INITIAL_STATE) => ({ ...state, isLoading: false });

export default createReducer(INITIAL_STATE, {
    [Types.GET_ALL_SCHOOL_GRADES_REQUEST]: schoolGradesRequest as any,
    [Types.GET_ALL_SCHOOL_GRADES_SUCCESS]: getAllSchoolGradesSuccess,
    [Types.GET_ALL_SCHOOL_GRADES_FAILURE]: getAllSchoolGradesFailure,

    [Types.GET_SCHOOL_GRADE_REQUEST]: schoolGradesRequest,
    [Types.GET_SCHOOL_GRADE_SUCCESS]: getSchoolGradeSuccess,
    [Types.GET_SCHOOL_GRADE_FAILURE]: getSchoolGradeFailure,

    [Types.GET_ALL_SCHOOL_GRADE_PROJECTS_REQUEST]: schoolGradesRequest,
    [Types.GET_ALL_SCHOOL_GRADE_PROJECTS_SUCCESS]: getAllSchoolGradeProjects,
    [Types.GET_ALL_SCHOOL_GRADE_PROJECTS_FAILURE]: getAllSchoolGradeProjectsFailure,

    [Types.SEND_PROJECT_TO_SCHOOL_GRADE_REQUEST]: schoolGradesRequest,
    [Types.SEND_PROJECT_TO_SCHOOL_GRADE_SUCCESS]: sendProjectToSchoolGrade,
    [Types.SEND_PROJECT_TO_SCHOOL_GRADE_FAILURE]: sendProjectToSchoolGradeFailure
});
