export const createOrEditProductPhysicalConfigurationOption = `
mutation ($data: prodigio_product_physical_configuration_options_insert_input!) {
    item: insert_prodigio_product_physical_configuration_options_one(object: $data, on_conflict: {constraint: PK_ff0c025b855c0e23dbabc341564, update_columns: [name, sku, updated, id_media]}) {
      id
      name
      sku
      media {
        id
        url
      }
    }
  }
`;
