import { IField } from 'utils/interfaces/IField';
import InputTypes from 'utils/enums/inputTypes';

const event: IField[] = [
    {
        presentation: 'Titulo',
        label: 'Titulo',
        name: 'title',
        type: InputTypes.text,
        columns: 3
    }
];

export default event;
