import { Form } from '@rocketseat/unform';
import { SmallTitle } from 'assets/global';
import Button from 'components/button';
import DatePicker from 'components/forms/datePicker';
import { FormGroup, Label } from 'components/forms/styles';
import dictionary from 'config/dictionary';
import { useAlert } from 'hooks/useAlert';
import { useModal } from 'hooks/useModal';
import React, { useCallback } from 'react';
import api from 'services/api';
import { date, object } from 'yup';
import { ModalContent, ModalFooter, ModalHeader } from '../styles';
import { GenerateExamRankingContainer, GenerateExamRankingGlobalStyle } from './styles';

interface IFormBody {
    startResolutionsDate: Date;
    endResolutionsDate: Date;
}

interface IProps {
    action: any;
}

const schema = object().shape({
    startResolutionsDate: date().typeError(dictionary.validations.required).required(dictionary.validations.required),
    endResolutionsDate: date().typeError(dictionary.validations.required).required(dictionary.validations.required)
});

const GenerateExamRanking: React.FC<IProps> = ({ action }) => {
    const modal = useModal();
    const alert = useAlert();

    const handleGenerateRanking = useCallback(
        async (data: IFormBody) => {
            try {
                await api.post(`/admin/exam/${action?.examId}/rankings`, data);

                alert.show('Requisição enviada.', 'success');
            } catch (error) {
                console.log(error);
                alert.show('Desculpe, ocorreu um problema.', 'danger');
            }
        },
        [action, alert]
    );

    const handleSubmit = useCallback(
        (data: any) => {
            modal.open('confirmActionModal', {
                confirmAction: () => handleGenerateRanking(data)
            });
        },
        [handleGenerateRanking, modal]
    );

    if (!action?.examId) {
        alert.show('Id do simulado não informado.', 'danger');
        modal.close();

        return null;
    }

    return (
        <>
            <GenerateExamRankingGlobalStyle />
            <GenerateExamRankingContainer>
                <ModalHeader>
                    <SmallTitle>Gerar ranking</SmallTitle>
                </ModalHeader>
                <Form onSubmit={handleSubmit} schema={schema}>
                    <ModalContent>
                        <FormGroup withSpacing>
                            <Label>Data início*</Label>
                            <DatePicker placeholderText="Data início" name="startResolutionsDate" autoComplete="off" dateFormat="P" onChange={() => null} />
                        </FormGroup>

                        <FormGroup withSpacing>
                            <Label>Data fim*</Label>
                            <DatePicker placeholderText="Data início" name="endResolutionsDate" autoComplete="off" dateFormat="P" onChange={() => null} />
                        </FormGroup>
                    </ModalContent>
                    <ModalFooter>
                        <Button type="submit" variant="success" block>
                            Enviar
                        </Button>
                    </ModalFooter>
                </Form>
            </GenerateExamRankingContainer>
        </>
    );
};

export default GenerateExamRanking;
