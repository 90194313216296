import { lazy } from 'react';

const List = lazy(() => import('pages/crud'));
const CreateOrEdit = lazy(() => import('pages/crud/createOrEdit'));

const subjectFrontRoutes = [
    {
        path: '/app/frentes-de-disciplina',
        name: 'Frentes de disciplina',
        component: List,
        exact: true,
        title: 'Frentes de disciplina'
    },
    {
        path: '/app/frentes-de-disciplina/novo',
        name: 'Nova',
        component: CreateOrEdit,
        exact: true,
        title: 'Nova frente'
    },
    {
        path: '/app/frentes-de-disciplina/editar/:id',
        name: 'Editar',
        component: CreateOrEdit,
        exact: true,
        title: 'Editar frente'
    }
];

export default subjectFrontRoutes;
