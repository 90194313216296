import { put, call, takeLatest } from 'redux-saga/effects';
import { Creators as campaignActions, Types as CampaignTypes } from 'store/ducks/campaign';
import { Creators as alertActions } from 'store/ducks/alert';
import history from 'services/history';
import { apiRequest } from '../apiRequest';
import { campaignByPk } from 'utils/queries/campaigns/campaignByPk';
import { graphQLRequest } from '../graphQLRequest';
import { restVerbs } from 'store/sagas/apiRequest';
import { showAlertError } from '../utils/showAlertError';

export function* getCampaignById(action: { type: string; payload: { id: number } }) {
    try {
        const { payload } = action;

        const { data } = yield graphQLRequest(campaignByPk, payload);

        if (!data?.item) {
            throw new Error();
        }

        yield put(campaignActions.getCampaignSuccess(data.item));
    } catch (error) {
        yield put(campaignActions.getCampaignFailure());
        yield put(alertActions.showAlert('Não foi possível encontrar esse registro.', 'danger'));
    }
}

export function* createOrEditCampaign(action: { type: string; payload: { method: restVerbs; endpoint: string; params: any; created: boolean; id_store: number } }) {
    try {
        const { method, endpoint, params, created, id_store } = action.payload;

        const { data } = yield apiRequest(method, endpoint, params);

        yield put(campaignActions.createOrEditCampaignSuccess(data));
        yield put(alertActions.showAlert(`Campanha ${created ? 'criada' : 'alterada'} com sucesso`, 'success'));
        yield call(history.push, { pathname: `/app/lojas/ver/${id_store}/campanhas` });
    } catch (error) {
        yield put(campaignActions.createOrEditCampaignFailure());
        yield showAlertError(error);
    }
}

export default [takeLatest(CampaignTypes.GET_CAMPAIGN_REQUEST, getCampaignById), takeLatest(CampaignTypes.CREATE_OR_EDIT_CAMPAIGN_REQUEST, createOrEditCampaign)];
