import styled from 'styled-components';
import theme from 'config/theme';
import { MediaQueries } from 'assets/settings';
import { LogoContainer } from 'components/logo/styles';

export const AuthContainer = styled.div`
    background: ${theme.colors.secondary};
    background: -webkit-linear-gradient(to right, ${theme.colors.secondary}, ${theme.colors.primary});
    background: linear-gradient(to right, ${theme.colors.secondary}, ${theme.colors.primary});
    display: flex;
    height: 100%;

    ${MediaQueries.SMALL} {
        flex-direction: column;
        justify-content: flex-end;
    }
`;

export const AuthBar = styled.aside`
    align-items: center;
    background: ${theme.colors.white};
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    width: 20%;
    padding: ${theme.spacing.large.all};

    ${LogoContainer} {
        margin-bottom: ${theme.spacing.large.all};
    }

    > form {
        width: 100%;
    }

    ${MediaQueries.SMALL} {
        height: unset;
        max-height: 70%;
        overflow-y: auto;
    }

    ${MediaQueries.TABLET_PORTRAIT} {
        width: 50%;
    }

    ${MediaQueries.TABLET_LANDSCAPE} {
        width: 40%;
    }

    ${MediaQueries.NOTEBOOK} {
        width: 30%;
    }
`;
