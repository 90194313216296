import styled, { css } from 'styled-components';
import theme from 'config/theme';
import hexToRgba from 'utils/hexToRgba';
import { jelly, jelly2, jelly3 } from 'assets/animations';

export type LoaderSize = 'small' | 'medium' | 'large';

export const LoaderWrapper = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    padding: ${theme.spacing.large.all};
`;

export const LoaderBall = styled.div`
    animation: ${jelly} 1.5s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite;
    background: ${hexToRgba(theme.colors.secondary, 0.3)};
    border-radius: 50%;
    position: absolute;
    z-index: 4;

    &:nth-child(2) {
        animation: ${jelly2} 1.5s 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite;
        transform: scale(1.4);
        z-index: 3;
    }

    &:nth-child(3) {
        animation: ${jelly3} 1.5s 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite;
        transform: scale(1.8);
        z-index: 2;
    }
`;

export const LoaderContainer = styled.div<{ size: LoaderSize }>`
    position: relative;

    ${({ size }) => {
        if (size === 'medium') {
            return css`
                height: 40px;
                width: 40px;

                ${LoaderBall} {
                    height: 40px;
                    width: 40px;
                }
            `;
        } else if (size === 'large') {
            return css`
                height: 50px;
                width: 50px;

                ${LoaderBall} {
                    height: 50px;
                    width: 50px;
                }
            `;
        }

        return css`
            height: 30px;
            width: 30px;

            ${LoaderBall} {
                height: 30px;
                width: 30px;
            }
        `;
    }}
`;
