import { lazy } from 'react';

const List = lazy(() => import('pages/crud'));
const VideoPage = lazy(() => import('pages/videos'));
const VideoView = lazy(() => import('pages/videos/view'));

const videosRoutes = [
    {
        path: '/app/videos',
        name: 'Vídeos',
        component: List,
        exact: true,
        title: 'Vídeos'
    },
    {
        path: '/app/videos/novo',
        name: 'Novo',
        component: VideoPage,
        exact: true,
        title: 'Novo vídeo'
    },
    {
        path: '/app/videos/editar/:id',
        name: 'Editar',
        component: VideoPage,
        exact: true,
        title: 'Editar vídeo'
    },
    {
        path: '/app/videos/ver/:id',
        name: 'Ver',
        component: VideoView,
        exact: true,
        title: 'Ver detalhes do vídeo'
    }
];

export default videosRoutes;
