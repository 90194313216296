const createPersonAddress = `mutation ($data: prodigio_person_addresses_insert_input!) {
    address: insert_prodigio_person_addresses_one(object: $data) {
        id
    }
}`;

const getPersonAddresses = `query getPersonAddresses($id: Int!) {
    addresses: prodigio_person_addresses(where: {id_person: {_eq: $id}}) {
        id
        label
        id_person
        id_address
        address {
            city
            complement
            created
            deleted
            id
            neighborhood
            number
            state
            street
            zip_code
            updated
        }
    }
}`;

export { createPersonAddress, getPersonAddresses };
