import { AxiosResponse } from 'axios';
import history from 'services/history';
import { apiRequest } from '../apiRequest';
import api, { headers } from 'services/api';
import mapSagaMethods from '../mapSagaMethods';
import { IToken } from 'utils/interfaces/IToken';
import { call, put, takeLatest } from 'redux-saga/effects';
import { Creators as alertActions } from 'store/ducks/alert';
import { Creators as modalActions } from 'store/ducks/modal';
import { Creators as companyActions } from 'store/ducks/company';
import { Creators as authActions, Types } from 'store/ducks/auth';
import apiMongo from 'services/api-mongo';
import { showAlertError } from '../utils/showAlertError';

// Middleware for Login
function* login(action: any) {
    try {
        const { data }: AxiosResponse<IToken> = yield call(api.post, '/token', action.payload, { ...headers, timeout: 120000 });

        api.defaults.headers.common.Authorization = yield `Bearer ${data.token}`;
        apiMongo.defaults.headers.common.Authorization = yield `Bearer ${data.token}`;

        yield put(authActions.loginSuccess(data));

        if (action.action) {
            yield put(mapSagaMethods[action.action.type](action.action.payload));
            yield put({ type: 'persist/REHYDRATE' });
        }

        yield put(modalActions.closeModal());

        yield call(history.push, { pathname: '/empresas' });
    } catch (error) {
        console.log({ error });

        yield showAlertError(error, 'Ocorreu um erro ao tentar fazer login.');
        yield put(authActions.loginFailure());
    }
}

// Middleware for Logout
function* logout() {
    try {
        delete api.defaults.headers.common.Authorization;
        delete api.defaults.headers.common['X-Brand'];
        delete api.defaults.headers.common['X-Company'];
        delete api.defaults.headers.common['X-Unit'];

        delete apiMongo.defaults.headers.common.Authorization;
        delete apiMongo.defaults.headers.common['X-Brand'];
        delete apiMongo.defaults.headers.common['X-Company'];
        delete apiMongo.defaults.headers.common['X-Unit'];

        yield put(companyActions.clearCompanies());
        yield put(companyActions.clearCompany());
    } catch (error) {
        console.log('error', error);
    } finally {
        yield call(history.push, { pathname: '/' });
    }
}

// GET MENU
function* getMenu(action: any) {
    try {
        const { payload } = action;

        const { data } = yield apiRequest(payload.method, payload.endpoint, payload.body, payload.headers);

        yield put(authActions.getNavbarMenuSuccess(data));

        yield put(modalActions.closeModal());
    } catch (error) {
        console.log('error', error);
        yield put(authActions.getNavbarMenuFailure());
        yield put(authActions.logout());

        yield put(alertActions.showAlert(error?.response?.data?.detail?.message || 'Não foi possível obter o Menu', 'danger'));
    } finally {
        yield call(history.push, { pathname: '/' });
    }
}

// GET MENU
function* getProfile(action: any) {
    try {
        const { data } = yield apiRequest('GET', '/person/profile');

        yield put(authActions.getProfileSuccess(data));
    } catch (error) {
        yield put(authActions.getProfileFailure());
    }
}

export default [takeLatest(Types.LOGIN_REQUEST, login), takeLatest(Types.LOGOUT, logout), takeLatest(Types.GET_NAVBAR_MENU_REQUEST, getMenu), takeLatest(Types.GET_PROFILE_REQUEST, getProfile)];
