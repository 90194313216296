import { lazy } from 'react';

const List = lazy(() => import('pages/crud'));
const SchoolGrade = lazy(() => import('pages/schoolGrade'));
const CreateOrEdit = lazy(() => import('pages/crud/createOrEdit'));

const schoolgradeRoutes = [
    {
        path: '/app/series',
        name: 'Series',
        component: List,
        exact: true,
        title: 'Séries'
    },
    {
        path: '/app/series/novo',
        name: 'Novo',
        component: CreateOrEdit,
        exact: true,
        title: 'Nova Série'
    },
    {
        path: '/app/series/editar/:id',
        name: 'Editar',
        component: CreateOrEdit,
        exact: true,
        title: 'Editar Série'
    },
    {
        path: '/app/series/ver/:id',
        name: 'Visualizar',
        component: SchoolGrade,
        exact: true,
        title: 'Visualizar Série'
    }
];

export default schoolgradeRoutes;
