import { createActions, createReducer } from 'reduxsauce';
import { IStudentState, IStudentActionsCreators, StudentTypes } from './types';

export const { Creators, Types } = createActions<Record<StudentTypes, StudentTypes>, IStudentActionsCreators>({
    createRequest: ['payload'],
    createSuccess: ['payload'],
    createFailure: [],

    editRequest: ['payload'],
    editSuccess: ['payload'],
    editFailure: [],

    getRequest: ['payload'],
    getSuccess: ['payload'],
    getFailure: [],

    deleteStudentRequest: ['payload'],
    deleteStudentSuccess: ['payload'],
    deleteStudentFailure: [],

    createOrEditAddressRequest: ['payload'],
    createOrEditAddressSuccess: ['payload'],
    createOrEditAddressFailure: [],

    deleteAddressRequest: ['payload'],
    deleteAddressSuccess: ['payload'],
    deleteAddressFailure: [],

    enrollStudentRequest: ['payload'],
    enrollStudentSuccess: ['payload'],
    enrollStudentFailure: [],

    getSubjectsRequest: ['payload'],
    getSubjectsSuccess: ['payload'],
    getSubjectsFailure: [],

    getStudentOrdersRequest: ['payload'],
    getStudentOrdersSuccess: ['payload'],
    getStudentOrdersFailure: [],

    createOrEditSubscriptionRequest: ['payload'],
    createOrEditSubscriptionSuccess: ['payload'],
    createOrEditSubscriptionFailure: [],

    cancelSubscriptionRequest: ['payload'],
    cancelSubscriptionSuccess: ['payload'],
    cancelSubscriptionFailure: [],

    clear: []
});

const INITIAL_STATE: IStudentState = {
    isLoading: false,
    isSaved: false,
    isSubjectsLoading: false,
    isOrdersLoading: true,
    created: '',
    id: 0,
    isMigrated: false,
    person: {
        cpf: '',
        id: 0,
        name: '',
        slug: '',
        addresses: [],
        user: {},
        isMigrated: false,
        isTermsAccepted: false
    },
    preRegistrations: [],
    subscriptions: [],
    subjects: [],
    orders: []
};

const getRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true });

const getSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    ...action.payload,
    isLoading: false
});

const getFailure = (state = INITIAL_STATE) => ({ ...state, isLoading: false });

const deleteRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true });

const deleteSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoading: false
});

const deleteFailure = (state = INITIAL_STATE) => ({ ...state, isLoading: false });

const createRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true });

const createSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoading: false,
    isSaved: true
});

const createFailure = (state = INITIAL_STATE) => ({ ...state, ...INITIAL_STATE });

const editRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true });

const editSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoading: false,
    isSaved: true
});

const editFailure = (state = INITIAL_STATE) => ({ ...state, isLoading: false });

const createOrEditAddressRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true });

const createOrEditAddressSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    person: {
        ...state.person,
        addresses: action.payload
    },
    isLoading: false,
    isSaved: true
});

const createOrEditAddressFailure = (state = INITIAL_STATE) => ({ ...state, isLoading: false, isSaved: false });

const deleteAddressRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true });

const deleteAddressSuccess = (state = INITIAL_STATE, action: any) => {
    const { payload } = action;

    if (!state.person.addresses) {
        return {
            ...state,
            isLoading: false,
            isSaved: true
        };
    }

    const updateAddresses = state.person.addresses.filter((item: any) => item.id !== payload.id);

    return {
        ...state,
        isLoading: false,
        isSaved: true,
        person: {
            ...state.person,
            addresses: updateAddresses
        }
    };
};

const deleteAddressFailure = (state = INITIAL_STATE) => ({ ...state, isLoading: false, isSaved: false });

const enrollStudentRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true });

const enrollStudentSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoading: false
});

const enrollStudentFailure = (state = INITIAL_STATE) => ({ ...state, isLoading: false });

const getSubjectsRequest = (state = INITIAL_STATE) => ({ ...state, isSubjectsLoading: true });

const getSubjectsSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isSubjectsLoading: false,
    subjects: action.payload
});

const getSubjectsFailure = (state = INITIAL_STATE) => ({ ...state, isSubjectsLoading: false });

const getStudentOrdersRequest = (state = INITIAL_STATE) => ({ ...state, isOrdersLoading: true });
const getStudentOrdersSuccess = (state = INITIAL_STATE, action: any) => ({ ...state, isOrdersLoading: false, orders: action.payload });
const getStudentOrdersFailure = (state = INITIAL_STATE) => ({ ...state, isOrdersLoading: false });

const createOrEditSubscriptionRequest = (state = INITIAL_STATE, action: any) => ({ ...state, isLoading: true });
const createOrEditSubscriptionSuccess = (state = INITIAL_STATE, action: any) => {
    return { ...state, isLoading: false, subscriptions: [action.payload, ...state.subscriptions] };
};
const createOrEditSubscriptionFailure = (state = INITIAL_STATE) => ({ ...state, isLoading: false });

const cancelSubscriptionRequest = (state = INITIAL_STATE) => ({
    ...state,
    isLoading: true
});
const cancelSubscriptionSuccess = (state = INITIAL_STATE, action: any) => {
    const updateSubscription = state.subscriptions.filter((item: any) => item.id !== action.payload);

    return {
        ...state,
        subscriptions: updateSubscription,
        isLoading: false
    };
};
const cancelSubscriptionFailure = (state = INITIAL_STATE) => ({
    ...state,
    isLoading: false
});

// CLear Duck
const clear = () => INITIAL_STATE;

export default createReducer(INITIAL_STATE, {
    [Types.CREATE_REQUEST]: createRequest,
    [Types.CREATE_SUCCESS]: createSuccess,
    [Types.CREATE_FAILURE]: createFailure,

    [Types.EDIT_REQUEST]: editRequest,
    [Types.EDIT_SUCCESS]: editSuccess,
    [Types.EDIT_FAILURE]: editFailure,

    [Types.GET_REQUEST]: getRequest,
    [Types.GET_SUCCESS]: getSuccess,
    [Types.GET_FAILURE]: getFailure,

    [Types.DELETE_STUDENT_REQUEST]: deleteRequest,
    [Types.DELETE_STUDENT_SUCCESS]: deleteSuccess,
    [Types.DELETE_STUDENT_FAILURE]: deleteFailure,

    [Types.ENROLL_STUDENT_REQUEST]: enrollStudentRequest,
    [Types.ENROLL_STUDENT_SUCCESS]: enrollStudentSuccess,
    [Types.ENROLL_STUDENT_FAILURE]: enrollStudentFailure,

    [Types.CREATE_OR_EDIT_ADDRESS_REQUEST]: createOrEditAddressRequest,
    [Types.CREATE_OR_EDIT_ADDRESS_SUCCESS]: createOrEditAddressSuccess,
    [Types.CREATE_OR_EDIT_ADDRESS_FAILURE]: createOrEditAddressFailure,

    [Types.DELETE_ADDRESS_REQUEST]: deleteAddressRequest,
    [Types.DELETE_ADDRESS_SUCCESS]: deleteAddressSuccess,
    [Types.DELETE_ADDRESS_FAILURE]: deleteAddressFailure,

    [Types.GET_SUBJECTS_REQUEST]: getSubjectsRequest,
    [Types.GET_SUBJECTS_SUCCESS]: getSubjectsSuccess,
    [Types.GET_SUBJECTS_FAILURE]: getSubjectsFailure,

    [Types.GET_STUDENT_ORDERS_REQUEST]: getStudentOrdersRequest,
    [Types.GET_STUDENT_ORDERS_SUCCESS]: getStudentOrdersSuccess,
    [Types.GET_STUDENT_ORDERS_FAILURE]: getStudentOrdersFailure,

    [Types.CREATE_OR_EDIT_SUBSCRIPTION_REQUEST]: createOrEditSubscriptionRequest,
    [Types.CREATE_OR_EDIT_SUBSCRIPTION_SUCCESS]: createOrEditSubscriptionSuccess,
    [Types.CREATE_OR_EDIT_SUBSCRIPTION_FAILURE]: createOrEditSubscriptionFailure,

    [Types.CANCEL_SUBSCRIPTION_REQUEST]: cancelSubscriptionRequest,
    [Types.CANCEL_SUBSCRIPTION_SUCCESS]: cancelSubscriptionSuccess,
    [Types.CANCEL_SUBSCRIPTION_FAILURE]: cancelSubscriptionFailure,

    [Types.CLEAR]: clear
});
