import styled from 'styled-components';
import theme from 'config/theme';
import Button from 'components/button';
import hexToRgba from 'utils/hexToRgba';
import { ButtonContainer } from 'components/button/styles';
import { SlideInLeftSmall, rotate } from 'assets/animations';
// import { AccordionContainer, AccordionTitle } from 'components/accordion/styles';

export const BrandSelectorWrapper = styled.div`
    width: 400px;
`;

export const BrandSelectorName = styled.div`
    flex: 1;
    text-align: left;
    display: flex;
    align-items: center;
`;

export const BrandSelectorItem = styled(Button)`
    align-items: center;
    border-radius: 0;
    color: ${theme.colors.base};
    display: flex;
    font-family: ${theme.typography.family.primary.normal.regular};
    font-size: ${theme.typography.sizes.normal};
    justify-content: space-between;
    padding: ${theme.spacing.medium.all} 10px;
    width: 100%;

    .ico-arrow-right {
        fill: transparent;
        stroke: ${theme.colors.base};
    }

    &:not(:last-child) {
        border-bottom: 1px solid ${hexToRgba(theme.colors.base, 0.3)};
    }

    &:hover {
        background: ${hexToRgba(theme.colors.base, 0.1)};
    }
`;

export const BrandSelectorEmpty = styled.div`
    padding: 20px 0;
    text-align: center;
`;

export const BrandSelectorUnits = styled.div`
    /* padding: 20px; */
    background: rgba(0, 0, 0, 0.03);
`;

export const BrandSelectorAccordionItem = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    background: ${hexToRgba(theme.colors.base, 0.1)};
    padding: 15px;
`;

export const BrandSelectorAccordionText = styled.span`
    margin: 10px 0;
    text-align: center;
`;

export const BrandSelectorSelected = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    /* animation: ${SlideInLeftSmall} 0.3s linear; */

    ${ButtonContainer} {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;

        svg {
            margin-right: 15px;
        }
    }
`;

export const BrandSelectorLoading = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 300px;
    transition: all 0.3s;

    svg {
        animation: ${rotate} 0.25s infinite linear;
        width: 35px;
        height: 35px;
    }
`;
