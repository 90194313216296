import { createActions, createReducer } from 'reduxsauce';
import { ITimezone } from 'utils/interfaces/ITimezone';

export const { Creators, Types } = createActions({
    timezoneRequest: [],
    timezoneSuccess: ['payload'],
    timezoneFailure: ['payload'],
    clearTimezones: []
});

export interface ITimezoneState {
    isLoading: boolean;
    items: ITimezone[];
}

const INITIAL_STATE: ITimezoneState = {
    isLoading: false,
    items: []
};

const timezoneRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true });
const timezoneSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoading: false,
    items: action.payload.items
});

const timezoneFailure = (state = INITIAL_STATE) => ({ ...state, ...INITIAL_STATE });

const clearTimezones = (state = INITIAL_STATE) => ({ ...state, ...INITIAL_STATE });

export default createReducer(INITIAL_STATE, {
    [Types.TIMEZONE_REQUEST]: timezoneRequest,
    [Types.TIMEZONE_SUCCESS]: timezoneSuccess,
    [Types.TIMEZONE_FAILURE]: timezoneFailure,
    [Types.CLEAR_TIMEZONES]: clearTimezones
});
