import { IPaginationCore } from './IPagination';

export interface IStoreCategory {
    id: number;
    name: string;
    slug: string;
}

export interface IStoreAddress {
    storeLocationsId: number;
    addressId: number;
    name: string;
    number: string;
    state: string;
    street: string;
    zipCode: string;
    complement: string;
    city: string;
}

export interface IStoreProduct {
    id: number;
    name: string;
    slug: string;
}

export interface IStore {
    id: number;
    slug?: string;
    name: string;
    url: string;
    isActive: boolean;
    hasShowcase: boolean;
    hasWithdrawal: boolean;
    withDelivery: boolean;
}

export interface IStoreEntity {
    items: any[];
    pagination: IPaginationCore;
}

export interface IProductConfiguration {
    id_product_physical: number;
    id: number;
    name: string;
    options: Array<{
        id: number;
        id_media: number | null;
        id_product_physical_configuration: number;
        name: string;
        sku: string;
    }>;
}

export interface IStoreOrderDetail {
    amount: number;
    id: number;
    quantity: number;
    prices_by_payment_form: {
        id: number;
        price: {
            value: number;
            products: Array<{
                product: {
                    id: number;
                    amount: number | null;
                    name: string;
                    thumb: string;
                };
            }>;
        };
    };
    order_details_physicals: Array<{
        id: number;
        id_product_physical_configuration_option: number;
        products_physical: {
            id: number;
            name: string;
            sku: string;
            medias: Array<{
                media: {
                    url: string;
                    id: number;
                };
            }>;
            configurations: IProductConfiguration[];
        };
    }>;
}

export interface IStoreOrder {
    id: number;
    amount: number;
    boleto_barcode: string;
    boleto_url: string;
    code: string;
    created: string;
    due_date: string;
    original_amount: number;
    pay_date: string;
    addresses: IStoreLocationAddress[];
    installments: number;
    customer: {
        country: string;
        document_number: string;
        email: string;
        id: number;
        name: string;
        last_name: string;
        phone_number: string;
        type: any;
    };
    details: IStoreOrderDetail[];
    payment_form: {
        name: string;
    };
    status: {
        name: OrderStatusesDictionary;
    };
    movimentations: Array<{
        created: string;
        id: number;
        updated: string;
        order_status: {
            name: OrderStatusesDictionary;
        };
    }>;
}

export interface IStoreState extends IStore {
    isLoading: boolean;
    isOrderRefunded: boolean;
    isLoadingEntity: boolean;
    isLoadingOrder: boolean;
    categories: IStoreCategory[];
    products: IStoreProduct[];
    address: IStoreAddress[];
    entity: IStoreEntity;
    order: IStoreOrder;
}

export interface IStoreLocationAddress {
    address_type: OrderAddressType;
    city: string;
    complement: string;
    id: number;
    neighborhood: string;
    number: string;
    state: string;
    street: string;
    updated: string;
    zip_code: string;
}
export interface IStoreLocation {
    name: string;
    id: number;
    address: IStoreLocationAddress;
}

export enum OrderAddressType {
    Financial = 1,
    Shipping = 2
}

export enum OrderStatusesDictionary {
    WaitingPayment = 'Aguardando pagamento',
    UnauthorizedPayment = 'Pagamento não autorizado',
    RefundedPayment = 'Pagamento reembolsado',
    RefusedStatus = 'Recusado',
    Processing = 'Em processamento',
    AuthorizedPayment = 'Pagamento recebido',
    OrderCompleted = 'Pedido finalizado'
}

export enum OrderStatuses {
    WaitingPayment = 'WaitingPayment',
    UnauthorizedPayment = 'UnauthorizedPayment',
    RefundedPayment = 'RefundedPayment',
    RefusedStatus = 'RefusedStatus',
    Processing = 'Processing',
    AuthorizedPayment = 'AuthorizedPayment',
    OrderCompleted = 'OrderCompleted'
}

// ''
//     | ''
//     | ''
//     | ''
//     | ''
//     | ''
//     | ''
