import styled from 'styled-components';
import theme from 'config/theme';
import { MediaQueries } from 'assets/settings';
import { bounceRight } from 'assets/animations';
import lightenDarkenColor from 'utils/lightAndDarkColor';

export const AlertContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: fixed;
    right: 0;
    bottom: 0;
    padding-bottom: 15px;
    z-index: 1000;
`;

export const AlertClose = styled.button`
    .ico-close {
        fill: #fff;
    }
`;

export const AlertWrapper = styled.div<{ type: string }>`
    animation: ${bounceRight} 5s 0s both;
    align-items: center;
    background: ${({ type }) => (type ? theme.colors.system[type] : theme.colors.system.info)};
    border-radius: 5px;
    box-shadow: 2px 2px 3px ${({ type }) => lightenDarkenColor(theme.colors.system[type], -20)};
    bottom: 20px;
    color: ${theme.colors.white};
    display: flex;
    padding: 15px;
    margin: 5px 15px;
    z-index: 9999999;

    ${MediaQueries.SMALL} {
        width: 100%;
    }
`;

export const AlertText = styled.span`
    flex: 1;
    font-family: ${theme.typography.family.primary.light.regular};
    margin-right: ${theme.spacing.large.all};

    ${MediaQueries.SMALL} {
        margin-right: ${theme.spacing.small.all};
    }
`;
