import React from 'react';

// Components
import Icon from 'components/icons/icon';

// Helpers
import { Variant } from 'components/button/styles';

// Assets
import { BadgeContainer, BadgeButton } from './styles';

interface IProps {
    children: any;
    variant: Variant;
    size?: 'small' | 'normal';
    style?: React.CSSProperties;
    onDelete?: () => void;
}

const Badge = ({ children, variant, size = 'normal', style, onDelete }: IProps) => (
    <BadgeContainer variant={variant} size={size} style={style}>
        {children}
        {onDelete && (
            <BadgeButton variant={variant} type="button" onClick={onDelete}>
                <Icon idIcon="ico-close" width="8" height="8" viewBox="0 0 512 512" />
            </BadgeButton>
        )}
    </BadgeContainer>
);

export default Badge;
