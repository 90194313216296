const project = {
    create: `mutation ($data: prodigio_projects_insert_input!) {
        project: insert_prodigio_projects_one(object: $data) {
            id
        }
    }`,
    update: `mutation ($data: prodigio_projects_set_input!, $tags: [prodigio_project_tags_insert_input!]!, $id: Int!) {
        project: update_prodigio_projects_by_pk(pk_columns: { id: $id }, _set: $data) {
            id
        },
        tags: insert_prodigio_project_tags(objects: $tags, on_conflict: { constraint: idx_450771_PRIMARY , update_columns: [deleted] }) {
            affected_rows
        }
    }`
};

const resume = {
    create: `mutation ($data: prodigio_project_resumes_insert_input!) {
        insert_prodigio_project_resumes_one(object: $data) {
            id
        }
    }`,
    update: `mutation ($data: prodigio_project_resumes_set_input!, $id: Int!) {
        update_prodigio_project_resumes_by_pk(pk_columns: { id: $id }, _set: $data) {
            id
        }
    }`,
    delete: `mutation ($date: timestamptz, $id: Int!) {
        update_prodigio_project_resumes_by_pk(pk_columns: { id: $id }, _set: { deleted: $date }) {
          id
        }
      }
    `
};

const projectModule = {
    create: `mutation ($data: prodigio_project_modules_insert_input!) {
        insert_prodigio_project_modules_one(object: $data) {
            id
        }
    }`,
    update: `mutation ($data: prodigio_project_modules_set_input!, $id: Int!) {
        update_prodigio_project_modules_by_pk(pk_columns: { id: $id }, _set: $data) {
            id
        }
    }`,
    delete: `mutation ($date: timestamptz, $id: Int!) {
        update_prodigio_project_modules_by_pk(pk_columns: { id: $id }, _set: { deleted: $date }) {
          id
        }
      }
    `,
    reorder: `mutation ($data: [prodigio_project_modules_insert_input!]!) {
        insert_prodigio_project_modules(objects: $data, on_conflict: { constraint: idx_450740_PRIMARY , update_columns: [order] }) {
            affected_rows
        }
    }`
};

const section = {
    create: `mutation ($data: prodigio_section_modules_insert_input!) {
        insert_prodigio_section_modules_one(object: $data) {
            id
        }
    }`,
    update: `mutation ($data: prodigio_section_modules_set_input!, $id: Int!) {
        update_prodigio_section_modules_by_pk(pk_columns: { id: $id }, _set: $data) {
            id
        }
    }`,
    delete: `mutation ($date: timestamptz, $id: Int!) {
        update_prodigio_section_modules_by_pk(pk_columns: { id: $id }, _set: { deleted: $date }) {
          id
        }
      }
    `,
    reorder: `mutation ($data: [prodigio_section_modules_insert_input!]!) {
        insert_prodigio_section_modules(objects: $data, on_conflict: { constraint: idx_450951_PRIMARY , update_columns: [order] }) {
            affected_rows
        }
    }`,
    content: {
        create: `mutation ($data: prodigio_section_contents_insert_input!) {
            sectionContent: insert_prodigio_section_contents_one(object: $data) {
                id
            }
        }`,
        update: `mutation ($id: Int!, $data: prodigio_section_contents_set_input!) {
            update_prodigio_section_contents_by_pk(pk_columns: { id: $id }, _set: $data) {
                id
            }
        }`,
        delete: `mutation ($date: timestamptz, $id: Int!) {
            update_prodigio_section_contents_by_pk(pk_columns: { id: $id }, _set: { deleted: $date }) {
                id
            }
        }`,
        updateMaterial: `mutation ($id: Int!, $data: prodigio_learning_object_materials_set_input!) {
            update_prodigio_learning_object_materials_by_pk(pk_columns: { id: $id }, _set: $data) {
                id
            }
        }`,
        reorder: `mutation ($data: [prodigio_section_contents_insert_input!]!) {
            insert_prodigio_section_contents(objects: $data, on_conflict: { constraint: idx_450930_PRIMARY , update_columns: [order] }) {
                affected_rows
            }
        }`
    }
};

export { project, section, resume, projectModule };
