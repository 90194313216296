export const storeByPk = `query GetStoreByPk($id: Int!) {
    item: prodigio_stores_by_pk(id: $id) {
      id
      slug
      name
      url
      isActive: is_active
      hasShowcase: has_showcase
      hasWithdrawal: has_withdrawal
      withDelivery: with_delivery
    }
  }
  `;
