import { createActions, createReducer } from 'reduxsauce';
import { IVideoTopic } from 'utils/interfaces/IVideoDoubt';
import { answerQuestionsStatus } from 'utils/enums/answerQuestionsStatus';
import { VideoStatus } from 'utils/enums/videoStatus';

export const { Creators, Types } = createActions({
    searchVideosRequest: ['payload'],
    searchVideosSuccess: ['payload'],
    searchVideosFailure: [],

    getVideoByIdRequest: ['payload'],
    getVideoByIdSuccess: ['payload'],
    getVideoByIdFailure: [],

    createOrEditVideoRequest: ['payload'],
    createOrEditVideoSuccess: ['payload'],
    createOrEditVideoFailure: [],

    associateVideoQrCodeRequest: ['payload'],
    associateVideoQrCodeSuccess: ['payload'],
    associateVideoQrCodeFailure: [],

    getTopicRequest: ['payload'],
    getTopicSuccess: ['payload'],
    getTopicFailure: [],

    activateOrDeactivateTopic: ['payload'],
    activateOrDeactivateTopicSuccess: ['payload'],
    activateOrDeactivateTopicFailure: [],

    clearVideos: []
});

export interface IVideoState {
    isLoading: boolean;
    type: number;
    items: [];
    id: number;
    code: number;
    description: string;
    videoKey: string;
    url: string;
    videoHash: string;
    provider: number;
    title: string;
    learningObject: any;
    teachers: any[];
    videoEmbed: string;
    subject?: any;
    metadata?: any;
    totalViews?: number;
    qrCode?: {
        id: number;
        codeImageUrl: string;
        code: string;
    };
    doubt: IVideoTopic;
    status?: VideoStatus;
}

const INITIAL_STATE: IVideoState = {
    isLoading: false,
    items: [],
    id: 0,
    code: 0,
    description: '',
    videoKey: '',
    url: '',
    videoHash: '',
    provider: 0,
    title: '',
    learningObject: {},
    teachers: [],
    videoEmbed: '',
    type: 0,
    doubt: {
        id: '',
        isCreated: false,
        status: answerQuestionsStatus.Desativado
    }
};

// GENERIC ACTIONS

const associateQrCode = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoading: false,
    qrCode: action.payload
});

const videosRequest = (state = INITIAL_STATE, action: any) => ({ ...state, isLoading: true });
const videosRequestSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoading: false,
    ...action.payload
});
const videosRequestFailure = (state = INITIAL_STATE) => INITIAL_STATE;

const getTopicRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true });
const getTopicSuccess = (state = INITIAL_STATE, action: any) => ({ ...state, isLoading: false, doubt: action.payload });
const getTopicFailure = (state = INITIAL_STATE) => ({ ...state, isLoading: false });

const activateOrDeactivateTopic = (state = INITIAL_STATE) => ({ ...state, isLoading: true });
const activateOrDeactivateTopicSuccess = (state = INITIAL_STATE, action: any) => ({ ...state, isLoading: false, doubt: action.payload });
const activateOrDeactivateTopicFailure = (state = INITIAL_STATE) => ({ ...state, isLoading: false });

const clearVideos = (state = INITIAL_STATE) => INITIAL_STATE;

export default createReducer(INITIAL_STATE, {
    [Types.GET_VIDEO_BY_ID_REQUEST]: videosRequest,
    [Types.GET_VIDEO_BY_ID_SUCCESS]: videosRequestSuccess,
    [Types.GET_VIDEO_BY_ID_FAILURE]: videosRequestFailure,

    [Types.SEARCH_VIDEOS_REQUEST]: videosRequest,
    [Types.SEARCH_VIDEOS_SUCCESS]: videosRequestSuccess,
    [Types.SEARCH_VIDEOS_FAILURE]: videosRequestFailure,

    [Types.CREATE_OR_EDIT_VIDEO_REQUEST]: videosRequest,
    [Types.CREATE_OR_EDIT_VIDEO_SUCCESS]: videosRequestSuccess,
    [Types.CREATE_OR_EDIT_VIDEO_FAILURE]: videosRequestFailure,

    [Types.ASSOCIATE_VIDEO_QR_CODE_REQUEST]: videosRequest,
    [Types.ASSOCIATE_VIDEO_QR_CODE_SUCCESS]: associateQrCode,
    [Types.ASSOCIATE_VIDEO_QR_CODE_FAILURE]: videosRequestFailure,

    [Types.GET_TOPIC_REQUEST]: getTopicRequest,
    [Types.GET_TOPIC_SUCCESS]: getTopicSuccess,
    [Types.GET_TOPIC_FAILURE]: getTopicFailure,

    [Types.ACTIVATE_OR_DEACTIVATE_TOPIC]: activateOrDeactivateTopic,
    [Types.ACTIVATE_OR_DEACTIVATE_TOPIC_SUCCESS]: activateOrDeactivateTopicSuccess,
    [Types.ACTIVATE_OR_DEACTIVATE_TOPIC_FAILURE]: activateOrDeactivateTopicFailure,

    [Types.CLEAR_VIDEOS]: clearVideos
});
