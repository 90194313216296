import { put } from 'redux-saga/effects';
import { apiRequest } from '../apiRequest';

// Redux
import { Creators as learningObjectActions } from 'store/ducks/learningObject';
import { Creators as courseActions } from 'store/ducks/course';
import { Creators as lessonPlanEventsActions } from 'store/ducks/lessonPlanEvents';
import { Creators as alertActions } from 'store/ducks/alert';
import { Creators as questionsActions } from 'store/ducks/questions';
import { Creators as modalActions } from 'store/ducks/modal';

export function* createLOVideoCourse(action: any, data: any) {
    try {
        const createBookletContentItemPayload = [
            {
                module: action.idModule,
                learningObject: data.learningObject.id,
                order: action.newOrder,
                data
            }
        ];

        yield put(courseActions.createBookletContentItemRequest(createBookletContentItemPayload));
        yield put(modalActions.closeModal());
    } catch (error) {
        console.log('error');
    }
}

export function* createLOVideoLessonPlanEvents(action: any, data: any) {
    try {
        yield put(learningObjectActions.createLOVideoSuccess(data));
        yield put(
            lessonPlanEventsActions.createOrEditLessonPlanEventsContentRequest({
                event: action.event,
                content: data
            })
        );
    } catch (error) {
        console.log('error');
    }
}

export function* createLOVideoLessonPlanEventsMainVideo(action: any) {
    try {
        const videoId = action.data.id;
        const formatPayloadVideo = action.data;
        delete formatPayloadVideo.id;

        const formatPayload = {
            ...formatPayloadVideo,
            teachers: formatPayloadVideo.teachers.map((teacher: any) => ({
                id: teacher.value
            }))
        };

        const { data } = yield apiRequest(!!videoId ? 'PATCH' : 'POST', `/admin/video/${!!videoId ? videoId : ''}`, formatPayload, { 'X-Relations': 'learningObject' });

        if (videoId === undefined) {
            yield apiRequest('PATCH', `/lessonplanevent/${action.event}`, { video: { id: data.id } });
        }

        // Chama o HandShake do video novamente
        yield put(lessonPlanEventsActions.getHandShakeLessonPlanEventsRequest({ event: action.event, video: data }));

        yield put(alertActions.showAlert('Video Principal Atualizado!', 'success'));

        yield put(modalActions.closeModal());
    } catch (error) {
        console.log('error');
    }
}

export function* createLOVideoQuestionResolution(action: any, data: any) {
    try {
        yield put(questionsActions.createQuestionsResolutionRequest(data));
    } catch (error) {
        console.log('error');
    }
}
