// sampleReducer.js
import { createReducer, createActions } from 'reduxsauce';
import { ComplaintTypes, IComplaintActionsCreators, IComplaintState } from './types';

export const { Creators, Types } = createActions<Record<ComplaintTypes, ComplaintTypes>, IComplaintActionsCreators>({
    getComplaintByIdRequest: ['payload'],
    getComplaintByIdSuccess: ['payload'],
    getComplaintByIdFailure: [],

    updateComplaintRequest: ['payload'],
    updateComplaintSuccess: [],
    updateComplaintFailure: [],

    clearComplaint: []
});

const INITIAL_STATE: IComplaintState = {
    error: false,
    loading: false
};

const request = (state = INITIAL_STATE) => {
    return { ...state, error: false, loading: true };
};

const success = (state = INITIAL_STATE, action?: any) => {
    return { ...state, error: false, loading: false, ...action?.payload };
};

const failure = (state = INITIAL_STATE) => {
    return { ...state, error: true, loading: false };
};

const clear = () => INITIAL_STATE;

const HANDLERS = {
    [Types.GET_COMPLAINT_BY_ID_REQUEST]: request,
    [Types.GET_COMPLAINT_BY_ID_SUCCESS]: success,
    [Types.GET_COMPLAINT_BY_ID_FAILURE]: failure,

    [Types.UPDATE_COMPLAINT_REQUEST]: request,
    [Types.UPDATE_COMPLAINT_SUCCESS]: success,
    [Types.UPDATE_COMPLAINT_FAILURE]: failure,

    [Types.CLEAR_COMPLAINT]: clear
};

export default createReducer(INITIAL_STATE, HANDLERS);
