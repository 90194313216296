import React, { useState, useCallback } from 'react';

import * as S from './styles';
import Button from 'components/button';
import Dropdown from 'components/dropdown';
import Icon from 'components/icons/icon';
import { useAlert } from 'hooks/useAlert';
import api from 'services/api';

export enum QRCodeFileType {
    SVG = 'SVG',
    EPS = 'EPS'
}

interface IProps {
    code?: string;
    group?: string;
    svgImage?: string;
    size?: 'small' | 'large';
}

const DownloadQRCode: React.FC<IProps> = ({ code, svgImage, size, group }) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const [loading, setLoading] = useState(false);
    const [fileType, setFileType] = useState(QRCodeFileType.SVG);

    const alert = useAlert();

    const handleSelect = useCallback((type: QRCodeFileType) => {
        setFileType(type);
        setShowDropdown(false);
    }, []);

    const forceClick = useCallback(
        (url: any) => {
            const fakeElement = document.createElement('a');
            fakeElement.href = url;
            fakeElement.download = `${code}.${fileType}` || '';
            fakeElement.textContent = code || '';
            fakeElement.target = '_blank';

            fakeElement.click();
        },
        [code, fileType]
    );

    const handleDownloadIMage = useCallback(async () => {
        try {
            setLoading(true);

            if (fileType === QRCodeFileType.EPS) {
                await api.get(`/admin/qrcode/${code}/eps`, { responseType: 'arraybuffer' }).then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    forceClick(url);
                });

                return;
            }

            forceClick(svgImage);
        } catch (error) {
            alert.show('Ocorreu um erro. Tente novamente.', 'danger');
        } finally {
            setLoading(false);
        }
    }, [alert, code, fileType, forceClick, svgImage]);

    const handleDownloadGroup = useCallback(async () => {
        try {
            setLoading(true);

            const endpoint = fileType === QRCodeFileType.EPS ? `/admin/qrcode/download/${group}/eps` : `/admin/qrcode/download/${group}`;

            const response = await api({ url: endpoint, method: 'GET', responseType: 'blob' });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');

            link.href = url;
            link.setAttribute('download', 'qrcodes.zip');

            document.body.appendChild(link);
            link.click();
        } catch (error) {
            alert.show('Ocorreu um erro. Tente novamente.', 'danger');
        } finally {
            setLoading(false);
        }
    }, [alert, fileType, group]);

    return (
        <S.DownloadQRCodeContainer>
            <Button type="button" variant="info" block={true} onClick={() => (!!group ? handleDownloadGroup() : handleDownloadIMage())} isLoading={loading} size={size}>
                Download
            </Button>

            <S.DownloadQRCodeSelect>
                <Button type="button" variant="info" onClick={() => setShowDropdown((old) => !old)} block={true} size={size}>
                    {fileType}
                    <Icon idIcon="ico-arrow-down" width="12" height="6" viewBox="0 0 10 6" />
                </Button>

                <Dropdown
                    isOpen={showDropdown}
                    onClickOut={() => setShowDropdown(false)}
                    items={[
                        {
                            name: 'SVG',
                            action: () => handleSelect(QRCodeFileType.SVG)
                        },
                        {
                            name: 'EPS',
                            action: () => handleSelect(QRCodeFileType.EPS)
                        }
                    ]}
                />
            </S.DownloadQRCodeSelect>
        </S.DownloadQRCodeContainer>
    );
};

export default DownloadQRCode;
