import { put } from 'redux-saga/effects';
import { Creators as modalActions } from 'store/ducks/modal';
import { Creators as alertActions } from 'store/ducks/alert';
import { apiRequest } from '../apiRequest';

export function* openModalRequest(action: any) {
    try {
        const { method, endpoint, data, content, origin } = action.action;

        const result = yield apiRequest(method, endpoint, data);

        if (origin === 'lessonPlanSelectDay') {
            return yield put(
                modalActions.openModalSuccess(action.element, {
                    ...content,
                    video: result.data
                })
            );
        }

        yield put(modalActions.openModalSuccess(action.element, result.data));
    } catch (error) {
        yield put(modalActions.openModalFailure());

        yield put(alertActions.showAlert(error?.response?.data?.detail?.message || 'Ocorreu um erro. Tente novamente mais tarde.', 'danger'));
    }
}
