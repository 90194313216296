import { createActions, createReducer } from 'reduxsauce';

export const { Creators, Types } = createActions({
    getMenuRequest: ['payload'],
    getMenuSuccess: ['payload'],
    getMenuFailure: [],

    createOrEditMenuRequest: ['payload'],
    createOrEditMenuSuccess: ['payload'],
    createOrEditMenuFailure: [],

    clearMenu: []
});

export interface IMenuState {
    isLoading: boolean;
    name: string;
    icon: string;
    menus: any[];
    type: number;
    company?: any;
}

const INITIAL_STATE: IMenuState = {
    isLoading: false,
    name: '',
    icon: '',
    menus: [],
    type: 1
};

const getMenuRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true });

const getMenuSuccess = (state = INITIAL_STATE, action: any) => ({
    isLoading: false,
    ...action.payload
});

const getMenuFailure = (state = INITIAL_STATE) => INITIAL_STATE;

const createOrEditMenuRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true });

const createOrEditMenuSuccess = (state = INITIAL_STATE, action: any) => ({
    isLoading: false,
    ...action.payload
});

const createOrEditMenuFailure = (state = INITIAL_STATE) => ({ ...state, isLoading: false });

// CLEAR
const clearMenu = (state = INITIAL_STATE) => INITIAL_STATE;

export default createReducer(INITIAL_STATE, {
    [Types.GET_MENU_REQUEST]: getMenuRequest,
    [Types.GET_MENU_SUCCESS]: getMenuSuccess,
    [Types.GET_MENU_FAILURE]: getMenuFailure,

    [Types.CREATE_OR_EDIT_MENU_REQUEST]: createOrEditMenuRequest,
    [Types.CREATE_OR_EDIT_MENU_SUCCESS]: createOrEditMenuSuccess,
    [Types.CREATE_OR_EDIT_MENU_FAILURE]: createOrEditMenuFailure,

    [Types.CLEAR_MENU]: clearMenu
});
