import { lazy } from 'react';

const List = lazy(() => import('pages/crud'));
const Revise = lazy(() => import('pages/answerCard'));
const ReviseViewPage = lazy(() => import('pages/answerCard/view'));

const answerCardRoutes = [
    {
        path: '/app/listas-de-exercicios-extras/correcoes',
        name: 'Correções',
        component: List,
        exact: true,
        title: 'Correções'
    },
    {
        path: '/app/listas-de-exercicios-extras/correcoes/ver/:id',
        name: 'Ver',
        component: ReviseViewPage,
        exact: true,
        title: 'Ver correção'
    },
    {
        path: '/app/listas-de-exercicios-extras/correcoes/editar/:id',
        name: 'Corrigir',
        component: Revise,
        exact: true,
        title: 'Corrigir'
    }
];

export default answerCardRoutes;
