import { lazy } from 'react';

const List = lazy(() => import('pages/crud'));
const CreateOrEdit = lazy(() => import('pages/crud/createOrEdit'));

const pagesRoutes = [
    {
        path: '/app/paginas',
        name: 'Páginas',
        component: List,
        exact: true,
        title: 'Páginas'
    },
    {
        path: '/app/paginas/novo',
        name: 'Nova Página',
        component: CreateOrEdit,
        exact: true,
        title: 'Nova Página'
    },
    {
        path: '/app/paginas/editar/:id',
        name: 'Editar',
        component: CreateOrEdit,
        exact: true,
        title: 'Editar Página'
    }
];

export default pagesRoutes;
