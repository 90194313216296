import styled from 'styled-components';
import theme from 'config/theme';
import { Container } from 'assets/global';
import { TooltipContainer } from 'components/tooltip/styles';

export const PageHeaderContainer = styled.div`
    margin-bottom: ${theme.spacing.large.all};

    ${Container} {
        align-items: center;
        display: flex;
        justify-content: space-between;
    }
`;

export const PageHeaderLeft = styled.div`
    align-items: center;
    display: flex;
    flex: 1;
`;

export const ButtonWrapper = styled.div`
    margin-left: ${theme.spacing.medium.all};
    position: relative;
`;

export const PageHeaderTitle = styled.div`
    position: relative;

    :hover {
        ${TooltipContainer} {
            visibility: visible;
            opacity: 1;
            max-width: 100%;
            white-space: break-spaces;
            z-index: 999;
            font-size: 1rem;
            line-height: 1.3rem;
            padding: 15px;
        }
    }
`;
