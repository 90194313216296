import { lazy } from 'react';

const List = lazy(() => import('pages/crud'));
const SchoolUnit = lazy(() => import('pages/schoolunit'));

const schoolunitRoutes = [
    {
        path: '/app/unidades-de-escola',
        name: 'Unidades de Escola',
        component: List,
        exact: true,
        title: 'Unidades de Escola'
    },
    {
        path: '/app/unidades-de-escola/novo',
        name: 'Nova',
        component: SchoolUnit,
        exact: true,
        title: 'Nova Unidade de Escola'
    },
    {
        path: '/app/unidades-de-escola/editar/:id',
        name: 'Editar',
        component: SchoolUnit,
        exact: true,
        title: 'Editar Unidade de Escola'
    }
];

export default schoolunitRoutes;
