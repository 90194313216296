import { put, call, select, takeLatest } from 'redux-saga/effects';
import { Creators as questionsActions } from 'store/ducks/questions';
import { Creators as alertActions } from 'store/ducks/alert';
import { Creators as exerciseListActions } from 'store/ducks/exerciseList';
import { Creators as modalActions } from 'store/ducks/modal';
import { apiRequest } from '../apiRequest';
import history from 'services/history';
import { IReduxStore } from 'utils/interfaces/IReduxStore';
import { questionDifficultOptions } from 'utils/formatQuestionDifficults';
import { IRequestAction } from 'utils/interfaces/IRequestAction';
import HttpStatus from 'utils/enums/httpStatus';
import { showAlertError } from '../utils/showAlertError';
import { Types as QuestionsTypes } from 'store/ducks/questions';
import { IReduxAction } from 'store/types/IRedux';
import { IChangeSelectTag } from 'utils/interfaces/IQuestion';
import { graphQLRequest } from '../graphQLRequest';

const globalState = (state: IReduxStore): IReduxStore => state;

function* getExamId() {
    return yield select(({ questions }: IReduxStore) => questions?.id);
}

function formatData(data: any) {
    const levelOption = !!data.metadata && questionDifficultOptions.find((item) => item.value === data.metadata.level);
    const formatData = {
        ...data,
        metadata: !!data.metadata
            ? {
                  ...data.metadata,
                  level: levelOption,
                  subject: !!data.metadata.subject
                      ? {
                            value: data.metadata.subject.id,
                            label: data.metadata.subject.name
                        }
                      : null,
                  contest: !!data.metadata.contest
                      ? {
                            value: data.metadata.contest.id,
                            label: data.metadata.contest.name
                        }
                      : null,
                  topics: !!data.metadata.topics
                      ? {
                            value: data.metadata.topics.id,
                            label: data.metadata.topics.name
                        }
                      : null,
                  subTopic: !!data.metadata.subTopic
                      ? {
                            value: data.metadata.subTopic.id,
                            label: data.metadata.subTopic.name
                        }
                      : null,
                  subjectFront: !!data.metadata.subjectFront
                      ? {
                            value: data.metadata.subjectFront.id,
                            label: data.metadata.subjectFront.title
                        }
                      : null
              }
            : null
    };

    return formatData;
}

function fomartRequest(data: any) {
    const formatAlternatives =
        !!data.alternatives && data.alternatives.length > 0
            ? data.alternatives.map((alternative: any) => {
                  if (alternative.id < 1) {
                      delete alternative.id;
                  }

                  return alternative;
              })
            : [];

    const formatResolutions =
        !!data.resolutions && data.resolutions.length > 0 ? data.resolutions.map((resolution: any) => ({ id: (resolution.learningObject && resolution.learningObject.id) || resolution.id })) : [];
    const fomartRequest = {
        ...data,
        alternatives: formatAlternatives,
        resolutions: formatResolutions,
        questionType: data.questionType.value,
        metadata: !!data.metadata
            ? {
                  ...data.metadata,
                  subject: !!data.metadata.subject ? data.metadata.subject.value : null,
                  contest: !!data.metadata.contest ? data.metadata.contest.value : null,
                  topics: !!data.metadata.topics ? data.metadata.topics.value : null,
                  subTopic: !!data.metadata.subTopic ? data.metadata.subTopic.value : null,
                  subjectFront: !!data.metadata.subjectFront ? data.metadata.subjectFront.value : null
              }
            : null
    };

    return fomartRequest;
}

function* getQuestionsById(action: any) {
    try {
        const { data } = yield apiRequest('GET', `/questionlo/${action.payload}`, null, { 'X-Relations': 'resolutions.material;resolutions.video' });

        yield put(questionsActions.getQuestionsSuccess(formatData(data)));
    } catch (error) {
        yield put(questionsActions.getQuestionsFailure({ code: error?.response?.status }));
        yield showAlertError(error);
    }
}

function* createOrEditQuestions(action: any) {
    try {
        const { body, endpoint, method, headers, onSubmit, tags } = action.payload;

        const fomatRequestData = fomartRequest(body);

        const { data } = yield apiRequest(method, endpoint, fomatRequestData, headers);

        const formatResult = {
            ...body,
            ...data
        };

        yield put(questionsActions.createOrEditQuestionsSuccess(formatResult));

        // Callback to question form component
        if (onSubmit) {
            onSubmit(formatResult);
        }

        // Adiciona lógica na criação de questão para uma lista de exercícios
        if (!!action.payload.redirectParams) {
            const { idExerciseList, order, exerciseListType } = action.payload.redirectParams;
            const payload = {
                idExerciseList,
                exerciseListType,
                questions: [
                    {
                        exerciseList: idExerciseList,
                        question: data.id,
                        order
                    }
                ]
            };

            return yield put(exerciseListActions.createExerciseListItemRequest(payload));
        }

        yield put(alertActions.showAlert(`Questão ${method === 'POST' ? 'Criada' : 'Atualizada'}!`, 'success'));
        // yield put(questionsActions.getQuestionsRequest(body.idQuestion));

        if (!!tags?.length) {
            yield put(questionsActions.updateQuestionTagsRequest(tags));
        }

        if (method === 'POST' && !onSubmit) {
            yield call(history.push, { pathname: `/app/questoes/editar/${data.id}` });
        }
    } catch (error) {
        console.log('error', error);

        yield put(questionsActions.createOrEditQuestionsFailure());

        if (error?.response?.data?.status === HttpStatus.CONFLICT) {
            const msg = error?.response?.data?.detail;

            return yield put(modalActions.openModal('questionConflict', { msg, questionId: action?.payload?.body?.idQuestion, bodyUpdate: action?.payload?.body }));
        }

        yield showAlertError(error);
    }
}

function* createOrEditQuestionsAlternative(action: any) {
    try {
        const { payload } = action;

        const formatAlternative = {
            id: Math.random(), // Esse é um id provisório usado enquanto a alternativa ainda nao foi adicionada ao banco
            ...payload.body,
            isCorrection: !!payload.body.isCorrection ? 1 : 0
        };

        yield put(questionsActions.createOrEditQuestionsAlternativeSuccess(formatAlternative));

        yield put(modalActions.closeModal());

        yield put(alertActions.showAlert(`Alternativa ${payload.method === 'POST' ? 'Criada' : 'Atualizada'}!`, 'success'));
    } catch (error) {
        console.log(error);
        yield put(questionsActions.createOrEditQuestionsAlternativeFailure());
        yield showAlertError(error);
    }
}

function* deleteQuestionsAlternative(action: any) {
    try {
        const { payload } = action;

        yield put(questionsActions.deleteQuestionsAlternativeSuccess(payload.body));
        yield put(modalActions.closeModal());
        yield put(alertActions.showAlert('Alternativa Excluida!', 'success'));
    } catch (error) {
        yield put(questionsActions.deleteQuestionsAlternativeFailure());
        yield showAlertError(error);
    }
}

function* createQuestionsResolution(action: any) {
    try {
        const { payload } = action;

        const store = yield select(globalState);

        yield put(questionsActions.createQuestionsResolutionSuccess([...store.questions.resolutions, payload]));

        yield put(alertActions.showAlert('Resolução Adicionada!', 'success'));

        yield put(modalActions.closeModal());
    } catch (error) {
        console.log(error);
        yield put(questionsActions.createQuestionsResolutionFailure());
        yield showAlertError(error);
    }
}

function* deleteQuestionsResolution(action: any) {
    try {
        const { payload } = action;

        yield put(questionsActions.deleteQuestionsResolutionSuccess(payload.body));

        yield put(modalActions.closeModal());

        yield put(alertActions.showAlert('Resolução Excluida!', 'success'));
    } catch (error) {
        yield put(questionsActions.deleteQuestionsResolutionFailure());
        yield showAlertError(error);
    }
}

function* associateQuestionQrCode(action: IRequestAction<any>) {
    try {
        const { payload } = action;

        const { data } = yield apiRequest(payload.method, payload.endpoint, payload.body, payload.headers);

        yield put(questionsActions.associateQuestionQrCodeSuccess(data));

        yield put(modalActions.closeModal());

        yield put(alertActions.showAlert('Qr Code associado!', 'success'));
    } catch (error) {
        yield put(questionsActions.associateQuestionQrCodeFailure());
        yield showAlertError(error);
    }
}

function* bulkCancel(action: { type: string; payload: { questionId: number; bodyUpdate: object; items: number[] } }) {
    try {
        const { questionId, bodyUpdate, items } = action.payload;

        if (!questionId) {
            throw new Error();
        }

        yield apiRequest('PATCH', `/admin/exerciselistitem/bulkcancel`, items);

        yield put(modalActions.closeModal());

        yield put(questionsActions.bulkCancelQuestionSuccess());

        const payloadUpdate = {
            method: 'PATCH',
            endpoint: `/questionlo/${questionId}`,
            body: bodyUpdate,
            headers: {
                'X-Relations': 'learningObject;resolutions;resolutions.material;resolutions.video'
            }
        };

        yield put(questionsActions.createOrEditQuestionsRequest(payloadUpdate));
    } catch (error) {
        yield put(questionsActions.bulkCancelQuestionFailure());

        yield showAlertError(error);
    }
}

function* createTags(tags: IChangeSelectTag[]) {
    const questionId = yield getExamId();

    const newTags = tags?.filter(({ type }) => type === 'new')?.map(({ value }) => ({ id_tag: value, id_question: questionId })) || [];

    if (!newTags?.length) {
        return;
    }

    const { data } = yield graphQLRequest(
        `mutation ($body: [prodigio_questions_tags_insert_input!]!) {
            items: insert_prodigio_questions_tags(objects: $body) {
              affected_rows
            }
          }
          `,
        {
            body: newTags
        }
    );

    if (!data?.items) {
        throw new Error();
    }
}

function* deleteTags(tags: IChangeSelectTag[]) {
    const questionId = yield getExamId();

    const deletedTags = tags?.filter(({ type }) => type === 'deleted')?.map(({ value }) => value) || [];

    if (!deletedTags?.length) {
        return;
    }

    const { data } = yield graphQLRequest(
        `mutation ($where: prodigio_questions_tags_bool_exp!) {
            items: delete_prodigio_questions_tags(where: $where) {
              affected_rows
            }
          }`,
        {
            where: {
                id_question: {
                    _eq: questionId
                },
                id_tag: {
                    _in: deletedTags
                }
            }
        }
    );

    if (!data?.items) {
        throw new Error();
    }
}

function* updateTags(action: IReduxAction<IChangeSelectTag[]>) {
    try {
        const tags = action.payload;

        yield createTags(tags);
        yield deleteTags(tags);

        yield put(questionsActions.updateQuestionTagsSuccess());
        yield put(alertActions.showAlert('Lista de tags atualizada com sucesso.', 'success'));
    } catch (error) {
        console.log({ error });

        yield put(alertActions.showAlert('Ocorreu um erro ao atualizar a lista de tags da questão.', 'danger'));

        yield put(questionsActions.updateQuestionTagsFailure());
    }
}

export default [
    takeLatest(QuestionsTypes.GET_QUESTIONS_REQUEST, getQuestionsById),
    takeLatest(QuestionsTypes.CREATE_OR_EDIT_QUESTIONS_REQUEST, createOrEditQuestions),
    takeLatest(QuestionsTypes.CREATE_OR_EDIT_QUESTIONS_ALTERNATIVE_REQUEST, createOrEditQuestionsAlternative),
    takeLatest(QuestionsTypes.DELETE_QUESTIONS_ALTERNATIVE_REQUEST, deleteQuestionsAlternative),
    takeLatest(QuestionsTypes.CREATE_QUESTIONS_RESOLUTION_REQUEST, createQuestionsResolution),
    takeLatest(QuestionsTypes.DELETE_QUESTIONS_RESOLUTION_REQUEST, deleteQuestionsResolution),
    takeLatest(QuestionsTypes.ASSOCIATE_QUESTION_QR_CODE_REQUEST, associateQuestionQrCode),
    takeLatest(QuestionsTypes.BULK_CANCEL_QUESTION_REQUEST, bulkCancel),
    takeLatest(QuestionsTypes.UPDATE_QUESTION_TAGS_REQUEST, updateTags)
];
