import { put } from 'redux-saga/effects';
import { Creators as alertActions } from 'store/ducks/alert';

export function* showAlert(action: any) {
    try {
        const alertId = Math.random();

        const formatPayload = {
            alertType: action.alertType,
            message: action.message,
            id: alertId
        };

        yield put(alertActions.showAlertSuccess(formatPayload));
    } catch (error) {
        console.log('error: ', error);
    }
}
