import { createActions, createReducer } from 'reduxsauce';
import { IVideo } from 'utils/interfaces/ILearningObject';

export const { Creators, Types } = createActions({
    getLessonPlanEventsRequest: ['payload'],
    getLessonPlanEventsSuccess: ['payload'],
    getLessonPlanEventsFailure: [],

    getLessonPlanEventByIdRequest: ['payload'],
    getLessonPlanEventByIdSuccess: ['payload'],
    getLessonPlanEventByIdFailure: [],

    createOrEditLessonPlanEventsRequest: ['payload'],
    createOrEditLessonPlanEventsSuccess: ['payload'],
    createOrEditLessonPlanEventsFailure: [],

    createOrEditLessonPlanEventDetailRequest: ['payload'],
    createOrEditLessonPlanEventDetailSuccess: ['payload'],
    createOrEditLessonPlanEventDetailFailure: [],

    deleteLessonPlanEventsRequest: ['payload'],
    deleteLessonPlanEventsSuccess: ['payload'],
    deleteLessonPlanEventsFailure: [],

    getHandShakeLessonPlanEventsRequest: ['payload'],
    getHandShakeLessonPlanEventsSuccess: ['payload'],
    getHandShakeLessonPlanEventsFailure: [],

    createOrEditLessonPlanEventsContentRequest: ['payload'],
    createOrEditLessonPlanEventsContentSuccess: ['payload'],
    createOrEditLessonPlanEventsContentFailure: [],

    deleteLessonPlanEventsContentRequest: ['payload'],
    deleteLessonPlanEventsContentSuccess: ['payload'],
    deleteLessonPlanEventsContentFailure: [],

    setCurrentLessonPlanEvent: ['payload'],

    setCurrentLessonPlanEventCreateVideo: ['payload'],

    clearLessonPlanEvents: [],
    clearCurrentLessonPlanEvent: [],

    createLessonPlanEventVideoRequest: ['payload'],
    createLessonPlanEventVideoSuccess: ['payload'],
    createLessonPlanEventVideoFailure: []
});

export interface ILessonPlanEventTag {
    id: number;
    name: string;
    color?: string;
}

export interface IEventLessonPlanModuleModule {
    id: number;
    name: string;
    deleted?: string;
    start_date?: string;
    end_date?: string;
    lesson_plan?: {
        id: number;
        name: string;
    };
}
export interface IEventLessonPlanModule {
    tag?: ILessonPlanEventTag;
    module: IEventLessonPlanModuleModule;
}

export interface IEventBookletModule {
    booklet_module?: {
        id: number;
        title: string;
        course?: {
            id: number;
            name: string;
        };
    };
}

export interface IEventTeacher {
    teacher?: {
        id: number;
        person?: {
            name: string;
        };
    };
}
export interface IEvent {
    id: number;
    title: string;
    category: number;

    video?: IVideo;

    // New

    tag?: ILessonPlanEventTag;
    lesson_plan_events_modules?: IEventLessonPlanModule[];
    start_date?: string;
    end_date?: string;
    booklet_modules?: IEventBookletModule[];
    teachers: IEventTeacher[];
    has_forum: boolean;
    is_available: boolean;

    // OLD
    contentPerType?: any;
}

export interface ILessonPlanEventsState extends IEvent {
    isLoading: boolean;
    items: IEvent[];
    currentEvent: IEvent;
    activeCreateVideo: boolean;
}

const CURRENT_INITIAL_STATE: IEvent = {
    booklet_modules: [],
    category: 0,
    has_forum: false,
    id: 0,
    teachers: [],
    title: '',
    is_available: false,
    lesson_plan_events_modules: []
};

const INITIAL_STATE: ILessonPlanEventsState = {
    isLoading: false,
    items: [],
    id: 0,
    title: '',
    category: -1,
    teachers: [],
    booklet_modules: [],
    has_forum: false,
    currentEvent: CURRENT_INITIAL_STATE,
    activeCreateVideo: false,
    is_available: false
};

// GET ALL
const getLessonPlanEventsSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoading: false,
    items: action.payload
});
const getLessonPlanEventsFailure = (state = INITIAL_STATE) => ({ ...state, ...INITIAL_STATE });

// CREATE OR EDIT
const createOrEditLessonPlanEventsSuccess = (state = INITIAL_STATE, action: any) => {
    const createOrEdit = state.items.find((eventItem) => eventItem.id === action.payload.id);
    const updateItem = state.items.map((eventItem) => (eventItem.id === action.payload.id ? { ...eventItem, ...action.payload } : eventItem));

    return {
        ...state,
        isLoading: false,
        items: createOrEdit ? updateItem : [...state.items, action.payload],
        currentEvent: action.payload
    };
};

// DELETE
const deleteLessonPlanEventsSuccess = (state = INITIAL_STATE, action: any) => {
    const updateItem = state.items.filter((eventItem) => eventItem.id !== action.payload);

    return {
        ...state,
        isLoading: false,
        items: updateItem
    };
};

// FOR EVENT DETAILS PAGE
const getHandShakeLessonPlanEventsSuccess = (state = INITIAL_STATE, action: any) => {
    const { video, event } = action.payload;

    return {
        ...state,
        isLoading: false,
        items: state.items.map((item) =>
            item.id === event
                ? {
                      ...item,
                      video
                  }
                : item
        )
    };
};
const getHandShakeLessonPlanEventsFailure = (state = INITIAL_STATE) => ({ ...state, ...INITIAL_STATE });

// Create or Edit Event Content
const createOrEditLessonPlanEventsContentSuccess = (state = INITIAL_STATE, action: any) => {
    try {
        const { content, event } = action.payload;

        /**
         *      GAMBI PRA FORMATAR O PAYLOAD DA API QUE VEM TODO ZOADO
         */

        const typeName = content.learningObject.type;
        const typeNameVariable = typeName.replace(typeName.charAt(0), typeName.charAt(0).toLowerCase());

        const formatContent = {
            id: content.id,
            order: 1,
            learningObject: {
                ...content,
                id: content.learningObject.id,
                type: content.learningObject.type
            }
        };

        formatContent.learningObject[typeNameVariable] = { ...content };

        delete formatContent.learningObject.learningObject;

        delete formatContent.learningObject[typeNameVariable].learningObject;

        /**
         *      FIM DA GAMBI
         */

        // CASO NAO EXISTA ESSE TIPO NO CONTENTPERTYPE

        const haveThisEvent = state.items.find((item) => item.id === event);

        const haveThisContentType = !!haveThisEvent && haveThisEvent.contentPerType.find((item: any) => item.type === content.learningObject.type);

        const formatNewContentPerType = {
            type: content.learningObject.type,
            title: content.learningObject.type,
            items: [],
            events: []
        };

        const actualEvent = !!haveThisEvent && {
            ...haveThisEvent,
            contentPerType: !!haveThisContentType ? haveThisEvent.contentPerType : [...haveThisEvent.contentPerType, formatNewContentPerType]
        };

        if (!actualEvent) {
            throw new Error();
        }

        const updateEvents = {
            ...state,
            items: state.items.map((item) => (item.id === actualEvent.id ? actualEvent : item))
        };

        const updateItems = updateEvents.items.map((item: any) =>
            item.id === event
                ? {
                      ...item,
                      contentPerType: item.contentPerType.map((contentPerTypeItem: any) => {
                          // Se for desse tipo
                          if (contentPerTypeItem.type === content.learningObject.type) {
                              // Verifica se esse conteudo ja existe
                              const haveThisContent = contentPerTypeItem.items.find((contentItem: any) => contentItem.id === content.id);

                              // Atualiza os conteudos
                              const updateContentItems = !!haveThisContent
                                  ? contentPerTypeItem.items.map((contentItem: any) => (contentItem.id === content.id ? content : contentItem))
                                  : [...contentPerTypeItem.items, formatContent];

                              return { ...contentPerTypeItem, items: updateContentItems };
                          }

                          return contentPerTypeItem;
                      })
                  }
                : item
        );

        return {
            ...state,
            isLoading: false,
            items: updateItems
        };
    } catch (error) {
        console.log(error);

        return {
            ...state,
            isLoading: false
        };
    }
};

// DELETE Event Content
const deleteLessonPlanEventsContentSuccess = (state = INITIAL_STATE, action: any) => {
    const { event, content } = action.payload;

    const updateItems = state.items.map((item) =>
        item.id === event
            ? {
                  ...item,
                  contentPerType: item.contentPerType.map((contentPerTypeItem: any) =>
                      contentPerTypeItem.type === content.learningObject.type
                          ? {
                                ...contentPerTypeItem,
                                items: contentPerTypeItem.items.filter((contentItem: any) => contentItem.id !== content.id)
                            }
                          : contentPerTypeItem
                  )
              }
            : item
    );

    return {
        ...state,
        isLoading: false,
        items: updateItems
    };
};

// get by ai
const getByIdSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoading: false,
    currentEvent: action.payload
});
const getByIdFailure = (state = INITIAL_STATE, action: any) => ({ ...state, currentEvent: CURRENT_INITIAL_STATE, isLoading: false });

// edit detail
const editDetail = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoading: false,
    ...action.payload
});

const setCurrentEvent = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoading: false,
    currentEvent: action.payload
});

const createCurrentEventVideo = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoading: false,
    currentEvent: {
        ...state.currentEvent,
        video: action.payload
    }
});

const setCurrentEventCreateVideo = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoading: false,
    activeCreateVideo: action.payload
});

// generic
const request = (state = INITIAL_STATE, action: any) => ({ ...state, isLoading: true });
const failure = (state = INITIAL_STATE) => ({ ...state, isLoading: false });

const clear = () => INITIAL_STATE;
const clearCurrent = (state = INITIAL_STATE) => ({
    ...state,
    isLoading: false,
    currentEvent: CURRENT_INITIAL_STATE
});

// createOrEditLessonPlanEventDetailRequest
export default createReducer(INITIAL_STATE, {
    // GET ALL
    [Types.GET_LESSON_PLAN_EVENTS_REQUEST]: request,
    [Types.GET_LESSON_PLAN_EVENTS_SUCCESS]: getLessonPlanEventsSuccess,
    [Types.GET_LESSON_PLAN_EVENTS_FAILURE]: getLessonPlanEventsFailure,

    // CREATE OR EDIT DETAIL
    [Types.CREATE_OR_EDIT_LESSON_PLAN_EVENT_DETAIL_REQUEST]: request,
    [Types.CREATE_OR_EDIT_LESSON_PLAN_EVENT_DETAIL_SUCCESS]: editDetail,
    [Types.CREATE_OR_EDIT_LESSON_PLAN_EVENT_DETAIL_FAILURE]: failure,

    // GET BY ID
    [Types.GET_LESSON_PLAN_EVENT_BY_ID_REQUEST]: request,
    [Types.GET_LESSON_PLAN_EVENT_BY_ID_SUCCESS]: getByIdSuccess,
    [Types.GET_LESSON_PLAN_EVENT_BY_ID_FAILURE]: getByIdFailure,

    // CREATE OR EDIT
    [Types.CREATE_OR_EDIT_LESSON_PLAN_EVENTS_REQUEST]: request,
    [Types.CREATE_OR_EDIT_LESSON_PLAN_EVENTS_SUCCESS]: createOrEditLessonPlanEventsSuccess,
    [Types.CREATE_OR_EDIT_LESSON_PLAN_EVENTS_FAILURE]: failure,

    // DELETE
    [Types.DELETE_LESSON_PLAN_EVENTS_REQUEST]: request,
    [Types.DELETE_LESSON_PLAN_EVENTS_SUCCESS]: deleteLessonPlanEventsSuccess,
    [Types.DELETE_LESSON_PLAN_EVENTS_FAILURE]: failure,

    // HAND SHAKE
    [Types.GET_HAND_SHAKE_LESSON_PLAN_EVENTS_REQUEST]: request,
    [Types.GET_HAND_SHAKE_LESSON_PLAN_EVENTS_SUCCESS]: getHandShakeLessonPlanEventsSuccess,
    [Types.GET_HAND_SHAKE_LESSON_PLAN_EVENTS_FAILURE]: getHandShakeLessonPlanEventsFailure,

    // CREATE OR EDIT EVENT CONTENT
    [Types.CREATE_OR_EDIT_LESSON_PLAN_EVENTS_CONTENT_REQUEST]: request,
    [Types.CREATE_OR_EDIT_LESSON_PLAN_EVENTS_CONTENT_SUCCESS]: createOrEditLessonPlanEventsContentSuccess,
    [Types.CREATE_OR_EDIT_LESSON_PLAN_EVENTS_CONTENT_FAILURE]: failure,

    // DELETE EVENT CONTENT
    [Types.DELETE_LESSON_PLAN_EVENTS_CONTENT_REQUEST]: request,
    [Types.DELETE_LESSON_PLAN_EVENTS_CONTENT_SUCCESS]: deleteLessonPlanEventsContentSuccess,
    [Types.DELETE_LESSON_PLAN_EVENTS_CONTENT_FAILURE]: failure,

    // CLEAR
    [Types.CLEAR_LESSON_PLAN_EVENTS]: clear,

    // CURRENT LESSON PLAN
    [Types.SET_CURRENT_LESSON_PLAN_EVENT]: setCurrentEvent,
    [Types.CLEAR_CURRENT_LESSON_PLAN_EVENT]: clearCurrent,

    // CREATE CURRENT EVENT VIDEO
    [Types.CREATE_LESSON_PLAN_EVENT_VIDEO_REQUEST]: request,
    [Types.CREATE_LESSON_PLAN_EVENT_VIDEO_SUCCESS]: createCurrentEventVideo,
    [Types.CREATE_LESSON_PLAN_EVENT_VIDEO_FAILURE]: failure,

    // ACTIVE CREATE VIDEO
    [Types.SET_CURRENT_LESSON_PLAN_EVENT_CREATE_VIDEO]: setCurrentEventCreateVideo
});
