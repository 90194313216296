import styled from 'styled-components';
import lightenDarkenColor from 'utils/lightAndDarkColor';
import theme from 'config/theme';

export const TableV2SearchInputContainer = styled.div`
    position: relative;
    width: 100%;
    z-index: 2;
    > svg {
        position: absolute;
        left: 8px;
        top: 50%;
        margin-top: -8px;
        fill: ${lightenDarkenColor(theme.colors.base, 20)};
        z-index: 1;
    }

    .select__control {
        border: unset;
        height: 39px;
        min-height: 39px;
        padding-left: 30px;

        :hover {
            border: unset;
        }

        .select__placeholder {
            color: #b4bfd0;
        }

        .select__indicator-separator {
            display: none;
        }
    }

    .react-datepicker-wrapper {
        input {
            border: unset !important;
            padding-left: 30px;
        }
    }

    .react-datepicker-popper {
        width: 270px !important;
    }
`;

export const TableV2SearchInputTextField = styled.input`
    border-radius: 0 !important;
    border: none !important;
    font-weight: 300;
    padding-left: 30px;

    ::placeholder {
        color: ${lightenDarkenColor(theme.colors.base, 20)};
        font-family: ${theme.typography.family.primary.normal.italic};
    }

    &:hover {
        background: #f5f5f5 !important;
    }
`;
