import { takeLatest, put, call, select } from 'redux-saga/effects';
import axios from 'axios';
import { Types as uploadVideoTypes, Creators as uploadVideoActions } from 'store/ducks/bunnyVideo';
import history from 'services/history';

import eventEmitter from 'utils/eventEmitter';
import { IReduxStore } from 'utils/interfaces/IReduxStore';

interface IUploadBunnyResponse {
    id?: number;
    title?: string;
    file: File;
    filesArrayItemReference: number;
}

const upload_URL = process.env.REACT_APP_API_BUNNY_URL!;
const source = axios.CancelToken.source();
const globalState = (state: IReduxStore): IReduxStore => state;

export function* uploadVideo(file: FormData, filesArrayItemReference: number) {
    const store = yield select(globalState);
    const { data } = store.bunnyVideo;
    const dataStore = data;

    try {
        const { data } = yield axios.post(upload_URL, file, {
            onUploadProgress: (percent) => {
                const progress = Math.round((percent.loaded * 100) / percent.total);

                if (progress === 100) {
                    eventEmitter.removeListener(`${file.get('title')}${filesArrayItemReference}`);
                    return eventEmitter.removeListener(`${file.get('id')}`);
                }

                if (Number.isInteger(progress) && file.get('id')) {
                    return eventEmitter.emit(`${file.get('id')}`, progress);
                }

                if (Number.isInteger(progress)) {
                    return eventEmitter.emit(`${file.get('title')}${filesArrayItemReference}`, progress);
                }
                return;
            },
            cancelToken: source.token
        });

        const dataSuccess = {
            title: `${file.get('title') ?? ''}`,
            id: data.id,
            message: 'Concluido',
            icon: 'ico-check',
            colorIcon: '#02CCBA',
            filesArrayItemReference: filesArrayItemReference
        };

        const newArraySuccess = dataStore.filter((item: any) => item.filesArrayItemReference !== filesArrayItemReference);

        yield put(uploadVideoActions.uploadBunnySuccess({ data: [...newArraySuccess, dataSuccess] }));

        if (filesArrayItemReference === dataStore.length) {
            yield put(uploadVideoActions.uploadBunnyIsLoading());
        }
    } catch (error) {
        const dataError = {
            title: `${file.get('title') ?? ''}`,
            id: dataStore[filesArrayItemReference - 1].id,
            message: `${error?.response?.data || 'Erro'}`,
            icon: 'ico-close',
            colorIcon: '#E15B64',
            filesArrayItemReference: filesArrayItemReference
        };

        const newArrayFailure = dataStore.filter((item: any) => item.filesArrayItemReference !== filesArrayItemReference);

        yield put(uploadVideoActions.uploadBunnyFailure({ data: [...newArrayFailure, dataError] }));

        if (filesArrayItemReference === dataStore.length) {
            yield put(uploadVideoActions.uploadBunnyIsLoading());
        }
    }
}

export function* uploadBunnyRequest(action: any) {
    const { data } = action.payload;

    for (let index = 0; index < data.length; index++) {
        const { file, title, id, filesArrayItemReference }: IUploadBunnyResponse = data[index];

        const fileUpload = new FormData();

        fileUpload.append('file', file);
        id && fileUpload.append('id', String(id));
        title && fileUpload.append('title', title);

        yield uploadVideo(fileUpload, filesArrayItemReference);
    }
}

export function* uploadBunnyCancel() {
    yield source.cancel();
    yield call(history.go, 0);
}

export default [takeLatest(uploadVideoTypes.UPLOAD_BUNNY_REQUEST, uploadBunnyRequest), takeLatest(uploadVideoTypes.UPLOAD_BUNNY_CLEAR, uploadBunnyCancel)];
