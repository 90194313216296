export function getRichTextString(text: string) {
    if (!text) {
        return '';
    }

    const element = document.createElement('div');

    element.innerHTML = text;

    return element?.textContent ?? '';
}
