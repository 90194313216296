import styled from 'styled-components';
import theme from 'config/theme';
import Col from 'react-bootstrap/esm/Col';

export const TableHeaderContainer = styled.div`
    display: flex;
    align-items: center;

    background: rgb(248, 250, 252);
    border-bottom: 1px solid #eeeeee;

    .col-md-auto {
        flex: 1;
    }

    div[class*='col-'] {
        padding-left: 0;
        padding-right: 0;
    }

    .row {
        border-right: 1px solid #eeeeee;
    }
`;

export const TableHeaderItem = styled(Col)`
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    :not(:last-child) {
        border-right: 1px solid #eeeeee;
    }
`;

export const TableHeaderTexts = styled.div`
    display: flex;
    align-items: center;
`;

export const TableHeaderLabel = styled.span`
    text-transform: uppercase;
    color: ${theme.colors.secondary};
    font-family: ${theme.typography.family.primary.bold.regular};
    font-size: ${theme.typography.sizes.normalX};
    padding: 15px;
    display: flex;
    align-items: center;
`;
