import styled from 'styled-components';
import { ButtonContainer } from 'components/button/styles';
import { DropdownContainer } from 'components/dropdown/styles';

export const TableActionsDropdown = styled.div<{ active: boolean }>`
    align-items: center;
    display: flex;
    justify-content: center;
    position: relative;

    ${({ active }) => active && `z-index: 9;`};

    ${ButtonContainer} {
        position: relative;
        z-index: 2;
    }

    ${DropdownContainer} {
        box-shadow: 0px 0px 10px 0px #e2e2e2;
        bottom: 20px;
        right: 25px;
        z-index: 1;
    }
`;
