import { createActions, createReducer } from 'reduxsauce';
import { IMaterial } from 'utils/interfaces/ILearningObject';

export const { Creators, Types } = createActions({
    getFileRequest: ['payload'],
    getFileSuccess: ['payload'],
    getFileFailure: [],

    createOrEditFileRequest: ['payload'],
    createOrEditFileSuccess: ['payload'],
    createOrEditFileFailure: [],

    editMaterialFile: ['payload'],

    clearFile: []
});

export interface IDocumentCategory {
    id: number;
    name: string;
    created: string;
}
export interface IFilesState {
    isLoading: boolean;
    id: number;
    created: string;
    course?: {
        id: number;
        name: string;
    };
    material?: IMaterial;
    documentCategory?: IDocumentCategory;
}

const INITIAL_STATE: IFilesState = {
    isLoading: false,
    id: 0,
    created: new Date().toISOString()
};

const request = (state = INITIAL_STATE) => ({ ...state, isLoading: true });

const success = (state = INITIAL_STATE, action: any) => ({ ...state, ...action.payload, isLoading: false });

const failure = (state = INITIAL_STATE) => ({ ...state, isLoading: false });

const clear = () => INITIAL_STATE;

export default createReducer(INITIAL_STATE, {
    [Types.GET_FILE_REQUEST]: request,
    [Types.GET_FILE_SUCCESS]: success,
    [Types.GET_FILE_FAILURE]: failure,

    [Types.CREATE_OR_EDIT_FILE_REQUEST]: request,
    [Types.CREATE_OR_EDIT_FILE_SUCCESS]: success,
    [Types.CREATE_OR_EDIT_FILE_FAILURE]: failure,

    [Types.EDIT_MATERIAL_FILE]: request,

    [Types.CLEAR_FILE]: clear
});
