import React, { useMemo } from 'react';

// Components
import Button from 'components/button';
import Icon from 'components/icons/icon';
import Tooltip from 'components/tooltip';
import ListData from 'components/listData';

// Assets
import * as S from './styles';
import { SmallTitle } from 'assets/global';
import { IAvaliableColumns, IAdvancedSearch } from 'utils/interfaces/IRoute';

import _set from 'lodash/set';

export interface IStoreListProps {
    title: string;

    deleteQuery?: any;
    getAllQuery?: any;
    tableName?: string;
    apiEndpoint?: any;
    idStore: number;
    columns: IAvaliableColumns[];
    canDelete?: boolean;
    canEdit?: boolean;
    canSeeDetails?: boolean;
    storeRelationName?: string;
    basePath?: string;
    advancedSearch?: IAdvancedSearch[];

    onClickCreate?(): void;
    onClickDetails?(id: number): void;
    onClickEdit?(id: number, item?: any): void;
    onClickDelete?(id: number): void;
}

const StoreList = ({
    title,
    idStore,
    columns,
    onClickCreate,
    onClickDetails,
    onClickEdit,
    tableName,
    canDelete = true,
    canEdit = true,
    onClickDelete,
    canSeeDetails = true,
    storeRelationName = 'id_store',
    getAllQuery,
    deleteQuery,
    basePath,
    ...rest
}: IStoreListProps) => {
    const filter = useMemo(() => {
        if (!storeRelationName) {
            return;
        }

        const tmpfilter = {};

        _set(tmpfilter, storeRelationName, { _eq: idStore });

        return tmpfilter;
    }, [idStore, storeRelationName]);

    return (
        <S.StoreListContainer>
            <S.StoreListHeader>
                <S.StoreListTitle>
                    <SmallTitle>{title}</SmallTitle>
                    {onClickCreate && (
                        <Button type="button" rounded={true} variant="success" size="small" onClick={onClickCreate}>
                            <Icon idIcon="ico-plus" width="11" height="11" viewBox="0 0 31.444 31.444" />
                            <Tooltip content="Adicionar" />
                        </Button>
                    )}
                </S.StoreListTitle>
            </S.StoreListHeader>

            <ListData
                columns={columns}
                graphQLTableName={tableName}
                canDelete={canDelete}
                canEdit={canEdit}
                onClickDetails={onClickDetails}
                onClickEdit={onClickEdit}
                onClickDelete={onClickDelete}
                canSeeDetails={canSeeDetails}
                defaultFiltes={filter}
                getAllQuery={getAllQuery}
                deleteQuery={deleteQuery}
                basePath={basePath}
                {...rest}
            />
        </S.StoreListContainer>
    );
};

export default StoreList;
