import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';

const ExamResolutions = lazy(() => import('pages/examResolutions'));

const examResolutionsRoutes = [
    {
        name: 'Resoluções',
        title: 'Resoluções',
        path: '/app/simulados/realizacoes',
        component: () => (
            <Redirect
                to={{
                    pathname: '/app/simulados'
                }}
            />
        ),
        exact: true
    },
    {
        name: 'Visualizar resolução',
        title: 'Visualizar resolução',
        path: '/app/simulados/realizacoes/ver/:id',
        component: ExamResolutions,
        exact: false
    }
];

export default examResolutionsRoutes;
