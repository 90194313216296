import React from 'react';
import { Row, Col } from 'react-bootstrap';

import { IStoreState } from 'utils/interfaces/IStore';
import { StoreDataItem, StoreCard } from '../styles';
import { CardContainer } from 'components/card/styles';

interface IProps {
    store: IStoreState;
}

const CardDetails = ({ store }: IProps) => {
    return (
        <Row style={{ marginBottom: '30px' }}>
            <Col lg={12}>
                <CardContainer>
                    <StoreCard.Content>
                        <StoreDataItem>
                            <strong>Nome</strong>
                            <span>{store.name || '-'}</span>
                        </StoreDataItem>
                        <StoreDataItem>
                            <strong>Slug</strong>
                            <span>{store.slug || '-'}</span>
                        </StoreDataItem>
                        <StoreDataItem>
                            <strong>Ativo</strong>
                            <span>{store.isActive ? 'Sim' : 'Não'}</span>
                        </StoreDataItem>
                        <StoreDataItem>
                            <strong>Url</strong>
                            <span>{store.url || '-'}</span>
                        </StoreDataItem>
                        <StoreDataItem>
                            <strong>Url Cupom</strong>
                            <span>{`${store.url}/finalizar-compra/IDPRODUTO?voucher=CODIGOCUPOM` || '-'}</span>
                        </StoreDataItem>
                    </StoreCard.Content>
                </CardContainer>
            </Col>
        </Row>
    );
};

export default CardDetails;
