import React from 'react';

import _get from 'lodash/get';

import { ListAdvancedSearchBadgesContainer } from './styles';
import Badge from 'components/badge';
import dictionary from 'config/dictionary';
import CSSTransition from 'react-transition-group/CSSTransition';
import { isValidDate } from 'utils/isValidDate';
import { DateTime } from 'luxon';

interface IProps {
    search?: object;
    onClickRemove(key: string): void;
}

const ListAdvancedSearchBadges = ({ search, onClickRemove }: IProps) => {
    if (!search) {
        return null;
    }

    return (
        <ListAdvancedSearchBadgesContainer>
            {Object.keys(search).map((key) => {
                const searchItem = _get(search, key);

                if (searchItem?.value === undefined || searchItem?.value === null) {
                    return null;
                }

                console.log(searchItem.label);

                const isDate = typeof searchItem.value === 'object' && isValidDate(searchItem.value);

                return (
                    <CSSTransition key={key} timeout={300} classNames="item" unmountOnExit>
                        <Badge variant="primary" key={key}>
                            <strong>{dictionary.crud[key] ?? key}</strong>:{' '}
                            {!!searchItem.label ? searchItem.label : isDate ? DateTime.fromJSDate(searchItem.value).toFormat('dd/LL/yyyy') : searchItem?.value}
                            <button type="button" onClick={() => onClickRemove(key)}>
                                &times;
                            </button>
                        </Badge>
                    </CSSTransition>
                );
            })}
        </ListAdvancedSearchBadgesContainer>
    );
};

export default ListAdvancedSearchBadges;
