import { put, select, call } from 'redux-saga/effects';

import { Creators as alertActions } from 'store/ducks/alert';
import { Creators as courseActions } from 'store/ducks/course';
import { Creators as modalActions } from 'store/ducks/modal';

import { apiRequest } from '../apiRequest';
import { IReduxStore } from 'utils/interfaces/IReduxStore';

import history from 'services/history';
import dictionary from 'config/dictionary';
import { IRequestAction, IPayloadRequest } from 'utils/interfaces/IRequestAction';
import { MaterialType } from 'utils/enums/materialType';
import { IBookletModule } from 'utils/interfaces/IBookletModule';
import { IContentPerTypeItem } from 'utils/interfaces/IContentPerType';
import { reorderGeneric } from 'utils/reorderArray';
import { uploadFile } from '../upload';

const globalState = (state: IReduxStore): IReduxStore => state;

// Get Course
export function* getCourseById(action: any) {
    try {
        const { payload } = action;

        const { data } = yield apiRequest(payload.method, payload.endpoint, payload.body, payload.headers);

        yield put(courseActions.getCourseByIdSuccess(data));
    } catch (error) {
        console.log('error', JSON.stringify(error));
        yield put(alertActions.showAlert('Ocorreu um erro ao carregar os dados.', 'danger'));
        yield put(courseActions.getCourseByIdFailure());
    }
}

export function* createOrEditCourse(action: any) {
    try {
        const { payload } = action;

        const { data } = yield apiRequest(payload.method, payload.endpoint, payload.body, payload.headers);

        yield put(courseActions.createOrEditCourseSuccess(data));

        yield put(alertActions.showAlert(payload.method === 'POST' ? 'Curso criado com sucesso' : 'Curso atualizado com sucesso', 'success'));

        if (payload.method === 'POST') {
            // @ts-ignore
            yield call(history.push, `/app/cursos/editar/${data.id}`);
        }
    } catch (error) {
        yield put(alertActions.showAlert(error?.response?.data?.detail?.message || 'Ocorreu um erro. Tente novamente mais tarde.', 'danger'));
        yield put(courseActions.createOrEditCourseFailure({ code: error?.response?.status }));
    }
}

export function* getModulesBySubject(action: any) {
    try {
        const { payload } = action;

        const { data } = yield apiRequest(payload.method, payload.endpoint, payload.body, payload.headers);

        yield put(courseActions.getModulesBySubjectSuccess(data));
    } catch (error) {
        yield put(courseActions.getModulesBySubjectFailure());
        yield put(alertActions.showAlert('Ocorreu um error.', 'danger'));
    }
}

export function* createModule(action: any) {
    try {
        const { payload } = action;

        const formaBody = {
            ...payload.body,
            template: payload.body.template.id
        };

        const { data } = yield apiRequest(payload.method, payload.endpoint, formaBody, payload.headers);

        // const { data } = yield apiRequest('POST', '/bookletmodule', payload);

        yield put(
            courseActions.createModuleSuccess({
                ...data,
                contentPerType: payload.body.template.contentPerType || [],
                stats: payload.body.template.stats || [],
                template: {
                    id: payload.body.template.id
                }
            })
        );

        //
        //  REORDENAÇÃO DE MÓDULOS
        //

        // Pega os módulos selecionados da store
        const store = yield select(globalState);
        const { selectedModules } = store.course;

        // Joga o módulo novo como index 0 e reordena com os módulos selecionados, passando o novo order
        const updateModules = [data, ...selectedModules.filter((item: IBookletModule) => item.id !== data.id)];
        const updateModulesOrders = (reorderGeneric(updateModules, 0, data.order > 0 ? data.order - 1 : data.order) as IBookletModule[]).map((item, index) => ({
            ...item,
            order: index + 1
        }));

        // Joga os módulos com order atualizado no store
        yield put(courseActions.getModulesBySubjectSuccess(updateModulesOrders));

        // Faz o request pra atualizar todos os módulos
        const payloadOrder: IPayloadRequest<any> = {
            endpoint: '/bookletmodule/order',
            method: 'PATCH',
            body: updateModulesOrders
        };
        yield put(courseActions.reorderCourseItemsRequest(payloadOrder));
    } catch (error) {
        yield put(courseActions.createModuleFailure({ code: error?.response?.status }));
        yield put(alertActions.showAlert('Ocorreu um erro ao selecionar o módulo', 'danger'));
    }
}

export function* deleteModule(action: any) {
    try {
        const { payload } = action;

        yield apiRequest(payload.method, payload.endpoint, payload.body, payload.headers);

        yield put(courseActions.deleteModuleSuccess(payload.body));

        //
        //  REORDENAÇÃO DE MÓDULOS
        //

        // Pega os módulos selecionados da store
        const store = yield select(globalState);
        const { selectedModules } = store.course;

        const updateModulesOrders = (selectedModules as IBookletModule[]).map((item, index) => ({
            ...item,
            order: index + 1
        }));

        // Joga os módulos com order atualizado no store
        yield put(courseActions.getModulesBySubjectSuccess(updateModulesOrders));

        yield put(alertActions.showAlert('Módulo excluído com sucesso', 'success'));

        if (!updateModulesOrders.length) {
            return;
        }

        // Faz o request pra atualizar todos os módulos
        const payloadOrder: IPayloadRequest<any> = {
            endpoint: '/bookletmodule/order',
            method: 'PATCH',
            body: updateModulesOrders
        };
        yield put(courseActions.reorderCourseItemsRequest(payloadOrder));

        //
    } catch (error) {
        console.log('error', error);
        yield put(courseActions.deleteModuleFailure());
        yield put(alertActions.showAlert('Ocorreu um erro ao apagar o módulo', 'danger'));
    }
}

export function* createBookletContentItem(action: any) {
    try {
        const { payload } = action;

        const headers = {
            'X-Relations': 'module;learningObject;learningObject.video;learningObject.exerciseList;learningObject.material;learningObject.playlist'
        };

        for (let index = 0; index < payload.length; index++) {
            const { data } = yield apiRequest('POST', '/bookletcontentitem', payload[index], headers);

            const LOType = data.learningObject.material && data.learningObject.material.type === MaterialType.Booklet ? 'Apostila' : data.learningObject.type;
            const store = yield select(globalState);
            const currentModule = store.course.currentModule;

            const haveContentType = currentModule.contentPerType.some((item: any) => item.type === LOType);

            const updateContentPerType = currentModule.contentPerType.map((item: any) => {
                if (item.type === LOType) {
                    return { ...item, items: [...item.items, data] };
                }

                return item;
            });

            if (!haveContentType) {
                updateContentPerType[currentModule.contentPerType.length || 0] = {
                    type: LOType,
                    items: [data],
                    title: dictionary.course[LOType],
                    events: []
                };
            }

            yield put(
                courseActions.createBookletContentItemSuccess({
                    id: payload[index].module,
                    contentPerType: updateContentPerType
                })
            );
        }

        yield put(alertActions.showAlert('Conteúdo criado com sucesso', 'success'));
        yield put(modalActions.closeModal());
    } catch (error) {
        console.log('error', error);
        yield put(courseActions.createBookletContentItemFailure());
        yield put(
            alertActions.showAlert((error?.response?.data?.detail?.error === 'Conflict' && 'Esse conteúdo já foi associado ao Módulo') || 'Ocorreu um erro ao associar esse conteúdo.', 'danger')
        );
    }
}

export function* deleteBookletContentItem(action: any) {
    try {
        const { payload } = action;

        yield apiRequest(payload.method, payload.endpoint);

        yield put(courseActions.deleteBookletContentItemSuccess(payload.body));
        yield put(alertActions.showAlert('Conteúdo excluído com sucesso', 'success'));
    } catch (error) {
        console.log('error', JSON.stringify(error));
        yield put(alertActions.showAlert('Ocorreu um erro.', 'danger'));
        yield put(courseActions.deleteBookletContentItemFailure());
    }
}

export function* createModuleAndTemplate(action: any) {
    try {
        const { payload } = action;

        const { data } = yield apiRequest(payload.method, payload.endpoint, payload.body, payload.headers);

        yield put(courseActions.createModuleAndTemplateSuccess(data));
        yield put(alertActions.showAlert('Módulo criado com sucesso', 'success'));
        yield put(modalActions.closeModal());
    } catch (error) {
        console.log('error', error);
        yield put(alertActions.showAlert('Ocorreu um erro.', 'danger'));
        yield put(courseActions.createModuleAndTemplateFailure());
    }
}

export function* editBookletModule(action: IRequestAction<any>) {
    try {
        const { payload } = action;

        yield apiRequest(payload.method, payload.endpoint, payload.body, payload.headers);

        // TODO: formatar dado c/ subjectFront
        // Ignora o response da api e taca o payload.body pro state
        const store = yield select(globalState);
        const updateCurrentModule = {
            ...store.course.currentModule,
            ...payload.body
        };

        yield put(courseActions.editCourseModuleSuccess(updateCurrentModule));
        yield put(modalActions.closeModal());
    } catch (error) {
        console.log('error', error);
        yield put(alertActions.showAlert('Ocorreu um erro.', 'danger'));
        yield put(courseActions.editCourseModuleFailure());
    }
}

export function* associateQrCodeToModule(action: any) {
    try {
        const { payload } = action;

        yield apiRequest(payload.method, payload.endpoint, payload.body, payload.headers);

        yield put(courseActions.associateQrCodeToModuleSuccess(payload.body));
        yield put(modalActions.closeModal());

        yield put(alertActions.showAlert('QR Code associado com sucesso!', 'success'));
    } catch (error) {
        console.log('error', error);
        yield put(alertActions.showAlert('Ocorreu um erro.', 'danger'));
        yield put(courseActions.associateQrCodeToModuleFailure());
    }
}

export function* associateQrCodeToBookletContentItem(action: any) {
    try {
        const { payload } = action;

        const { data } = yield apiRequest(payload.method, payload.endpoint, payload.body, payload.headers);

        yield put(
            courseActions.associateQrCodeToBookletContentItemSuccess({
                moduleId: payload.body.moduleId,
                contentItem: payload.body,
                qrCode: data
            })
        );

        yield put(modalActions.closeModal());

        yield put(alertActions.showAlert('QR Code associado com sucesso!', 'success'));
    } catch (error) {
        console.log('error', error);
        yield put(alertActions.showAlert('Ocorreu um erro.', 'danger'));
        yield put(courseActions.associateQrCodeToBookletContentItemFailure());
    }
}

// Salva tudo que ta no modulo como template
export function* saveBookletModuleTemplate(action: IRequestAction<any>) {
    try {
        const { payload } = action;

        yield apiRequest(payload.method, payload.endpoint, payload.body, payload.headers);

        yield put(courseActions.saveBookletModuleTemplateSuccess(payload.body));

        yield put(alertActions.showAlert('Módulo salvo como template!', 'success'));
    } catch (error) {
        console.log('error', error);
        yield put(alertActions.showAlert('Ocorreu um erro ao salvar o Módulo.', 'danger'));
        yield put(courseActions.saveBookletModuleTemplateFailure());
    }
}

export function* getBookletModuleTemplates(action: IRequestAction<any>) {
    try {
        const { payload } = action;

        const { data } = yield apiRequest(payload.method, payload.endpoint, payload.body, payload.headers);

        yield put(courseActions.getBookletModuleTemplatesSuccess(data.items));
    } catch (error) {
        console.log('error', error);
        yield put(courseActions.getBookletModuleTemplatesFailure());
    }
}

export function* reorderCourseItems(action: IRequestAction<any>) {
    try {
        const { payload } = action;

        const sortItems = payload.body
            .map((item: IBookletModule, index: number) => ({ ...item, order: index + 1 }))
            .sort((a: IBookletModule, b: IBookletModule) => (a.order > b.order ? 1 : b.order > a.order ? -1 : 0));

        const formatBody = sortItems.map((item: IBookletModule) => ({
            id: item.id,
            order: item.order
        }));

        yield apiRequest(payload.method, payload.endpoint, formatBody);

        yield put(courseActions.getModulesBySubjectSuccess(sortItems));
        yield put(courseActions.reorderCourseItemsSuccess());
    } catch (error) {
        console.log('error', JSON.stringify(error));
        yield put(alertActions.showAlert('Ocorreu um erro ao ordenar os módulos.', 'danger'));
        yield put(courseActions.reorderCourseItemsFailure());
    }
}

export function* reorderCourseContentItems(action: IRequestAction<any>) {
    try {
        const { payload } = action;

        if (!payload.body.items) {
            throw new Error();
        }

        const formatItems = payload.body.items.map((item: IContentPerTypeItem, index: number) => ({
            id: item.id,
            order: index + 1
        }));

        yield apiRequest(payload.method, payload.endpoint, formatItems);

        yield put(courseActions.reorderCourseContentItemsSuccess(payload.body));

        yield put(alertActions.showAlert('Itens reordenados.', 'success'));
    } catch (error) {
        console.log('error', JSON.stringify(error));
        yield put(alertActions.showAlert('Ocorreu um erro.', 'danger'));
        yield put(courseActions.reorderCourseContentItemsFailure());
    }
}

export function* editBookletContentItem(action: IRequestAction<any>) {
    try {
        const { payload } = action;

        const { type, content, learningObjectId } = payload.body;

        yield apiRequest(payload.method, payload.endpoint, content, payload.headers);

        const haveFileToUpload = !!type && (type.toLowerCase() === 'material' || type.toLowerCase() === 'apostila') && !!content.url && !!learningObjectId && content.url instanceof File;

        if (haveFileToUpload) {
            const payloadUpload = {
                method: 'POST',
                endpoint: `/learningobject/${payload.body.learningObjectId}/upload`,
                body: {
                    file: content.url
                }
            };

            const response = yield call(uploadFile, payloadUpload);

            payload.body.content.url = response;
        }

        yield put(courseActions.editCourseContentItemSuccess(payload.body));

        yield put(alertActions.showAlert('Conteúdo atualizado.', 'success'));
        yield put(modalActions.closeModal());
    } catch (error) {
        console.log('error', JSON.stringify(error));
        yield put(alertActions.showAlert('Ocorreu um erro.', 'danger'));
        yield put(courseActions.reorderCourseContentItemsFailure());
    }
}

export function* disassociateQrCodeToBookletContentItem(action: { type: string; payload: { contentPerTypeItemLOId: number; moduleId: number; qrCodeId: number } }) {
    try {
        const { contentPerTypeItemLOId, moduleId, qrCodeId } = action.payload;

        if (!qrCodeId || !contentPerTypeItemLOId) {
            throw new Error();
        }

        yield apiRequest('DELETE', `/admin/qrcode/${qrCodeId}/content/${contentPerTypeItemLOId}`);

        yield put(
            courseActions.disassociateQrCodeToBookletContentItemSuccess({
                contentPerTypeItemLOId,
                moduleId
            })
        );

        yield put(alertActions.showAlert('Conteúdo desassociado.', 'success'));
    } catch (error) {
        console.log('error', error);
        yield put(alertActions.showAlert('Não foi possível desassociar o conteúdo.', 'danger'));

        yield put(courseActions.disassociateQrCodeToBookletContentItemFailure());
    }
}
