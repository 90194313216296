import { put, call } from 'redux-saga/effects';
import { Creators as filesActions } from 'store/ducks/files';
import { Creators as alertActions } from 'store/ducks/alert';
import { Creators as modalActions } from 'store/ducks/modal';
import history from 'services/history';
import { apiRequest } from '../apiRequest';
import { IRequestAction } from 'utils/interfaces/IRequestAction';
import { uploadFile } from '../upload';

export function* getFileById(action: IRequestAction<any>) {
    try {
        const { payload } = action;

        const { data } = yield apiRequest(payload.method, payload.endpoint, payload.body, payload.headers);

        yield put(filesActions.getFileSuccess(data));
    } catch (error) {
        yield put(alertActions.showAlert('Registro não encontrado.', 'danger'));
        yield put(filesActions.getFileFailure());
        yield call(history.push, { pathname: `/app/arquivos` });
    }
}

export function* createOrEditFile(action: IRequestAction<any>) {
    try {
        const { payload } = action;

        const { data } = yield apiRequest(payload.method, payload.endpoint, payload.body, payload.headers);

        yield put(filesActions.createOrEditFileSuccess(data));

        yield put(alertActions.showAlert('Arquivo salvo!', 'success'));

        yield call(history.push, { pathname: `/app/arquivos` });
    } catch (error) {
        yield put(filesActions.createOrEditFileFailure());
        yield put(alertActions.showAlert('Ocorreu um erro. Tente novamente mais tarde.', 'danger'));
    }
}

export function* updateMaterialFile(action: IRequestAction<any>) {
    try {
        const { payload } = action;

        console.log('updateMaterialFile', action);
        console.log('payload', payload);

        const { data } = yield apiRequest(payload.method, payload.endpoint, payload.body.content, payload.headers);

        if (!!payload.body.content.url) {
            const payloadUpload = {
                method: 'POST',
                endpoint: `/learningobject/${payload.body.learningObjectId}/upload`,
                body: {
                    file: payload.body.content.url
                }
            };

            const response = yield call(uploadFile, payloadUpload);

            if (!response) {
                throw new Error();
            }

            data.url = response;
        }

        yield put(filesActions.createOrEditFileSuccess({ material: data }));
        yield put(modalActions.closeModal());
    } catch (error) {
        console.log(error);
        yield put(filesActions.createOrEditFileFailure());
        yield put(alertActions.showAlert('Ocorreu um erro. Tente novamente mais tarde.', 'danger'));
    }
}
