export default function generateSearchQuery(tableName?: string, searchFor = 'name') {
    if (!tableName) {
        return '';
    }

    const formatTableName = `prodigio_${tableName}`;

    return `query ($text: String!, $where: [${formatTableName}_bool_exp]) {
        items: ${formatTableName}(where: {${searchFor}: {_ilike: $text}, _and: $where}, order_by: {${searchFor}: asc}, limit: 10, offset: 0) {
          id
          ${searchFor}
        }
      }`;
}
