import { lazy } from 'react';

const List = lazy(() => import('pages/crud'));

const CorrectionEngine = lazy(() => import('pages/correctionEngine'));

const correctionEngineRoutes = [
    {
        path: '/app/motores-de-correcao',
        name: 'Motores de correção',
        component: List,
        exact: true,
        title: 'Motores de correção'
    },
    {
        path: '/app/motores-de-correcao/novo',
        name: 'Novo Motor de correção',
        component: CorrectionEngine,
        exact: true,
        title: 'Novo'
    },
    {
        path: '/app/motores-de-correcao/editar/:id',
        name: 'Editar',
        component: CorrectionEngine,
        exact: true,
        title: 'Editar motor de correção'
    }
];

export default correctionEngineRoutes;
