import React, { useRef, useCallback, lazy, Suspense, useMemo } from 'react';

// Components
import Button from 'components/button';
import Icon from 'components/icons/icon';
import Loading from 'components/loading';

// Helpers
import useKeyDown from 'hooks/useKeyDown';
import { IModal } from 'utils/interfaces/IModal';
import ModalErrorBoundary from './errorBoundary';
import useOnClickOutside from 'hooks/useOnClickOutside';
import { IModalActions } from 'utils/interfaces/IActions';

// Assets
import { ModalContainer, ModalWrapper, ModalWrapperResizable, ModalContainerRef } from './styles';
import 'react-resizable/css/styles.css';
import GenerateExamRanking from './generateExamRanking';

interface IProps {
    modal: IModal;
    modalActions: IModalActions;
}

// Modals
const NewLogin = lazy(() => import('components/newLogin'));
const ConfirmDelete = lazy(() => import('./confirmDelete'));
const SearchContent = lazy(() => import('components/search'));
const SearchQuestions = lazy(() => import('components/examDay/searchQuestions'));
const VideoPreviewModal = lazy(() => import('./previewModal'));
const ExerciseListModal = lazy(() => import('./exerciseListModal'));
const CreateLOModal = lazy(() => import('./CreateLOModal'));
const CreateExamDayModal = lazy(() => import('./CreateExamDayModal'));
const BrandSelectorModal = lazy(() => import('./brandSelectorModal'));
const LessonPlanSelectDayModal = lazy(() => import('./lessonPlanSelectDayModal'));
const SelectContentModal = lazy(() => import('./SelectContentModal'));
const SelectQuestionModal = lazy(() => import('./SelectQuestionModal'));
const CreateOrEditQuestionAlternative = lazy(() => import('./createOrEditQuestionAlternative'));
const SelectLearningObject = lazy(() => import('./selectLearningObject'));
const CreateManagingUser = lazy(() => import('./createManagingUser'));
const SelectStudent = lazy(() => import('./SelectStudentModal'));
const CreateTeacherSchoolClass = lazy(() => import('./createTeacherSchoolClass'));
const AddressModal = lazy(() => import('./addressModal'));
const EnrollModal = lazy(() => import('./enrollModal'));
const ConfirmActionModal = lazy(() => import('./confirmActionModal'));
const AddStudentsToExerciseListModal = lazy(() => import('./addStudentsToExerciseListModal'));
const TagsModal = lazy(() => import('./tagsModal'));
const SectionTextModal = lazy(() => import('./sectionTextModal'));
const SectionLinkModal = lazy(() => import('./sectionLinkModal'));
const ProjectSummaryModal = lazy(() => import('./project/summary'));
const ProjectModuleModal = lazy(() => import('./project/module'));
const ProjectSectionModal = lazy(() => import('./project/section'));
const ManageVideoModal = lazy(() => import('./manageVideoModal'));
const SelectUnitModal = lazy(() => import('./SelectUnitModal'));
const SearchModal = lazy(() => import('./searchModal'));
const CreateBookletModuleModal = lazy(() => import('./createBookletModule'));
const UploadModal = lazy(() => import('./uploadModal'));
const CompanySelectorModal = lazy(() => import('./companySelectorModal'));
const PreviewQrCodeModal = lazy(() => import('./previewQrCodeModal'));
const AssociateQrCodeModal = lazy(() => import('./associateQrCodeModal'));
const ChangePasswordModal = lazy(() => import('./changePasswordModal'));
const ProductPriceModal = lazy(() => import('./productPriceModal'));
const ProductCreditModal = lazy(() => import('./productCreditModal'));
const StoreCategoryModal = lazy(() => import('./storeCategoryModal'));
const StoreAddressModal = lazy(() => import('./storeAddressModal'));
const EditBookletModuleModal = lazy(() => import('./editBookletModuleModal'));
const PreviewUrlModal = lazy(() => import('./previewUrlModal'));
const StudentCreditsModal = lazy(() => import('./studentCreditsModal'));
const RefoundOrderModal = lazy(() => import('./refoundOrderModal'));
const EditContentModalWrapper = lazy(() => import('./course/editContent'));
const PreviewTextMaterialModal = lazy(() => import('./course/previewTextMaterial'));
const StudentSubscriptionModal = lazy(() => import('./studentSubscription'));
const ReorderContentModal = lazy(() => import('./reorderContentModal'));
const ImportStudentsModal = lazy(() => import('./student/import'));
const ConfirmSubscriptionCancelationModal = lazy(() => import('./ConfirmSubscriptionCancelationModal/index'));
const SendProjectToSchoolGradeModal = lazy(() => import('./SendProjectToSchoolGrade'));
const CreateOrEditSubjectGroup = lazy(() => import('./createOrEditSubjectGroup'));
const CreateSubjectGroupTeacher = lazy(() => import('./createSubjectGroupTeacher'));
const CreateExamPermission = lazy(() => import('./createExamPermission'));
const CreateOrEditMaterial = lazy(() => import('../createLO/createMaterial'));
const ProductSeller = lazy(() => import('./productSeller'));
const ProductCategory = lazy(() => import('./productCategory'));
const CreateLessonPlanModule = lazy(() => import('./createLessonPlanModule'));
const Refund = lazy(() => import('./refund'));
const OrderAddress = lazy(() => import('./order-address'));
const ComplaintModal = lazy(() => import('./complaint'));
const SendExamToSchoolClass = lazy(() => import('./sendExamToSchoolClass'));
const SelectItem = lazy(() => import('./selectItem'));
const AnswerCardQuestion = lazy(() => import('./answerCardQuestion'));
const CreateApplymentWindow = lazy(() => import('./createApplymentWindow'));
const QuestionConflict = lazy(() => import('./questionConflict'));
const CreateOrUpdateLessonPlanEducationalModule = lazy(() => import('pages/lesson-plan-educational/components/modals/create-module'));
const CreateProjectExhibitionPlaces = lazy(() => import('./createProjectExhibitionPlaces'));
const ModalDownload = lazy(() => import('./download'));
const DiscardExamImport = lazy(() => import('./discardExamImport'));
const ListDataModal = lazy(() => import('./listData'));
const StudentSubjectProgress = lazy(() => import('./studentSubjectProgress'));
const ConfirmDeleteLessonPlanEventModal = lazy(() => import('./confirmDeleteLessonPlanEvent'));

const GUTTER = 80;
const WINDOW_WIDTH = window.innerWidth;
const WINDOW_HEIGHT = window.innerHeight;

const BLACK_LIST = [
    'createLO',
    'editContent',
    'selectQuestions',
    'editBookletModule',
    'handleProjectModule',
    'projectContentModal',
    'createBookletModule',
    'lessonPlanSelectDay',
    'handleProjectSection',
    'handleProjectSummary',
    'createOrEditMaterial',
    'handleSectionProjectContent',
    'createOrEditQuestionAlternative'
    // 'selectSchoolClass'
];

const renderByType = (modal: IModal) => {
    try {
        const { action, element } = modal;

        const modals: { [key: string]: React.ReactNode } = {
            newLogin: <NewLogin prevAction={action} />,
            companySelector: <CompanySelectorModal />,
            brandSelector: <BrandSelectorModal />,
            lessonPlanSelectDay: <LessonPlanSelectDayModal action={action} />,
            createExamDay: <CreateExamDayModal action={action} />,
            selectContent: <SelectContentModal Component={SearchContent} action={action} />,
            selectSchoolClass: <AddStudentsToExerciseListModal action={action} />,
            selectQuestions: <SelectQuestionModal Component={SearchQuestions} action={action} />,
            createLO: <CreateLOModal action={action} />,
            confirmDelete: <ConfirmDelete action={action} />,
            preview: <VideoPreviewModal action={action} />,
            exerciseListPreview: <ExerciseListModal action={action} />,
            createOrEditQuestionAlternative: <CreateOrEditQuestionAlternative action={action} />,
            selectLearningObject: <SelectLearningObject action={action} />,
            createManagingUser: <CreateManagingUser action={action} />,
            selectStudent: <SelectStudent action={action} />,
            createTeacherSchoolClass: <CreateTeacherSchoolClass action={action} />,
            addressModal: <AddressModal action={action} />,
            enrollModal: <EnrollModal action={action} />,
            confirmActionModal: <ConfirmActionModal action={action} />,
            search: <SearchModal action={action} />,
            handleProjectSummary: <ProjectSummaryModal action={action} />,
            handleSectionProjectContent: <SectionTextModal action={action} />,
            handleSectionProjectLink: <SectionLinkModal action={action} />,
            handleProjectTags: <TagsModal />,
            handleVideoSelector: <ManageVideoModal action={action} />,
            handleProjectModule: <ProjectModuleModal action={action} />,
            handleProjectSection: <ProjectSectionModal action={action} />,
            selectUnit: <SelectUnitModal action={action} />,
            upload: <UploadModal action={action} />,
            previewQrCode: <PreviewQrCodeModal action={action} />,
            associateQrCode: <AssociateQrCodeModal action={action} />,
            changePassword: <ChangePasswordModal action={action} />,
            productPrice: <ProductPriceModal action={action} />,
            productCredit: <ProductCreditModal action={action} />,
            storeCategory: <StoreCategoryModal action={action} />,
            storeAddress: <StoreAddressModal action={action} />,
            editBookletModule: <EditBookletModuleModal action={action} />,
            previewUrl: <PreviewUrlModal action={action} />,
            studentCredits: <StudentCreditsModal action={action} />,
            refoundOrder: <RefoundOrderModal action={action} />,
            createBookletModule: <CreateBookletModuleModal action={action} />,
            editContent: <EditContentModalWrapper action={action} />,
            previewTextMaterial: <PreviewTextMaterialModal material={action} />,
            studentSubscription: <StudentSubscriptionModal action={action} />,
            reorderContent: <ReorderContentModal action={action} />,
            importStudents: <ImportStudentsModal />,
            confirmSubscriptionCancelation: <ConfirmSubscriptionCancelationModal action={action} />,
            sendProjectToSchoolGradeModal: <SendProjectToSchoolGradeModal {...action} />,
            createOrEditSubjectGroup: <CreateOrEditSubjectGroup {...action} />,
            createSubjectGroupTeacher: <CreateSubjectGroupTeacher {...action} />,
            createExamPermission: <CreateExamPermission {...action} />,
            createOrEditMaterial: <CreateOrEditMaterial {...action} />,
            productSeller: <ProductSeller action={action} />,
            productCategory: <ProductCategory action={action} />,
            createLessonPlanModule: <CreateLessonPlanModule action={action} />,
            refund: <Refund action={action} />,
            orderAddress: <OrderAddress action={action} />,
            complaint: <ComplaintModal action={action} />,
            generateExamRanking: <GenerateExamRanking action={action} />,
            sendExamToSchoolClass: <SendExamToSchoolClass action={action} />,
            selectItem: <SelectItem action={action} />,
            answerCardQuestion: <AnswerCardQuestion action={action} />,
            createApplymentWindow: <CreateApplymentWindow action={action} />,
            questionConflict: <QuestionConflict action={action} />,
            createOrUpdateLessonPlanEducationalModule: <CreateOrUpdateLessonPlanEducationalModule action={action} />,
            createProjectExhibitionPlaces: <CreateProjectExhibitionPlaces action={action} />,
            download: <ModalDownload action={action} />,
            discardExamImport: <DiscardExamImport action={action} />,
            listData: <ListDataModal {...(action as any)} />,
            studentSubjectProgress: <StudentSubjectProgress action={action} />,
            confirmDeleteLessonPlanEvent: <ConfirmDeleteLessonPlanEventModal action={action} />
        };

        if (modals[element]) {
            return (
                <ModalErrorBoundary>
                    <Suspense fallback={<Loading fullScreen={true} />}>{modals[element]}</Suspense>
                </ModalErrorBoundary>
            );
        }

        return <div />;
    } catch (error) {
        console.log(error);
        return <div />;
    }
};

const Modal = ({ modal, modalActions }: IProps) => {
    const ref = useRef<HTMLDivElement>(null);

    const handleClickOutside = useCallback(() => {
        const isPresentOnBlackList = BLACK_LIST.some((item) => item === modal.element);

        if (isPresentOnBlackList) {
            return;
        }

        modal.isModalVisible && modal.element !== 'newLogin' && modal.element !== 'confirmDelete' && modalActions.closeModal();
    }, [modal.element, modal.isModalVisible, modalActions]);

    const currentModal = useMemo(() => modal.isModalVisible && renderByType(modal), [modal]);

    // Fecha o modal com clique fora
    useOnClickOutside(ref, handleClickOutside);

    // Fecha o modal com ESC
    useKeyDown(() => modal.element !== 'newLogin' && modalActions.closeModal());

    if (!modal.isModalVisible) {
        return null;
    }

    if (modal?.isCustom && currentModal) {
        return currentModal;
    }

    const hasCloseButton = modal.element !== 'newLogin' && modal.element !== 'confirmDelete';

    return (
        <ModalContainer isVisible={modal.isModalVisible}>
            <ModalContainerRef ref={ref}>
                {!!modal.resizeOptions ? (
                    <ModalWrapperResizable
                        width={modal.resizeOptions.width}
                        height={modal.resizeOptions.height}
                        minConstraints={modal.resizeOptions.minConstraints}
                        maxConstraints={[WINDOW_WIDTH - GUTTER, WINDOW_HEIGHT - GUTTER]}
                    >
                        {hasCloseButton && (
                            <Button variant="outline-secondary" type="button" onClick={() => modalActions.closeModal()}>
                                <Icon idIcon="ico-close" width="15" height="15" viewBox="0 0 512 512" />
                            </Button>
                        )}
                        {currentModal}
                    </ModalWrapperResizable>
                ) : (
                    <ModalWrapper>
                        {hasCloseButton && (
                            <Button variant="outline-secondary" type="button" onClick={() => modalActions.closeModal()}>
                                <Icon idIcon="ico-close" width="15" height="15" viewBox="0 0 512 512" />
                            </Button>
                        )}
                        {currentModal}
                    </ModalWrapper>
                )}
            </ModalContainerRef>
        </ModalContainer>
    );
};

export default Modal;
