export enum ExerciseListType {
    Normal = 1,
    Extra = 2,
    External = 3
}

export enum QuestionDifficults {
    'Fácil' = 1,
    'Médio' = 2,
    'Difícil' = 3
}

export enum QuestionType {
    Objective,
    Discursive
}
