import { put, call } from 'redux-saga/effects';
import { Creators as alertActions } from 'store/ducks/alert';
import { Creators as orderActions } from 'store/ducks/order';
import { apiRequest } from '../apiRequest';
import history from 'services/history';
import { IRequestAction } from 'utils/interfaces/IRequestAction';

export function* getOrderById(action: IRequestAction<any>) {
    try {
        const { payload } = action;

        const { data } = yield apiRequest(payload.method, payload.endpoint, payload.body, payload.headers);

        yield put(orderActions.getOrderSuccess(data));
    } catch (error) {
        console.log(error);
        yield put(orderActions.getOrderFailure());
        yield put(alertActions.showAlert('Ocorreu um erro. Tente novamente mais tarde.', 'danger'));
        yield call(history.push, { pathname: '/app/pedidos' });
    }
}

export function* refoundOrder(action: IRequestAction<any>) {
    try {
        const { payload } = action;

        const { data } = yield apiRequest(payload.method, payload.endpoint, payload.body, payload.headers);

        yield put(orderActions.refoundOrderSuccess(data));
        yield put(alertActions.showAlert('Perdido estornado com sucesso.', 'success'));
    } catch (error) {
        console.log(error);
        yield put(orderActions.refoundOrderFailure());
        yield put(alertActions.showAlert('Ocorreu um erro. Tente novamente mais tarde.', 'danger'));
        yield call(history.push, { pathname: '/app/pedidos' });
    }
}
