import React, { useEffect, useState, useCallback } from 'react';

// Components
import Badge from 'components/badge';
import Button from 'components/button';
import Icon from 'components/icons/icon';

// Helpers
import api from 'services/api';
import theme from 'config/theme';
import { IAuthActions } from 'store/types/IAuth';
import { IBrand } from 'utils/interfaces/IBrand';
import { ICompany } from 'utils/interfaces/ICompany';
import { IProfile } from 'utils/interfaces/IProfile';
import { ICompanyActions, IModalActions } from 'utils/interfaces/IActions';

// Assets
import { SmallTitle } from 'assets/global';
import { ModalHeader, ModalContent, ModalFooter } from 'components/modal/styles';
import { BrandSelectorWrapper, BrandSelectorItem, BrandSelectorEmpty, BrandSelectorName, BrandSelectorAccordionText, BrandSelectorSelected, BrandSelectorLoading } from './styles';
import apiMongo from 'services/api-mongo';

interface IProps {
    companyActions: ICompanyActions;
    companies: ICompany[];
    modalActions: IModalActions;
    isLoading: boolean;
    authActions: IAuthActions;
    profiles: IProfile[];
}

const BrandSelector = ({ companyActions, companies, modalActions, isLoading, authActions, profiles }: IProps) => {
    const [selectedCompany, setSelectedCompany] = useState<any>();

    const getProfile = useCallback(() => {
        authActions.getProfileRequest();
    }, [authActions]);

    useEffect(() => getProfile(), [getProfile]);

    const setFirstSelectedCompany = useCallback(() => {
        if (companies.length === 1) {
            setSelectedCompany(companies[0]);
        }
    }, [companies]);

    useEffect(() => setFirstSelectedCompany(), [setFirstSelectedCompany]);

    const handleClickBrand = useCallback(
        (brand: IBrand, idCompany: number, units?: any[]) => {
            if (!units || units.length === 0) {
                return companyActions.setCurrentBrandRequest({ idBrand: brand.id, idCompany });
            }

            modalActions.openModal('selectUnit', { brand, idCompany, units });
        },
        [companyActions, modalActions]
    );

    const handleGodAuth = useCallback(() => {
        try {
            delete api.defaults.headers.common['X-Brand'];
            delete api.defaults.headers.common['X-Company'];
            delete api.defaults.headers.common['X-Unit'];

            delete apiMongo.defaults.headers.common['X-Brand'];
            delete apiMongo.defaults.headers.common['X-Company'];
            delete apiMongo.defaults.headers.common['X-Unit'];

            companyActions.clearNavigationHeaders();

            const payload = {
                method: 'GET',
                endpoint: '/person/menu',
                god: true
            };

            authActions.getNavbarMenuRequest(payload);
        } catch (error) {
            console.log(error);
        }
    }, [authActions, companyActions]);

    if (isLoading) {
        return (
            <BrandSelectorWrapper>
                <ModalHeader>
                    <SmallTitle>Continuar como</SmallTitle>
                </ModalHeader>

                <ModalContent>
                    <BrandSelectorLoading>
                        <Icon idIcon="ico-spinner" width="20" height="20" viewBox="0 0 50 50" />
                    </BrandSelectorLoading>
                </ModalContent>
            </BrandSelectorWrapper>
        );
    }

    return (
        <BrandSelectorWrapper>
            <ModalHeader>
                <SmallTitle>Continuar como</SmallTitle>
            </ModalHeader>
            <ModalContent style={{ margin: 0, paddingTop: '10px' }}>
                {selectedCompany && (
                    <BrandSelectorSelected>
                        <Button variant="info" block={true} onClick={() => companyActions.setCurrentCompanyRequest(selectedCompany)}>
                            {selectedCompany.name}
                        </Button>

                        {selectedCompany.brands.length > 0 ? (
                            <>
                                <BrandSelectorAccordionText>
                                    <small>ou</small>
                                    <br />
                                    Navegue por uma Marca
                                </BrandSelectorAccordionText>

                                {selectedCompany.brands.map((brand: any) => (
                                    <BrandSelectorItem key={brand.id} size="small" variant="outline-info" onClick={() => handleClickBrand(brand, selectedCompany.id, brand.units!)}>
                                        <BrandSelectorName>
                                            {brand.name}
                                            {!!brand.units && brand.units.length > 0 && (
                                                <Badge variant="info" size="small">
                                                    {brand.units.length} unidade{brand.units.length > 0 ? 's' : ''}
                                                </Badge>
                                            )}
                                        </BrandSelectorName>

                                        <Icon idIcon="ico-arrow-right" width="15" height="10" viewBox="0 0 22 14" />
                                    </BrandSelectorItem>
                                ))}
                            </>
                        ) : (
                            <BrandSelectorEmpty>
                                <p>Ainda não existe nenhuma marca cadastrada para essa empresa.</p>
                            </BrandSelectorEmpty>
                        )}

                        <Button variant="outline-info" block={true} onClick={() => setSelectedCompany(null)}>
                            Voltar
                        </Button>
                    </BrandSelectorSelected>
                )}

                {!selectedCompany &&
                    companies.map((company) => (
                        <BrandSelectorItem key={company.id} size="small" variant="outline-info" onClick={() => setSelectedCompany(company)}>
                            <BrandSelectorName>{company.name}</BrandSelectorName>

                            <Icon idIcon="ico-arrow-right" width="15" height="10" viewBox="0 0 22 14" />
                        </BrandSelectorItem>
                    ))}
            </ModalContent>

            {!!profiles && Array.isArray(profiles) && profiles.some((item) => item.type === 1) && (
                <ModalFooter style={{ borderTop: `1px solid ${theme.colors.borderColor}` }}>
                    <Button variant="success" block={true} onClick={() => handleGodAuth()}>
                        Continuar como Admin
                    </Button>
                </ModalFooter>
            )}
        </BrandSelectorWrapper>
    );
};

export default BrandSelector;
