import styled, { keyframes } from 'styled-components';

const SkeletonAnimation = keyframes`
  from {
    background: rgba(0, 0, 0, 0.04);
  }

  to {
    background: rgba(0, 0, 0, 0.08);
  }
`;

export const SkeletonItem = styled.span<{ width?: string; height: string }>`
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    animation: ${SkeletonAnimation} 0.5s alternate infinite;
    background: rgba(0, 0, 0, 0.04);
    display: flex;
`;
