const getOrderByPk = `
    query getOrderByPk($id: Int!) {
        order: prodigio_orders_by_pk(id: $id) {
            id
            amount
            boleto_barcode
            boleto_url
            code
            created
            due_date
            original_amount
            pay_date
            installments
            addresses(where: {deleted: {_is_null: true}}) {
                address_type
                city
                complement
                id
                neighborhood
                number
                state
                street
                zip_code
            }
            customer {
                document_number
                email
                id
                name
                last_name
                phone_number
                type
            }
            details {
                amount
                id
                quantity
                prices_by_payment_form {
                    id
                    price {
                        value
                        products {
                            product {
                                id
                                amount
                                name
                                thumb
                            }
                        }
                    }
                }
                order_details_physicals {
                    id
                    id_product_physical_configuration_option
                    products_physical {
                        id
                        name
                        sku
                        medias {
                            media {
                                url
                                id
                            }
                        }
                        configurations {
                            id_product_physical
                            id
                            name
                            options {
                                id
                                id_media
                                id_product_physical_configuration
                                name
                                sku
                            }
                        }
                    }
                }
            }
            payment_form {
                name
            }
            status {
                name
            }
            movimentations {
                created
                id
                updated
                order_status {
                    name
                }
            }
        }
    }
`;

const createOrderAddress = `mutation ($data: prodigio_order_addresses_insert_input!) {
    insert_prodigio_order_addresses_one(object: $data) {
        id
    }
}`;

const updateOrderAddress = `mutation ($data: prodigio_order_addresses_set_input!, $id: Int!) {
    update_prodigio_order_addresses_by_pk(pk_columns: { id: $id }, _set: $data) {
        id
    }
}`;

const deleteOrderAddress = `mutation ($date: timestamp, $id: Int!) {
    update_prodigio_order_addresses_by_pk(pk_columns: { id: $id }, _set: { deleted: $date }) {
      id
    }
  }
`;

export { createOrderAddress, deleteOrderAddress, updateOrderAddress, getOrderByPk };
