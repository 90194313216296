// Components
import Alert from './alert';

// Redux
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { Creators as AlertActions } from 'store/ducks/alert';

// Helpers
import { IReduxStore } from 'utils/interfaces/IReduxStore';
import { withRouter } from 'react-router';

const mapStateToProps = ({ alert }: IReduxStore) => ({
    alert
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    alertActions: bindActionCreators(AlertActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Alert) as any);
