import { createActions, createReducer } from 'reduxsauce';
import { ISellerState } from 'utils/interfaces/ISeller';

export const { Creators, Types } = createActions({
    getSellerRequest: ['payload'],
    getSellerSuccess: ['payload'],
    getSellerFailure: [],

    createOrEditSellerRequest: ['payload'],
    createOrEditSellerSuccess: ['payload'],
    createOrEditSellerFailure: [],

    clearSeller: []
});

const INITIAL_STATE: ISellerState = {
    isLoading: false,
    id: 0,
    name: '',
    document: '',
    juridicName: '',
    address: {
        id: 0,
        number: '',
        state: '',
        street: '',
        zipCode: '',
        complement: '',
        city: ''
    }
};

// GENERIC ACTIONS
const sellerRequest = (state = INITIAL_STATE, action: any) => ({ ...state, isLoading: true });
const sellerSuccess = (state = INITIAL_STATE, action: any) => ({ ...state, isLoading: false, ...action.payload });
const sellerFailure = (state = INITIAL_STATE) => ({ ...state, isLoading: false });

const clearSeller = (state = INITIAL_STATE) => INITIAL_STATE;

export default createReducer(INITIAL_STATE, {
    [Types.GET_SELLER_REQUEST]: sellerRequest,
    [Types.GET_SELLER_SUCCESS]: sellerSuccess,
    [Types.GET_SELLER_FAILURE]: sellerFailure,

    [Types.CREATE_OR_EDIT_SELLER_REQUEST]: sellerRequest,
    [Types.CREATE_OR_EDIT_SELLER_SUCCESS]: sellerSuccess,
    [Types.CREATE_OR_EDIT_SELLER_FAILURE]: sellerFailure,

    [Types.CLEAR_SELLER]: clearSeller
});
