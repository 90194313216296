import { lazy } from 'react';

const List = lazy(() => import('pages/crud'));
const CreateOrEdit = lazy(() => import('pages/crud/createOrEdit'));

const knowledgeAreaRoutes = [
    {
        path: '/app/areas-de-conhecimento',
        name: 'Áreas de conhecimento',
        component: List,
        exact: true,
        title: 'Áreas de conhecimento'
    },
    {
        path: '/app/areas-de-conhecimento/novo',
        name: 'Nova área de conhecimento',
        component: CreateOrEdit,
        exact: true,
        title: 'Nova área de conhecimento'
    },
    {
        path: '/app/areas-de-conhecimento/editar/:id',
        name: 'Editar',
        component: CreateOrEdit,
        exact: true,
        title: 'Editar'
    }
];

export default knowledgeAreaRoutes;
