import { lazy } from 'react';

const List = lazy(() => import('pages/crud'));
const FilesCategories = lazy(() => import('pages/filesCategories'));

const filesCategoryRoutes = [
    {
        path: '/app/arquivos/categorias',
        name: 'Categorias de Arquivo',
        component: List,
        exact: true,
        title: 'Categorias de Arquivo'
    },
    {
        path: '/app/arquivos/categorias/novo',
        name: 'Nova disciplina',
        component: FilesCategories,
        exact: true,
        title: 'Nova categoria de arquivo'
    },
    {
        path: '/app/arquivos/categorias/editar/:id',
        name: 'Editar',
        component: FilesCategories,
        exact: true,
        title: 'Editar categoria de arquivo'
    }
];

export default filesCategoryRoutes;
