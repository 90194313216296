import InputTypes from 'utils/enums/inputTypes';
import { IField } from 'utils/interfaces/IField';
import convertEnumToOptions from 'utils/convertEnumToOptions';
import { CorrectionEngineAlgorithm } from 'utils/enums/correctionEngineAlgorithm';

const correctionEngine: IField[] = [
    {
        presentation: 'Nome',
        label: 'Nome do motor',
        name: 'name',
        type: InputTypes.text,
        columns: 3
    },
    {
        presentation: 'Algoritmo',
        label: 'Algoritmo',
        name: 'algorithm',
        type: InputTypes.simpleSelect,
        columns: 3,
        data: convertEnumToOptions(CorrectionEngineAlgorithm)
    }
];

export default correctionEngine;
