import { lazy } from 'react';

const List = lazy(() => import('pages/crud'));
const Questions = lazy(() => import('pages/questions'));
const QuestionsView = lazy(() => import('pages/questions/view'));

const lessonPlanRoutes = [
    {
        path: '/app/questoes',
        name: 'Questões',
        component: List,
        exact: true,
        title: 'Questões'
    },
    {
        path: '/app/questoes/novo',
        name: 'Nova',
        component: Questions,
        exact: true,
        title: 'Nova Questão'
    },
    {
        path: '/app/questoes/editar/:id',
        name: 'Editar',
        component: Questions,
        exact: true,
        title: 'Editar Questão'
    },
    {
        path: '/app/questoes/ver/:id',
        name: 'Visualizar',
        component: QuestionsView,
        exact: true,
        title: 'Visualizar Questao'
    }
];

export default lessonPlanRoutes;
