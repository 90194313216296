import React, { useMemo, memo } from 'react';

// Dependencies
import _find from 'lodash/find';

// Helpers
import { IProductConfiguration } from 'utils/interfaces/IStore';
import { OrderProductConfiguration } from 'pages/store/styles';

const ProductConfiguration = (props: IProductConfiguration & { idOption: number }) => {
    const selectedOption = useMemo(() => _find(props.options, { id: props.idOption }), [props.idOption, props.options]);

    if (!selectedOption) {
        return null;
    }

    return (
        <OrderProductConfiguration>
            <span>
                {props.name}: {selectedOption.name} • SKU: {selectedOption.sku}
            </span>
        </OrderProductConfiguration>
    );
};

export default memo(ProductConfiguration);
