import { AxiosResponse } from 'axios';
import history from 'services/history';
import { uploadFile } from '../upload';
import { apiRequest } from '../apiRequest';
import { IReduxAction } from 'store/types/IRedux';
import { graphQLRequest } from '../graphQLRequest';
import { IProject } from 'utils/interfaces/IProject';
import * as queries from 'pages/projects/utils/queries';
import { section } from 'pages/projects/utils/mutations';
import { call, put, takeLatest } from 'redux-saga/effects';
import { Types as ProjectTypes, Creators as ProjectActions } from 'store/ducks/project';

// Middleware for get relations to feed page
function* getProjectCategories() {
    try {
        const { data } = yield graphQLRequest(queries.getProjectCategories);

        if (!data) {
            throw new Error();
        }

        yield put({ type: 'GET_PROJECT_CATEGORIES_SUCCESS', payload: data.items });
    } catch (error) {
        yield put({ type: 'GET_PROJECT_CATEGORIES_FAILURE', payload: { code: error?.response?.status } });
    }
}

function* getProject(action: IReduxAction<{ id: string }>) {
    try {
        const { data }: AxiosResponse<IProject> = yield apiRequest('GET', `/admin/project/${action.payload.id}`);

        yield put({ type: 'GET_PROJECT_SUCCESS', payload: data });
    } catch (error) {
        yield put({ type: 'GET_PROJECT_FAILURE', payload: { code: error?.response?.status } });
        yield put({ type: 'SHOW_ALERT', message: error?.response?.data?.detail?.message || 'Ocorreu um erro. Tente novamente mais tarde.', alertType: 'danger' });
    }
}

// Middleware for create or edit a project
function* createOrEditProject(action: { type: string; payload: { method: 'CREATE' | 'UPDATE'; mutation: any; body: any } }) {
    try {
        const { body, mutation, method } = action.payload;

        if (body.data.file) {
            const payloadUpload = {
                method: 'POST',
                endpoint: '/upload',
                body: {
                    file: body.data.file
                }
            };

            const response = yield call(uploadFile, payloadUpload);

            delete body.data.file;

            body.data.urlImage = response;
        }

        if (method !== 'CREATE' && body.data.tags) {
            body.tags = body.data.tags.data;

            delete body.data.tags;
        }

        const { data, errors } = yield graphQLRequest(mutation, body);

        if (errors) {
            throw new Error();
        }

        yield put({ type: 'CREATE_OR_EDIT_PROJECT_SUCCESS', payload: data });

        const alertMessage = method === 'CREATE' ? 'Projeto criado com sucesso' : 'Projeto atualizado com sucesso';

        yield put({ type: 'SHOW_ALERT', message: alertMessage, alertType: 'success' });

        if (method === 'UPDATE') {
            yield put({ type: 'CLOSE_MODAL' });

            return yield put({ type: 'GET_PROJECT_REQUEST', payload: { id: data.project.id } });
        }

        yield call(history.push, { pathname: `/app/projetos/editar/${data.project.id}` });
    } catch (error) {
        console.log('error', JSON.stringify(error));

        yield put({ type: 'SHOW_ALERT', message: error?.response?.data?.detail?.message || 'Ocorreu um erro. Tente novamente mais tarde.', alertType: 'danger' });
        yield put({ type: 'PROJECT_FAILURE' });
    }
}

// Middleware for edit resource of project
function* editResource(action: { type: string; payload: { idProject: number; mutation: any; body: any } }) {
    try {
        const { idProject, mutation, body } = action.payload;

        const { errors } = yield graphQLRequest(mutation, body);

        if (errors) {
            throw new Error();
        }

        yield put({ type: 'GET_PROJECT_REQUEST', payload: { id: idProject } });
        yield put({ type: 'CLOSE_MODAL' });
        yield put({ type: 'SHOW_ALERT', message: 'Resumo atualizado com sucesso.', alertType: 'success' });
    } catch (error) {
        yield put({ type: 'PROJECT_FAILURE' });
        yield put({ type: 'SHOW_ALERT', message: error?.response?.data?.detail?.message || 'Ocorreu um erro. Tente novamente mais tarde.', alertType: 'danger' });
    }
}

// Reorder list of resources
function* reorderResource(action: { type: string; payload: { idProject: number; mutation: any; body: any } }) {
    try {
        const { idProject, mutation, body } = action.payload;

        const { errors } = yield graphQLRequest(mutation, body);

        if (errors) {
            throw new Error();
        }

        yield put({ type: 'GET_PROJECT_REQUEST', payload: { id: idProject } });
        yield put({ type: 'CLOSE_MODAL' });
        yield put({ type: 'SHOW_ALERT', message: 'Registro atualizado com sucesso.', alertType: 'success' });
    } catch (error) {
        yield put({ type: 'SHOW_ALERT', message: error?.response?.data?.detail?.message || 'Ocorreu um erro ao tentar atualizar. Tente novamente.', alertType: 'danger' });
        yield put({ type: 'PROJECT_FAILURE' });
    }
}

// Create or edit resume
function* createOrEditEntity(action: { type: string; payload: { idProject: number; method: 'CREATE' | 'UPDATE'; mutation: any; body: any } }) {
    try {
        const { method, mutation, body, idProject } = action.payload;

        const { errors } = yield graphQLRequest(mutation, body);

        if (errors) {
            throw new Error();
        }

        yield put({ type: 'GET_PROJECT_REQUEST', payload: { id: idProject } });
        yield put({ type: 'CLOSE_MODAL' });

        const message = `Registro ${method === 'UPDATE' ? 'atualizado' : 'criado'} com sucesso`;

        yield put({ type: 'SHOW_ALERT', message, alertType: 'success' });
    } catch (error) {
        console.log('error', JSON.stringify(error));
        yield put({ type: 'SHOW_ALERT', message: 'Ocorreu um erro ao tentar atualizar. Tente novamente.', alertType: 'danger' });
        yield put({ type: 'PROJECT_FAILURE' });
    }
}

// Create or edit resume
function* createOrEditContent(action: any) {
    try {
        const { method, mutation, body, idProject } = action.payload;

        const { idMaterial, ...rest } = body;
        const { data, errors: errorsSectionContent } = yield graphQLRequest(mutation, rest);

        if (errorsSectionContent) {
            throw new Error();
        }

        // Update material content if payload exists
        if (idMaterial) {
            const { errors: errorsUpdateMaterial } = yield graphQLRequest(section.content.updateMaterial, { id: idMaterial, data: { id_section_content: data.sectionContent.id } });

            if (errorsUpdateMaterial) {
                throw new Error();
            }
        }

        yield put({ type: 'GET_PROJECT_REQUEST', payload: { id: idProject } });
        yield put({ type: 'CLOSE_MODAL' });

        const message = `Registro ${method === 'UPDATE' ? 'atualizado' : 'criado'} com sucesso`;

        yield put({ type: 'SHOW_ALERT', message, alertType: 'success' });
    } catch (error) {
        console.log('error', JSON.stringify(error));
        yield put({ type: 'SHOW_ALERT', message: 'Ocorreu um erro ao tentar atualizar. Tente novamente.', alertType: 'danger' });
        yield put({ type: 'PROJECT_FAILURE' });
    }
}

// Middleware for delete resume of project
function* deleteResource(action: { type: string; payload: { idProject: number; mutation: any; body: any } }) {
    try {
        const { idProject, mutation, body } = action.payload;

        const { errors } = yield graphQLRequest(mutation, { date: new Date(), id: body.id });

        if (errors) {
            throw new Error();
        }

        yield put({ type: 'SHOW_ALERT', message: 'Registro apagado com sucesso.', alertType: 'success' });
        yield put({ type: 'GET_PROJECT_REQUEST', payload: { id: idProject } });
    } catch (error) {
        yield put({ type: 'PROJECT_FAILURE' });
        yield put({ type: 'SHOW_ALERT', message: 'Ocorreu um erro. Tente novamente mais tarde.', alertType: 'danger' });
    }
}

function* createExhibitionPlaces(action: IReduxAction<{ brandId: number; courses?: number[]; projectId: number }>) {
    try {
        const { brandId, courses, projectId } = action.payload;

        if (!projectId || !brandId) {
            throw new Error();
        }

        let body = [
            {
                id_project: projectId,
                id_brand: brandId
            }
        ];

        if (!!courses?.length) {
            body = courses.map((id_course) => ({
                id_project: projectId,
                id_brand: brandId,
                id_course
            }));
        }

        const { errors } = yield graphQLRequest(
            `mutation createProjectExhibitionPlaces($data: [prodigio_project_exhibition_places_insert_input!]!) {
            items: insert_prodigio_project_exhibition_places(objects: $data) {
              affected_rows
            }
          }
          `,
            { data: body }
        );

        if (errors) {
            throw new Error();
        }

        yield put(ProjectActions.createProjectExhibitionPlacesSuccess());

        yield put({ type: 'CLOSE_MODAL' });
        yield put({ type: 'REFRESH_GET_ALL' });

        yield put({ type: 'SHOW_ALERT', message: 'Lista de locais de exibição atualizada.', alertType: 'success' });
    } catch (error) {
        yield put({ type: 'SHOW_ALERT', message: 'Ocorreu um erro. Tente novamente mais tarde.', alertType: 'danger' });

        yield put(ProjectActions.createProjectExhibitionPlacesFailure());
    }
}

export default [
    takeLatest(ProjectTypes.GET_PROJECT_REQUEST, getProject),
    takeLatest(ProjectTypes.EDIT_RESOURCE_REQUEST, editResource),
    takeLatest(ProjectTypes.DELETE_RESOURCE_REQUEST, deleteResource),
    takeLatest(ProjectTypes.REORDER_RESOURCE_REQUEST, reorderResource),
    takeLatest(ProjectTypes.CREATE_OR_EDIT_RESUME_REQUEST, createOrEditEntity),
    takeLatest(ProjectTypes.CREATE_OR_EDIT_MODULE_REQUEST, createOrEditEntity),
    takeLatest(ProjectTypes.CREATE_OR_EDIT_SECTION_REQUEST, createOrEditEntity),
    takeLatest(ProjectTypes.CREATE_OR_EDIT_CONTENT_REQUEST, createOrEditContent),
    takeLatest(ProjectTypes.CREATE_OR_EDIT_PROJECT_REQUEST, createOrEditProject),
    takeLatest(ProjectTypes.GET_PROJECT_CATEGORIES_REQUEST, getProjectCategories),
    takeLatest(ProjectTypes.CREATE_PROJECT_EXHIBITION_PLACES_REQUEST, createExhibitionPlaces)
];
