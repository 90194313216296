import React, { memo } from 'react';

// Dependencies
import { Link } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';

// Components
import ProductConfiguration from './configuration';

// Helpers
import formatPrice from 'utils/formatPrice';
import { IStoreOrderDetail } from 'utils/interfaces/IStore';

// Assets
import { StudentDataItem } from 'pages/student/styles';
import { OrderProductDetails, OrderProductThumb, OrderPhysicalProducts, OrderPhysicalProductsItem } from 'pages/store/styles';

const Product = (props: IStoreOrderDetail) => {
    return (
        <OrderProductDetails>
            {props.prices_by_payment_form.price.products[0].product.thumb && (
                <Col md={2}>
                    <OrderProductThumb>
                        <Link to={`/app/produtos/ver/${props.prices_by_payment_form.price.products[0].product.id}`}>
                            <img src={props.prices_by_payment_form.price.products[0].product.thumb} alt={props.prices_by_payment_form.price.products[0].product.name} />
                        </Link>
                    </OrderProductThumb>
                </Col>
            )}
            <Col md={props.prices_by_payment_form.price.products[0].product.thumb ? 10 : 12}>
                <Row>
                    <Col md={8}>
                        <StudentDataItem>
                            <strong>Produto</strong>
                            <span>
                                <Link to={`/app/produtos/ver/${props.prices_by_payment_form.price.products[0].product.id}`}>{props.prices_by_payment_form.price.products[0].product.name}</Link>
                            </span>
                        </StudentDataItem>
                    </Col>
                    <Col md={4}>
                        <StudentDataItem>
                            <strong>Valor</strong>
                            <span>{formatPrice(props.prices_by_payment_form.price.value)}</span>
                        </StudentDataItem>
                    </Col>
                    {props.order_details_physicals.length > 0 && (
                        <Col md={12} style={{ margin: '10px 0' }}>
                            <StudentDataItem>
                                <strong>Produtos físicos escolhidos</strong>
                                <OrderPhysicalProducts>
                                    {props.order_details_physicals.map((item) => (
                                        <OrderPhysicalProductsItem key={item.id}>
                                            <span>
                                                <Link to={`/app/produtos-fisicos/editar/${item.products_physical.id}`}>{item.products_physical.name}</Link> • SKU: {item.products_physical.sku}
                                            </span>
                                            {item.products_physical.configurations.length > 0 ? (
                                                item.products_physical.configurations.map((config) => (
                                                    <ProductConfiguration idOption={item.id_product_physical_configuration_option} {...config} key={config.id} />
                                                ))
                                            ) : (
                                                <span style={{ padding: 10 }}>Produto sem configuração</span>
                                            )}
                                        </OrderPhysicalProductsItem>
                                    ))}
                                </OrderPhysicalProducts>
                            </StudentDataItem>
                        </Col>
                    )}
                </Row>
            </Col>
        </OrderProductDetails>
    );
};

export default memo(Product);
