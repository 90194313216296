// Components
import AuthForm from './authForm';

// Redux
import { Dispatch, bindActionCreators } from 'redux';
import { Creators as AuthActions } from 'store/ducks/auth';

// Helpers
import { connect } from 'react-redux';
import { IReduxStore } from 'utils/interfaces/IReduxStore';

const mapStateToProps = ({ auth }: IReduxStore) => ({
    isLoading: auth.isLoading
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    authActions: bindActionCreators(AuthActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthForm as any);
