export enum BreakPoints {
    tiny = 320,
    small = 768,
    medium = 1024,
    wide = 1200,
    huge = 1400
}

export const MediaQueries = {
    SMALL: `@media only screen and (max-width: ${BreakPoints.small - 1}px)`,
    TABLET_PORTRAIT: `@media only screen and (min-width: ${BreakPoints.small}px) and (max-width: ${BreakPoints.medium - 1}px)`,
    TABLET_LANDSCAPE: `@media only screen and (min-width: ${BreakPoints.medium}px) and (max-width: ${BreakPoints.wide - 1}px)`,
    NOTEBOOK: `@media only screen and (min-width: ${BreakPoints.wide}px) and (max-width: ${BreakPoints.huge - 1}px)`,
    DESKTOP: `@media only screen and (min-width: ${BreakPoints.huge}px)`
};
