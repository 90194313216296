// Redux
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { Creators as AlertActions } from 'store/ducks/alert';

// Components
import ErrorBoundary from './error';

const mapDispatchToProps = (dispatch: Dispatch) => ({
    alertActions: bindActionCreators(AlertActions, dispatch) as any
});

export default connect(null, mapDispatchToProps)(ErrorBoundary);
