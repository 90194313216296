const dictionary = {
    validations: {
        email: 'E-mail inválido',
        required: 'Campo obrigatório',
        number: 'O valor precisa ser um número',
        numberGreaterThanZero: 'O valor precisa ser um número maior que zero',
        selection: 'Selecione ao menos um item'
    },
    crud: {
        'company.name': 'empresa',
        icon: 'ícone',
        id: 'id',
        name: 'nome',
        order: 'ordem',
        slug: 'slug',
        startDate: 'Data início',
        endDate: 'data fim',
        dateStart: 'Data início',
        dateFinish: 'Data Fim',
        'schoolPeriod.name': 'ano letivo',
        period: 'ano letivo',
        points: 'pontuação',
        examDays: 'dia(s)',
        title: 'Título',
        code: 'código',
        totalText: 'Total',
        durationDefault: 'duração padrão',
        realizationsTotal: 'total de realizações',
        realizationsAverageHitRate: 'taxa média de acertos',
        enunciation: 'Enunciado',
        month: 'mês',
        year: 'ano',
        'contest.name': 'concurso',
        contest: 'Concurso',
        urlFile: 'arquivo',
        'person.name': 'nome',
        grade: 'série',
        subjects: 'disciplinas',
        'unit.name': 'Unidade',
        'grade.name': 'Série',
        'period.name': 'Período',
        'course.name': 'Curso',
        'person.user.email': 'e-mail',
        'person.phone': 'telefone',
        'person.cpf': 'cpf',
        director: 'Diretor',
        description: 'Conteúdo',
        signature: 'Assinatura',
        clientUrl: 'URL (Cliente)',
        'exerciseList.title': 'Lista de exercício',
        'student.person.name': 'Aluno',
        status: 'Status',
        'status.name': 'Status',
        type: 'Tipo',
        brand: 'Marca',
        unit: 'Unidade',
        serie: 'Série',
        subject: 'Matéria',
        class: 'Turma',
        route: 'rota',
        path: 'Link',
        group: 'Grupo',
        download_group: 'Download',
        quantity: 'Quantidade',
        'user.person.name': 'Autor',
        'subject.name': 'Matéria',
        'metadata.subject.name': 'Matéria',
        teachers: 'Professor(es)',
        'question.enunciation': 'Enunciado',
        'metadata.year': 'Ano',
        'subject.id': 'Disciplina',
        'topic.id': 'Tópico',
        'contest.id': 'Concurso',
        'metadata.owner': 'Dono',
        'metadata.type': 'Tipo',
        'paymentForm.name': 'Forma de pagamento',
        'module.lessonPlan.name': 'Plano de estudos',
        created: 'Data',
        amount: 'Valor',
        category: 'Categoria',
        color: 'cor',
        email: 'E-mail',
        'module.lessonPlan': 'Plano de Estudos',
        'exerciseList.subject': 'Matéria',
        'student.person.user.email': 'E-mail do aluno',
        'teacher.person.name': 'Professor',
        'exerciseList.subject.name': 'Matéria',
        'answerCard.status': 'Status',
        teacher: 'Professor',
        'answerCard.teacher.name': 'Professor',
        'answerCard.teacher': 'Professor',
        'teacher.name': 'Professor',
        questionsTotal: 'Total de Questões',
        totalStudents: 'Total de Alunos',
        'student.person': 'Aluno',
        exerciseList: 'Lista de Exercícios',
        student: 'Aluno',
        'material.title': 'Material',
        'documentCategory.name': 'Categoria',
        'brand.name': 'Marca',
        brands: 'Marcas',
        provider: 'Provedor',
        'event.startDate': 'Data do evento',
        'lessonPlan.id': 'Plano de Estudos',
        'teacher.id': 'Professor',
        videoKey: 'Provedor ID',
        'video.status': 'Situação',
        Available: 'Disponível',
        Archiving: 'Arquivando',
        Archived: 'Arquivado',
        Restoring: 'Restaurando',
        'event.category': 'Categoria do evento',
        'metadata.version': 'Versão',
        course: 'Curso',
        'store.name': 'Loja',
        url: 'Url',
        algorithm: 'Algoritmo',
        'address.city': 'Cidade',
        sku: 'SKU',
        configurations: 'Configurações',
        options: 'Opções',
        'price.title': 'Nome',
        'price.value': 'Valor',
        'credit.description': 'Tipo',
        value: 'Valor',
        project: 'Projeto',
        commission: 'Comissão',
        'seller.juridic_name': 'Comissionado',
        WaitingPayment: 'Aguardando pagamento',
        AuthorizedPayment: 'Pagamento recebido',
        OrderCompleted: 'Pedido finalizado',
        UnauthorizedPayment: 'Pagamento não autorizado',
        RefundedPayment: 'Pagamento reembolsado',
        Processing: 'Em processamento',
        RefusedStatus: 'Recusado',
        actions: 'Ações',
        'customer.name': 'Nome',
        is_active: 'Ativo',
        active: 'Ativo',
        'metadata.video_type': 'Tipo',
        Live: 'Ao Vivo',
        Complementary: 'Complementar',
        Mentorship: 'Monitoria',
        Theory: 'Teoria',
        Resolution: 'Resolução',
        'events.lesson_plan_module.lesson_plan.name': 'Nome do plano de estudos',
        'events.start_date': 'Data de início do evento',
        'events.category': 'Categoria do evento',
        'learning_object.subject.id': 'Matéria',
        'lesson_plan_module.lesson_plan.name': 'Plano de estudos',
        Monitoring: 'Monitoria',
        start_date: 'Data início',
        video_key: 'Provedor ID',
        'metadata.topic.name': 'Tópico',
        'metadata.subtopic.name': 'Subtópico',
        'metadata.contest.name': 'Concurso',
        end_date: 'Data fim',
        'exercise_list.title': 'Lista de exercício',
        Done: 'Correção pendente',
        Corrected: 'Corrigida',
        Started: 'Iniciada',
        New: 'Nova',
        is_published: 'Publicado',
        'category.name': 'Categoria',
        'school_period.name': 'Ano letivo',
        'customer.email': 'E-mail do Cliente',
        'details.id_product': 'Produto',
        is_erp_synced: 'Sincronizado',
        External: 'Externo',
        Extra: 'Extra',
        number: 'Número',
        'student.subscriptions.school_class.id': 'Turma',
        Simple: 'Simples',
        date: 'Data',
        AverageBetweenHitsAndQuestions: 'Média entre acertos e questões',
        timeout: 'Tempo',
        periodicity: 'Periodicidade',
        'category.title': 'Categoria',
        'genre.title': 'Gênero textual',
        complexity: 'Complexidade',
        idGenre: 'Gênero Textual',
        idCategory: 'Categoria',
        Average: 'Média aritmética',
        Sum: 'Soma',
        correctionEngine: 'Mecanismo de correção'
    },
    general: {
        New: 'Nova',
        Started: 'Iniciada',
        Done: 'Correção pendente',
        Corrected: 'Corrigida',
        Normal: 'Lista normal',
        Extra: 'Lista extra',
        Free: 'Disponível',
        Used: 'Utilizado',
        Cancelled: 'Cancelado',
        Live: 'Ao Vivo',
        Complementary: 'Complementar',
        Mentorship: 'Monitoria',
        VirtualRoom: 'Sala virtual',
        Theory: 'Teoria',
        Resolution: 'Resolução',
        paid: 'Pago',
        Monitoring: 'Monitoria',
        Simple: 'Simples',
        Pending: 'Pendente',
        Aproved: 'Aprovada',
        Discarted: 'Descartada',
        InPerson: 'Presencial',
        Finished: 'Finalizado',
        Discarded: 'Descartado',
        Draft: 'Rascunho',
        CorrectorPending: 'Correção pendente',
        Easy: 'Fácil',
        Average: 'Médio',
        Hard: 'Difícil',
        Basic: 'Básico',
        Intermediate: 'Intermediário',
        Advanced: 'Avançado',
        withUnansweredPosts: 'Tópicos com posts não respondidos'
    },
    course: {
        Video: 'Vídeo(s) relacionado(s)',
        ExerciseList: 'Lista(s) de exercício(s)',
        Material: 'Material(is) didático(s)',
        'Ao Vivo': 'Ao Vivo',
        Apostila: 'Apostila(s)',
        Modal: 'Modal',
        Self: 'Na mesma aba ou janela',
        Blank: 'Em outra aba ou janela'
    },
    lo: {
        Video: 'Vídeo',
        Booklet: 'Apostila',
        ExerciseList: 'Lista de exercícios',
        Material: 'Material didático',
        Apostila: 'Apostila'
    },
    question: {
        Objective: 'Objetiva',
        Discursive: 'Discursiva'
    },
    relatedContent: {
        bookletmodule: 'Módulo(s) relacionado(s)',
        video: 'Vídeo(s) relacionado(s)',
        relatedvideo: 'Vídeo(s) relacionado(s)',
        exerciseList: 'Lista(s) relacionada(s)',
        material: 'Material(is) relacionado(s)',
        apostila: 'Apostila(s) relacionada(s)',
        lessonPlanModule: 'Plano(s) de estudo(s)'
    },
    credits: {
        Subscription: 'Assinatura',
        Live: 'Ao vivo',
        Course: 'Curso',
        Essay: 'Redação',
        Exam: 'Simulado',
        Project: 'Projeto',
        Mentorship: 'Monitoria',
        Device: 'Aparelho'
    }
};

export default dictionary;
