import styled, { css } from 'styled-components/macro';
import theme from 'config/theme';
import { Input } from '@rocketseat/unform';
import { SVG } from 'components/icons/styles';
import asyncSelect from 'react-select/async';
import ReactSelect from 'react-select';
import hexToRgba from 'utils/hexToRgba';
import CurrencyInput from 'react-currency-input';

export const InputPrefix = styled.div`
    position: absolute;
    background: #ced3db;
    height: calc(100% - 4px);
    border-radius: 3px 0 0 3px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    font-size: 12px;
    left: 2px;
    top: 2px;
    transition: all 0.2s ease;
    max-width: 65px;
    width: 65px;

    span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
`;

const InputStyle = css`
    border: 2px solid ${theme.colors.components.inputBorder} !important;
    background-color: #fff !important;
    border-radius: 6px !important;
    color: ${theme.colors.baseDark} !important;
    font-size: ${theme.typography.sizes.normal} !important;
    letter-spacing: 0.2px;
    transition: all 0.2s ease;
    white-space: nowrap;
    width: 100%;

    &:hover {
        background: #f5f5f5 !important;
    }

    &:focus {
        border-color: ${theme.colors.secondary} !important;

        & ~ ${InputPrefix} {
            background: ${theme.colors.secondary};
            color: ${theme.colors.white};
        }
    }

    &::placeholder {
        color: ${hexToRgba(theme.colors.base, 0.7)} !important;
        font-family: ${theme.typography.family.primary.light.italic};
        letter-spacing: -0.2px;
    }

    &:disabled {
        background: #efefef !important;
        border-color: #efefef !important;
        cursor: no-drop !important;
        color: #b1b1b1 !important;
    }

    &[type='date']::-webkit-clear-button {
        display: none;
    }

    /* Removes the spin button */
    &[type='date']::-webkit-inner-spin-button {
        display: none;
    }

    /* Always display the drop down caret */
    &[type='date']::-webkit-calendar-picker-indicator {
        color: #2c3e50;
    }

    /* A few custom styles for date inputs */
    &[type='date'] {
        padding: 9px !important;
        appearance: none;
        -webkit-appearance: none;
        display: inline-block !important;
        visibility: visible !important;
    }

    &[type='date'],
    focus {
        color: #95a5a6;
        box-shadow: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
    }
`;
export const InputElement = styled(Input)`
    ${InputStyle};
`;

export const CurrencyElement = styled(CurrencyInput)`
    ${InputStyle};
`;

export const InputElementTextArea = styled(Input).attrs({
    multiline: true
})`
    ${InputStyle};

    padding: 10px;

    ${({ multiline }) =>
        multiline &&
        css`
            height: 120px;
            white-space: pre-wrap;
        `}
`;

export const Label = styled.label`
    display: block;
    font-family: ${theme.typography.family.primary.bold.regular};
`;

export const InputWrapper = styled.div<{ hasIcon?: boolean; hasError?: boolean; hasPrefix?: boolean }>`
    position: relative;

    ${SVG} {
        left: 11px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }

    ${InputElement} + span {
        color: ${theme.colors.system.danger};
        font-style: italic;
        font-size: 12px;
        display: block;
        margin-top: 5px;
    }

    ${({ hasError, hasIcon, hasPrefix }) => {
        if (hasIcon) {
            return css`
                ${InputElement} {
                    padding: 10px 10px 10px 40px;
                }
            `;
        }

        if (hasPrefix) {
            return css`
                ${InputElement} {
                    padding: 10px 10px 10px 75px;
                }
            `;
        }

        if (hasError) {
            return css`
                ${InputElement} {
                    border-color: ${theme.colors.system.danger} !important;
                }
            `;
        }

        return css``;
    }}
`;

export const SelectWrapper = styled.div`
    [class*='-Input'] {
        margin: none !important;
        padding-bottom: none !important;
        padding-top: none !important;
        visibility: hidden !important;
        color: transparent !important;
    }

    position: relative;

    ${SVG} {
        left: 11px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }

    span {
        color: ${theme.colors.system.danger};
        font-style: italic;
        font-size: 12px;
        display: block;
        margin-top: 5px;
    }

    .selected_styled {
        width: 100%;

        [class*='-is-disabled'] {
            .select__single-value {
                color: rgba(0, 0, 0, 0.6) !important;
            }
        }
    }
`;

type FormGroupStyles = {
    withSpacing?: boolean;
    withSpanStyled?: boolean;
};

export const FormGroup = styled.div<FormGroupStyles>`
    margin-bottom: ${theme.spacing.medium.all};

    ${({ withSpacing }) =>
        withSpacing &&
        css`
            margin: ${theme.spacing.small.vertical};
        `}

    ${({ withSpanStyled }) =>
        withSpanStyled &&
        css`
            > span {
                color: ${theme.colors.system.danger};
                display: block;
                font-size: ${theme.typography.sizes.small};
                margin-top: 5px;
            }
        `}

    .react-datepicker-popper {
        width: 100%;
    }

    ${Label} {
        margin-bottom: ${theme.spacing.small.all};
    }
`;

export const CheckboxHelper = styled.span`
    background: ${theme.colors.white};
    border-radius: 3px;
    align-items: center;
    display: flex;
    height: 20px;
    justify-content: center;
    width: 20px;

    &:after {
        content: '';
        display: block;
    }
`;

export const CheckboxItem = styled.input`
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
    cursor: pointer;

    label {
        margin-left: ${theme.spacing.small.all};
    }

    &:hover ~ ${CheckboxHelper} {
        background: #f5f5f5;
    }

    &:focus ~ ${CheckboxHelper} {
        border-color: ${theme.colors.secondary};
    }

    &:checked ~ ${CheckboxHelper} {
        background: ${theme.colors.secondary};
        border-color: ${theme.colors.secondary};
    }

    &:disabled ~ ${CheckboxHelper} {
        background: #e1e1e1;
        border-color: #e1e1e1;
        cursor: no-drop;
    }
`;

export const CheckboxLabel = styled.span`
    margin-left: ${theme.spacing.small.all};
`;

export const CheckboxContainer = styled.label<{ hasError?: boolean }>`
    align-items: center;
    cursor: pointer;
    display: flex;

    ${CheckboxHelper} {
        border: 2px solid ${({ hasError }) => (hasError ? theme.colors.system.danger : theme.colors.components.inputBorder)};
    }

    ${CheckboxLabel} {
        color: ${({ hasError }) => (hasError ? theme.colors.system.danger : theme.colors.base)};
    }
`;

export const CheckboxWrapper = styled.div`
    display: flex;
    flex-direction: row-reverse;
    position: relative;

    > input {
        border: none !important;
        height: 20px;
        width: 20px;
    }

    > label {
        margin-left: ${theme.spacing.small.all};
    }
`;

export const FormFields = styled.div`
    margin-bottom: ${theme.spacing.medium.all};
`;

export const SelectElement = styled(ReactSelect)<{ error?: boolean }>`
    ${({ error }) =>
        error &&
        css`
            .select__control {
                border-width: 2px !important;
                border-color: ${theme.colors.system.danger} !important;
            }
        `}

    .select__control {
        border: 2px solid ${theme.colors.components.inputBorder};
        min-height: 43px;

        &:hover {
            background: #f5f5f5;
            border: 2px solid ${theme.colors.components.inputBorder};
        }

        &--is-focused {
            border-color: ${theme.colors.secondary} !important;
        }
    }
`;

export const SelectAsync = styled(asyncSelect)<{ error?: boolean }>`
    ${({ error }) =>
        error &&
        css`
            .select__control {
                border-width: 2px !important;
                border-color: ${theme.colors.system.danger} !important;
            }
        `}

    .select__control {
        border: 2px solid ${theme.colors.components.inputBorder};
        min-height: 43px;

        &:hover {
            background: #f5f5f5;
            border: 2px solid ${theme.colors.components.inputBorder};
        }

        &--is-focused {
            border-color: ${theme.colors.secondary} !important;
        }
    }
`;

export const AsyncSelect = styled(asyncSelect)`
    [class*='control'] {
        &:hover {
            border-color: ${theme.colors.secondary};
        }

        background-color: #fff;
        /* border-color: #d2d2d2; */
        border: 2px solid #ced3db;
        min-height: 43px;
    }

    [class*='-input'] {
        border: 0 !important;
        input {
            border: 0 !important;
        }
    }

    [id*='-input'] {
        border: 0 !important;
        input {
            border: 0 !important;
        }
    }
`;

export const RadioItem = styled.input`
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
    cursor: pointer;

    label {
        margin-left: ${theme.spacing.small.all};
    }

    &:hover ~ ${CheckboxHelper} {
        background: #f5f5f5;
    }

    &:focus ~ ${CheckboxHelper} {
        border-color: ${theme.colors.secondary};
    }

    &:checked ~ ${CheckboxHelper} {
        background: ${theme.colors.secondary};
        border-color: ${theme.colors.secondary};

        &:after {
            content: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" height="12" width="12" viewBox="0 0 26 26"><path d="M.3 14c-.2-.2-.3-.5-.3-.7s.1-.5.3-.7l1.4-1.4c.4-.4 1-.4 1.4 0l.1.1 5.5 5.9c.2.2.5.2.7 0L22.8 3.3h.1c.4-.4 1-.4 1.4 0l1.4 1.4c.4.4.4 1 0 1.4l-16 16.6c-.2.2-.4.3-.7.3-.3 0-.5-.1-.7-.3L.5 14.3.3 14z" fill="white" /></svg>');
        }
    }

    &:disabled ~ ${CheckboxHelper} {
        background: #e1e1e1;
        border-color: #e1e1e1;
        cursor: no-drop;

        &:after {
            content: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" height="12" width="12" viewBox="0 0 26 26"><path d="M.3 14c-.2-.2-.3-.5-.3-.7s.1-.5.3-.7l1.4-1.4c.4-.4 1-.4 1.4 0l.1.1 5.5 5.9c.2.2.5.2.7 0L22.8 3.3h.1c.4-.4 1-.4 1.4 0l1.4 1.4c.4.4.4 1 0 1.4l-16 16.6c-.2.2-.4.3-.7.3-.3 0-.5-.1-.7-.3L.5 14.3.3 14z" fill="black" /></svg>');
        }
    }
`;

export const FormElementWrapper = styled.div<{ hasError?: boolean }>`
    ol li {
        list-style: decimal;
    }

    ul li {
        list-style: inside;
    }

    ${({ hasError }) => {
        if (!hasError) {
            return;
        }

        return css`
            .fr-box {
                border-color: ${theme.colors.system.danger} !important;
            }

            .react-datepicker-wrapper input {
                border-color: ${theme.colors.system.danger} !important;
            }

            .currency-input {
                border-color: ${theme.colors.system.danger} !important;
            }

            .rdl-control {
                border-color: ${theme.colors.system.danger} !important;
            }
        `;
    }}

    .indent-left svg {
        -moz-transform: scaleX(-1);
        -o-transform: scaleX(-1);
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
    }

    input::placeholder {
        color: ${hexToRgba(theme.colors.base, 0.7)} !important;
        font-family: ${theme.typography.family.primary.light.italic};
        letter-spacing: -0.2px;
    }
`;

export const InputColorContainer = styled.div<{ color: string }>`
    position: relative;

    ${({ color }) =>
        !!color &&
        css`
            input {
                border-color: ${color} !important;
            }
        `}
`;

export const InputColorPicker = styled.div<{ active: boolean }>`
    position: absolute;
`;

export const FormHelper = styled.div`
    display: block;
    font-style: italic;
    font-size: 12px;
    margin: -5px 0 10px;

    strong {
        color: ${theme.colors.primary};
    }
`;

export const ContainerRadioBox = styled.div`
    display: flex;
    margin-bottom: 10px;

    label {
        margin-right: 20px;
    }

    span {
        border-radius: 50%;
    }
`;

export const ContainerDualList = styled.div`
    .rdl-control {
        border: 2px solid #ced3db;
        border-radius: 6px;
    }

    .rdl-control-container {
        height: 300px;
    }

    .rdl-move-all {
        display: block;
    }

    option {
        cursor: pointer;
        color: #818c9d;
    }
`;
