import { put } from 'redux-saga/effects';
import { Creators as alertActions } from 'store/ducks/alert';

export function* showAlertError(error?: any, defaultMsg?: string) {
    try {
        const msgError = error?.response?.data?.detail ?? defaultMsg ?? 'Ocorreu um erro. Verifique se você está conectado a internet.';

        yield put(alertActions.showAlert(msgError, 'danger'));
    } catch (error) {
        console.log(error);
    }
}
