import React from 'react';

// Assets
import { SwitchTrail, SwitchCircle } from './styles';

interface IProps {
    active: boolean;
    onClick: () => void;
    disabled?: boolean;
}

const Switch = ({ active, onClick, disabled }: IProps) => (
    <SwitchTrail type="button" active={active} onClick={onClick} disabled={disabled}>
        <SwitchCircle />
    </SwitchTrail>
);

export default Switch;
