import { put, call } from 'redux-saga/effects';
import { Creators as videosActions } from 'store/ducks/videos';
import { Creators as alertActions } from 'store/ducks/alert';
import { Creators as modalActions } from 'store/ducks/modal';
import { apiRequest } from '../apiRequest';
import history from 'services/history';
import { IRequestAction } from 'utils/interfaces/IRequestAction';
import { answerQuestionsStatus } from 'utils/enums/answerQuestionsStatus';

export function* searchVideos(action: any) {
    try {
        const { data } = yield apiRequest('GET', '/admin/video', null, { 'X-Where': `title=%${action.payload}%` });

        yield put(videosActions.searchVideosSuccess(data));
    } catch (error) {
        yield put(videosActions.searchVideosFailure());
        yield put(alertActions.showAlert('Não foi possível pesquisar os vídeos.', 'danger'));
    }
}

export function* getVideoById(action: IRequestAction<any>) {
    try {
        const { payload } = action;

        const { data } = yield apiRequest(payload.method, payload.endpoint, payload.body, payload.headers);

        yield put(videosActions.getVideoByIdSuccess(data));
    } catch (error) {
        yield put(videosActions.getVideoByIdFailure());

        // @ts-ignore
        yield call(history.push, `/app/videos`);
        yield put(alertActions.showAlert('Não foi possível encontrar esse registro.', 'danger'));
    }
}

export function* createOrEditVideo(action: IRequestAction<any>) {
    try {
        const { payload } = action;
        const { data } = yield apiRequest(payload.method, payload.endpoint, payload.body, payload.headers);

        yield put(videosActions.createOrEditVideoSuccess(data));
        if (payload.method === 'POST') {
            yield call(history.push, { pathname: `/app/videos/editar/${data.id}` });
        }

        yield put(alertActions.showAlert('O vídeo foi salvo com sucesso.', 'success'));
    } catch (error) {
        yield put(videosActions.createOrEditVideoFailure());
        yield put(alertActions.showAlert('Ocorreu um erro. Tente novamente mais tarde.', 'danger'));
    }
}

export function* associateVideoQRCode(action: IRequestAction<any>) {
    try {
        const { payload } = action;

        const { data } = yield apiRequest(payload.method, payload.endpoint, payload.body, payload.headers);

        yield put(videosActions.associateVideoQrCodeSuccess(data));

        yield put(alertActions.showAlert('QR Code associado!', 'success'));
        yield put(modalActions.closeModal());
    } catch (error) {
        yield put(videosActions.associateVideoQrCodeFailure());

        yield put(alertActions.showAlert('Ocorreu um erro. Tente novamente mais tarde.', 'danger'));
    }
}

export function* getTopicRequest(action: any) {
    try {
        const { learningObjectId } = action.payload;
        const { data } = yield apiRequest('GET', `admin/forum/topic/reference/${learningObjectId}`);

        yield put(
            videosActions.getTopicSuccess({
                id: data.id,
                isCreated: !!Object.values(data).length,
                status: data.status
            })
        );
    } catch (error) {
        yield put(videosActions.getTopicFailure());

        yield put(alertActions.showAlert('Não foi possivel carregar informações do tópico de dúvidas', 'warning'));
    }
}

export function* activateOrDeactivateTopic(action: any) {
    const { learningObjectId, topicId, isCreated, status } = action.payload;

    const statusKeys = {
        0: 'deactivate',
        1: 'activate',
        2: 'turnreadonly'
    };

    if (!isCreated && [answerQuestionsStatus.Ativado, answerQuestionsStatus['Somente leitura']].includes(status as answerQuestionsStatus)) {
        try {
            yield apiRequest('POST', `admin/forum/topic/lo/${learningObjectId}`);

            const { data } = yield apiRequest('GET', `admin/forum/topic/reference/${learningObjectId}`);

            if (status === answerQuestionsStatus['Somente leitura']) {
                yield apiRequest('PATCH', `admin/forum/topic/${data.id}/${statusKeys[status]}`);
            }

            yield put(videosActions.activateOrDeactivateTopicSuccess({ id: data.id, isCreated: true, status }));
        } catch (error) {
            yield put(videosActions.activateOrDeactivateTopicFailure());
            yield put(alertActions.showAlert('Erro ao criar tópico de dúvidas', 'warning'));
        }
    }

    if (isCreated) {
        try {
            yield apiRequest('PATCH', `admin/forum/topic/${topicId}/${statusKeys[status]}`);

            yield put(videosActions.activateOrDeactivateTopicSuccess({ id: topicId, isCreated, status }));
        } catch (error) {
            yield put(videosActions.activateOrDeactivateTopicFailure());
            yield put(alertActions.showAlert('Erro ao alterar status do tópico de dúvidas', 'warning'));
        }
    }
}
