import React from 'react';
import moment from 'moment';
import { isValidDate } from 'utils/isValidDate';
import Button from 'components/button';
import Icon from 'components/icons/icon';
import Avatar from 'components/avatar';
import { TableItemPersonContainer, TableItemText, TableItemColor, TableV2Link } from './styles';
import Badge from 'components/badge';
import formatDate from 'utils/formatDate';
import { TableItemType } from 'utils/enums/tableItemType';
import dictionary from 'config/dictionary';
import { renderBadgeVariantByStatus } from 'utils/renderBadgeByStatus';
import _get from 'lodash/get';
import { getRichTextString } from 'utils/getRichTextString';
import TableItemPerson from './person';
import { useModal } from 'hooks/useModal';
import { answerQuestionsStatus } from 'utils/enums/answerQuestionsStatus';
interface IProps {
    value: any;
    type: TableItemType;
    enumValue?: any;
    name: string;
    customComponent?(item: any): any;
    subItem?: string;
    path?: string;
}

const TableItem = ({ value, type, enumValue, name, customComponent, subItem, path }: IProps) => {
    const empty = '-';
    const modal = useModal();

    if (value === null || value === undefined) {
        return empty;
    }

    const variant = { 0: 'danger', 1: 'success', 2: 'warning' };

    switch (type) {
        case TableItemType.Color:
            return (
                <TableItemColor color={value}>
                    <figure />

                    <span>{value}</span>
                </TableItemColor>
            );

        case TableItemType.Text:
        case TableItemType.Number:
        case TableItemType.Email:
            return (
                <div style={{ display: 'grid' }}>
                    <TableItemText>{value === '' ? empty : value ?? empty}</TableItemText>
                </div>
            );

        case TableItemType.Custom:
            if (!customComponent) {
                return <span>{empty}</span>;
            }

            return customComponent(value);

        case TableItemType.Date:
            return <span>{formatDate('DD/MM/YYYY', value)}</span>;

        case TableItemType.Calendar:
            return <span>{isValidDate(value) ? moment(value).format('LLL') : empty}</span>;

        case TableItemType.Array:
            if (!Array.isArray(value)) {
                return empty;
            }

            if (!subItem) {
                return (
                    value?.map((item: any, index: number) => (
                        <Badge key={index} variant="primary">
                            {item}
                        </Badge>
                    )) ?? empty
                );
            }

            return (
                value?.map((item: any, index: number) => {
                    const itemValue = _get(item, subItem!);

                    if (!itemValue) {
                        return null;
                    }

                    return (
                        <Badge key={`array_${index}`} variant="primary">
                            {itemValue}
                        </Badge>
                    );
                }) ?? empty
            );

        case TableItemType.Link:
            return (
                <Button withIcon={true} variant="outline-success" style={{ padding: 10 }} onClick={() => window.open(value, '_blank')}>
                    <Icon idIcon="ico-download" width="15" height="15" viewBox="0 0 433.5 433.5" />
                    <span style={{ margin: '0 0 0 5px' }}>Download</span>
                </Button>
            );

        case TableItemType.RichText:
            return (
                <div style={{ maxWidth: '100%', overflow: 'hidden', display: 'grid' }}>
                    <TableItemText>{getRichTextString(value?.substring(0, 200) ?? '')}</TableItemText>
                </div>
            );

        case TableItemType.Price:
            return <span>{`R$ ${value.toFixed(2).replace('.', ',') || '0'}`}</span>;

        case TableItemType.Enum:
            const badgeVariant = renderBadgeVariantByStatus(value, enumValue); // eslint-disable-line

            const label = dictionary.general[enumValue[value]] ?? dictionary.crud[enumValue[value]] ?? enumValue[value]; // eslint-disable-line

            return !!label ? <Badge variant={badgeVariant}>{label}</Badge> : value ?? <span>{empty}</span>;

        case TableItemType.Teacher:
            if (Array.isArray(value)) {
                return (
                    <div style={{ maxWidth: '100%', display: 'grid' }}>
                        {value
                            .filter((item: any) => !!item?.teacher?.person)
                            .filter((_, index) => index <= 0)

                            .map((item: any, index) => (
                                <TableItemPersonContainer key={`teacher_${index}`} style={{ overflow: 'hidden' }}>
                                    <Avatar tooltip={false} {...(!!item?.teacher?.image && { avatarPath: item.teacher.image })} name={item?.teacher?.person?.name} size="small" />
                                    <p>{item?.teacher?.person?.name}</p>
                                </TableItemPersonContainer>
                            ))}
                    </div>
                );
            }

            return empty;

        case TableItemType.Person:
            return <TableItemPerson {...value} />;

        case TableItemType.Boolean:
            return <Badge variant={!!value ? 'success' : 'danger'}>{!!value ? 'Sim' : 'Não'}</Badge>;

        case TableItemType.ArrayText:
            return (
                <div style={{ maxWidth: '100%', overflow: 'hidden', display: 'grid' }}>
                    <TableItemText>{value?.map((item: any) => _get(item, subItem || 'name', '-'))?.join(', ')}</TableItemText>
                </div>
            );

        case TableItemType.ArrayTextTooltip:
            return (
                <div style={{ maxWidth: '100%', overflow: 'hidden', display: 'grid' }}>
                    {value.length > 3 ? (
                        <TableItemText>
                            {value
                                ?.slice(0, 3)
                                .map((item: any) => _get(item, subItem || 'name', '-'))
                                ?.join(', ')}
                            ...
                            <span className="tooltip">{value?.map((item: any) => _get(item, subItem || 'name', '-'))?.join(', ')}</span>
                        </TableItemText>
                    ) : (
                        <TableItemText>{value?.map((item: any) => _get(item, subItem || 'name', '-'))?.join(', ')}</TableItemText>
                    )}
                </div>
            );

        case TableItemType.Clickable:
            // eslint-disable-next-line
            const url = (path || '')?.replace(':id', value?.id);
            // eslint-disable-next-line
            const text = !!subItem ? _get(value, subItem, '-') : value?.name;

            return (
                <TableV2Link to={url} onClick={() => modal.close()}>
                    {text}
                </TableV2Link>
            );

        case TableItemType.Status:
            return <Badge variant={variant[value] || 'success'}>{answerQuestionsStatus[value]}</Badge>;

        default:
            return <span>{value ?? empty}</span>;
    }
};

export default TableItem;
