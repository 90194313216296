// Usado no ContentPerType do Curso
export const formatLoType = {
    Video: 'video',
    Material: 'material',
    ExerciseList: 'exerciseList',
    video: 'video',
    material: 'material',
    exerciselist: 'exerciseList',
    Playlist: 'playlist',
    playlist: 'playlist'
};
