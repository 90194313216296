import { put, call } from 'redux-saga/effects';
import { Creators as schoolClassActions } from 'store/ducks/schoolClass';
import { Creators as alertActions } from 'store/ducks/alert';
import { Creators as modalActions } from 'store/ducks/modal';
import history from 'services/history';
import { apiRequest } from '../apiRequest';
import { IRequestAction } from 'utils/interfaces/IRequestAction';

export function* getSchoolClasses(action: any) {
    try {
        const { data } = yield apiRequest('GET', '/schoolclass', null, {
            'X-Relations': !!action.payload && !!action.payload.grade ? 'subscriptions;subscriptions.student;subscriptions.student.person' : 'unit;grade;period;course',
            'X-Where': !!action.payload && !!action.payload.grade ? `grade=${action.payload.grade}` : '',
            'X-No-Pagination': true
        });

        yield put(schoolClassActions.getSchoolClassesSuccess(data.items));
    } catch (error) {
        console.log('error', error);
        yield put(schoolClassActions.getSchoolClassesFailure());
        yield put(alertActions.showAlert('Não foi possível recuperar as turmas.', 'danger'));
    }
}

export function* getSchoolClassById(action: any) {
    try {
        const { payload } = action;

        const { data } = yield apiRequest(payload.method, payload.endpoint, payload.body, payload.headers);

        yield put(schoolClassActions.getSchoolClassByIdSuccess(data));
    } catch (error) {
        // @ts-ignore
        yield call(history.push, `/app/turmas`);
        yield put(schoolClassActions.getSchoolClassByIdFailure());
        yield put(alertActions.showAlert('Não foi possível encontrar esse registro.', 'danger'));
    }
}

export function* createOrEditSchoolClass(action: any) {
    try {
        const { payload } = action;

        const { data } = yield apiRequest(payload.method, payload.endpoint, payload.body, payload.headers);

        yield put(schoolClassActions.createOrEditSchoolClassSuccess(data));

        yield put(alertActions.showAlert(`Turma ${payload.method === 'POST' ? 'Criada' : 'Atualizada'}!`, 'success'));

        if (payload.method === 'POST') {
            // @ts-ignore
            yield call(history.push, `/app/turmas/editar/${data.id}`);
        }
    } catch (error) {
        yield put(schoolClassActions.createOrEditSchoolClassFailure());
        yield put(alertActions.showAlert('Ocorreu um erro. Tente novamente mais tarde.', 'danger'));
    }
}

export function* createOrEditTeacherSchoolClass(action: any) {
    try {
        const { payload } = action;

        const { data } = yield apiRequest(payload.method, payload.endpoint, payload.body, payload.headers);

        yield put(schoolClassActions.createOrEditTeacherSchoolClassSuccess(data));

        yield put(alertActions.showAlert(`Professor Adicionado!`, 'success'));
        yield put(modalActions.closeModal());
    } catch (error) {
        yield put(schoolClassActions.createOrEditSchoolClassFailure());
        yield put(alertActions.showAlert('Ocorreu um erro. Tente novamente mais tarde.', 'danger'));
    }
}

export function* deleteTeacherSchoolClass(action: any) {
    try {
        const { payload } = action;

        yield apiRequest(payload.method, payload.endpoint, payload.body, payload.headers);

        yield put(schoolClassActions.deleteTeacherSchoolClassSuccess(payload.body));

        yield put(alertActions.showAlert(`Professor Removido!`, 'success'));
        yield put(modalActions.closeModal());
    } catch (error) {
        yield put(schoolClassActions.deleteTeacherSchoolClassFailure());
        yield put(alertActions.showAlert('Ocorreu um erro. Tente novamente mais tarde.', 'danger'));
    }
}

export function* createOrEditStudentSchoolClass(action: any) {
    try {
        const { payload } = action;

        const { data } = yield apiRequest(payload.method, payload.endpoint, payload.body, payload.headers);

        yield put(schoolClassActions.createOrEditStudentSchoolClassSuccess(data));

        yield put(alertActions.showAlert(`Lista de Alunos Atualizada!`, 'success'));
        yield put(modalActions.closeModal());
    } catch (error) {
        yield put(schoolClassActions.createOrEditStudentSchoolClassFailure());
        yield put(alertActions.showAlert('Ocorreu um erro. Tente novamente mais tarde.', 'danger'));
    }
}

export function* deleteStudentSchoolClass(action: any) {
    try {
        const { payload } = action;

        yield apiRequest(payload.method, payload.endpoint, payload.body, payload.headers);

        yield put(schoolClassActions.deleteStudentSchoolClassSuccess(payload.body));

        yield put(alertActions.showAlert(`Aluno removido da Turma!`, 'success'));
        yield put(modalActions.closeModal());
    } catch (error) {
        yield put(schoolClassActions.deleteStudentSchoolClassFailure());
        yield put(alertActions.showAlert('Ocorreu um erro. Tente novamente mais tarde.', 'danger'));
    }
}

export function* getTeachersBySubject(action: IRequestAction<any>) {
    try {
        const { payload } = action;

        const { data } = yield apiRequest(payload.method, payload.endpoint, payload.body, payload.headers);

        yield put(schoolClassActions.getTeacherSchoolClassSuccess(data.items));
    } catch (error) {
        console.log(error);
        yield put(schoolClassActions.getTeacherSchoolClassFailure());
    }
}
