import { Creators as answerCardActions } from 'store/ducks/answerCard';
import { Creators as authActions } from 'store/ducks/auth';
import { Creators as crudActions } from 'store/ducks/crud';
import { Creators as companyActions } from 'store/ducks/company';
import { Creators as courseActions } from 'store/ducks/course';
import { Creators as examActions } from 'store/ducks/exam';
import { Creators as exerciseListActions } from 'store/ducks/exerciseList';
import { Creators as dashboardActions } from 'store/ducks/dashboard';
import { Creators as projectActions } from 'store/ducks/project';
import { Creators as questionsActions } from 'store/ducks/questions';
import { Creators as searchActions } from 'store/ducks/search';
import { Creators as timezoneActions } from 'store/ducks/timezone';

const mapActions = {
    LOGIN_REQUEST: authActions.loginRequest,
    LOGOUT: authActions.logout,

    GET_ALL_REQUEST: crudActions.getAllRequest,
    GET_ALL_GRAPHQL_REQUEST: crudActions.getAllGraphqlRequest,
    GET_WITH_RELATIONS_REQUEST: crudActions.getWithRelationsRequest,
    GET_ONE_REQUEST: crudActions.getOneRequest,
    DELETE_REQUEST: crudActions.deleteRequest,
    GRAPHQL_DELETE_REQUEST: crudActions.graphqlDeleteRequest,
    CREATE_OR_EDIT_REQUEST: crudActions.createOrEditRequest,
    CLEAR_ALL_REQUEST: crudActions.clearAllRequest,

    GET_COMPANIES_REQUEST: companyActions.getCompaniesRequest,
    SET_CURRENT_BRAND_REQUEST: companyActions.setCurrentBrandRequest,

    GET_COURSE_BY_ID_REQUEST: courseActions.getCourseByIdRequest,
    GET_ALL_ENTITIES_REQUEST: courseActions.getAllEntitiesRequest,
    CREATE_MODULE_REQUEST: courseActions.createModuleRequest,
    DELETE_MODULE_REQUEST: courseActions.deleteModuleRequest,

    GET_EXAMS_REQUEST: examActions.getExamsRequest,
    GET_EXAM_REQUEST: examActions.getExamRequest,
    CREATE_OR_EDIT_EXAM_REQUEST: examActions.createOrEditExamRequest,
    UPDATE_EXAM_DAY_REQUEST: examActions.getExamsRequest,

    CREATE_EXERCISE_LIST_ITEM_REQUEST: examActions.getExamsRequest,
    DELETE_EXERCISE_LIST_ITEM_REQUEST: examActions.getExamsRequest,

    GET_EXERCISE_LIST_REQUEST: exerciseListActions.getExerciseListRequest,
    CREATE_OR_EDIT_EXERCISE_LIST_REQUEST: exerciseListActions.exerciseListRequest,

    GET_DASHBOARD_CONTENT_REQUEST: dashboardActions.getDashboardContentRequest,
    GET_ANSWER_CARD_REQUEST: answerCardActions.getAnswerCardRequest,

    GET_PROJECT_CATEGORIES_REQUEST: projectActions.getProjectCategoriesRequest,

    GET_INITIAL_DATA_REQUEST: projectActions.getInitialDataRequest,
    GET_PROJECT_REQUEST: projectActions.getProjectRequest,

    GET_QUESTIONS_REQUEST: questionsActions.getQuestionsRequest,

    SEARCH_REQUEST: searchActions.searchRequest,

    TIMEZONE_REQUEST: timezoneActions.timezoneRequest
};

export default mapActions;
