import apiErrorHandling from './errorHandling';
import axios, { AxiosRequestConfig } from 'axios';

export const headers = {
    headers: {
        'Content-Type': 'application/json'
    }
};

export const config: AxiosRequestConfig = {
    baseURL: process.env.REACT_APP_API_URL,
    headers: headers.headers
    // timeout: 60000
};

const api = axios.create(config);

api.interceptors.response.use((response) => response, async (error) => apiErrorHandling(error));

export default api;
