import { put } from 'redux-saga/effects';

// Redux
import { Creators as courseActions } from 'store/ducks/course';
import { Creators as lessonPlanEventsActions } from 'store/ducks/lessonPlanEvents';

export function* createLOExerciseListCourse(action: any) {
    try {
        const createBookletContentItemPayload = [
            {
                module: action.idModule,
                learningObject: action.data.learningObject.id,
                order: action.newOrder,
                data: action.data
            }
        ];

        yield put(courseActions.createBookletContentItemRequest(createBookletContentItemPayload));
    } catch (error) {
        console.log('error');
    }
}

export function* createLOExerciseListLessonPlanEvents(action: any) {
    try {
        yield put(
            lessonPlanEventsActions.createOrEditLessonPlanEventsContentRequest({
                event: action.event,
                content: action.data
            })
        );
    } catch (error) {
        console.log('error');
    }
}
