import { lazy } from 'react';

const List = lazy(() => import('pages/crud'));
const CreateOrEdit = lazy(() => import('pages/crud/createOrEdit'));

const projectCategoryRoutes = [
    {
        path: '/app/projetos/categorias',
        name: 'Categorias',
        component: List,
        exact: true,
        title: 'Categorias de projeto'
    },
    {
        path: '/app/projetos/categorias/novo',
        name: 'Nova',
        component: CreateOrEdit,
        exact: true,
        title: 'Nova categoria categoria de projeto'
    },
    {
        path: '/app/projetos/categorias/editar/:id',
        name: 'Editar categoria',
        component: CreateOrEdit,
        exact: true,
        title: 'Editar categoria categoria de projeto'
    }
];

export default projectCategoryRoutes;
