// Components
import Modal from './modal';

// Redux
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { IReduxStore } from 'utils/interfaces/IReduxStore';
import { Creators as ModalActions } from 'store/ducks/modal';

const mapStateToProps = ({ modal }: IReduxStore) => ({
    modal
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    modalActions: bindActionCreators(ModalActions, dispatch) as any
});

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
