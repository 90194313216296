export const createStore = `
mutation ($data: prodigio_stores_insert_input!) {
    item: insert_prodigio_stores_one(object: $data) {
      id
      slug
      name
      url
      isActive: is_active
      hasShowcase: has_showcase
      hasWithdrawal: has_withdrawal
      withDelivery: with_delivery
    }
  }
`;
