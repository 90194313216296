// Components
import UserNav from './userNav';

// Helpers

// Redux
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Creators as AuthActions } from 'store/ducks/auth';

const mapDispatchToProps = (dispatch: Dispatch) => ({
    authActions: bindActionCreators(AuthActions, dispatch) as any
});

export default connect(null, mapDispatchToProps)(UserNav);
