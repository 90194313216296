import { lazy } from 'react';

const List = lazy(() => import('pages/crud'));
const Order = lazy(() => import('pages/order'));

const orderRoutes = [
    {
        path: '/app/pedidos',
        name: 'Pedidos',
        component: List,
        exact: true,
        title: 'Pedidos'
    },
    {
        path: '/app/pedidos/ver/:id',
        name: 'Visualizar',
        component: Order,
        exact: true,
        title: 'Visualizar Professor'
    }
];

export default orderRoutes;
