import sagas from './sagas';
import reducers from './ducks';
import createSagaMiddleware from 'redux-saga';
import storage from 'redux-persist/lib/storage';
import authMiddleware from 'store/middlewares/auth';
import { createStore, applyMiddleware, Store } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import { composeWithDevTools } from 'redux-devtools-extension';

const middlewares: any[] = [];

const sagaMiddleware = createSagaMiddleware({
    onError: (error) => {
        // if (process.env.NODE_ENV === 'production') {
        //     // SOME ERROR API INTEGRATION
        // }
    }
});

middlewares.push(authMiddleware);
middlewares.push(sagaMiddleware);

const persistConfig = {
    key: 'prodigio-admin',
    storage,
    whitelist: ['auth', 'organizations']
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store: Store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(...middlewares)));
const persistor = persistStore(store);

sagaMiddleware.run(sagas);

export { store, persistor };
