import { lazy } from 'react';

const List = lazy(() => import('pages/crud'));
const ExerciseList = lazy(() => import('pages/exerciseList'));
const ExerciseListView = lazy(() => import('pages/exerciseList/view'));

const exerciseListRoutes = [
    {
        path: '/app/listas-de-exercicios',
        name: 'Listas de Exercícios',
        component: List,
        exact: true,
        title: 'Listas de Exercícios'
    },
    {
        path: '/app/listas-de-exercicios/novo',
        name: 'Nova lista de exercícios',
        component: ExerciseList,
        exact: true,
        title: 'Nova lista de exercícios'
    },
    {
        path: '/app/listas-de-exercicios/editar/:id',
        name: 'Editar',
        component: ExerciseList,
        exact: true,
        title: 'Editar lista de exercícios'
    },
    {
        path: '/app/listas-de-exercicios/ver/:id',
        name: 'Ver',
        component: ExerciseListView,
        exact: true,
        title: 'Ver lista de exercícios'
    }
];

export default exerciseListRoutes;
