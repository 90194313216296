import React, { useState, useEffect } from 'react';

import { ToggleButton, ToggleLabel } from './styles';
import Switch from 'components/switch';

export interface IProps {
    falseLabel?: string;
    trueLabel?: string;
    initialValue: boolean;
    onChange(active: boolean): void;
    style?: React.CSSProperties;
    disabled?: boolean;
}

const Toggle = ({ falseLabel, trueLabel, initialValue, onChange, style, disabled }: IProps) => {
    const [active, setActive] = useState(initialValue);

    useEffect(() => {
        setActive(initialValue);
    }, [initialValue]);

    const handleChange = () => {
        setActive(!active);

        onChange(!active);
    };

    return (
        <ToggleButton style={style}>
            <ToggleLabel>
                <Switch active={active} onClick={handleChange} disabled={disabled} />
                <span>
                    {!active && falseLabel}
                    {active && trueLabel}
                </span>
            </ToggleLabel>
        </ToggleButton>
    );
};

export default React.memo(Toggle);
