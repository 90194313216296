import styled from 'styled-components';
import { AvatarContainer } from 'components/avatar/styles';
import theme from 'config/theme';
import { Link } from 'react-router-dom';

export const TableItemPersonContainer = styled.div`
    display: flex;
    align-items: center;

    ${AvatarContainer} {
        margin-right: 5px;
    }

    :hover {
        color: ${theme.colors.baseDark};
    }
`;

export const TableItemPersonTexts = styled.div`
    display: flex;
    flex-direction: column;

    p,
    small {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    p {
        font-size: 14px;
    }

    small {
        font-size: 12px;
    }
`;

export const TableItemText = styled.span`
    max-height: calc(1rem * 3);
    overflow: hidden;
    line-height: 1rem;
    /* text-align: justify; */
    text-overflow: ellipsis;
    :hover {
        span.tooltip {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            background-color: #ffffff;
            border-radius: 4px;
            z-index: 2;
            border: 1px solid #005495;
            padding: 10px;
            box-shadow: 2px 2px 1px hsla(0, 0%, 0%, 0.15);
        }
    }
    span.tooltip {
        display: none;
    }
`;

export const TableItemColor = styled.div<{ color?: string }>`
    display: flex;
    align-items: center;

    > figure {
        width: 15px;
        height: 15px;
        content: ' ';
        background: ${({ color }) => color || '#000'};
        border-radius: 100%;
        margin-right: 10px;
    }
`;

export const TableV2Link = styled(Link)`
    color: ${theme.colors.link};
    text-decoration: underline;
`;
