import { createActions, createReducer } from 'reduxsauce';

export const { Creators, Types } = createActions({
    getProfileByIdRequest: ['payload'],
    getProfileByIdSuccess: ['payload'],
    getProfileByIdFailure: [],

    createOrEditProfileRequest: ['payload'],
    createOrEditProfileSuccess: ['payload'],
    createOrEditProfileFailure: [],

    clearProfiles: []
});

export interface IProfileRules {
    id: number;
    name: string;
    type: number;
}

export interface IProfilePermitions {
    id: number;
    name: string;
    path: string;
    rules: IProfileRules[];
}

export interface IProfileState {
    isLoading: boolean;
    id: number;
    name: string;
    description: string;
    pages: IProfilePermitions[];
}

const INITIAL_STATE: IProfileState = {
    isLoading: false,
    id: 0,
    name: '',
    description: '',
    pages: []
};

const getProfileByIdRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true });
const getProfileByIdSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoading: false,
    ...action.payload
});
const getProfileByIdFailure = (state = INITIAL_STATE) => ({ ...state, ...INITIAL_STATE });

const createOrEditProfileRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true });
const createOrEditProfileSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoading: false,
    ...action.payload
});
const createOrEditProfileFailure = (state = INITIAL_STATE) => ({ ...state, ...INITIAL_STATE });

const clearProfiles = (state = INITIAL_STATE) => INITIAL_STATE;

export default createReducer(INITIAL_STATE, {
    [Types.GET_PROFILE_BY_ID_REQUEST]: getProfileByIdRequest,
    [Types.GET_PROFILE_BY_ID_SUCCESS]: getProfileByIdSuccess,
    [Types.GET_PROFILE_BY_ID_FAILURE]: getProfileByIdFailure,

    [Types.CREATE_OR_EDIT_PROFILE_REQUEST]: createOrEditProfileRequest,
    [Types.CREATE_OR_EDIT_PROFILE_SUCCESS]: createOrEditProfileSuccess,
    [Types.CREATE_OR_EDIT_PROFILE_FAILURE]: createOrEditProfileFailure,

    [Types.CLEAR_PROFILES]: clearProfiles
});
