import styled, { css } from 'styled-components/macro';
import theme from 'config/theme';
import { Link } from 'react-scroll';
import { NavLink } from 'react-router-dom';

export const NavListContainer = styled.nav`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    padding-left: 5.5px;
    position: relative;

    &:before {
        background: ${theme.colors.borderColor};
        content: '';
        height: calc(100% - 33px);
        left: 5px;
        padding: 15px 0;
        position: absolute;
        top: 17px;
        width: 1px;
    }
`;

const itemStyle = css`
    cursor: pointer;
    font-family: ${theme.typography.family.primary.bold.regular};
    padding: 10px 15px 10px 20px;
    position: relative;
    text-transform: uppercase;
    transition: color 0.2s ease;
    text-decoration: none;
    color: ${theme.colors.base};

    &:before {
        background: ${theme.colors.base};
        border-radius: 50%;
        content: '';
        left: -3.5px;
        position: absolute;
        height: 7px;
        top: 50%;
        transform: translateY(-50%);
        transition: background 0.2s ease;
        width: 7px;
    }

    &:hover,
    &.active {
        color: ${theme.colors.secondary};

        &:before {
            background: ${theme.colors.secondary};
        }
    }
`;

export const NavListItemLink = styled(NavLink)`
    ${itemStyle};
`;

export const NavListItem = styled.button`
    border: none;
    background: transparent;
    outline: none;
    text-align: left;

    ${itemStyle};
`;

export const NavListItemScroll = styled(Link)`
    ${itemStyle};
`;
