// sampleReducer.js
import { createReducer, createActions } from 'reduxsauce';
import { ContestTypes, IContestActionsCreators, IContestState } from './types';

export const { Creators, Types } = createActions<Record<ContestTypes, ContestTypes>, IContestActionsCreators>({
    getContestByIdRequest: ['payload'],
    getContestByIdSuccess: ['payload'],
    getContestByIdFailure: [],

    createOrEditContestRequest: ['payload'],
    createOrEditContestSuccess: ['payload'],
    createOrEditContestFailure: [],

    clearContest: []
});

const INITIAL_STATE: IContestState = {
    isLoading: false,
    id: 0,
    slug: '',
    correctionEngine: '',
    name: ''
};

const getContestByIdRequest = (state = INITIAL_STATE) => {
    return { ...state, isLoading: true };
};

const getContestByIdSuccess = (state = INITIAL_STATE, action?: any) => {
    return { ...state, isLoading: false, ...action?.payload };
};

const getContestByIdFailure = (state = INITIAL_STATE) => {
    return { ...state, isLoading: false };
};

const createOrEditContestRequest = (state = INITIAL_STATE) => {
    return { ...state, isLoading: true };
};

const createOrEditContestSuccess = (state = INITIAL_STATE, action?: any) => {
    return { ...state, isLoading: false };
};

const createOrEditContestFailure = (state = INITIAL_STATE) => {
    return { ...state, isLoading: false };
};

const clearContest = () => INITIAL_STATE;

export default createReducer(INITIAL_STATE, {
    [Types.GET_CONTEST_BY_ID_REQUEST]: getContestByIdRequest,
    [Types.GET_CONTEST_BY_ID_SUCCESS]: getContestByIdSuccess,
    [Types.GET_CONTEST_BY_ID_FAILURE]: getContestByIdFailure,

    [Types.CREATE_OR_EDIT_CONTEST_REQUEST]: createOrEditContestRequest,
    [Types.CREATE_OR_EDIT_CONTEST_SUCCESS]: createOrEditContestSuccess,
    [Types.CREATE_OR_EDIT_CONTEST_FAILURE]: createOrEditContestFailure,

    [Types.CLEAR_CONTEST]: clearContest
});
