import { createActions, createReducer } from 'reduxsauce';

export const { Creators, Types } = createActions({
    getUserRequest: ['payload'],
    getUserSuccess: ['payload'],
    getUserFailure: [],

    createOrEditUserRequest: ['payload'],
    createOrEditUserSuccess: ['payload'],
    createOrEditUserFailure: [],

    changeUserPasswordRequest: ['payload'],
    changeUserPasswordSuccess: [],
    changeUserPasswordFailure: [],

    clearUser: []
});

export interface IUserPerson {
    birthDate: string;
    cpf: string;
    gender: string;
    id: number;
    imageProfile: string;
    name: string;
    observation: string;
    phone: string;
    slug: string;
}

export interface IUserCompany {
    id: number;
    name: string;
    brands: {
        id: number;
        name: string;
    }[];
}
export interface IUserState {
    isLoading: boolean;
    email: string;
    id: number;
    idFacebook: number;
    username: string;
    person: IUserPerson;
    companies: IUserCompany[];
}

const INITIAL_STATE: IUserState = {
    isLoading: false,
    email: '',
    id: 0,
    idFacebook: 0,
    username: '',
    person: {
        birthDate: '',
        cpf: '',
        gender: '',
        id: 0,
        imageProfile: '',
        name: '',
        observation: '',
        phone: '',
        slug: ''
    },
    companies: []
};

const getUser = (state = INITIAL_STATE, action: any) => ({
    isLoading: false,
    ...action.payload
});

const createOrEditUser = (state = INITIAL_STATE, action: any) => ({
    isLoading: false,
    ...action.payload
});

// GENERIC ACTIONS
const userRequest = (state = INITIAL_STATE, action: any) => ({ ...state, isLoading: true });
const userRequestFailure = (state = INITIAL_STATE) => ({ ...state, isLoading: false });

const clearUser = (state = INITIAL_STATE) => INITIAL_STATE;

export default createReducer(INITIAL_STATE, {
    [Types.GET_USER_REQUEST]: userRequest,
    [Types.GET_USER_SUCCESS]: getUser,
    [Types.GET_USER_FAILURE]: userRequestFailure,

    [Types.CREATE_OR_EDIT_USER_REQUEST]: userRequest,
    [Types.CREATE_OR_EDIT_USER_SUCCESS]: createOrEditUser,
    [Types.CREATE_OR_EDIT_USER_FAILURE]: userRequestFailure,

    [Types.CHANGE_USER_PASSWORD_REQUEST]: userRequest,
    [Types.CHANGE_USER_PASSWORD_SUCCESS]: userRequestFailure,
    [Types.CHANGE_USER_PASSWORD_FAILURE]: userRequestFailure,

    [Types.CLEAR_USER]: clearUser
});
