import styled, { css } from 'styled-components';
import theme from 'config/theme';
import { rotate } from 'assets/animations';

export const VideoWrapper = styled.div`
    align-items: center;
    background: #404040;
    background: -webkit-linear-gradient(to bottom, #404040, #131313);
    background: linear-gradient(to bottom, #404040, #131313);
    display: flex;
    justify-content: center;
    height: 360px;
    width: 100%;
    transition: height 0.2s ease;
    position: relative;
    padding-top: 56.25%;

    > div {
        position: absolute;
        top: 0;
        left: 0;

        height: 100% !important;
        width: 100% !important;

        video {
            outline: 0;
        }
    }
`;

export const VideoPlayButton = styled.button<{ isLoading: boolean }>`
    position: absolute;
    top: 0;
    left: 0;

    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    width: 100%;

    .ico-play,
    .ico-spinner {
        fill: ${theme.colors.secondary};
    }

    ${({ isLoading }) => {
        if (!isLoading) {
            return;
        }

        return css`
            svg {
                animation: ${rotate} 0.7s linear infinite;
            }
        `;
    }}
`;

export const VideoPlayLoader = styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
