export const editStore = `
mutation ($data: prodigio_stores_set_input!, $id: Int!) {
    item: update_prodigio_stores_by_pk(pk_columns: {id: $id}, _set: $data) {
      id
      slug
      name
      url
      isActive: is_active
      hasShowcase: has_showcase
      hasWithdrawal: has_withdrawal
      withDelivery: with_delivery
    }
  }
`;
