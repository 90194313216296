import React from 'react';

// Dependencies
import ReactDOM from 'react-dom';

// Pages
import App from './App';

// Assets
import 'bootstrap/dist/css/bootstrap-grid.min.css';
import 'react-table/react-table.css';

const render = (Component: any) => {
    return ReactDOM.render(<Component />, document.getElementById('root'));
};

render(App);

if ((module as any).hot) {
    (module as any).hot.accept('./App', () => {
        const NextApp = require('./App').default;
        render(NextApp);
    });
}
