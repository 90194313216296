// Page
import Main from './main';

// HOCs
import withToken from 'hocs/auth';

// Redux
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { Creators as CrudActions } from 'store/ducks/crud';

const mapDispatchToProps = (dispatch: Dispatch) => ({
    crudActions: bindActionCreators(CrudActions, dispatch) as any
});

export default withToken(connect(null, mapDispatchToProps)(Main));
