import styled, { css } from 'styled-components';
import theme from 'config/theme';
import { NavLink } from 'react-router-dom';
import { MediaQueries } from 'assets/settings';
import { DropdownContainer } from 'components/dropdown/styles';
import hexToRgba from 'utils/hexToRgba';
import { ButtonContainer } from 'components/button/styles';
import { bounceInLeft } from 'assets/animations';

export const NavbarPreviewActions = styled.div`
    bottom: -40px;
    position: absolute;

    ${ButtonContainer} {
        animation: ${bounceInLeft} 0.5s linear;
    }
`;

export const NavbarPreview = styled.div`
    animation: ${bounceInLeft} 0.5s linear;
    display: flex;
`;

export const NavContainer = styled.nav`
    position: relative;
`;

export const NavWrapper = styled.ul`
    align-items: center;
    display: flex;
    min-height: 20px;

    ${MediaQueries.SMALL} {
        overflow-x: auto;
    }
`;

export const NavButton = styled(NavLink)`
    background: ${theme.colors.white};
    padding: ${theme.spacing.medium.all};
    position: relative;

    svg {
        display: block !important;
        stroke: ${theme.colors.baseDark};
        margin-left: 10px;
    }

    &:hover,
    &:active,
    &.active {
        background: ${hexToRgba(theme.colors.secondary, 0.1)};
        color: ${theme.colors.primary};

        svg {
            stroke: ${theme.colors.primary};
        }
    }
`;

export const NavItem = styled.li<{ hasSubmenu?: boolean }>`
    position: relative;

    > a {
        color: ${theme.colors.baseDark};
        display: block;
        transition: background 0.2s ease, color 0.2s ease;
        white-space: nowrap;

        ${({ hasSubmenu }) => {
            if (hasSubmenu) {
                return css`
                    ${NavButton} {
                        display: none !important;
                    }

                    &:after {
                        background: ${theme.colors.secondary};
                        border-radius: 50%;
                        bottom: 10px;
                        content: '';
                        height: 5px;
                        left: 50%;
                        position: absolute;
                        transform: translateX(-50%);
                        width: 5px;
                    }
                `;
            }

            return;
        }}

        &:hover {
            color: ${theme.colors.primary};
        }
    }

    &:hover {
        ${DropdownContainer} {
            display: block;
        }
    }

    ${DropdownContainer} {
        display: none;
        left: 0;
    }
`;
