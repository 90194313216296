import { put } from 'redux-saga/effects';
import { Creators as schoolPeriodActions } from 'store/ducks/schoolPeriod';
import { Creators as alertActions } from 'store/ducks/alert';
import { apiRequest } from '../apiRequest';

export function* getAllSchoolPeriods(action: any) {
    try {
        const { data } = yield apiRequest('GET', '/schoolperiod', undefined, {
            'X-No-Pagination': true
        });

        yield put(schoolPeriodActions.getAllSchoolPeriodsSuccess(data.items));
    } catch (error) {
        yield put(schoolPeriodActions.getAllSchoolPeriodsFailure());
        yield put(alertActions.showAlert('Não foi possível recuperar os anos letivos.', 'danger'));
    }
}
