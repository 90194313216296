import styled from 'styled-components';
import { tada } from 'assets/animations';
import theme from 'config/theme';
import { SmallTitle } from 'assets/global';

export const ErrorContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px;

    font-family: ${theme.typography.family.primary.normal.regular}, sans-serif;

    ${SmallTitle} {
        margin-bottom: 15px;
    }

    p {
        margin-bottom: 20px;

        a {
            color: ${theme.colors.link};
            text-decoration: underline;
        }
    }

    > svg {
        animation: ${tada} linear 0.5s;
        margin-bottom: 15px;
    }
`;
