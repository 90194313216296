import { createActions, createReducer } from 'reduxsauce';

export const { Creators, Types } = createActions({
    getAllBrandsRequest: ['payload'],
    getAllBrandsSuccess: ['payload'],
    getAllBrandsFailure: [],

    getBrandRequest: ['payload'],
    getBrandSuccess: ['payload'],
    getBrandFailure: [],

    createOrEditBrandRequest: ['payload'],
    createOrEditBrandSuccess: ['payload'],
    createOrEditBrandFailure: [],

    createOrEditUsersBrandRequest: ['payload'],
    createOrEditUsersBrandSuccess: ['payload'],
    createOrEditUsersBrandFailure: [],

    clearBrand: []
});

export interface IBrandState {
    isLoading: boolean;
    id: number;
    name: string;
    slug: string;
    type: number;
    tradingName: string;
    managingUsers: any[];
    cnpj: any;
    address: {
        name: string;
        number: any;
        complement: string;
        zip: any;
        city: string;
        state: string;
    };
    company: any;
    items: any[];
}

const INITIAL_STATE: IBrandState = {
    isLoading: false,
    id: 0,
    name: '',
    slug: '',
    type: 0,
    cnpj: '',
    tradingName: '',
    managingUsers: [],
    address: {
        name: '',
        number: 0,
        complement: '',
        zip: '',
        city: '',
        state: ''
    },
    company: {},
    items: []
};

const getAllBrandsRequest = (state = INITIAL_STATE, action: any) => ({ ...state, isLoading: true });
const getAllBrandsSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoading: false,
    items: action.payload
});
const getAllBrandsFailure = (state = INITIAL_STATE) => INITIAL_STATE;

const getBrandRequest = (state = INITIAL_STATE, action: any) => ({ ...state, isLoading: true });
const getBrandSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoading: false,
    ...action.payload
});
const getBrandFailure = (state = INITIAL_STATE) => INITIAL_STATE;

const createOrEditBrandRequest = (state = INITIAL_STATE, action: any) => ({ ...state, isLoading: true });
const createOrEditBrandSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoading: false,
    ...action.payload
});
const createOrEditBrandFailure = (state = INITIAL_STATE) => ({ ...state, isLoading: false });

const createOrEditUsersBrandRequest = (state = INITIAL_STATE, action: any) => ({ ...state, isLoading: true });
const createOrEditUsersBrandSuccess = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        isLoading: false,
        managingUsers: action.payload
    };
};
const createOrEditUsersBrandFailure = (state = INITIAL_STATE) => ({ ...state, isLoading: false });

const clearBrand = (state = INITIAL_STATE) => INITIAL_STATE;

// TYPES
export default createReducer(INITIAL_STATE, {
    [Types.GET_BRAND_REQUEST]: getBrandRequest,
    [Types.GET_BRAND_SUCCESS]: getBrandSuccess,
    [Types.GET_BRAND_FAILURE]: getBrandFailure,

    [Types.GET_ALL_BRANDS_REQUEST]: getAllBrandsRequest,
    [Types.GET_ALL_BRANDS_SUCCESS]: getAllBrandsSuccess,
    [Types.GET_ALL_BRANDS_FAILURE]: getAllBrandsFailure,

    [Types.CREATE_OR_EDIT_BRAND_REQUEST]: createOrEditBrandRequest,
    [Types.CREATE_OR_EDIT_BRAND_SUCCESS]: createOrEditBrandSuccess,
    [Types.CREATE_OR_EDIT_BRAND_FAILURE]: createOrEditBrandFailure,

    [Types.CREATE_OR_EDIT_USERS_BRAND_REQUEST]: createOrEditUsersBrandRequest,
    [Types.CREATE_OR_EDIT_USERS_BRAND_SUCCESS]: createOrEditUsersBrandSuccess,
    [Types.CREATE_OR_EDIT_USERS_BRAND_FAILURE]: createOrEditUsersBrandFailure,

    [Types.CLEAR_BRAND]: clearBrand
});
