import React from 'react';

// Assets
import { EmptyContainer, EmptyThumb, EmptyText } from './styles';

interface IProps {
    direction?: 'left' | 'center' | 'right';
    thumb?: string;
    text: string;
}

const Empty = ({ direction = 'center', text, thumb }: IProps) => (
    <EmptyContainer>
        {thumb && (
            <EmptyThumb>
                <img alt={text} src={thumb} />
            </EmptyThumb>
        )}
        <EmptyText style={{ textAlign: direction }}>{text}</EmptyText>
    </EmptyContainer>
);

export default Empty;
