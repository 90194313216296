import { lazy } from 'react';

const List = lazy(() => import('pages/crud'));
const User = lazy(() => import('pages/user'));

const usersRoutes = [
    {
        path: '/app/usuarios',
        name: 'Usuários',
        component: List,
        exact: true,
        title: 'Usuários'
    },
    {
        path: '/app/usuarios/novo',
        name: 'Nova usuário',
        component: User,
        exact: true,
        title: 'Nova usuário'
    },
    {
        path: '/app/usuarios/editar/:id',
        name: 'Editar',
        component: User,
        exact: true,
        title: 'Editar usuário'
    }
];

export default usersRoutes;
