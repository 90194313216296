import styled from 'styled-components';
import { SmallTitle } from 'assets/global';
import { FormGroup } from 'components/forms/styles';

export const StoreListContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const StoreListHeader = styled.header`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
`;

export const StoreListTitle = styled.div`
    display: flex;
    align-items: center;
    margin-right: auto;

    ${SmallTitle} {
        margin-right: 15px;
    }
`;

export const StoreListSearchFields = styled.div`
    display: flex;

    ${FormGroup} {
        position: relative;
        margin-right: 10px;

        .react-datepicker-wrapper {
            width: 250px;
        }

        .react-datepicker-popper {
            max-width: 100%;
            width: 100% !important;
        }
    }
`;
