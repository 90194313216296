// Page
import BrandSelectorScreen from './brandSelector';

// HOCs
import withToken from 'hocs/auth';

// Redux
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { Creators as CompanyActions } from 'store/ducks/company';
import { Creators as ModalActions } from 'store/ducks/modal';
import { Creators as SearchActions } from 'store/ducks/search';
import { Creators as AuthActions } from 'store/ducks/auth';

// Helpers
import { IReduxStore } from 'utils/interfaces/IReduxStore';

const mapStateToProps = ({ organizations, search, auth }: IReduxStore) => ({
    companies: organizations.companies,
    search,
    profiles: auth.profiles
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    companyActions: bindActionCreators(CompanyActions, dispatch) as any,
    modalActions: bindActionCreators(ModalActions, dispatch) as any,
    searchActions: bindActionCreators(SearchActions, dispatch) as any,
    authActions: bindActionCreators(AuthActions, dispatch) as any
});

export default withToken(connect(mapStateToProps, mapDispatchToProps)(BrandSelectorScreen));
