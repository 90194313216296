import styled, { createGlobalStyle, css } from 'styled-components';
import { MediaQueries, BreakPoints } from './settings';

// Fonts
import fontPrimaryThinRegularWoff from './fonts/caros-thin.woff';
import fontPrimaryThinRegularWoff2 from './fonts/caros-thin.woff';
import fontPrimaryThinItalicWoff from './fonts/caros-thin-italic.woff';
import fontPrimaryThinItalicWoff2 from './fonts/caros-thin-italic.woff2';

import fontPrimaryLightRegularWoff from './fonts/caros-light.woff';
import fontPrimaryLightRegularWoff2 from './fonts/caros-light.woff2';
import fontPrimaryLightItalicWoff from './fonts/caros-light-italic.woff';
import fontPrimaryLightItalicWoff2 from './fonts/caros-light-italic.woff2';

import fontPrimaryRegularWoff from './fonts/caros-regular.woff';
import fontPrimaryRegularWoff2 from './fonts/caros-regular.woff2';
import fontPrimaryItalicWoff from './fonts/caros-italic.woff';
import fontPrimaryItalicWoff2 from './fonts/caros-italic.woff2';

import fontPrimaryBoldRegularWoff from './fonts/caros-bold.woff';
import fontPrimaryBoldRegularWoff2 from './fonts/caros-bold.woff2';
import fontPrimaryBoldItalicWoff from './fonts/caros-bold-italic.woff';
import fontPrimaryBoldItalicWoff2 from './fonts/caros-bold-italic.woff2';

import fontPrimaryBlackRegularWoff from './fonts/caros-black.woff';
import fontPrimaryBlackRegularWoff2 from './fonts/caros-black.woff2';
import fontPrimaryBlackItalicWoff from './fonts/caros-black-italic.woff';
import fontPrimaryBlackItalicWoff2 from './fonts/caros-black-italic.woff2';

import theme from 'config/theme';
import { ButtonContainer } from 'components/button/styles';
import lightenDarkenColor from 'utils/lightAndDarkColor';
import { TooltipContainer } from 'components/tooltip/styles';
import { CKEditorDefaultStyles } from './ckeditor';
import { BadgeContainer } from 'components/badge/styles';
import { rotate } from './animations';

export const Normalize = css`
    * {
        margin: 0;
        box-sizing: border-box;
    }

    *:before,
    *:after {
        box-sizing: border-box;
    }

    html,
    body,
    button {
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        font-smoothing: antialiased;
    }

    html,
    body {
        box-sizing: border-box;
        height: 100%;
        width: 100%;
    }

    html {
        font-family: sans-serif;
        -ms-text-size-adjust: 100%;
        -webkit-text-size-adjust: 100%;
    }

    article,
    aside,
    details,
    figcaption,
    figure,
    footer,
    header,
    hgroup,
    main,
    menu,
    nav,
    section,
    summary {
        display: block;
    }

    audio,
    canvas,
    progress,
    video {
        display: inline-block;
        vertical-align: baseline;
    }

    audio:not([controls]) {
        display: none;
        height: 0;
    }

    [hidden],
    template {
        display: none;
    }

    a {
        background-color: transparent;
        text-decoration: none;
    }

    a:active,
    a:hover {
        outline: 0;
        text-decoration: none;
    }

    abbr[title] {
        border-bottom: 1px dotted;
    }

    b,
    strong {
        font-family: ${theme.typography.family.primary.bold.regular};
        font-weight: bold;
    }

    dfn {
        font-style: italic;
    }

    mark {
        background: #ff0;
        color: #000;
    }

    small {
        font-size: 80%;
    }

    sub,
    sup {
        font-size: 75%;
        line-height: 0;
        position: relative;
        vertical-align: baseline;
    }

    sup {
        top: -0.5em;
    }

    sub {
        bottom: -0.25em;
    }

    img {
        border: 0;
        vertical-align: middle;
    }

    svg:not(:root) {
        overflow: hidden;
    }

    figure {
        margin: 0;
    }

    hr {
        height: 0;
        box-sizing: content-box;
    }

    pre {
        overflow: auto;
    }

    code,
    kbd,
    pre,
    samp {
        font-family: monospace, monospace;
        font-size: 1em;
    }

    button,
    input,
    optgroup,
    select,
    textarea {
        color: inherit;
        font: inherit;
        margin: 0;
        outline: 0;
        padding: 0;
    }

    button {
        border: none;
        background-color: transparent;
        outline: 0;
        padding: 0;
        overflow: visible;
    }

    button,
    select {
        text-transform: none;
    }

    button,
    html input[type='button'],
    input[type='reset'],
    input[type='submit'] {
        -webkit-appearance: button;
        cursor: pointer;
        padding: 0;
    }

    button[disabled],
    html input[disabled] {
        cursor: no-drop;
    }

    button::-moz-focus-inner,
    input::-moz-focus-inner {
        border: 0;
        padding: 0;
    }

    input {
        line-height: normal;
        border: 0;
    }

    input[type='checkbox'],
    input[type='radio'] {
        padding: 0;
        box-sizing: border-box;
    }

    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
        height: auto;
    }

    input[type='search']::-webkit-search-cancel-button,
    input[type='search']::-webkit-search-decoration {
        -webkit-appearance: none;
    }

    fieldset {
        padding: 0;
        margin: 0;
        border: 0;
        min-width: 0;
    }

    legend {
        border: 0;
        padding: 0;
    }

    textarea {
        border: none;
        overflow: auto;
    }

    optgroup {
        font-weight: bold;
    }

    table {
        border-collapse: collapse;
        border-spacing: 0;
    }

    td,
    th {
        padding: 0;
    }

    address {
        font-style: normal;
    }

    ul,
    li {
        list-style: none;
        padding: 0;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-weight: normal;
        line-height: 1;
    }
`;

export const GlobalStyles = createGlobalStyle`

    ${Normalize};

    @font-face {
        font-family: 'carosbold_italic';
        src: url(${fontPrimaryBoldItalicWoff2}) format('woff2'),
            url(${fontPrimaryBoldItalicWoff}) format('woff');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'carosbold';
        src: url(${fontPrimaryBoldRegularWoff2}) format('woff2'),
            url(${fontPrimaryBoldRegularWoff}) format('woff');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'carosheavy_italic';
        src: url(${fontPrimaryBlackItalicWoff2}) format('woff2'),
            url(${fontPrimaryBlackItalicWoff}) format('woff');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'carosheavy';
        src: url(${fontPrimaryBlackRegularWoff2}) format('woff2'),
            url(${fontPrimaryBlackRegularWoff}) format('woff');
        font-weight: normal;
        font-style: normal;
    }

    
    @font-face {
        font-family: 'caroslight_italic';
        src: url(${fontPrimaryLightItalicWoff2}) format('woff2'),
            url(${fontPrimaryLightItalicWoff}) format('woff');
        font-weight: normal;
        font-style: normal;
    }
        
    @font-face {
        font-family: 'caroslight';
        src: url(${fontPrimaryLightRegularWoff2}) format('woff2'),
            url(${fontPrimaryLightRegularWoff}) format('woff');
        font-weight: normal;
        font-style: normal;
    }
    
    @font-face {
        font-family: 'carosthin';
        src: url(${fontPrimaryThinRegularWoff2}) format('woff2'),
            url(${fontPrimaryThinRegularWoff}) format('woff');
        font-weight: normal;
        font-style: normal;
    }
    
    @font-face {
        font-family: 'carosthin_italic';
        src: url('${fontPrimaryThinItalicWoff2}') format('woff2'),
            url(${fontPrimaryThinItalicWoff}) format('woff');
        font-weight: normal;
        font-style: normal;
    }
        
    @font-face {
        font-family: 'carosregular';
        src: url(${fontPrimaryRegularWoff2}) format('woff2'),
            url(${fontPrimaryRegularWoff}) format('woff');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'carositalic';
        src: url(${fontPrimaryItalicWoff2}) format('woff2'),
            url(${fontPrimaryItalicWoff}) format('woff');
        font-weight: normal;
        font-style: normal;
    }

    html,
    body,
    #root {
        height: 100%;
        width: 100%;
    }

    body {
        color: ${theme.colors.base};
        font-family: ${theme.typography.family.primary.normal.regular}, sans-serif !important;
        font-size: ${theme.typography.sizes.normal};
    }

    iframe {
        height: 100%;
        width: 100%;
    }

    .datatable {

        .rt-table, .rt-tbody {
            overflow: inherit !important;
        }

        .rt-thead {
            background: #f8fafc;

            .rt-th {
                display: block;
                text-align: left;
                text-transform: uppercase;
            }

            &.-header {
                box-shadow: none !important;

                .rt-th {
                    padding: 15px !important;

                    &:last-child {
                        position: relative;
                        right: 0px;
                        top: 21px;
                    }
                }
            }

            &.-filters {

                .rt-th {
                    border-right: 1px solid rgba(0,0,0,0.05) !important;
                    overflow: visible;
                    padding: 0 !important;

                    > input[type=text] {
                        border-bottom: none;
                        border-left: none;
                        border-radius: 0;
                        border-right: none;
                        display: block;
                        padding: 10px;
                        width: 100%;

                        &::placeholder {
                            color: ${lightenDarkenColor(theme.colors.base, 20)};
                            font-family: ${theme.typography.family.primary.normal.italic};
                        }
                    }

                    &:last-child {
                        border-right: none !important;
                    }
                }
            }
        }

        .rt-td {
            border-color: ${theme.colors.borderColor} !important;
            padding: 15px !important;

            > span {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }

        .rt-tbody {

            .rt-tr-group {

                &:not(:last-child) {
                    border-bottom: 1px solid ${theme.colors.borderColor} !important;
                }

                &:hover {
                    background: #f8fafc;
                }
            }

            .rt-tr {

                .rt-td {
                    &:last-child {

                        ${ButtonContainer} {
                            opacity: 0;
                        }
                    }
                }

                &:hover {
                    ${ButtonContainer} {
                        opacity: 1 !important;
                    }
                }
            }

            .rt-td {
                align-items: center;
                border-color: ${theme.colors.borderColor} !important;
                display: flex;
                flex-wrap: wrap;
                overflow: visible;

                &:last-child {
                    display: flex;
                    justify-content: center;
                }
            }
        }

        .-pagination {
            align-items: center !important;
            background: #f8fafc;
            border-top: 1px solid ${theme.colors.borderColor} !important;
            box-shadow: none !important;
            padding: ${theme.spacing.medium.all} !important;

            .-previous,
            .-next,
            .-center {
                flex: unset !important;
            }

            .-center {
                margin: 0 !important;
            }

            .-pageSizeOptions {
                margin: 0 !important;
            }

            .-pageInfo {
                align-items: center !important;
                display: flex !important;
                margin: 0 ${theme.spacing.small.all} 0 0 !important;
            }

            .-totalPages {
                margin-left: 5px;
            }

            .-pageJump {
                display: unset !important;
                margin: ${theme.spacing.small.horizontal};

                > input {
                    padding: ${theme.spacing.smallX.all} !important;
                    width: 60px !important;
                }
            }

            .ico-arrow-left,
            .ico-arrow-right {
                fill: transparent;
            }
        }

        .rt-noData {
            align-items: center;
            pointer-events: auto !important;
            position: static !important;
            transform: none !important;
            display: flex !important;
            flex-direction: column;
            justify-content: center;

            > p {margin-bottom: 10px;}
        }

        .-loading{

            &.-active {
                background: #FFF !important;

                > div {
                    transform: translateY(-50%) !important;
                }
            }
        }
    }

    .compact-table {
        border: none !important;

        .-loading {
            background: ${theme.colors.white} !important;
        }

        .-loading.-active > div {
            transform: translateY(-50%) !important;
        }

        .rt-noData {
            text-align: center;
            width: 100%;
        }

        .rt-thead {

            &.-header {
                box-shadow: none !important;
                padding: 0;

                .rt-th {
                    border-bottom: 2px solid ${theme.colors.borderColor};
                }

                .rt-tr {
                    border-radius: 5px 5px 0 0;
                    overflow: hidden;
                }
            }

            .rt-th {
                background: #f2f4f5;
                border-right: none !important;
                color: ${theme.colors.secondary};
                font-weight: 700;
                padding: 12px 15px !important;
                text-align: left !important;
            }
        }

        .rt-td {
            align-items: center;
            display: flex;
            flex-wrap: wrap;
            overflow: visible;
            padding: ${theme.spacing.small.all} !important;
        }

        .rt-tr-group {
            &:hover {
                background: #f8fafc;
            }
        }

        .-pagination {
            align-items: center !important;
            background: #f8fafc;
            border-top: 1px solid ${theme.colors.borderColor} !important;
            box-shadow: none !important;
            padding: ${theme.spacing.medium.all} !important;

            .-previous,
            .-next,
            .-center {
                flex: unset !important;
            }

            .-center {
                margin: 0 !important;
            }

            .-pageSizeOptions {
                margin: 0 !important;
            }

            .-pageInfo {
                align-items: center !important;
                display: flex !important;
                margin: 0 ${theme.spacing.small.all} 0 0 !important;
            }

            .-totalPages {
                margin-left: 5px;
            }

            .-pageJump {
                display: unset !important;
                margin: ${theme.spacing.small.horizontal};

                > input {
                    padding: ${theme.spacing.smallX.all} !important;
                    width: 60px !important;
                }
            }

            .ico-arrow-left,
            .ico-arrow-right {
                fill: transparent;
            }
        }
    }

    .slide-enter {
        transform: translateY(-100%);
    }
    
    .slide-enter-done {
        transform: translateY(0%);
    }

    .slide-exit {
        transform: translateY(0%);
    }

    .slide-exit-active {
        transform: translateY(-100%);
    }
    
    .fadeOut-enter {
        opacity: 1;
    }
    
    .fadeOut-enter-done {
        opacity: 0;
    }

    .fadeOut-exit {
        opacity: 0;
    }

    .fadeOut-exit-active {
        opacity: 1;
    }

    .fadeIn-enter {
        opacity: 0;
    }
    
    .fadeIn-enter-active {
        opacity: 1;
        transition: opacity 1s ease;
    }

    .fadeIn-exit {
        opacity: 1;
    }

    .fadeIn-exit-active {
        opacity: 0;
        transition: opacity 1s ease;
    }

    .react-datepicker-wrapper,
    .react-datepicker__input-container {
        width: 100%;
    }

    input {
        border: 2px solid ${theme.colors.components.inputBorder} !important;
        background-color: #fff !important;
        border-radius: 6px !important;
        color: ${theme.colors.baseDark} !important;
        font-size: ${theme.typography.sizes.normal} !important;
        letter-spacing: 0.2px;
        padding: ${theme.spacing.small.all};
        transition: all 0.2s ease;
        white-space: nowrap;
        width: 100%;

        &:hover {
            background: #f5f5f5 !important;
        }

        &:focus {
            border-color: ${theme.colors.secondary} !important;
        }

        &:disabled {
            background: #efefef !important;
            border-color: #efefef !important;
            cursor: no-drop !important;
        }
    }

    .react-datepicker-popper {
        border-bottom-color: ${theme.colors.primary};
        width: calc(100% - 30px) !important;
        z-index: 10 !important;
    }

    .react-datepicker--time-only {
        .react-datepicker__time-container  {
            width: 100%;

            .react-datepicker-time__header {
                color: #fff;
                padding: 6px 0;
            }

            .react-datepicker__time-box {
                width: 100% !important;
            }
        }
    }

    .react-datepicker-popper {
        width: 100%;
    }

    .react-datepicker {
        border-color: #ced3db !important;
        display: flex !important;
        font-family: ${theme.typography.family.primary.normal.regular} !important;
        overflow: hidden;
        width: 100%;

        .react-datepicker__triangle {display: none;}

        .react-datepicker__navigation {

            &--previous {border-right-color: ${theme.colors.white} !important;}
            &--next {border-left-color: ${theme.colors.white} !important;}
        }

        .react-datepicker__header {
            background: ${theme.colors.primary};
            padding: 0;
            text-transform: uppercase;
        }

        .react-datepicker__current-month,
        .react-datepicker-time__header {
            padding: ${theme.spacing.small.all};
        }

        .react-datepicker__month-container {
            flex: 1;
            width: 100%;
        }

        .react-datepicker__time-container {
            border-left-color: #ced3db;
            width: 80px;

            .react-datepicker__time .react-datepicker__time-box {
                width: 100%;
            }
        }

        .react-datepicker__time-list-item {
            height: unset !important;
            line-height: 1;
            padding: ${theme.spacing.small.all} !important;
        }

        .react-datepicker__day-name,
        .react-datepicker__current-month,
        .react-datepicker__day--selected,
        .react-datepicker__day--keyboard-selected, 
        .react-datepicker__month-text--keyboard-selected,
        .react-datepicker-time__header {
            color: ${theme.colors.white} !important;
        }

        .react-datepicker__day, 
        .react-datepicker__time-name {
            color: ${theme.colors.base};
        }

        .react-datepicker__day--keyboard-selected, 
        .react-datepicker__month-text--keyboard-selected {
            background: ${theme.colors.secondary};
        }
    }

    .select__value-container {
        overflow: hidden;
    }

    .select__placeholder {
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .select__indicator {
        svg {margin: 0 !important;}
    }

    .select__input {
        > input {
            border: none !important;
        }
    }

    .select--is-disabled  {

        .select__control {
            background: #efefef !important;
            border-color: #efefef !important;
            cursor: no-drop !important;
        }
    }

    .select__menu {
        z-index: 100 !important;
    }

    p[data-f-id='pbf'] {
        display: none !important;
    }

    .rc-slider {
        height: unset !important;
        padding: 0 !important;

        ${TooltipContainer} {
            bottom: 20px;
        }

        .rc-slider-rail,
        .rc-slider-step {
            height: 2px !important;
        }
    }

    .react-resizable-handle {
        z-index: 999999;
    }

    .ck {

        &.ck-content {
            min-height: 250px;
        }
    }

    p {
        &:empty {display: none;}
    }

    .MathJax {
        color: black;
    }

    ${CKEditorDefaultStyles};
`;

export const MainContainer = styled.main`
    background: #f2f4f5;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    width: 100%;
`;

export const MainWrapper = styled.section`
    flex: 1;
    padding: ${theme.spacing.largeX.vertical};
    width: 100%;
`;

export const Container = styled.div`
    margin: 0 auto;
    padding: 0 15px;
    width: 100%;

    position: relative;

    ${MediaQueries.NOTEBOOK} {
        width: ${BreakPoints.wide};
    }

    ${MediaQueries.DESKTOP} {
        width: 90%;
    }
`;

export const Title = styled.h1`
    color: ${theme.colors.baseDark};
    font-family: ${theme.typography.family.primary.black.regular};
    font-size: ${theme.typography.sizes.largeX};
`;

export const Subtitle = styled.strong`
    color: ${theme.colors.secondary};
    font-family: ${theme.typography.family.primary.bold.regular};
    font-size: ${theme.typography.sizes.normalX};
`;

export const Content = styled.div``;

export const ButtonGroup = styled.div`
    align-items: center;
    display: flex;

    ${ButtonContainer} {
        &:not(:last-child) {
            margin-right: ${theme.spacing.small.all};
        }
    }
`;

export const SmallTitle = styled.strong`
    color: ${theme.colors.baseDark};
    display: block;
    font-family: ${theme.typography.family.primary.bold.regular};
    font-size: ${theme.typography.sizes.normalXX};
`;

export const OrderBullet = styled.span`
    align-items: center;
    background: ${theme.colors.borderColor};
    border-radius: 50%;
    color: ${theme.colors.base};
    display: flex;
    font-size: ${theme.typography.sizes.normalX};
    font-weight: 700;
    height: 35px;
    justify-content: center;
    margin-right: 25px;
    width: 35px;
`;

export const TagsWrapper = styled.div`
    align-items: center;
    display: flex;
    justify-content: flex-start;

    > span {
        font-family: ${theme.typography.family.primary.bold.regular};
    }

    > ${ButtonContainer} {
        margin: 0 0 0 ${theme.spacing.smallX.all} !important;
        padding: ${theme.spacing.smallX.all} !important;
    }

    &:not(:last-child) {
        margin-bottom: ${theme.spacing.small.all};
    }
`;

export const Tag = styled.div`
    position: relative;

    ${BadgeContainer} {
        align-items: center;
        display: flex;
        margin: 0;

        > svg {
            margin-top: -1px;
        }
    }

    &:hover {
        ${TooltipContainer} {
            opacity: 1;
            visibility: visible;
        }
    }

    &:not(:last-child) {
        margin-right: ${theme.spacing.smallX.all};
    }
`;

export const FormErrorMessage = styled.span`
    color: ${theme.colors.system.danger};
    display: block;
    font-style: italic;
    font-size: 12px;
    margin-top: 5px;
`;

export const MetadataContainer = styled.div`
    align-items: flex-start;
    border-bottom: 1px solid #f0f0f2;
    display: flex;
    margin-bottom: 20px;
    padding-bottom: 20px;
`;

export const MetadataGroup = styled.div`
    flex: 1 0 calc(100% / 3);
    margin-bottom: ${theme.spacing.medium.all};

    svg {
        fill: ${theme.colors.secondary};
        margin-right: ${theme.spacing.smallX.all};
    }

    strong {
        color: ${theme.colors.baseDark};
        display: block;
        font-family: ${theme.typography.family.primary.bold.regular};
        margin-bottom: ${theme.spacing.smallX.all};
        text-transform: uppercase;
    }

    span {
        flex: 1;
        font-family: ${theme.typography.family.primary.light.italic};
        font-size: 14px;
    }

    &:nth-last-child(-n + 3) {
        margin-bottom: 0;
    }
`;

export const SpinnerWrapper = styled.div`
    align-items: center;
    display: flex;

    svg {
        animation: ${rotate} 1s linear infinite;
    }
`;

export const PrintStyles = createGlobalStyle`
    @media print {

        html, body {
            height: 210mm;
            width: 297mm;
        }

        * {
            box-shadow: none !important;
            color: #000 !important;
            font-size: 14px;
            text-shadow: none;
            filter: none;
            -ms-filter: none;
        }

        #header,
        #footer,
        #page-header {
            display: none;
        }

        #main {
            padding: 0;
        }

        ${Container} {
            padding: 0;
        }

        @page {
            margin: 36pt 30px;
        }
    }
`;
