import { IField } from 'utils/interfaces/IField';
import InputTypes from 'utils/enums/inputTypes';

const campaigns: IField[] = [
    {
        presentation: 'Nome',
        label: 'Nome da Campanha',
        name: 'name',
        type: InputTypes.text,
        columns: 3
    }
];

export default campaigns;
