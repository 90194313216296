import theme from 'config/theme';
import styled from 'styled-components/macro';
import { SlideInBottom } from 'assets/animations';
import { SlideInLeftSmall } from 'assets/animations';
import { CardContainer } from 'components/card/styles';
import { VideoWrapper } from 'components/player/styles';
import { EmptyContainer } from 'components/empty/styles';
import { ButtonContainer } from 'components/button/styles';
import { Subtitle, SmallTitle, Title } from 'assets/global';
import { TooltipContainer } from 'components/tooltip/styles';
import { QuestionEnunciation } from 'components/question/styles';
import { ButtonGroupContainer } from 'components/buttonGroup/styles';
import { ListContentItemHeader, CompactListHeaderWrapper } from 'components/listContent/styles';

export const ModuleContainer = styled.section`
    padding: ${theme.spacing.large.all};
    padding-right: 0;
`;

export const ModuleHeader = styled.header`
    align-self: center;
    display: flex;
    justify-content: space-between;

    ${Subtitle} {
        display: block;
        font-size: ${theme.typography.sizes.large};
        margin-bottom: ${theme.spacing.large.all};
    }
`;

export const ModuleSection = styled.div`
    ${CompactListHeaderWrapper} {
        margin-bottom: ${theme.spacing.medium.all};
    }

    &:not(:last-child) {
        margin-bottom: 50px;
    }
`;

export const ModuleSectionBody = styled.div``;

export const SectionContainer = styled.div`
    border: 1px solid ${theme.colors.borderColor};
    border-radius: 5px 5px 0 0;
    overflow: hidden;

    &:not(:last-child) {
        margin-bottom: ${theme.spacing.medium.all};
    }
`;

export const SectionHeader = styled(ListContentItemHeader)`
    background: #f3f3f3;
    border-radius: ${({ isActive }) => (isActive ? '5px 5px 0 0' : '5px')};
    cursor: pointer;

    ${TooltipContainer} {
        display: none;
    }
`;

export const SectionContent = styled.div`
    animation: ${SlideInBottom} 0.2s 0s both;
    padding: ${theme.spacing.large.all};
`;

export const ActionsContainer = styled.nav``;

export const ActionsList = styled.ul`
    align-items: center;
    display: flex;
`;

export const ActionsItem = styled.li`
    position: relative;

    ${ButtonContainer} {
        margin: 0;
        padding: 7px;

        > span {
            margin: 0 0 0 ${theme.spacing.smallX.all};
        }
    }

    ${ButtonGroupContainer} {
        animation: ${SlideInLeftSmall} 0.5s ease;
        position: absolute;
        right: 30px;
        top: -2px;

        &:after {
            border-top: 7px solid transparent;
            border-left: 5px solid ${theme.colors.white};
            border-bottom: 7px solid transparent;
            content: '';
            right: -5px;
            position: absolute;
            transform: translateY(-50%);
            top: 50%;
        }
    }
`;

export const SectionContentItemContainer = styled.article`
    &:not(:last-child) {
        margin-bottom: ${theme.spacing.medium.all};
    }
`;

export const SectionContentItemHeader = styled.header`
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: ${theme.spacing.medium.all};

    .ico-play,
    .ico-text-editor,
    .ico-exercises-list {
        fill: ${theme.colors.primary};
    }

    > div {
        align-items: center;
        display: flex;
    }

    ${Subtitle} {
        margin-left: ${theme.spacing.small.all};
    }

    ${TooltipContainer} {
        bottom: calc(100% + 2px);
    }
`;

export const SectionContentItemContent = styled.div``;

export const SectionContentItemContentText = styled.div`
    p {
        &:not(:last-child) {
            margin-bottom: 15px;
        }
    }
`;

export const SectionContentItemFooter = styled.footer`
    margin-top: ${theme.spacing.medium.all};

    ${ButtonContainer} {
        padding: 7px;

        span {
            margin: 0 0 0 5px;
        }
    }
`;

export const VideoDetailsContainer = styled.div`
    background: #f5f5f5;
    border-radius: 5px;
    padding: 20px;
    position: relative;

    ${Subtitle} {
        display: block;
    }
`;

export const IntroductionTitleWrapper = styled.div`
    align-items: center;
    display: flex;
    margin-bottom: ${theme.spacing.medium.all};
    justify-content: space-between;

    > div {
        align-items: center;
        display: flex;
    }

    .ico-play,
    .ico-exercises-list,
    .ico-text-editor,
    .ico-link {
        fill: ${theme.colors.primary};
        margin-right: 10px;
    }
`;

export const VideoActions = styled.nav`
    position: absolute;
    right: 5px;
    top: 5px;
    z-index: 1;

    ${ButtonContainer} {
        padding: 7px;
    }
`;

export const ProjectList = styled.ul``;

export const ProjectListItem = styled.li<{ isMain?: boolean }>`
    align-items: center;
    display: flex;
    line-height: 1;

    svg {
        fill: ${theme.colors.secondary};
    }

    .ico-check {
        fill: ${theme.colors.system.success};
    }

    strong {
        color: ${theme.colors.secondary};
        font-family: ${theme.typography.family.primary.bold.regular};
    }

    span {
        color: ${({ isMain }) => isMain && theme.colors.primary};
        font-family: ${theme.typography.family.primary.normal.italic};
        margin: 0 ${theme.spacing.smallX.all} 0 ${theme.spacing.small.all};
    }

    .ico-star {
        fill: ${theme.colors.system.warning};
    }

    &:not(:last-child) {
        margin-bottom: 15px;
    }
`;

export const ProjectCardHeader = styled.header`
    border-radius: 5px 5px 0 0;
    overflow: hidden;
    position: relative;
`;

export const ProjectMetaMediaSwitcher = styled.div`
    position: absolute;
    right: 15px;
    top: 15px;
    z-index: 2;

    ${ButtonContainer} {
        box-shadow: 0px 0px 30px 0px #616161;
    }
`;

export const ProjectMetaMedia = styled.div<{ activeItem: number }>`
    display: flex;
    flex-wrap: wrap;
    left: ${({ activeItem }) => (activeItem === 2 ? '-100%' : '0')};
    overflow: hidden;
    position: relative;
    transition: left 0.2s ease;
    width: 200%;

    > * {
        flex: 1;
    }

    ${VideoWrapper} {
        height: 100%;
    }
`;

export const ProjectCardFooter = styled.footer`
    border-top: 1px solid ${theme.colors.borderColor};
    margin-top: ${theme.spacing.medium.all};
    padding-top: ${theme.spacing.medium.all};

    ${ProjectList} {
        font-size: 14px;
    }
`;

export const ProjectCover = styled.figure`
    img {
        display: block;
        height: auto;
        max-width: 100%;
    }
`;

export const ProjectCardContent = styled.div`
    padding: ${theme.spacing.medium.all};

    ${SmallTitle} {
        margin-bottom: ${theme.spacing.medium.all};
    }
`;

export const ProjectDetailsContainer = styled.section`
    padding: ${theme.spacing.large.all};

    ${Title} {
        font-family: ${theme.typography.family.primary.bold.regular};
        font-size: 24px;
        margin-bottom: ${theme.spacing.medium.all};
    }

    ${QuestionEnunciation} {
        padding: 20px 20px 0 20px;
    }
`;

export const ProjectDetailsWrapper = styled.div`
    &:not(:last-child) {
        margin-bottom: ${theme.spacing.largeX.all};
    }
`;

export const ModuleDetailsContainer = styled.div`
    margin-top: ${theme.spacing.medium.all};
    width: 100%;

    ${CardContainer} {
        min-width: 100%;
        overflow: hidden;
    }

    ${VideoWrapper} {
        height: 200px;
    }
`;

export const ModuleDetailsIntro = styled(SectionContentItemContentText)`
    font-family: ${theme.typography.family.primary.light.italic};
    margin-top: -10px;
`;

export const ModuleDetailsHeader = styled.div`
    margin-bottom: ${theme.spacing.medium.all};
`;

export const SectionDetailsTitle = styled(SmallTitle)`
    margin-bottom: ${theme.spacing.medium.all};
`;

export const SectionDetailsContainer = styled.div`
    ${EmptyContainer} {
        align-items: flex-start;
        font-family: ${theme.typography.family.primary.light.italic};
        margin-top: -10px;
    }

    &:not(:last-child) {
        margin-bottom: ${theme.spacing.large.all};
    }
`;

export const SectionDetailsContent = styled.ul``;

export const SectionDetailsContentItem = styled.li`
    align-items: center;
    display: flex;

    svg {
        fill: ${theme.colors.secondary};
        margin-right: ${theme.spacing.small.all};
    }

    &:not(:last-child) {
        margin-bottom: 20px;
    }
`;
