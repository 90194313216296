import { lazy } from 'react';

const SubjectGroup = lazy(() => import('pages/subjectGroup'));

const subjectGroupRoutes = [
    {
        path: '/app/grupo-de-materias',
        name: 'Novo Grupo de Materias',
        component: SubjectGroup,
        exact: true,
        title: 'Novo Grupo de Materias'
    }
];

export default subjectGroupRoutes;
