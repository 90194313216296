import { Types as ProductPhysicalTypes, Creators as productPhysicalActions } from 'store/ducks/productPhysical';
import { IGraphqlRequestAction } from 'utils/interfaces/IRequestAction';
import { graphQLRequest } from '../graphQLRequest';
import { takeLatest, put, select, call } from 'redux-saga/effects';
import { Creators as alertActions } from 'store/ducks/alert';
import { IPaginationCore } from 'utils/interfaces/IPagination';
import { IReduxStore } from 'utils/interfaces/IReduxStore';

import history from 'services/history';
import { createProductPhysicalMedia } from 'utils/queries/productsPhysical/createProductPhysicalMedia';
import { deleteProductsPhysicalMedia } from 'utils/queries/productsPhysical/deleteProductsPhysicalMedia';
import { deleteProductsPhysicalConfiguration } from 'utils/queries/productsPhysical/deleteProductsPhysicalConfiguration';
import { IProductPhysicalConfiguration } from 'utils/interfaces/IProductPhysical';
import { getAllProductPhysicalConfigurationOptions } from 'utils/queries/productsPhysical/getAllProductPhysicalConfigurationOptions';
import { checkGraphQLConflict } from 'utils/checkGraphQLConflictQuery';

import { createOrEditProductPhysicalConfigurationOption as createOrEditProductPhysicalConfigurationOptionQuery } from 'utils/queries/productsPhysical/createOrEditProductPhysicalConfigurationOption';
import { deleteProductsPhysicalConfigurationOption } from 'utils/queries/productsPhysical/deleteProductsPhysicalConfigurationOption';

function* getProductPhysicalById(action: IGraphqlRequestAction<{ id: number }>) {
    try {
        const { query, params } = action.payload;

        const { data } = yield graphQLRequest(query, params);

        if (!data?.item) {
            throw new Error();
        }

        const body = {
            ...data.item,
            medias: data.medias || []
        };

        yield put(productPhysicalActions.getProductPhysicalByIdSuccess(body));
    } catch (error) {
        yield put(productPhysicalActions.getProductPhysicalByIdFailure(error));

        yield put(alertActions.showAlert(error?.message || 'Ocorreu um erro. Tente novamente.', 'danger'));
    }
}

function* createMidia(imageUrl: string, idProduct: number, idCompany: number) {
    const params = {
        data: {
            id_product_physical: idProduct,
            media: {
                data: {
                    url: imageUrl,
                    id_company: idCompany
                }
            }
        }
    };

    const { data } = yield graphQLRequest(createProductPhysicalMedia, params);

    if (!data?.item?.media) {
        throw new Error();
    }

    return data.item;
}

function* deleteMidia(idMedia: number) {
    if (!idMedia) {
        return;
    }

    const params = {
        idMedia,
        date: new Date().toISOString()
    };

    const { data } = yield graphQLRequest(deleteProductsPhysicalMedia, params);

    if (!data?.productMedia?.affected_rows) {
        throw new Error();
    }
}

function* createOrEditProductPhysical(action: IGraphqlRequestAction) {
    try {
        const companyId: number | undefined = yield select((state: IReduxStore) => state.organizations.currentCompany?.id);

        if (!companyId) {
            throw new Error();
        }

        const { query, params } = action.payload;

        const isCreateQuery = !params?.data?.id;

        const formatParams = {
            ...params,
            data: {
                ...params.data,
                ...(isCreateQuery && { id_company: companyId }) // ADD COMPANY ID TO CREATE QUERY
            }
        };

        if (formatParams.medias) {
            delete formatParams.medias;
        }

        const { data, errors } = yield graphQLRequest(query, formatParams);

        if (checkGraphQLConflict(errors)) {
            throw new Error('Esse SKU já está sendo usado.');
        }

        if (!data?.item) {
            throw new Error();
        }

        let uploadedMedia: any;
        const currentMediaId: number | undefined = yield select((state: IReduxStore) => (!!state.productPhysical?.medias?.length ? state.productPhysical.medias[0].media?.id : undefined));

        // DELETE CURRENT MEDIA
        if (!!currentMediaId && !params?.medias?.length) {
            yield deleteMidia(currentMediaId);
        }

        if (!!params?.medias?.length) {
            const [firstMedia] = params.medias;

            // CREATE A NEW MEDIA
            if (firstMedia.media.id !== currentMediaId) {
                uploadedMedia = yield createMidia(firstMedia.media.url, data.item.id, companyId);

                yield deleteMidia(currentMediaId!);
            }
        }

        yield put(
            productPhysicalActions.createOrEditProductPhysicalSuccess({
                ...data.item,
                ...(!!uploadedMedia && { medias: [uploadedMedia] })
            })
        );
        yield put(alertActions.showAlert('Produto salvo com sucesso.', 'success'));

        if (isCreateQuery) {
            yield call(history.push, { pathname: `/app/produtos-fisicos/editar/${data.item.id}` });
        }
    } catch (error) {
        yield put(productPhysicalActions.createOrEditProductPhysicalFailure(error));

        yield put(alertActions.showAlert(error?.message || 'Ocorreu um erro. Tente novamente.', 'danger'));
    }
}

function* getAllProductPhysicalConfigurations(action: IGraphqlRequestAction) {
    try {
        const { query, params } = action.payload;

        const { data } = yield graphQLRequest(query, params);

        if (!data?.items) {
            throw new Error();
        }

        const { items, quantity } = data;
        const { offset = 0, limit = 10 } = params;
        const count = quantity?.aggregate?.count || 0;

        const pagination: IPaginationCore = {
            page: (limit + offset) / limit,
            totalItems: count,
            count: items.length,
            totalPages: Math.ceil(count / limit)
        };

        const body = {
            items,
            pagination
        };

        yield put(productPhysicalActions.getAllProductPhysicalConfigurationsSuccess(body));
    } catch (error) {
        yield put(productPhysicalActions.getAllProductPhysicalConfigurationsFailure(error));

        yield put(alertActions.showAlert(error?.message || 'Ocorreu um erro ao carregar as configurações.', 'danger'));
    }
}

function* deleteProductPhysicalConfiguration(action: { type: string; payload: { id: number } }) {
    try {
        const { id } = action.payload;

        if (!id) {
            throw new Date();
        }

        const { data } = yield graphQLRequest(deleteProductsPhysicalConfiguration, {
            id,
            date: new Date().toISOString()
        });

        if (!data?.item?.affected_rows) {
            throw new Error();
        }

        yield put(productPhysicalActions.deleteProductPhysicalConfigurationSuccess({ id }));
        yield put(alertActions.showAlert('Configuração removida com sucesso.', 'success'));
    } catch (error) {
        yield put(productPhysicalActions.deleteProductPhysicalConfigurationFailure(error));

        yield put(alertActions.showAlert(error?.message || 'Ocorreu um erro ao remover a configuração.', 'danger'));
    }
}

function* openProductPhysicalConfigurationModal(action: { type: string; payload: IProductPhysicalConfiguration }) {
    try {
        if (!action?.payload?.id) {
            return yield put(productPhysicalActions.openProductPhysicalConfigurationModalSuccess());
        }

        const payload = {
            orderBy: {
                name: 'asc'
            },
            where: {
                deleted: {
                    _is_null: true
                },
                id_product_physical_configuration: {
                    _eq: action.payload.id
                }
            }
        };

        const { data } = yield graphQLRequest(getAllProductPhysicalConfigurationOptions, payload);

        if (!data?.items) {
            throw new Error();
        }

        yield put(productPhysicalActions.openProductPhysicalConfigurationModalSuccess(data.items));
    } catch (error) {
        console.log(error);

        yield put(productPhysicalActions.closeProductPhysicalConfigurationModal());

        yield put(alertActions.showAlert(error?.message || 'Ocorreu um erro ao remover a configuração.', 'danger'));
    }
}

function* createOrEditProductPhysicalConfiguration(action: IGraphqlRequestAction<IProductPhysicalConfiguration>) {
    try {
        const { query, params } = action.payload;

        const isCreate = !params?.id;

        const companyId: number | undefined = yield select((state: IReduxStore) => state.organizations.currentCompany?.id);
        const productId: number | undefined = yield select((state: IReduxStore) => state.productPhysical.id);

        if (!companyId || !productId) {
            throw new Error();
        }

        const createParams = {
            data: {
                name: params.name,
                // order: 1,
                id_product_physical: productId
            }
        };

        const editParams = {
            id: params.id,

            data: {
                name: params.name,
                id_product_physical: productId
            }
        };

        const { data } = yield graphQLRequest(query, isCreate ? createParams : editParams);

        if (!data?.item) {
            throw new Error();
        }

        yield put(productPhysicalActions.createOrEditProductPhysicalConfigurationSuccess(data.item));

        yield put(alertActions.showAlert('Configuração salva com sucesso.', 'success'));

        if (!isCreate) {
            yield put(productPhysicalActions.closeProductPhysicalConfigurationModal());
        }
    } catch (error) {
        console.log(error);
        yield put(productPhysicalActions.createOrEditProductPhysicalConfigurationFailure(error));

        yield put(alertActions.showAlert(error?.message || 'Ocorreu um erro ao remover a configuração.', 'danger'));
    }
}

function* createOrEditProductPhysicalConfigurationOption(action: { type: string; payload: { id: number; name: string; sku: string; image?: { id?: number; url: string } } }) {
    try {
        const configurationId: number | undefined = yield select((state: IReduxStore) => state?.productPhysical?.activeConfiguration?.id);
        const companyId: number | undefined = yield select((state: IReduxStore) => state?.organizations?.currentCompany?.id);

        if (!action.payload || !configurationId || !companyId) {
            throw new Error();
        }

        const { id, name, sku, image } = action.payload;

        const params: any = {
            data: {
                ...(!!id && { id }),
                sku,
                name,
                id_product_physical_configuration: configurationId
            }
        };

        if (!!image?.url) {
            params.data.media = {
                data: {
                    id_company: configurationId,
                    ...image
                },
                on_conflict: {
                    constraint: 'PK_8075d558ea6dc52f09dbea64ebb',
                    update_columns: ['updated', 'url', 'id_company']
                }
            };
        }

        const { data } = yield graphQLRequest(createOrEditProductPhysicalConfigurationOptionQuery, params);

        if (!data?.item) {
            throw new Error();
        }

        yield put(productPhysicalActions.createOrEditProductPhysicalConfigurationOptionSuccess(data.item));

        yield put(alertActions.showAlert('Configuração salva.', 'success'));

        yield put(productPhysicalActions.closeProductPhysicalConfigurationOption());
    } catch (error) {
        console.log(error);
        yield put(productPhysicalActions.createOrEditProductPhysicalConfigurationOptionFailure());

        yield put(alertActions.showAlert(error?.message || 'Ocorreu um erro ao salvar a configuração.', 'danger'));
    }
}

function* deleteProductPhysicalConfigurationOption(action: { type: string; payload: { id: number } }) {
    try {
        if (!action?.payload?.id) {
            throw new Error();
        }

        const { data } = yield graphQLRequest(deleteProductsPhysicalConfigurationOption, { id: action?.payload.id, date: new Date().toISOString() });

        if (!data?.item?.affected_rows) {
            throw new Error();
        }

        yield put(productPhysicalActions.deleteProductPhysicalConfigurationOptionSuccess(action?.payload));

        yield put(alertActions.showAlert('Opção excluída com successo.', 'success'));

        yield put(productPhysicalActions.closeProductPhysicalConfigurationOption());
    } catch (error) {
        console.log(error);
        yield put(productPhysicalActions.deleteProductPhysicalConfigurationOptionFailure());

        yield put(alertActions.showAlert(error?.message || 'Ocorreu um erro ao excluir a opção.', 'danger'));
    }
}

export default [
    takeLatest(ProductPhysicalTypes.GET_PRODUCT_PHYSICAL_BY_ID_REQUEST, getProductPhysicalById),
    takeLatest(ProductPhysicalTypes.GET_ALL_PRODUCT_PHYSICAL_CONFIGURATIONS_REQUEST, getAllProductPhysicalConfigurations),
    takeLatest(ProductPhysicalTypes.CREATE_OR_EDIT_PRODUCT_PHYSICAL_REQUEST, createOrEditProductPhysical),
    takeLatest(ProductPhysicalTypes.DELETE_PRODUCT_PHYSICAL_CONFIGURATION_REQUEST, deleteProductPhysicalConfiguration),
    takeLatest(ProductPhysicalTypes.OPEN_PRODUCT_PHYSICAL_CONFIGURATION_MODAL_REQUEST, openProductPhysicalConfigurationModal),
    takeLatest(ProductPhysicalTypes.CREATE_OR_EDIT_PRODUCT_PHYSICAL_CONFIGURATION_REQUEST, createOrEditProductPhysicalConfiguration),
    takeLatest(ProductPhysicalTypes.CREATE_OR_EDIT_PRODUCT_PHYSICAL_CONFIGURATION_OPTION_REQUEST, createOrEditProductPhysicalConfigurationOption),
    takeLatest(ProductPhysicalTypes.DELETE_PRODUCT_PHYSICAL_CONFIGURATION_OPTION_REQUEST, deleteProductPhysicalConfigurationOption)
];
