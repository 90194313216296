import { put, call } from 'redux-saga/effects';
import { Creators as schoolunitActions } from 'store/ducks/schoolunit';
import { Creators as alertActions } from 'store/ducks/alert';
import history from 'services/history';
import { apiRequest } from '../apiRequest';

export function* getAllSchoolUnits(action: any) {
    try {
        const { data } = yield apiRequest('GET', '/schoolunit', undefined, {
            'X-No-Pagination': true
        });

        yield put(schoolunitActions.getAllSchoolUnitsSuccess(data.items));
    } catch (error) {
        yield put(schoolunitActions.getAllSchoolUnitsFailure());
        yield put(alertActions.showAlert('Não foi possível encontrar esse registro.', 'danger'));
    }
}

export function* getSchoolUnitById(action: any) {
    try {
        const { payload } = action;

        const { data } = yield apiRequest(payload.method, payload.endpoint, payload.body, payload.headers);

        yield put(schoolunitActions.getSchoolUnitByIdSuccess(data));
    } catch (error) {
        // @ts-ignore
        yield call(history.push, `/app/unidades-de-escola`);
        yield put(schoolunitActions.getSchoolUnitByIdFailure());
        yield put(alertActions.showAlert('Não foi possível encontrar esse registro.', 'danger'));
    }
}

export function* createOrEditSchoolUnit(action: any) {
    try {
        const { payload } = action;

        const { data } = yield apiRequest(payload.method, payload.endpoint, payload.body, payload.headers);

        yield put(schoolunitActions.createOrEditSchoolUnitSuccess(data));

        yield put(alertActions.showAlert(`Unidade de Escola ${payload.method === 'POST' ? 'Criada' : 'Atualizada'}!`, 'success'));

        if (payload.method === 'POST') {
            // @ts-ignore
            yield call(history.push, `/app/unidades-de-escola/editar/${data.id}`);
        }
    } catch (error) {
        yield put(schoolunitActions.createOrEditSchoolUnitFailure());
        yield put(alertActions.showAlert('Ocorreu um erro. Tente novamente mais tarde.', 'danger'));
    }
}
