import { lazy } from 'react';

const List = lazy(() => import('pages/crud'));
const CreateOrEdit = lazy(() => import('pages/crud/createOrEdit'));
const View = lazy(() => import('pages/exam'));

const examRoutes = [
    {
        path: '/app/provas-anteriores',
        name: 'Provas Anteriores',
        component: List,
        exact: true,
        title: 'Provas Anteriores'
    },
    {
        path: '/app/provas-anteriores/novo',
        name: 'Nova prova Anterior',
        component: CreateOrEdit,
        exact: true,
        title: 'Nova Prova Anterior'
    },
    {
        path: '/app/provas-anteriores/editar/:id',
        name: 'Editar',
        component: CreateOrEdit,
        exact: true,
        title: 'Editar Prova Anterior'
    },
    {
        path: '/app/provas-anteriores/ver/:id',
        name: 'Ver',
        component: View,
        exact: false,
        title: 'Ver Prova Anterior'
    }
];

export default examRoutes;
