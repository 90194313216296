import React, { useEffect, useState, useCallback, useMemo } from 'react';

// Assets
import { Content, Container } from 'assets/global';

import { Row, Col } from 'react-bootstrap';

// Components

import Loading from 'components/loading';

import PageHeader from 'components/pageHeader';

// Helpers
import { RouteComponentProps } from 'react-router';
import { IStoreState, IStoreLocation, OrderStatuses } from 'utils/interfaces/IStore';
import { IPayloadGraphqlRequest } from 'utils/interfaces/IRequestAction';
import { IStoreActions, IModalActions } from 'utils/interfaces/IActions';

//Queries
import { storeByPk } from 'utils/queries/store/storeByPk';

import { CardContainer } from 'components/card/styles';
import ErrorBoundary from 'components/error/error';
import StoreNav from './components/nav';

import CardDetails from './components/cardDetails';
import StoreList, { IStoreListProps } from './components/list';
import { storeLocations } from 'utils/queries/store/storeLocations';
import { getAllCampaignsQuery } from 'utils/queries/campaigns/getAllCampaigns';
import history from 'services/history';

import { TableItemType } from 'utils/enums/tableItemType';
import { TableAdvancedSearchType } from 'utils/enums/tableAdvancedSearchType';

interface IProps extends RouteComponentProps<{ id: string; activeEntity: string }> {
    store: IStoreState;
    storeActions: IStoreActions;
    modalActions: IModalActions;
}

export enum StoreNavOptions {
    Unities,
    Categories,
    Products,
    Orders,
    Campaigns
}

const activeEntityOptions = {
    campanhas: StoreNavOptions.Campaigns
};

const Store = ({ match, store, storeActions, modalActions }: IProps) => {
    const { id, activeEntity } = match.params;

    const [currentEntity, setCurrentEntity] = useState<StoreNavOptions>(activeEntity ? activeEntityOptions[activeEntity] : StoreNavOptions.Orders);

    useEffect(() => {
        return () => storeActions.clearStore();
    }, [storeActions]);

    const requestStore = useCallback(() => {
        if (!id) {
            return;
        }

        const payload: IPayloadGraphqlRequest<any> = {
            query: storeByPk,
            params: {
                id
            }
        };

        storeActions.getStoreRequest(payload);
    }, [id, storeActions]);

    useEffect(() => requestStore(), [requestStore]);

    const handleClickCreateOrEditEntity = useCallback(
        (modal: string, entity?: IStoreLocation) => {
            modalActions.openModal(modal, {
                storeId: +id,
                ...entity
            });
        },
        [id, modalActions]
    );

    const handleClickViewOrder = useCallback((id: number) => history.push(`/app/lojas/ver/${store.id}/pedidos/${id}`), [store.id]);

    const relationContentConfig: IStoreListProps | null = useMemo(() => {
        if (!store.id) {
            return null;
        }

        switch (currentEntity) {
            case StoreNavOptions.Unities:
                return {
                    title: 'Unidades',
                    columns: [
                        {
                            name: 'name',
                            type: TableItemType.Text
                        },
                        {
                            name: 'address.city',
                            type: TableItemType.Text
                        }
                    ],

                    canSeeDetails: false,
                    onClickCreate: () => handleClickCreateOrEditEntity('storeAddress'),
                    onClickEdit: (_: number, item: any) => handleClickCreateOrEditEntity('storeAddress', item),
                    getAllQuery: storeLocations,
                    idStore: store.id
                };

            case StoreNavOptions.Categories:
                return {
                    title: 'Categorias',
                    columns: [
                        {
                            name: 'name',
                            type: TableItemType.Text
                        },
                        {
                            name: 'slug',
                            type: TableItemType.Text
                        }
                    ],

                    canSeeDetails: false,
                    onClickCreate: () => handleClickCreateOrEditEntity('storeCategory'),
                    onClickEdit: (_: number, item: any) => handleClickCreateOrEditEntity('storeCategory', item),
                    getAllQuery: storeLocations,
                    tableName: 'stores_categories',
                    idStore: store.id
                };

            case StoreNavOptions.Products:
                return {
                    title: 'Produtos',
                    columns: [
                        {
                            name: 'name',
                            type: TableItemType.Text,
                            grid: 5
                        },
                        {
                            name: 'slug',
                            type: TableItemType.Text
                        },
                        {
                            name: 'is_active',
                            type: TableItemType.Boolean
                        }
                    ],

                    onClickCreate: () => history.push(`/app/produtos/novo?storeId=${store.id}`),
                    onClickEdit: (id: number, _: any) => history.push(`/app/produtos/ver/${id}?storeId=${store.id}`),
                    tableName: 'products',
                    basePath: '/app/produtos',
                    idStore: store.id,
                    canSeeDetails: false
                };

            case StoreNavOptions.Orders:
                return {
                    title: 'Pedidos',
                    onClickDetails: handleClickViewOrder,
                    columns: [
                        {
                            name: 'code',
                            type: TableItemType.Text
                        },
                        {
                            name: 'amount',
                            type: TableItemType.Number
                        },
                        {
                            name: 'customer.name',
                            type: TableItemType.Text
                        },
                        {
                            name: 'created',
                            type: TableItemType.Calendar
                        },
                        {
                            name: 'status.name',
                            type: TableItemType.Enum,
                            enum: OrderStatuses
                        },
                        {
                            name: 'is_erp_synced',
                            type: TableItemType.Boolean
                        }
                    ],
                    tableName: 'orders',
                    canDelete: false,
                    canEdit: false,
                    idStore: store.id,
                    advancedSearch: [
                        {
                            name: 'customer.email',
                            type: TableAdvancedSearchType.Email
                        },
                        {
                            name: 'details.id_product',
                            type: TableAdvancedSearchType.AsyncSelect,
                            relation: {
                                graphQLCompanyRelatioName: 'brand.company',
                                graphQLBrandRelatioName: 'brand',
                                graphQLTableName: 'products'
                            },
                            defaultFilters: {
                                id_store: {
                                    _eq: store.id
                                }
                            }
                        }
                    ]
                };

            case StoreNavOptions.Campaigns:
                return {
                    title: 'Campanhas de Desconto',
                    columns: [
                        {
                            name: 'name',
                            type: TableItemType.Text,
                            label: 'Nome da Campanha'
                        },
                        {
                            name: 'code',
                            type: TableItemType.Text,
                            label: 'Cupom'
                        },
                        {
                            name: 'usage',
                            type: TableItemType.Number,
                            label: 'Usos'
                        },
                        {
                            name: 'qtd',
                            type: TableItemType.Number,
                            label: 'Quantidade Total'
                        },
                        {
                            name: 'date_start',
                            type: TableItemType.Date,
                            label: 'Data Inicio'
                        },
                        {
                            name: 'date_finish',
                            type: TableItemType.Date,
                            label: 'Data Fim'
                        },
                        {
                            name: 'is_active',
                            type: TableItemType.Boolean,
                            label: 'Ativo'
                        }
                    ],
                    canSeeDetails: false,
                    onClickCreate: () => history.push(`/app/campanhas/novo?storeId=${store.id}`),
                    onClickEdit: (id: number, _: any) => history.push(`/app/campanhas/editar/${id}?storeId=${store.id}`),
                    getAllQuery: getAllCampaignsQuery,
                    idStore: store.id,
                    tableName: 'campaigns'
                };

            default:
                return null;
        }
    }, [currentEntity, handleClickCreateOrEditEntity, handleClickViewOrder, store.id]);

    return (
        <>
            {store.isLoading && <Loading fullScreen={true} />}
            <PageHeader hasEditButton={true} title={store.name || 'Visualizar loja'} />
            <Content>
                <Container>
                    <CardDetails store={store} />

                    <Row style={{ position: 'relative' }}>
                        <Col lg={3}>
                            <StoreNav onClick={setCurrentEntity} activeEntity={currentEntity} />
                        </Col>
                        <Col lg={9}>
                            <CardContainer style={{ position: 'relative' }} withSpacing={true}>
                                {!!store?.id && <ErrorBoundary>{!!relationContentConfig && <StoreList {...relationContentConfig} />}</ErrorBoundary>}
                            </CardContainer>
                        </Col>
                    </Row>
                </Container>
            </Content>
        </>
    );
};

export default Store;
