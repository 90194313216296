import React, { memo } from 'react';
import { NavListContainer, NavListItemScroll, NavListItem, NavListItemLink } from './styles';
import { SmallTitle } from 'assets/global';

export interface INavListItem {
    id: any;
    title: string;
    onClick?(): void;
    active?: boolean;
    path?: string;
    exact?: boolean;
}
interface IProps {
    items: Array<INavListItem>;
    title?: string;
}

const NavList = memo(({ items, title }: IProps) => {
    if (!items.length) {
        return null;
    }

    return (
        <>
            {!!title && <SmallTitle style={{ marginBottom: '20px' }}>{title}</SmallTitle>}

            <NavListContainer>
                {items.map(({ id, title, onClick, active = false, path = '', exact = false }) => {
                    switch (true) {
                        case !!onClick:
                            return (
                                <NavListItem key={id} type="button" onClick={onClick} className={active ? 'active' : undefined}>
                                    {title}
                                </NavListItem>
                            );

                        case !!path:
                            return (
                                <NavListItemLink key={id} to={path} activeClassName="active" exact={exact}>
                                    {title}
                                </NavListItemLink>
                            );

                        default:
                            return (
                                <NavListItemScroll activeClass="active" to={id} spy={true} smooth={true} duration={500} offset={-30} key={id}>
                                    {title}
                                </NavListItemScroll>
                            );
                    }
                })}
            </NavListContainer>
        </>
    );
});

export default NavList;
