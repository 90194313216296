export const deleteProductsPhysicalMedia = `
mutation ($date: timestamptz, $idMedia: Int!) {
    productMedia: delete_prodigio_product_physical_medias(where: {id_media: {_eq: $idMedia}}) {
      affected_rows
    }
    media: update_prodigio_medias_by_pk(pk_columns: {id: $idMedia}, _set: {deleted: $date}) {
      id
    }
  }
`;
