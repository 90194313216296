import { put, call } from 'redux-saga/effects';
import { Creators as alertActions } from 'store/ducks/alert';
import { Creators as teacherActions } from 'store/ducks/teacher';
import { Creators as modalActions } from 'store/ducks/modal';
import { apiRequest, restVerbs } from '../apiRequest';
import history from 'services/history';
import { uploadFile } from '../upload';
import { graphQLRequest } from '../graphQLRequest';
import { getPersonAddresses } from 'utils/queries/person';

export function* teacherRequest(action: any) {
    try {
        const payload = {
            method: 'GET' as restVerbs,
            endpoint: `/teacher`,
            header: {
                'X-Relations': 'person',
                'X-No-Pagination': true
            }
        };

        const { data } = yield apiRequest(payload.method, payload.endpoint, null, payload.header);

        const validTeachers = data.items.filter((teacher: any) => teacher.person !== null);

        const formatTeachers = validTeachers.map((teacherItem: any) => ({
            value: teacherItem.id,
            label: teacherItem.person.name
        }));

        yield put(teacherActions.teacherSuccess(formatTeachers));
    } catch (error) {
        yield put(teacherActions.teacherFailure());
        console.log('error: ', error);
    }
}

export function* getTeacherById(action: any) {
    try {
        const { payload } = action;

        const { data } = yield apiRequest(payload.method, payload.endpoint, payload.body, payload.headers);

        yield put(teacherActions.getTeacherByIdSuccess(data));
    } catch (error) {
        console.log(error);
        yield put(teacherActions.getTeacherByIdFailure());
        yield put(alertActions.showAlert('Ocorreu um erro. Tente novamente mais tarde.', 'danger'));
    }
}

export function* createOrEditTeacher(action: any) {
    try {
        const { payload } = action;

        if (!!payload.body.fileImage) {
            const payloadUpload = {
                method: 'POST',
                endpoint: `/upload`,
                body: {
                    file: payload.body.fileImage
                }
            };

            const response = yield call(uploadFile, payloadUpload);

            delete payload.body.fileImage;

            payload.body.image = response;
        }

        const { data } = yield apiRequest(payload.method, payload.endpoint, payload.body, payload.headers);

        yield put(teacherActions.createOrEditTeacherSuccess(data));

        if (payload.method === 'POST') {
            // @ts-ignore
            yield call(history.push, `/app/professores/editar/${data.id}`);
        }

        yield put(alertActions.showAlert('Professor Salvo!', 'success'));
    } catch (error) {
        console.log(error);
        yield put(teacherActions.createOrEditTeacherFailure());
        yield put(alertActions.showAlert(error?.response?.data?.detail?.message || 'Ocorreu um erro. Tente novamente mais tarde.', 'danger'));
    }
}

export function* createOrEditTeacherAddress(action: any) {
    try {
        const { type, graphql, rest } = action.payload;

        if (type === 'create') {
            const { data } = yield graphQLRequest(graphql.query, { data: graphql.data });

            if (!data) {
                throw new Error();
            }
        } else {
            const { data } = yield apiRequest(rest.method, rest.endpoint, rest.body, rest.headers);

            if (!data.person.addresses) {
                throw new Error();
            }
        }

        const response = yield graphQLRequest(getPersonAddresses, { id: graphql.data.id_person });

        yield put(teacherActions.createOrEditTeacherAddressSuccess(response.data.addresses));
        yield put(modalActions.closeModal());

        yield put(alertActions.showAlert(`Endereço ${type === 'create' ? 'criado' : 'alterado'} com sucesso`, 'success'));
    } catch (error) {
        console.log(error);
        yield put(teacherActions.createOrEditTeacherAddressFailure());
        yield put(alertActions.showAlert(error?.response?.data?.detail?.message || 'Ocorreu um erro. Tente novamente mais tarde.', 'danger'));
    }
}

export function* deleteTeacherAddress(action: any) {
    try {
        const { payload } = action;

        yield apiRequest(payload.method, payload.endpoint, payload.body, payload.headers);

        yield put(teacherActions.deleteTeacherAddressSuccess(payload.body));

        yield put(alertActions.showAlert('Endereço Excluído!', 'success'));
    } catch (error) {
        console.log(error);
        yield put(teacherActions.deleteTeacherAddressFailure());
        yield put(alertActions.showAlert(error?.response?.data?.detail?.message || 'Ocorreu um erro. Tente novamente mais tarde.', 'danger'));
    }
}
