import { createActions, createReducer } from 'reduxsauce';
import { ISubject } from 'utils/interfaces/ISubject';
import { formatLoType } from 'utils/formatLoType';
import { IBookletModule, IBookletModuleTemplate } from 'utils/interfaces/IBookletModule';
import { IContentPerTypeItem } from 'utils/interfaces/IContentPerType';

export const { Creators, Types } = createActions({
    getCourseByIdRequest: ['payload'],
    getCourseByIdSuccess: ['payload'],
    getCourseByIdFailure: [],

    createOrEditCourseRequest: ['payload'],
    createOrEditCourseSuccess: ['payload'],
    createOrEditCourseFailure: ['payload'],

    createModuleRequest: ['payload'],
    createModuleSuccess: ['payload'],
    createModuleFailure: ['payload'],

    deleteModuleRequest: ['payload'],
    deleteModuleSuccess: ['payload'],
    deleteModuleFailure: ['payload'],

    getModulesBySubjectRequest: ['payload'],
    getModulesBySubjectSuccess: ['payload'],
    getModulesBySubjectFailure: [],

    createModuleAndTemplateRequest: ['payload'],
    createModuleAndTemplateSuccess: ['payload'],
    createModuleAndTemplateFailure: [],

    createBookletContentItemRequest: ['payload'],
    createBookletContentItemSuccess: ['payload'],
    createBookletContentItemFailure: ['payload'],

    deleteBookletContentItemRequest: ['payload'],
    deleteBookletContentItemSuccess: ['payload'],
    deleteBookletContentItemFailure: ['payload'],

    associateQrCodeToModuleRequest: ['payload'],
    associateQrCodeToModuleSuccess: ['payload'],
    associateQrCodeToModuleFailure: ['payload'],

    associateQrCodeToBookletContentItemRequest: ['payload'],
    associateQrCodeToBookletContentItemSuccess: ['payload'],
    associateQrCodeToBookletContentItemFailure: ['payload'],

    disassociateQrCodeToBookletContentItemRequest: ['payload'],
    disassociateQrCodeToBookletContentItemSuccess: ['payload'],
    disassociateQrCodeToBookletContentItemFailure: ['payload'],

    editCourseModuleRequest: ['payload'],
    editCourseModuleSuccess: ['payload'],
    editCourseModuleFailure: ['payload'],

    saveBookletModuleTemplateRequest: ['payload'],
    saveBookletModuleTemplateSuccess: ['payload'],
    saveBookletModuleTemplateFailure: ['payload'],

    getBookletModuleTemplatesRequest: ['payload'],
    getBookletModuleTemplatesSuccess: ['payload'],
    getBookletModuleTemplatesFailure: ['payload'],

    reorderCourseItemsRequest: ['payload'],
    reorderCourseItemsSuccess: ['payload'],
    reorderCourseItemsFailure: ['payload'],

    reorderCourseContentItemsRequest: ['payload'],
    reorderCourseContentItemsSuccess: ['payload'],
    reorderCourseContentItemsFailure: ['payload'],

    editCourseContentItemRequest: ['payload'],
    editCourseContentItemSuccess: ['payload'],
    editCourseContentItemFailure: ['payload'],

    editCoursePlaylist: ['payload'],

    setCurrentModule: ['payload'],

    clearTemplatesAndModules: [],

    clearCourse: []
});

export interface ICourseState {
    isLoading: boolean;
    id: number;
    name: string;
    slug: string;
    schoolPeriod?: any;
    currentModule: IBookletModule;
    bookletModuleTemplates: IBookletModuleTemplate[];
    selectedModules: IBookletModule[];
    subjects?: ISubject[];
    isPublished: boolean;
    helptext: string;
}

const INITIAL_STATE: ICourseState = {
    isLoading: false,
    id: 0,
    name: '',
    slug: '',
    selectedModules: [],
    isPublished: true,
    helptext: '',
    currentModule: {
        order: 0,
        id: 0,
        contentPerType: [],
        name: '',
        subject: {
            id: 0,
            name: '',
            slug: ''
        },
        subjectFront: null,
        template: {
            id: 0,
            name: ''
        }
    },
    bookletModuleTemplates: []
};

// Create Or Edit
const createOrEditCourseRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true });

const createOrEditCourseSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoading: false,
    ...action.payload
});

const createOrEditCourseFailure = (state = INITIAL_STATE) => ({ ...state, isLoading: false });

// Get Modules
const getModulesBySubjectRequest = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoading: true,
    selectedModules: []
});

const getModulesBySubjectSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoading: false,
    selectedModules: action.payload
});

const getModulesBySubjectFailure = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoading: false,
    selectedModules: []
});

// Create Module
const createModuleRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true });

const createModuleSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoading: false,
    selectedModules: [action.payload, ...state.selectedModules]
});

const createModuleFailure = (state = INITIAL_STATE) => ({ ...state, isLoading: false, isSaved: false, hasError: true });

// Delete Module
const deleteModuleRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true });

const deleteModuleSuccess = (state = INITIAL_STATE, action: any) => {
    const updateModules = state.selectedModules.filter((item) => item.id !== action.payload.id);

    return {
        ...state,
        isLoading: false,
        selectedModules: updateModules,
        currentModule: action.payload.id === state.currentModule!.id ? {} : state.currentModule
    };
};

const deleteModuleFailure = (state = INITIAL_STATE) => ({ ...state, isLoading: false, isSaved: false });

// createBookletContentItem
const createBookletContentItemRequest = (state = INITIAL_STATE) => ({
    ...state,
    isLoading: true
});

const createBookletContentItemSuccess = (state = INITIAL_STATE, action: any) => {
    const updateModules = state.selectedModules.map((item) => (item.id === action.payload.id ? { ...item, ...action.payload } : item));

    const updateCurrentdModule = state.selectedModules.find((item) => item.id === action.payload.id);

    return {
        ...state,
        isLoading: false,
        currentModule: { ...updateCurrentdModule, ...action.payload },
        selectedModules: updateModules
    };
};

const createBookletContentItemFailure = (state = INITIAL_STATE) => ({ ...state, isLoading: false });

// associate qr code to BookletContentItem

const associateQrCodeToBookletContentItemRequest = (state = INITIAL_STATE) => ({
    ...state,
    isLoading: true
});

const associateQrCodeToBookletContentItemSuccess = (state = INITIAL_STATE, action: any) => {
    // contentItem: idContent // contentType
    const { contentItem, moduleId, qrCode } = action.payload;

    // Atualiza a Lista de Conteudos do Módulo Selecionado
    const updateCurrentdModuleContentPerType = state.currentModule!.contentPerType.map((contentPerType: any) =>
        contentPerType.type.toLowerCase() === contentItem.contentType
            ? {
                  ...contentPerType,
                  items: contentPerType.items.map((contentPerTypeItem: any) =>
                      !!contentPerTypeItem.learningObject[formatLoType[contentItem.contentType]] &&
                      contentPerTypeItem.learningObject[formatLoType[contentItem.contentType]].id === contentItem.idContent
                          ? { ...contentPerTypeItem, learningObject: { ...contentPerTypeItem.learningObject, qrCode } }
                          : contentPerTypeItem
                  )
              }
            : contentPerType
    );

    const updateSelectedModules = state.selectedModules.map((bookletModule) =>
        bookletModule.id === moduleId ? { ...bookletModule, contentPerType: updateCurrentdModuleContentPerType } : bookletModule
    );

    return {
        ...state,
        isLoading: false,
        currentModule: {
            ...state.currentModule,
            contentPerType: updateCurrentdModuleContentPerType
        },
        selectedModules: updateSelectedModules
    };
};

const associateQrCodeToBookletContentItemFailure = (state = INITIAL_STATE) => ({ ...state, isLoading: false });

// disassociate qr code to BookletContentItem

const disassociateQrCodeToBookletContentItemRequest = (state = INITIAL_STATE) => ({
    ...state,
    isLoading: true
});

const disassociateQrCodeToBookletContentItemSuccess = (state = INITIAL_STATE, action: any) => {
    const { contentPerTypeItemLOId, moduleId } = action.payload;

    const updateSelectedModules = state.selectedModules.map((selectedModule) =>
        selectedModule.id === moduleId
            ? {
                  ...selectedModule,
                  contentPerType: selectedModule.contentPerType.map((contentPerType) => ({
                      ...contentPerType,
                      items: contentPerType.items.map((contentPerTypeItem) =>
                          contentPerTypeItem?.learningObject?.id === contentPerTypeItemLOId
                              ? { ...contentPerTypeItem, learningObject: { ...contentPerTypeItem.learningObject, qrCode: undefined } }
                              : contentPerTypeItem
                      )
                  }))
              }
            : selectedModule
    );

    const updateCurrentdModuleContentPerType = state.currentModule.contentPerType.map((contentPerType) => ({
        ...contentPerType,
        items: contentPerType.items.map((contentPerTypeItem) =>
            contentPerTypeItem?.learningObject?.id === contentPerTypeItemLOId
                ? { ...contentPerTypeItem, learningObject: { ...contentPerTypeItem.learningObject, qrCode: undefined } }
                : contentPerTypeItem
        )
    }));

    return {
        ...state,
        isLoading: false,
        selectedModules: updateSelectedModules,
        currentModule: {
            ...state.currentModule,
            contentPerType: updateCurrentdModuleContentPerType
        }
    };
};

const disassociateQrCodeToBookletContentItemFailure = (state = INITIAL_STATE) => ({ ...state, isLoading: false });

// deleteBookletContentItem
const deleteBookletContentItemRequest = (state = INITIAL_STATE) => ({
    ...state,
    isLoading: true
});

const deleteBookletContentItemSuccess = (state = INITIAL_STATE, action: any) => {
    const { module, id, type } = action.payload;

    const updateModules = state.selectedModules.map((item) => {
        if (item.id === module) {
            return {
                ...item,
                contentPerType: item.contentPerType.map((content: any) => (content.type === type ? { ...content, items: content.items.filter((contentItem: any) => contentItem.id !== id) } : content))
            };
        }

        return item;
    });

    const updateCurrentdModuleContent = state.currentModule!.contentPerType.map((content: any) =>
        content.type === type ? { ...content, items: content.items.filter((contentItem: any) => contentItem.id !== id) } : content
    );

    return {
        ...state,
        isLoading: false,
        selectedModules: updateModules,
        currentModule: {
            ...state.currentModule,
            contentPerType: updateCurrentdModuleContent
        }
    };
};

const deleteBookletContentItemFailure = (state = INITIAL_STATE) => ({ ...state, isLoading: false });

// NOVO MODULO
const createModuleAndTemplateRequest = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoading: true
});

const createModuleAndTemplateSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoading: false,
    selectedModules: [...state.selectedModules, action.payload],
    currentModule: action.payload
});

const createModuleAndTemplateFailure = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoading: false
});

// EDITAR MODULE
const editCourseModuleRequest = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoading: true
});

const editCourseModuleSuccess = (state = INITIAL_STATE, action: any) => {
    const updateSelectedModules = state.selectedModules.map((bookletmodule) => (bookletmodule.id === action.payload.id ? action.payload : bookletmodule));

    return {
        ...state,
        isLoading: false,
        currentModule: action.payload,
        selectedModules: updateSelectedModules
    };
};

const editCourseModuleFailure = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoading: false
});

// Associar QRCode a um Modulo
const associateQrCodeToModuleRequest = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoading: true
});

const associateQrCodeToModuleSuccess = (state = INITIAL_STATE, action: any) => {
    const { payload } = action;
    const updateSelectedModules = state.selectedModules.map((item) => (item.id === payload.idContent ? { ...item, code: payload.code } : item));

    return {
        ...state,
        isLoading: false,
        currentModule: {
            ...state.currentModule,
            code: payload.code
        },
        selectedModules: updateSelectedModules
    };
};

const associateQrCodeToModuleFailure = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        isLoading: false
    };
};

const setCurrentModule = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoading: false,
    currentModule: action.payload
});

const clearTemplatesAndModules = (state = INITIAL_STATE) => ({
    ...state,
    currentModule: {}
});

const clearCourse = (state = INITIAL_STATE) => INITIAL_STATE;

// TEMPLATE
const getBookletModuleTemplates = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        isLoading: false,
        bookletModuleTemplates: action.payload
    };
};

const saveTemplateModuleSuccess = (state = INITIAL_STATE, action: any) => {
    const { payload } = action;

    delete payload.replaceTemplate;
    delete payload.id;

    const updateTemplates = state.bookletModuleTemplates.map((template) => (template.id === payload.template ? { ...template, ...payload } : template));

    return {
        ...state,
        isLoading: false,
        bookletModuleTemplates: updateTemplates
    };
};

const reorderContentItems = (state = INITIAL_STATE, action: any) => {
    const { type, items } = action.payload;

    const updateContentType = state.currentModule.contentPerType.map((contentPerType) => {
        if (contentPerType.type === type) {
            return {
                ...contentPerType,
                items: items.map((item: IContentPerTypeItem, index: number) => ({ ...item, order: index + 1 }))
            };
        }

        return contentPerType;
    });

    return {
        ...state,
        isLoading: false,
        currentModule: {
            ...state.currentModule,
            contentPerType: updateContentType
        }
    };
};

const editCoursePlaylist = (state = INITIAL_STATE, action: any) => {
    const { payload } = action;

    const updateContentType = state.currentModule.contentPerType.map((contentPerType) => {
        if (contentPerType.type !== 'Playlist') {
            return contentPerType;
        }

        const hasThisPlaylist = contentPerType.items.some((item) => item?.learningObject?.id === payload?.learningObject?.id);

        return {
            ...contentPerType,
            items: hasThisPlaylist ? contentPerType.items.map((item) => (item?.learningObject?.id === payload?.learningObject?.id ? payload : item)) : [...contentPerType.items, payload]
        };
    });

    return {
        ...state,
        currentModule: {
            ...state.currentModule,
            contentPerType: updateContentType
        }
    };
};

const editContentItem = (state = INITIAL_STATE, action: any) => {
    const { content, type } = action.payload;

    const updateCurrentModule = state.currentModule.contentPerType.map((item) => {
        if (item.type.toLowerCase() === type) {
            return {
                ...item,
                items: item.items
                    .filter((_item: any) => !!_item.learningObject)
                    .map((_item) => {
                        if (!!_item.learningObject[type] && _item.learningObject[type].id === content.id) {
                            return {
                                ..._item,
                                learningObject: {
                                    ..._item.learningObject,
                                    [type]: content
                                }
                            };
                        }

                        return _item;
                    })
            };
        }

        return item;
    });

    return {
        ...state,
        isLoading: false,
        currentModule: {
            ...state.currentModule,
            contentPerType: updateCurrentModule
        }
    };
};

// TODO: COLOCAR ACTIONS GENERICAS EM TUDO
// GENERIC ACTIONS
const courseRequest = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoading: true
});

const courseRequestSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    ...action.payload,
    isLoading: false
});

const courseRequestFailure = (state = INITIAL_STATE) => ({
    ...state,
    isLoading: false
});

export default createReducer(INITIAL_STATE, {
    // TEMPLATE
    [Types.SAVE_BOOKLET_MODULE_TEMPLATE_REQUEST]: courseRequest as any,
    [Types.SAVE_BOOKLET_MODULE_TEMPLATE_SUCCESS]: saveTemplateModuleSuccess,
    [Types.SAVE_BOOKLET_MODULE_TEMPLATE_FAILURE]: courseRequestFailure,

    [Types.GET_BOOKLET_MODULE_TEMPLATES_REQUEST]: courseRequest,
    [Types.GET_BOOKLET_MODULE_TEMPLATES_SUCCESS]: getBookletModuleTemplates,
    [Types.GET_BOOKLET_MODULE_TEMPLATES_FAILURE]: courseRequestFailure,

    // Course CRUD
    [Types.GET_COURSE_BY_ID_REQUEST]: courseRequest,
    [Types.GET_COURSE_BY_ID_SUCCESS]: courseRequestSuccess,
    [Types.GET_COURSE_BY_ID_FAILURE]: courseRequestFailure,

    [Types.CREATE_OR_EDIT_COURSE_REQUEST]: createOrEditCourseRequest,
    [Types.CREATE_OR_EDIT_COURSE_SUCCESS]: createOrEditCourseSuccess,
    [Types.CREATE_OR_EDIT_COURSE_FAILURE]: createOrEditCourseFailure,

    // Modules
    [Types.CREATE_MODULE_REQUEST]: createModuleRequest,
    [Types.CREATE_MODULE_SUCCESS]: createModuleSuccess,
    [Types.CREATE_MODULE_FAILURE]: createModuleFailure,

    [Types.DELETE_MODULE_REQUEST]: deleteModuleRequest,
    [Types.DELETE_MODULE_SUCCESS]: deleteModuleSuccess,
    [Types.DELETE_MODULE_FAILURE]: deleteModuleFailure,

    [Types.GET_MODULES_BY_SUBJECT_REQUEST]: getModulesBySubjectRequest,
    [Types.GET_MODULES_BY_SUBJECT_SUCCESS]: getModulesBySubjectSuccess,
    [Types.GET_MODULES_BY_SUBJECT_FAILURE]: getModulesBySubjectFailure,

    [Types.CREATE_MODULE_AND_TEMPLATE_REQUEST]: createModuleAndTemplateRequest,
    [Types.CREATE_MODULE_AND_TEMPLATE_SUCCESS]: createModuleAndTemplateSuccess,
    [Types.CREATE_MODULE_AND_TEMPLATE_FAILURE]: createModuleAndTemplateFailure,

    [Types.EDIT_COURSE_MODULE_REQUEST]: editCourseModuleRequest,
    [Types.EDIT_COURSE_MODULE_SUCCESS]: editCourseModuleSuccess,
    [Types.EDIT_COURSE_MODULE_FAILURE]: editCourseModuleFailure,

    // Booklet Item

    [Types.CREATE_BOOKLET_CONTENT_ITEM_REQUEST]: createBookletContentItemRequest,
    [Types.CREATE_BOOKLET_CONTENT_ITEM_SUCCESS]: createBookletContentItemSuccess,
    [Types.CREATE_BOOKLET_CONTENT_ITEM_FAILURE]: createBookletContentItemFailure,

    [Types.DELETE_BOOKLET_CONTENT_ITEM_REQUEST]: deleteBookletContentItemRequest,
    [Types.DELETE_BOOKLET_CONTENT_ITEM_SUCCESS]: deleteBookletContentItemSuccess,
    [Types.DELETE_BOOKLET_CONTENT_ITEM_FAILURE]: deleteBookletContentItemFailure,

    [Types.ASSOCIATE_QR_CODE_TO_MODULE_REQUEST]: associateQrCodeToModuleRequest,
    [Types.ASSOCIATE_QR_CODE_TO_MODULE_SUCCESS]: associateQrCodeToModuleSuccess,
    [Types.ASSOCIATE_QR_CODE_TO_MODULE_FAILURE]: associateQrCodeToModuleFailure,

    [Types.ASSOCIATE_QR_CODE_TO_BOOKLET_CONTENT_ITEM_REQUEST]: associateQrCodeToBookletContentItemRequest,
    [Types.ASSOCIATE_QR_CODE_TO_BOOKLET_CONTENT_ITEM_SUCCESS]: associateQrCodeToBookletContentItemSuccess,
    [Types.ASSOCIATE_QR_CODE_TO_BOOKLET_CONTENT_ITEM_FAILURE]: associateQrCodeToBookletContentItemFailure,

    [Types.DISASSOCIATE_QR_CODE_TO_BOOKLET_CONTENT_ITEM_REQUEST]: disassociateQrCodeToBookletContentItemRequest,
    [Types.DISASSOCIATE_QR_CODE_TO_BOOKLET_CONTENT_ITEM_SUCCESS]: disassociateQrCodeToBookletContentItemSuccess,
    [Types.DISASSOCIATE_QR_CODE_TO_BOOKLET_CONTENT_ITEM_FAILURE]: disassociateQrCodeToBookletContentItemFailure,

    [Types.SET_CURRENT_MODULE]: setCurrentModule,

    [Types.CLEAR_TEMPLATES_AND_MODULES]: clearTemplatesAndModules,

    [Types.REORDER_COURSE_ITEMS_REQUEST]: courseRequest,
    [Types.REORDER_COURSE_ITEMS_SUCCESS]: courseRequestSuccess,
    [Types.REORDER_COURSE_ITEMS_FAILURE]: courseRequestFailure,

    [Types.REORDER_COURSE_CONTENT_ITEMS_REQUEST]: courseRequest,
    [Types.REORDER_COURSE_CONTENT_ITEMS_SUCCESS]: reorderContentItems,
    [Types.REORDER_COURSE_CONTENT_ITEMS_FAILURE]: courseRequestFailure,

    [Types.EDIT_COURSE_CONTENT_ITEM_REQUEST]: courseRequest,
    [Types.EDIT_COURSE_CONTENT_ITEM_SUCCESS]: editContentItem,
    [Types.EDIT_COURSE_CONTENT_ITEM_FAILURE]: courseRequestFailure,

    [Types.EDIT_COURSE_PLAYLIST]: editCoursePlaylist,

    [Types.CLEAR_COURSE]: clearCourse
});
