const contentPerType = `
    id
    name: title
    subject_front {
        title
    }
`;

const relations = {
    getCourses: `query getCoursesByBrand($id: Int!) {
        courses: prodigio_courses(where: {company: {brands: {id: {_eq: $id}}}, deleted: {_is_null: true}}, order_by: {name: asc}) {
          value: id
          label: name
        }
    }`,
    getCourseSubjects: `query getCourseSubjects($id: Int!) {
        subjects: prodigio_subjects(where: {booklet_modules: {id_course: {_eq: $id}}, deleted: {_is_null: true}}) {
            id
            color
            name
            subject_fronts(where: {deleted: {_is_null: true}}) {
                id
                title
            }
            booklet_modules(where: {deleted: {_is_null: true}}) {
                id
            }
        }
    }`,
    getSubjectModules: `query getSubjectModules($id: Int!, $idCourse: Int!) {
        modules: prodigio_booklet_modules(where: {deleted: {_is_null: true}, id_subject: {_eq: $id}, id_course: {_eq: $idCourse}}) {
            ${contentPerType}
            Video: booklet_content_items_aggregate(where: {learning_object: {type: {_eq: "Video"}, deleted: {_is_null: true}, video: {deleted: {_is_null: true}}}, deleted: {_is_null: true}}) {
                aggregate {
                    count
                }
            }
            ExerciseList: booklet_content_items_aggregate(where: {learning_object: {type: {_eq: "ExerciseList"}, deleted: {_is_null: true}, exerciseList: {deleted: {_is_null: true}}}, deleted: {_is_null: true}}) {
                aggregate {
                    count
                }
            }
            Material: booklet_content_items_aggregate(where: {learning_object: {type: {_eq: "Material"}, deleted: {_is_null: true}, material: {deleted: {_is_null: true}, type: {_eq: 0}}}, deleted: {_is_null: true}}) {
                aggregate {
                    count
                }
            }
            Apostila: booklet_content_items_aggregate(where: {learning_object: {type: {_eq: "Material"}, deleted: {_is_null: true}, material: {deleted: {_is_null: true}, type: {_eq: 2}}}, deleted: {_is_null: true}}) {
                aggregate {
                    count
                }
            }
        }
    }`,
    getCurrentModulesOnLessonPlan: `query getCurrentModulesOnLessonPlan($id: Int!) {
  prodigio_booklet_modules(where: {lesson_plan_module_educational_items: {lesson_plan_modules_educational: {id_lesson_plan_educational: {_eq: $id}, deleted: {_is_null: true}}, deleted: {_is_null: true}}, deleted: {_is_null: true}}) {
    id
  }
}

`
};

const lessonPlan = {
    getLessonPlan: `query getLessonPlanEducationalByPk($id: Int!) {
        lessonPlan: prodigio_lesson_plans_educational_by_pk(id: $id) {
            id
            name
            is_published
            course {
                value: id
                label: name
                slug
            }
        }
    }`,
    getLessonPlanModules: `query getLessonPlanModules($id: Int!) {
        modules: prodigio_lesson_plan_modules_educational(where: {id_lesson_plan_educational: {_eq: $id}, deleted: {_is_null: true}}, order_by: {order: asc}) {
            id
            name
            slug
            order
        }
    }`,
    getLessonPlanModuleByPk: `query getLessonPlanModuleByPk($id: Int!) {
        module: prodigio_lesson_plan_modules_educational_by_pk(id: $id) {
            id
            name
            order
            lesson_plan_module_educational_items(where: {deleted: {_is_null: true}}, order_by: {order: asc}) {
                id
                order
                booklet_module {
                    ${contentPerType}
                    subject {
                        id
                        name
                    }
                }
            }
        }
    }`
};

export { relations, lessonPlan };
