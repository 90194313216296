import styled, { css } from 'styled-components/macro';
import theme from 'config/theme';
import { TemplateItemHeader, TemplateContentItem } from 'components/template/styles';
import { EmptyContainer } from 'components/empty/styles';
import { SkeletonItem } from 'components/skeleton/styles';
import { ButtonContainer } from 'components/button/styles';
import { SlideInBottom } from 'assets/animations';
import { InputWrapper, InputElement } from 'components/forms/styles';

export const ListContentContainer = styled.div`
    width: 100%;

    ${TemplateItemHeader} {
        > div {
            > svg {
                fill: ${theme.colors.base};
            }
        }
    }

    ${EmptyContainer} {
        padding: ${theme.spacing.medium.all};
        align-items: flex-start;
    }
`;

export const ListContentItemHeader = styled(TemplateContentItem)<{ isActive: boolean; isCanceled?: boolean }>`
    background: #f9fafa;
    cursor: pointer;
    flex: 1;
    flex-wrap: wrap;
    position: relative;
    transition: all 0.2s ease;
    z-index: 2;

    ${({ isCanceled }) =>
        isCanceled &&
        css`
            opacity: 0.6;

            :hover {
                opacity: 1;
            }
        `}
`;

export const ListContentItemBody = styled.div`
    animation: ${SlideInBottom} 0.2s 0s both;
    background: ${theme.colors.white};
    padding: 10px;
    position: relative;
    z-index: 0;

    ${InputWrapper} {
        width: 60px;

        ${InputElement} {
            padding: 5px;
        }
    }
`;

export const CompactListContainer = styled.div`
    width: 100%;

    ${TemplateItemHeader} {
        border: none !important;
        border-color: #ccc;
        padding: 0 1px;
        margin-bottom: ${theme.spacing.large.all};
        padding-bottom: 0px;

        > div {
            &:nth-child(2) {
                flex: 1 0 auto;
                max-width: 25%;
            }
        }
    }
`;

export const CompactListHeaderWrapper = styled.div`
    align-items: center;
    display: flex;

    ${ButtonContainer} {
        margin-left: ${theme.spacing.small.all};

        svg {
            margin: 0;
        }
    }
`;

export const CompactListContent = styled.div`
    ${SkeletonItem} {
        &:not(:last-child) {
            margin-bottom: ${theme.spacing.medium.all};
        }
    }
`;

export const CompactListText = styled.p`
    flex: 1;
    font-size: 14px;
    margin-right: ${theme.spacing.smallX.all};

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const ListContentItemWrapper = styled.div`
    flex: 1;
    margin-right: 25px;
    overflow: hidden;
    width: calc(100% - 110px);
`;

export const QuestionBadge = styled.span`
    border-radius: 3px;
    color: ${theme.colors.primary};
    font-family: ${theme.typography.family.primary.bold.italic};
    font-size: 12px;
    line-height: 1;
    text-transform: uppercase;
`;

export const ActionsWrapper = styled.div`
    align-items: center;
    display: flex;

    ${ButtonContainer} {
        padding: 7px;
    }
`;
