import { createActions, createReducer } from 'reduxsauce';
import { IApplymentWindowStudent } from 'utils/interfaces/IApplymentWindow';
import { IExam } from 'utils/interfaces/IExam';
import { IReduxAction } from 'store/types/IRedux';

export const { Creators, Types } = createActions({
    getExamRequest: ['payload'],
    getExamSuccess: ['payload'],
    getExamFailure: ['payload'],

    getExamDayRequest: ['payload'],
    getExamDaySuccess: ['payload'],
    getExamDayFailure: [],

    createOrEditExamRequest: ['payload'],
    createOrEditExamSuccess: ['payload'],
    createOrEditExamFailure: ['payload'],

    createExamDayRequest: ['payload'],
    createExamDayFailure: ['payload'],

    deleteExamEntityRequest: ['payload'],
    deleteExamEntityFailure: [],

    updateExamDayRequest: ['payload'],
    updateExamDayFailure: [],

    updateOrCreateKnowledgeAreaRequest: ['payload'],
    updateOrCreateKnowledgeAreaFailure: [],

    updateOrCreateQuestionsRequest: ['payload'],
    updateOrCreateQuestionsFailure: [],

    getExamTestersRequest: ['payload'],
    getExamTestersSuccess: ['payload'],
    getExamTestersFailure: [],

    updateTesterRequest: ['payload'],
    updateTesterSuccess: ['payload'],
    updateTesterFailure: [],

    getExamCompaniesRequest: ['payload'],
    getExamCompaniesSuccess: ['payload'],
    getExamCompaniesFailure: [],

    deleteExamCompanyRequest: ['payload'],
    deleteExamCompanySuccess: ['payload'],
    deleteExamCompanyFailure: [],

    updateCompanyRequest: ['payload'],
    updateCompanySuccess: ['payload'],
    updateCompanyFailure: [],

    getExamCoursesRequest: ['payload'],
    getExamCoursesSuccess: ['payload'],
    getExamCoursesFailure: [],

    createExamCourseRequest: ['payload'],
    createExamCourseSuccess: ['payload'],
    createExamCourseFailure: [],

    deleteExamCourseRequest: ['payload'],
    deleteExamCourseSuccess: ['payload'],
    deleteExamCourseFailure: [],

    createOrEditExamCorrectionEngineRequest: ['payload'],
    createOrEditExamCorrectionEngineSuccess: ['payload'],
    createOrEditExamCorrectionEngineFailure: [],

    deleteExamCorrectionEngineRequest: ['payload'],
    deleteExamCorrectionEngineSuccess: ['payload'],
    deleteExamCorrectionEngineFailure: [],

    deleteExamResolutionRequest: ['payload'],
    deleteExamResolutionSuccess: ['payload'],
    deleteExamResolutionFailure: [],

    getExamApplymentWindowStudentsRequest: ['payload'],
    getExamApplymentWindowStudentsSuccess: ['payload'],
    getExamApplymentWindowStudentsFailure: [],

    createExamApplymentWindowRequest: ['payload'],
    createExamApplymentWindowSuccess: ['payload'],
    createExamApplymentWindowFailure: [],

    deleteExamApplymentWindowRequest: ['payload'],
    deleteExamApplymentWindowSuccess: ['payload'],
    deleteExamApplymentWindowFailure: [],

    deleteExamApplymentWindowStudentRequest: ['payload'],
    deleteExamApplymentWindowStudentSuccess: ['payload'],
    deleteExamApplymentWindowStudentFailure: [],

    clearExamApplymentWindowStudent: [],

    discardExamImportRequest: ['payload'],
    discardExamImportSuccess: [],
    discardExamImportFailure: [],

    clearExam: []
});

export type IExamsState = IExam & {
    isLoading: boolean;
    isLoadingApplymentWindow: boolean;
    applymentWindowStudents: IApplymentWindowStudent[];
    totalApplymentWindowStudents?: number;
    isLoadingDiscardImport: boolean;
};

const INITIAL_STATE: IExamsState = {
    examDays: [],
    id: 0,
    isAnswerReleased: false,
    isAvailable: false,
    name: '',
    points: '',
    slug: '',
    showRanking: false,
    isLoading: false,
    type: 0,
    year: 0,
    isPublished: false,
    isPaid: false,
    hasResolutions: false,

    // ApplymentWindow
    isLoadingApplymentWindow: false,
    applymentWindowStudents: [],

    // import exam
    isLoadingDiscardImport: false,

    // RELATED CONTENTS:
    testers: [],
    courses: [],
    companies: [],
    correctionEngines: []
};

// GET ONE EXAM
const getExamRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true });

const getExamSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    ...action.payload,
    isLoading: false
});

const getExamFailure = (state = INITIAL_STATE) => ({ ...state, isLoading: false });

// GET ONE EXAM DAY
const getExamDayRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true });

const getExamDaySuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    examDays: state.examDays.map((item) => {
        if (item.id === action.payload.id) {
            return action.payload;
        }

        return item;
    }),
    isLoading: false
});

const getExamDayFailure = (state = INITIAL_STATE) => ({ ...state, isLoading: false });

// CREATE OR EDIT EXAM
const createOrEditExamRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true });

const createOrEditExamSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    ...action.payload,
    isLoading: false
});

const createOrEditExamFailure = (state = INITIAL_STATE) => ({ ...state, isLoading: false });

// testers

const getTestersSuccess = (state = INITIAL_STATE, action: any) => ({ ...state, isLoading: false, testers: action.payload });

// COMPANIES

const getCompaniesSuccess = (state = INITIAL_STATE, action: any) => ({ ...state, isLoading: false, companies: action.payload });

const updateCompaniesSuccess = (state = INITIAL_STATE, action: any) => ({ ...state, isLoading: false, companies: [action.payload, ...state.companies] });

const deleteCompanySuccess = (state = INITIAL_STATE, action: any) => ({ ...state, isLoading: false, companies: state.companies?.filter((item) => item.id !== action.payload.id) });

// COURSES

const getCoursesSuccess = (state = INITIAL_STATE, action: any) => ({ ...state, isLoading: false, courses: action.payload });

const deleteCoursesSuccess = (state = INITIAL_STATE, action: any) => {
    const { id } = action.payload;

    return { ...state, isLoading: false, courses: state.courses.filter((item) => item.id !== id) };
};

const createCourseSuccess = (state = INITIAL_STATE, action: any) => {
    return { ...state, isLoading: false, courses: [...state.courses, action.payload] };
};

const createOrEditCorrectionEngine = (state = INITIAL_STATE, action: any) => {
    return { ...state, isLoading: false, correctionEngines: [action.payload, ...state.correctionEngines] };
};

// CLEAR CURRENT EXAM
const clearExam = (state = INITIAL_STATE) => ({ ...state, ...INITIAL_STATE });

// APPLYMENT WINDOW

const requestApplymentWindow = (state = INITIAL_STATE, _: any) => ({ ...state, isLoadingApplymentWindow: true });
const failureApplymentWindow = (state = INITIAL_STATE) => ({ ...state, isLoadingApplymentWindow: false });

const getApplymentWindowStudents = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoadingApplymentWindow: false,
    applymentWindowStudents: action.payload?.students || [],
    totalApplymentWindowStudents: action.payload?.total
});

const createApplymentWindow = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoadingApplymentWindow: false,
    applymentWindowStudents: [],
    totalApplymentWindowStudents: 0
});

const clearExamApplymentWindowStudent = (state = INITIAL_STATE) => ({ ...state, isLoadingApplymentWindow: false, applymentWindowStudents: [], totalApplymentWindowStudents: 0 });

const startDiscardImportLoading = (state = INITIAL_STATE, action: IReduxAction<{ id: number }>) => ({ ...state, isLoadingDiscardImport: true });
const stopDiscardImportLoading = (state = INITIAL_STATE, action: IReduxAction<{ id: number }>) => ({ ...state, isLoadingDiscardImport: false });

export default createReducer(INITIAL_STATE, {
    [Types.GET_EXAM_REQUEST]: getExamRequest,
    [Types.GET_EXAM_SUCCESS]: getExamSuccess,
    [Types.GET_EXAM_FAILURE]: getExamFailure,

    [Types.GET_EXAM_DAY_REQUEST]: getExamDayRequest,
    [Types.GET_EXAM_DAY_SUCCESS]: getExamDaySuccess,
    [Types.GET_EXAM_DAY_FAILURE]: getExamDayFailure,

    [Types.CREATE_OR_EDIT_EXAM_REQUEST]: createOrEditExamRequest,
    [Types.CREATE_OR_EDIT_EXAM_SUCCESS]: createOrEditExamSuccess,
    [Types.CREATE_OR_EDIT_EXAM_FAILURE]: createOrEditExamFailure,

    [Types.CREATE_EXAM_DAY_REQUEST]: getExamRequest,
    [Types.CREATE_EXAM_DAY_FAILURE]: getExamFailure,

    [Types.DELETE_EXAM_ENTITY_REQUEST]: getExamRequest,
    [Types.DELETE_EXAM_ENTITY_FAILURE]: getExamFailure,

    [Types.UPDATE_EXAM_DAY_REQUEST]: getExamRequest,
    [Types.UPDATE_EXAM_DAY_FAILURE]: getExamFailure,

    [Types.UPDATE_OR_CREATE_KNOWLEDGE_AREA_REQUEST]: getExamRequest,
    [Types.UPDATE_OR_CREATE_KNOWLEDGE_AREA_FAILURE]: getExamFailure,

    [Types.UPDATE_OR_CREATE_QUESTIONS_REQUEST]: getExamRequest,
    [Types.UPDATE_OR_CREATE_QUESTIONS_FAILURE]: getExamFailure,

    [Types.GET_EXAM_TESTERS_REQUEST]: getExamRequest,
    [Types.GET_EXAM_TESTERS_SUCCESS]: getTestersSuccess,
    [Types.GET_EXAM_TESTERS_FAILURE]: getExamFailure,

    [Types.UPDATE_TESTER_REQUEST]: getExamRequest,
    [Types.UPDATE_TESTER_SUCCESS]: getTestersSuccess,
    [Types.UPDATE_TESTER_FAILURE]: getExamFailure,

    // COMPANIES

    [Types.GET_EXAM_COMPANIES_REQUEST]: getExamRequest,
    [Types.GET_EXAM_COMPANIES_SUCCESS]: getCompaniesSuccess,
    [Types.GET_EXAM_COMPANIES_FAILURE]: getExamFailure,

    [Types.UPDATE_COMPANY_REQUEST]: getExamRequest,
    [Types.UPDATE_COMPANY_SUCCESS]: updateCompaniesSuccess,
    [Types.UPDATE_COMPANY_FAILURE]: getExamFailure,

    [Types.DELETE_EXAM_COMPANY_REQUEST]: getExamRequest,
    [Types.DELETE_EXAM_COMPANY_SUCCESS]: deleteCompanySuccess,
    [Types.DELETE_EXAM_COMPANY_FAILURE]: getExamFailure,

    // COURSES

    [Types.GET_EXAM_COURSES_REQUEST]: getExamRequest,
    [Types.GET_EXAM_COURSES_SUCCESS]: getCoursesSuccess,
    [Types.GET_EXAM_COURSES_FAILURE]: getExamFailure,

    [Types.DELETE_EXAM_COURSE_REQUEST]: getExamRequest,
    [Types.DELETE_EXAM_COURSE_SUCCESS]: deleteCoursesSuccess,
    [Types.DELETE_EXAM_COURSE_FAILURE]: getExamFailure,

    [Types.CREATE_EXAM_COURSE_REQUEST]: getExamRequest,
    [Types.CREATE_EXAM_COURSE_SUCCESS]: createCourseSuccess,
    [Types.CREATE_EXAM_COURSE_FAILURE]: getExamFailure,

    // CORRECTION ENGINE

    [Types.CREATE_OR_EDIT_EXAM_CORRECTION_ENGINE_REQUEST]: getExamRequest,
    [Types.CREATE_OR_EDIT_EXAM_CORRECTION_ENGINE_SUCCESS]: createOrEditCorrectionEngine,
    [Types.CREATE_OR_EDIT_EXAM_CORRECTION_ENGINE_FAILURE]: getExamDayFailure,

    [Types.DELETE_EXAM_CORRECTION_ENGINE_REQUEST]: getExamRequest,
    [Types.DELETE_EXAM_CORRECTION_ENGINE_SUCCESS]: getExamDayFailure,
    [Types.DELETE_EXAM_CORRECTION_ENGINE_FAILURE]: getExamDayFailure,

    [Types.CLEAR_EXAM]: clearExam,

    // RESOLUTIONS

    [Types.DELETE_EXAM_RESOLUTION_REQUEST]: getExamRequest,
    [Types.DELETE_EXAM_RESOLUTION_SUCCESS]: getExamFailure,
    [Types.DELETE_EXAM_RESOLUTION_FAILURE]: getExamFailure,

    // Applyment Window

    [Types.GET_EXAM_APPLYMENT_WINDOW_STUDENTS_REQUEST]: requestApplymentWindow,
    [Types.GET_EXAM_APPLYMENT_WINDOW_STUDENTS_SUCCESS]: getApplymentWindowStudents,
    [Types.GET_EXAM_APPLYMENT_WINDOW_STUDENTS_FAILURE]: failureApplymentWindow,

    [Types.CREATE_EXAM_APPLYMENT_WINDOW_REQUEST]: requestApplymentWindow,
    [Types.CREATE_EXAM_APPLYMENT_WINDOW_SUCCESS]: createApplymentWindow,
    [Types.CREATE_EXAM_APPLYMENT_WINDOW_FAILURE]: failureApplymentWindow,

    [Types.DELETE_EXAM_APPLYMENT_WINDOW_REQUEST]: getExamRequest,
    [Types.DELETE_EXAM_APPLYMENT_WINDOW_SUCCESS]: getExamFailure,
    [Types.DELETE_EXAM_APPLYMENT_WINDOW_FAILURE]: getExamFailure,

    [Types.DELETE_EXAM_APPLYMENT_WINDOW_STUDENT_REQUEST]: getExamRequest,
    [Types.DELETE_EXAM_APPLYMENT_WINDOW_STUDENT_SUCCESS]: getExamFailure,
    [Types.DELETE_EXAM_APPLYMENT_WINDOW_STUDENT_FAILURE]: getExamFailure,

    [Types.CLEAR_EXAM_APPLYMENT_WINDOW_STUDENT]: clearExamApplymentWindowStudent,

    // EXAM IMPORT
    [Types.DISCARD_EXAM_IMPORT_REQUEST]: startDiscardImportLoading,
    [Types.DISCARD_EXAM_IMPORT_SUCCESS]: stopDiscardImportLoading,
    [Types.DISCARD_EXAM_IMPORT_FAILURE]: stopDiscardImportLoading
});
