import React, { useCallback } from 'react';

import Avatar from 'components/avatar';
import { TableItemPersonContainer, TableItemPersonTexts } from './styles';
import history from 'services/history';

interface IProps {
    name: string;
    imageProfile?: string;
    img_profile?: string;
    user?: {
        id?: number;
        email: string;
    };
}

const TableItemPerson: React.FC<IProps> = ({ name = '-', imageProfile, user, img_profile }) => {
    const handleClick = useCallback(() => {
        history.push(`/app/usuarios/editar/${user?.id}`);
    }, [user]);

    const img = imageProfile ?? img_profile;

    return (
        <TableItemPersonContainer
            {...(!!user?.id && {
                onClick: handleClick,
                style: { cursor: 'pointer' }
            })}
        >
            <Avatar tooltip={true} avatarPath={img} name={name} size="small" />

            <TableItemPersonTexts>
                <p>{name}</p>
                {!!user?.email && <small>{user?.email}</small>}
            </TableItemPersonTexts>
        </TableItemPersonContainer>
    );
};

export default TableItemPerson;
