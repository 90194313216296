export const createSellerAddress = `
mutation (
    $data: [prodigio_address_insert_input!]!
) {
    item:insert_prodigio_address(
        objects: $data
    ) {
        returning {
            id
        }
        affected_rows
    }
}
`;
