// Redux
import { put, call, select, takeLatest } from 'redux-saga/effects';
import { Creators as alertActions } from 'store/ducks/alert';
import { Creators as storeActions } from 'store/ducks/store';
import { Types as StoreTypes } from 'store/ducks/store';
import { Creators as modalActions } from 'store/ducks/modal';
import { Creators as crudActions } from 'store/ducks/crud';

import history from 'services/history';

import { IReduxStore } from 'utils/interfaces/IReduxStore';
import { IGraphqlRequestAction } from 'utils/interfaces/IRequestAction';

import { graphQLRequest } from '../graphQLRequest';
import { IPaginationCore } from 'utils/interfaces/IPagination';
import { checkGraphQLConflict } from 'utils/checkGraphQLConflictQuery';
import { graphQLClient } from 'services/graphqlClient';
import { getOrderByPk } from 'utils/queries/store/orderByPk';
import { apiRequest } from '../apiRequest';

function* getStoreById(action: IGraphqlRequestAction<any>) {
    try {
        const { query, params } = action.payload;

        const { data } = yield graphQLRequest(query, params);

        yield put(storeActions.getStoreSuccess(data.item));
    } catch (error) {
        console.log('error', error);
        yield put(storeActions.getStoreFailure());
        yield put(alertActions.showAlert('Ocorreu um erro. Tente novamente mais tarde.', 'danger'));
        yield call(history.push, { pathname: `/app/lojas` });
    }
}

function* createOrEditStore(action: IGraphqlRequestAction<any>) {
    try {
        const companyId: number | undefined = yield select((state: IReduxStore) => state.organizations.currentCompany?.id);

        if (!companyId) {
            throw new Error();
        }

        const { query, params } = action.payload;

        const storeData = {
            data: {
                // Criar ou atualizar
                ...params,
                commission_rule: 1,

                // Apenas criar
                ...(!params.id && { id_company: companyId })
            },
            ...(params.id && { id: params.id })
        };

        const { data, errors } = yield graphQLRequest(query, storeData);

        if (checkGraphQLConflict(errors)) {
            throw new Error('Esse slug já está sendo usado.');
        }

        if (!data?.item) {
            throw new Error();
        }

        yield put(storeActions.createOrEditStoreSuccess(data.item));

        if (!params.id) {
            yield call(history.push, { pathname: `/app/lojas/ver/${data.item.id}` });
        }

        yield put(alertActions.showAlert('Registro salvo com sucesso.', 'success'));
    } catch (error) {
        yield put(storeActions.createOrEditStoreFailure());
        yield put(alertActions.showAlert(error.message || 'Ocorreu um erro. Tente novamente mais tarde.', 'danger'));
    }
}

function* getAllEntity(action: IGraphqlRequestAction<any>) {
    try {
        const { query, params } = action.payload;

        const { data } = yield graphQLRequest(query, params);

        if (!data) {
            throw new Error();
        }

        const items = data.items || [];

        const count = data.quantity?.aggregate?.count || 0;

        const { offset = 0, limit = 10 } = params;

        const formatPagination: IPaginationCore = {
            page: (limit + offset) / limit,
            totalItems: count,
            count: items.length,
            totalPages: Math.ceil(count / limit)
        };

        const response = {
            items,
            pagination: formatPagination
        };

        yield put(storeActions.getAllStoreEntitySuccess(response));
    } catch (error) {
        yield put(storeActions.getAllStoreEntityFailure());
        yield put(alertActions.showAlert(error.message || 'Ocorreu um erro. Tente novamente mais tarde.', 'danger'));
    }
}

function* createOrEditEntity(action: IGraphqlRequestAction<any>) {
    try {
        const { query, params } = action.payload;

        const { data, errors } = yield graphQLRequest(query, params);

        if (checkGraphQLConflict(errors)) {
            throw new Error('Esse slug já está sendo usado.');
        }

        if (!data?.item) {
            throw new Error();
        }

        const formatResponse = {
            ...data.item
        };

        if (!!data.address) {
            formatResponse.address = data.address;
        }

        yield put(modalActions.closeModal());

        console.log('formatResponse', formatResponse);

        yield put(storeActions.createOrEditStoreEntitySuccess(formatResponse));

        yield put(crudActions.createOrEditSuccess(formatResponse));

        yield put(alertActions.showAlert('Registro salvo com sucesso!', 'success'));
    } catch (error) {
        console.log('error', error);

        yield put(storeActions.createOrEditStoreEntityFailure());

        yield put(alertActions.showAlert(error.message || 'Ocorreu um erro. Tente novamente mais tarde.', 'danger'));
    }
}

function* deleteEntity(action: IGraphqlRequestAction<any>) {
    try {
        const { query, params } = action.payload;

        const { data } = yield graphQLRequest(query, params);

        if (!data?.item) {
            throw new Error();
        }

        yield put(modalActions.closeModal());

        yield put(storeActions.deleteStoreEntitySuccess({ id: params.id }));

        yield put(alertActions.showAlert('Registro excluído com sucesso!', 'success'));
    } catch (error) {
        yield put(storeActions.deleteStoreEntityFailure());

        yield put(alertActions.showAlert(error.message || 'Ocorreu um erro. Tente novamente mais tarde.', 'danger'));
    }
}

function* getOrder(action: any) {
    try {
        const { data } = yield graphQLClient(getOrderByPk, { id: action.payload.id });

        yield put(storeActions.getStoreOrderSuccess(data.order));
    } catch (error) {
        yield put(storeActions.getStoreOrderFailure());
        yield put(alertActions.showAlert(error.message || 'Ocorreu um erro. Tente novamente mais tarde.', 'danger'));
    }
}

function* refund(action: any) {
    try {
        yield apiRequest('POST', `/admin/checkout/${action.payload.id}/refund`, {});

        yield put({ type: 'ORDER_REFUND_SUCCESS' });
        yield put(alertActions.showAlert('Pedido estornado com sucesso', 'success'));
    } catch (error) {
        yield put(alertActions.showAlert(error.message || 'Ocorreu um erro. Tente novamente mais tarde.', 'danger'));
        yield put({ type: 'ORDER_REFUND_FAILURE' });
    }
}

export default [
    takeLatest(StoreTypes.GET_STORE_REQUEST, getStoreById),
    takeLatest(StoreTypes.CREATE_OR_EDIT_STORE_REQUEST, createOrEditStore),
    takeLatest(StoreTypes.GET_ALL_STORE_ENTITY_REQUEST, getAllEntity),
    takeLatest(StoreTypes.CREATE_OR_EDIT_STORE_ENTITY_REQUEST, createOrEditEntity),
    takeLatest(StoreTypes.DELETE_STORE_ENTITY_REQUEST, deleteEntity),
    takeLatest(StoreTypes.GET_STORE_ORDER_REQUEST, getOrder),
    takeLatest(StoreTypes.ORDER_REFUND_REQUEST, refund)
];
