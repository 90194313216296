import React from 'react';

// Assets
import { TooltipContainer } from './styles';

export type TooltipDirection = 'left' | 'top' | 'right' | 'bottom';

interface IProps {
    content: string;
    direction?: TooltipDirection;
    visible?: boolean;
    style?: React.CSSProperties;
}

const Tooltip = ({ content, direction = 'top', visible = false, style }: IProps) => (
    <TooltipContainer isVisible={visible} direction={direction} style={style || {}}>
        {content}
    </TooltipContainer>
);

export default Tooltip;
