import React, { memo } from 'react';
import styled, { CSSProperties, css } from 'styled-components';
import { rotate, dash } from 'assets/animations';

interface IProps {
    size?: string | number;
    color?: string;
    style?: CSSProperties;
    center?: boolean;
}

export const SpinnerElement = styled.svg<{ color: string; center?: boolean }>`
    animation: ${rotate} 0.8s linear infinite;

    .path {
        stroke: ${({ color }) => color};
        stroke-linecap: round;
        animation: ${dash} 1.5s ease-in-out infinite;
    }

    ${({ center }) =>
        center &&
        css`
            margin: 0 auto;
            display: block;
        `};
`;

const Spinner = memo(({ size = 25, color = '#fff', ...rest }: IProps) => {
    return (
        <SpinnerElement className="spinner" viewBox="0 0 50 50" width={size} height={size} color={color} {...rest}>
            <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
        </SpinnerElement>
    );
});

export default Spinner;
