// Redux
import { put, call, select } from 'redux-saga/effects';
import { Creators as alertActions } from 'store/ducks/alert';
import { Creators as sellerActions } from 'store/ducks/seller';

// Services
import { graphQLClient } from 'services/graphqlClient';
import history from 'services/history';

// Helpers
import { IGraphqlRequestAction } from 'utils/interfaces/IRequestAction';
import { IReduxStore } from 'utils/interfaces/IReduxStore';

// Queries
import { createSellerAddress } from 'utils/queries/seller/createSellerAddress';
import { editSellerAddress } from 'utils/queries/seller/editSellerAddress';
import { createSellerCompany } from 'utils/queries/seller/createSellerCompany';

const globalState = (state: IReduxStore): IReduxStore => state;

export function* getSellerById(action: IGraphqlRequestAction<any>) {
    try {
        const { query, params } = action.payload;

        const { data } = yield graphQLClient(query, params);

        yield put(sellerActions.getSellerSuccess({ ...data.item }));
    } catch (error) {
        yield put(sellerActions.getSellerFailure());
        yield put(alertActions.showAlert('Ocorreu um erro. Tente novamente mais tarde.', 'danger'));
        yield call(history.push, { pathname: `/app/comissionados` });
    }
}

export function* createOrEditSeller(action: IGraphqlRequestAction<any>) {
    try {
        const stateRedux = yield select(globalState);

        const { query, params } = action.payload;
        const { name, juridicName, document } = params;
        const { number, state, street, zipCode, complement, city } = params.address;

        const addressData = {
            data: {
                number,
                state,
                street,
                zip_code: zipCode,
                complement,
                city
            },
            ...(params.address.id && { id: params.address.id })
        };

        const { data: addressResponse } = yield graphQLClient(params.address.id ? editSellerAddress : createSellerAddress, addressData);

        if (!addressResponse?.item) {
            throw new Error();
        }

        const addressId = addressResponse.item.returning[0].id;

        const sellerData = {
            data: {
                name,
                juridic_name: juridicName,
                document,
                type: 1,
                id_address: addressId
            },
            ...(params.id && { id: params.id })
        };

        const { data: sellerResponse } = yield graphQLClient(query, sellerData);

        const sellerId = sellerResponse.item.returning[0].id;

        if (!params.id) {
            const sellerCompanyData = {
                data: {
                    id_company: stateRedux.organizations.currentCompany.id,
                    id_seller: sellerId
                }
            };

            yield graphQLClient(createSellerCompany, sellerCompanyData);

            yield call(history.push, { pathname: `/app/comissionados/editar/${sellerId}` });
        } else {
            yield put(
                sellerActions.createOrEditSellerSuccess({
                    id: sellerId,
                    name,
                    juridicName,
                    document,
                    address: {
                        id: addressId,
                        number,
                        state,
                        street,
                        zipCode,
                        complement,
                        city
                    }
                })
            );
        }

        yield put(alertActions.showAlert('Comissionado Salvo.', 'success'));
    } catch (error) {
        yield put(sellerActions.createOrEditSellerFailure());
        yield put(alertActions.showAlert(error.message || 'Ocorreu um erro. Tente novamente mais tarde.', 'danger'));
    }
}
