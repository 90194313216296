import { lazy } from 'react';

const List = lazy(() => import('pages/crud'));
const Contest = lazy(() => import('pages/contest'));

const contestsRoutes = [
    {
        path: '/app/concursos',
        name: 'Concursos',
        component: List,
        exact: true,
        title: 'Concursos'
    },
    {
        path: '/app/concursos/novo',
        name: 'Novo concurso',
        component: Contest,
        exact: true,
        title: 'Novo concurso'
    },
    {
        path: '/app/concursos/editar/:id',
        name: 'Editar',
        component: Contest,
        exact: true,
        title: 'Editar concurso'
    }
];

export default contestsRoutes;
