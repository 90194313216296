import { put } from 'redux-saga/effects';
import { Creators as alertActions } from 'store/ducks/alert';
import { Creators as timezoneActions } from 'store/ducks/timezone';
import { apiRequest, restVerbs } from '../apiRequest';

export function* timezoneRequest(action: any) {
    try {
        const payload = {
            method: 'GET' as restVerbs,
            endpoint: `/timezone`,
            header: {
                'X-No-Pagination': true
            }
        };

        const { data } = yield apiRequest(payload.method, payload.endpoint, null, payload.header);

        yield put(timezoneActions.timezoneSuccess(data));
    } catch (error) {
        yield put(timezoneActions.timezoneFailure({ code: error?.response?.status }));
        yield put(alertActions.showAlert('Error ao obter a lista de timezones.', 'danger'));
        console.log('error: ', error);
    }
}
