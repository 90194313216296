import React, { useState, memo } from 'react';
import { ITableAction } from 'utils/interfaces/ITable';
import { TableActionsDropdown } from './styles';
import Button from 'components/button';
import Dropdown from 'components/dropdown';
import Icon from 'components/icons/icon';

interface IProps {
    actions: ITableAction[];
}

const Tablev2Actions = ({ actions = [] }: IProps) => {
    const [isVisible, setIsVisible] = useState(false);

    return (
        <TableActionsDropdown active={isVisible}>
            <Button variant={isVisible ? 'secondary' : 'outline-secondary'} rounded={true} size="small" className="actions-button" onClick={() => setIsVisible(!isVisible)}>
                <Icon idIcon="ico-dots" width="20" height="20" viewBox="0 0 60 60" />
            </Button>
            <Dropdown isOpen={isVisible} items={actions as any} onClickOut={() => setIsVisible(false)} />
        </TableActionsDropdown>
    );
};

export default memo(Tablev2Actions);
