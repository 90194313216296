import React from 'react';

// Dependencies
import { Router, Route, Switch } from 'react-router';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

// Components
import Alert from 'components/alert';
import ErrorBoundary from 'components/error';
import Icons from 'components/icons';
import Modal from 'components/modal';

// Helpers
import history from 'services/history';
import mainRoutes from 'routes/mainRoutes';
import { ProdigyFormProvider } from 'lib/prodigy-form/context';

// Redux
import { store, persistor } from './store';

// Assets
import { GlobalStyles, PrintStyles } from 'assets/global';

// Config
import 'moment/locale/pt-br';

const App = () => (
    <Provider store={store}>
        <ProdigyFormProvider>
            <PersistGate loading={null} persistor={persistor}>
                <GlobalStyles />
                <PrintStyles />
                <Icons />
                <Router history={history}>
                    <ErrorBoundary>
                        <Switch>
                            {mainRoutes.map((route, index) => (
                                <Route path={route.path} component={route.component} exact={route.exact} key={index} />
                            ))}
                        </Switch>
                    </ErrorBoundary>
                    <Modal />
                    <Alert />
                </Router>
            </PersistGate>
        </ProdigyFormProvider>
    </Provider>
);

export default App;
