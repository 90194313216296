import { createActions, createReducer } from 'reduxsauce';
import { IModal, IModalAction } from 'utils/interfaces/IModal';

export const { Creators, Types } = createActions({
    openModal: ['element', 'action', 'resizeOptions', 'isCustom'],
    closeModal: [],

    openModalRequest: ['element', 'action'],
    openModalSuccess: ['element', 'action', 'resizeOptions'],
    openModalFailure: []
});

const INITIAL_STATE: IModal = {
    action: {
        type: '',
        payload: {
            data: '',
            endpoint: '',
            method: '',
            redirectPath: ''
        }
    },
    element: '',
    isModalVisible: false,
    isLoading: false,
    isCustom: false
};

const openModal = (state = INITIAL_STATE, action: IModalAction) => {
    return {
        ...state,
        isModalVisible: true,
        isCustom: !!action?.isCustom,
        element: action.element,
        action: action.action,
        ...(!!action.resizeOptions && { resizeOptions: action.resizeOptions })
    };
};

const closeModal = (state = INITIAL_STATE) => {
    delete state.resizeOptions;

    return {
        ...state,
        ...INITIAL_STATE
    };
};

// ACTION PARA MODAL COM REQUEST NA API
const openModalRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true });

const openModalSuccess = (state = INITIAL_STATE, action: IModalAction) => ({
    ...state,
    isModalVisible: true,
    isLoading: false,
    element: action.element,
    action: action.action,
    ...(!!action.resizeOptions && { resizeOptions: action.resizeOptions })
});

const openModalFailure = (state = INITIAL_STATE) => ({ ...state, ...INITIAL_STATE });

// @ts-ignore
export default createReducer(INITIAL_STATE, {
    [Types.OPEN_MODAL]: openModal,
    [Types.CLOSE_MODAL]: closeModal,

    [Types.OPEN_MODAL_REQUEST]: openModalRequest,
    [Types.OPEN_MODAL_SUCCESS]: openModalSuccess,
    [Types.OPEN_MODAL_FAILURE]: openModalFailure
});
