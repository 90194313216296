import { IField } from 'utils/interfaces/IField';
import InputTypes from 'utils/enums/inputTypes';

const schoolunit: IField[] = [
    {
        presentation: 'Nome',
        label: 'Nome',
        name: 'name',
        type: InputTypes.text,
        columns: 3
    },
    {
        presentation: 'Diretor',
        label: 'Diretor',
        name: 'director',
        type: InputTypes.text,
        columns: 1
    }
];

export default schoolunit;
