import { all, call, put } from 'redux-saga/effects';
import { Creators as alertActions } from 'store/ducks/alert';
import { Creators as modalActions } from 'store/ducks/modal';
import { Creators as examActions } from 'store/ducks/exam';
import { Creators as exerciseListActions } from 'store/ducks/exerciseList';
import { ICrudAction } from 'utils/interfaces/ICrud';

import { apiRequest } from '../apiRequest';
import history from 'services/history';
import { IExerciseListItemAction, ISendExerciseListPayload } from 'utils/interfaces/IQuestion';
import { ExerciseListType } from 'utils/enums/question';
import { IRequestAction } from 'utils/interfaces/IRequestAction';

// Middleware for get one exercise list
export function* getExerciseList(action: ICrudAction) {
    try {
        const { data } = yield apiRequest('GET', `/admin/exerciselist/${action.payload}`, null, {
            'X-Relations': 'learningObject;subject;items;items.question;exerciseListStudent;exerciseListStudent.student;exerciseListStudent.student.person'
        });

        yield put(exerciseListActions.getExerciseListSuccess(data));
    } catch (error) {
        yield put(exerciseListActions.getExerciseListFailure({ code: error?.response?.status }));
        yield put(alertActions.showAlert(error?.response?.data?.detail?.message || 'Ocorreu um erro. Tente novamente mais tarde.', 'danger'));
    }
}

export function* searchExerciseLists(action: any) {
    try {
        const { data } = yield apiRequest('GET', '/admin/exerciselist', null, { 'X-Relations': 'learningObject', 'X-Where': `title=%${action.payload}%` });

        yield put(exerciseListActions.searchExerciseListsSuccess(data));
    } catch (error) {
        yield put(exerciseListActions.searchExerciseListsFailure());
        yield put(alertActions.showAlert('Não foi possível pesquisar as listas de exercícios.', 'danger'));
    }
}

// Middleware for create or edit a exercise list
export function* createOrEditExerciseList(action: ICrudAction) {
    try {
        const { data: payload, endpoint, method } = action.payload;

        const { data } = yield apiRequest(method, endpoint, payload);
        const alertMessage = method === 'POST' ? 'Lista de exercícios cadastrada com sucesso' : 'Lista de exercícios atualizada com sucesso';

        yield put(exerciseListActions.createOrEditExerciseListSuccess(data));
        yield put(alertActions.showAlert(alertMessage, 'success'));

        if (method === 'PATCH') {
            return yield put(exerciseListActions.getExerciseListRequest(payload.id));
        }

        const url =
            data.type === ExerciseListType.Extra || data.type === ExerciseListType.External ? `/app/listas-de-exercicios-extras/editar/${data.id}` : `/app/listas-de-exercicios/editar/${data.id}`;

        yield call(history.push, { pathname: url });
    } catch (error) {
        console.log('error');
        yield put(exerciseListActions.createOrEditExerciseListFailure());
        yield put(alertActions.showAlert(error?.response?.data?.detail?.message || 'Ocorreu um erro. Tente novamente mais tarde.', 'danger'));
    }
}

// Middleware for delete an exercise list
export function* deleteExerciseList(action: { type: string; payload: { idExam: number; exerciseListItemId: number } }) {
    try {
        yield apiRequest('DELETE', `/admin/exerciselistitem/${action.payload.exerciseListItemId}`);

        yield put(alertActions.showAlert('Lista de exercícios apagada com sucesso', 'success'));
        yield put(exerciseListActions.getExamRequest(action.payload.idExam));
    } catch (error) {
        yield put(exerciseListActions.deleteExerciseListFailure());
        yield put(alertActions.showAlert(error?.response?.data?.detail?.message || 'Ocorreu um erro. Tente novamente mais tarde.', 'danger'));
    }
}

// Middleware for create an question of exercise list in exam
export function* createExerciseListItem(action: IExerciseListItemAction) {
    try {
        const { idExam, idExerciseList, exerciseListType, questions } = action.payload;

        const requests = questions.map((question) => apiRequest('POST', '/admin/exerciselistitem', question));
        yield all([...requests]);

        if (idExerciseList) {
            if (window.location.pathname.includes('editar')) {
                yield put(exerciseListActions.getExerciseListRequest(idExerciseList));
                return yield put(modalActions.closeModal());
            }

            const url = ExerciseListType.Extra === exerciseListType ? `/app/listas-de-exercicios-extras/editar/${idExerciseList}` : `/app/listas-de-exercicios/editar/${idExerciseList}`;

            return yield call(history.push, { pathname: url });
        }

        yield put(alertActions.showAlert('Questão adicionada com sucesso', 'success'));

        if (idExam) {
            yield put(examActions.getExamRequest(idExam));
        }

        yield put(modalActions.closeModal());
    } catch (error) {
        yield put(exerciseListActions.createExerciseListItemFailure());
        yield put(alertActions.showAlert(error?.response?.data?.detail?.message || 'Ocorreu um erro. Tente novamente mais tarde.', 'danger'));
    }
}

// Middleware for delete an question of exercise list in exam
export function* deleteExerciseListItem(action: { type: string; payload: { idExam?: number; exerciseListItemId: number; idExerciseList?: number } }) {
    try {
        const { exerciseListItemId, idExam, idExerciseList } = action.payload;
        yield apiRequest('DELETE', `/admin/exerciselistitem/${exerciseListItemId}`);

        yield put(alertActions.showAlert('Questão apagada com sucesso', 'success'));

        if (idExerciseList) {
            return yield put(exerciseListActions.getExerciseListRequest(idExerciseList));
        }

        if (idExam) {
            return yield put(examActions.getExamRequest(idExam));
        }
    } catch (error) {
        yield put(exerciseListActions.deleteExerciseListItemFailure());
        yield put(alertActions.showAlert(error?.response?.data?.detail?.message || 'Ocorreu um erro. Tente novamente mais tarde.', 'danger'));
    }
}

// Middleware for send exercise list to students
export function* sendExerciseListToStudents(action: { type: string; payload: ISendExerciseListPayload }) {
    try {
        yield apiRequest('POST', '/admin/exerciselist/send', action.payload);

        yield put(alertActions.showAlert('Listas enviadas para os alunos com sucesso', 'success'));
        yield put(modalActions.closeModal());

        yield put(exerciseListActions.getExerciseListRequest(action.payload.exerciseList));
        yield put(
            exerciseListActions.getExerciseListStudentsRequest({
                endpoint: `/admin/exerciselist/${action.payload.exerciseList}/student`,
                method: 'GET',
                params: {
                    'X-Page': 1,
                    'X-PerPage': 10
                }
            })
        );
    } catch (error) {
        yield put(exerciseListActions.sendExerciseListToStudentsFailure());
        yield put(alertActions.showAlert(error?.response?.data?.detail?.message || 'Ocorreu um erro. Tente novamente mais tarde.', 'danger'));
    }
}

// Middleware for send exercise list to students
export function* getExerciseListStudents(action: ICrudAction) {
    try {
        const { data } = yield apiRequest('GET', action.payload.endpoint, null, { ...action.payload.params });
        yield put(exerciseListActions.getExerciseListStudentsSuccess(data));
    } catch (error) {
        yield put(exerciseListActions.getExerciseListStudentsFailure());
    }
}

export function* cancelExerciselistItem(action: IRequestAction<any>) {
    try {
        const { payload } = action;

        yield apiRequest(payload.method, payload.endpoint, payload.body, payload.headers);

        yield put(exerciseListActions.cancelExerciseListItemSuccess(payload.body));

        yield put(alertActions.showAlert(`Questão ${payload.body.isCanceled ? 'Anulada' : 'Disponibilizada'}!`, 'success'));
    } catch (error) {
        console.log(error);
        yield put(exerciseListActions.cancelExerciseListItemFailure());
        yield put(alertActions.showAlert('Ocorreu um erro. Tente novamente mais tarde.', 'danger'));
    }
}

export function* deleteExerciseListStudent(action: IRequestAction<any>) {
    try {
        const { payload } = action;

        yield apiRequest(payload.method, payload.endpoint);

        yield put(exerciseListActions.deleteExerciseListStudentSuccess(payload.body));

        yield put(alertActions.showAlert(`Estudante removido!`, 'success'));
    } catch (error) {
        console.log(error);
        yield put(exerciseListActions.deleteExerciseListStudentFailure());
        yield put(alertActions.showAlert(error?.response?.data?.detail?.message || 'Ocorreu um erro. Tente novamente mais tarde.', 'danger'));
    }
}
