import { lazy } from 'react';

const List = lazy(() => import('pages/crud'));
const ViewPage = lazy(() => import('pages/answerQuestions/viewPage'));

const answerQuestionsRoutes = [
    {
        path: '/app/forum/topicos',
        name: 'Tópicos',
        component: List,
        exact: true,
        title: 'Tópicos'
    },
    {
        path: '/app/forum/topicos/ver/:id',
        name: 'Ver',
        component: ViewPage,
        exact: true,
        title: 'Ver tópico'
    }
];

export default answerQuestionsRoutes;
