import { lazy } from 'react';

const List = lazy(() => import('pages/crud'));
const ExerciseList = lazy(() => import('pages/exerciseList'));
const ExerciseListView = lazy(() => import('pages/exerciseList/view'));

const extraExerciseListRoutes = [
    {
        path: '/app/listas-de-exercicios-extras',
        name: 'Listas de Exercícios Extras',
        component: List,
        exact: true,
        title: 'Listas de Exercícios Extras'
    },
    {
        path: '/app/listas-de-exercicios-extras/novo',
        name: 'Nova lista de exercícios',
        component: ExerciseList,
        exact: true,
        title: 'Nova lista de exercícios extra'
    },
    {
        path: '/app/listas-de-exercicios-extras/editar/:id',
        name: 'Editar',
        component: ExerciseList,
        exact: true,
        title: 'Editar lista de exercícios extra'
    },
    {
        path: '/app/listas-de-exercicios-extras/ver/:id',
        name: 'Ver',
        component: ExerciseListView,
        exact: true,
        title: 'Ver lista de exercícios extra'
    }
];

export default extraExerciseListRoutes;
