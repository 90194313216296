import { lazy } from 'react';

const List = lazy(() => import('pages/crud'));
const Profiles = lazy(() => import('pages/profiles'));

const profilesRoutes = [
    {
        path: '/app/perfis',
        name: 'Perfis',
        component: List,
        exact: true,
        title: 'Perfis'
    },
    {
        path: '/app/perfis/novo',
        name: 'Novo Perfil',
        component: Profiles,
        exact: true,
        title: 'Novo Perfil'
    },
    {
        path: '/app/perfis/editar/:id',
        name: 'Editar',
        component: Profiles,
        exact: true,
        title: 'Editar Perfil'
    }
];

export default profilesRoutes;
