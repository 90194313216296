import React, { InputHTMLAttributes, useState, useEffect } from 'react';

// Dependencies
import { useField } from '@rocketseat/unform';

// Assets
import { InputElement, InputWrapper, InputElementTextArea, InputPrefix } from './styles';

interface IProps {
    icon?: JSX.Element;
    multiline?: boolean;
    prefix?: string;
}

const Input = (props: IProps & InputHTMLAttributes<HTMLInputElement> & any) => {
    const { fieldName, defaultValue, error } = useField(props.name!);

    const [value, setValue] = useState(props.value || defaultValue || '');

    useEffect(() => {
        if (defaultValue) {
            setValue(defaultValue);
        }

        // eslint-disable-next-line
    }, [props.value, defaultValue]);

    return (
        <InputWrapper hasIcon={!!props.icon} hasError={!!error} hasPrefix={!!props.prefix}>
            {props.icon && props.icon}
            {props.multiline ? (
                <InputElementTextArea value={value} defaultValue={defaultValue} name={fieldName} itemRef={fieldName} onChange={(event: any) => setValue(event.target.value)} {...props} />
            ) : (
                <InputElement defaultValue={defaultValue} name={fieldName} itemRef={fieldName} {...props} />
            )}
            {props.prefix && (
                <InputPrefix>
                    <span>{props.prefix}</span>
                </InputPrefix>
            )}
        </InputWrapper>
    );
};

export default Input;
