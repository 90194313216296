import React, { lazy } from 'react';

// Dependencies
import { addMethod, object, string, date, number, mixed } from 'yup';

// Routes
import brandRoutes from './privateRoutes/brand';
import teachersRoutes from './privateRoutes/teachers';
import courseRoutes from './privateRoutes/course';
import companyRoutes from './privateRoutes/company';
import lessonPlanRoutes from './privateRoutes/lessonPlan';
import schoolPeriodRoutes from './privateRoutes/schoolPeriod';
import subjectRoutes from './privateRoutes/subject';
import questionsRoutes from './privateRoutes/questions';
import examRoutes from './privateRoutes/exam';
import examPreviousRoutes from './privateRoutes/examPrevious';
import essayThemeRoutes from './privateRoutes/essayTheme';
import exerciseListRoutes from './privateRoutes/exerciseList';
import announcementsRoutes from './privateRoutes/announcements';
import schoolgradeRoutes from './privateRoutes/schoolgrade';
import videosRoutes from './privateRoutes/videos';
import schoolClassRoutes from './privateRoutes/schoolclass';
import schoolunitRoutes from './privateRoutes/schoolunit';
import studentRoutes from './privateRoutes/student';
import menusRoutes from './privateRoutes/menus';
import answerCardRoutes from './privateRoutes/answerCard';
import projectRoutes from './privateRoutes/project';
import projectCategoryRoutes from './privateRoutes/projectCategory';
import profilesRoutes from './privateRoutes/profiles';
import pagesRoutes from './privateRoutes/pages';
import qrcodesRoutes from './privateRoutes/qrcodes';
import qrcodesHistoryRoutes from './privateRoutes/qrcodesHistory';
import orderRoutes from './privateRoutes/order';
import reportRoutes from './privateRoutes/report';
import extraExerciseListRoutes from './privateRoutes/extraExerciseList';
import usersRoutes from './privateRoutes/users';
import productsRoutes from './privateRoutes/products';
import storesRoutes from './privateRoutes/stores';
import subjectFrontRoutes from './privateRoutes/subjectFront';
import topicRoutes from './privateRoutes/topic';
import lessonPlanTagRoutes from './privateRoutes/lessonPlanTag';
import knowledgeAreaRoutes from './privateRoutes/knowledgeArea';
import filesRoutes from './privateRoutes/files';
import filesCategoryRoutes from './privateRoutes/filesCategory';
import subjectGroupRoutes from './privateRoutes/subjectGroup';
import eventRoutes from './privateRoutes/event';
import sellersRoutes from './privateRoutes/sellers';
import lessonPlanEducationalRoutes from './privateRoutes/lesson-plan-educational';
import campaignRoutes from './privateRoutes/campaigns';
import contestsRoutes from './privateRoutes/contests';
import bunnyVideoRoutes from './privateRoutes/bunnyVideo';
import answerQuestionsRoutes from './privateRoutes/answerQuestions';

// Models
import models from 'config/models';

// Helpers
import dictionary from 'config/dictionary';
import { IRoute } from 'utils/interfaces/IRoute';
import { AnswerCardStatus } from 'utils/enums/answerCard';
import { QrCodeStatus } from 'utils/enums/qrCodeStatus';
import { VideoType } from 'utils/enums/videoType';
import { PaymentFormDictionary, OrderStatus } from 'utils/enums/product';
import { EventCategory } from 'utils/enums/event';
import VideoPlatform from 'utils/enums/videoProviders';
import correctionEngineRoutes from './privateRoutes/correctionengine';
import { CorrectionEngineAlgorithm } from 'utils/enums/correctionEngineAlgorithm';

//Queries
import productsPhysicalRoutes from './privateRoutes/productsPhysical';
import { TableItemType } from 'utils/enums/tableItemType';
import DownloadQRCode from 'components/downloadQRCode';
import invoicesRoutes from './privateRoutes/invoices';
import { InvoiceStatus } from 'utils/enums/invoiceStatus';
import { InvoiceType } from 'utils/enums/invoiceType';
import { BunnyStatus } from 'utils/enums/bunnyStatus';
import { VideoStatus } from 'utils/enums/videoStatus';

import { getAllInvoicesQuery } from 'utils/queries/invoice/getAll';
import examResolutionsRoutes from './privateRoutes/examResolutions';
import { TableAdvancedSearchType } from 'utils/enums/tableAdvancedSearchType';
import { ComplexityType } from 'utils/enums/complexityType';

const VirtualClassroom = lazy(() => import('pages/virtualClassroom'));

const Dashboard = lazy(() => import('pages/dashboard'));
const Complaints = lazy(() => import('pages/complaints'));

addMethod(number, 'numberNotRequired', function () {
    return this.transform((value: string, originalValue: string) => {
        return originalValue.trim() === '' ? null : value;
    });
});

const privateRoutes: IRoute[] = [
    {
        hasViews: false,
        path: '/app',
        component: Dashboard,
        exact: true
    },
    {
        graphQLTableName: 'students',
        brandRelationName: 'brand',
        companyRelationName: 'brand.company',
        hasNewButton: true,
        hasViews: true,
        hasViewPage: true,
        path: '/app/alunos',
        fields: models.student,
        views: studentRoutes,
        availableColumns: [
            {
                name: 'id',
                type: TableItemType.Number
            },
            {
                name: 'person.name',
                type: TableItemType.Text
            },
            {
                name: 'person.user.email',
                type: TableItemType.Text
            },
            {
                name: 'person.cpf',
                type: TableItemType.Text
            }
        ],
        hasCustomAction: true
    },
    {
        graphQLTableName: 'companies',
        hasNewButton: true,
        hasViews: true,
        hasViewPage: false,
        path: '/app/empresas',
        fields: models.company,
        views: companyRoutes,
        availableColumns: [
            {
                name: 'id',
                type: TableItemType.Number,
                grid: 3
            },
            {
                name: 'name',
                type: TableItemType.Text
            }
        ]
    },
    {
        graphQLTableName: 'brands',
        companyRelationName: 'company',
        hasNewButton: true,
        hasViews: true,
        hasViewPage: false,
        path: '/app/marcas',
        fields: models.brands,
        views: brandRoutes,
        availableColumns: [
            {
                name: 'id',
                type: TableItemType.Number
            },
            {
                name: 'name',
                type: TableItemType.Text
            },
            {
                name: 'slug',
                type: TableItemType.Text
            },
            {
                name: 'company.name',
                type: TableItemType.Text
            }
        ]
    },

    {
        brandRelationName: 'company.brands',
        companyRelationName: 'company',

        graphQLTableName: 'teachers',

        hasNewButton: true,
        hasViews: true,
        hasViewPage: true,
        path: '/app/professores',
        fields: models.teachers,
        views: teachersRoutes,
        availableColumns: [
            {
                name: 'id',
                type: TableItemType.Number,
                grid: 3
            },
            {
                name: 'person.name',
                type: TableItemType.Text
            }
        ]
    },
    {
        brandRelationName: 'learning_object.company.brands',
        companyRelationName: 'learning_object.company',
        graphQLTableName: 'learning_object_videos',
        isLearningObject: true,
        hasNewButton: true,
        hasViews: true,
        hasViewPage: true,
        canToggleVideoStatus: true,
        path: '/app/videos',
        views: videosRoutes,
        availableColumns: [
            {
                name: 'id',
                type: TableItemType.Number
            },
            {
                name: 'title',
                type: TableItemType.Text
            },
            {
                name: 'teachers',
                type: TableItemType.ArrayTextTooltip,
                subItem: 'teacher.person.name',
                grid: 2,
                removeOrdenation: true
            },
            {
                name: 'status',
                label: 'Situação',
                type: TableItemType.Enum,
                enum: VideoStatus
            },
            {
                name: 'metadata.video_type',
                type: TableItemType.Enum,
                enum: VideoType
            },
            {
                name: 'provider',
                type: TableItemType.Enum,
                enum: VideoPlatform
            }
        ],
        advancedSearch: [
            {
                name: 'learning_object.subject.id',
                type: TableAdvancedSearchType.AsyncSelect,
                relation: {
                    apiEndpoint: 'subject',
                    relationName: 'subject'
                }
            },
            {
                name: 'video_key',
                type: TableAdvancedSearchType.Text
            },
            {
                name: 'metadata.year',
                type: TableAdvancedSearchType.Number
            },
            {
                name: 'events.start_date',
                type: TableAdvancedSearchType.Date
                // afterSelect: 'metadata.type',
                // afterSelectValues: [VideoType.Live, VideoType.Complementary, VideoType.Mentorship]
            },
            {
                name: 'events.lesson_plan_module.lesson_plan.name',
                type: TableAdvancedSearchType.Text
                // afterSelect: 'metadata.type',
                // afterSelectValues: [VideoType.Live, VideoType.Complementary, VideoType.Mentorship]
            },
            {
                name: 'events.category',
                type: TableAdvancedSearchType.Enum,
                enum: EventCategory
                // afterSelectValues: [VideoType.Live, VideoType.Complementary, VideoType.Mentorship],
            }
        ]
    },
    {
        brandRelationName: 'lesson_plan_events_modules.module.lesson_plan.course.company.brands',
        companyRelationName: 'lesson_plan_events_modules.module.lesson_plan.course.company',
        graphQLTableName: 'lesson_plan_events',
        hasNewButton: false,
        hasViews: true,
        hasViewPage: true,
        isRemovable: false,
        isEditable: false,
        path: '/app/plano-de-estudos/eventos',
        fields: models.event,
        views: eventRoutes,
        availableColumns: [
            {
                name: 'id',
                type: TableItemType.Number
            },
            {
                name: 'id_video',
                label: 'ID Vídeo',
                type: TableItemType.Number
            },
            {
                name: 'title',
                type: TableItemType.Text
            },
            {
                name: 'category',
                type: TableItemType.Enum,
                enum: EventCategory
            },
            {
                name: 'start_date',
                type: TableItemType.Calendar
            },
            {
                name: 'teachers',
                type: TableItemType.ArrayTextTooltip,
                subItem: 'teacher.person.name',
                removeOrdenation: true
            },
            {
                name: 'lesson_plan_events_modules',
                label: 'Plano de estudos',
                type: TableItemType.ArrayTextTooltip,
                subItem: 'module.lesson_plan.name',
                removeOrdenation: true
            }
        ]
    },

    {
        graphQLTableName: 'lesson_plan_event_tags',
        apiEndpoint: '/admin/lessonplaneventtag',
        hasNewButton: true,
        hasViews: true,
        hasViewPage: false,
        path: '/app/plano-de-estudos/tags',
        fields: models.lessonPlanTag,
        views: lessonPlanTagRoutes,
        availableColumns: [
            {
                name: 'id',
                type: TableItemType.Number,
                grid: 3
            },
            {
                name: 'name',
                type: TableItemType.Text
            },
            {
                name: 'color',
                type: TableItemType.Color
            }
        ]
    },
    {
        brandRelationName: 'course.company.brands',
        companyRelationName: 'course.company',

        graphQLTableName: 'lesson_plans',

        hasNewButton: true,
        hasViews: true,
        hasViewPage: false,
        path: '/app/plano-de-estudos',
        fields: models.lessonPlan,
        views: lessonPlanRoutes,
        availableColumns: [
            {
                name: 'id',
                type: TableItemType.Number,
                grid: 3
            },
            {
                name: 'name',
                type: TableItemType.Text
            },
            {
                name: 'course.name',
                type: TableItemType.Text
            }
        ]
    },
    {
        brandRelationName: 'learning_object.company.brands',
        companyRelationName: 'learning_object.company',
        graphQLTableName: 'learning_object_questions',
        isLearningObject: true,
        hasNewButton: true,
        hasViews: true,
        hasViewPage: true,
        path: '/app/questoes',
        fields: models.question,
        views: questionsRoutes,
        availableColumns: [
            {
                name: 'id',
                type: TableItemType.Number
            },
            {
                name: 'enunciation',
                type: TableItemType.RichText,
                grid: 4
            },
            {
                name: 'metadata.year',
                type: TableItemType.Number
            },
            {
                name: 'metadata.subject.name',
                type: TableItemType.Text
            },
            {
                name: 'question_tags',
                type: TableItemType.ArrayText,
                label: 'Tags',
                removeOrdenation: true,
                subItem: 'tag.name'
            }
        ],
        advancedSearch: [
            {
                name: 'metadata.version',
                type: TableAdvancedSearchType.Text
            },
            {
                name: 'metadata.topic.name',
                type: TableAdvancedSearchType.Text
            },
            {
                name: 'metadata.subtopic.name',
                type: TableAdvancedSearchType.Text
            },
            {
                name: 'metadata.contest.name',
                type: TableAdvancedSearchType.Text
            }
        ]
    },
    {
        brandRelationName: 'subject.companies.company.brands',
        companyRelationName: 'subject.companies.company',
        graphQLTableName: 'topics',
        apiEndpoint: '/topic',
        hasNewButton: true,
        hasViews: true,
        hasViewPage: false,
        path: '/app/questoes/topicos',
        fields: models.topic,
        views: topicRoutes,
        availableColumns: [
            {
                name: 'id',
                type: TableItemType.Number,
                grid: 3
            },
            {
                name: 'name',
                type: TableItemType.Text
            },
            {
                name: 'subject.name',
                type: TableItemType.Text
            }
        ],
        relations: [
            {
                apiEndpoint: 'subject',
                relationName: 'subject',
                headers: {
                    'X-No-Pagination': true
                }
            }
        ]
    },
    {
        apiEndpoint: '/admin/subject',
        hasNewButton: true,
        hasViews: true,
        hasViewPage: false,
        useGraphQlForDelete: false,
        path: '/app/disciplinas',
        fields: models.subject,
        views: subjectRoutes,
        messageDelete: 'Disciplina excluída com sucesso.',
        availableColumns: [
            {
                name: 'id',
                type: TableItemType.Number
            },
            {
                name: 'name',
                type: TableItemType.Text
            },
            {
                name: 'slug',
                type: TableItemType.Text
            },
            {
                name: 'color',
                type: TableItemType.Color
            }
        ],
        schema: object().shape({
            name: string().required(dictionary.validations.required),
            color: string().required(dictionary.validations.required)
        })
    },
    {
        brandRelationName: 'company.brands',
        companyRelationName: 'company',
        graphQLTableName: 'school_periods',

        apiEndpoint: '/schoolperiod',
        hasNewButton: true,
        hasViews: true,
        hasViewPage: false,
        path: '/app/anos-letivos',
        fields: models.schoolPeriod,
        views: schoolPeriodRoutes,
        availableColumns: [
            {
                name: 'id',
                type: TableItemType.Number
            },
            {
                name: 'name',
                type: TableItemType.Text
            },
            {
                name: 'start_date',
                type: TableItemType.Date
            },
            {
                name: 'end_date',
                type: TableItemType.Date
            }
        ],
        schema: object().shape({
            name: string().required(dictionary.validations.required),
            startDate: date().required(dictionary.validations.required),
            endDate: date().required(dictionary.validations.required)
        })
    },

    {
        brandRelationName: 'company.brands',
        companyRelationName: 'company',
        graphQLTableName: 'courses',

        hasNewButton: true,
        hasViews: true,
        hasViewPage: false,
        path: '/app/cursos',
        apiEndpoint: '/admin/course',
        useGraphQlForDelete: false,
        fields: models.course,
        views: courseRoutes,
        availableColumns: [
            {
                name: 'id',
                type: TableItemType.Number,
                grid: 3
            },
            {
                name: 'name',
                type: TableItemType.Text
            },
            {
                name: 'school_period.name',
                type: TableItemType.Text
            }
        ]
    },
    {
        brandRelationName: 'company.brands',
        companyRelationName: 'company',
        graphQLTableName: 'exams',
        redirectToDetails: true,
        apiEndpoint: '/admin/exam',
        hasNewButton: true,
        hasViews: true,
        hasViewPage: true,
        path: '/app/simulados',
        fields: models.exam,
        views: examRoutes,
        availableColumns: [
            {
                name: 'id',
                type: TableItemType.Number
            },
            {
                name: 'name',
                type: TableItemType.Text
            },
            {
                name: 'slug',
                type: TableItemType.Text
            },
            {
                name: 'year',
                type: TableItemType.Number
            }
        ],
        relations: [
            {
                apiEndpoint: 'contest',
                relationName: 'contest',
                headers: {
                    'X-No-Pagination': true
                }
            }
        ],
        schema: object().shape({
            name: string().required(dictionary.validations.required),
            contest: number().required(dictionary.validations.required),
            year: number().required(dictionary.validations.required)
        }),
        filters: {
            type: {
                _eq: 1
            }
        },
        postPayload: {
            type: 1,
            isPublished: false,
            showRanking: false,
            isAvailable: false
        }
    },
    {
        brandRelationName: 'company.brands',
        companyRelationName: 'company',
        graphQLTableName: 'exams',

        apiEndpoint: '/admin/exam',
        hasNewButton: true,
        hasViews: true,
        hasViewPage: true,
        path: '/app/provas-anteriores',
        fields: models.examPrevious,
        views: examPreviousRoutes,
        availableColumns: [
            {
                name: 'id',
                type: TableItemType.Number
            },
            {
                name: 'name',
                type: TableItemType.Text
            },
            {
                name: 'year',
                type: TableItemType.Number
            },
            {
                name: 'contest.name',
                type: TableItemType.Text
            }
        ],
        relations: [
            {
                apiEndpoint: 'contest/description',
                relationName: 'contest'
            }
        ],
        schema: object().shape({
            name: string().required(dictionary.validations.required),
            contest: number().required(dictionary.validations.required),
            year: number().required(dictionary.validations.required),
            description: string()
        }),
        filters: {
            type: {
                _eq: 2
            }
        },
        postPayload: {
            type: 2,
            points: 0,
            isAvailable: true,
            isPublished: false,
            showRanking: true
        }
    },
    {
        brandRelationName: 'subject.companies.company.brands',
        companyRelationName: 'subject.companies.company',
        graphQLTableName: 'knowledge_areas',

        apiEndpoint: '/admin/knowledgearea',
        hasNewButton: true,
        hasViews: true,
        hasViewPage: false,
        path: '/app/areas-de-conhecimento',
        fields: models.knowledgeArea,
        views: knowledgeAreaRoutes,
        availableColumns: [
            {
                name: 'id',
                type: TableItemType.Number,
                grid: 3
            },
            {
                name: 'name',
                type: TableItemType.Text
            },
            {
                name: 'color',
                type: TableItemType.Color
            }
        ],
        relations: [
            {
                apiEndpoint: 'subject/description',
                relationName: 'subject',
                headers: {
                    'X-No-Pagination': true
                }
            }
        ],
        schema: object()
            .shape({
                name: string(),
                subject: mixed()
            })
            .transform((value) => {
                if (value.name) {
                    return {
                        name: value.name
                    };
                }

                if (value.subject) {
                    return {
                        subject: {
                            id: value.subject
                        }
                    };
                }

                return value;
            })
    },

    {
        brandRelationName: 'learning_object.company.brands',
        companyRelationName: 'learning_object.company',
        graphQLTableName: 'learning_object_exercise_lists',
        isLearningObject: true,
        hasNewButton: true,
        hasViews: true,
        hasViewPage: true,
        path: '/app/listas-de-exercicios',
        views: exerciseListRoutes,
        availableColumns: [
            {
                name: 'id',
                type: TableItemType.Number,
                grid: 3
            },
            {
                name: 'title',
                type: TableItemType.Text
            }
        ],
        filters: {
            type: {
                _eq: 1
            }
        }
    },

    {
        brandRelationName: 'learning_object.company.brands',
        companyRelationName: 'learning_object.company',
        graphQLTableName: 'learning_object_exercise_lists',
        isLearningObject: true,
        hasNewButton: true,
        hasViews: true,
        hasViewPage: true,
        path: '/app/listas-de-exercicios-extras',
        views: extraExerciseListRoutes,
        availableColumns: [
            {
                name: 'id',
                type: TableItemType.Number
            },
            {
                name: 'title',
                type: TableItemType.Text
            },
            {
                name: 'start_date',
                type: TableItemType.Calendar
            },
            {
                name: 'end_date',
                type: TableItemType.Calendar
            }
        ],
        filters: {
            type: {
                _in: [2, 3]
            }
        }
    },

    {
        brandRelationName: 'exercise_list.learning_object.company.brands',
        companyRelationName: 'exercise_list.learning_object.company',

        graphQLTableName: 'answer_cards',

        hasNewButton: false,
        hasViews: true,
        hasViewPage: true,
        path: '/app/listas-de-exercicios-extras/correcoes',
        views: answerCardRoutes,
        availableColumns: [
            {
                name: 'id',
                type: TableItemType.Number
            },

            {
                name: 'exercise_list.title',
                type: TableItemType.Text
            },

            {
                name: 'student.person.name',
                type: TableItemType.Text,
                grid: 4
            },
            {
                name: 'status',
                type: TableItemType.Enum,
                enum: AnswerCardStatus
            },
            {
                name: 'created',
                type: TableItemType.Calendar
            }
        ],
        advancedSearch: [
            {
                name: 'exercise_list.subject',
                type: TableAdvancedSearchType.Select,
                relation: {
                    apiEndpoint: 'subject',
                    relationName: 'subject',
                    headers: {
                        'X-No-Pagination': true
                    }
                }
            },
            {
                name: 'student.person.user.email',
                type: TableAdvancedSearchType.Text
            }
        ]
    },

    {
        apiEndpoint: 'admin/essay/theme',
        hasNewButton: true,
        hasViews: true,
        hasViewPage: true,
        path: '/app/temas-de-redacao',
        fields: models.essayTheme,
        views: essayThemeRoutes,
        mainColumn: 'idTheme',
        uploads: [
            {
                apiEndpoint: 'upload',
                fieldName: 'material',
                targetFieldName: 'urlFile'
            }
        ],
        availableColumns: [
            {
                name: 'idTheme',
                label: 'id',
                type: TableItemType.Number,
                removeOrdenation: true,
                grid: 1
            },
            {
                name: 'title',
                type: TableItemType.Text,
                removeOrdenation: true
            },
            {
                name: 'contest.name',
                type: TableItemType.Text,
                removeOrdenation: true
            },
            {
                name: 'category.title',
                type: TableItemType.Text,
                removeOrdenation: true
            },
            {
                name: 'complexity',
                type: TableItemType.Enum,
                removeOrdenation: true,
                enum: ComplexityType
            },
            {
                name: 'genre.title',
                type: TableItemType.Text,
                removeOrdenation: true
            }
        ],
        schema: object().shape({
            title: string().required(dictionary.validations.required),
            description: string(),
            contest: number().required(dictionary.validations.required),
            month: number().nullable().numberNotRequired(),
            year: number().nullable().numberNotRequired(),
            material: mixed()
        })
    },
    {
        graphQLTableName: 'notifications',

        apiEndpoint: '/admin/notification',
        hasNewButton: true,
        hasViews: true,
        hasViewPage: true,
        path: '/app/comunicados',
        fields: models.announcements,
        views: announcementsRoutes,
        schema: object().shape({
            title: string().required(dictionary.validations.required),
            description: string().typeError('Campo obrigatório').required(dictionary.validations.required)
        }),
        availableColumns: [
            {
                name: 'id',
                type: TableItemType.Number
            },
            {
                name: 'title',
                type: TableItemType.Text
            },
            {
                name: 'signature',
                type: TableItemType.Text
            }
        ],
        postPayload: {
            status: 1
        }
    },
    {
        brandRelationName: 'company.brands',
        companyRelationName: 'company',

        graphQLTableName: 'qrcodes',

        hasNewButton: true,
        hasViews: true,
        hasViewPage: true,
        path: '/app/qrcodes',
        views: qrcodesRoutes,
        isRemovable: false,
        isEditable: false,
        availableColumns: [
            {
                name: 'id',
                type: TableItemType.Number,
                grid: 3
            },
            {
                name: 'code',
                type: TableItemType.Text
            },
            {
                name: 'status',
                type: TableItemType.Enum,
                enum: QrCodeStatus
            }
        ]
    },
    {
        graphQLTableName: 'qrcode_histories',

        apiEndpoint: '/admin/qrcodehistory',
        hasViews: true,
        hasViewPage: false,
        path: '/app/qrcodes-historico',
        views: qrcodesHistoryRoutes,
        schema: object().shape({
            group: string().required(dictionary.validations.required)
        }),
        isRemovable: false,
        availableColumns: [
            {
                name: 'group',
                type: TableItemType.Text
            },
            {
                name: 'quantity',
                type: TableItemType.Number
            },
            {
                name: 'user.person.name',
                type: TableItemType.Text
            },
            {
                name: 'group',
                type: TableItemType.Custom,
                customComponent: (item: any) => <DownloadQRCode group={item} size="small" />,
                label: 'Download',
                removeSearch: true,
                removeOrdenation: true
            }
        ]
    },
    {
        brandRelationName: 'company.brands',
        companyRelationName: 'company',
        unitRelationName: 'company.brands.units',

        graphQLTableName: 'school_grades',

        apiEndpoint: '/admin/schoolgrade',
        hasNewButton: true,
        hasViews: true,
        hasViewPage: true,
        path: '/app/series',
        fields: models.schoolGrade,
        views: schoolgradeRoutes,
        availableColumns: [
            {
                name: 'id',
                type: TableItemType.Number,
                grid: 3
            },
            {
                name: 'name',
                type: TableItemType.Text
            },
            {
                name: 'order',
                type: TableItemType.Number
            }
        ],
        schema: object().shape({
            name: string().required(dictionary.validations.required),
            order: number().required(dictionary.validations.required)
        })
    },
    {
        brandRelationName: 'brand',
        companyRelationName: 'brand.company',

        graphQLTableName: 'school_units',

        hasNewButton: true,
        hasViews: true,
        hasViewPage: false,
        path: '/app/unidades-de-escola',
        fields: models.schoolunit,
        views: schoolunitRoutes,
        availableColumns: [
            {
                name: 'id',
                type: TableItemType.Number,
                grid: 3
            },
            {
                name: 'name',
                type: TableItemType.Text
            }
        ]
    },
    {
        brandRelationName: 'unit.brand',
        companyRelationName: 'period.company',
        unitRelationName: 'unit',
        graphQLTableName: 'school_classes',

        apiEndpoint: '/admin/schoolclass',
        hasNewButton: true,
        hasViews: true,
        hasViewPage: true,
        path: '/app/turmas',
        fields: models.schoolClass,
        views: schoolClassRoutes,
        relations: [
            {
                apiEndpoint: 'schoolunit',
                relationName: 'unit'
            },
            {
                apiEndpoint: 'schoolgrade',
                relationName: 'grade'
            },
            {
                apiEndpoint: 'schoolperiod',
                relationName: 'period'
            },
            {
                apiEndpoint: 'course',
                relationName: 'course'
            }
        ],
        availableColumns: [
            {
                name: 'id',
                type: TableItemType.Number
            },
            {
                name: 'name',
                type: TableItemType.Text
            },
            {
                name: 'unit.name',
                type: TableItemType.Text
            },
            {
                name: 'grade.name',
                type: TableItemType.Text
            },
            {
                name: 'period.name',
                type: TableItemType.Text
            },
            {
                name: 'course.name',
                type: TableItemType.Text
            }
        ],
        schema: object().shape({
            name: string().required(dictionary.validations.required),
            unit: number().required(dictionary.validations.required),
            grade: number().required(dictionary.validations.required),
            period: number().required(dictionary.validations.required),
            course: number().required(dictionary.validations.required)
        })
    },
    {
        brandRelationName: 'company.brands',
        companyRelationName: 'company',
        graphQLTableName: 'projects',

        apiEndpoint: '/admin/project',
        hasNewButton: true,
        hasViews: true,
        hasViewPage: true,
        path: '/app/projetos',
        views: projectRoutes,
        availableColumns: [
            {
                name: 'id',
                type: TableItemType.Number
            },
            {
                name: 'name',
                type: TableItemType.Text,
                grid: 6
            },
            {
                name: 'is_published',
                type: TableItemType.Boolean
            }
        ]
    },
    {
        brandRelationName: 'projects.company.brands',
        companyRelationName: 'projects.company',
        graphQLTableName: 'project_categories',

        apiEndpoint: '/admin/category/project',
        hasNewButton: false,
        hasViews: true,
        hasViewPage: false,
        path: '/app/projetos/categorias',
        fields: models.projectCategory,
        views: projectCategoryRoutes,
        availableColumns: [
            {
                name: 'id',
                type: TableItemType.Number,
                grid: 3
            },
            {
                name: 'name',
                type: TableItemType.Text
            }
        ]
    },
    {
        graphQLTableName: 'menus',

        hasNewButton: true,
        hasViews: true,
        hasViewPage: false,
        path: '/app/menus',
        fields: models.menus,
        views: menusRoutes,
        availableColumns: [
            {
                name: 'id',
                type: TableItemType.Number
            },
            {
                name: 'name',
                type: TableItemType.Text,
                grid: 5
            },
            {
                name: 'company.name',
                type: TableItemType.Text
            }
        ],
        filters: {
            parentId: {
                _is_null: true
            },
            companyId: {
                _is_null: false
            }
        }
    },
    {
        graphQLTableName: 'profiles',

        hasNewButton: true,
        hasViews: true,
        hasViewPage: false,
        path: '/app/perfis',
        fields: models.profiles,
        views: profilesRoutes,
        availableColumns: [
            {
                name: 'id',
                type: TableItemType.Number,
                grid: 3
            },
            {
                name: 'name',
                type: TableItemType.Text
            }
        ]
    },
    {
        graphQLTableName: 'pages',
        apiEndpoint: '/admin/page',
        hasNewButton: true,
        hasViews: true,
        hasViewPage: false,
        path: '/app/paginas',
        fields: models.pages,
        views: pagesRoutes,
        availableColumns: [
            {
                name: 'id',
                type: TableItemType.Number,
                grid: 3
            },
            {
                name: 'name',
                type: TableItemType.Text
            },
            {
                name: 'route',
                type: TableItemType.Text
            }
        ],
        schema: object().shape({
            name: string().required(dictionary.validations.required),
            path: string().required(dictionary.validations.required)
        })
    },
    {
        graphQLTableName: 'users',
        brandRelationName: 'brands.brand',
        companyRelationName: 'companies.company',
        hasNewButton: true,
        hasViews: true,
        hasViewPage: false,
        path: '/app/usuarios',
        fields: models.users,
        views: usersRoutes,
        availableColumns: [
            {
                name: 'id',
                type: TableItemType.Number,
                grid: 3
            },
            {
                name: 'person.name',
                type: TableItemType.Text
            },
            {
                name: 'email',
                type: TableItemType.Text
            }
        ]
    },
    {
        brandRelationName: 'brand',
        companyRelationName: 'brand.company',
        graphQLTableName: 'products',

        hasViews: true,
        hasViewPage: true,
        hasNewButton: true,
        path: '/app/produtos',
        fields: models.products,
        views: productsRoutes,
        availableColumns: [
            {
                name: 'id',
                type: TableItemType.Number
            },
            {
                name: 'name',
                type: TableItemType.Text
            },
            {
                name: 'slug',
                type: TableItemType.Text
            },
            {
                name: 'store.name',
                type: TableItemType.Text
            },
            {
                name: 'is_active',
                type: TableItemType.Boolean
            }
        ]
    },
    {
        graphQLTableName: 'campaigns',
        hasViews: true,
        hasViewPage: true,
        hasNewButton: true,
        path: '/app/campanhas',
        fields: models.campaigns,
        views: campaignRoutes
    },

    {
        brandRelationName: 'company.brands',
        companyRelationName: 'company',
        graphQLTableName: 'products_physical',

        hasViews: true,
        hasNewButton: true,
        path: '/app/produtos-fisicos',
        fields: models.productsPhysical,
        views: productsPhysicalRoutes,
        availableColumns: [
            {
                name: 'id',
                type: TableItemType.Number,
                grid: 3
            },
            {
                name: 'name',
                type: TableItemType.Text
            },
            {
                name: 'sku',
                type: TableItemType.Text
            }
        ]
    },

    {
        brandRelationName: 'company.brands',
        companyRelationName: 'company',
        graphQLTableName: 'stores',

        hasNewButton: true,
        hasViews: true,
        hasViewPage: true,
        path: '/app/lojas',
        fields: models.stores,
        views: storesRoutes,
        availableColumns: [
            {
                name: 'id',
                type: TableItemType.Number,
                grid: 2
            },
            {
                name: 'name',
                type: TableItemType.Text
            },
            {
                name: 'url',
                type: TableItemType.Text
            },
            {
                name: 'is_active',
                type: TableItemType.Boolean
            }
        ]
    },
    {
        brandRelationName: 'companies.company.brands',
        companyRelationName: 'companies.company',
        graphQLTableName: 'sellers',

        hasViews: true,
        hasViewPage: true,
        hasNewButton: true,
        path: '/app/comissionados',
        fields: models.sellers,
        views: sellersRoutes,
        availableColumns: [
            {
                name: 'id',
                type: TableItemType.Number,
                grid: 3
            },
            {
                name: 'name',
                type: TableItemType.Text
            }
        ]
    },
    {
        graphQLTableName: 'subject_fronts',

        apiEndpoint: '/subjectfront',
        hasNewButton: true,
        hasViews: true,
        hasViewPage: false,
        path: '/app/frentes-de-disciplina',
        fields: models.subjectFront,
        views: subjectFrontRoutes,
        availableColumns: [
            {
                name: 'id',
                type: TableItemType.Number,
                grid: 2
            },
            {
                name: 'title',
                type: TableItemType.Text
            },
            {
                name: 'subject.name',
                type: TableItemType.Text
            }
        ],
        relations: [
            {
                apiEndpoint: 'subject',
                relationName: 'subject',
                headers: {
                    'X-No-Pagination': true
                }
            }
        ],
        schema: object().shape({
            title: string().required(dictionary.validations.required),
            order: number().typeError('Campo obrigatório').required(dictionary.validations.required),
            subject: number().typeError('Campo obrigatório').required(dictionary.validations.required)
        })
    },
    {
        brandRelationName: 'store.company.brands',
        companyRelationName: 'store.company',
        graphQLTableName: 'orders',

        hasNewButton: false,
        hasViews: true,
        hasViewPage: true,
        isRemovable: false,
        isEditable: false,
        path: '/app/pedidos',
        fields: models.order,
        views: orderRoutes,
        availableColumns: [
            {
                name: 'id',
                type: TableItemType.Number
            },
            {
                name: 'amount',
                type: TableItemType.Price
            },
            {
                name: 'payment_form.name',
                type: TableItemType.Enum,
                enum: PaymentFormDictionary
            },
            {
                name: 'created',
                type: TableItemType.Date
            },
            {
                name: 'status.name',
                type: TableItemType.Enum,
                enum: OrderStatus
            }
        ]
    },
    {
        brandRelationName: 'brand',
        companyRelationName: 'brand.company',
        graphQLTableName: 'documents',

        hasNewButton: true,
        hasViews: true,
        hasViewPage: false,
        path: '/app/arquivos',
        fields: models.files,
        views: filesRoutes,
        availableColumns: [
            {
                name: 'id',
                type: TableItemType.Number
            },
            {
                name: 'material.title',
                type: TableItemType.Text,
                grid: 4
            },
            {
                name: 'course.name',
                type: TableItemType.Text
            },
            {
                name: 'category.name',
                type: TableItemType.Text
            }
        ]
    },
    {
        brandRelationName: 'company.brands',
        companyRelationName: 'company',
        graphQLTableName: 'document_categories',

        hasNewButton: true,
        hasViews: true,
        hasViewPage: false,
        fields: models.files,
        path: '/app/arquivos/categorias',

        views: filesCategoryRoutes,
        availableColumns: [
            {
                name: 'id',
                type: TableItemType.Number,
                grid: 3
            },
            {
                name: 'name',
                type: TableItemType.Text
            }
        ]
    },
    {
        apiEndpoint: '/admin/grupo-de-materias',
        hasNewButton: true,
        hasViews: true,
        hasViewPage: false,
        path: '/app/grupo-de-materias',
        fields: models.subjectGroup,
        views: subjectGroupRoutes,
        availableColumns: [
            {
                name: 'name',
                type: TableItemType.Text
            }
        ]
    },
    {
        apiEndpoint: '/admin/document',
        hasNewButton: false,
        hasViews: true,
        hasViewPage: false,
        path: '/app/relatorios',
        views: reportRoutes
    },
    {
        graphQLTableName: 'correction_engines',
        apiEndpoint: '/admin/correctionengine',
        hasNewButton: true,
        hasViews: true,
        hasViewPage: false,
        path: '/app/motores-de-correcao',
        fields: models.correctionEngine,
        views: correctionEngineRoutes,
        availableColumns: [
            {
                name: 'name',
                type: TableItemType.Text
            },
            {
                name: 'algorithm',
                type: TableItemType.Enum,
                enum: CorrectionEngineAlgorithm
            }
        ],
        schema: object().shape({
            name: string().required(dictionary.validations.required),
            algorithm: number().required(dictionary.validations.required)
        })
    },
    {
        hasViews: false,
        path: '/app/sala-virtual',
        component: VirtualClassroom,
        exact: true
    },
    {
        graphqlListQuery: getAllInvoicesQuery,
        hasNewButton: false,
        hasViews: true,
        hasViewPage: false,
        path: '/app/notas-fiscais',
        fields: models.invoices,
        views: invoicesRoutes,
        companyRelationName: 'order.store.company',
        brandRelationName: 'order.store.company.brands',
        availableColumns: [
            {
                name: 'number',
                type: TableItemType.Text,
                enum: InvoiceType,
                label: 'Número'
            },
            {
                name: 'id_invoice_status',
                type: TableItemType.Enum,
                enum: InvoiceStatus,
                label: 'Status'
            },

            {
                name: 'order.customer.email',
                type: TableItemType.Text,
                label: 'E-mail',
                grid: 6
            },
            {
                name: 'key',
                type: TableItemType.Enum,
                enum: InvoiceType,
                label: 'Tipo'
            }
        ],
        isEditable: false,
        isRemovable: false,
        actions: [
            {
                name: 'Ver pedido',
                variant: 'primary',
                isVisible: ({ order }) => !!order?.store?.id || !!order?.id,
                path: (_, { order }) => `/app/lojas/ver/${order?.store?.id}/pedidos/${order?.id}`
            },
            {
                name: 'Download PDF',
                isVisible: ({ link_pdf }) => !!link_pdf,
                path: (_, { link_pdf }) => link_pdf,
                targetBlank: true
            },
            {
                name: 'Download XML',
                isVisible: ({ link_xml }) => !!link_xml,
                path: (_, { link_xml }) => link_xml,
                targetBlank: true
            }
        ]
    },
    {
        hasNewButton: false,
        hasViews: true,
        hasViewPage: false,
        path: '/app/simulados/realizacoes',
        fields: [],
        views: examResolutionsRoutes,
        availableColumns: [],
        isEditable: false,
        isRemovable: false
    },
    {
        hasViews: false,
        path: '/app/forum/denuncias',
        component: Complaints,
        exact: true
    },
    {
        graphQLTableName: 'lesson_plans_educational',
        apiEndpoint: '',
        hasNewButton: true,
        hasViews: true,
        hasViewPage: false,
        path: '/app/planos-de-estudos-semanal',
        views: lessonPlanEducationalRoutes,
        availableColumns: [
            {
                name: 'id',
                type: TableItemType.Number
            },
            {
                name: 'name',
                type: TableItemType.Text,
                label: 'Nome',
                grid: 6
            },
            {
                name: 'slug',
                type: TableItemType.Text
            },
            {
                name: 'course.name',
                type: TableItemType.Text
            },
            {
                name: 'is_published',
                type: TableItemType.Boolean
            }
        ]
    },
    {
        apiEndpoint: '/contest',
        hasNewButton: true,
        hasViews: true,
        hasViewPage: false,
        isEditable: true,
        isRemovable: true,
        path: '/app/concursos',
        views: contestsRoutes,
        useGraphQlForDelete: false,
        availableColumns: [
            {
                name: 'id',
                type: TableItemType.Number
            },
            {
                name: 'name',
                type: TableItemType.Text
            },
            {
                name: 'correctionEngine',
                type: TableItemType.Text
            }
        ]
    },
    {
        apiEndpoint: process.env.REACT_APP_API_BUNNY_URL,
        hasNewButton: true,
        hasViews: true,
        isEditable: false,
        hasViewPage: false,
        useQueryParams: true,
        useGraphQlForDelete: false,
        messageDelete: 'Video excluído com sucesso.',
        path: '/app/videos/fila-de-uploads',
        fields: models.subject,
        views: bunnyVideoRoutes,
        availableColumns: [
            {
                name: 'id',
                type: TableItemType.Number,
                removeOrdenation: true
            },
            {
                name: 'title',
                label: 'Nome',
                type: TableItemType.Text,
                removeOrdenation: true
            },
            {
                name: 'status',
                type: TableItemType.Enum,
                enum: BunnyStatus,
                removeOrdenation: true
            },
            {
                name: 'attempts',
                label: 'Tentativas de envio',
                type: TableItemType.Number,
                removeSearch: true,
                removeOrdenation: true
            },
            {
                name: 'createdAt',
                label: 'Data de criação',
                type: TableItemType.Calendar,
                removeSearch: true,
                removeOrdenation: true
            }
        ]
    },
    {
        apiEndpoint: '/admin/forum/topics',
        hasNewButton: false,
        hasViews: true,
        hasViewPage: true,
        isEditable: false,
        canToggleAnswerQuestionStatus: true,
        isRemovable: false,
        useQueryParams: true,
        path: '/app/forum/topicos',
        views: answerQuestionsRoutes,
        useGraphQlForDelete: false,
        availableColumns: [
            {
                name: 'reference',
                label: 'Referência',
                type: TableItemType.Text
            },
            {
                name: 'title',
                label: 'Nome do tópico',
                type: TableItemType.Text
            },
            {
                name: 'status',
                label: 'Status',
                type: TableItemType.Status
            },
            {
                name: 'createdAt',
                label: 'Data de criação',
                type: TableItemType.Date,
                isDefaultQueryOrdenator: true
            }
        ],
        personalizedToggle: {
            name: 'withUnansweredPosts',
            defaultValue: false
        }
    }
];

export default privateRoutes;
