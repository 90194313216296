import { createActions, createReducer } from 'reduxsauce';

export const { Creators, Types } = createActions({
    setBrand: ['payload'],
    setGrades: ['payload'],
    setClasses: ['payload'],
    setStudents: ['payload'],
    clearAll: []
});

export interface IPickerState {
    isLoading: boolean;
    brand: any;
    grades: any[];
    classes: any[];
    students: any[];
}

const INITIAL_STATE: IPickerState = {
    isLoading: false,
    brand: null,
    grades: [],
    classes: [],
    students: []
};

const setBrand = (state = INITIAL_STATE, action: any) => ({ ...state, brand: action.payload });
const setGrades = (state = INITIAL_STATE, action: any) => ({ ...state, grades: action.payload });
const setClasses = (state = INITIAL_STATE, action: any) => ({ ...state, classes: action.payload });
const setStudents = (state = INITIAL_STATE, action: any) => ({ ...state, students: action.payload });
const clearAll = (state = INITIAL_STATE, action: any) => INITIAL_STATE;

export default createReducer(INITIAL_STATE, {
    [Types.SET_BRAND]: setBrand,
    [Types.SET_GRADES]: setGrades,
    [Types.SET_CLASSES]: setClasses,
    [Types.SET_STUDENTS]: setStudents,
    [Types.CLEAR_ALL]: clearAll
});
