export const getLessonPlanEventByIdQuery = `query getLessonPlanEvent($id: Int!) {
    item: prodigio_lesson_plan_events_by_pk(id: $id) {
      id
      title
      subtitle
      category
      has_forum
      end_date
      start_date
      is_available
      lesson_plan_events_modules {
        tag {
          id
          name
          color
        }
        module {
          id
          name
          deleted
          start_date
          end_date
          lesson_plan {
            id
            name
            course {
              id
              name
            }
          }
        }
      }
      booklet_modules {
        booklet_module {
          id
          title
          course {
            id
            name
          }
        }
      }
      video {
        id
        videoHash: video_hash
        provider
        title
        learningObject: learning_object {
          id
          openForum: open_forum
        }
      }
      teachers {
        teacher {
          id
          person {
            name
          }
        }
      }
    }
  }
`;
