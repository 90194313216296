import React, { useCallback } from 'react';
import { IAvaliableColumns } from 'utils/interfaces/IRoute';
import { TableItemType } from 'utils/enums/tableItemType';

import * as S from './styles';
import Row from 'react-bootstrap/esm/Row';
import dictionary from 'config/dictionary';
import { TableActions } from 'components/tablev2/styles';
import TableV2Carets, { TableCaretType } from '../carets';
import SearchInput from '../searchInput';
import _get from 'lodash/get';

interface IProps {
    columns: IAvaliableColumns[];
    onChangeSearch(searchData: any): void;
    searchData: any;
    hasActions: boolean;
    onChangeOrdenation(orderData: any): void;
    orderData: any;
    hasOrdenations: boolean;
    tableId: number;
    sortBySingleField?: boolean;
}

const TableV2Header = ({ columns = [], onChangeSearch, searchData, hasActions, onChangeOrdenation, orderData, hasOrdenations = false, tableId, sortBySingleField = false }: IProps) => {
    const handleChangeSearchText = useCallback(
        (value: any, column: IAvaliableColumns) => {
            onChangeSearch({
                ...searchData,
                [column.name]: {
                    value: value === '' ? undefined : (column.type === TableItemType.Number ? Number(value) : value) ?? undefined,
                    type: column.type,
                    subItem: column?.subItem
                }
            });
        },
        [onChangeSearch, searchData]
    );

    const getColumnOrdenationName = useCallback((column: IAvaliableColumns) => {
        switch (column.type) {
            case TableItemType.Person:
                return `${column.name}.name`;

            case TableItemType.Clickable:
                return `${column.name}.${column?.subItem ?? 'name'}`;

            default:
                return column.name;
        }
    }, []);

    const handleChangeOrdenation = useCallback(
        (column: IAvaliableColumns) => {
            const columnName = getColumnOrdenationName(column);

            const currentColumnOrder = !!orderData ? orderData[columnName] : undefined;

            let newColumnOrder = undefined;

            if (!currentColumnOrder) {
                newColumnOrder = TableCaretType.Ascending;
            }

            if (currentColumnOrder === TableCaretType.Ascending) {
                newColumnOrder = TableCaretType.Descending;
            }

            onChangeOrdenation({
                ...(!sortBySingleField && { ...orderData }),
                [columnName]: newColumnOrder
            });
        },
        [getColumnOrdenationName, onChangeOrdenation, orderData, sortBySingleField]
    );

    return (
        <S.TableHeaderContainer>
            <Row>
                {columns.map((column, index) => {
                    const columnLabel = column?.label || _get(dictionary.crud, column.name, column?.name || '-');
                    const columnOrdenation = _get(orderData, getColumnOrdenationName(column));

                    return (
                        <S.TableHeaderItem md={column?.grid ?? 'auto'} key={`column_header_${tableId}_${index}`}>
                            <S.TableHeaderTexts>
                                <S.TableHeaderLabel>{columnLabel}</S.TableHeaderLabel>

                                {hasOrdenations && !column?.removeOrdenation && <TableV2Carets type={columnOrdenation} onClick={() => handleChangeOrdenation(column)} />}
                            </S.TableHeaderTexts>

                            {!column?.removeSearch && (
                                <SearchInput handleChangeSearchText={(value) => handleChangeSearchText(value, column)} column={column} columnLabel={(columnLabel as string)?.toLocaleLowerCase()} />
                            )}
                        </S.TableHeaderItem>
                    );
                })}
            </Row>

            {hasActions && (
                <TableActions>
                    <S.TableHeaderLabel style={{ justifyContent: 'center' }}>Ação</S.TableHeaderLabel>
                </TableActions>
            )}
        </S.TableHeaderContainer>
    );
};

export default TableV2Header;
