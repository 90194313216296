import { IField } from 'utils/interfaces/IField';
import InputTypes from 'utils/enums/inputTypes';

const schoolClass: IField[] = [
    {
        presentation: 'Nome',
        label: 'Nome',
        name: 'name',
        type: InputTypes.text,
        columns: 3
    },
    {
        presentation: 'Unidade',
        label: 'Unidade',
        name: 'unit',
        type: InputTypes.simpleSelect,
        columns: 3
    },
    {
        presentation: 'Serie',
        label: 'Serie',
        name: 'grade',
        type: InputTypes.simpleSelect,
        columns: 3
    },
    {
        presentation: 'Periodo',
        label: 'Periodo',
        name: 'period',
        type: InputTypes.simpleSelect,
        columns: 3
    },
    {
        presentation: 'Curso',
        label: 'Curso',
        name: 'course',
        type: InputTypes.simpleSelect,
        columns: 3
    }
];

export default schoolClass;
