import React from 'react';

// Components
import Logo from 'components/logo';
import Icon from 'components/icons/icon';
import BrandSelector from 'components/brandSelector';

// Helpers
import { IAuthActions } from 'store/types/IAuth';
import { ICompany } from 'utils/interfaces/ICompany';
import { IProfile } from 'utils/interfaces/IProfile';
import { ICompanyActions, IModalActions, ISearchActions } from 'utils/interfaces/IActions';

// Assets
import { CardContainer } from 'components/card/styles';
import { BrandSelectorContainer, BrandSelectorHeader, BrandSelectorLogout } from './styles';

interface IProps {
    companyActions: ICompanyActions;
    companies: ICompany[];
    modalActions: IModalActions;
    search: any;
    searchActions: ISearchActions;
    authActions: IAuthActions;
    profiles: IProfile[];
}

class BrandSelectorScreen extends React.Component<IProps> {
    public componentDidMount = () => {
        const { companyActions } = this.props;

        companyActions.getCompaniesRequest();
    };

    public render() {
        const { companies, companyActions, modalActions, authActions, profiles } = this.props;

        return (
            <BrandSelectorContainer>
                <BrandSelectorLogout variant="danger" size="small" onClick={() => authActions.logout()}>
                    sair
                    <Icon idIcon="ico-logout" width="14" height="8" viewBox="0 0 512 512" />
                </BrandSelectorLogout>

                <CardContainer>
                    <BrandSelectorHeader>
                        <Logo type="main" />
                    </BrandSelectorHeader>
                    <div style={{ flex: 1, overflowY: 'auto' }}>
                        <BrandSelector isLoading={false} companies={companies} companyActions={companyActions} modalActions={modalActions} authActions={authActions} profiles={profiles} />
                    </div>
                </CardContainer>
            </BrandSelectorContainer>
        );
    }
}

export default BrandSelectorScreen;
