import styled, { css } from 'styled-components';
import theme from 'config/theme';

const fixedLoading = css`
    position: fixed;
    bottom: 15px;
    left: 15px;
    font-size: 1.1rem;
    color: #fff;
    background: ${theme.colors.primary};
    padding: 10px 15px;
    z-index: 9999;
    opacity: 0.7;
    border-radius: 5px;

    align-items: center;
    display: flex;
    justify-content: center;

    .spinner {
        z-index: 2;
        margin-left: 10px;
        width: 25px;
        height: 25px;
    }
`;

const fullScreenLoading = css`
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    font-size: 1.1rem;
    color: #fff;
    background: rgba(255, 255, 255, 0.6);
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;

    .spinner {
        z-index: 2;
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -25px 0 0 -25px;
        width: 50px;
        height: 50px;
    }
`;

export const LoadingWrap = styled.div<{ fullScreen?: boolean }>`
    ${(props) => (props.fullScreen ? fullScreenLoading : fixedLoading)};
    cursor: progress;
`;
