import React, { useCallback, useEffect, useState, useRef, memo, useMemo } from 'react';
import CSSTransition from 'react-transition-group/CSSTransition';
import _merge from 'lodash/merge';
import moment from 'moment';

// Redux
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { Creators as CrudActions } from 'store/ducks/crud';
import { Creators as ModalActions } from 'store/ducks/modal';

// Helpers
import { IReduxStore } from 'utils/interfaces/IReduxStore';
import { ICrudResponse, IGraphqlCrudPayload, ICrudPayload } from 'utils/interfaces/ICrud';
import { ICrudActions, IModalActions } from 'utils/interfaces/IActions';
import Tablev2 from 'components/tablev2';
import { IAvaliableColumns, IRelation, IAdvancedSearch, IPersonalizedToggle } from 'utils/interfaces/IRoute';
import { ITableAction } from 'utils/interfaces/ITable';
import { restVerbs } from 'store/sagas/apiRequest';
import { generateXWhere } from 'utils/generateXWhere';
import { generateXOrder } from 'utils/generateXOrder';
import convertToGraphQLSearch from 'utils/formatGraphQL/convertToGraphQLSearch';
import generateGetAllQuery from 'utils/formatGraphQL/generateGetAllQuery';
import addCompanyOrBrandVariables from 'utils/formatGraphQL/addCompanyOrBrandVariables';
import generateDeleteQuery from 'utils/formatGraphQL/generateDeleteQuery';
import { Variant } from 'components/button/styles';
import { AxiosInstances } from 'utils/enums/axiosInstance';
import { queryStringToObject } from 'utils/queryStringToObject';
import { ISelect } from 'utils/interfaces/IReactSelect';
import { answerQuestionsStatus } from 'utils/enums/answerQuestionsStatus';
import dictionary from 'config/dictionary';

import AdvancedSearch from 'components/advancedSearchModal';
import ListAdvancedSearchBadges from './advancedSearchBadges';
import Toggle from 'components/toggle';
import { ListContainer, FilterWrapper, FilterLabel } from './styles';

export interface IListDataProps {
    columns: IAvaliableColumns[];
    basePath?: string;
    canEdit?: boolean;
    canDelete?: boolean;
    canToggleAnswerQuestionStatus?: boolean;
    canToggleVideoStatus?: boolean;
    canSeeDetails?: boolean;
    actions?: ITableAction[];
    apiEndpoint?: string;
    getAllQuery?: string;
    deleteQuery?: string;
    advancedSearch?: IAdvancedSearch[];
    brandRelationName?: string;
    companyRelationName?: string;
    unitRelationName?: string;
    graphQLTableName?: string;
    defaultFiltes?: any;
    onClickDetails?(id: any): void;
    onClickEdit?(id: any, item?: any): void;
    isLearningObject?: boolean;
    mainColumn?: string;
    axiosInstance?: AxiosInstances;
    onClickDelete?(id: any, item?: any): void;
    ignoreDeletedItems?: boolean;
    loading?: boolean;
    graphQLSchemaName?: string;
    useGraphQlForDelete?: boolean;
    messageDelete?: string;
    useQueryParams?: boolean;
    personalizedToggle?: IPersonalizedToggle;
}

interface IProps extends IListDataProps {
    isLoading: boolean;
    data: ICrudResponse;
    xrelations?: IRelation[];
    isEmpty: boolean;
    error: boolean;
    crudActions: ICrudActions;
    modalActions: IModalActions;
}

const ListData = memo(
    ({
        columns = [],
        isLoading = false,
        isEmpty = false,
        data,
        crudActions,
        modalActions,
        basePath,
        canEdit = false,
        canDelete = false,
        canSeeDetails = false,
        canToggleAnswerQuestionStatus = false,
        canToggleVideoStatus = false,
        actions = [],
        apiEndpoint,
        getAllQuery,
        deleteQuery,
        xrelations,
        advancedSearch = [],
        graphQLTableName,
        unitRelationName,
        companyRelationName,
        brandRelationName,
        defaultFiltes,
        onClickDetails,
        onClickEdit,
        onClickDelete,
        isLearningObject,
        mainColumn,
        axiosInstance,
        ignoreDeletedItems = true,
        error,
        loading,
        graphQLSchemaName,
        useGraphQlForDelete = true,
        messageDelete,
        useQueryParams,
        personalizedToggle
    }: IProps) => {
        const [activeAdvancedSearch, setActiveAdvancedSearch] = useState(false);
        const [advancedSearchState, setAdvancedSearchState] = useState<any>();
        const [personalizedToggleValue, setPersonalizedToggleValue] = useState(personalizedToggle?.defaultValue ?? false);
        const [showTable, setShowTable] = useState(true);

        // TMP SEARCH STATE
        const tmpSearch = useRef<any>();
        const tmpOrder = useRef<any>();

        useEffect(() => {
            return () => {
                crudActions.clearAllRequest();
            };
        }, [crudActions]);

        useEffect(() => {
            if (columns) {
                return () => {
                    setAdvancedSearchState(undefined);
                    tmpSearch.current = undefined;
                    tmpOrder.current = undefined;
                    setShowTable(false);
                };
            }

            return;
        }, [columns]);

        const handleClickDelete = useCallback(
            (id?: number) => {
                if (!id) {
                    return;
                }

                const payloadRestApi: ICrudPayload = {
                    endpoint: apiEndpoint || '',
                    method: 'DELETE',
                    redirectPath: '',
                    data: {
                        id
                    },
                    axiosInstance,
                    messageDelete
                };

                const query = generateDeleteQuery(graphQLTableName, deleteQuery, graphQLSchemaName);

                const payloadGraphQL: IGraphqlCrudPayload = {
                    query,
                    params: {
                        id,
                        date: moment(new Date()).toISOString()
                    },
                    messageDelete
                };

                modalActions.openModal('confirmActionModal', {
                    confirmAction: () => (useGraphQlForDelete ? crudActions.graphqlDeleteRequest(payloadGraphQL) : crudActions.deleteRequest(payloadRestApi))
                });
            },
            [apiEndpoint, axiosInstance, crudActions, deleteQuery, graphQLSchemaName, graphQLTableName, messageDelete, modalActions, useGraphQlForDelete]
        );

        const handleAnswerQuestionsStatus = useCallback(
            (id: string, item?: any) => {
                if (!id) {
                    return;
                }

                const answerQuestionsOptions = Object.values(answerQuestionsStatus)
                    .filter((value) => isNaN(Number(value)))
                    .reduce(
                        (acc: any, current: any, index: number) => [
                            ...acc,
                            {
                                value: index,
                                label: current
                            }
                        ],
                        []
                    );

                const handleSelected = (item: ISelect) => {
                    const doubtStatus = {
                        0: 'deactivate',
                        1: 'activate',
                        2: 'turnreadonly'
                    };

                    const apiUrl = `/admin/forum/topic/${id}/${doubtStatus[item.value]}`;

                    const payloadRestApi: ICrudPayload = {
                        endpoint: apiUrl,
                        method: 'PATCH',
                        redirectPath: '/app/forum/topicos',
                        data: {
                            id,
                            status: item.value
                        },
                        axiosInstance
                    };

                    crudActions.toggleActiveRequest(payloadRestApi);
                    modalActions.closeModal();
                };

                modalActions.openModal('selectItem', {
                    title: 'Trocar status',
                    onSubmit: (item: ISelect) => handleSelected(item),
                    options: answerQuestionsOptions,
                    optionSelected: answerQuestionsOptions.find((option) => option.value === item?.status),
                    labelButton: 'Alterar'
                });
            },
            [axiosInstance, crudActions, modalActions]
        );

        const handleVideoStatus = useCallback(
            (id: string, item?: any) => {
                if (!id || ![1, 2, 3].includes(item.status)) {
                    return;
                }

                const apiUrl = `/admin/video/${id}/status`;
                let status = 0;

                if (item.status === 1) status = 2;
                if (item.status === 2) status = 1;
                if (item.status === 3) status = 4;

                const payloadRestApi: ICrudPayload = {
                    endpoint: apiUrl,
                    method: 'PATCH',
                    data: {
                        id,
                        status
                    },
                    axiosInstance
                };

                crudActions.changeVideoStatusRequest(payloadRestApi);
            },
            [axiosInstance, crudActions]
        );

        const formatActions = useMemo(() => {
            const defaultActions: ITableAction[] = actions;

            if (canSeeDetails) {
                defaultActions.push({
                    name: 'Ver',
                    ...(onClickDetails && {
                        action: (id: any) => onClickDetails(id!)
                    }),
                    ...(!!basePath && { path: (id) => `${basePath}/ver/${id}` }),
                    isPublic: true
                });
            }

            if (canEdit) {
                defaultActions.push({
                    name: 'Editar',
                    ...(onClickEdit && {
                        action: (id: any, item?: any) => onClickEdit(id, item)
                    }),
                    ...(!!basePath && { path: (id) => `${basePath}/editar/${id}` })
                });
            }

            if (canToggleAnswerQuestionStatus) {
                defaultActions.push({
                    name: 'Trocar status',
                    action: (id: any, item?: any) => handleAnswerQuestionsStatus(id, item)
                });
            }

            if (canToggleVideoStatus) {
                defaultActions.push({
                    name: 'video.status',
                    action: (id: any, item?: any) => handleVideoStatus(id, item)
                });
            }

            if (canDelete) {
                defaultActions.push({
                    name: 'Apagar',
                    action: (id: any, item?: any) => (!!onClickDelete ? onClickDelete(id, item) : handleClickDelete(id)),
                    variant: 'danger' as Variant
                });
            }

            return defaultActions;
        }, [
            actions,
            canSeeDetails,
            canEdit,
            canToggleAnswerQuestionStatus,
            canToggleVideoStatus,
            canDelete,
            onClickDetails,
            basePath,
            onClickEdit,
            handleAnswerQuestionsStatus,
            handleVideoStatus,
            onClickDelete,
            handleClickDelete
        ]);

        const getAll = useMemo(() => generateGetAllQuery(columns, graphQLTableName, getAllQuery, !!isLearningObject, graphQLSchemaName), [
            columns,
            getAllQuery,
            graphQLSchemaName,
            graphQLTableName,
            isLearningObject
        ]);

        const formatVariables = useCallback(
            (vars: any) => {
                return addCompanyOrBrandVariables(!!isLearningObject, brandRelationName, companyRelationName, unitRelationName, _merge(vars, defaultFiltes));
            },
            [brandRelationName, companyRelationName, defaultFiltes, isLearningObject, unitRelationName]
        );

        const requestGraphQL = useCallback(
            (page = 1) => {
                if (!getAll) {
                    return;
                }

                const formatSearch = convertToGraphQLSearch({ ...tmpSearch.current, ...advancedSearchState });

                const variables = formatVariables(formatSearch);

                const payload: IGraphqlCrudPayload = {
                    query: getAll,
                    page,
                    search: {
                        ...(ignoreDeletedItems && { deleted: { _is_null: true } }),
                        ...variables
                    },
                    order: tmpOrder.current
                };

                crudActions.getAllGraphqlRequest(payload);
            },
            [advancedSearchState, crudActions, formatVariables, getAll, ignoreDeletedItems]
        );

        const requestRestApi = useCallback(
            (page = 1) => {
                if (!apiEndpoint) {
                    return;
                }

                const xwhere = generateXWhere(
                    {
                        ...tmpSearch.current,
                        ...advancedSearchState,
                        ...defaultFiltes,
                        ...(!!personalizedToggle?.name && { [personalizedToggle?.name]: { type: 'boolean', value: personalizedToggleValue } })
                    },
                    useQueryParams
                );

                const xorder = generateXOrder(tmpOrder.current);

                const payload: ICrudPayload = {
                    endpoint: apiEndpoint!,
                    method: 'GET' as restVerbs,
                    redirectPath: basePath,
                    ...(!useQueryParams && {
                        params: {
                            'X-Page': page,
                            'X-PerPage': 10,
                            ...(!!xorder && { 'X-Order': xorder }),
                            ...(!!xrelations?.length && { 'X-Relations': xrelations.map(({ relationName }) => relationName).join(';') }),
                            ...(!!xwhere && { 'X-Where': xwhere })
                        }
                    }),
                    ...(useQueryParams && {
                        queryParams: {
                            page,
                            perpage: 10,
                            ...queryStringToObject(xwhere),
                            ...(!!xorder && { order: xorder })
                        }
                    }),
                    data: {},
                    axiosInstance
                };

                crudActions.getAllRequest(payload);
            },
            [advancedSearchState, apiEndpoint, axiosInstance, basePath, crudActions, defaultFiltes, personalizedToggle, personalizedToggleValue, useQueryParams, xrelations]
        );

        const requestItems = useCallback(
            (page = 1) => {
                if (!!getAll) {
                    requestGraphQL(page);
                } else {
                    requestRestApi(page);
                }
            },
            [getAll, requestGraphQL, requestRestApi]
        );

        useEffect(() => {
            let defaultOrdenator: string | undefined = undefined;

            if (columns.length > 0) {
                columns.forEach((column) => {
                    if (column.isDefaultQueryOrdenator && !defaultOrdenator) {
                        defaultOrdenator = column.name;
                    }
                });
            }

            if (!!defaultOrdenator && !tmpOrder.current) {
                tmpOrder.current = { [defaultOrdenator!]: 'desc_nulls_last' };
            }

            requestItems();
        }, [columns, requestItems]);

        const handleChangeSearch = useCallback(
            (search: any) => {
                if (!search) {
                    return;
                }

                tmpSearch.current = search;

                requestItems();
            },
            [requestItems]
        );

        const handleClickRemoveAdvancedSearchItem = useCallback((key: string) => {
            setAdvancedSearchState((old: any) => ({
                ...old,
                [key]: undefined
            }));
        }, []);

        const handleChangeOrdenation = useCallback(
            (order: object) => {
                if (!order) {
                    return;
                }

                tmpOrder.current = order;

                requestItems();
            },
            [requestItems]
        );

        const handleChangePagination = useCallback(
            (page: number) => {
                if (!page) {
                    return;
                }

                requestItems(page);
            },
            [requestItems]
        );

        return (
            <ListContainer>
                <CSSTransition in={showTable} timeout={200} classNames="table" unmountOnExit mountOnEnter onExited={() => setShowTable(true)}>
                    <>
                        <ListAdvancedSearchBadges search={advancedSearchState} onClickRemove={handleClickRemoveAdvancedSearchItem} />

                        {!!personalizedToggle?.name && (
                            <FilterWrapper>
                                <FilterLabel>Filtrar por:</FilterLabel>

                                <Toggle initialValue={personalizedToggleValue} onChange={setPersonalizedToggleValue}></Toggle>

                                <FilterLabel>{dictionary.general[personalizedToggle.name]}</FilterLabel>
                            </FilterWrapper>
                        )}

                        <Tablev2
                            error={error}
                            isEmpty={isEmpty}
                            isLoading={loading || isLoading}
                            actions={formatActions}
                            columns={columns}
                            data={data?.items ?? []}
                            pagination={{
                                page: data?.page ?? 1,
                                totalPages: data?.totalPages ?? 1,
                                totalItems: data?.totalItems ?? 0
                            }}
                            handleChangePagination={handleChangePagination}
                            handleChangeSearch={handleChangeSearch}
                            ordenation={tmpOrder.current}
                            handleChangeOrdenation={handleChangeOrdenation}
                            mainColumn={mainColumn}
                            sortBySingleField={useQueryParams}
                        />

                        {!!advancedSearch?.length && (
                            <AdvancedSearch
                                active={activeAdvancedSearch}
                                onClickButton={() => setActiveAdvancedSearch((old) => !old)}
                                searchFields={advancedSearch}
                                onSubmit={setAdvancedSearchState}
                                state={advancedSearchState}
                                isLearningObject={!!isLearningObject}
                            />
                        )}
                    </>
                </CSSTransition>
            </ListContainer>
        );
    }
);

const mapStateToProps = ({ crud }: IReduxStore) => ({
    isLoading: crud.isLoading,
    data: crud.data,
    relations: crud.relations,
    isEmpty: crud.isEmpty,
    error: !!crud.error
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    crudActions: bindActionCreators(CrudActions, dispatch) as any,
    modalActions: bindActionCreators(ModalActions, dispatch) as any
});

export default connect(mapStateToProps, mapDispatchToProps)(ListData);
