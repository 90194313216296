import { createActions, createReducer } from 'reduxsauce';
import { ITeacher } from 'utils/interfaces/ITeacher';

export const { Creators, Types } = createActions({
    getSubjectGroupsRequest: ['payload'],
    getSubjectGroupsSuccess: ['payload'],
    getSubjectGroupsFailure: [],

    createOrEditSubjectGroupRequest: ['payload'],
    createOrEditSubjectGroupSuccess: ['payload'],
    createOrEditSubjectGroupFailure: [],

    deleteSubjectGroupRequest: ['payload'],
    deleteSubjectGroupSuccess: ['payload'],
    deleteSubjectGroupFailure: [],

    reorderSubjectGroupsRequest: ['payload'],
    reorderSubjectGroupsSuccess: ['payload'],
    reorderSubjectGroupsFailure: [],

    // teachers
    getSubjectGroupsTeachersRequest: ['payload'],
    getSubjectGroupsTeachersSuccess: ['payload'],
    getSubjectGroupsTeachersFailure: [],

    addSubjectGroupsTeacherRequest: ['payload'],
    addSubjectGroupsTeacherSuccess: ['payload'],
    addSubjectGroupsTeacherFailure: [],

    deleteSubjectGroupTeacherRequest: ['payload'],
    deleteSubjectGroupTeacherSuccess: ['payload'],
    deleteSubjectGroupTeacherFailure: [],

    // subjects
    addSubjectGroupSubjectRequest: ['payload'],
    addSubjectGroupSubjectSuccess: ['payload'],
    addSubjectGroupSubjectFailure: [],

    deleteSubjectGroupSubjectRequest: ['payload'],
    deleteSubjectGroupSubjectSuccess: ['payload'],
    deleteSubjectGroupSubjectFailure: [],

    clearSubjectGroups: []
});

export interface ISubjectItemTeacher {
    id: number;
    teacher: ITeacher;
    schoolClass?: {
        id: number;
        name: string;
    };
}

export interface ISubjectGroupSubject {
    id: number;
    order: number;
    subject: {
        id: number;
        name: string;
        color: string;
    };
    subjectItemTeacher: ISubjectItemTeacher[];
}

export interface ISubjectGroup {
    id: number;
    name: string;
    color: string;
    order: number;
    subjects: ISubjectGroupSubject[];
}

export interface ISubjectGroupsState {
    isLoading: boolean;
    items: ISubjectGroup[];
}

const INITIAL_STATE: ISubjectGroupsState = {
    isLoading: false,
    items: []
};

// GENERIC ACTIONS

const request = (state = INITIAL_STATE, action: any) => ({ ...state, isLoading: true });
const failure = (state = INITIAL_STATE) => ({ ...state, isLoading: false });

const getAllSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoading: false,
    items: action.payload
});

const createOrEditSubjectGroupSuccess = (state = INITIAL_STATE, action: any) => {
    const { id = 0 } = action.payload;

    const haveThisGroup = state.items.find((group) => group.id === id);

    const updateGroups = haveThisGroup ? state.items.map((group) => (group.id === id ? { ...group, ...action.payload } : group)) : [...state.items, action.payload];

    return { ...state, isLoading: false, items: updateGroups };
};

const deleteSubjectGroupSuccess = (state = INITIAL_STATE, action: any) => {
    const updateGroups = state.items.filter((item) => item.id !== action.payload);

    return { ...state, isLoading: false, items: updateGroups };
};

const getSubjectGroupsTeachersSuccess = (state = INITIAL_STATE, action: any) => {
    try {
        const { items, subjectGroupId, subjectItemId } = action.payload;

        const updateGroups = state.items.map((subjectGroup) =>
            subjectGroup.id === subjectGroupId
                ? { ...subjectGroup, subjects: subjectGroup.subjects.map((subjectItem) => (subjectItem.id === subjectItemId ? { ...subjectItem, subjectItemTeacher: items } : subjectItem)) }
                : subjectGroup
        );

        return { ...state, isLoading: false, items: updateGroups };
    } catch (error) {
        return { ...state, isLoading: false };
    }
};

const addSubjectGroupsTeacherSuccess = (state = INITIAL_STATE, action: any) => {
    try {
        const { subjectGroupId, subjectItem, teacher, id } = action.payload;

        if (!subjectGroupId || !subjectItem || !teacher || !id) {
            throw new Error();
        }

        const updateGroups = state.items.map((subjectGroup) =>
            subjectGroup.id === subjectGroupId
                ? {
                      ...subjectGroup,
                      subjects: subjectGroup.subjects.map((subjectGroupSubject) =>
                          subjectGroupSubject.id === subjectItem.id ? { ...subjectGroupSubject, subjectItemTeacher: [...subjectGroupSubject.subjectItemTeacher, { id, teacher }] } : subjectGroupSubject
                      )
                  }
                : subjectGroup
        );

        return { ...state, isLoading: false, items: updateGroups };
    } catch (error) {
        return { ...state, isLoading: false };
    }
};

const addSubjectGroupSubjectSuccess = (state = INITIAL_STATE, action: any) => {
    try {
        const { subject, subjectGroup, id, order } = action.payload;

        if (!subject || !subjectGroup || !id) {
            throw new Error();
        }

        const updateGroups = state.items.map((subjectGroupItem) =>
            subjectGroupItem.id === subjectGroup ? { ...subjectGroupItem, subjects: [...subjectGroupItem.subjects, { id, order, subject }] } : subjectGroupItem
        );

        return { ...state, isLoading: false, items: updateGroups };
    } catch (error) {
        return { ...state, isLoading: false };
    }
};

const deleteSubjectGroupSubjectSuccess = (state = INITIAL_STATE, action: any) => {
    try {
        const { subjectGroupId, subjectItemId } = action.payload;

        if (!subjectGroupId || !subjectItemId) {
            throw new Error();
        }

        const updateGroups = state.items.map((subjectGroup) =>
            subjectGroup.id === subjectGroupId ? { ...subjectGroup, subjects: subjectGroup.subjects.filter((subjectItem) => subjectItem.id !== subjectItemId) } : subjectGroup
        );

        return { ...state, isLoading: false, items: updateGroups };
    } catch (error) {
        return { ...state, isLoading: false };
    }
};

const deleteSubjectGroupsTeacherSuccess = (state = INITIAL_STATE, action: any) => {
    try {
        const { subjectGroupId, subjectItemId, teacherId } = action.payload;

        if (!subjectGroupId || !subjectItemId || !teacherId) {
            throw new Error();
        }

        const updateGroups = state.items.map((subjectGroup) =>
            subjectGroup.id === subjectGroupId
                ? {
                      ...subjectGroup,
                      subjects: subjectGroup.subjects.map((subjectItem) =>
                          subjectItem.id === subjectItemId ? { ...subjectItem, subjectItemTeacher: subjectItem.subjectItemTeacher.filter((teacher) => teacher.id !== teacherId) } : subjectItem
                      )
                  }
                : subjectGroup
        );

        return { ...state, isLoading: false, items: updateGroups };
    } catch (error) {
        console.log('error', error);
        return { ...state, isLoading: false };
    }
};

const reorderSubjectGroupsSuccess = (state = INITIAL_STATE, action: any) => {
    try {
        const { items, subjectGroupId, type } = action.payload;

        if (!items || !type) {
            throw new Error();
        }

        if (type === 'group') {
            return { ...state, isLoading: false, items };
        }

        const updateGroups = state.items.map((subjectGroup) => (subjectGroup.id === subjectGroupId ? { ...subjectGroup, subjects: items } : subjectGroup));

        return { ...state, isLoading: false, items: updateGroups };
    } catch (error) {
        console.log('error', error);
        return { ...state, isLoading: false };
    }
};

const clearSubjectGroups = () => INITIAL_STATE;

export default createReducer(INITIAL_STATE, {
    [Types.GET_SUBJECT_GROUPS_REQUEST]: request,
    [Types.GET_SUBJECT_GROUPS_SUCCESS]: getAllSuccess,
    [Types.GET_SUBJECT_GROUPS_FAILURE]: failure,

    [Types.CREATE_OR_EDIT_SUBJECT_GROUP_REQUEST]: request,
    [Types.CREATE_OR_EDIT_SUBJECT_GROUP_SUCCESS]: createOrEditSubjectGroupSuccess,
    [Types.CREATE_OR_EDIT_SUBJECT_GROUP_FAILURE]: failure,

    [Types.REORDER_SUBJECT_GROUPS_REQUEST]: request,
    [Types.REORDER_SUBJECT_GROUPS_SUCCESS]: reorderSubjectGroupsSuccess,
    [Types.REORDER_SUBJECT_GROUPS_FAILURE]: failure,

    [Types.DELETE_SUBJECT_GROUP_REQUEST]: request,
    [Types.DELETE_SUBJECT_GROUP_SUCCESS]: deleteSubjectGroupSuccess,
    [Types.DELETE_SUBJECT_GROUP_FAILURE]: failure,

    [Types.GET_SUBJECT_GROUPS_TEACHERS_REQUEST]: request,
    [Types.GET_SUBJECT_GROUPS_TEACHERS_SUCCESS]: getSubjectGroupsTeachersSuccess,
    [Types.GET_SUBJECT_GROUPS_TEACHERS_FAILURE]: failure,

    [Types.ADD_SUBJECT_GROUPS_TEACHER_REQUEST]: request,
    [Types.ADD_SUBJECT_GROUPS_TEACHER_SUCCESS]: addSubjectGroupsTeacherSuccess,
    [Types.ADD_SUBJECT_GROUPS_TEACHER_FAILURE]: failure,

    [Types.ADD_SUBJECT_GROUP_SUBJECT_REQUEST]: request,
    [Types.ADD_SUBJECT_GROUP_SUBJECT_SUCCESS]: addSubjectGroupSubjectSuccess,
    [Types.ADD_SUBJECT_GROUP_SUBJECT_FAILURE]: failure,

    [Types.DELETE_SUBJECT_GROUP_SUBJECT_REQUEST]: request,
    [Types.DELETE_SUBJECT_GROUP_SUBJECT_SUCCESS]: deleteSubjectGroupSubjectSuccess,
    [Types.DELETE_SUBJECT_GROUP_SUBJECT_FAILURE]: failure,

    [Types.DELETE_SUBJECT_GROUP_TEACHER_REQUEST]: request,
    [Types.DELETE_SUBJECT_GROUP_TEACHER_SUCCESS]: deleteSubjectGroupsTeacherSuccess,
    [Types.DELETE_SUBJECT_GROUP_TEACHER_FAILURE]: failure,

    [Types.CLEAR_SUBJECT_GROUPS]: clearSubjectGroups
});
