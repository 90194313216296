import InputTypes from 'utils/enums/inputTypes';
import { IField } from 'utils/interfaces/IField';

const question: IField[] = [
    {
        presentation: 'Titulo',
        label: 'Titulo da Questao',
        name: 'title',
        type: InputTypes.text,
        columns: 3
    }
];

export default question;
