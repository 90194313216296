import { store } from 'store';
import { Creators as modalActions } from 'store/ducks/modal';
import { IReduxStore } from 'utils/interfaces/IReduxStore';

const logout = () => {
    store.dispatch(modalActions.openModal('newLogin'));

    throw new Error();
};

export const graphQLClient = async (query: string, variables = {}) => {
    const token = (store.getState() as IReduxStore)?.auth?.token;

    if (!token) {
        logout();
    }

    const response = await fetch(process.env.REACT_APP_GRAPHQL_API_URL as string, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ query, variables })
    });

    const bodyResponse: any = await response.json();

    if (!!bodyResponse?.errors?.length && (bodyResponse?.errors[0]?.extensions?.code === 'access-denied' || !!bodyResponse?.errors[0]?.message?.includes('authorization hook'))) {
        logout();
    }

    return bodyResponse;
};
