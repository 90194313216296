import styled from 'styled-components/macro';

import { PaginationContainer } from 'components/pagination/styles';

export const TableContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    border: 1px solid #eeeeee;

    .row {
        width: 100%;
        margin-right: unset;
        margin-left: unset;
    }

    ${PaginationContainer} {
        border-top: 1px solid #eeeeee;
    }
`;

export const TableActions = styled.div`
    display: flex;
    align-items: center;
    width: 100px;
    justify-content: center;

    > button {
        width: 35px;
        padding: 10px 0;

        :hover {
        }
    }
`;
