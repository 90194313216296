import _set from 'lodash/set';
import { TableCaretType } from 'components/tablev2/components/carets';

export default function formatGraphQLOrder(order: any) {
    const formatOrder = {};

    if (!order) {
        return formatOrder;
    }

    Object.keys(order).forEach((key) => {
        const value = order[key];

        if (value === null || value === undefined) {
            return;
        }

        return _set(formatOrder, key, value === TableCaretType.Ascending ? 'asc_nulls_last' : 'desc_nulls_last');
    });

    return formatOrder;
}
