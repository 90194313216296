import { put, call, select } from 'redux-saga/effects';
import { Creators as authActions } from 'store/ducks/auth';
import { Creators as menuActions } from 'store/ducks/menu';
import { Creators as alertActions } from 'store/ducks/alert';
import history from 'services/history';
import { apiRequest } from '../apiRequest';
import { IReduxStore } from 'utils/interfaces/IReduxStore';

const authStore = ({ auth }: IReduxStore) => auth.menu;

export function* getMenuById(action: any) {
    try {
        const { payload } = action;

        const { data } = yield apiRequest(payload.method, payload.endpoint, payload.body, payload.headers);

        yield put(menuActions.getMenuSuccess(data));
    } catch (error) {
        yield put(menuActions.getMenuFailure());
        // @ts-ignore
        yield call(history.push, `/app/menus`);
        yield put(alertActions.showAlert('Não foi possível encontrar o registro.', 'danger'));
    }
}

export function* createOrEditMenu(action: any) {
    try {
        const { payload } = action;

        const { data } = yield apiRequest(payload.method, payload.endpoint, payload.body, payload.headers);

        yield put(menuActions.createOrEditMenuSuccess(data));

        const menu = yield select(authStore);

        if (menu.id === data.id) {
            yield put(authActions.getNavbarMenuSuccess(data));
        }

        yield put(alertActions.showAlert('Menu salvo!', 'success'));

        if (payload.method === 'POST') {
            // @ts-ignore
            yield call(history.push, `/app/menus/editar/${data.id}`);
        }
    } catch (error) {
        yield put(menuActions.createOrEditMenuFailure());
        // @ts-ignore
        // yield call(history.push, `/app/menus`);
        yield put(alertActions.showAlert('Ocorreu um erro. Tente novamente mais tarde.', 'danger'));
    }
}
