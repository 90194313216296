import { createActions, createReducer } from 'reduxsauce';

export const { Creators, Types } = createActions({
    getLessonPlanModulesRequest: ['payload'],
    getLessonPlanModulesSuccess: ['payload'],
    getLessonPlanModulesFailure: [],

    createOrEditLessonPlanModulesRequest: ['payload'],
    createOrEditLessonPlanModulesSuccess: ['payload'],
    createOrEditLessonPlanModulesFailure: [],

    deleteLessonPlanModulesRequest: ['payload'],
    deleteLessonPlanModulesSuccess: ['payload'],
    deleteLessonPlanModulesFailure: []
});

export interface ILessonPlanModuleState {
    isLoading: boolean;
    items: any[];
}

const INITIAL_STATE: ILessonPlanModuleState = {
    isLoading: false,
    items: []
};

// GET ALL
const getLessonPlanModulesRequest = (state = INITIAL_STATE, action: any) => ({ ...state, isLoading: true });
const getLessonPlanModulesSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoading: false,
    items: action.payload
});
const getLessonPlanModulesFailure = (state = INITIAL_STATE) => ({ ...state, ...INITIAL_STATE });

// CREATE OR EDIT
const createOrEditLessonPlanModulesRequest = (state = INITIAL_STATE, action: any) => ({ ...state, isLoading: true });
const createOrEditLessonPlanModulesSuccess = (state = INITIAL_STATE, action: any) => {
    const createOrEdit = state.items.find((moduleItem) => moduleItem.id === action.payload.id);
    const updateModule = state.items.map((moduleItem) => (moduleItem.id === action.payload.id ? { ...moduleItem, ...action.payload } : moduleItem));

    return {
        ...state,

        isLoading: false,
        items: createOrEdit ? updateModule : [...state.items, action.payload]
    };
};
const createOrEditLessonPlanModulesFailure = (state = INITIAL_STATE) => ({ ...state, isLoading: false });

// DELETE

const deleteLessonPlanModulesRequest = (state = INITIAL_STATE, action: any) => ({ ...state, isLoading: true });
const deleteLessonPlanModulesSuccess = (state = INITIAL_STATE, action: any) => {
    const removeModule = state.items.filter((modulesItem) => modulesItem.id !== action.payload);

    return {
        ...state,
        isLoading: false,
        items: removeModule
    };
};
const deleteLessonPlanModulesFailure = (state = INITIAL_STATE) => ({ ...state, ...INITIAL_STATE });

// TYPES
export default createReducer(INITIAL_STATE, {
    // GET ALL
    [Types.GET_LESSON_PLAN_MODULES_REQUEST]: getLessonPlanModulesRequest,
    [Types.GET_LESSON_PLAN_MODULES_SUCCESS]: getLessonPlanModulesSuccess,
    [Types.GET_LESSON_PLAN_MODULES_FAILURE]: getLessonPlanModulesFailure,

    // CREATE OR EDIT
    [Types.CREATE_OR_EDIT_LESSON_PLAN_MODULES_REQUEST]: createOrEditLessonPlanModulesRequest,
    [Types.CREATE_OR_EDIT_LESSON_PLAN_MODULES_SUCCESS]: createOrEditLessonPlanModulesSuccess,
    [Types.CREATE_OR_EDIT_LESSON_PLAN_MODULES_FAILURE]: createOrEditLessonPlanModulesFailure,

    // DELETE
    [Types.DELETE_LESSON_PLAN_MODULES_REQUEST]: deleteLessonPlanModulesRequest,
    [Types.DELETE_LESSON_PLAN_MODULES_SUCCESS]: deleteLessonPlanModulesSuccess,
    [Types.DELETE_LESSON_PLAN_MODULES_FAILURE]: deleteLessonPlanModulesFailure
});
