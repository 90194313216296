import HttpStatus from 'utils/enums/httpStatus';
import { store } from 'store';

const apiErrorHandling = async (error: any) => {
    // if (process.env.NODE_ENV === 'production') {
    //    // SOME ERROR API INTEGRATION
    // }

    if (error?.response?.status === HttpStatus.UNAUTHORIZED) {
        store.dispatch({
            type: 'OPEN_MODAL',
            element: 'newLogin'
        });
    }

    return Promise.reject(error);
};

export default apiErrorHandling;
