import { lazy } from 'react';

const List = lazy(() => import('pages/crud'));
const SchoolClass = lazy(() => import('pages/schoolClass'));
const SchoolClassView = lazy(() => import('pages/schoolClass/view'));

const schoolClassRoutes = [
    {
        path: '/app/turmas',
        name: 'Turmas',
        component: List,
        exact: true,
        title: 'Turmas'
    },
    {
        path: '/app/turmas/novo',
        name: 'Nova Turma',
        component: SchoolClass,
        exact: true,
        title: 'Nova Turma'
    },
    {
        path: '/app/turmas/editar/:id',
        name: 'Editar',
        component: SchoolClass,
        exact: true,
        title: 'Editar Turma'
    },
    {
        path: '/app/turmas/ver/:id',
        name: 'Visualizar',
        component: SchoolClassView,
        exact: true,
        title: 'Visualizar Turma'
    }
];

export default schoolClassRoutes;
