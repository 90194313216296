import Header from './header';

// Redux
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { Creators as ModalActions } from 'store/ducks/modal';

// Helpers
import { IReduxStore } from 'utils/interfaces/IReduxStore';

const mapStateToProps = ({ auth, organizations }: IReduxStore) => ({
    currentBrandName: (organizations.currentBrand && organizations.currentBrand.name) || undefined,
    currentCompanyName: (organizations.currentCompany && organizations.currentCompany.name) || undefined,
    currentUnitName: (organizations.currentUnit && organizations.currentUnit.name) || undefined,
    name: auth.credentials.name,
    imageProfile: auth.credentials.imageProfile || ''
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    modalActions: bindActionCreators(ModalActions, dispatch) as any
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
