export enum TableItemType {
    Custom = 'custom',
    Date = 'date',
    Calendar = 'calendar',
    Array = 'array',
    Link = 'link',
    RichText = 'richText',
    Price = 'price',
    Enum = 'enum',
    Teacher = 'teacher',
    Person = 'person',
    Boolean = 'boolean',
    Number = 'number',
    Text = 'text',
    Color = 'color',
    Email = 'email',
    ArrayText = 'arrayText',
    ArrayTextTooltip = 'arrayTextTooltip',
    Clickable = 'clickable',
    Status = 'status'
}
