// Components
import Store from './storeCreateOrEdit';

// Redux
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { Creators as StoreActions } from 'store/ducks/store';

// Helpers
import { IReduxStore } from 'utils/interfaces/IReduxStore';

const mapStateToProps = ({ store }: IReduxStore) => ({ store });

const mapDispatchToProps = (dispatch: Dispatch) => ({
    storeActions: bindActionCreators(StoreActions, dispatch) as any
});

export default connect(mapStateToProps, mapDispatchToProps)(Store);
