import styled from 'styled-components';
import { Variant, defineBgColor, defineHoverBgColor, defineTextColor } from 'components/button/styles';

export const BadgeContainer = styled.span<{ variant: Variant; size?: 'small' | 'normal' }>`
    background-color: ${({ variant }) => defineBgColor(variant)};
    border-radius: 3px;
    color: ${({ variant }) => defineTextColor(variant)};
    display: inline-block;
    font-size: ${(props) => (props.size === 'small' ? '9px' : '11px')};
    font-weight: 300;
    line-height: 1;
    margin: 5px;
    overflow: hidden;
    padding: ${(props) => (props.size === 'small' ? '3px' : '5px')};
    white-space: nowrap;
    text-transform: uppercase;

    > svg {
        margin-right: 4px;
    }
`;

export const BadgeButton = styled.button<{ variant: Variant }>`
    background-color: ${({ variant }) => defineBgColor(variant)};
    display: inline-block;
    margin: -5px -5px -5px 5px;
    padding: 7px;
    vertical-align: middle;

    svg {
        fill: ${({ variant }) => defineTextColor(variant)};
    }

    &:hover {
        background-color: ${({ variant }) => defineHoverBgColor(variant)};
    }
`;
