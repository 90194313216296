import { createActions, createReducer } from 'reduxsauce';

export const { Creators, Types } = createActions({
    getNotificationRequest: ['payload'],
    getNotificationSuccess: ['payload'],
    getNotificationFailure: [],
    sendNotificationRequest: ['payload'],
    sendNotificationSuccess: ['payload'],
    sendNotificationFailure: []
});

export interface INotificationState {
    isLoading: boolean;
    item: any;
}

const INITIAL_STATE: INotificationState = {
    isLoading: false,
    item: {}
};

const getNotificationRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true });
const getNotificationSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoading: false,
    item: action.payload
});
const getNotificationFailure = (state = INITIAL_STATE) => ({ ...state, ...INITIAL_STATE });

const sendNotificationRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true });
const sendNotificationSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoading: false
});
const sendNotificationFailure = (state = INITIAL_STATE) => ({ ...state, ...INITIAL_STATE });

export default createReducer(INITIAL_STATE, {
    [Types.GET_NOTIFICATION_REQUEST]: getNotificationRequest,
    [Types.GET_NOTIFICATION_SUCCESS]: getNotificationSuccess,
    [Types.GET_NOTIFICATION_FAILURE]: getNotificationFailure,
    [Types.SEND_NOTIFICATION_REQUEST]: sendNotificationRequest,
    [Types.SEND_NOTIFICATION_SUCCESS]: sendNotificationSuccess,
    [Types.SEND_NOTIFICATION_FAILURE]: sendNotificationFailure
});
