const getProjectCategories = `query getProjectCategories {
    items: prodigio_project_categories(order_by: {order: asc}) {
        value: id
        label: name
    }
}`;

const getProject = `query getProject($id: Int!) {
    project: prodigio_projects_by_pk(id: $id) {
        id
        name
        order
        price
        urlImage
        is_published
        resumes {
            id
            title
            text
        }
        tags {
            icon
            id
            isMain
            name
        }
        projectCategory {
            id
            order
            name
        }
        modules {
            id
            introduction_text
            name
            order
            sections {
                id
                order
                text
                contents {
                    id
                    order
                    title
                    url_material
                    url_external
                    material {
                        content
                        link_html
                        id
                        resume
                        title
                        type
                        url
                    }
                    exerciseList {
                        code
                        content
                        duration_default
                        duration_in_minutes
                        external_url
                        id
                        slug
                        title
                        type
                        is_released
                        realizations_average_hit_rate
                        realizations_average_points
                        realizations_per_student
                        realizations_total
                    }
                    video {
                        description
                        id
                        provider
                        title
                        type
                        video_hash
                        video_key
                    }
                    id_learning_object
                    id_section_module
                }
            }
        }
        video {
            id
            provider
            title
            teachers {
                teacher {
                    person {
                        name
                    }
                }
            }
            learning_object {
                subject {
                    name
                }
            }
        }
    }
}`;

export { getProject, getProjectCategories };
