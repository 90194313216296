import React, { useRef } from 'react';

// Helpers
import { ICrudPayload } from 'utils/interfaces/ICrud';
import { INavActionsItem } from 'components/navActions';
import { ICrudActions } from 'utils/interfaces/IActions';

// Assets
import { DropdownContainer, DropdownItemTitle, DropdownItem, DropdownButton, DropdownList, DropdownButtonNavLink, DropdownButtonLink } from './styles';
import useOnClickOutside from 'hooks/useOnClickOutside';

interface IProps {
    crudActions: ICrudActions;
    items: INavActionsItem[];
    isOpen: boolean;
    onClickOut?(): void;
}

const Dropdown = ({ crudActions, isOpen = false, items, onClickOut }: IProps) => {
    const handleAction = (e: any, item: INavActionsItem) => {
        if (!item.path) {
            if (!!item.config) {
                const deletePayload: ICrudPayload = {
                    endpoint: item?.config?.apiEndpoint ?? '',
                    method: 'DELETE',
                    redirectPath: '',
                    data: {
                        id: item.id
                    }
                };

                crudActions.deleteRequest(deletePayload);

                e.preventDefault();
            }

            e.preventDefault();
        }
    };

    const ref = useRef(null);
    useOnClickOutside(ref, () => isOpen && onClickOut && onClickOut());

    return (
        <DropdownContainer isVisible={isOpen} ref={ref}>
            <ul>
                {items.map((item, index) => {
                    return (
                        <DropdownItem hasSubItem={!!item.menus && item.menus.length > 0} key={item.name + index}>
                            {item.action ? (
                                <DropdownButton variant={item?.variant} type="button" onClick={item.action}>
                                    <DropdownItemTitle>{item.name}</DropdownItemTitle>
                                </DropdownButton>
                            ) : item?.targetBlank ? (
                                <DropdownButtonLink href={item.path || ''} target="_blank">
                                    <DropdownItemTitle>{item.name}</DropdownItemTitle>
                                </DropdownButtonLink>
                            ) : (
                                <DropdownButtonNavLink variant={item?.variant} to={item.path || ''} onClick={(e: any) => handleAction(e, item)}>
                                    <DropdownItemTitle>{item.name}</DropdownItemTitle>
                                </DropdownButtonNavLink>
                            )}
                            {item.menus && (
                                <DropdownList hasSubItem={true}>
                                    {item.menus.map((navItem, ix) => {
                                        return (
                                            <DropdownItem key={navItem?.id ?? ix}>
                                                <DropdownButtonNavLink to={navItem.path}>
                                                    <DropdownItemTitle>{navItem.name}</DropdownItemTitle>
                                                </DropdownButtonNavLink>
                                            </DropdownItem>
                                        );
                                    })}
                                </DropdownList>
                            )}
                        </DropdownItem>
                    );
                })}
            </ul>
        </DropdownContainer>
    );
};

export default Dropdown;
