import styled from 'styled-components';
import theme from 'config/theme';
import { Container } from 'assets/global';
import { NavContainer, NavItem, NavWrapper } from 'components/navBar/styles';
import { MediaQueries } from 'assets/settings';
import { pulse } from 'assets/animations';

export const FooterContainer = styled.footer`
    background: ${theme.colors.white};
    box-shadow: 0px -3px 10px 0 #e2e2e2;
    padding: ${theme.spacing.large.vertical};

    ${Container} {
        align-items: center;
        display: flex;
        justify-content: space-between;
    }

    ${NavContainer} {
        padding: 0;
    }

    ${NavItem} {
        &:not(:last-child) {
            margin-right: ${theme.spacing.medium.all};
        }
    }

    ${MediaQueries.SMALL} {
        ${Container} {
            flex-direction: column;
        }

        ${NavWrapper} {
            flex-direction: column;
        }

        ${NavItem} {
            &:not(:last-child) {
                margin: 0 0 ${theme.spacing.medium.all};
            }
        }
    }

    ${MediaQueries.TABLET_PORTRAIT} {
        ${Container} {
            flex-direction: column;
        }
    }
`;

export const FooterCol = styled.div`
    align-items: center;
    display: flex;
`;

export const FooterText = styled.p`
    color: ${theme.colors.primary};
    font-family: ${theme.typography.family.primary.black.regular};
    margin-right: ${theme.spacing.small.all};

    ${MediaQueries.SMALL} {
        margin-bottom: ${theme.spacing.large.all};
    }

    ${MediaQueries.TABLET_PORTRAIT} {
        margin-bottom: ${theme.spacing.medium.all};
    }
`;

export const FooterHelper = styled.span`
    align-items: center;
    display: flex;
    font-size: ${theme.typography.sizes.small};

    strong {
        margin-left: 5px;
    }

    svg {
        animation: ${pulse} 2s infinite;
    }

    .ico-heart {
        fill: ${theme.colors.system.danger};
        margin: 0 5px;
    }
`;
