import theme from 'config/theme';
import { Form } from '@rocketseat/unform';
import { SlideInLeftSmall } from 'assets/animations';
import styled, { css } from 'styled-components/macro';
import { CardContainer } from 'components/card/styles';
import { ButtonContainer } from 'components/button/styles';
import { AvatarContainer } from 'components/avatar/styles';
import { Subtitle, SmallTitle, Title } from 'assets/global';
import { ActionsContainer, ActionsItem } from 'pages/projects/components/styles';
import { EmptyText, EmptyContainer } from 'components/empty/styles';
import { ButtonGroupContainer } from 'components/buttonGroup/styles';

export const Addresses = styled.div`
    width: 100%;

    header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding-bottom: 12px;
        border-bottom: 1px solid #0094d9;
        align-items: center;

        h3 {
            color: #0094d9;
            font-weight: bold;
            font-size: 15px;
        }
    }

    ul {
        li {
            &:not(:last-child) {
                border-bottom: 1px solid rgb(240, 240, 242);
            }

            padding-top: 15px;
            padding-bottom: 15px;

            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            button {
                padding: 10px;
                border-radius: 5px;

                :hover {
                    background: rgba(109, 192, 224, 0.2);
                }
            }
            svg {
                fill: #6dc0e0;
                stroke: #6dc0e0;
            }
        }
    }
`;

export const CourseForm = styled(Form)`
    width: 100%;

    ${ButtonContainer} {
        margin-top: 29px;
        padding: 14px 15px;
    }
`;

export const CourseCol = styled.div<{ colSize: number }>`
    display: flex;
    flex: ${({ colSize }) => colSize};
    height: 700px;

    &:not(:last-child) {
        border-right: 1px solid ${theme.colors.borderColor};
    }
`;

export const Card = styled.div`
    padding: 20px;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0px 0px 10px 0px #e2e2e2;
    margin-top: 30px;

    .circle {
        width: 60px;
        height: 60px;
        border: 2px solid #e2e2e2;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
    }

    .subject {
        font-size: 22px;
        font-weight: bold;
    }
`;

export const CardHeader = styled.header`
    align-items: center;
    display: flex;

    > ${Subtitle} {
        font-size: ${theme.typography.sizes.large};
        text-transform: uppercase;
    }
`;

export const CardBody = styled.div`
    border-bottom: 1px solid ${theme.colors.borderColor};
    padding: ${theme.spacing.medium.all};
    padding-top: ${theme.spacing.large.all};
`;

export const CardFooter = styled.footer`
    padding: ${theme.spacing.medium.all};
`;

export const StudentContainer = styled.div`
    ${ButtonGroupContainer} {
        animation: ${SlideInLeftSmall} 0.5s ease;
        position: absolute;
        right: 45px;
        top: -0.5px;

        &:after {
            border-bottom: 7px solid transparent;
            border-left: 5px solid ${theme.colors.white};
            border-top: 7px solid transparent;
            content: '';
            right: -5px;
            position: absolute;
            transform: translateY(-50%);
            top: 50%;
        }
    }
`;

export const StudentContentWrapper = styled.div`
    ${CardContainer} {
        &:not(:last-child) {
            margin-bottom: ${theme.spacing.large.all};
        }
    }

    ${CardHeader} {
        background: ${theme.colors.white};
        border-bottom: 1px solid ${theme.colors.borderColor};
        border-radius: 5px 5px 0 0;
        justify-content: space-between;
        left: 0;
        overflow: hidden;
        padding: ${theme.spacing.medium.all};
        position: sticky;
        top: 0;
        width: 100%;
        z-index: 2;
    }

    ${CardBody} {
        padding: 0;
    }
`;

export const StudentDetailsContainer = styled.div`
    border-bottom: 1px solid ${theme.colors.borderColor};
    margin-bottom: ${theme.spacing.medium.all};
    padding-bottom: ${theme.spacing.medium.all};
`;

export const StudentName = styled.strong`
    color: ${theme.colors.secondary};
    display: block;
    font-family: ${theme.typography.family.primary.bold.regular};
    font-size: ${theme.typography.sizes.normalXX};
    text-align: center;
`;

export const StudentRegistration = styled.span`
    display: block;
    font-family: ${theme.typography.family.primary.light.italic};
    font-size: 14px;
    text-align: center;
`;

export const StudentDataContainer = styled.div`
    flex: 1 0 70%;
    padding: ${theme.spacing.medium.all};
`;

export const StudentDataItem = styled.div`
    flex: 1 0 calc(100% / 3);
    margin-bottom: ${theme.spacing.medium.all};

    svg {
        /* fill: ${theme.colors.secondary}; */
        margin-right: ${theme.spacing.smallX.all};

        &.ico-check {
            fill: ${theme.colors.system.success};
        }
        &.ico-close {
            fill: ${theme.colors.system.danger};
        }
    }

    strong {
        align-items: center;
        color: ${theme.colors.baseDark};
        display: flex;
        justify-content: space-between;
        font-family: ${theme.typography.family.primary.bold.regular};
        margin-bottom: ${theme.spacing.smallX.all};
        text-transform: uppercase;
    }

    span {
        display: block;
        flex: 1;
        font-family: ${theme.typography.family.primary.light.italic};
        font-size: 14px;
    }

    > div {
        align-items: center;
        display: flex;

        ${ActionsContainer} {
            margin-left: 8px;
        }

        ${ActionsItem} {
            &:not(:last-child) {
                margin-right: 4px;
            }
        }

        &:not(:last-child) {
            margin-bottom: 10px;
        }
    }

    strong,
    > div {
        ${ButtonContainer} {
            width: 20px;
            height: 20px;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                margin: 0;
            }

            &:not(:last-child) {
                margin-right: 8px;
            }
        }
    }

    &:nth-last-child(-n + 3) {
        margin-bottom: 0;
    }
`;

export const StudentDataGroup = styled.div<{ fullColumn?: boolean }>`
    > div {
        ${({ fullColumn }) => {
            if (!fullColumn) {
                return css`
                    display: flex;
                    flex-wrap: wrap;
                `;
            }

            return;
        }}
    }

    ${Subtitle} {
        align-items: center;
        color: ${theme.colors.primary};
        display: flex;
        margin-bottom: ${theme.spacing.medium.all};
        text-transform: uppercase;

        svg {
            fill: ${theme.colors.primary};
            margin-right: ${theme.spacing.small.all};
        }
    }

    ${EmptyContainer} {
        align-items: flex-start;
    }

    ${EmptyText} {
        text-align: left !important;
    }

    &:not(:last-child) {
        border-bottom: 1px solid ${theme.colors.borderColor};
        margin-bottom: ${theme.spacing.medium.all};
        padding-bottom: ${theme.spacing.medium.all};
    }
`;

export const StudentDetailsHeaderContainer = styled.div`
    align-items: center;
    display: flex;

    ${Subtitle} {
        color: ${theme.colors.primary};
        margin-right: ${theme.spacing.medium.all};
    }
`;

export const StudentDetailsNav = styled.div`
    position: relative;
`;

export const SidebarContainer = styled.aside`
    left: 0;
    position: sticky;
    top: ${theme.spacing.large.all};

    ${SmallTitle} {
        margin-bottom: ${theme.spacing.medium.all};
        text-transform: uppercase;
    }

    ${CardHeader} {
        justify-content: flex-end;
        position: absolute;
        right: 10px;
        top: 10px;
    }
`;

export const StudentProgressContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -1px;
    position: relative;
`;

export const Graph = styled.div`
    align-items: center;
    border-right: 1px solid ${theme.colors.borderColor};
    border-bottom: 1px solid ${theme.colors.borderColor};
    display: flex;
    flex-direction: column;
    padding: ${theme.spacing.medium.all};
    width: calc(100% / 3);

    &:nth-child(3n + 3) {
        border-right: none;
    }
`;

export const GraphWrapper = styled.div`
    margin-bottom: ${theme.spacing.medium.all};
`;

export const SubjectProgressDetails = styled.div`
    flex: 1;
    text-align: center;
    white-space: nowrap;

    > ${Subtitle} {
        display: block;
        margin-bottom: ${theme.spacing.smallX.all};
    }

    > button {
        color: ${theme.colors.secondary};
        font-family: ${theme.typography.family.primary.light.italic};
        font-size: ${theme.typography.sizes.small};
        text-decoration: underline;
    }
`;

export const SubjectProgressText = styled.span`
    display: block;
    font-family: ${theme.typography.family.primary.light.italic};
    margin-bottom: ${theme.spacing.smallX.all};
`;

export const SubjectDetailsContainer = styled.div`
    background: rgba(255, 255, 255, 0.95);
    height: 100%;
    left: 0;
    overflow-y: auto;
    padding: ${theme.spacing.large.all};
    position: absolute;
    width: 100%;
    top: 0;
    z-index: 10;

    ${ButtonContainer} {
        position: absolute;
        right: 15px;
        top: 15px;
    }

    ${Title} {
        margin-bottom: ${theme.spacing.large.all};
    }
`;

export const SubjectModulesContainer = styled.ul`
    display: flex;
    flex-wrap: wrap;
`;

export const SubjectModuleContainer = styled.li`
    border-bottom: 1px solid ${theme.colors.borderColor};
    border-right: 1px solid ${theme.colors.borderColor};
    padding: ${theme.spacing.medium.all};
    width: calc(100% / 3);

    ${SmallTitle} {
        margin-bottom: ${theme.spacing.medium.all};
    }

    &:nth-child(3n + 3) {
        border-right: none;
    }

    &:nth-last-child(-n + 3) {
        border-bottom: none;
    }
`;

export const SubjectModuleItems = styled.ul`
    margin-bottom: ${theme.spacing.medium.all};
`;

export const SubjectModuleItem = styled.li`
    align-items: center;
    display: flex;

    svg {
        fill: ${theme.colors.secondary};
        margin-right: ${theme.spacing.small.all};
    }

    &:not(:last-child) {
        margin-bottom: ${theme.spacing.medium.all};
    }
`;

export const SubjectModuleText = styled.span`
    white-space: nowrap;
`;

export const StudentSubscriptionsContainer = styled.div`
    padding: ${theme.spacing.medium.all};
`;

export const StudentProfileContainer = styled.div`
    display: flex;
`;

export const StudentInfos = styled.div`
    border-right: 1px solid ${theme.colors.borderColor};
    display: flex;
    flex: 1 0 30%;
    flex-direction: column;
    justify-content: center;
    padding: ${theme.spacing.medium.all};

    ${AvatarContainer} {
        background: ${theme.colors.primary};
        margin: 0 auto ${theme.spacing.medium.all};

        span {
            color: ${theme.colors.white};
        }
    }
`;

export const StudentActionsContainer = styled.div`
    position: relative;
`;

export const StudentCrudContainer = styled.div`
    ${SmallTitle} {
        margin-bottom: ${theme.spacing.medium.all};
        text-transform: uppercase;
    }

    ${CardContainer} {
        &:not(:last-child) {
            margin-bottom: ${theme.spacing.large.all};
        }
    }
`;

export const StudentOrdersContainer = styled.div`
    padding: ${theme.spacing.medium.all};
`;
