import { createActions, createReducer } from 'reduxsauce';
import { ISchoolClass } from 'utils/interfaces/ISchoolClass';
import { IExerciseList, IAnswerCard } from 'utils/interfaces/IExerciseList';
import { ISchoolPeriod } from 'utils/interfaces/ISchoolPeriod';

export const { Creators, Types } = createActions({
    getDashboardContentRequest: [],
    getDashboardContentSuccess: ['payload'],
    getDashboardContentFailure: ['payload'],

    clearDashboard: []
});

export interface IDashboardState {
    isLoading: boolean;
    answerCards?: IAnswerCard[];
    classes?: ISchoolClass[];
    exerciseLists?: IExerciseList[];
    schoolPeriods?: ISchoolPeriod[];
}

const INITIAL_STATE: IDashboardState = {
    isLoading: false,
    classes: []
};

const getDashboardContentRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true });

const getDashboardContentSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    ...action.payload,
    isLoading: false
});

const getDashboardContentFailure = (state = INITIAL_STATE) => ({ ...state, ...INITIAL_STATE, isLoading: false });

const clearDashboard = (state = INITIAL_STATE) => INITIAL_STATE;

export default createReducer(INITIAL_STATE, {
    [Types.GET_DASHBOARD_CONTENT_REQUEST]: getDashboardContentRequest,
    [Types.GET_DASHBOARD_CONTENT_SUCCESS]: getDashboardContentSuccess,
    [Types.GET_DASHBOARD_CONTENT_FAILURE]: getDashboardContentFailure,

    [Types.CLEAR_DASHBOARD]: clearDashboard
});
