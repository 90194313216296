import theme from 'config/theme';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { Variant } from 'components/button/styles';

export const DropdownContainer = styled.nav<{ isVisible: boolean }>`
    background: ${theme.colors.white};
    box-shadow: rgba(0, 0, 0, 0.05) 2px 2px 15px;
    border-radius: 5px;
    bottom: 0;
    min-width: 200px;
    display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
    position: absolute;
    transform: translateY(100%);
    z-index: 10;
`;

export const DropdownList = styled.ul<{ hasSubItem?: boolean }>`
    transition: transform 0.2s ease;
    display: none;

    ${({ hasSubItem }) => {
        if (hasSubItem) {
            return css`
                min-width: 200px;
                position: absolute;
                right: 0;
                top: 0;
                transform: translateX(0);
                z-index: -1;
            `;
        }

        return;
    }}
`;

export const DropdownItem = styled.li<{ hasSubItem?: boolean }>`
    position: relative;

    ${({ hasSubItem }) => {
        if (hasSubItem) {
            return css`
                &:after {
                    background: ${theme.colors.secondary};
                    border-radius: 50%;
                    bottom: 10px;
                    content: '';
                    height: 5px;
                    position: absolute;
                    right: 15px;
                    transform: translateY(-50%);
                    top: 50%;
                    width: 5px;
                }
            `;
        }

        return;
    }}

    &:hover {
        ${DropdownList} {
            flex-direction: column;
            transform: translateX(100%);
            display: flex;
        }
    }
`;

const variantColor = (variant: any) => theme.colors[variant?.replace('outline-', '')] ?? theme.colors.system[variant?.replace('outline-', '')] ?? theme.colors.base;

interface IButtonProps {
    hasSubmenu?: boolean;
    variant?: Variant;
}

const DropdownButtonStyle = css<IButtonProps>`
    background: ${theme.colors.white};
    color: ${({ variant }) => variantColor(variant)};
    display: block;
    font-family: ${theme.typography.family.primary.light.regular};
    padding: ${theme.spacing.small.all} ${theme.spacing.medium.all} ${theme.spacing.small.all} ${theme.spacing.medium.all};
    position: relative;
    text-align: left;
    transition: background 0.2s ease;
    white-space: nowrap;
    width: 100%;

    &:hover {
        background: #f9f9f9;
    }

    &.active {
        background: #f9f9f9;
        color: ${theme.colors.secondary};
    }
`;

export const DropdownButtonLink = styled.a<IButtonProps>`
    ${DropdownButtonStyle};
`;

export const DropdownButtonNavLink = styled(NavLink)<IButtonProps>`
    ${DropdownButtonStyle};
`;

export const DropdownButton = styled.button<IButtonProps>`
    ${DropdownButtonStyle};
`;

export const DropdownItemTitle = styled.span`
    font-family: ${theme.typography.family.primary.normal.regular};
`;
