import { createActions, createReducer } from 'reduxsauce';
import { IOrderState } from 'utils/interfaces/IOrder';
import { OrderStatus } from 'utils/enums/product';

export const { Creators, Types } = createActions({
    getOrderRequest: ['payload'],
    getOrderSuccess: ['payload'],
    getOrderFailure: [],

    refoundOrderRequest: ['payload'],
    refoundOrderSuccess: ['payload'],
    refoundOrderFailure: ['payload'],

    clearOrder: []
});

const INITIAL_STATE: IOrderState = {
    isLoading: false,
    id: 0,
    amount: 0,
    originalAmount: 0,
    installments: 0,
    updated: '',
    code: '',
    status: '',
    created: '',
    paymentForm: {
        id: 0,
        name: 'cartão',
        defaultNumberOfInstallments: 0
    },
    details: [],
    financialMovement: [],
    customer: {
        id: 0,
        country: '',
        documentNumber: '',
        email: '',
        name: '',
        type: '',
        created: '',
        student: {
            created: '',
            id: 0,
            updated: '',
            person: {
                addresses: [],
                cpf: '',
                id: 0,
                name: '',
                slug: '',
                isMigrated: false,
                isTermsAccepted: false
            }
        },
        updated: ''
    },
    transaction: []
};

const getOrder = (state = INITIAL_STATE, action: any) => ({
    isLoading: false,
    ...action.payload
});

const refoundOrder = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoading: false,
    status: OrderStatus.RefundedPayment,
    financialMovement: [
        ...state.financialMovement,
        {
            id: Math.random(),
            updated: new Date().toISOString(),
            created: new Date().toISOString(),
            status: OrderStatus.RefundedPayment,
            reason: ''
        }
    ]
});

// GENERIC ACTIONS
const Request = (state = INITIAL_STATE, action: any) => ({ ...state, isLoading: true });
const RequestFailure = (state = INITIAL_STATE) => ({ ...state, isLoading: false });

const clear = () => INITIAL_STATE;

export default createReducer(INITIAL_STATE, {
    [Types.GET_ORDER_REQUEST]: Request,
    [Types.GET_ORDER_SUCCESS]: getOrder,
    [Types.GET_ORDER_FAILURE]: RequestFailure,

    [Types.REFOUND_ORDER_REQUEST]: Request,
    [Types.REFOUND_ORDER_SUCCESS]: refoundOrder,
    [Types.REFOUND_ORDER_FAILURE]: RequestFailure,

    [Types.CLEAR_ORDER]: clear
});
