export enum InvoiceStatus {
    'Aguardando Integração com API' = 1,
    'Integrada com API',
    'Gerada',
    Erro,
    'Gerada Manual',
    'Aguardando Cancelamento',
    Cancelada,
    'Erro no Cancelamento',
    'Nota gerada, mas sem arquivo xml'
}
