import styled from 'styled-components';
import theme from 'config/theme';
import { Container } from 'assets/global';
import hexToRgba from 'utils/hexToRgba';
import { AvatarContainer } from 'components/avatar/styles';
import { MediaQueries } from 'assets/settings';

export const HeaderContainer = styled.header`
    box-shadow: 0px 3px 10px 0px #e2e2e2;
    position: relative;
    z-index: 20;
`;

export const HeaderActions = styled.div`
    align-items: center;
    display: flex;
`;

export const HeaderTop = styled.div`
    background: ${theme.colors.primary};
    padding: ${theme.spacing.medium.vertical};
    width: 100%;

    ${Container} {
        align-items: center;
        display: flex;
        justify-content: space-between;
    }
`;

export const HeaderBottom = styled.div`
    background-color: ${theme.colors.white};
`;

export const WelcomeCardContainer = styled.div`
    position: relative;
`;

export const WelcomeCardButton = styled.button<{ isNavOpen: boolean }>`
    align-items: center;
    display: flex;

    > svg {
        transform: ${({ isNavOpen }) => (isNavOpen ? 'rotate(0)' : 'rotate(-180deg)')};
        transition: transform 0.2s ease;
    }

    .ico-arrow-down {
        opacity: 0.7;
        stroke: ${theme.colors.white};
    }

    ${AvatarContainer} {
        margin-right: ${theme.spacing.small.all};
    }

    ${MediaQueries.TABLET_LANDSCAPE} {
        background: ${hexToRgba(theme.colors.white, 0.15)};
        border-radius: 5px;
        padding: 10px 15px;
    }

    ${MediaQueries.NOTEBOOK} {
        background: ${hexToRgba(theme.colors.white, 0.15)};
        border-radius: 5px;
        padding: 10px 15px;
    }

    ${MediaQueries.DESKTOP} {
        background: ${hexToRgba(theme.colors.white, 0.15)};
        border-radius: 5px;
        padding: 10px 15px;
    }
`;

export const WelcomeCardText = styled.span`
    color: ${theme.colors.white};
    margin-right: ${theme.spacing.small.all};

    strong {
        font-family: ${theme.typography.family.primary.bold.regular};
    }

    ${MediaQueries.SMALL} {
        display: none;
    }
`;

export const BrandSelectorContainer = styled.div`
    border-left: 1px solid ${hexToRgba(theme.colors.white, 0.3)};
    margin-left: ${theme.spacing.large.all};
    padding-left: ${theme.spacing.large.all};
`;

export const BrandSelectorText = styled.span`
    color: ${theme.colors.white};
    font-family: ${theme.typography.family.primary.light.italic};
    font-size: ${theme.typography.sizes.small};
`;

export const BrandSelectWrapper = styled.div`
    align-items: center;
    display: flex;
    padding: 5px 0;

    .ico-exchange {
        fill: ${theme.colors.white};
    }
`;

export const BrandSelected = styled.span`
    color: white;
    margin-right: ${theme.spacing.small.all};
`;
