import React, { useMemo, useCallback } from 'react';
import { IAdvancedSearch } from 'utils/interfaces/IRoute';
import { FormGroup, Label } from 'components/forms/styles';
import dictionary from 'config/dictionary';
import Input from 'components/forms/input';
import moment from 'moment';
import Select from 'components/forms/select';
import convertEnumToOptions from 'utils/convertEnumToOptions';
import api from 'services/api';
import AsyncSelect from 'components/forms/asyncSelect';
import { isValidDate } from 'utils/isValidDate';
import DatePicker from 'components/forms/datePicker';
import { graphQLClient } from 'services/graphqlClient';
import generateSearchQuery from 'utils/formatGraphQL/generateSearchQuery';
import addCompanyOrBrandVariables from 'utils/formatGraphQL/addCompanyOrBrandVariables';
interface IProps {
    field: IAdvancedSearch;
    stateFilters: any;
    setStateFilters(data: any): void;
    isLearningObject?: boolean;
}

const AdvancedSearchFieldItem = ({ field, stateFilters, setStateFilters, isLearningObject }: IProps) => {
    const label = useMemo(() => dictionary.crud[field.name] ?? field.name, [field.name]);

    const handleChange = useCallback(
        (value: any, type = 'text', label?: string) => {
            setStateFilters({
                ...stateFilters,
                [field.name]:
                    value === undefined
                        ? undefined
                        : {
                              value: type === 'number' ? Number(value) : value,
                              type,
                              label
                          }
            });
        },
        [field, setStateFilters, stateFilters]
    );

    const handleLoadOptions = useCallback(
        async (text: string) => {
            console.log('field?.relation', field?.relation);

            if (!text?.trim() || !field?.relation) {
                return new Promise((resolve) => resolve([]));
            }

            let data: any;

            if (!!field?.relation?.apiEndpoint) {
                const response = await api.get(field?.relation?.apiEndpoint, {
                    headers: {
                        'X-Where': `${field?.relation?.searchFor ?? 'name'}=%${text}%`
                    }
                });

                data = response?.data;
            }

            if (!!field?.relation?.query || !!field?.relation?.graphQLTableName) {
                const response = await graphQLClient(field?.relation?.query ?? generateSearchQuery(field.relation.graphQLTableName, field.relation?.searchFor), {
                    text: `%${text}%`,
                    where: addCompanyOrBrandVariables(
                        !!isLearningObject,
                        field?.relation?.graphQLBrandRelatioName,
                        field?.relation?.graphQLCompanyRelatioName,
                        field?.relation?.graphQLUnitRelatioName,
                        {
                            ...field?.relation?.defaultFilters
                        }
                    )
                });

                data = response?.data;
            }

            const formatData = (Array.isArray(data) ? data : data?.items)?.map((item: any) => ({ value: item.id, label: item?.name ?? item?.person?.name ?? '-' })) || [];

            return new Promise((resolve) => resolve(formatData));
        },
        [field, isLearningObject]
    );

    const inputfield = useMemo(() => {
        try {
            const value = !!stateFilters ? (stateFilters[field.name]?.label ? { ...stateFilters[field.name] } : stateFilters[field.name]?.value) : undefined;

            if (!!field?.afterSelect) {
                const haveAfterSelectValue = !!stateFilters && !!stateFilters[field.afterSelect] && stateFilters[field.afterSelect]?.value !== undefined;

                if (!haveAfterSelectValue) {
                    return null;
                }

                if (!!field.afterSelectValues && !field.afterSelectValues.some((value) => value === stateFilters[field.afterSelect!] || value === stateFilters[field.afterSelect!].value)) {
                    return null;
                }
            }

            switch (field.type) {
                case 'number':
                    return (
                        <Input name={field.name} autoFocus={true} placeholder={`Buscar por ${label}`} value={value || ''} onChange={(e: any) => handleChange(e.target.value, 'number')} type="number" />
                    );
                case 'text':
                    return <Input name={field.name} autoFocus={true} placeholder={`Buscar por ${label}`} value={value || ''} onChange={(e: any) => handleChange(e.target.value)} />;
                case 'email':
                    return (
                        <Input name={field.name} type="email" autoFocus={true} placeholder={`Buscar por ${label}`} value={value || ''} onChange={(e: any) => handleChange(e.target.value, 'email')} />
                    );

                case 'date':
                case 'calendar':
                    return (
                        <DatePicker
                            name="startDate"
                            autoComplete="off"
                            dateFormat="P"
                            selected={isValidDate(value) ? moment(value).toDate() : undefined}
                            onChange={(date) => handleChange(date, field.type)}
                        />
                    );

                case 'asyncSelect':
                    return (
                        <AsyncSelect
                            placeholder={`Digite para buscar...`}
                            multiple={false}
                            name={field.name}
                            isClearable={true}
                            loadOptions={handleLoadOptions}
                            value={value}
                            onChange={(option: any) => handleChange(option?.value, 'number', option?.label)}
                        />
                    );
                case 'select':
                    return (
                        <Select
                            autoFocus={true}
                            placeholder={`Buscar por ${label}`}
                            options={[]}
                            isClearable={true}
                            value={value}
                            onChange={(option: any) => handleChange(option?.value, 'number', option?.label)}
                            classNamePrefix="select"
                            multiple={false}
                            name={field.name}
                        />
                    );

                case 'enum':
                    return (
                        <Select
                            autoFocus={true}
                            placeholder={`Buscar por ${label}`}
                            options={convertEnumToOptions(field.enum, 'general') ?? []}
                            isClearable={true}
                            value={value}
                            onChange={(option: any) => handleChange(option?.value, 'number', option?.label)}
                            classNamePrefix="select"
                            name={field.name}
                            multiple={false}
                        />
                    );

                default:
                    return null;
            }
        } catch (error) {
            console.log(error);

            return null;
        }
    }, [field, handleChange, handleLoadOptions, label, stateFilters]);

    if (!inputfield) {
        return null;
    }

    return (
        <FormGroup withSpacing>
            <Label>{label}</Label>

            {inputfield}
        </FormGroup>
    );
};

export default AdvancedSearchFieldItem;
