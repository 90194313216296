// Components
import Dropdown from './dropdown';

// Redux
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Creators as CrudActions } from 'store/ducks/crud';

const mapDispatchToProps = (dispatch: Dispatch) => ({
    crudActions: bindActionCreators(CrudActions, dispatch) as any
});

export default connect(null, mapDispatchToProps)(Dropdown);
