import styled, { css } from 'styled-components';
import theme from 'config/theme';
import { TooltipDirection } from '.';

export const TooltipContainer = styled.div<{ direction?: TooltipDirection; isVisible: boolean }>`
    background: ${theme.colors.components.tooltipBg};
    border-radius: 3px;
    color: ${theme.colors.white};
    font-family: ${theme.typography.family.primary.light.regular};
    font-size: ${theme.typography.sizes.small};
    line-height: 1;
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    padding: 5px 10px;
    position: absolute;
    transition: opacity 0.2s ease;
    visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
    white-space: nowrap;
    z-index: 12;

    &:after {
        content: '';
        height: 0;
        position: absolute;
        width: 0;
    }

    ${({ direction }) => {
        if (direction === 'left') {
            return css`
                bottom: 50%;
                left: auto;
                right: calc(100% + 10px);
                transform: translateY(50%);

                &:after {
                    border-top: 7px solid transparent;
                    border-left: 5px solid ${theme.colors.components.tooltipBg};
                    border-bottom: 7px solid transparent;
                    right: -4px;
                    position: absolute;
                    transform: translateY(-50%);
                    top: 50%;
                }
            `;
        } else if (direction === 'right') {
            return css`
                bottom: 50%;
                left: calc(100% + 10px);
                right: auto;
                transform: translateY(50%);

                &:after {
                    border-top: 7px solid transparent;
                    border-right: 5px solid ${theme.colors.components.tooltipBg};
                    border-bottom: 7px solid transparent;
                    left: -4px;
                    position: absolute;
                    transform: translateY(-50%);
                    top: 50%;
                }
            `;
        } else if (direction === 'bottom') {
            return css`
                left: 50%;
                transform: translateX(-50%);
                top: calc(100% + 10px);

                &:after {
                    border-left: 7px solid transparent;
                    border-right: 7px solid transparent;
                    border-bottom: 5px solid ${theme.colors.components.tooltipBg};
                    left: 50%;
                    position: absolute;
                    transform: translateX(-50%);
                    top: -4px;
                }
            `;
        }

        return css`
            bottom: calc(100% + 10px);
            left: 50%;
            transform: translateX(-50%);

            &:after {
                border-left: 7px solid transparent;
                border-right: 7px solid transparent;
                border-top: 5px solid ${theme.colors.components.tooltipBg};
                bottom: -4px;
                left: 50%;
                position: absolute;
                transform: translateX(-50%);
            }
        `;
    }}
`;
