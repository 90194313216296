export const getExamAnswerCardKnowledgeAreasQuery = `query getExamAnswerCardLight($id: Int!) {
    item: prodigio_answer_cards_by_pk(id: $id) {
      id
      exerciseList: exercise_list {
        id
        exam_day {
          knowledge_areas(distinct_on: [id_knowledge_area]) {
            order
            id: id_knowledge_area
            knowledge_area {
              id
              name
              color
            }
          }
        }
        items(where: {deleted: {_is_null: true}, id_knowledge_area: {_is_null: false}, id_question: {_is_null: false}}, order_by: {order: asc}) {
          id_question
          id_knowledge_area
          order
        }
      }
    }
  }
`;
