import { OrderStatusesDictionary } from './interfaces/IStore';
import theme from 'config/theme';

const generateOrderStatusColor = (status: OrderStatusesDictionary) => {
    let color;

    const colors = new Map<any, string>([
        [['UnauthorizedPayment', 'RefundedPayment', 'RefusedStatus'], theme.colors.system.danger],
        [['WaitingPayment', 'Processing'], theme.colors.system.warning],
        [['AuthorizedPayment', 'OrderCompleted'], theme.colors.system.success]
    ]);

    if (!status) {
        return theme.colors.system.warning;
    }

    for (const item of colors.keys()) {
        if (item.includes(status)) {
            color = colors.get(item);
        }
    }

    return color;
};

export default generateOrderStatusColor;
