// sampleReducer.js
import { createReducer, createActions } from 'reduxsauce';
import { ProductPhysicalTypes, IProductPhysicalActionsCreators, IProductPhysicalState } from './types';

export const { Creators, Types } = createActions<Record<ProductPhysicalTypes, ProductPhysicalTypes>, IProductPhysicalActionsCreators>({
    getProductPhysicalByIdRequest: ['payload'],
    getProductPhysicalByIdSuccess: ['payload'],
    getProductPhysicalByIdFailure: ['payload'],

    createOrEditProductPhysicalRequest: ['payload'],
    createOrEditProductPhysicalSuccess: ['payload'],
    createOrEditProductPhysicalFailure: ['payload'],

    getAllProductPhysicalConfigurationsRequest: ['payload'],
    getAllProductPhysicalConfigurationsSuccess: ['payload'],
    getAllProductPhysicalConfigurationsFailure: ['payload'],

    deleteProductPhysicalConfigurationRequest: ['payload'],
    deleteProductPhysicalConfigurationSuccess: ['payload'],
    deleteProductPhysicalConfigurationFailure: ['payload'],

    createOrEditProductPhysicalConfigurationRequest: ['payload'],
    createOrEditProductPhysicalConfigurationSuccess: ['payload'],
    createOrEditProductPhysicalConfigurationFailure: ['payload'],

    openProductPhysicalConfigurationModalRequest: ['payload'],
    openProductPhysicalConfigurationModalSuccess: ['payload'],
    closeProductPhysicalConfigurationModal: [],

    openProductPhysicalConfigurationOption: ['payload'],
    closeProductPhysicalConfigurationOption: [],

    createOrEditProductPhysicalConfigurationOptionRequest: ['payload'],
    createOrEditProductPhysicalConfigurationOptionSuccess: ['payload'],
    createOrEditProductPhysicalConfigurationOptionFailure: [],

    deleteProductPhysicalConfigurationOptionRequest: ['payload'],
    deleteProductPhysicalConfigurationOptionSuccess: ['payload'],
    deleteProductPhysicalConfigurationOptionFailure: [],

    clearProductPhysical: []
});

const CONFIGURATIONS_INITIAL_STATE = {
    items: [],
    pagination: {
        count: 0,
        page: 0,
        totalItems: 0,
        totalPages: 0
    }
};

const INITIAL_STATE: IProductPhysicalState = {
    loading: false,
    error: false,
    loadingConfigurations: false,
    errorConfigurations: false,

    loadingDeleteConfigurations: false,

    loadingCrudConfigurations: false,
    loadingCrudConfigurationOptions: false,

    name: '',
    id: 0,
    is_visible: false,
    sku: '',
    configurations: CONFIGURATIONS_INITIAL_STATE,
    medias: [],

    activeConfigurationModal: false,
    activeConfiguration: undefined,

    activeOption: undefined,
    activeOptionForm: false,

    loadingActiveOption: false,

    amount: 0
};

const request = (state = INITIAL_STATE) => {
    return { ...state, error: false, loading: true };
};

const success = (state = INITIAL_STATE, action: any) => {
    return { ...state, error: false, loading: false, ...action.payload };
};

const failure = (state = INITIAL_STATE) => {
    return { ...state, error: true, loading: false };
};

const configurationsRequest = (state = INITIAL_STATE) => {
    return { ...state, errorConfigurations: false, loadingConfigurations: true, configurations: CONFIGURATIONS_INITIAL_STATE };
};

const configurationsSuccess = (state = INITIAL_STATE, action: any) => {
    return { ...state, errorConfigurations: false, loadingConfigurations: false, configurations: action.payload };
};

const configurationsFailure = (state = INITIAL_STATE) => {
    return { ...state, errorConfigurations: true, loadingConfigurations: false, configurations: CONFIGURATIONS_INITIAL_STATE };
};

const deleteConfigurationsRequest = (state = INITIAL_STATE) => {
    return { ...state, loadingDeleteConfigurations: true };
};

const deleteConfigurationsSuccess = (state = INITIAL_STATE, action: any) => {
    const updateConfigurations = state.configurations.items.filter((configuration) => configuration.id !== action.payload.id);

    return {
        ...state,
        loadingDeleteConfigurations: false,
        configurations: {
            ...state.configurations,
            items: updateConfigurations
        }
    };
};

const deleteConfigurationsFailure = (state = INITIAL_STATE) => {
    return { ...state, loadingDeleteConfigurations: false };
};

const clear = () => INITIAL_STATE;

const openConfigurationModal = (state = INITIAL_STATE, action?: any) => {
    return { ...state, activeConfigurationModal: true, activeConfiguration: action?.payload, loadingCrudConfigurationOptions: true };
};

const openConfigurationModalSuccess = (state = INITIAL_STATE, action?: any) => {
    return {
        ...state,
        activeConfigurationModal: true,
        activeConfiguration: {
            ...state.activeConfiguration,
            options: action.payload
        },
        loadingCrudConfigurationOptions: false
    };
};

const closeConfigurationModal = (state = INITIAL_STATE) => {
    return { ...state, activeConfigurationModal: false, activeConfiguration: undefined, loadingCrudConfigurationOptions: false };
};

const crudConfigurationsRequest = (state = INITIAL_STATE) => {
    return { ...state, loadingCrudConfigurations: true };
};

const crudConfigurationsSuccess = (state = INITIAL_STATE, action: any) => {
    const haveThisConfiguration = state?.configurations?.items?.some((configuration) => configuration.id === action.payload.id);

    const updateConfigurations = haveThisConfiguration
        ? state?.configurations?.items?.map((configuration) => (configuration.id === action.payload.id ? action.payload : configuration))
        : [action.payload, ...state?.configurations?.items];

    return {
        ...state,
        loadingCrudConfigurations: false,
        configurations: {
            ...state.configurations,
            items: updateConfigurations,
            options: []
        },
        activeConfiguration: action.payload
    };
};

const crudConfigurationsFailure = (state = INITIAL_STATE) => {
    return { ...state, loadingCrudConfigurations: false };
};

const openConfigurationOption = (state = INITIAL_STATE, action?: any) => {
    return { ...state, activeOptionForm: true, activeOption: action?.payload };
};

const closeConfigurationOption = (state = INITIAL_STATE) => {
    return { ...state, activeOptionForm: false, activeOption: undefined };
};

const createOrEditConfigurationOptionRequest = (state = INITIAL_STATE, _?: any) => {
    return { ...state, loadingActiveOption: true };
};

const createOrEditConfigurationOptionSuccess = (state = INITIAL_STATE, action?: { payload: { id: number; media?: any; name: string; sku: string } }) => {
    const haveThisOption = state.activeConfiguration?.options?.some((option) => option.id === action?.payload?.id);

    const updateOptions = haveThisOption
        ? state.activeConfiguration?.options?.map((option) => (option.id === action?.payload?.id ? action?.payload : option))
        : [...(state.activeConfiguration?.options || []), action?.payload];

    return {
        ...state,
        loadingActiveOption: false,
        activeConfiguration: {
            ...state.activeConfiguration,
            options: updateOptions
        }
    };
};

const createOrEditConfigurationOptionFailure = (state = INITIAL_STATE) => {
    return { ...state, loadingActiveOption: false };
};

const deleteConfigurationOptionRequest = (state = INITIAL_STATE) => {
    return { ...state, loadingActiveOption: true };
};

const deleteConfigurationOptionSuccess = (state = INITIAL_STATE, action: { payload: { id: number } }) => {
    const updateOptions = state.activeConfiguration?.options?.filter((option) => option.id !== action.payload.id);

    return {
        ...state,
        loadingActiveOption: false,
        activeConfiguration: {
            ...state.activeConfiguration,
            options: updateOptions
        }
    };
};

const deleteConfigurationOptionFailure = (state = INITIAL_STATE) => {
    return { ...state, loadingActiveOption: false };
};

const HANDLERS: any = {
    [Types.GET_PRODUCT_PHYSICAL_BY_ID_REQUEST]: request,
    [Types.GET_PRODUCT_PHYSICAL_BY_ID_SUCCESS]: success,
    [Types.GET_PRODUCT_PHYSICAL_BY_ID_FAILURE]: failure,

    [Types.CREATE_OR_EDIT_PRODUCT_PHYSICAL_REQUEST]: request,
    [Types.CREATE_OR_EDIT_PRODUCT_PHYSICAL_SUCCESS]: success,
    [Types.CREATE_OR_EDIT_PRODUCT_PHYSICAL_FAILURE]: failure,

    [Types.GET_ALL_PRODUCT_PHYSICAL_CONFIGURATIONS_REQUEST]: configurationsRequest,
    [Types.GET_ALL_PRODUCT_PHYSICAL_CONFIGURATIONS_SUCCESS]: configurationsSuccess,
    [Types.GET_ALL_PRODUCT_PHYSICAL_CONFIGURATIONS_FAILURE]: configurationsFailure,

    [Types.DELETE_PRODUCT_PHYSICAL_CONFIGURATION_REQUEST]: deleteConfigurationsRequest,
    [Types.DELETE_PRODUCT_PHYSICAL_CONFIGURATION_SUCCESS]: deleteConfigurationsSuccess,
    [Types.DELETE_PRODUCT_PHYSICAL_CONFIGURATION_FAILURE]: deleteConfigurationsFailure,

    [Types.OPEN_PRODUCT_PHYSICAL_CONFIGURATION_MODAL_REQUEST]: openConfigurationModal,
    [Types.OPEN_PRODUCT_PHYSICAL_CONFIGURATION_MODAL_SUCCESS]: openConfigurationModalSuccess,
    [Types.CLOSE_PRODUCT_PHYSICAL_CONFIGURATION_MODAL]: closeConfigurationModal,

    [Types.CREATE_OR_EDIT_PRODUCT_PHYSICAL_CONFIGURATION_REQUEST]: crudConfigurationsRequest,
    [Types.CREATE_OR_EDIT_PRODUCT_PHYSICAL_CONFIGURATION_SUCCESS]: crudConfigurationsSuccess,
    [Types.CREATE_OR_EDIT_PRODUCT_PHYSICAL_CONFIGURATION_FAILURE]: crudConfigurationsFailure,

    [Types.OPEN_PRODUCT_PHYSICAL_CONFIGURATION_OPTION]: openConfigurationOption,
    [Types.CLOSE_PRODUCT_PHYSICAL_CONFIGURATION_OPTION]: closeConfigurationOption,

    [Types.CREATE_OR_EDIT_PRODUCT_PHYSICAL_CONFIGURATION_OPTION_REQUEST]: createOrEditConfigurationOptionRequest,
    [Types.CREATE_OR_EDIT_PRODUCT_PHYSICAL_CONFIGURATION_OPTION_SUCCESS]: createOrEditConfigurationOptionSuccess,
    [Types.CREATE_OR_EDIT_PRODUCT_PHYSICAL_CONFIGURATION_OPTION_FAILURE]: createOrEditConfigurationOptionFailure,

    [Types.DELETE_PRODUCT_PHYSICAL_CONFIGURATION_OPTION_REQUEST]: deleteConfigurationOptionRequest,
    [Types.DELETE_PRODUCT_PHYSICAL_CONFIGURATION_OPTION_SUCCESS]: deleteConfigurationOptionSuccess,
    [Types.DELETE_PRODUCT_PHYSICAL_CONFIGURATION_OPTION_FAILURE]: deleteConfigurationOptionFailure,

    [Types.CLEAR_PRODUCT_PHYSICAL]: clear
};

export default createReducer(INITIAL_STATE, HANDLERS);
