import theme from 'config/theme';
import styled from 'styled-components/macro';
import { SlideInLeftSmall } from 'assets/animations';
import { ButtonGroupContainer } from 'components/buttonGroup/styles';

export const ActionsNavContainer = styled.div`
    ${ButtonGroupContainer} {
        animation: ${SlideInLeftSmall} 0.5s ease;
        position: absolute;
        right: 45px;
        top: -0.5px;

        &:after {
            border-bottom: 7px solid transparent;
            border-left: 5px solid ${theme.colors.white};
            border-top: 7px solid transparent;
            content: '';
            right: -5px;
            position: absolute;
            transform: translateY(-50%);
            top: 50%;
        }
    }
`;
