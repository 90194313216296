import { createActions, createReducer } from 'reduxsauce';
import { ICrudResponse } from 'utils/interfaces/ICrud';

export interface ICrudState {
    isLoading: boolean;
    currentItem: any;
    data: ICrudResponse;
    relations: any;
    isEmpty: boolean;
    payload?: object;
    error: boolean;
}

export const { Creators, Types } = createActions({
    getAllRequest: ['payload'],
    getAllGraphqlRequest: ['payload'],
    getAllSuccess: ['payload'],
    getAllFailure: ['payload'],

    getWithRelationsRequest: ['payload'],
    getWithRelationsSuccess: ['payload'],
    getWithRelationsFailure: ['payload'],

    createOrEditRequest: ['payload'],
    createOrEditSuccess: ['payload'],
    createOrEditFailure: ['payload'],
    deleteRequest: ['payload'],
    graphqlDeleteRequest: ['payload'],
    deleteSuccess: ['payload'],
    deleteFailure: ['payload'],

    toggleActiveRequest: ['payload'],
    toggleActiveSuccess: ['payload'],
    toggleActiveFailure: [],

    changeVideoStatusRequest: ['payload'],
    changeVideoStatusSuccess: ['payload'],
    changeVideoStatusFailure: [],

    removeItemById: ['payload'],

    getOneRequest: ['payload'],
    getOneSuccess: ['payload'],
    getOneFailure: ['payload'],

    clearAllRequest: [],

    refreshGetAll: ['payload']
});

const INITIAL_STATE: ICrudState = {
    isLoading: false,
    isEmpty: false,
    error: false,
    data: {
        count: 0,
        items: [],
        page: 0,
        totalItems: 0,
        totalPages: 0
    },
    currentItem: {},
    relations: {},
    payload: undefined
};

const chore = (state = INITIAL_STATE) => state;

const getAllRequest = (state = INITIAL_STATE) => ({
    ...state,
    isLoading: true,
    isEmpty: false,
    error: false
});

const getAllSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    data: Array.isArray(action.payload) ? action.payload : { ...action.payload },
    isLoading: false,
    isEmpty: Array.isArray(action.payload) ? !action.payload.length : !action.payload?.items.length,
    error: false,
    ...(action?.payload?.payload && { payload: action?.payload?.payload })
});

const getAllFailure = (state = INITIAL_STATE) => ({ ...state, isLoading: false, isEmpty: false, error: true });

const getWithRelationsRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true });

const getWithRelationsSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    relations: { ...action.payload },
    isLoading: false
});

const getWithRelationsFailure = (state = INITIAL_STATE) => ({ ...state, isLoading: false });

const createOrEditRequest = (state = INITIAL_STATE) => ({
    ...state,
    isLoading: true
});

const createOrEditSuccess = (state = INITIAL_STATE, action: any) => {
    const hasThisItem = state.data?.items?.some(({ id }) => id === action.payload.id);

    const updateItems = hasThisItem ? state.data?.items?.map((item) => (item.id === action.payload.id ? action.payload : item)) : [action.payload, ...state.data?.items];

    return {
        ...state,
        isLoading: false,
        data: {
            ...state.data,
            items: updateItems
        }
    };
};

const createOrEditFailure = (state = INITIAL_STATE) => ({ ...state, isLoading: false });

const deleteRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true });

const deleteGraphqlRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true });

const deleteSuccess = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        data: {
            ...state.data,
            items: [...action.payload]
        },
        isLoading: false,
        isEmpty: !action?.payload?.length
    };
};

const deleteFailure = (state = INITIAL_STATE) => ({ ...state, isLoading: false });

const toggleActiveRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true });
const toggleActiveSuccess = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        data: {
            ...state.data,
            items: [...action.payload]
        },
        isLoading: false,
        isEmpty: !action?.payload?.length
    };
};
const toggleActiveFailure = (state = INITIAL_STATE) => ({ ...state, ...INITIAL_STATE, isLoading: false });

const changeVideoStatusRequest = (state = INITIAL_STATE) => ({ ...state });
const changeVideoStatusSuccess = (state = INITIAL_STATE, action: any) => {
    const formattedItemns = state.data.items.map((item) => {
        if (item.id === action.payload.id) {
            return {
                ...item,
                status: action.payload.status
            };
        }

        return item;
    });

    return {
        ...state,
        data: {
            ...state.data,
            items: formattedItemns
        }
    };
};
const changeVideoStatusFailure = (state = INITIAL_STATE) => ({ ...state });

const getOneRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true });

const getOneSuccess = (state = INITIAL_STATE, action: any) => ({ ...state, currentItem: action.payload, isLoading: false });

const getOneFailure = (state = INITIAL_STATE) => ({ ...state, ...INITIAL_STATE, isLoading: false });

const clearAllRequest = (state = INITIAL_STATE) => ({ ...state, ...INITIAL_STATE });

const removeItemById = (
    state = INITIAL_STATE,
    action: {
        type: string;
        payload: {
            id: number;
        };
    }
) => {
    const updateItems = state?.data?.items?.filter(({ id }) => id !== action?.payload?.id);
    return {
        ...state,
        data: {
            ...state.data,
            items: updateItems
        },
        isEmpty: !updateItems?.length
    };
};

export default createReducer(INITIAL_STATE, {
    [Types.GET_ALL_REQUEST]: getAllRequest,
    [Types.GET_ALL_GRAPHQL_REQUEST]: getAllRequest,
    [Types.GET_ALL_SUCCESS]: getAllSuccess,
    [Types.GET_ALL_FAILURE]: getAllFailure,

    [Types.GET_WITH_RELATIONS_REQUEST]: getWithRelationsRequest,
    [Types.GET_WITH_RELATIONS_SUCCESS]: getWithRelationsSuccess,
    [Types.GET_WITH_RELATIONS_FAILURE]: getWithRelationsFailure,

    [Types.CREATE_OR_EDIT_REQUEST]: createOrEditRequest,
    [Types.CREATE_OR_EDIT_SUCCESS]: createOrEditSuccess,
    [Types.CREATE_OR_EDIT_FAILURE]: createOrEditFailure,

    [Types.DELETE_REQUEST]: deleteRequest,
    [Types.GRAPHQL_DELETE_REQUEST]: deleteGraphqlRequest,
    [Types.DELETE_SUCCESS]: deleteSuccess,
    [Types.DELETE_FAILURE]: deleteFailure,

    [Types.TOGGLE_ACTIVE_REQUEST]: toggleActiveRequest,
    [Types.TOGGLE_ACTIVE_SUCCESS]: toggleActiveSuccess,
    [Types.TOGGLE_ACTIVE_FAILURE]: toggleActiveFailure,

    [Types.CHANGE_VIDEO_STATUS_REQUEST]: changeVideoStatusRequest,
    [Types.CHANGE_VIDEO_STATUS_SUCCESS]: changeVideoStatusSuccess,
    [Types.CHANGE_VIDEO_STATUS_FAILURE]: changeVideoStatusFailure,

    [Types.GET_ONE_REQUEST]: getOneRequest,
    [Types.GET_ONE_SUCCESS]: getOneSuccess,
    [Types.GET_ONE_FAILURE]: getOneFailure,

    [Types.CLEAR_ALL_REQUEST]: clearAllRequest,

    [Types.REMOVE_ITEM_BY_ID]: removeItemById,

    [Types.REFRESH_GET_ALL]: chore
});
