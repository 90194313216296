import React, { useEffect, useState, useCallback, useMemo } from 'react';

// Assets
import { Content, Container } from 'assets/global';
import { FormGroup, Label } from 'components/forms/styles';

// Dependencies
import { object, string } from 'yup';
import { Form } from '@rocketseat/unform';
import { Row, Col } from 'react-bootstrap';

// Components
import Button from 'components/button';
import Loading from 'components/loading';
import Input from 'components/forms/input';
import PageHeader from 'components/pageHeader';

// Helpers
import dictionary from 'config/dictionary';
import { RouteComponentProps } from 'react-router';
import { IStoreState } from 'utils/interfaces/IStore';
import { IPayloadGraphqlRequest } from 'utils/interfaces/IRequestAction';
import { IStoreActions } from 'utils/interfaces/IActions';

import { storeByPk } from 'utils/queries/store/storeByPk';

import { CardContainer } from 'components/card/styles';
import Toggle from 'components/toggle';
import { createStore } from 'utils/queries/store/createStore';
import { editStore } from 'utils/queries/store/editStore';
import { slugify } from 'utils/slugify';

interface IProps extends RouteComponentProps<{ id: string }> {
    store: IStoreState;
    storeActions: IStoreActions;
}

const schema = object().shape({
    name: string().required(dictionary.validations.required),
    slug: string().required(dictionary.validations.required),
    url: string().required(dictionary.validations.required)
});

const Store = ({ match, store, storeActions }: IProps) => {
    const { id } = match.params;

    const [booleanValues, setBooleanValues] = useState<{
        is_active: boolean;
        has_showcase: boolean;
        has_withdrawal: boolean;
        with_delivery: boolean;
    }>({
        is_active: false,
        has_showcase: false,
        has_withdrawal: false,
        with_delivery: false
    });

    const setInitialData = useCallback(() => {
        setBooleanValues({
            is_active: !!store.isActive,
            has_showcase: !!store.hasShowcase,
            has_withdrawal: !!store.hasWithdrawal,
            with_delivery: !!store.withDelivery
        });
    }, [store.hasShowcase, store.hasWithdrawal, store.isActive, store.withDelivery]);

    useEffect(() => {
        setInitialData();
    }, [setInitialData]);

    const requestStore = useCallback(() => {
        if (!id) {
            return;
        }

        const payload: IPayloadGraphqlRequest<any> = {
            query: storeByPk,
            params: {
                id
            }
        };

        storeActions.getStoreRequest(payload);
    }, [id, storeActions]);

    useEffect(() => {
        return () => storeActions.clearStore();
    }, [storeActions]);

    useEffect(() => requestStore(), [requestStore]);

    const handleSubmit = useCallback(
        (data: any) => {
            const payload: IPayloadGraphqlRequest<any> = {
                query: !!id ? editStore : createStore,
                params: {
                    ...data,
                    ...booleanValues,

                    ...(!!id && { id })
                }
            };

            storeActions.createOrEditStoreRequest(payload);
        },
        [booleanValues, id, storeActions]
    );

    const handleChangeName = useCallback((e: any) => {
        const slugInput = document.getElementById('slug') as HTMLInputElement | null;

        if (!slugInput) {
            return;
        }

        slugInput.value = slugify(e.target.value);
    }, []);

    const isCreateView = useMemo(() => !id || isNaN(+id), [id]);

    return (
        <>
            {store.isLoading && <Loading fullScreen={true} />}
            <PageHeader title={isCreateView ? 'Nova loja' : store.name || 'Editar Loja'} />
            <Content>
                <Container>
                    <CardContainer withSpacing>
                        <Form onSubmit={handleSubmit} initialData={store} schema={schema}>
                            <Row>
                                <Col md={4}>
                                    <FormGroup withSpacing={true}>
                                        <Label htmlFor="name">Nome*</Label>
                                        <Input type="text" placeholder="Nome" name="name" onChange={handleChangeName} />
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <FormGroup withSpacing={true}>
                                        <Label htmlFor="slug">Slug*</Label>
                                        <Input type="text" placeholder="Slug" name="slug" id="slug" />
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <FormGroup withSpacing={true}>
                                        <Label htmlFor="url">Url*</Label>
                                        <Input type="text" placeholder="Url" name="url" />
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <FormGroup withSpacing={true}>
                                        <Label>Ativa</Label>
                                        <Toggle initialValue={!!booleanValues.is_active} onChange={(active) => setBooleanValues((old) => ({ ...old, is_active: active }))} />
                                    </FormGroup>
                                </Col>

                                <Col md={4}>
                                    <FormGroup withSpacing={true}>
                                        <Label>Com Vitrine</Label>
                                        <Toggle initialValue={!!booleanValues.has_showcase} onChange={(active) => setBooleanValues((old) => ({ ...old, has_showcase: active }))} />
                                    </FormGroup>
                                </Col>

                                <Col md={4}>
                                    <FormGroup withSpacing={true}>
                                        <Label>Com Retirada</Label>
                                        <Toggle initialValue={!!booleanValues.has_withdrawal} onChange={(active) => setBooleanValues((old) => ({ ...old, has_withdrawal: active }))} />
                                    </FormGroup>
                                </Col>

                                <Col md={4}>
                                    <FormGroup withSpacing={true}>
                                        <Label>Com Entrega</Label>
                                        <Toggle initialValue={!!booleanValues.with_delivery} onChange={(active) => setBooleanValues((old) => ({ ...old, with_delivery: active }))} />
                                    </FormGroup>
                                </Col>
                            </Row>

                            <FormGroup style={{ margin: '30px 0' }}>
                                <Button type="submit" variant="info">
                                    Salvar
                                </Button>
                            </FormGroup>
                        </Form>
                    </CardContainer>
                </Container>
            </Content>
        </>
    );
};

export default Store;
