import styled from 'styled-components/macro';
import { DropdownContainer } from 'components/dropdown/styles';
import { ButtonContainer } from 'components/button/styles';

export const DownloadQRCodeContainer = styled.div`
    display: flex;

    ${ButtonContainer} {
        :active,
        :focus {
            box-shadow: unset;
        }
    }

    > ${ButtonContainer} {
        border-radius: 0.25rem 0 0 0.25rem;
        border-right: 2px solid #fff;

        svg {
            width: 15px;
            height: 15px;
        }
    }
`;

export const DownloadQRCodeSelect = styled.div`
    display: flex;
    position: relative;

    ${ButtonContainer} {
        display: flex;
        border-radius: 0 0.25rem 0.25rem 0;

        svg {
            margin-left: 10px;
            transform: rotate(180deg);
        }
    }

    ${DropdownContainer} {
        right: 0;
        min-width: unset;
        width: 100%;
        transform: translateY(-100%);
        bottom: unset;
        box-shadow: 0px 0px 10px 0px #e2e2e2;
    }
`;
