import React, { memo } from 'react';

// Dependencies
import { Row, Col } from 'react-bootstrap';

// Components
import Icon from 'components/icons/icon';
import Actions from 'pages/projects/components/actions';

// Helpers
import formatDate from 'utils/formatDate';
import dictionary from 'config/dictionary';
import { formatCEP } from 'utils/formatText';
import generateOrderStatusColor from 'utils/generateOrderStatusColor';

// Assets
import { Subtitle } from 'assets/global';
import { StudentDataGroup, StudentDataItem } from 'pages/student/styles';
import { IStoreOrder, IStoreLocationAddress, OrderAddressType } from 'utils/interfaces/IStore';

interface IOrderSummaryProps {
    addresses: IStoreLocationAddress[];
    order: IStoreOrder;
    onEditAddress: (type: OrderAddressType, address: IStoreLocationAddress) => void;
    onDeleteAddress: (id: number) => void;
}

const OrderSummary = ({ addresses, order, onEditAddress, onDeleteAddress }: IOrderSummaryProps) => {
    return (
        <StudentDataGroup>
            <Subtitle>
                <Icon idIcon="ico-price" width="21" height="21" viewBox="0 0 59 59" />
                Resumo
            </Subtitle>
            <Row>
                {order.status && (
                    <Col md={4}>
                        <StudentDataItem>
                            <strong>Status</strong>
                            <span style={{ color: generateOrderStatusColor(order.status.name) }}>{dictionary.crud[order.status.name]}</span>
                        </StudentDataItem>
                    </Col>
                )}
                {order.created && (
                    <Col md={4}>
                        <StudentDataItem>
                            <strong>Data da compra</strong>
                            <span>{formatDate('LLL', order.created)}</span>
                        </StudentDataItem>
                    </Col>
                )}
                {order.due_date && (
                    <Col md={4}>
                        <StudentDataItem>
                            <strong>Data do vencimento</strong>
                            <span>{formatDate('LLL', order.due_date)}</span>
                        </StudentDataItem>
                    </Col>
                )}
                {order.pay_date && (
                    <Col md={4}>
                        <StudentDataItem>
                            <strong>Data do pagamento</strong>
                            <span>{formatDate('LLL', order.pay_date)}</span>
                        </StudentDataItem>
                    </Col>
                )}
                {order.payment_form && (
                    <Col md={4}>
                        <StudentDataItem>
                            <strong>Forma de pagamento</strong>
                            <span>{order.payment_form.name}</span>
                        </StudentDataItem>
                    </Col>
                )}
                {order.installments && (
                    <Col md={4}>
                        <StudentDataItem>
                            <strong>Parcelado em:</strong>
                            <span>{order.installments}</span>
                        </StudentDataItem>
                    </Col>
                )}
                <Col md={12}>
                    <StudentDataItem>
                        <strong>Endereço de envio</strong>
                        {Boolean(addresses.length) ? (
                            addresses.map((item) => (
                                <div key={item.id}>
                                    <span>
                                        {item.street}, {item.number} - {item.complement} - {item.city}, {item.state} • {formatCEP(item.zip_code)}
                                    </span>
                                    <Actions
                                        options={[
                                            {
                                                buttonVariant: 'outline-warning',
                                                icon: <Icon idIcon="ico-pencil" width="12" height="12" viewBox="0 0 512 512" />,
                                                action: () => onEditAddress(OrderAddressType.Shipping, item)
                                            },
                                            {
                                                buttonVariant: 'outline-danger',
                                                icon: <Icon idIcon="ico-trash" width="12" height="13" viewBox="0 0 465 512" />,
                                                action: () => onDeleteAddress(item.id)
                                            }
                                        ]}
                                        tooltipDirection="left"
                                    />
                                </div>
                            ))
                        ) : (
                            <span>Nenhum endereço cadastrado. </span>
                        )}
                    </StudentDataItem>
                </Col>
            </Row>
        </StudentDataGroup>
    );
};

export default memo(OrderSummary);
