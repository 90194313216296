import { put, call } from 'redux-saga/effects';
import { Creators as alertActions } from 'store/ducks/alert';
import { Creators as lessonPlanEventsActions } from 'store/ducks/lessonPlanEvents';
import { Creators as modalActions } from 'store/ducks/modal';
import { Creators as videosActions } from 'store/ducks/videos';
import { apiRequest } from '../apiRequest';
import { IRequestAction } from 'utils/interfaces/IRequestAction';
import { graphQLRequest } from '../graphQLRequest';
import { getLessonPlanEventByIdQuery } from 'utils/queries/lessonPlanEvent/getById';

import history from 'services/history';

export function* getLessonPlanEventById(action: { type: string; payload: { id: number } }) {
    try {
        const { id } = action.payload;

        const { data } = yield graphQLRequest(getLessonPlanEventByIdQuery, { id });

        if (!data?.item) {
            throw new Error();
        }

        yield put(lessonPlanEventsActions.getLessonPlanEventByIdSuccess(data?.item));
    } catch (error) {
        console.log('error', error);

        yield put(lessonPlanEventsActions.getLessonPlanEventByIdFailure());
        yield put(alertActions.showAlert('Evento não encontrado.', 'danger'));

        yield put(modalActions.closeModal());
    }
}

export function* getLessonPlanEvents(action: any) {
    try {
        const { method, endpoint, body, headers } = action.payload;

        const { data } = yield apiRequest(method, endpoint, body, headers);

        const result = {
            items: (!!data.items && data.items) || [data]
        };

        yield put(lessonPlanEventsActions.getLessonPlanEventsSuccess(result.items));
    } catch (error) {
        console.log('error', error);

        yield put(lessonPlanEventsActions.getLessonPlanEventsFailure());
        yield put(alertActions.showAlert('Não foi possível obter a lista de Eventos.', 'danger'));
    }
}

export function* createOrEditLessonPlanEvents(action: any) {
    try {
        const { endpoint, method, body, isFromDetail } = action.payload;

        const { data } = yield apiRequest(method, endpoint, body);

        /*
          Check if request comes from the detail page (app/plano-de-estudos/eventos/ver/:id);
          If passes, should get event by id so that can render correctly the event information on that page
        */
        if (isFromDetail) {
            yield put(lessonPlanEventsActions.createOrEditLessonPlanEventDetailSuccess(data));
            yield put(lessonPlanEventsActions.getLessonPlanEventByIdRequest({ id: data?.id }));
        } else {
            yield put(lessonPlanEventsActions.createOrEditLessonPlanEventsSuccess(data));
        }

        yield put(alertActions.showAlert(`Evento Salvo`, 'success'));
        yield put(modalActions.closeModal());
    } catch (error) {
        console.log(error);

        yield put(lessonPlanEventsActions.createOrEditLessonPlanEventsFailure());

        yield put(alertActions.showAlert('Ocorreu um erro. Tente novamente mais tarde.', 'danger'));
    }
}

export function* deleteLessonPlanEvents(action: { type: string; payload: { id: number; isFromDetailsPage: boolean; lessonPlanModuleId?: number } }) {
    try {
        const { id, isFromDetailsPage, lessonPlanModuleId } = action.payload;

        if (!!lessonPlanModuleId) {
            yield graphQLRequest(
                `mutation deleteLessonPlanEventsLessonPlanModules($moduleId: Int!, $eventId: Int!) {
                    delete_prodigio_lesson_plan_events_lesson_plan_modules(where: {id_lesson_plan_module: {_eq: $moduleId}, id_lesson_plan_event: {_eq: $eventId}}) {
                      affected_rows
                    }
                  }
                  `,
                {
                    moduleId: lessonPlanModuleId,
                    eventId: id
                }
            );
        } else {
            yield apiRequest('DELETE', `/lessonplanevent/${id}`);
        }

        if (isFromDetailsPage && !lessonPlanModuleId) {
            yield call(history.push, { pathname: '/app/plano-de-estudos/eventos' });
        }

        yield put(lessonPlanEventsActions.deleteLessonPlanEventsSuccess(id));
        yield put(alertActions.showAlert(!!lessonPlanModuleId ? 'Evento removido do plano de estudos.' : 'Evento Excluído.', 'success'));
        yield put(modalActions.closeModal());
    } catch (error) {
        yield put(lessonPlanEventsActions.deleteLessonPlanEventsFailure());
        yield put(alertActions.showAlert(error?.response?.data?.detail?.message || 'Ocorreu um erro. Tente novamente mais tarde.', 'danger'));
    }
}

export function* getHandShakeLessonPlanEvents(action: any) {
    try {
        const { event, video } = action.payload;

        const { data } = yield apiRequest('GET', `/admin/video/${video.id}`);

        if (!data.url) {
            throw new Error('Error no HandShake');
        }

        yield put(
            lessonPlanEventsActions.getHandShakeLessonPlanEventsSuccess({
                event,
                video: data
            })
        );
    } catch (error) {
        console.log(error);
        yield put(lessonPlanEventsActions.getHandShakeLessonPlanEventsFailure());
        yield put(alertActions.showAlert('Não foi possível obter o HandShake do Vídeo.', 'danger'));
    }
}

export function* deleteLessonPlanEventsContent(action: any) {
    try {
        const { event, content } = action.payload;

        yield apiRequest('DELETE', `/lessonplancontent/${content.id}`);

        yield put(
            lessonPlanEventsActions.deleteLessonPlanEventsContentSuccess({
                event,
                content
            })
        );

        yield put(alertActions.showAlert('Conteudo Excluído!', 'success'));
    } catch (error) {
        yield put(lessonPlanEventsActions.deleteLessonPlanEventsContentFailure());
        yield put(alertActions.showAlert(error?.response?.data?.detail?.message || 'Não foi possível remover esse conteudo', 'danger'));
    }
}

export function* createOrEditLessonPlanEventsContent(action: any) {
    try {
        const { content, event } = action.payload;

        yield apiRequest('POST', `/lessonplancontent`, {
            event,
            learningObject: content.learningObject.id
        });

        yield put(
            lessonPlanEventsActions.createOrEditLessonPlanEventsContentSuccess({
                event,
                content
            })
        );

        yield put(modalActions.closeModal());

        yield put(alertActions.showAlert('Conteudo Criado!', 'success'));
    } catch (error) {
        console.log(error);
        yield put(lessonPlanEventsActions.createOrEditLessonPlanEventsContentFailure());
        yield put(alertActions.showAlert(error?.response?.data?.detail?.message || 'Não foi possível remover esse conteudo', 'danger'));
    }
}

export function* createLessonPlanEventVideo(action: IRequestAction<any>) {
    try {
        const { body, endpoint, method } = action.payload;

        const { data } = yield apiRequest(method, endpoint, body);

        if (!data) {
            throw new Error();
        }

        const valueActiveOrDeactiveTopic = {
            doubt: { ...body.doubt },
            learningObjectId: { videoId: data.learningObject.id }
        };

        yield put(lessonPlanEventsActions.createLessonPlanEventVideoSuccess(data));
        yield put(lessonPlanEventsActions.setCurrentLessonPlanEventCreateVideo(false));
        yield put(videosActions.activateOrDeactivateTopic({ ...valueActiveOrDeactiveTopic }));
    } catch (error) {
        console.log(error);
        yield put(lessonPlanEventsActions.createLessonPlanEventVideoFailure());
        yield put(alertActions.showAlert(error?.response?.data?.detail?.message || 'Não foi possível criar o vídeo. Tente novamente.', 'danger'));
    }
}
