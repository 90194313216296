import React, { memo } from 'react';

// Components
import Button from 'components/button';
import Tooltip from 'components/tooltip';
import ButtonGroup, { ButtonGroupOptions } from 'components/buttonGroup';

// Helpers
import { Variant } from 'components/button/styles';

// Assets
import { ActionsContainer, ActionsList, ActionsItem } from './styles';

export interface IActionOption {
    buttonLabel?: string;
    buttonVariant: Variant;
    hide?: boolean;
    icon: JSX.Element;
    tooltipText?: string;
    buttonGroupOptions?: ButtonGroupOptions;
    action: () => void;
}

export interface IActionProps {
    isButtonGroupOpen?: boolean;
    options: IActionOption[];
    tooltipDirection?: 'left' | 'top' | 'right' | 'bottom';
}

const Actions = ({ isButtonGroupOpen, options, tooltipDirection = 'left' }: IActionProps) => {
    return (
        <ActionsContainer>
            <ActionsList>
                {options.map((option, index) => {
                    if (option.hide) {
                        return null;
                    }

                    return (
                        <ActionsItem key={index}>
                            <Button
                                withIcon={!!option.buttonLabel}
                                size="small"
                                variant={option.buttonVariant}
                                onClick={(e: any) => {
                                    e.stopPropagation();
                                    option.action();
                                }}
                            >
                                {option.icon}
                                {option.buttonLabel && <span>{option.buttonLabel}</span>}
                                {option.tooltipText && <Tooltip direction={tooltipDirection} content={option.tooltipText} />}
                            </Button>
                            {option.buttonGroupOptions && isButtonGroupOpen && <ButtonGroup options={option.buttonGroupOptions} />}
                        </ActionsItem>
                    );
                })}
            </ActionsList>
        </ActionsContainer>
    );
};

export default memo(Actions);
