import { IReduxAction } from 'store/types/IRedux';
import { IOption } from 'utils/interfaces/IOption';
import { createActions, createReducer } from 'reduxsauce';
import {
    LessonPlanEducationalActionsType,
    ILessonPlanEducationalActionsCreators,
    ILessonPlanEducationalState,
    ILessonPlanEducational,
    ILessonPlanEducationalModule,
    ILessonPlanModuleItemResponse
} from 'store/types/ILessonPlanEducational';

export const { Creators, Types } = createActions<Record<LessonPlanEducationalActionsType, LessonPlanEducationalActionsType>, ILessonPlanEducationalActionsCreators>(
    {
        getCoursesRequest: ['payload'],
        getCoursesSuccess: ['payload'],
        getCoursesFailure: [],

        getLessonPlanRequest: ['payload'],
        getLessonPlanSuccess: ['payload'],
        getLessonPlanFailure: [],

        getLessonPlanModulesRequest: ['payload'],
        getLessonPlanModulesSuccess: ['payload'],
        getLessonPlanModulesFailure: [],

        getCourseSubjectsRequest: ['payload'],
        getCourseSubjectsSuccess: ['payload'],
        getCourseSubjectsFailure: [],

        getSubjectModulesRequest: ['payload'],
        getSubjectModulesSuccess: ['payload'],
        getSubjectModulesFailure: [],

        getModuleRequest: ['payload'],
        getModuleSuccess: ['payload'],
        getModuleFailure: [],

        createOrUpdateLessonPlanEducationalRequest: ['payload'],
        createOrUpdateLessonPlanEducationalSuccess: ['payload'],
        createOrUpdateLessonPlanEducationalFailure: [],

        createOrUpdateModuleRequest: ['payload'],
        createOrUpdateModuleSuccess: [],
        createOrUpdateModuleFailure: [],

        reorderModulesRequest: ['payload'],
        reorderModulesSuccess: [],
        reorderModulesFailure: [],

        reorderModuleItemsRequest: ['payload'],
        reorderModuleItemsSuccess: [],
        reorderModuleItemsFailure: [],

        addModuleItemRequest: ['payload'],
        addModuleItemSuccess: [],
        addModuleItemFailure: [],

        deleteModuleItemRequest: ['payload'],
        deleteModuleItemSuccess: [],
        deleteModuleItemFailure: [],

        deleteModuleRequest: ['payload'],
        deleteModuleSuccess: [],
        deleteModuleFailure: [],

        clearLessonPlanEducational: [],

        getCurrentModulesOnLessonPlanRequest: ['payload'],
        getCurrentModulesOnLessonPlanSuccess: ['payload'],
        getCurrentModulesOnLessonPlanFailure: []
    },
    { prefix: '@@LESSON_PLAN_EDUCATIONAL/' }
);

const INITIAL_STATE: ILessonPlanEducationalState = {
    isLoading: false,
    courses: [],
    subjects: [],
    lessonPlan: {
        id: 0,
        is_published: false,
        name: '',
        slug: '',
        lesson_plan_modules_educationals: [],
        modules: [],
        module: {
            id: 0,
            order: 0,
            lesson_plan_module_educational_items: []
        }
    },
    currentModulesOnLessonPlan: []
};

const lessonPlanEducationalRequest = (state = INITIAL_STATE): ILessonPlanEducationalState => ({ ...state, isLoading: true });
const lessonPlanEducationalFailure = (state = INITIAL_STATE): ILessonPlanEducationalState => ({ ...state, isLoading: false });

const getCoursesRequest = (state = INITIAL_STATE): ILessonPlanEducationalState => ({ ...state });

const getCoursesSuccess = (state = INITIAL_STATE, action: IReduxAction<IOption[]>): ILessonPlanEducationalState => ({
    ...state,
    courses: action.payload,
    isLoading: false
});

const getCourseSubjectsSuccess = (state = INITIAL_STATE, action: IReduxAction<any[]>): ILessonPlanEducationalState => ({
    ...state,
    subjects: action.payload,
    isLoading: false
});

const getSubjectModulesSuccess = (state = INITIAL_STATE, action: IReduxAction<any[]>): ILessonPlanEducationalState => ({
    ...state,
    lessonPlan: {
        ...state.lessonPlan,
        modules: action.payload
    },
    isLoading: false
});

const getModuleSuccess = (state = INITIAL_STATE, action: IReduxAction<ILessonPlanModuleItemResponse>): ILessonPlanEducationalState => ({
    ...state,
    lessonPlan: {
        ...state.lessonPlan,
        module: action.payload
    },
    isLoading: false
});

const lessonPlanEducationalSuccess = (state = INITIAL_STATE, action: IReduxAction<ILessonPlanEducational>): ILessonPlanEducationalState => ({
    ...state,
    lessonPlan: {
        ...state.lessonPlan,
        ...action.payload
    },
    isLoading: false
});

const lessonPlanEducationalModulesSuccess = (state = INITIAL_STATE, action: IReduxAction<ILessonPlanEducationalModule[]>): ILessonPlanEducationalState => ({
    ...state,
    lessonPlan: {
        ...state.lessonPlan,
        lesson_plan_modules_educationals: action.payload
    },
    isLoading: false
});

const getCurrentModulesOnLessonPlanRequest = (state = INITIAL_STATE) => ({
    ...state,
    isLoading: true
});

const getCurrentModulesOnLessonPlanSuccess = (state = INITIAL_STATE, action: IReduxAction<number[]>) => ({
    ...state,
    isLoading: false,
    currentModulesOnLessonPlan: action.payload
});

const getCurrentModulesOnLessonPlanFailure = (state = INITIAL_STATE) => ({
    ...state,
    isLoading: false
});

const createOrUpdateLessonPlanEducationalSuccess = (state = INITIAL_STATE, action: any): ILessonPlanEducationalState => ({ ...state, isLoading: false });

const moduleSuccess = (state = INITIAL_STATE, action: any): ILessonPlanEducationalState => ({ ...state, isLoading: false });

// Clear project
const clearLessonPlanEducational = (state = INITIAL_STATE): ILessonPlanEducationalState => ({ ...state, ...INITIAL_STATE });

export default createReducer<ILessonPlanEducationalState>(INITIAL_STATE, {
    // get courses
    [Types.GET_COURSES_REQUEST]: getCoursesRequest,
    [Types.GET_COURSES_SUCCESS]: getCoursesSuccess,
    [Types.GET_COURSES_FAILURE]: lessonPlanEducationalFailure,

    // get lesson plan
    [Types.GET_LESSON_PLAN_REQUEST]: lessonPlanEducationalRequest,
    [Types.GET_LESSON_PLAN_SUCCESS]: lessonPlanEducationalSuccess,
    [Types.GET_LESSON_PLAN_FAILURE]: lessonPlanEducationalFailure,

    // get lesson plan modules
    [Types.GET_LESSON_PLAN_MODULES_REQUEST]: lessonPlanEducationalRequest,
    [Types.GET_LESSON_PLAN_MODULES_SUCCESS]: lessonPlanEducationalModulesSuccess,
    [Types.GET_LESSON_PLAN_MODULES_FAILURE]: lessonPlanEducationalFailure,

    // get course subjecs
    [Types.GET_COURSE_SUBJECTS_REQUEST]: lessonPlanEducationalRequest,
    [Types.GET_COURSE_SUBJECTS_SUCCESS]: getCourseSubjectsSuccess,
    [Types.GET_COURSE_SUBJECTS_FAILURE]: lessonPlanEducationalFailure,

    // get subject modules
    [Types.GET_SUBJECT_MODULES_REQUEST]: lessonPlanEducationalRequest,
    [Types.GET_SUBJECT_MODULES_SUCCESS]: getSubjectModulesSuccess,
    [Types.GET_SUBJECT_MODULES_FAILURE]: lessonPlanEducationalFailure,

    // get module
    [Types.GET_MODULE_REQUEST]: lessonPlanEducationalRequest,
    [Types.GET_MODULE_SUCCESS]: getModuleSuccess,
    [Types.GET_MODULE_FAILURE]: lessonPlanEducationalFailure,

    // create or update lesson plan educational
    [Types.CREATE_OR_UPDATE_LESSON_PLAN_EDUCATIONAL_REQUEST]: lessonPlanEducationalRequest,
    [Types.CREATE_OR_UPDATE_LESSON_PLAN_EDUCATIONAL_SUCCESS]: createOrUpdateLessonPlanEducationalSuccess,
    [Types.CREATE_OR_UPDATE_LESSON_PLAN_EDUCATIONAL_FAILURE]: lessonPlanEducationalFailure,

    // create or update module
    [Types.CREATE_OR_UPDATE_MODULE_REQUEST]: lessonPlanEducationalRequest,
    [Types.CREATE_OR_UPDATE_MODULE_SUCCESS]: moduleSuccess,
    [Types.CREATE_OR_UPDATE_MODULE_FAILURE]: lessonPlanEducationalFailure,

    // reorder modules
    [Types.REORDER_MODULES_REQUEST]: lessonPlanEducationalRequest,
    [Types.REORDER_MODULES_SUCCESS]: moduleSuccess,
    [Types.REORDER_MODULES_FAILURE]: lessonPlanEducationalFailure,

    // reorder module items
    [Types.REORDER_MODULE_ITEMS_REQUEST]: lessonPlanEducationalRequest,
    [Types.REORDER_MODULE_ITEMS_SUCCESS]: moduleSuccess,
    [Types.REORDER_MODULE_ITEMS_FAILURE]: lessonPlanEducationalFailure,

    // add module item
    [Types.ADD_MODULE_ITEM_REQUEST]: lessonPlanEducationalRequest,
    [Types.ADD_MODULE_ITEM_SUCCESS]: moduleSuccess,
    [Types.ADD_MODULE_ITEM_FAILURE]: lessonPlanEducationalFailure,

    // delete module item
    [Types.DELETE_MODULE_ITEM_REQUEST]: lessonPlanEducationalRequest,
    [Types.DELETE_MODULE_ITEM_SUCCESS]: moduleSuccess,
    [Types.DELETE_MODULE_ITEM_FAILURE]: lessonPlanEducationalFailure,

    // delete module
    [Types.DELETE_MODULE_REQUEST]: lessonPlanEducationalRequest,
    [Types.DELETE_MODULE_SUCCESS]: moduleSuccess,
    [Types.DELETE_MODULE_FAILURE]: lessonPlanEducationalFailure,

    // Clear lesson plan educational
    [Types.CLEAR_LESSON_PLAN_EDUCATIONAL]: clearLessonPlanEducational,

    [Types.GET_CURRENT_MODULES_ON_LESSON_PLAN_REQUEST]: getCurrentModulesOnLessonPlanRequest,
    [Types.GET_CURRENT_MODULES_ON_LESSON_PLAN_SUCCESS]: getCurrentModulesOnLessonPlanSuccess,
    [Types.GET_CURRENT_MODULES_ON_LESSON_PLAN_FAILURE]: getCurrentModulesOnLessonPlanFailure
});
