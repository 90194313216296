/**
 * @description Helper para conversão de data
 *
 * @param {string} text Texto à ser truncado
 * @param {number} date Quantidade de caracteres
 * @returns {string} Retorna um texto com a quantidade de caracteres definida pelo "limit"
 */
const truncateText = (text: string, limit = 20, withPoints?: boolean) => (text.length > limit ? `${text.substr(0, limit)}${withPoints ? '...' : ''}` : text);

export default truncateText;
