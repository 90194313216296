import { IField } from 'utils/interfaces/IField';
import InputTypes from 'utils/enums/inputTypes';

const productsPhysical: IField[] = [
    {
        presentation: 'Nome',
        label: 'Nome do Produto',
        name: 'name',
        type: InputTypes.text,
        columns: 3
    }
];

export default productsPhysical;
