import { put, call } from 'redux-saga/effects';
import { Creators as alertActions } from 'store/ducks/alert';
import { Creators as modalActions } from 'store/ducks/modal';
import { Creators as lessonPlanActions } from 'store/ducks/lessonPlan';
import { Creators as lessonPlanModulesActions } from 'store/ducks/lessonPlanModules';

import { apiRequest } from '../apiRequest';

import history from 'services/history';

import * as Moment from 'moment';
import { extendMoment } from 'moment-range';

const moment = extendMoment(Moment);

export function* createOrEditLessonPlan(action: any) {
    try {
        const { payload } = action;

        const { data } = yield apiRequest(payload.method, payload.endpoint, payload.body, payload.headers);

        yield put(lessonPlanActions.createOrEditLessonPlanSuccess(data));

        if (payload.method === 'POST') {
            const payload = {
                endpoint: '/lessonplanmodule',
                method: 'POST',
                data: {
                    shortName: `1ª SEM`,
                    name: `Semana 1`,
                    lessonPlan: data.id,
                    startDate: moment(new Date()).startOf('week').add('days', 1).toISOString(),
                    endDate: moment(new Date()).endOf('week').toISOString(),
                    order: 1
                }
            };

            yield put(lessonPlanModulesActions.createOrEditLessonPlanModulesRequest(payload));
        }

        yield call(history.push, { pathname: `/app/plano-de-estudos/editar/${data.id}` });
    } catch (error) {
        console.log(error);
        yield put(lessonPlanActions.createOrEditLessonPlanFailure());

        yield put(alertActions.showAlert(error?.response?.data?.detail?.message || 'Ocorreu um erro. Tente novamente mais tarde.', 'danger'));
    }
}

export function* getLessonPlan(action: any) {
    try {
        const { payload } = action;

        const { data } = yield apiRequest(payload.method, payload.endpoint, payload.body, payload.headers);

        yield put(lessonPlanActions.getLessonPlanSuccess(data));
    } catch (error) {
        yield call(history.push, { pathname: '/app/plano-de-estudos' });
        yield put(lessonPlanActions.getLessonPlanFailure());
        yield put(alertActions.showAlert(error?.response?.data?.detail?.message || 'Ocorreu um erro. Tente novamente mais tarde.', 'danger'));
    }
}

// Modules

export function* getLessonPlanModules(action: any) {
    try {
        const { payload } = action;

        const { data } = yield apiRequest('GET', '/lessonplanmodule', '', {
            'X-Where': `lessonPlan=${payload.id}`
        });

        yield put(lessonPlanActions.getLessonPlanModulesSuccess(data.items));
    } catch (error) {
        yield put(lessonPlanActions.getLessonPlanModulesFailure());
        yield put(alertActions.showAlert('Não foi possível obter a lista de Modulos.', 'danger'));
    }
}

export function* createOrEditLessonPlanModule(action: any) {
    try {
        const { payload } = action;

        const { data } = yield apiRequest(payload.method, payload.endpoint, payload.data);

        yield put(lessonPlanActions.createOrEditLessonPlanModuleSuccess(data));
    } catch (error) {
        yield put(lessonPlanActions.createOrEditLessonPlanModuleFailure());

        yield put(alertActions.showAlert(error?.response?.data?.detail?.message || 'Ocorreu um erro. Tente novamente mais tarde.', 'danger'));
    }
}

export function* deleteLessonPlanModule(action: any) {
    try {
        const { payload } = action;
        yield apiRequest(payload.method, payload.endpoint, payload.data);

        yield put(lessonPlanActions.deleteLessonPlanModuleSuccess(payload.data.id));
        yield put(alertActions.showAlert('Modulo Excluído.', 'success'));
    } catch (error) {
        yield put(lessonPlanActions.deleteLessonPlanModuleFailure());

        yield put(alertActions.showAlert(error?.response?.data?.detail?.message || 'Ocorreu um erro. Tente novamente mais tarde.', 'danger'));
    }
}

// EVENTS
export function* createOrEditLessonPlanEvent(action: any) {
    try {
        const { payload } = action;

        const { data } = yield apiRequest(payload.method, payload.endpoint, payload.data);

        yield put(lessonPlanActions.createOrEditLessonPlanEventSuccess({ config: { moduleId: payload.data.module, method: payload.method }, data }));

        yield put(modalActions.closeModal());
    } catch (error) {
        yield put(lessonPlanActions.createOrEditLessonPlanEventFailure());

        yield put(alertActions.showAlert(error?.response?.data?.detail?.message || 'Ocorreu um erro. Tente novamente mais tarde.', 'danger'));
    }
}

export function* deleteLessonPlanEvent(action: any) {
    try {
        const { payload } = action;

        yield apiRequest(payload.method, payload.endpoint, payload.data);

        yield put(lessonPlanActions.deleteLessonPlanEventSuccess(payload.data));
        yield put(alertActions.showAlert('Evento Excluído.', 'success'));

        yield put(modalActions.closeModal());
    } catch (error) {
        yield put(lessonPlanActions.deleteLessonPlanEventFailure());

        yield put(alertActions.showAlert(error?.response?.data?.detail?.message || 'Ocorreu um erro. Tente novamente mais tarde.', 'danger'));
    }
}
