import { lazy } from 'react';

const Report = lazy(() => import('pages/report'));

const reportRoutes = [
    {
        path: '/app/relatorios',
        name: 'Relatórios',
        component: Report,
        exact: true,
        title: 'Relatórios'
    }
];

export default reportRoutes;
