import * as React from 'react';

// Redux
import { connect } from 'react-redux';

// Helpers
import history from 'services/history';
import { IReduxStore } from 'utils/interfaces/IReduxStore';

export interface IAuthorizeProps {
    hasBrand: boolean;
    token: string;
}

export default function(Component: any) {
    class Authenticate extends React.Component<IAuthorizeProps> {
        public componentDidMount = () => {
            this.checkAndRedirect();
        };

        public componentDidUpdate = () => {
            this.checkAndRedirect();
        };

        private checkAndRedirect = () => {
            const { hasBrand, token } = this.props;

            if (!token && !hasBrand) {
                history.push('/');
            }
        };

        public render() {
            const { token } = this.props;

            return token ? <Component {...this.props} /> : null;
        }
    }

    const mapStateToProps = ({ auth, organizations }: IReduxStore) => ({
        hasBrand: organizations.currentBrand.id > 0,
        token: auth.token
    });

    return connect(mapStateToProps)(Authenticate);
}
