import styled from 'styled-components/macro';
import { BadgeContainer } from 'components/badge/styles';
import TransitionGroup from 'react-transition-group/TransitionGroup';

export const ListContainer = styled.div`
    display: flex;
    flex-direction: column;

    .table-enter {
        opacity: 0;
    }

    .table-enter-active {
        opacity: 1;
        transition: opacity 200ms ease-in;
    }

    .table-exit {
        opacity: 1;
    }

    .table-exit-active {
        opacity: 0.5;
        transition: opacity 200ms;
    }
`;

export const ListAdvancedSearchBadgesContainer = styled(TransitionGroup)`
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: 20px;

    ${BadgeContainer} {
        display: flex;
        align-items: center;

        > button {
            margin-left: 10px;
            background: transparent;
            border: none;
            color: #fff;
            font-size: 14px;
            line-height: 14px;
        }
    }
`;

export const FilterWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: flex-end;
    margin-bottom: 20px;
`;

export const FilterLabel = styled.p`
    &:first-child {
        margin-right: 10px;
    }
`;
